import React, {useEffect, useState} from 'react';
import {Tabs, Tab, Spinner} from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

import AuthorizationChecker from '../../../services/AuthorizationChecker';
import Loader from '../../widgets/Loader';

import HeadsetCard from './HeadsetCard';
import HeadsetAccessibleVideos from './HeadsetAccessibleVideos';
import HeadsetEvents from './HeadsetEvents';
import HeadsetComments from './HeadsetComments';
import HeadsetAccessibleQuizzes from './HeadsetAccessibleQuizzes';
import HeadsetUpdateFormComponent from './HeadsetUpdateFormComponent';
import PropTypes from 'prop-types';

import './HeadsetDetailComponent.scss';
import withURLParamsTableComponent from '../../../HOC/withURLParamsTableComponent';
import { useToast } from '../../../hooks/useToast';

const HeadsetAccessibleQuizzesWithParams = withURLParamsTableComponent(HeadsetAccessibleQuizzes);
const HeadsetAccessibleVideosWithParams = withURLParamsTableComponent(HeadsetAccessibleVideos);

const HeadsetDetailComponent = ({ headset, setMainModalShow }) => {
    const { headsetById, uploadedAndAccessibleVideosByHeadset, eventsByHeadset, commentsByHeadset, isFetchingOneHeadset } = useStoreState(state => state.headsets);
    const { subscriptionsByClientId} = useStoreState(state => state.clients);
    const {fetchHeadsetById} = useStoreActions(actions => actions.headsets);
    const {fetchSubscriptionsByClientId} = useStoreActions(actions => actions.clients);
    const toast = useToast();

    const [isFetching, setIsFetching] = useState(false);

    const spinner = (titre) => <div className="disabledTitle">
        <p>{titre}</p>
        <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className='tabspin' />
    </div>;

    useEffect(() => {
      if(headset?.uniqueId){
        setIsFetching(true);
        fetchHeadsetById(headset.uniqueId)
            .then(() => {
                fetchSubscriptionsByClientId(headset.clientUniqueId)
                    .finally(() => setIsFetching(false));
            })
        }
    }, [headset]); 

    if (!headsetById || (headsetById.clientUniqueId !== headset?.clientUniqueId))  return <Loader />;

    return <Tabs defaultActiveKey="details" id="headset-activity-tab">
        <Tab eventKey="details" title="Infos" mountOnEnter={true}>
            <div className="tab_content">
                <HeadsetCard headset={headsetById} setMainModalShow={setMainModalShow} />
            </div>
        </Tab>
        {(headsetById.isEditable && subscriptionsByClientId)&& <Tab disabled={isFetching} eventKey="updateHeadset" 
            title={isFetching ? spinner("Éditer") : "Éditer"} mountOnEnter={true} unmountOnExit={true}>
            <div className="tab_content">
                <HeadsetUpdateFormComponent headset={headsetById} subscriptions={subscriptionsByClientId} />
            </div>
        </Tab>}
        <Tab disabled={!eventsByHeadset} eventKey="headsetEvents" 
            title={!eventsByHeadset ? spinner("Journal de navigation") : "Journal de navigation"} mountOnEnter={true}>
            <div className="tab_content">
                <HeadsetEvents events={eventsByHeadset} />      
            </div>
        </Tab>
        <Tab disabled={!uploadedAndAccessibleVideosByHeadset} eventKey="loadedVideos" 
            title={!uploadedAndAccessibleVideosByHeadset ? spinner("Expériences immersives chargées") : "Expériences immersives chargées"} mountOnEnter={true}>
                {uploadedAndAccessibleVideosByHeadset && <div className="tab_content">
                    <HeadsetAccessibleVideosWithParams uploadedAndAccessibleVideos={uploadedAndAccessibleVideosByHeadset} />
                </div>}
        </Tab>
        {AuthorizationChecker.isAdmin() &&
            <Tab eventKey="accessibleQuizzes" title="Quiz accessibles" mountOnEnter={true}>
                <div className="tab_content">
                    <HeadsetAccessibleQuizzesWithParams headsetDeviceId={headsetById.deviceId} />      
                </div> 
            </Tab>
        }
        {AuthorizationChecker.hasGetRightsForProp("headsets", "comments") &&
            <Tab disabled={!commentsByHeadset && isFetchingOneHeadset} eventKey="headsetComments"  title={!commentsByHeadset && isFetchingOneHeadset ? spinner("Historique") : "Historique"} mountOnEnter={true}>
                <div className="tab_content" style={{padding: '0'}}>
                    <HeadsetComments comments={commentsByHeadset} headset={headset}/>      
                </div>
            </Tab>
        }
    </Tabs>
}

HeadsetDetailComponent.propTypes = {
    headset: PropTypes.object.isRequired
}

export default HeadsetDetailComponent;