import React, { useEffect,useState } from 'react';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {Form, Alert} from 'react-bootstrap';
import SelectSingleClientComponent from "../widgets/selectClient/SelectSingleClientComponent.jsx";

const TransferUserComponent = (props) => {
    const {user=null,} = props;
    const { apiData: {ACTIONS} } = useStoreState(state => state.actionSlugs);
    const {formName, csrfToken} = ACTIONS['users']["transfer"];
    const [clientUniqueId, setClientUniqueId] = useState(user && user.clientUniqueId ? [user.clientUniqueId] : []);

  
      useEffect(() => {
      }, [])

      return (
        <div className='form-transfer-user'>
            <Form id={formName} onSubmit={(event) => event.preventDefault()}>
                 <Form.Group  className="formSection">
						<Form.Label>Transférer l'utilisateur⋅rice chez:</Form.Label>
						<SelectSingleClientComponent
							nameSelect={`clientUniqueId`}
                            selectedClients={clientUniqueId}
                            setSelectedClients={setClientUniqueId} />
                        {user?.isRegisteredUser && user?.clientUniqueId !== clientUniqueId[0] && <Alert variant='danger'><i className='fas fa-exclamation-triangle' /> Attention, vous allez transférer cet accès. Si un contact existe déjà chez ce client avec cet email l'accès lui sera attribué et ses informations écrasées. Sinon un nouveau contact sera créé.</Alert>}
            		</Form.Group>
                    <Form.Group >
                            <Form.Control
                                name={`token`}
                                value={csrfToken}
                                type="hidden"
                            />
                    </Form.Group>
            </Form>
        </div>
    )

}


export const submitTransferUserForm = (action, formName, uniqueId) => {

    const formData = new FormData(document.getElementById(formName));
   
    const payload = {
        formData,
        uniqueId: uniqueId,
    }

    return action(payload)
}

export default TransferUserComponent;