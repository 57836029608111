import React, {useState} from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Loader from "../../../widgets/Loader";
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import AuthorizationChecker from "../../../../services/AuthorizationChecker";
import { useModal } from "../../../../hooks/useModal";
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import AxonautLogoBubbleComponent from "../../../widgets/axonautLogoBubble/AxonautLogoBubbleComponent";
import AddressFormComponent, {submitAddressForm} from "../../../address/AddressFormComponent.jsx";
import { useToast } from "../../../../hooks/useToast.jsx";

const ClientAddressesComponent = ({
    onSelect,
    updateAddressesForOrders,
    client,
    downloadable,
    addresses,
    withParams
}) => {
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const toast = useToast();

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const [activeRowId, setActiveRowId] = useState(null);

    const { removeAddress } = useStoreActions(actions => actions.clients);
    const { updateAddress } = useStoreActions(actions => actions.clients);

    if(typeof addresses != 'object') {return <Loader />}


    if(addresses?.length === 0) {return <div>Pas d'addresses</div>}
   

    const sortType = [
        {
            value : 'addressName', label : 'intitulé',
            test : HelperMetiers360.isArrayContainsValue(addresses, "addressName"),
            method: (a,b) =>  HelperMetiers360.localeCompareWithNullable(a.addressName, b.addressName),
            display: (address) => address.isFromAxonaut 
                ? <div className="d-flex align-items-center word-break-all">
                    <AxonautLogoBubbleComponent />
                    <span className="break-word">{address.addressName}</span>
                </div>
                : address.addressName,
            flatDisplay: (address) => address.addressName
        },
        {
            value: 'companyName', label: "Structure",
            test : HelperMetiers360.isArrayContainsValue(addresses, "companyName"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.companyName, b.companyName),
            display: (address) => address.companyName && address.companyName !== "" ? address.companyName : '-'
        },
        {
            value: 'addressStreet', label: "Voie",
            test : HelperMetiers360.isArrayContainsValue(addresses, "addressStreet"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.addressStreet, b.addressStreet),
            display: (address) => address.addressStreet && address.addressStreet  !== ""  ? address.addressStreet : '-'
        },
        {
            value: 'addressZipCode', label: "Code postal",
            test : HelperMetiers360.isArrayContainsValue(addresses, "addressZipCode"),
            method: (a, b) =>  HelperMetiers360.localeCompareWithNullable(a.addressZipCode, b.addressZipCode),
            display: (address) => address.addressZipCode && address.addressZipCode  !== "" ? address.addressZipCode : '-'
        },
        {
            value: 'addressCity', label: "Ville",
            test : HelperMetiers360.isArrayContainsValue(addresses, "addressCity"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.addressCity, b.addressCity),
            display: (address) => address.addressCity && address.addressCity  !== "" ? address.addressCity : '-'
        },
        {
            value: 'addressRegion', label: "Région",
            test : HelperMetiers360.isArrayContainsValue(addresses, "addressRegion"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.addressRegion, b.addressRegion),
            display: (address) => address.addressRegion && address.addressRegion !== "" ? address.addressRegion : '-'
        },
        {
            value: 'addressCountry', label: "Pays",
            test : HelperMetiers360.isArrayContainsValue(addresses, "addressCountry"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.addressCountry, b.addressCountry),
            display: (address) => address.addressCountry && address.addressCountry  !== "" ? address.addressCountry : '-'
        }
    ];


    if(AuthorizationChecker.hasUpdateRights('clients')) {
        sortType.push(
            {
                value: 'update', label: "Éditer",
                test : true,
                display: (address) => {
                    if(address.isFromAxonaut) return <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip>
                            <ul>
                                {address.isFromAxonaut && <li>Cette adresse est issue d'Axonaut et ne peut pas être modifiée.</li>}
                            </ul>
                        </Tooltip>
                    }    
                    >
                        <span><Button disabled variant="secondary"  className="p-2 m-1"><i className="fas fa-edit"></i></Button></span>
                    </OverlayTrigger>;
                    return<Button variant="secondary"  className="p-2 m-1" onClick={() => {updateAddressModal(address)}}><i className="fas fa-edit"></i></Button>;
                },
                flatDisplay: (address) => address.isFromAxonaut !== 0 ? '-':'X'
            },
            {
            value: 'delete', label: "Supprimer",
            test : true,
            display: (address) => {
                if(address.isFromAxonaut || address.uniqueId === client?.defaultAddress?.uniqueId) return <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip>
                        <ul>
                            {<li>L'adresse de facturation ne peut pas être supprimée.</li>}
                        </ul>
                    </Tooltip>
                }    
                >
                    <span><Button disabled variant="danger" size="sm" className="m-0 p-2"><i className="fas fa-trash-alt"></i></Button></span>
                </OverlayTrigger>;
                return <Button variant="danger" size="sm" className="m-0 p-2" onClick={() =>  deleteAddressModal(address)}><i className="fas fa-trash-alt"></i></Button>;
            },
            flatDisplay: (address) => address.isFromAxonaut !== 0 ? '-':'X'
        });
    }

   
    
    const updateAddressModal = (address)=>{
        const {formName} = ACTIONS['clientsCRM']['addresses']['update'];

        const handleSubmit = () => {
            setIsSending(true);
            const result = submitAddressForm({formName, action: updateAddress, uniqueId: address?.uniqueId, 
                updateAddressesForOrders: updateAddressesForOrders});

            result.then(() => {
                toast.open({ message: "Edition de l\'adresse réalisée avec succès", variant: "success" });
                setModalShow(false);
            })
            .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Edition de l'adresse</>,
            content: <AddressFormComponent formName={formName} address={address} />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'lg'
        });
        setModalShow(true);
    }

        const deleteAddressModal = AuthorizationChecker.hasDeleteRights('users') ? (address) => {
            setModalData({
                ...modalData,
                header: <>Suppression de l'adresse</>,
                content: <h5>Êtes-vous sûr⋅e de vouloir supprimer cette adresse
                    {address.addressName && " : " + address.addressName}&nbsp;?</h5>,
                resetButton: 'Supprimer',
                cancelButton: 'Annuler',
                onReset: () => {
                    setIsSending(true);
                    removeAddress({ uniqueId: address.uniqueId }).then(()=>{
                        if(updateAddressesForOrders){
                            updateAddressesForOrders({addressToDeleteUniqueId:address.uniqueId})
                        }
                        toast.open({ message: "Suppression de l\'adresse réalisée avec succès", variant: "success" });
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
                },
            });
            setModalShow(true);
        } : null;
    
        const handleClick = onSelect ? 
        (selectedId) => {
            onSelect(addresses.find( address =>  address.uniqueId == selectedId ));
            setActiveRowId(selectedId);
        }
        :null;

    return addresses?.length === 0
        ? <>Pas d'addresses</>
        : <>
            <DynamicTable
                contentTable={addresses}
                contentSort={sortType}
                index='uniqueId'
                downloadable={downloadable}
                handleClick = {handleClick}
                activeRowId={activeRowId}
                withParams={withParams}
                filename="adresses"
                />
            {modalComponent}
        </>;
}

export default ClientAddressesComponent