import React from 'react';
import './ToastComponent.scss'
import { Toast } from 'react-bootstrap';

const ToastComponent = (props) => {
    // variant : 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
    const {contentMessage, autohide = true, variant, show = true, onClose, delay = 3000} = props;

    return <Toast bg={variant} show={show} onClose={onClose} delay={delay} autohide={autohide}>
        <Toast.Body className={'d-flex justify-content-between align-items-center w-100 ' + 
            (variant === 'success' || variant === 'danger' ? 'color-white' : undefined)}>
            {contentMessage}
            <i className="fas fa-times close-toast" onClick={onClose}></i>
        </Toast.Body>
    </Toast>;
}

export default ToastComponent;