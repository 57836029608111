import React, { useState, useEffect } from "react";
import { ListGroup, Image } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DragableItemComponent = (props) => {
    const {list, setList, onClickItem, size, onReorderList} = props;

    const [dragableList, setDragableList] = useState(list);

    const reorder = (aList, startIndex, endIndex) => {
        const result = Array.from(aList);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        onReorderList && onReorderList(result);
        return result;
      };

    useEffect(() => {
        setDragableList(list);
    }, [list]);

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
            dragableList,
            result.source.index,
            result.destination.index
        );
    
        setList([...items]);
      };

    const itemsList = dragableList.map((item, index) => (
        <div onClick={onClickItem ? () => onClickItem(item.uniqueId):null} key={item.uniqueId}>
        <Draggable draggableId={item.uniqueId} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ListGroup.Item>{item.thumbnail ?<Image style={{width:'100px'}} src={item.thumbnail} thumbnail />:null} {item.name}</ListGroup.Item>
            </div>
          )}
        </Draggable>
        </div>
      ));

    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
                <ListGroup>
                <div
                ref={provided.innerRef}
                style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                {...provided.droppableProps}
                >
                    {itemsList}
                {provided.placeholder}
                </div>
                </ListGroup>
            )}
        </Droppable>
    </DragDropContext>
}

export default DragableItemComponent;