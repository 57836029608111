import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from 'prop-types';
import './TooltipInfo.scss'

const TooltipInfo = ({ description }) => {
    return <div className="tooltip-info">
        <OverlayTrigger placement="top"
            overlay={
                <Tooltip className="tooltip-description">
                    {description}
                </Tooltip>
            }>
            <span className="d-flex ms-1 info-icon">
                <i className="fas fa-info-circle"></i>
            </span>
        </OverlayTrigger>
    </div>
}

TooltipInfo.propTypes = {
    description: PropTypes.string.isRequired
};

export default TooltipInfo;