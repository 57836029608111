import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import regions from './geoJson/regions.json';
import departments from './geoJson/departements.json';
import departments_numbers from './geoJson/departments_numbers.json';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import Loader from '../widgets/Loader';
import PropTypes from 'prop-types';
import './GeoMap.scss';

const GeoMap = ({ data = [], showOnlyGraph = false, width = "100%", height = "1000px" }) => {

    const [proxiedData, isValid] = useCheckDataProps(data || []);

    const [mapType, setMapType] = useState("regions");
    const [infoDisplayed, setInfoDisplayed] = useState('video_views');
    const [geoData, setGeoData] = useState(null);
    let geojsonRef = useRef();


    const integrateDataWithGeoJSON = (infoToDisplay, mapTypeUsed) => {
        const geoObject = {
            ...mapTypeUsed,
            features: mapTypeUsed.features.map(feature => {
                const zoneData = proxiedData.find(d => d.zone === feature.properties.nom || departments_numbers?.find(dep => dep.department_number == d.zone)?.department_name == feature.properties.nom);
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        metricValue: zoneData ? zoneData[infoToDisplay] : 0
                    }
                };
            })
        };
        setGeoData(geoObject);
    };


    useEffect(() => {
        integrateDataWithGeoJSON(infoDisplayed, mapType === "regions" ? regions : departments);
    }, [data]);


    const colorsForInfo = {
        color1: '#800026',
        color2: '#BD0026',
        color3: '#E31A1C',
        color4: '#FC4E2A',
        color5: '#FD8D3C',
        color6: '#FFEDA0',
    };


    const averageValue = useMemo(() => {
        if (geoData?.features?.length) {
            const metricValues = geoData?.features?.map(feature => feature?.properties?.metricValue);
            const average = metricValues?.reduce((sum, value) => sum + value, 0) / metricValues?.length;
            return Math.ceil(average);
        } else {
            return 0;
        }
    }, [geoData]);



    const getColor = (value) => {
        return value > averageValue ? colorsForInfo?.color1 :
            value > Math.ceil(averageValue / 2) ? colorsForInfo?.color2 :
                value > Math.ceil(averageValue / 3) ? colorsForInfo?.color3 :
                    value > Math.ceil(averageValue / 4) ? colorsForInfo?.color4 :
                        value > Math.ceil(averageValue / 5) ? colorsForInfo?.color5 :
                            colorsForInfo?.color6;
    }

    const style = (feature) => ({
        fillColor: getColor(feature.properties.metricValue),
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
    });


    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: (e) => highlightFeature(e),
            mouseout: (e) => resetHighlight(e),
        });

        if (feature?.properties?.nom && feature?.properties?.metricValue !== undefined) {
            layer.bindTooltip(
                `${feature.properties.nom}: ${feature.properties.metricValue}`,
                {
                    permanent: false,
                    direction: "auto",
                    className: "layer-tooltip"
                }
            );
        }
    };

    const highlightFeature = (e) => {
        const layer = e.target;

        layer.setStyle({
            weight: 7,
            color: 'white',
            dashArray: '',
        });
    };

    const resetHighlight = (e) => {
        geojsonRef.current.resetStyle(e.target);
    };


    return (
        !isValid ?
            <Alert variant="danger">Les données ne correspondent pas</Alert>
            : geoData ?
                <div className='geo-map'>
                    {!showOnlyGraph &&
                        <Row className='m-4'>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Information:</Form.Label>
                                    <Form.Select
                                        name="info-displayed"
                                        onChange={(e) => { setInfoDisplayed(e.target.value); integrateDataWithGeoJSON(e.target.value, mapType === "regions" ? regions : departments) }}
                                    >
                                        <option value="video_views">Vue des vidéos</option>
                                        <option value="unique_clients_total">Clients ayant lu les vidéos</option>
                                        <option value="unique_devices_total">Casques ayant lu les vidéos</option>
                                    </Form.Select>
                                </Form.Group >
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Carte:</Form.Label>
                                    <Form.Select
                                        name="map-type"
                                        onChange={(e) => { setMapType(e.target.value); integrateDataWithGeoJSON(infoDisplayed, e.target.value === "regions" ? regions : departments) }}                        >
                                        <option value="regions">Par régions</option>
                                        <option value="departments">Par département</option>
                                    </Form.Select>
                                </Form.Group >
                            </Col>
                        </Row>}

                    <Row>
                        <div className="captions-color">
                            <div className='caption-color'>
                                <div className='little-square' style={{ backgroundColor: colorsForInfo?.color5 }}></div>
                                &gt; {Math.ceil(averageValue / 5)}
                            </div>
                            <div className='caption-color'>
                                <div className='little-square' style={{ backgroundColor: colorsForInfo?.color4 }}></div>
                                &gt; {Math.ceil(averageValue / 4)}
                            </div>
                            <div className='caption-color'>
                                <div className='little-square' style={{ backgroundColor: colorsForInfo?.color3 }}></div>
                                &gt; {Math.ceil(averageValue / 3)}
                            </div>
                            <div className='caption-color'>
                                <div className='little-square' style={{ backgroundColor: colorsForInfo?.color2 }}></div>
                                &gt; {Math.ceil(averageValue / 2)}
                            </div>
                            <div className='caption-color'>
                                <div className='little-square' style={{ backgroundColor: colorsForInfo?.color1 }}></div>
                                &gt; {Math.ceil(averageValue / 1)}
                            </div>
                        </div>
                    </Row>


                    <MapContainer center={[47, 1]} zoom={6} style={{ height, width, zIndex: "1" }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <GeoJSON
                            key={`key${infoDisplayed}-${mapType}`}
                            data={geoData}
                            style={style}
                            onEachFeature={onEachFeature}
                            ref={geojsonRef} />
                    </MapContainer>
                </div>

                : <Loader />
    );
};

GeoMap.propTypes = {
    data: PropTypes.array.isRequired,
    showOnlyGraph: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string
}

export default GeoMap;
