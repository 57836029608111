import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import Loader from "../../../components/widgets/Loader";
import ReturnOrderDetailsComponent from "../../../components/orders/returnOrders/ReturnOrderDetails/ReturnOrderDetailsComponent";
import useBackButton from "../../../hooks/useBackButton";

const ReturnOrderDetail = () => {
    const {uniqueId} = useParams();

    const { fetchReturnOrderById } = useStoreActions(actions => actions.orders);
    const { returnOrderById } = useStoreState(state => state.orders);
    const { backButtonComponent } = useBackButton();

    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        if (!isLoading) {
            setIsLoading(true);
            fetchReturnOrderById(uniqueId)
                .finally(() => setIsLoading(false));
        }

        return () => setIsMounted(false);
    },[uniqueId]);

    const statusColors = {draft: 'danger', validated: 'success', processed: 'secondary', closed: 'info'};

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className="d-flex justify-content-between">
                        <span className="d-flex">
                            Bon de retour {returnOrderById?.id && ' N° '+returnOrderById.id}
                            {returnOrderById?.returnOrderStatus 
                                && <div className="statusdiv fs-6">
                                    <i className={`fas fa-solid fa-circle fa-xs text-${statusColors[returnOrderById.returnOrderStatus.value]}`}/>
                                        {returnOrderById.returnOrderStatus.label}
                                </div>}
                        </span>
                        {backButtonComponent}
                    </h2>
                </Col>
            </Row>
            <Row className="item_detail_page">
                { (!returnOrderById || isLoading || !isMounted)
                    ? <Loader />
                    : <ReturnOrderDetailsComponent order={returnOrderById}/>
                }
            </Row>
        </Container>
    );
}

export default ReturnOrderDetail;