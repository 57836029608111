import React, { useState, useRef } from "react";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from "react-router-dom";

import { Form, Alert } from 'react-bootstrap';

import ButtonLoaderComponent from "../widgets/ButtonLoaderComponent";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";

const UploadTagComponent = (props) => {

    const navigate = useNavigate();

    const { postTag, readAll: readAllTagSlugs} = props;

    const { allTags } = useCustomGetStoreState("tags");
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs)

    const token = ACTIONS['tags']['create'].csrfToken;

    const [errors, setErrors] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const uploadFormRef = useRef(null);

    const checkData = () => {
        setErrors([]);
        setIsSuccess(false);

        let errorsList = {};

        uploadFormRef.current = (document.getElementById('tagForm'));
        let formData = new FormData(uploadFormRef.current);

        let name = formData.get('tag[name]');

        if (!name) {
            errorsList.name = "Le nom du tag est obligatoire";
        }

        if(name && allTags.find(tag => tag.name === name.toLowerCase())){
            errorsList.name = "Ce nom de tag existe déjà";
        }

        if (Object.keys(errorsList).length) {
            setErrors(errorsList);
        }
        else {
            setIsSuccess(true);
            setErrors([]);
            setIsSending(true);
            submitForm();
        }
    }

    const submitForm = () => {
        const formData = new FormData(document.getElementById('tagForm'));

        postTag({ formData })
            .then(() => {
                setIsSuccess(true);
                navigate(readAllTagSlugs);
            })
    }

    return (
        <div>
            <div>
                {
                    errors.global ?

                        <Alert variant="danger">
                            {errors.global}
                        </Alert> : null
                }

                {
                    isSuccess && <Alert variant="success"> Le tag a bien été créé ! </Alert>
                }

                <Form id="tagForm" method="post" encType="multipart/form-data">
                    <Form.Group>
                        <Form.Label>Nom :</Form.Label>
                        <Form.Control
                            type="text"
                            name="tag[name]"
                            placeholder="Exemple: informatique"
                            isInvalid={errors.name}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group >
                        <Form.Control
                            name="tag[_token]"
                            defaultValue={token}
                            type="hidden"
                        />
                    </Form.Group>
                    <ButtonLoaderComponent
                        isSending={isSending}
                        active={!isSending}
                        className="mt-3"
                        variant="secondary"
                        onClick={checkData}
                        msg={<><i className="fas fa-save"/> Enregistrer</>}
                    />
                </Form>
            </div>
        </div>

    );
};

export default UploadTagComponent;