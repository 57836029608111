import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useStoreActions } from 'easy-peasy';
import PropTypes from 'prop-types';
import FormOneProComponent from "./formOneProComponent";

const FormProfessionalComponent = (props) => {
    const { selectedRomes, secondaryRomes, pros } = props;

    const { fetchOneRomeById } = useStoreActions(actions => actions.romes);

    const [countPro, setCountPro] = useState(0);
    const [romeAppellations, setRomeAppellations] = useState([]);
    const [romeAppellationsFetched, setRomeAppellationsFetched] = useState(false);

    useEffect(() => {
        const newAppellationRome = { ...romeAppellations };
        setRomeAppellationsFetched(false);

        Object.keys(newAppellationRome).forEach((key) => {
            if (![...selectedRomes, ...secondaryRomes].some(codeRome => key == codeRome)) {
                delete newAppellationRome[key];
            }
        });

        const requests = [...selectedRomes, ...secondaryRomes].reduce((promiseChain, codeRome) => {
            if (!newAppellationRome[codeRome]) {
                return promiseChain.then(() => new Promise((resolve) => {
                    fetchOneRomeById(codeRome).then((data) => {
                        newAppellationRome[codeRome] = data.appellations;
                        resolve();
                    });
                }));
            } else { return new Promise((resolve) => { resolve() }); }
        }, Promise.resolve());
        requests.then(() => {
            setRomeAppellations(newAppellationRome);
            setRomeAppellationsFetched(true);
        })
    }, [selectedRomes, secondaryRomes]);

    const header = <Row className='mt-5'>
        <Form.Label>Professionnel⋅les associé⋅es</Form.Label>
    </Row>;

    const addBtn = selectedRomes.length > 0
        && <Col className="d-flex justify-content-center">
            <Button variant="success" className="me-4 d-flex align-items-center justify-content-center" onClick={() => setCountPro((prev) => prev += 1)}>
                <i className="fas fa-plus"></i> &nbsp;Ajouter
            </Button>
        </Col>;

    const removeBtn = countPro > 0
        && <Col className="d-flex justify-content-center">
            <Button variant="danger" className="me-4 d-flex align-items-center justify-content-center" onClick={() => setCountPro((prev) => prev -= 1)}>
                <i className="fas fa-trash-alt"></i> &nbsp;Supprimer le⋅la dernier⋅ère
            </Button>
        </Col>;

    const existingProFormArray = pros
        .map((pro, idx) => <FormOneProComponent
            key={`pro${pro.uniqueId}`}
            id={`pro${idx}`}
            pro={pro}
            romeAppellations={romeAppellations}
            romeAppellationsFetched={romeAppellationsFetched}
            selectedRomes={selectedRomes}
        />
        );
    const newProFormArray = Array.from({ length: countPro }, (_, index) => index)
        .map((num, i) => <FormOneProComponent
            key={`pro${i + existingProFormArray.length}`}
            id={`pro${i + existingProFormArray.length}`}
            romeAppellations={romeAppellations}
            romeAppellationsFetched={romeAppellationsFetched}
            selectedRomes={selectedRomes}
        />
        );
    const content = selectedRomes.length === 0 ?
        <>Veuillez choisir un ou des codes ROME</>
        : <>
            {existingProFormArray}
            {newProFormArray}
            <span className="d-flex">
                {addBtn}
                {removeBtn}
            </span>
        </>;

    return <>{header}{content}</>;

}
FormProfessionalComponent.propTypes = {
    selectedRomes: PropTypes.array,
    secondaryRomes: PropTypes.array,
    pros: PropTypes.array
};
export default FormProfessionalComponent;