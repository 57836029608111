import { useStoreActions, useStoreState } from "easy-peasy";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

/**
 * hook that create and returns a previous button. 
 * 
 * @param {string} className for custom style
 * @param {string} label for custom label
 * @returns 
 */
const useBackButton = (props = {}) => {
    const { history } = useStoreState(state => state.navigation);
    const { setHistory } = useStoreActions(actions => actions.navigation);
    const defaultLabel = <><i className="fas fa-arrow-left"></i> &nbsp;Retour</>;
    const { className, label = defaultLabel } = props;
    const navigate = useNavigate();

    const goPreviousPage = () => {
        if (history.length > 1) {
            const newHistory = history.slice(0, history.length - 1);
            setHistory(newHistory);
            navigate(newHistory[newHistory.length - 1]);
        }
    }

    const backButtonComponent = <Button 
        className={className + " w-auto"}
        onClick={() => goPreviousPage()}
    >
        {label}
    </Button>

    return {backButtonComponent}
}

export default useBackButton;