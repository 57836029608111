import {store} from '../store/index.js';

export default class AuthorizationChecker {

    static isAdmin() {
        const {roles} = store.getState().auth;
        return roles.includes('ROLE_M360');
    }

    static isSuperUser() {
        const { roles } = store.getState().auth;
        return roles.includes('ROLE_SUPER_USER');
    }

    static getRightsFromActions(entity, actionName, propName) {
        const { apiData: {ACTIONS} } = store.getState().actionSlugs;

        if (propName) {
            return !!ACTIONS[entity]['editProps'][propName][actionName];
        }
        return !!ACTIONS[entity] && !!ACTIONS[entity][actionName];
         
    }

    /**
     * checks if the user has access to getOne url 
     * @param {String} entity entity name you want to test (videos/quizzes/stories...) 
     * @returns {boolean}
     */
    static hasReadOneRights(entity, propName = null) {
        return this.getRightsFromActions(entity, 'readOne', propName);
    }

    /**
     * checks if use has access to readAll url 
     * @param {String} entity entity name you want to test (videos/quizzes/stories...) 
     * @returns {boolean}
     */
    static hasReadAllRights(entity, propName = null) {
        return this.getRightsFromActions(entity, 'readAll', propName);
    }

    /**
     * check if the user has access to the create url for this entity
     * @param {String} entity entity entity name you want to test (videos/quizzes/stories...)
     * @returns {boolean}
     */
    static hasCreateRights(entity, propName = null) {
        return this.getRightsFromActions(entity, 'create', propName);
    }

    /**
     * checks if the user has access to the update url for this entity
     * @param {String} entity entity entity name you want to test (videos/quizzes/stories...)
     * @returns {boolean}
     */
    static hasUpdateRights(entity, propName = null) {
        return this.getRightsFromActions(entity, 'update', propName);
    }

    /**
     * checks if the user has access to the delete url for this entity
     * @param {String} entity entity entity name you want to test (videos/quizzes/stories...)
     * @returns {boolean}
     */
    static hasDeleteRights(entity, propName = null) {
        return this.getRightsFromActions(entity, 'delete', propName);
    }

    /**
     * checks if the user has access to the getPropsForAll url for this entity
     * @param {String} entity entity entity name you want to test (videos/quizzes/stories...)
     * @returns {boolean}
     */
    static hasGetPropsForAllRights(entity, propName = null) {
        return this.getRightsFromActions(entity, 'getPropsForAll', propName);
    }

    /**
     * checks if the user has access to the a props GET url for this entity
     * @param {String} entity entity entity name you want to test (videos/quizzes/stories...)
     * @param {String} propName props name you want to test (comments/events/subscriptions...)
     * @returns {boolean}
     */
        static hasGetRightsForProp(entity, propName) {
            const { apiData: {ACTIONS} } = store.getState().actionSlugs;
            return !!ACTIONS[entity]?.['getProps']?.['get' + propName.charAt(0).toUpperCase() + propName.slice(1)];
        }
}