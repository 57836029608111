import React, { useState, useRef, useMemo } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';

import HelperMetiers360 from '../../../services/HelpersMetiers360';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState';
import GetMultiUsersOrContactsComponent from './GetMultiUsersOrContactsComponent';

/**
 * @param {string} entityType type of entity to add : "user" or "contact"
 * @example <CreateMultiUsersOrContactsComponent entityType="user" />
 */
const CreateMultiUsersOrContactsComponent = ({entityType, clientUniqueIdsSelected = [], selectedUniqueIdParentClientDefault=null}) => {
    const { apiData } = useStoreState(state => state.actionSlugs);
    const { allFormations} = useCustomGetStoreState("formations");
    const { allUsers } = useCustomGetStoreState("users");
    const { allClients } = useCustomGetStoreState("clients");

    const isEntityUser = entityType === "user";

    const { csrfToken } = apiData.ACTIONS['users'][isEntityUser ? 'create' : 'createContact'];
    const { createUser, createContact } = useStoreActions(actions => actions.users);
    const { fetchClientCRMById } = useStoreActions(actions => actions.clients);

    const entitiesRef = useRef(null);

    const [showMultiEntityComponent, setShowMultiEntityComponent] = useState(true);
    const [logSent, setLogSent] = useState([]);
    const [formationRequiredUniqueId, setFormationRequiredUniqueId] = useState("");
    const [isFormationRequired, setIsFormationRequired] = useState(false);
    const [currentSending, setCurrentSending] = useState(null);
    const [createUserAccessForContacts, setCreateUserAccessForContacts] = useState(false);
    const [formationForUserAccessForContacts, setFormationForUserAccessForContacts] = useState(false);

    const dataStructureTarget = [
        {field:'clientUniqueId', label:<>UniqueId client</>, csvIdx: "UniqueId client", 
            callBack:(value) => value?.replace(" ", "")},
        {field:'email', label:<>Email</>, csvIdx: "Email", 
            callBack:(value) => value?.toLowerCase().replace(" ", "").replace("\n", "")},
        {field:'firstName',label:<>Prénom</>, csvIdx: "Prénom"},
        {field:'lastName', label:<>Nom</>, csvIdx: "Nom"},
        {field:'job', label:<>Fonction</>, csvIdx: "Fonction"},
        {field:'phoneNumber', label:<>Téléphone</>, csvIdx: "Téléphone"},
        {field:'cellphoneNumber', label:<>Mobile</>, csvIdx: "Mobile"}
    ];

    const formationsAuthorized = useMemo(() => {
        if(isEntityUser) {
            if(entitiesRef?.current) {
                const usersClientsUniqueId = entitiesRef.current
                        ?.map((user) => user.clientUniqueId)
                        .filter((uniqueId, idx, array) => array.indexOf(uniqueId) === idx );

                return allFormations
                    ?.filter((formation)=> {
                        let clientsUniqueId = formation.clients?.map((client)=>{
                            return client.uniqueId;
                        })
                        return new Date(formation?.releaseDate) < new Date() 
                        || !formation?.releaseDate
                        || !formation?.private
                        || usersClientsUniqueId.reduce((acc, clientUniqueId) => 
                            acc && clientsUniqueId.includes(clientUniqueId), true)
                    });
            } else {
                return allFormations;
            }
        } else {
            return [];
        }
    }, [allFormations, entitiesRef?.current]);

    const handleIsFormationRequired = (isRequired) =>{
        setIsFormationRequired(isRequired);
    }

   const handleFormationRequiredUniqueId = (firstFormationUniqueId) =>{
        setFormationRequiredUniqueId(firstFormationUniqueId);
    }

    const onValidate = async () => {
        if(isFormationRequired == null){
            return;
        }
        setShowMultiEntityComponent(false);
        const doNextPromise = (key) => {
            const entity = entitiesRef.current[key];
            if(entity && isEntityUser) {
                const existingUser = allUsers.find(value => value.email === entity.email) ;
                if(existingUser) {
                    const correspondingClient = allClients.find(client => client.clientName === existingUser.clientName);
                    if(correspondingClient.uniqueId == entity.clientUniqueId) {
                        let newLogSent = logSent;
                        newLogSent.push({
                            email: entity.email,
                            isFailed: true,
                            msg:'Déjà dans la base'
                        });
                        setLogSent(newLogSent);
                    } else {
                        const msg = 'Déjà dans la base mais avec client '.concat(correspondingClient.clientName,' !')
                        let newLogSent = logSent;
                        newLogSent.push({
                            email: entity.email,
                            isFailed: true,
                            msg:msg
                        });
                        setLogSent(newLogSent);
                    }
                    key++;
                    if(key < entitiesRef.current.length) {doNextPromise(key)}
                    return;
                } else {
                    createEntity(entity)
                        .finally(() => {
                            setCurrentSending(null);
                            key++;
                            if(key < entitiesRef.current.length) {doNextPromise(key)}
                        });
                }
            } else if(entity && !isEntityUser) {
                fetchClientCRMById(entity.clientUniqueId).then((result) => {
                    const existingContact = result.contacts.find(value => value.email === entity.email) 
                    if(existingContact) {
                        let newLogSent = logSent;
                        newLogSent.push({
                            email: entity.email,
                            isFailed: true,
                            msg:'Déjà dans la base'
                        });
                        setLogSent(newLogSent);
                        key++;
                        if(key < entitiesRef.current.length) {doNextPromise(key)}
                        return;
                    } else {
                        createEntity(entity)
                            .finally(() => {
                                setCurrentSending(null);
                                key++;
                                if(key < entitiesRef.current.length) {doNextPromise(key)}
                            });
                    }
                });
            }
        };
        doNextPromise(0);
    };

    const createEntity = (entity) => {
        const formData = new FormData();
        formData.append('token', csrfToken);
        dataStructureTarget.forEach((value) => {formData.append(value.field, entity[value.field]);})

        if(isEntityUser) {
            formData.append('firstFormationRequired', isFormationRequired ? 'required' : '');
            if(isFormationRequired && formationRequiredUniqueId !== ""){
                formData.append('formationRequiredUniqueId', formationRequiredUniqueId);
            }
        }

        const payload =  {
            formData,
            uniqueId: entity.clientUniqueId,
        }
        setCurrentSending(entity.email);

        const createEntityEndpoint = isEntityUser ? createUser(payload) : createContact(payload);

        return createEntityEndpoint
            .then((entityCreated) => {
                // Create BO user access for contact created if checkbox checked
                if(!isEntityUser && createUserAccessForContacts) {
                    const formDataUserAccess = new FormData();
                    formDataUserAccess.append('token', apiData.ACTIONS['users']['create']?.csrfToken);
                    formDataUserAccess.append('firstFormationRequired', formationForUserAccessForContacts);

                    const contactUser = entityCreated?.contacts?.find(contact => contact.email == formData.get('email'));
                    return createUser({formData: formDataUserAccess, uniqueId: contactUser?.uniqueId})
                        .then(() => {
                            let newLogSent = logSent;
                            newLogSent.push({
                                email: entity?.email,
                                isOK: true,
                                msg: "Enregistré sur le serveur. Compte utilisateur créé."
                            });
                            setLogSent(newLogSent);
                        })
                        .catch(() => {
                            let newLogSent = logSent;
                            newLogSent.push({
                                email: entity?.email,
                                isFailed: true,
                                msg: "Enregistré sur le serveur. Création du compte utilisateur en erreur."
                            });
                            setLogSent(newLogSent);
                        })
                } else {
                    let newLogSent = logSent;
                    newLogSent.push({
                        email: entity?.email,
                        isOK: true,
                        msg: "Enregistré sur le serveur"
                    });
                    setLogSent(newLogSent);
                }
            })
            .catch(err => {
                let newLogSent = logSent;
                newLogSent.push({
                    email: entity.email,
                    isFailed: true,
                    msg:err
                });
                setLogSent(newLogSent);
            })
    }

    const logSentDisplay = logSent.map(log => 
        <ListGroupItem key={HelperMetiers360.generateUniqueId()}>
            Envoi de {log.email}&nbsp;
            {log.isOK && <span className="text-success icon-checkmark"> &nbsp;{log.msg}</span>}
            {log.isFailed && <span className="text-danger icon-cross"> &nbsp;{log.msg}</span>}
        </ListGroupItem>);

    const currentSendingDisplay = currentSending !== null
        && <ListGroupItem>Envoi en cours de {currentSending}&nbsp;
            <Spinner size="sm" animation="border" />
        </ListGroupItem>;

    return (
        <div>
            {showMultiEntityComponent && <GetMultiUsersOrContactsComponent 
                clientUniqueIdsSelected={clientUniqueIdsSelected}
                entityType={entityType}
                entitiesRef={entitiesRef}
                formationsAuthorized={formationsAuthorized}
                onValidate={onValidate} 
                onChangeIsFormationRequired={isEntityUser ? handleIsFormationRequired : null} 
                onChangeFormationRequiredUniqueId={isEntityUser ? handleFormationRequiredUniqueId : null}
                dataStructureTarget={dataStructureTarget}
                selectedUniqueIdParentClientDefault={selectedUniqueIdParentClientDefault}
                createUserAccessForContacts={createUserAccessForContacts}
                setCreateUserAccessForContacts={setCreateUserAccessForContacts} 
                setFormationForUserAccessForContacts={setFormationForUserAccessForContacts} />}
            {logSent.length > 0 && <ListGroup>{logSentDisplay}</ListGroup>}
            {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}
        </div>
    )
}

export default CreateMultiUsersOrContactsComponent;