
import './ActivitiesCard.scss';

const ActivitiesCard = ({activityName, activityData}) => {

	return(
		<div className="activities-card">
			<div className="activities-data">
				{activityData}
			</div>
			<div className="activities-name">
				{activityName}
			</div>
		</div>
	)
}

export default ActivitiesCard;