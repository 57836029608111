import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';

const playlistsInitialState = {
    allPlaylists: [],
    isFetchingPlaylists: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    playlistById: null,
    isFetchingOnePlaylist: false,
}

const playlists = {
    ...playlistsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allPlaylists: state.allPlaylists,
                    isFetchingPlaylists: state.isFetchingPlaylists,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    playlistById: state.playlistById,
                    isFetchingOnePlaylist: state.isFetchingOnePlaylist
                } = playlistsInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsFetchingPlaylists: action((state, payload) => {
        state.isFetchingPlaylists = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllPlaylists: action((state, payload) => {
        state.allPlaylists = payload;
    }),

    fetchAllPlaylists: thunk((actions, payload, helpers) => {
        const { isFetchingPlaylists, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getAllUrl = ACTIONS?.playlists?.readAll?.url;

        if (!isFetchingPlaylists && isUpdateNecessary) {
            actions.setIsFetchingPlaylists(true);
            return axios.get(getAllUrl)
                .then(data => {
                    actions.setAllPlaylists(data);
                    actions.setIsFetchingPlaylists(playlistsInitialState.isFetchingPlaylists);
                    actions.setIsUpdateNecessary(playlistsInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => {
                    Promise.reject(error);
                })
        } else {
            Promise.resolve();
        }
    }),

    // ACTIONS TO HYDRATE STORE INSTEAD OF REFETCHING LIST OF PLAYLISTS
    addPlaylist: action((state, payload) => {
        state.allPlaylists.push(payload);
    }),

    updateAllPlaylists: action((state, payload) => {
        const index = state.allPlaylists.findIndex((playlist) => playlist.uniqueId === payload.uniqueId);

        state.allPlaylists[index] = {
            ...state.allPlaylists[index],
            ...payload,
        };

    }),

    removeFromAllPlaylists: action((state, payload) => {
        const indexToRemove = state.allPlaylists.findIndex(playlist => playlist.uniqueId === payload);
        state.allPlaylists.splice(indexToRemove, 1);
    }),

    // handlingOnePlaylist
    setPlaylistById: action((state, payload) => {
        state.playlistById = payload;
    }),

    setIsFetchingOnePlaylist: action((state, payload) => {
        state.isFetchingOnePlaylist = payload;
    }),

    fetchPlaylistById: thunk((actions, payload, helpers) => {
        const { isFetchingOnePlaylist } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.playlists?.readOne?.url?.replace('uniqueId', payload);
        if (!isFetchingOnePlaylist) {
            actions.setIsFetchingOnePlaylist(true);
            actions.setPlaylistById(playlistsInitialState.playlistById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOnePlaylist(playlistsInitialState.isFetchingOnePlaylist);
                    actions.setPlaylistById(data);
                }
                )
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve();
        }
    }),

    // posting one playlist
    postPlaylist: thunk((actions, payload, helpers) => {
        const { setIsUpdateNecessary: setShouldUpdateHeadset } = helpers.getStoreActions().headsets;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const createPlaylistUrl = apiData.ACTIONS?.playlists?.create?.url;
        const csrfToken = apiData.ACTIONS?.playlists?.create?.csrfToken;
        const { formData } = payload;

        formData.set('csrfToken', csrfToken);

        return axios.post(createPlaylistUrl, formData)
            .then((result) => {
                actions.addPlaylist(result);
                actions.setPlaylistById(playlistsInitialState.playlistById)
                return result;
            }).catch(e => {
                Promise.reject(e);
            });
    }),

    // update one playlist
    updatePlaylist: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const {updatePlaylistPropOfHeadset } = helpers.getStoreActions().headsets;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const updatePlaylistUrl = apiData.ACTIONS?.playlists?.update?.url?.replace('uniqueId', uniqueId);
        const csrfToken = apiData.ACTIONS?.playlists?.update?.csrfToken;
        formData.set('csrfToken', csrfToken);

        return axios.post(updatePlaylistUrl, formData)
            .then((result) => {
                if(result){
                    actions.updateAllPlaylists(result.playlistToUpdate);
                    actions.setPlaylistById(result.playlistToUpdate);

                    if(result.playlistToUpdate?.headsets?.length > 0){
                        updatePlaylistPropOfHeadset({headsetsToUpdate: result.playlistToUpdate.headsets , playlistUniqueId: result.playlistToUpdate.uniqueId});
                    }
                    if(result.headsetsToRemove?.length > 0){
                        updatePlaylistPropOfHeadset({headsetsToUpdate: result.headsetsToRemove , playlistUniqueId: null})
                    }
                }
                return result;
            })
            .catch(error => {
                Promise.reject(error);
            })
    }),

    // deleting one playlist
    deletePlaylist: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { updatePlaylistPropOfHeadset } = helpers.getStoreActions().headsets;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const formData = new FormData();
        formData.append('csrfToken', apiData.ACTIONS?.playlists?.delete?.csrfToken)
        const deletePlaylistUrl = apiData.ACTIONS?.playlists?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.post(deletePlaylistUrl, formData)
            .then(result => {
                actions.setPlaylistById(playlistsInitialState.playlistById);
                actions.removeFromAllPlaylists(uniqueId);
                if(result?.headsetsToRemove?.length > 0){
                    updatePlaylistPropOfHeadset({headsetsToUpdate: result.headsetsToRemove , playlistUniqueId: null})
                }
            })
            .catch(error => {
                Promise.reject(error);
            });
    }),
};

export default playlists;