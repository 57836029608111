import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Row, Col, Card, ListGroup, Alert, Container, Button, Image } from 'react-bootstrap';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

import AuthorizationChecker from "../../../services/AuthorizationChecker";

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import { Link, useNavigate } from "react-router-dom";
import { useModal } from '../../../hooks/useModal';

import './PedagogicModuleDetailsComponent.scss';

const PedagogicModuleDetailsComponent = (props) => {
    const { pedagogicModule, deletePedagogicModule } = props;

    const pedagogiqueModuleSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    const {pingDownloadEvent} = useStoreActions(actions => actions.pedagogicModules);
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfStage: allStages } = BACKOFFICE_DEFAULT_VALUES;

    const { readOne: readOneVideo } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    const { readOne: readOneQuiz } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('quizzes'));
    const { readOne: readOneGroup } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('groups'));
    const { readOne: readOneFormation  } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('formations'));

    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const navigate = useNavigate();

    const tagList =
        <>
        {(pedagogicModule.tags && pedagogicModule.tags.length > 0) ? 
            pedagogicModule.tags.map((tag) => {
                return <span key={tag.uniqueId} className="badge badge-primary mr-1 p-2">{tag.name}</span>
            })
            :   <>
                <span className="badge badge-primary mr-1 p-2">Aucun tag </span>
                { AuthorizationChecker.isAdmin() && 
                <Link to="/tags"> Voir la liste des tags</Link>
                }
                </>

        }
        </>

    const isUsingVideo = (pedagogicModule.videos?.length > 0);
    const isUsingQuiz = (pedagogicModule.quizzes && pedagogicModule.quizzes.length > 0);
    const isRelatedToGroup = (pedagogicModule.groups && pedagogicModule.groups.length > 0);
    const isRelatedToFormation = (pedagogicModule.formations && pedagogicModule.formations.length > 0);
    const isRelatedToOther = isUsingVideo || isUsingQuiz || isRelatedToGroup || isRelatedToFormation;

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression du module pédagogique</>,
            content: <> 
                {isUsingVideo && 
                    <Alert variant="danger"> 
                        <h5>Ce module exploite <b>{pedagogicModule.videos.length}</b>  
                            &nbsp;{pedagogicModule.videos.length > 1 ? 'expériences immersives' : 'expérience immersive'}</h5>
                    </Alert>}
                {isUsingQuiz && 
                    <Alert variant="danger"> 
                        <h5>Ce module exploite <b>{pedagogicModule.quizzes.length}</b>  quiz</h5>
                    </Alert>}
                {isRelatedToGroup &&
                    <Alert variant="danger"> 
                        <h5>Ce module correspond à <b>{pedagogicModule.groups.length}</b> 
                            &nbsp;{pedagogicModule.groups.length > 1 ? 'espaces immersifs' : 'espace immersif'}</h5>
                    </Alert>}
                {isRelatedToOther ? 
                    <h5>Veuillez supprimer ces relations pour pouvoir continuer.</h5>
                    :<h5>Êtes-vous sûr⋅e de vouloir supprimer le module <b>{pedagogicModule.name}</b>&nbsp;?</h5>}
                </>,
            resetButton: 'Supprimer',
            cancelButton: !isRelatedToOther && 'Annuler',
            onReset: !isRelatedToOther ? 
                () => {
                deletePedagogicModule({ uniqueId: pedagogicModule.uniqueId })
                    .then(() => {
                        redirect(pedagogiqueModuleSlugs.readAll);
                    });
                }
                :null,
        });
        setModalShow(true);
    }

    const redirect = (url) => {
        navigate(url);
    }

    const onDownloadPdf = () => {
        pingDownloadEvent(pedagogicModule.uniqueId)
            .then(() => window.open(pedagogicModule.pdfFile));
    }

    return (
        <>
            <Container className="item_details">
                <Row>
                    <Col>
                        <Card className="item_details-card">

                            <Card.Body className="item_details-body">
                                {pedagogicModule?.links?.image && <Image className="item_details-thumbnail" src={pedagogicModule.links.image}/>}
                                {
                                    (AuthorizationChecker.hasUpdateRights('pedagogicModules') || AuthorizationChecker.hasDeleteRights('pedagogicModules'))
                                    && <div className="item_details_btn_actions">
                                        {AuthorizationChecker.hasUpdateRights('pedagogicModules') ?
                                            <Button variant="secondary" as={Link} to={pedagogiqueModuleSlugs.update.replace(':uniqueId', pedagogicModule && pedagogicModule.uniqueId)}>
                                                <i className="fas fa-edit"></i> &nbsp;Éditer
                                            </Button>
                                            : null}
                                        {AuthorizationChecker.hasDeleteRights('pedagogicModules') ?
                                            <Button variant="danger" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
                                            : null}
                                    </div>
                                }
                                <Card.Title className="item_details_card_title">
                                    {pedagogicModule && pedagogicModule.name ? pedagogicModule.name : "Détails de la story"}
                                </Card.Title>

                                <ListGroup>
                                    {pedagogicModule.releaseDate != null &&
                                        <ListGroup.Item>
                                            <strong>Publication :</strong> {HelperMetiers360.getdisplayDateType(pedagogicModule.createdAt, 'day')}
                                        </ListGroup.Item>}
                                    {pedagogicModule.lastUpdated != null &&
                                        <ListGroup.Item>
                                            <strong>Mise à jour :</strong> {HelperMetiers360.getdisplayDateType(pedagogicModule.updatedAt, 'day')}
                                        </ListGroup.Item>}
                                    {pedagogicModule.categories && pedagogicModule.categories?.length != 0 &&
                                        <ListGroup.Item><strong>Catégorie{pedagogicModule.categories?.length>1 && 's'} :&nbsp;</strong>
                                        {allCategories
                                            .filter(category => pedagogicModule.categories.includes(category.value))
                                            .map(category => category.label)
                                            ?.join(', ')}
                                        </ListGroup.Item>}
                                    {pedagogicModule.levels && pedagogicModule.levels?.length != 0 &&
                                        <ListGroup.Item><strong>Contexte{pedagogicModule.levels?.length>1 && 's'} :&nbsp;</strong>
                                        {allLevels
                                            .filter(level => pedagogicModule.levels.includes(level.value))
                                            .map(level => level.label)
                                            ?.join(', ')}
                                        </ListGroup.Item>}
                                    {pedagogicModule.stages && pedagogicModule.stages?.length != 0 &&
                                        <ListGroup.Item><strong>Étape{pedagogicModule.stages?.length>1 && 's'} :&nbsp;</strong>
                                        {allStages
                                            .filter(stage => pedagogicModule.stages.includes(stage.value))
                                            .map(stage => stage.label)
                                            ?.join(', ')}
                                        </ListGroup.Item>}
                                    {pedagogicModule.tools && pedagogicModule.tools?.length != 0 &&
                                        <ListGroup.Item><strong>Outil{pedagogicModule.tools?.length>1 ? 's':null} :&nbsp;</strong>
                                        {allTools
                                            .filter(tool => pedagogicModule.tools.includes(tool.value))
                                            .map(tool => tool.label)
                                            ?.join(', ')}
                                        </ListGroup.Item>}
                                    {pedagogicModule.duration != null && pedagogicModule.duration != 0 &&
                                        <ListGroup.Item><strong>Durée :&nbsp;</strong>
                                        {HelperMetiers360.formatDuration(pedagogicModule.duration)}
                                        </ListGroup.Item>}
                                    {'downloadCount' in pedagogicModule && AuthorizationChecker.isAdmin() &&
                                        <ListGroup.Item><strong>Nombre de téléchargements :&nbsp;</strong>
                                        {pedagogicModule.downloadCount ? pedagogicModule.downloadCount : "0"} 
                                        </ListGroup.Item>}
                                    {AuthorizationChecker.hasUpdateRights('pedagogicModules') && tagList &&
                                        <ListGroup.Item><strong>Tags :</strong>
                                            <ListGroup.Item>{tagList} </ListGroup.Item>
                                        </ListGroup.Item>}
                                </ListGroup>

                                <div className="item_details_description">
                                {pedagogicModule.goal && 
                                    <>
                                        <Card.Title className="item_details_description_title">
                                            Objectifs
                                        </Card.Title>
                                    <span className="text-muted" dangerouslySetInnerHTML={{__html: pedagogicModule.goal}}></span>
                                    </>}

                                    {pedagogicModule.pdfFile !== null &&
                                        <div className="mb-4 mt-2">
                                            <Button variant="primary" onClick={onDownloadPdf}> 
                                                <i className="fas fa-download"></i> &nbsp;Télécharger le PDF
                                            </Button>
                                        </div>
                                    }

                                { pedagogicModule.description && 
                                    <>
                                        <Card.Title className="item_details_description_title">
                                            Description
                                        </Card.Title>
                                        <span className="text-muted" dangerouslySetInnerHTML={{__html: pedagogicModule.description}}></span>
                                    </>}
                                </div>
                           

                                <div className="associated_card">
                                    { isRelatedToGroup &&
                                        <div className="associated_card_item">
                                            <>
                                            <Card.Title className="associated_card_title">
                                                    Espaces immersifs
                                            </Card.Title>
                                            <CardGroupMediaComponent mediaType="groups" mediaList={pedagogicModule.groups} readOne={readOneGroup} />
                                            </>
                                        </div>}
                                     { isUsingVideo &&
                                        <div className="associated_card_item">
                                            <>
                                            <Card.Title className="associated_card_title">
                                                    Expériences immersives
                                            </Card.Title>
                                            <CardGroupMediaComponent mediaType="video" mediaList={pedagogicModule.videos} readOne={readOneVideo} />
                                            </>
                                        </div>}
                                    { isUsingQuiz &&
                                        <div className="associated_card_item">
                                            <>
                                            <Card.Title className="associated_card_title">
                                                Quiz
                                            </Card.Title>
                                            <CardGroupMediaComponent mediaType="quiz" mediaList={pedagogicModule.quizzes} readOne={readOneQuiz} />
                                            </>
                                        </div>} 
                                        { isRelatedToFormation &&
                                        <div className="associated_card_item">
                                            <>
                                            <Card.Title className="associated_card_title">
                                               Webinaires
                                            </Card.Title>
                                            <CardGroupMediaComponent mediaType="groups" mediaList={pedagogicModule.formations} readOne={readOneFormation} />
                                            </>
                                        </div>}
                                </div>
                             </Card.Body>
                        </Card>
                        </Col>
                        </Row>
                {modalComponent}
            </Container>
        </>
    );
}
export default PedagogicModuleDetailsComponent;