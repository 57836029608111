import { useEffect, useMemo, useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Loader from '../widgets/Loader';
import PropTypes from 'prop-types';
import { Editor } from "@tinymce/tinymce-react";
import ButtonLoaderComponent from '../widgets/ButtonLoaderComponent.jsx';
import { useNavigate } from "react-router-dom";
import { useToast } from "../../hooks/useToast.jsx";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";
import "./WelcomeMessageFormComponent.scss";

registerLocale('fr', fr);


const WelcomeMessageFormComponent = ({ existingMessage = null }) => {

  const { fetchUnavailableDates, createMessage, updateMessage } = useStoreActions(actions => actions.welcomeMessage);

  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});
  const [includeButton, setIncludeButton] = useState(!!existingMessage?.button?.label);

  const [unavailableDates, setUnavailableDates] = useState();
  const [newMessage, setNewMessage] = useState(existingMessage ? { ...existingMessage } : {});

  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState();

  const [titleLenght, setTitleLenght] = useState(existingMessage?.title?.length ?? 0);
  const [contentLength, setContentLength] = useState();

  const messageSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("welcomeMessage"));

  const navigate = useNavigate();
  const toast = useToast();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setErrors((prev) => ({...prev, date: null }));
  };

  const today = new Date();

  useEffect(() => {
    fetchUnavailableDates(existingMessage?.uniqueId ?? "")
      .then((res) => {
        const intervals = res?.map((interval) => {
          return ({
            start: new Date(interval.startDate?.date),
            end: new Date(interval.expirationDate?.date),
          })
        });
        setUnavailableDates(intervals ?? []);
      })
  }, []);

  const firstAvailableDate = useMemo(() => {
    if (existingMessage?.startDate?.date) {
      return existingMessage?.startDate?.date;
    } else if (unavailableDates?.length) {
      today.setHours(0, 0, 0, 0);
      const sortedIntervals = unavailableDates?.sort((a, b) => new Date(a.start) - new Date(b.start));
      let firstAvailable = today;
      for (const interval of sortedIntervals) {
        const start = new Date(interval.start);
        const end = new Date(interval.end);
        end.setDate(end.getDate() + 1);
        if (firstAvailable < start) {
          break;
        }
        if (end > firstAvailable) {
          firstAvailable = end;
        }
      }
      return firstAvailable;
    } else {
      return today;
    }
  }, [unavailableDates]);

  useEffect(() => {
    setStartDate(firstAvailableDate);
    setEndDate(existingMessage?.expirationDate?.date ?? firstAvailableDate)
  }, [firstAvailableDate]);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (name === "category" && value === "") {
      setNewMessage({ ...newMessage, [name]: null });
      return;
    }
    if (name === "title") {
      setTitleLenght(value.length);
    }
    setNewMessage({ ...newMessage, [name]: value });
  }

  const handleEditorChangeContent = (content, editor) => {
    setErrors({});
    const wordCount = editor.plugins.wordcount;
    const textCount = wordCount.body.getCharacterCount();
    setContentLength(textCount);
    setNewMessage({ ...newMessage, content: content });
  }

  const handleButtonChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setNewMessage({ ...newMessage, button: { ...newMessage.button, [name]: value } });
  }

  const validateForm = () => {
    let result = true;
    setErrors({});
    let errorsList = {};
    if (!newMessage.title) {
      errorsList.title = "Merci d'indiquer un titre";
      result = false;
    }
    if (!newMessage.content) {
      errorsList.content = "Merci d'indiquer un message";
      result = false;
    }
    if (!startDate || !endDate) {
      errorsList.date = "Merci d'indiquer une date de début et de fin";
      result = false;
    }
    if (includeButton && !newMessage?.button?.link?.match(/^(https?:\/\/|www\.)[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?\/?.*$/)) {
      errorsList.button = "Entrez un lien valide";
      result = false;
    }
    if (includeButton && (!newMessage?.button?.label || !newMessage?.button?.link)) {
      errorsList.button = "Merci d'indiquer un intitulé et un lien si vous souhaitez inclure un bouton";
      result = false;
    }
    if (contentLength > 255) {
      errorsList.content = "Le message est trop long";
      result = false;
    }
    if (newMessage.title?.length > 50) {
      errorsList.title = "Le titre est trop long";
      result = false;
    }
    setErrors(errorsList);
    return result;
  }

  const formatDateAsISODate = (date) => {
    const d = new Date(date);
    const yyyy = d.getFullYear();
    const mm = ('0' + (d.getMonth() + 1)).slice(-2);
    const dd = ('0' + d.getDate()).slice(-2);
    return `${yyyy}-${mm}-${dd}`;
  }

  const handleClick = () => {
    const isValidated = validateForm();

    if (isValidated) {
      setIsSending(true);
      const messageData = {
        ...newMessage,
        startDate: formatDateAsISODate(startDate),
        expirationDate: formatDateAsISODate(endDate)
      }
      if (!includeButton) {
        messageData.button = null;
      }
      if (existingMessage) {
        updateMessage(messageData)
          .then((res) => {
            setIsSending(false);
            toast.open({message: "Message modifié", variant: "success"});
            navigate(messageSlugs.readAll);
          })
          .catch((error) => {
            setIsSending(false);
          })
      } else {
        createMessage(messageData)
          .then((res) => {
            setIsSending(false);
            toast.open({message: "Message créé", variant: "success"});
            navigate(messageSlugs.readAll);
          })
          .catch((error) => {
            setIsSending(false);
          })
      }
    }
  }


  return (
    <>
      {unavailableDates ?
        <div>
          <label htmlFor='title' className='mr-2'>Titre en interne (non affiché aux utilisateurs)</label>
          <input type="text" name='title' id='title' defaultValue={newMessage.title} onChange={handleChange} style={{ minWidth: "40em" }}></input>
          {!!titleLenght && <span className='ml-2 textLimit' style={{ color: titleLenght > 50 ? "red" : "black" }}>{titleLenght}/50</span>}
          {errors?.title && <p className='text-danger'>{errors.title}</p>}
          <hr />
          <label className='mr-2'>Dates d'affichage </label>
          {startDate !== undefined ?
            <DatePicker
              locale='fr'
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
              excludeDateIntervals={unavailableDates}
              selectsRange
              selectsDisabledDaysInRange
            />
            : <Loader />}
          {errors?.date && <p className='text-danger'>{errors.date}</p>}
          <label htmlFor='category' className='mr-2 ml-2'>Catégorie</label>
          <select
            id="category"
            className="select-category"
            multiple={false}
            onChange={handleChange}
            name="category"
            defaultValue={existingMessage?.category || ''}
          >
            <option value=''>--</option>
            <option value='danger'>Alerte</option>
            <option value='warning'>Avertissement</option>
            <option value='info'>Info</option>
          </select>
          <hr />
          <label>Texte</label>
          {!!contentLength && <span className='ml-2 textLimit' style={{ color: contentLength > 255 ? "red" : "black" }}>{contentLength}/255</span>}
          <Editor
            name="content"
            apiKey={process.env.REACT_APP_TINYMCE_KEY}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'emoticons', 'textcolor', 'wordcount'
              ],
              toolbar:
                'undo redo | bold italic underline | forecolor backcolor | \
                emoticons | removeformat',
            }}
            onEditorChange={handleEditorChangeContent}
            initialValue={existingMessage ? existingMessage.content : ' '}
          />
          {errors?.content && <p className='text-danger'>{errors.content}</p>}
          <hr />
          <Row className="mt-3">
            <Col>
              <Form.Check
                type="checkbox"
                onChange={() => { setIncludeButton((prev) => !prev); setErrors({}) }}
                defaultChecked={includeButton}
                label="Inclure un bouton d'action"
                id="includeButton"
              />
            </Col>
          </Row>
          {includeButton &&
            <>
              <label htmlFor='label' className='mr-2'>Intitulé</label>
              <input type="text" name='label' id='label' value={newMessage?.button?.label} onChange={handleButtonChange}></input>
              <label htmlFor='link' className='mr-2 ml-2'>Lien</label>
              <input type="text" name='link' id='link' value={newMessage?.button?.link} onChange={handleButtonChange}></input>
              {errors?.button && <p className='text-danger'>{errors.button}</p>}
            </>}
          <ButtonLoaderComponent
            className='mt-4'
            variant={existingMessage ? "secondary" : "success"}
            onClick={handleClick}
            msg={existingMessage ? "Éditer" : "Sauvegarder"}
            iconClassName="fas fa-save"
            isSending={isSending}
            disabled={isSending}
          />
        </div>

        : <Loader />
      }
    </>
  )
}

WelcomeMessageFormComponent.propTypes = {
  existingMessage: PropTypes.object,
};

export default WelcomeMessageFormComponent