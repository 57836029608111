import React from 'react';

import { Image, Card, Alert } from 'react-bootstrap';


const QuizPresentationComponent = ({quiz}) => {
    return (
            <>
                <Card className="quiz_presentation_elem">
                    <Card.Header as={"h3"} > Introduction </Card.Header>
                        <Card.Body>
                            <div className='intro-text-section'>
                                {quiz.introText ?
                                    <div
                                    dangerouslySetInnerHTML={{__html: quiz.introText}}/>
                                    : <Alert variant="danger" className="null-content">Texte d'introduction non renseigné</Alert>
                                }
                            
                            </div>
                            <div className='intro-background'>
                                {quiz.links.introBackground ?
                                    <Image rounded fluid src={quiz.links.introBackground}/>
                                    : <Alert variant="danger" className="null-content">Pas d'image de fond enregistrée</Alert>
                                }
                            </div>
                        </Card.Body>
                </Card>

                <Card className="quiz_presentation_elem">
                    <Card.Header as={"h3"} > Outro </Card.Header>
                        <Card.Body>
                            <div className='outro-text-section'>
                                {quiz.outroText ?
                                    <div 
                                    dangerouslySetInnerHTML={{__html: quiz.outroText}}/>
                                    : <Alert variant="danger" className="null-content">Texte Outro non renseigné</Alert>
                                }
                            </div>
                            <div className='outro-background'>
                                {quiz.links.outroBackground ?
                                    <Image rounded fluid src={quiz.links.outroBackground}/>
                                    : <Alert variant="danger" className="null-content">Pas d'image de fond enregistrée</Alert>
                                }
                            </div>
                        </Card.Body>
                </Card>
            </>
    )
}

export default QuizPresentationComponent;