import React from 'react';
import DynamicTable from '../widgets/dynamicTable/DynamicTable.jsx';
import PropTypes from 'prop-types';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';
import AuthorizationChecker from '../../services/AuthorizationChecker.js';
import { useToast } from '../../hooks/useToast.jsx';
import { useModal } from '../../hooks/useModal.jsx';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Alert, Button } from 'react-bootstrap';
import ClientStatusFormComponent, { submitClientStatusForm } from './ClientStatusFormComponent.jsx';

/**
 * 
 * @param {Array} clientStatusToDisplay array of all clientStatus to be rendered
 
 * @example
 *  <ClientStatusTableComponent clientStatusToDisplay={clientStatus} />
 * 
 */

const ClientStatusTableComponent = ({clientStatusToDisplay = [], withParams}) => {
    const {allClientStatus} = useStoreState(state => state.clientStatus);
    const {updateClientStatus, deleteClientStatus} = useStoreActions(actions => actions.clientStatus);
    const toast = useToast();
    const {modalComponent, setModalShow, setModalData, modalData, setIsSending} = useModal();

    const openEditModal = (clientStatus) => {
        const handleSubmit = () => {
            setIsSending(true);
            submitClientStatusForm({clientStatus, allClientStatus, action: updateClientStatus})
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }
        
        setModalData({
            ...modalData,
            header: <>Edition du statut client</>,
            content: <ClientStatusFormComponent clientStatus={clientStatus} />,
            cancelButton : 'Annuler',
            onValidate: handleSubmit,
            size: 'xl', 
        });
        setModalShow(true);
    }

    const openDeleteModal = (clientStatus) => {
        setModalData({
            ...modalData,
            header: <>Suppresion du statut client</>,
            content: <>
                <h5>
                    Êtes vous sûr⋅e de vouloir supprimer le statut client<strong> {clientStatus.label}</strong> &nbsp;?
                </h5>
                {clientStatus.countClients > 0 &&
                    <Alert variant='danger'>
                        <strong>Attention :</strong> Le statut sera retiré de <strong>{clientStatus.countClients}</strong> clients !
                    </Alert>}
            </>,
            resetButton: 'Supprimer',
            cancelButton: 'Annuler',
            onReset: () => {
                deleteClientStatus({uniqueId : clientStatus.uniqueId})
                    .then(() => {
                        toast.success();
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            },
        });
        setModalShow(true);
    }

    const sortType = [
        {
            value: 'label', label: 'Nom',
            test:  HelperMetiers360.isArrayContainsValue(clientStatusToDisplay, "label"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (clientStatus) => clientStatus.label
        },
        {
            value: 'countClients', label: 'Occurence',
            test: HelperMetiers360.isArrayContainsValue(clientStatusToDisplay, "countClients"),
            method: (a, b) => a.countClients-b.countClients,
            display: (clientStatus) => clientStatus.countClients
        },
        {
            value: 'edit', label: 'Éditer',
            test: AuthorizationChecker.hasUpdateRights('clientStatus'),
            display: (clientStatus) => <Button variant="secondary" size="sm" onClick={() => {openEditModal(clientStatus)}}>
                <i className="fas fa-edit"></i>
            </Button>
        },
        {
            value: 'delete', label: 'Supprimer',
            test: AuthorizationChecker.hasDeleteRights('clientStatus'),
            display: (clientStatus) => <Button variant="danger" size="sm" onClick={() => {openDeleteModal(clientStatus)}}>
                <i className="fas fa-trash-alt"></i>
            </Button>
        }
    ];

    return <>
        <DynamicTable
            contentTable={clientStatusToDisplay}
            contentSort={sortType}
            valueInitSort="label"
            index='uniqueId'
            withParams={withParams}
        />
        {modalComponent}
    </>
}

ClientStatusTableComponent.propTypes = {
    clientStatusToDisplay: PropTypes.array.isRequired
};
export default ClientStatusTableComponent;