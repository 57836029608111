import React from "react";
import DynamicTable from "../../widgets/dynamicTable/DynamicTable";
import HelperMetiers360 from "../../../services/HelpersMetiers360";

const OrderLinesTableComponent = ({
    orderLines
}) => {
  

    const sortType = orderLines?.length > 0 ?
    [
        {
            value : 'name', label : 'Nom',
            test : HelperMetiers360.isArrayContainsValue(orderLines, "name"),
            method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (product) => product.name? product.name : '-'
        },
        {
            value : 'quantity', label : 'Quantité',
            test : HelperMetiers360.isArrayContainsValue(orderLines, "quantity"),
            method : (a, b) =>   b.quantity  - a.quantity,
            display: (product) => product.quantity  ? product.quantity : '-'
        }
    ]
    :null;

    return sortType && <DynamicTable
        contentTable = {orderLines}
        contentSort = {sortType}
        valueInitSort = "name"
        index = 'uniqueId'
        filename="contenu_bon" />;
};

export default OrderLinesTableComponent;