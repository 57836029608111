import React from 'react';
import HelperMetiers360 from '../../../../services/HelpersMetiers360';
import { useStoreActions } from 'easy-peasy';
import AuthorizationChecker from "../../../../services/AuthorizationChecker";

const VideoFlatComponent = (props) => {
    const { video } = props;
    const formatVideoSource = video.videoSource.split('.').pop();
    const formatAltVideoSource = video.altVideoSource ? video.altVideoSource.split('.').pop():null;
    const {addVideoLength} = useStoreActions(actions => actions.videos);

    const handleMetadata = (e) => {
        if (!video.videoLength && AuthorizationChecker.isAdmin()) {
            const payload = { uniqueId: video.uniqueId, videoLength: e.target.duration };
            addVideoLength(payload);
        }
    };

    return (
        <video width="100%" controls onLoadedMetadata={handleMetadata}>
            {HelperMetiers360.checkVideoFormatSupport(formatVideoSource) && <source src={video.videoSource} type={"video/".concat(formatVideoSource)} />}
            {formatAltVideoSource && HelperMetiers360.checkVideoFormatSupport(formatAltVideoSource)
                && <source src={video.altVideoSource} type={"video/".concat(formatAltVideoSource)} />}
            Votre navigateur ne supporte pas les différents .
        </video>
    )
}

export default VideoFlatComponent;