import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from "react-router-dom";
import Loader from "../../components/widgets/Loader";
import VideoDetailsComponent from "../../components/videos/videoDetails/VideoDetailsComponent";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";


import "./VideoDetailPage.scss";

const VideoDetailPage = (props) => {
    const {uniqueId} = useParams();
    
    // Video state variables + actions
    const { videoById } = useStoreState(state => state.videos);
    const {fetchVideoById} = useStoreActions(actions => actions.videos);
    
    const { allStories, isUpdateNecessary } = useCustomGetStoreState("stories")
    const { allPedagogicModules, isFetchingAllPedagogicModules} = useCustomGetStoreState("pedagogicModules")
    const videoSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
        
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const [ videoStories, setVideoStories ] = useState([]);
    const [ videoPedagogicModules, setVideoPedagogicModules ] = useState([]);

    useEffect(() => {
        setIsMounted(true);

        if (!isLoading) {
            setIsLoading(true);
            fetchVideoById(uniqueId)
                .finally(() => setIsLoading(false));
        }

        return () => setIsMounted(false);
	}, []);

    useEffect(() => {
        if (videoById) {
            setVideoStories(allStories
                .filter(story => (
                    story.fromVideoUniqueId === videoById.uniqueId
                )))
        }
    }, [allStories, videoById])

    useEffect(() => {
        if (videoById) {
            setVideoPedagogicModules(allPedagogicModules
                .filter(pedagogicModule => (
                    pedagogicModule.videos
                .some(video => video.uniqueId === videoById.uniqueId)
                )))
        }
    }, [allPedagogicModules, videoById])

    const returnLink = videoSlugs.readAll;

    return (!videoById || isLoading || !isMounted)
        ? <Loader />
        : <VideoDetailsComponent
            video={videoById}
            videoStories={videoStories}
            isFetchingStories={isUpdateNecessary}
            isFetchingAllPedagogicModules={isFetchingAllPedagogicModules}
            videoPedagogicModules={videoPedagogicModules}
            returnLink={returnLink}
        />
}

export default VideoDetailPage;