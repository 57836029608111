import { useStoreState } from 'easy-peasy';
import React, { useState } from 'react';
import {Form, InputGroup, Button, Row} from 'react-bootstrap';

import ButtonLoaderComponent from '../../../../widgets/ButtonLoaderComponent.jsx';

import './WebappMenuForm.scss';
import { useToast } from '../../../../../hooks/useToast.jsx';

const WebappMenuFormComponent = ({client, onValidate, onDelete}) => {
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const toast = useToast();

	const { webappMenuItems: WEBAPP_MENU_ITEMS } = BACKOFFICE_DEFAULT_VALUES;

	const [formErrors, setFormErrors] = useState({});
	const [isSending, setIsSending] = useState(false);

	const {csrfToken, formName} = ACTIONS['clients']['editProps']['editWebappMenu'];


    const deleteWebappMenu = () => {
		onDelete()
			.then(() => {
				resetFormValue();
			})
			.catch(error => {
				setFormErrors(error['errors']);
			});
		
	}

    const resetFormValue = () => {
		let form = document.getElementById('form-webapp-menu');
		form.reset();
	}

  

    const checkData = () => {
		let formData = new FormData(document.getElementById('form-webapp-menu'));
		let name = formData.get('webapp_menu[name]');

		if (!name) {
			setFormErrors({
				name: 'Veuillez saisir un nom pour ce menu'
			});
			return true;
		}

		setFormErrors({});
		return false;
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		const isError = checkData();
		if (!isError) {
			setIsSending(true);
			onValidate()
				.then(() => toast.success())
				.catch(error => {
					setFormErrors(error['errors']);
				})
                .finally(() => setIsSending(false));
		}
	}

    return (
        <div className='form-webapp-menu'>
			<div className='webapp-menu-information'>
				<p>Menu actuel :
					<span className='webapp-menu-name'> {client.webappCustomisation.webappMenu ? client.webappCustomisation.webappMenu.name : "Menu par défaut"} </span>
					{client.webappCustomisation.webappMenu && !client.webappCustomisation.fromParent &&
						<Button variant='danger' size="sm" onClick={deleteWebappMenu}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
					}
				</p>
			</div>

			<Form id='form-webapp-menu' onSubmit={handleSubmit}>

				<Row>
					<InputGroup>
						<InputGroup.Text>Nom du menu</InputGroup.Text>
						<Form.Control
							placeholder="Exemple: Menu version freemium..."
							name={`${formName}[name]`}
							isInvalid={formErrors.name !== undefined}
							defaultValue={client.webappCustomisation.webappMenu ? client.webappCustomisation.webappMenu.name : ''}
						/>
						<Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
					</InputGroup>
				</Row>

				<Row className='webapp-menu-items-row'>
                    <div className='webapp-menu-items-row-check'>
					{WEBAPP_MENU_ITEMS.map( (item) => {
						let checked = false;

						if (client.webappCustomisation.webappMenu && client.webappCustomisation.webappMenu.items.some(webappMenuItem => webappMenuItem.id === item.id)) {
							checked = true;
						} else if (!client.webappCustomisation.webappMenu) { // we want all the checkboxes checked if it's the default menu
							checked = true;
						}

						return <Form.Check
                            // inline
							type='checkbox'
							id={'webapp_menu_webapp_menu_items' + item.id}
							name={`${formName}[webappMenuItems][]`}
							value={item.id}
							label={<p>{item.name}</p>}
							defaultChecked={checked}
							key={item.id}
						/>
					})}
                    </div>
				</Row>

				<Form.Group >
					<Form.Control
						name={`${formName}[_token]`}
                        value={csrfToken}
						type="hidden"
					/>
				</Form.Group>

				<ButtonLoaderComponent 
					isSending={isSending} 
					type="submit" 
					variant='success' 
					block={true}
                    size='sm' 
					className="mt-4 mx-auto d-flex"
					msg={<><i className="fas fa-check me-2 text-white"/> Valider</>}
					disabled={isSending}
				/>

			</Form>
		</div>
    )
}

export const submitMenuForm = (actionFn, clientId, menuId) => {
	let formData = new FormData(document.getElementById('form-webapp-menu'));
	return actionFn({formData, uniqueId: clientId, webappUniqueId: menuId});
}

export const deleteMenuForm = (deleteWebappMenu, clientId, webappMenuUniqueId, csrfToken) => {
	const formData = new FormData();

	formData.set('token', csrfToken);

	const payload =  {
		uniqueId: clientId,
		webappMenuUniqueId,
		formData,
	}

	return deleteWebappMenu(payload)
}

export default WebappMenuFormComponent;