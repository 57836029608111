import axios from 'axios';

import {store} from '../store/index.js';

export const URLS = {
    BASE_URL: process.env.REACT_APP_URL,
    API_URL: process.env.REACT_APP_API_URL
}

const instance = axios.create({
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    }
})

instance.defaults.withCredentials = true;

// POSSIBILITY TO ADD AN INTERCEPTORS ON EVERY REQUEST TO ADD SOME LOGIC
instance.interceptors.request.use((config) => {
    if (!config.url.includes(URLS.API_URL))
        config.url = URLS.API_URL + config.url;
    return config;
}, (error) => {
    return Promise.reject(error);
})


// INTERCEPTOR ADDED ON THE RESPONSE TO RESHAPE THE RESPONSE
instance.interceptors.response.use((response) => {
    if (response.data)
        return response.data;
    return response;
}, async (error) => {
    const status = error.response?.status;
    const setShouldCheckAuth = store.getActions().auth.setShouldCheckAuth;
    const isLogged = store.getState().auth.isLogged;
    const pages = store.getState().actionSlugs.pages;
    const errorSlug = pages?.find((page) => page.id === 'error').slug;
    const setPath = store.getActions().navigation.setPath;
    const addToast = store.getActions().toasts.addToast;

    if (axios.isCancel(error)) {
        return Promise.reject(error)
    }

    // Display api error message to user if expected
    if(typeof error?.response?.data?.displayErrorToUser == "string") {
        addToast({ 
            message: error?.response?.data?.displayErrorToUser,
            variant: "danger" 
        });
    } else {
        switch (status) {
            case 400:
                addToast({ 
                    message: "ERREUR : La requête que vous avez envoyée est incorrecte",
                    variant: "danger" 
                });
                break;
            case 401:
                if (isLogged) {
                    setShouldCheckAuth(true)
                    addToast({ 
                        message: "ERREUR : Vous n'êtes pas autorisé⋅e à accéder à cette ressource",
                        variant: "danger" 
                    });
                }
                break;
            case 403:
                addToast({ 
                    message: "ERREUR : Vous n'avez pas les droits pour accéder à cette ressource",
                    variant: "danger" 
                });
                break;
            case 406:
                addToast({ 
                    message: "ERREUR : Il est impossible de servir une réponse qui satisfait les critères définis",
                    variant: "danger" 
                });
                break;
            case 422:
                addToast({ 
                    message: "ERREUR : La requête que vous avez envoyée comprend des erreurs de validation",
                    variant: "danger" 
                });
                break;
            case 404:
                setPath(errorSlug.replace(':errorCode',status));
                break;
            case 500:
                addToast({ 
                    message: "ERREUR : Une erreur inattendue s'est produite côté serveur",
                    variant: "danger" 
                });
                break;
            default:
                addToast({ 
                    message: "ERREUR : Une erreur inattendue s'est produite",
                    variant: "danger" 
                });
                break;
        }
    }

    return Promise.reject(error.response?.data);
})

export default instance;