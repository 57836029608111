import React, { useState, useEffect } from "react";
import { Container, Row, Col, Alert, Tabs, Tab } from "react-bootstrap";

import SelectItemComponent from "../widgets/selectItem/SelectItemComponent.jsx";
import SelectedMediaListComponent from "../widgets/SelectedMediaList/SelectedMediaListComponent.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import Loader from "../widgets/Loader.jsx";

import './SelectItemGroupComponent.scss';

const SelectItemGroupComponent = (props) => {
    const {groupToUpdate, initSelectItems, onSelectItem, isSpecialGroupCreated = false} = props;
    const {allGroups} = useCustomGetStoreState("groups")

    const {allVideos} = useCustomGetStoreState("videos");
    const {allQuizzes} = useCustomGetStoreState("quizzes");

    const [selectedItems, setSelectedItems] = useState(initSelectItems);

    useEffect(() => {
        setSelectedItems(initSelectItems);
    }, [initSelectItems])

    const [videos, setVideos] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [groups, setGroups] = useState([]);

    const isSpecialGroup = isSpecialGroupCreated || groupToUpdate?.isSpecial;

	useEffect(() => {
        setVideos(allVideos
            .filter((video) => selectedItems.length ? ! selectedItems.map((item) => item.uniqueId).includes(video.uniqueId):true)
            .map((video) => {
                return {
                    uniqueId:video.uniqueId,
                    name:video.videoName, 
                    description:video.description, 
                    thumbnail:video.links.thumbnail, 
                    type:'video'}
                }))
	}, [allVideos,selectedItems]);
	useEffect(() => {
        setQuizzes(allQuizzes
            .filter((quiz) => selectedItems.length ? ! selectedItems.map((item) => item.uniqueId).includes(quiz.uniqueId):true)
            .map((quiz) => {
                return {
                    uniqueId:quiz.uniqueId,
                    name:quiz.name, 
                    thumbnail:quiz.links.thumbnail,
                    type:'quiz'}
                }))
	}, [allQuizzes,selectedItems]);
	useEffect(() => {
        setGroups(allGroups
            .filter((group) => group.idHeadset !== 0 && (!groupToUpdate || group.idHeadset != groupToUpdate.idHeadset))
            .filter((group) => selectedItems.length ? ! selectedItems.map((item) => item.uniqueId).includes(group.uniqueId):true)
            .map((group) => {
                return {
                    uniqueId:group.uniqueId,
                    name:group.name, 
                    thumbnail:group.links.thumbnail, 
                    type:'group'}
                }))
	}, [allGroups,selectedItems]);

    useEffect(() =>{onSelectItem(selectedItems)},[selectedItems])

    const onSelectVideo = (selectedUniqueId) => {
        if (!isSpecialGroup) {
        setSelectedItems([...selectedItems,...videos.filter((video) => video.uniqueId === selectedUniqueId)]);
        setVideos([
            ...videos.filter((video) => video.uniqueId !== selectedUniqueId)
        ]);
        }
    }
    const onSelectQuiz = (selectedUniqueId) => {
        if (!isSpecialGroup) {
            setSelectedItems([...selectedItems,...quizzes.filter((quiz) => quiz.uniqueId === selectedUniqueId)]);
            setQuizzes([
                ...quizzes.filter((quiz) => quiz.uniqueId !== selectedUniqueId)
            ]);
        }
    }
    const onSelectGroup = (selectedUniqueId) => {
        setSelectedItems([...selectedItems,...groups.filter((group) => group.uniqueId === selectedUniqueId)]);
        setGroups([
            ...groups.filter((group) => group.uniqueId !== selectedUniqueId)
        ]);
    }
    const onClickItem = (selectedUniqueId) => {
        const selectedItem = selectedItems.filter((item) => item.uniqueId === selectedUniqueId)[0];
        setSelectedItems([...selectedItems.filter((item) => item.uniqueId !== selectedUniqueId)]);
        switch (selectedItem.type) {
            case 'video':
                setVideos([...videos, selectedItem]);
                break;
            case 'quiz':
                setQuizzes([...quizzes, selectedItem]);
                break;
            case 'group':
                setGroups([...groups, selectedItem]);
                break;
            default:
                break;
        }
    }


    return <Container>
        <Row>
            <Col className="draggable-list-group mb-4">

                {selectedItems.length !== 0 ? <>Cliquer sur un élément pour l'enlever de la liste / drag'n'drop pour modifier l'ordre des éléments</>:null}
                {selectedItems.length === 0 ?
                    <>Cliquer sur les éléments ci-contre que vous souhaitez inclure
                                <Alert className="mt-3 d-flex align-items-center justify-content-center" variant="danger">
                                    <i className="fas fa-shopping-basket m-4" style={{fontSize: "6rem"}}/>
                                </Alert>
                            </>
                        : <SelectedMediaListComponent
                            draggable={true}
                            mediaType={"videos"}
                            mediaList={selectedItems} 
                            onClickItem={onClickItem} 
                            setList={setSelectedItems}
                            />}

            </Col>
        </Row>
        <Row>
            <Tabs
                defaultActiveKey="videos"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                <Tab eventKey="videos" title="Expériences immersives" mountOnEnter unmountOnExit>
                    {isSpecialGroup ? 
                        <Alert variant="warning">Ceci est un groupe automatique, il n'est pas possible d'y ajouter des expériences immersives manuellement.</Alert>
                    : allVideos.length === 0 ?
                        <Loader />
                        : (<SelectItemComponent list={videos} onSelect={onSelectVideo} mediaType="video" displayType="cards" />) }
                </Tab>
                <Tab eventKey="quiz" title="Quiz" mountOnEnter unmountOnExit>
                { isSpecialGroup ? 
                    <Alert variant="warning">Ceci est un groupe automatique, il n'est pas possible d'y ajouter des quizzes manuellement.</Alert>
                    : 
                allQuizzes.length === 0 ?
                    <Loader />
                    : (<SelectItemComponent list={quizzes} onSelect={onSelectQuiz} mediaType="quiz" displayType="cards" />) }
                </Tab>
                <Tab eventKey="groups" title="Groupes" mountOnEnter unmountOnExit>
                {allGroups.length === 0 ?
                    <Loader />
                    : (<SelectItemComponent list={groups} onSelect={onSelectGroup} mediaType="group" displayType="cards" />) }
                </Tab>
            </Tabs>
        </Row>
        </Container>;
}

export default SelectItemGroupComponent;