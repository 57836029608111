import { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { Link, useLocation, matchPath } from "react-router-dom";

import { Breadcrumb } from "react-bootstrap";
import AuthorizationChecker from "../services/AuthorizationChecker";
import HelperMetiers360 from "../services/HelpersMetiers360";

const skipPath = [];

const useBreadCrumbs = () => {
    const location = useLocation();
    const {pages} = useStoreState(state => state.actionSlugs);
    const {currentEntityName} = useStoreState(state => state.breadcrumbs)
    const [matches, setMatches] = useState([])
    const isAdmin = AuthorizationChecker.isAdmin();

    const isEditOrCreate = location.pathname.includes("/edit") || location.pathname.includes("/create")

    useEffect(() => {
        const matchesArray = [];
        pages?.length > 0 && pages.forEach((page) => {
            if (('slug' in page)) {
                if(page.id){
                        // Add intermediate breadcrumb if is part of routes group
                        if(page.menuItem && page.menuItem == location.pathname?.split("/")[1]) {
                            const homePageOfRoutesGroup = pages
                                .filter(p => p.activeRoutes)
                                .find(p => p.activeRoutes.some(activeRoute => activeRoute === page.menuItem));
                            
                            if(homePageOfRoutesGroup && !matchesArray.some(m => m.label == homePageOfRoutesGroup.labelNav)) {
                                const path = homePageOfRoutesGroup.slug.includes('/') ? homePageOfRoutesGroup.slug : '/'+homePageOfRoutesGroup.slug;

                                let matchPreviousRoute = matchPath({
                                    path: path,
                                    end: false
                                }, path);

                                if(matchPreviousRoute) {
                                    matchPreviousRoute.active = false;
                                    matchPreviousRoute.label = homePageOfRoutesGroup.labelNav;
                                    matchesArray.push(matchPreviousRoute);
                                }
                            }
                        }

                        const match = matchPath({
                            path: page.slug,
                            end: false,
                        }, location.pathname)

                        if (match && match.params.uniqueId !== "create" && !skipPath.includes(page.slug) ) {
                            match.active = (location.pathname === match.pathname)
                            
                            // match.label = page.labelBreadcrumb ? page.labelBreadcrumb : page.labelNav;
                            match.label = computeLabel(page);
                            match.icone = page.icone ? page.icone : "";
                            match.storeName = page.menuItem;
                            matchesArray.push(match)
                        }
                }
            }
        })
        setMatches(matchesArray)
    }, [location, pages, currentEntityName]);
        

    const computeLabel = (page) => {
        if (page.action === 'readOne') {
            return currentEntityName
        }

        if (page.menuItem === 'catalogue') {
            return 'Catalogue'
        }

        if(!page.labelBreadcrumb && !page.labelNav) {
            const pageInCommon = pages.find(menuPage => menuPage.activeRoutes?.includes(page.slug));
            return pageInCommon?.labelBreadcrumb ?? pageInCommon?.labelNav;
        }

        return page.labelBreadcrumb ?? page.labelNav;
    }

    const breadcrumbComponent = <Breadcrumb>
        { matches.length > 0 && matches.map(match => {
                return <Breadcrumb.Item
                    key={match.pathname+'-'+HelperMetiers360.generateUniqueId()}
                    active={match.active || !isAdmin}
                    linkAs={Link}
                    linkProps={{
                        to: match.pathname,
                        replace: match.active ? false : isEditOrCreate,
                        state: {fromEdit: isEditOrCreate}
                    }}>
                        {match.label}
            </Breadcrumb.Item> 
        })}
    </Breadcrumb>

    return {breadcrumbComponent};
}

export default useBreadCrumbs;