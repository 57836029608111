import React, {useState,  useEffect} from "react";
import {Form} from "react-bootstrap";

const RadioTypeWidget = (props) => {
    const {allTypes, typesName, defaultType} = props;
    const [checkedValue, setCheckedValue] = useState("");

    const handleChangeCheckedValue = (value) => {
            setCheckedValue(value);
    }

    const setLabelAndIcon = (name, label) => {
        return <><i className={name} ></i> {label}</>
    }


    const typeCheck = 
    <>
    {allTypes?.map(type => 
        <Form.Check   
            className={(checkedValue == type.value || (defaultType && type.value == defaultType) ) ? "selected-filter": ""}
            type="radio"
            name={typesName}
            label={setLabelAndIcon(type.icon, type.label)}
            id={`type-`.concat(type.value)}
            value={type.value}
            key={'type-'.concat(type.value)}
            defaultChecked={defaultType && type.value == defaultType }
            onChange= {(e) => handleChangeCheckedValue(e.target.value)}
        />
        )}
    </>

    return {typeCheck, checkedValue , handleChangeCheckedValue}
}

export default RadioTypeWidget