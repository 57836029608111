import React, {useState, useEffect} from "react";
import { Container, Form, Row} from "react-bootstrap";
import { useStoreState } from 'easy-peasy';
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import RadioTypeWidget from "../../../../components/widgets/radioType/RadioTypeWidget.jsx";



const PeadgogicModuleSearchViewComponent = (props) => {
    const {admin, presentTypeElements, setLevelLabelAsTitle , allPedagogicModules, setFilteredPedagogicModules, search} = props;

    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;
    
    const [pedagogicModulesCategories, setPedagogicModulesCategories] = useState([]);
  
    const {typeCheck:typeCheckLevels, checkedValue:checkedLevel, handleChangeCheckedValue:handleChangeCheckedValueLevel} = RadioTypeWidget({
        typesName: 'levels',
        allTypes:presentTypeElements.levels
    }); 

    const {typeCheck:typeCheckTools, checkedValue:checkedTool, handleChangeCheckedValue:handleChangeCheckedValueTool} = RadioTypeWidget({
        typesName: 'tools',
        allTypes:presentTypeElements.tools
    }); 

    const {typeCheck:typeCheckCategories, checkedValue:checkedCategory, handleChangeCheckedValue:handleChangeCheckedValueCategory} = RadioTypeWidget({
        typesName: 'categories',
        allTypes:pedagogicModulesCategories
    }); 

    const {typeCheck:typeCheckStages, checkedValue:checkedStage, handleChangeCheckedValue:handleChangeCheckedValueStage} = RadioTypeWidget({
        typesName: 'stages',
        allTypes:presentTypeElements.stages
    }); 

    useEffect(() => {
        let categories = presentTypeElements?.categories;
        
        if(!admin){
            categories = categories?.filter((cat)=>{
                return (cat.value == allCategories[2].value || cat.value == allCategories[3].value);
            });
        }

        categories = categories?.map((cat)=>{
            let iconName;
            if(cat.value == allCategories[2].value || cat.value == allCategories[3].value){
                iconName = cat.value == allCategories[2].value ? "category-logo fas fa-cube" : "category-logo fas fa-cubes";
            }
            return {value: cat.value, 
                   label : cat.pedagogicModuleClientLabel ? cat.pedagogicModuleClientLabel : cat.label ,
                   icon : iconName ? iconName : null };
         });

         setPedagogicModulesCategories(categories);
	}, [presentTypeElements?.categories]);

    useEffect(() => {
        setFilteredPedagogicModules(
            [...allPedagogicModules.filter(pedagogicModule => 
                (HelperMetiers360.isSearchInText(search, pedagogicModule.name)
                    || HelperMetiers360.isSearchInText(search, pedagogicModule.description)
                    || HelperMetiers360.isSearchInText(search, pedagogicModule.goal))
                && (!checkedLevel || checkedLevel=='' || pedagogicModule.levels !== undefined && pedagogicModule.levels.includes(checkedLevel))
                && (!checkedTool || checkedTool=='' || pedagogicModule.tools !== undefined && pedagogicModule.tools.includes(checkedTool))
                && (!checkedCategory || checkedCategory=='' || pedagogicModule.categories !== undefined && pedagogicModule.categories.includes(checkedCategory))
                && (!checkedStage || checkedStage=='' || pedagogicModule.stages !== undefined && pedagogicModule.stages.includes(checkedStage))
            )]
        );
	}, [allPedagogicModules, search, checkedCategory, checkedLevel, checkedStage, checkedTool]);
   



    useEffect(() => {
        let levelSelected = presentTypeElements?.levels?.find((level)=>{
            return level.value == checkedLevel;
        })
        setLevelLabelAsTitle(levelSelected?.label);
	}, [checkedLevel]);


    return (
        <>
        <Container fluid>
        {presentTypeElements?.levels?.length > 0 &&
            <Row  className="side-filter-element" >
                <span as="legend" className="side-filter-element-title" >
                    Contexte d'exploitation
                </span>
                <Form.Check 
                  className={checkedLevel == "" ? "selected-filter": ""}  
                  type="radio"
                  name="levels"
                  label="Tous les contextes"
                  id="type-all-levels"
                  value=""
                  key="type-all-levels"
                  checked={checkedLevel == ""}
                  onChange= {(e) => handleChangeCheckedValueLevel(e.target.value)}
                />
                <Form.Group as={Row} >
                   {typeCheckLevels}
                </Form.Group>
            </Row>
            }
            {presentTypeElements?.tools?.length > 0 && admin &&
            <Row  className="side-filter-element" >
                <span as="legend" className="side-filter-element-title" >
                    Outils
                </span>
                <Form.Check 
                  className={checkedTool == "" ? "selected-filter": ""}  
                  type="radio"
                  name="tools"
                  label="Toutes les outils"
                  id="type-all-tools"
                  value=""
                  key="type-all-tools"
                  checked={checkedTool == ""}
                  onChange= {(e) => handleChangeCheckedValueTool(e.target.value)}
                />
                <Form.Group as={Row} >
                   {typeCheckTools}
                </Form.Group>
            </Row>
            }
            {presentTypeElements?.stages?.length > 0 &&
            <Row  className="side-filter-element" >
            <Form.Group as={Row} >
                 <span as="legend" className="side-filter-element-title" >
                     Que souhaitez-vous travailler ?
                 </span>
                 <Form.Check  
                  className={checkedStage == "" ? "selected-filter": ""}   
                  type="radio"
                  name="stages"
                  label="Toutes les étapes"
                  id="type-all-stages"
                  value=""
                  key="type-all-stages"
                  checked={checkedStage == ""}
                  onChange= {(e) => handleChangeCheckedValueStage(e.target.value)}
                  />
                  {typeCheckStages}
                </Form.Group>
            </Row>
            }
            {presentTypeElements?.categories?.length > 0 &&
            <Row  className="side-filter-element" >
                <Form.Group as={Row} >
                   <span as="legend" className="side-filter-element-title" >
                       Type de module
                    </span>
                   <Form.Check   
                    className={checkedCategory == "" ? "selected-filter": ""}   
                    type="radio"
                    name="categories"
                    label="Tous les types"
                    id="type-all-categories"
                    value=""
                    key="type-all-categories"
                    checked={checkedCategory == ""}
                    onChange= {(e) => handleChangeCheckedValueCategory(e.target.value)}
                   />
                   {typeCheckCategories}
                </Form.Group>
            </Row>
            }
        </Container>
        </>
        );
}
export default PeadgogicModuleSearchViewComponent;