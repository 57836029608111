import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Form, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useStoreState, useStoreActions } from "easy-peasy";
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import CustomSelectSingleComponent from "../../widgets/CustomSelectComponent/CustomSelectSingleComponent";
import './formOneProComponent.scss'

const FormOneProComponent = (props) => {
    const { id, romeAppellations, selectedRomes,romeAppellationsFetched=false, pro = null } = props;
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { 
        allAreasOfInterest,
        allWorkingConditions,
        allSkills,
        isFetchingAllAreasOfInterest,
        isFetchingAllWorkingConditions,
        isFetchingAllSkills 
    } = useCustomGetStoreState("romes");
    const { fetchAllAreasOfInterest, fetchAllWorkingConditions, fetchAllSkills } = useStoreActions(actions => actions.romes);
    useEffect(() => {
        Promise.all([
            allAreasOfInterest.length === 0 && !isFetchingAllAreasOfInterest && fetchAllAreasOfInterest(),
            allWorkingConditions.length === 0 && !isFetchingAllWorkingConditions && fetchAllWorkingConditions(),
            allSkills.length === 0 && !isFetchingAllSkills && fetchAllSkills()            
        ])
    }, [])

    const [selectedJobs, setSelectedJobs] = useState(pro?.jobAppellations?.map(jobAppellation => jobAppellation.code) ?? []);
    const [selectedMainJob, setSelectedMainJob] = useState(pro?.mainJob ? [pro.mainJob.code]:[] );
    const [selectedAreasOfInterest, setSelectedAreasOfInterest] = useState(pro?.areasOfInterest?.map(areaOfInterest => areaOfInterest.code) ?? []);
    const [selectedWorkingConditions, setSelectedWorkingConditions] = useState(pro?.workingConditions?.map(workingCondition => workingCondition.code) ?? []);
    const [selectedSkills, setSelectedSkills] = useState(pro?.softSkills?.map(softSkill => softSkill.code) ?? []);
    const [visible,setVisible] = useState(pro === null);

    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const [thumbnailFileInputName, setThumbnailFileInputName] = useState('Choisir le fichier');
    
    const allAppellations = useMemo(() => 
        Object.keys(romeAppellations)
            .map(key => romeAppellations[key])
            .reduce((accumulator, romeAppellations) => accumulator.concat(romeAppellations),[])
    , [romeAppellations]);

    const allMainJobAppellations = useMemo(() => 
        Object.keys(romeAppellations)
            .filter(key => selectedRomes.includes(key))
            .map(key => romeAppellations[key])
            .reduce((accumulator, romeAppellations) => accumulator.concat(romeAppellations),[])
    , [romeAppellations, selectedRomes]);

    // Reset selected  main job if code ROME selected is removed
    useEffect(() => {
        romeAppellationsFetched &&
            setSelectedMainJob(prevJobs => prevJobs.filter(job => allMainJobAppellations.some(appellation => appellation.code === job)));
    }, [allMainJobAppellations, romeAppellationsFetched]);

    return <Container className="mt-3 mb-3">
        <hr />
        <Row className="mt-4">
            <div className="d-flex justify-content-between me-3 clickable" onClick={() => setVisible(!visible)}>
                <h4 className="w-auto">
                    {pro?.name ?? 'Nouveau⋅lle professionnel⋅le'}
                </h4>
                {visible 
                    ? <i className="fa fa-angle-up fa-lg"></i>
                    : <i className="fa fa-angle-down fa-lg"></i>
                }
            </div>
        </Row>
        <div className={!visible ? "d-none" : null}>
            <Row>
                <Col>
                    <Form.Control name={`${id}[uniqueId]`} defaultValue={pro?.uniqueId ?? null} type="hidden" />
                    <Form.Group className="formSection">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            name={`${id}[name]`}
                            defaultValue={pro?.name ?? null}
                            placeholder="Entrer le nom du pro"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="formSection">
                        <Form.Label>Photo de profil ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
                        <div className="input-group mb-4">
                            <input
                                type="file"
                                className="custom-file-input"
                                id={`${id}[thumb]`}
                                name={`${id}[thumb]`}
                                accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
                                onChange={(event) => { setThumbnailFileInputName(event.target.files[0].name) }}
                            />
                            <label className="custom-file-label" htmlFor={`thumb_${id}`} data-browse="Parcourir">
                                {thumbnailFileInputName}
                            </label>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="formSection d-flex flex-column">
                        <Form.Label>Métier principal</Form.Label>
                        <CustomSelectSingleComponent 
                            selectId={`${id}[mainJob]`}
                            options={allMainJobAppellations}
                            nameSelect={`${id}[mainJob]`}
                            selectedOptions={selectedMainJob}
                            setSelectedOptions={setSelectedMainJob}
                            optionLabel="label"
                            valueLabel="code"
                            />
                    </Form.Group>

                    <Form.Label>Métiers sélectionnés :</Form.Label>
                    {selectedJobs.length === 0 && selectedMainJob.length === 0 && <p className="ms-3">- Aucun métier sélectionné -</p>}
                    <ul>
                        {Object.keys(romeAppellations)
                            .map(key => romeAppellations[key]
                                .filter(appellation => selectedMainJob[0] === appellation.code)
                                .map(appellation => 
                                    <li key={`${key}_${appellation.code}`}><b>{appellation.label}</b> ({key})</li>)
                        )}
                        {Object.keys(romeAppellations)
                            .map(key => romeAppellations[key]
                                .filter(appellation => selectedJobs.some(job => job === appellation.code))
                                .map(appellation => 
                                    <li key={`${key}_${appellation.code}`}>{appellation.label} ({key})</li>)
                        )}
                    </ul>
                </Col>
                <Col>
                    <Form.Group className="formSection d-flex flex-column">
                        <Form.Label>Autres métiers</Form.Label>
                        <SelectMultipleItemsWithSearchBar
                            idSelector={`${id}[appellations][]`}
                            allItems={allAppellations}
                            selectedItemKeys={selectedJobs} setSelectedItemKeys={setSelectedJobs}
                            itemKey="code" itemValue="code" itemValueDescription="label"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="formSection d-flex flex-column">
                        <Form.Label>Centres d'intérêt</Form.Label>
                        <SelectMultipleItemsWithSearchBar
                            idSelector={`${id}[areasOfInterest][]`}
                            allItems={allAreasOfInterest}
                            selectedItemKeys={selectedAreasOfInterest} setSelectedItemKeys={setSelectedAreasOfInterest}
                            itemKey="code" itemValue="label"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="formSection d-flex flex-column">
                        <Form.Label>Savoir-être professionnel</Form.Label>
                        <SelectMultipleItemsWithSearchBar
                            idSelector={`${id}[softSkills][]`}
                            allItems={allSkills}
                            selectedItemKeys={selectedSkills} setSelectedItemKeys={setSelectedSkills}
                            itemKey="code" itemValue="code" itemValueDescription="label"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="col-6">
                    <Form.Group className="formSection d-flex flex-column">
                        <Form.Label>Contexte de travail</Form.Label>
                        <SelectMultipleItemsWithSearchBar
                            idSelector={`${id}[workingConditions][]`}
                            allItems={allWorkingConditions}
                            selectedItemKeys={selectedWorkingConditions} setSelectedItemKeys={setSelectedWorkingConditions}
                            itemKey="code" itemValue="code" itemValueDescription="label"
                        />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    </Container>;
}
FormOneProComponent.propTypes = {
    id: PropTypes.string.isRequired,
    romeAppellations: PropTypes.any,
    pro: PropTypes.object,
    selectedRomes:PropTypes.array
};
export default FormOneProComponent;