import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useStoreState } from 'easy-peasy';
import Loader from "../../../../widgets/Loader.jsx";
import { SelectStoryTableComponent } from "./SelectStoryTableComponent.jsx";
import useCustomGetStoreState from "../../../../../hooks/useCustomGetStoreState.jsx";

/**
 *
 * Story whiteListing form component for the client
 *
 * @param {object} client info object of the client concerned by the form
 * @example
 *          <WhiteListStoryFormComponent client={client} />
 */

const WhiteListStoryFormComponent = ({ client, actionName }) => {

	const { allStories, isFetchingStories } = useCustomGetStoreState("stories")
	const {apiData} = useStoreState(state => state.actionSlugs)
    
    const {formName, csrfToken} = apiData.ACTIONS['clients']['editProps'][actionName];

	const [selectedStories, setSelectedStories] = useState(
		client && client.storyList ? client.storyList.map(String) : [],
	);

	return (
		<Form
			id={`${formName}-form`}
			method='post'
			encType='multipart/form-data'
		>
			<Row>
				<Form.Group as={Col}>
					<Form.Group className='mb-5'>
						<h3>Story(whitelist)</h3>
					</Form.Group>
					<Form.Group>
						<Form.Check type='hidden' id={`${formName}_storyListIsRestrictive`}>
							<Form.Check.Input
								name={`${formName}[storyListIsRestrictive]`}
								type='hidden'
								value='1'
								defaultChecked={true}
							/>
						</Form.Check>
					</Form.Group>
					{isFetchingStories ? <Loader /> : null}
					<SelectStoryTableComponent
						stories={allStories}
						selectedStories={selectedStories}
						setSelectedStories={setSelectedStories}
						isLoading={isFetchingStories}
						formName={formName}
					/>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Control
					name={`${formName}[_token]`}
					defaultValue={csrfToken}
					type='hidden'
				/>
			</Form.Group>
		</Form>
	);
};

export const submitStoryForm = (formId, updateAction, uniqueId, formName) => {
	const formData = new FormData(
		document.getElementById(formId),
	);

	if (!formData.has(`${formName}[storyList][]`)) {
		return;
	}

	return updateAction({formData, uniqueId});
};

export const resetStoryform = (formId, updateAction, uniqueId, formName) => {
	const formData = new FormData(
		document.getElementById(formId),
	);

	formData.delete(`${formName}[storyListIsRestrictive]`);
	formData.delete(`${formName}[storyList][]`);

	return updateAction({formData, uniqueId});
};


export default WhiteListStoryFormComponent;

