import React, { useEffect } from "react";
import { Row, ToggleButtonGroup, Col, ToggleButton } from "react-bootstrap";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import { useStoreActions } from "easy-peasy";
import PropTypes from 'prop-types';
import AuthorizationChecker from "../../services/AuthorizationChecker.js";

const RomesFilters = ({ romes, setFilteredRomes, viewStyle, setViewStyle, viewStyleType }) => {

    const setViewStylePreference = useStoreActions(actions => actions.preferences.setCodeRomesListContent);
    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (code ROME, nom, riasec majeur...)', delay: 400 });

    useEffect(() => {
        setFilteredRomes(romes
            ?.filter(rome => !search
                || HelperMetiers360.isSearchInText(search, rome.codeRome)
                || HelperMetiers360.isSearchInText(search, rome.label)
                || HelperMetiers360.isSearchInText(search, HelperMetiers360.riasecLabel(rome.riasecMajeur))
                || HelperMetiers360.isSearchInText(search, HelperMetiers360.riasecLabel(rome.riasecMineur))
            )
        );
    }, [search, romes]);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    return <Row className="item-page">
        <Col>
            {searchInput}
        </Col>
        {AuthorizationChecker.isAdmin() && <Col className="col-auto">
            <ToggleButtonGroup type="radio" name="option-view-videos" value={viewStyle} onChange={handleChange}>
                {checkViewStyle}
            </ToggleButtonGroup>
        </Col>}
    </Row>;
}

RomesFilters.propTypes = {
    romes: PropTypes.array.isRequired,
    setFilteredRomes: PropTypes.any.isRequired,
    viewStyle: PropTypes.string.isRequired,
    setViewStyle: PropTypes.any.isRequired,
    viewStyleType: PropTypes.array.isRequired,
};

export default RomesFilters;