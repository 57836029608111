import './QuizPlayAnswers.scss'

const QuizPlayAnswers = ({answers, clickHandler, checkedAnswerId, valid, sondage}) => {
   

    const isIdInArray = checkedAnswerId.includes(answers.uniqueId)

    return (
        <>
        <div className={isIdInArray ? "answerButton-active" : "answerButton"} onClick={clickHandler}>
            {answers.caption}   
                {valid && answers.isTrue && !sondage ? <i className="fas fa-solid fa-check"></i> : ""}
                {valid && !answers.isTrue && !sondage ? <i className="fas fa-times"></i> : ""}

        </div>
        </>
    );
};

export default QuizPlayAnswers;