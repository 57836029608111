import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const tagOperationsInitialState = {
    allTagOperations: [],
    isFetchingAllTagOperations: false,
    isUpdateNecessary: true,
    initialFetchDone: false,
}

const tagOperations = {
    ...tagOperationsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allTagOperations: state.allTagOperations,
                    isFetchingAllTagOperations: state.isFetchingAllTagOperations,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone
                } = tagOperationsInitialState);
            }
        }
    ),

    setAllTagOperations: action((state, payload) => {
        state.allTagOperations = payload;
    }),

    setIsFetchingAllTagOperations: action((state, payload) => {
        state.isFetchingAllTagOperations = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    fetchTagOperations: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { isFetchingAllTagOperations, isUpdateNecessary } = helpers.getState();

        const getTagOperationsUrl = ACTIONS.tagOperations?.readAll?.url;
        if (!isFetchingAllTagOperations && isUpdateNecessary) {
            actions.setIsFetchingAllTagOperations(true);
            return axios.get(getTagOperationsUrl)
                .then((result) => {
                    actions.setAllTagOperations(result);
                    actions.setIsFetchingAllTagOperations(tagOperationsInitialState.isFetchingAllTagOperations);
                    actions.setIsUpdateNecessary(false);
                    return result;
                })
                .catch(error => Promise.reject(error));
        } else {
            return Promise.resolve();
        }
    }),

    addTagOperation: action((state, payload) => {
        for (const element of payload) {
            const existingTag = state.allTagOperations.find(operation => operation?.uniqueId === element.uniqueId);
            if (existingTag === undefined) {
                state.allTagOperations.push(element);
            }
        }
    }),

    createTagOperation: thunk((actions, payload, helpers) => {
        const data = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const createTagOperationUrl = ACTIONS?.tagOperations?.create?.url;

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(createTagOperationUrl, data, options)
            .then((result) => {
                actions.addTagOperation(result);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    updateTagsOperationsProps: thunk((actions, payload, helpers) => {
        const allTagOperations = helpers.getState().allTagOperations;

        const newAllTagOperations = allTagOperations
            .map(tagOpe => {
                const newTagOpe = payload?.find(newTagOpe => tagOpe.uniqueId === newTagOpe.uniqueId);
                return { ...tagOpe, ...newTagOpe }
            });

        actions.setAllTagOperations(newAllTagOperations);
    }),

    updateTagOperation: thunk((actions, payload, helpers) => {
        const { data, uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const updateTagOperationUrl = ACTIONS?.tagOperations?.update?.url?.replace('uniqueId', uniqueId);

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(updateTagOperationUrl, data, options)
            .then((result) => {
                actions.updateTagsOperationsProps(result);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    removeTagOperation: action((state, payload) => {
        state.allTagOperations = state.allTagOperations.filter(operation => operation?.uniqueId !== payload);
    }),

    deleteTagOperation: thunk((actions, payload, helpers) => {
        const { uniqueId, data } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const deleteTagOperationUrl = ACTIONS?.tagOperations?.delete?.url?.replace('uniqueId', uniqueId);

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(deleteTagOperationUrl, data, options)
            .then((result) => {
                actions.removeTagOperation(uniqueId);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

}

export default tagOperations;