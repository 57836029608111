import React, {useState, useRef, useEffect} from "react";
import {Form} from "react-bootstrap";

const SelectTypeWidget = (props) => {
    const {allTypes, label, formError, elementType, defaultValue, id} = props;
    const [selectedValue, setSelectedValue] = useState(null);
    const [isInvalid, setIsInvalid] = useState(false);
    const typeSelectorRef = useRef(null);

    const handleChange = () => {
        setSelectedValue([...typeSelectorRef.current.options].find(option => option.selected).value);
    }

    useEffect(() => {
       if(formError){
         if(elementType in formError){
            setIsInvalid(true)
         }
         else{
            setIsInvalid(false)
         }
       }
    }, [formError]);

    useEffect(() => {
        if(defaultValue){
            setSelectedValue(defaultValue);
        }
     }, [defaultValue]);
    

    const typeSelector = <Form.Select ref={typeSelectorRef} isInvalid={isInvalid} onChange={handleChange} defaultValue={defaultValue} id={id}>
        <option value='all'>{label}</option>
        {allTypes?.map(type => <option key={'level-'.concat(type.value)} defaultValue={selectedValue == type.value } value={type.value}>{type.label}</option>)}
    </Form.Select>;

     return {typeSelector, selectedValue, setSelectedValue}
}

export default SelectTypeWidget;