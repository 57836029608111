import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import './CardInteractionComponent.scss';
import iconInteractionTypes from '../../../routerUtils/iconInteractionTypes';
import AxonautLogoBubbleComponent from '../../widgets/axonautLogoBubble/AxonautLogoBubbleComponent';
import { useModal } from '../../../hooks/useModal';
import { useStoreActions } from 'easy-peasy';
import PropTypes from 'prop-types';
import { useToast } from '../../../hooks/useToast';

const contactsM360Interaction = (uniqueId, interactionContactsM360) => 
    interactionContactsM360?.length > 0 && 
        <div className='contactsM360-interaction'>
            { interactionContactsM360?.map(contactM360 => 
                <OverlayTrigger placement="top" key={ 'contactM360-'+uniqueId+'-'+contactM360.name }
                    overlay={<Tooltip>{contactM360.name}</Tooltip>}>
                    <div className='contactM360-bubble cursor-default'>{ contactM360.name?.substring(0, 1)?.toUpperCase() }</div>
                </OverlayTrigger>
            )}
    </div>;

const typeInteraction = (interactionType) => 
    <div className='type-interaction'>
        <i className={ iconInteractionTypes[interactionType?.value] ?? "fas fa-question" }></i>
        <p>{ interactionType?.name }</p>
    </div>;

const CardInteractionComponent = (props) => {
    const { interaction, setOpenFormType, setInteractionToEdit, editable = true, 
        setModalCallingShow = null, disableIsDone = false } = props;

    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const { removeClientInteraction, updateClientInteraction } = useStoreActions(actions => actions.interactions);
    const toast = useToast();

    const contactsList = interaction.contacts?.length > 0
        ? interaction.contacts?.map(contact => 
                contact.firstName || contact.lastName
                    ? HelperMetiers360.capitalize(contact.firstName) + " " + contact.lastName?.toUpperCase()
                    : contact.email)
            .join(', ')
        : "";

    const openFormEditInteraction = () => {
        setOpenFormType('edit');
        setInteractionToEdit(interaction);
    }

    const openModalRemoveInteraction = () => {
        const validate = () => {
            removeClientInteraction({ uniqueId: interaction.uniqueId })
                .then((result) => {
                    toast.open({message: "Interaction supprimée avec succès", variant: "success"});
                    if(setModalCallingShow) setModalCallingShow(false);
                });
        };

        setModalData({
            ...modalData,
            header: "Suppression d'une interaction",
            content: <h5>Êtes-vous sûr⋅e de vouloir supprimer cette interaction ?</h5>,  
            cancelButton: "Annuler",
            resetButton: "Supprimer",
            onReset: validate,
            id: 'remove-interaction-modal'
        });
        setModalShow(true);
    }

    const interactionDone = () => {
        let newInteraction = {
            isDone: true
        };

        const timestampNow =  Math.round(+new Date()/1000);
        if(interaction.timestamp > timestampNow) {
            newInteraction.timestamp = timestampNow;
        }
        updateClientInteraction({ interaction: newInteraction, uniqueId: interaction.uniqueId });
        if(setModalCallingShow) setModalCallingShow(false);
    }

    return <div className='d-flex w-100'>
        { interaction.idAxonaut && 
            <div className='axonaut-logo-interaction'>
                <AxonautLogoBubbleComponent />
            </div>
        }
        <div className={'card-interaction shadow-element ' + (interaction.idAxonaut ? 'axonaut-card' : 'non-axonaut-card')}>
            { contactsM360Interaction(interaction.uniqueId, interaction.contactsM360) }
            <Row className='d-flex align-items-top justify-content-between'>
                <Col className='col-9 d-flex align-items-baseline'>
                    { interaction.flow === 1 && <span className="icon-interaction-flow-1 me-2 mb-0"></span>}
                    { interaction.flow === 2 && <span className="icon-interaction-flow-2 me-2 mb-0"></span>}
                    <p className='title-interaction'>{ interaction.title }</p> 
                </Col>
                { interaction.isDone === false && 
                    <Col className='d-flex justify-content-end'>
                        <div className={(interaction.idAxonaut || disableIsDone)
                            ? 'todo-interaction-disable'
                            : 'todo-interaction clickable'}
                            onClick={() => !disableIsDone && !interaction.idAxonaut && interactionDone()}
                            onKeyDown={() => !disableIsDone && !interaction.idAxonaut && interactionDone()}></div>
                    </Col>}
            </Row>
            
                <p className='contacts-interaction'>
                    { contactsList.length > 0 && 
                        <><span>Contacts client : </span>
                        { contactsList }</>
                    }
                </p>
            
            <div dangerouslySetInnerHTML={{__html: interaction.content}} className='d-flex flex-column content-interaction display-linebreak'></div>
            <Row className='footer-interaction'>
                <Col className='ps-0'>
                    <p className='date-interaction'>{ HelperMetiers360.timestampToDisplayDate(interaction.timestamp) }</p>
                </Col>
                <Col className='d-flex justify-content-end'>
                    { typeInteraction(interaction.type) }
                </Col>
            </Row>
        </div>
        { interaction.idAxonaut === null &&
            <div>
                { editable && 
                    <div className='btn-edit-interaction' onClick={() => openFormEditInteraction()}
                        onKeyDown={() => openFormEditInteraction()}>
                        <i className="fas fa-edit"></i>
                    </div>}
                <div className='btn-delete-interaction' onClick={() => openModalRemoveInteraction()}
                    onKeyDown={() => openModalRemoveInteraction()}>
                    <i className="fas fa-trash-alt"></i>
                </div>
            </div>
        }
        { modalComponent }
    </div>;
}

CardInteractionComponent.propTypes = {
    interaction: PropTypes.object.isRequired,
    setOpenFormType: PropTypes.any,
    setInteractionToEdit: PropTypes.any,
    editable: PropTypes.bool,
    setModalCallingShow: PropTypes.any,
    disableIsDone: PropTypes.bool,
}

export default CardInteractionComponent;
