import { action, thunk, actionOn } from 'easy-peasy';
import HelperMetiers360 from '../services/HelpersMetiers360';

const toastsInitialState = {
    toastsToDisplay: []
}

const toasts = {
    ...toastsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    toastsToDisplay: state.toastsToDisplay
                } = toastsInitialState);
            }
        }
    ),

    setToastsToDisplay: action((state, payload) => {
        state.toastsToDisplay = payload;
    }),

    addToast: thunk((actions, payload, helpers) => {
        const { toastsToDisplay } = helpers.getState();

        actions.setToastsToDisplay([...toastsToDisplay,
            {
                id: HelperMetiers360.generateUniqueId(),
                ...payload
            }
        ])
    }),

    removeToast: thunk((actions, payload, helpers) => {
        const { toastsToDisplay } = helpers.getState();

        actions.setToastsToDisplay(
            toastsToDisplay?.filter((toast) => toast.id !== payload)
        )
    })
};

export default toasts;