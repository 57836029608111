import React from 'react';
import { useStoreActions} from 'easy-peasy';

import FormationFormComponent from './FormationFormComponent';


const UploadFormationComponent = ({
        formationToUpdate,
        action
    }) => {

		const {updateFormation, createFormation} = useStoreActions(actions => actions.formations);

    return (
		<>
				<FormationFormComponent 
					createFormation={createFormation}
					updateFormation={updateFormation}
					formationToUpdate={formationToUpdate}
					action={action}
				/>
			
		</>
    )
}

export default UploadFormationComponent;