import { useStoreState } from 'easy-peasy';
import React, { useState, useRef, useEffect } from 'react';
import {Form, Col, Row } from 'react-bootstrap';

import ButtonLoaderComponent from '../../../../widgets/ButtonLoaderComponent.jsx';
import './WebappSkinForm.scss';
import { useToast } from '../../../../../hooks/useToast.jsx';

const WebappSkinFormComponent = ({client, onValidate}) => {
    const fileInput = useRef(null);
    const toast = useToast();

    const {apiData : {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

    const { csrfToken } = ACTIONS['clients']['editProps']['editWebappSkin']

    const fileInputControl = {
        min : 60,
        max : 300,
        msg : <><p> Choisir le fichier <br /> <span> (png de 60px par 60px mini, 300 par 300 max) </span> </p></>
    }
    
    const colorsDefaultValue = {
        'primaryColor' : BACKOFFICE_DEFAULT_VALUES.webappCustomisationColors.primaryColor,
        'secondaryColor' : BACKOFFICE_DEFAULT_VALUES.webappCustomisationColors.secondaryColor,
        'textColor' : BACKOFFICE_DEFAULT_VALUES.webappCustomisationColors.textColor
    }

    const [colors, setColors] = useState(colorsDefaultValue);
    const [fileInputName, setFileInputName] = useState(fileInputControl.msg);
    const [formErrors, setFormErrors] = useState({});
    const [isSending, setIsSending] = useState(false);

    const titleStyle={color: colors.primaryColor, fontWeight:'bold', padding:'5px', margin:'5px'}
    const buttonStyle={color: colors.secondaryColor, fontWeight:'bold', padding:'5px', margin:'5px'}
    const sample=<div style={{textAlign: 'center', margin: '40px'}}>
        Rendu : <br />
        <img width="150" id='img-sample' src={client.webappCustomisation.logoURL}  alt="logo webapp" />
        <p style={{margin: '20px'}}><span style={buttonStyle}><i className="fas fa-home" />Accueil</span><br /><span style={titleStyle}>Titre story</span></p>
        </div>;

    useEffect(() => {
        updateColors();
    }, [client])

    const updateColors = () => {
        const {primaryColor, secondaryColor, textColor} = client.webappCustomisation;
        
        const newColors = {
            primaryColor: primaryColor || colorsDefaultValue.primaryColor,
            secondaryColor: secondaryColor || colorsDefaultValue.secondaryColor,
            textColor: textColor || colorsDefaultValue.textColor,
        }

        setColors(prev => ({...prev, ...newColors}));
    }

    const checkImage = () => {
        let errorsList = {};
        const file = fileInput.current.files[0];
        const imgSample=document.getElementById('img-sample');
        imgSample.src = window.URL.createObjectURL(file);
        imgSample.onload = () => {
            if(imgSample.width<fileInputControl.min || imgSample.width>fileInputControl.max || imgSample.height<fileInputControl.min || imgSample.height>fileInputControl.max){
                errorsList = {'logoFileName': 'Veuillez choisir un fichier image satisfaisant aux critères'};
                setFileInputName(fileInputControl.msg);
                imgSample.src = '';
                setFormErrors(errorsList);
            }
            else {
                errorsList = {}
                setFormErrors(errorsList);
                setFileInputName(fileInput.current.files[0].name);
            }
        };
    }

    return <Form 
                id="CustomiseWebapp-form"
                className="form-customize-webapp"
                method="post" 
                encType="multipart/form-data" 
                onSubmit={(e)=> {
                    setIsSending(true);
                    e.preventDefault();
                    onValidate()
                        .then(() => toast.success())
                        .finally(() => setIsSending(false));
                }
            }>
                <Form.Group controlId="formWebapp">
                    <Row>
                    <Col>
                        <Form.Label>Couleur titre story</Form.Label>
                        <Form.Control 
                            name="webapp_customisation[primaryColor]"
                            type="color" 
                            value={colors.primaryColor}
                            onChange={(event)=>setColors({...colors,'primaryColor':event.target.value})}
                            />
                    </Col>
                    <Col>
                        <Form.Label>Couleur texte menu</Form.Label>
                        <Form.Control 
                            name="webapp_customisation[secondaryColor]"
                            type="color" 
                            value={colors.secondaryColor}
                            onChange={(event)=>setColors({...colors,'secondaryColor':event.target.value})}
                            />
                    </Col>
                    <Col>
                        <Form.Label>Couleur texte bouton</Form.Label>
                        <Form.Control 
                            name="webapp_customisation[textColor]"
                            type="color"
                            value={colors.textColor}
                            onChange={(event)=>setColors({...colors,'textColor':event.target.value})}
                            />
                    </Col>
                    </Row>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupFileAddon01">Changer l'icône</span>
                        </div>
                        <div className="custom-file">
                            <input 
                                type="file" 
                                accept="image/png"
                                className={"custom-file-input"+(formErrors.logoFileName ? " is-invalid":null)}
                                id="inputGroupFile01" 
                                name="webapp_customisation[logoFileName]" 
                                aria-describedby="inputGroupFileAddon01"
                                ref={fileInput}
                                onChange={checkImage} />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                {fileInputName}
                            </label>
                        </div>
                        <Form.Control
                            type="hidden"
                            isInvalid={formErrors.logoFileName != undefined}
                        />
                        <Form.Control.Feedback type='invalid'>{formErrors.logoFileName}</Form.Control.Feedback>
                    </div>
                    {sample}
                </Form.Group>
                <Form.Group controlId="formWebapp">
                    
                </Form.Group>

                <Form.Check type='checkbox' custom id='enable-magic-box-checkbox'>
                    <Form.Check.Input
                        name='webapp_customisation[enableMagicBox]'
                        type='checkbox'
                        defaultChecked={Array.isArray(client.webappCustomisation) ? true : client.webappCustomisation.enableMagicBox}
                    />
                    <Form.Check.Label> Afficher la magicbox dans la webapp  </Form.Check.Label>
                </Form.Check>

                <input type="hidden" name="webapp_customisation[_token]" value={csrfToken} />
                <ButtonLoaderComponent
                    block={true}
                    type="submit" 
                    variant='success' 
                    size='sm' 
                    className="mt-4 mx-auto d-flex"
                    isSending={isSending}
                    disabled={isSending}
                    msg={<><i className="fas fa-check me-2 text-white"/> Valider</>}
                />
            </Form>;
};

export const submitWebappSkinForm = (updateWebappSkin, uniqueId) => {
    const formData = new FormData(document.getElementById('CustomiseWebapp-form'));
    return updateWebappSkin({formData, uniqueId});
}

export default WebappSkinFormComponent;