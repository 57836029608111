import React, {useState, useEffect, useRef} from 'react';

import {Button, Container, Row, Col, Image, Alert } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import DownloadFileComponent from '../../../widgets/DownloadFile/DownloadFileComponent.jsx';
import FormationSessionUserActionButtonComponent from './FormationSessionUserActionButtonComponent.jsx';
import FormationSessionRegisteredUsersTableComponent from './FormationSessionRegisteredUsersTableComponent.jsx';
import FormationSessionFormComponent, { submitSessionForm } from './FormationSessionFormComponent.jsx';
import { useModal } from '../../../../hooks/useModal';
import './FormationSessionDetailsComponent.scss';
import { useToast } from '../../../../hooks/useToast.jsx';
import withURLParamsTableComponent from '../../../../HOC/withURLParamsTableComponent.jsx';

const FormationSessionRegisteredUsersTableComponentWithParams = withURLParamsTableComponent(FormationSessionRegisteredUsersTableComponent);

const FormationSessionDetailsComponent = (props) => {
    const {formationSession, formationToUpdate ,clientView, client=null} = props;
    const[typeOfActions, setTypeOfActions] = useState({});
    const[userHasJustRegistered, setUserHasJustRegistered] = useState(false);
    const[userHasJustUnRegistered, setUserHasJustUnRegistered] = useState(false);
    const toast = useToast();
    const {apiData: {BACKOFFICE_DEFAULT_VALUES, ACTIONS}} = useStoreState(state => state.actionSlugs);
	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;

    const {registerUserToFormationSession, deleteFormationSession, updateFormationSession, unregisterUserToFormationSession, participateUserToFormationSession} = useStoreActions(actions => actions.formationSessions);
    const editorContentRefDescription = useRef(null);
   
    const {userUniqueId} = useStoreState(state => state.auth);
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const onGoingMessage =   formationSession?.onGoing ?
        <div className="message-container">
            <span className='text-danger'>En cours</span>
        </div> 
        : null;
    const participateMessage = !userHasJustRegistered && !userHasJustUnRegistered &&  formationSession?.userCanParticipate ?
        <div className="message-container">
            <Alert variant="info" className="mt-2">{!formationSession?.onGoing ? 'La session débutera le ' + HelperMetiers360.getdisplayDateType(formationSession?.startsAt) + ' .' : ''} <br/> Cliquez sur le bouton "Participer" , ci-dessous , le jour J, pour la rejoindre</Alert>
        </div>
        : null;
    const registrationMailMessage = !formationSession?.onGoing && userHasJustRegistered ?
        <div className="message-container">
             <Alert variant="info" className="mt-2">Un email confirmant votre inscription à la session du {HelperMetiers360.getdisplayDateType(formationSession?.startsAt)} vient de vous être envoyé</Alert>
        </div>
        : null;

    useEffect(() => {
        const actions = {
            register : registerUserToFormationSession,
            unregister : unregisterUserToFormationSession,
            participate : participateUserToFormationSession
        }
        setTypeOfActions(actions)
    }, []);
    
	const openEditFormModal = () => {
        editorContentRefDescription.current = formationSession?.description ?? '';

        const handleSubmit = () => {
            setIsSending(true);
            submitSessionForm({formName: ACTIONS?.formations?.editProps?.formationSessions?.update.formName,
                formation: formationToUpdate, formationSession, fileContraints, editorContentRefDescription,
                formationSessionPersistAction: updateFormationSession})
                    .then(() => {
                        toast.success();
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: "Edition de la session du " + HelperMetiers360.getdisplayDateType(formationSession?.startsAt),
            content: <FormationSessionFormComponent fileContraints={fileContraints} formationSession={formationSession}
                editorContentRefDescription={editorContentRefDescription}/>,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'xl',
        });
        setModalShow(true);
	}


    const handleUserHasJustRegistered = (isRegistered) => {
        setUserHasJustRegistered(isRegistered);
	}

    const handleUserHasJustUnRegistered = (isRegistered) => {
        setUserHasJustUnRegistered(isRegistered);
	}

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression d'une session de webinaire</>,
            content: <h5>
                Êtes-vous sûr⋅e de vouloir supprimer la session du &nbsp;
                <b>{HelperMetiers360.getdisplayDateType(formationSession?.startsAt)}</b>
            </h5>,
            resetButton: 'Supprimer',
            cancelButton: 'Annuler',
            onReset: () => {
                handleDeleteFormationSession();
                toast.success();
            }
        });
        setModalShow(true);
    }
   
    const handleDeleteFormationSession = (()=>{
        deleteFormationSession({uniqueId :formationSession.uniqueId, formationUniqueId: formationToUpdate?.uniqueId});
        if(setModalShow){
            setModalShow(false);
        }
    })

    return (
        <Container >
            <Row className='mt-2 text-center'>
            {formationSession?.startsAt && formationSession?.endsAt &&
                <Col>
                    Durée: {HelperMetiers360.formatDuration(HelperMetiers360.calculateDurationInMins(formationSession?.startsAt, formationSession?.endsAt))}
                </Col>
            }
                <Col>
                    Places restantes: {formationSession?.maxParticipants ? formationSession?.places > 0 ? formationSession?.places : "Complet" : "Illimitées"}
                </Col>
            {formationSession?.image && (formationToUpdate?.links?.image !== formationSession?.image) &&
                <Col>
                        <Image className="item_details-thumbnail-session" src={formationSession?.image} />
                </Col>
            }
            </Row>
            {formationSession?.description && formationSession?.description !== "null" &&
            <Row >
                <Col>
                    <div><span className="text-muted" dangerouslySetInnerHTML={{ __html: formationSession.description }}></span> <br />
                    </div>
               </Col>
            </Row>
            }
            {AuthorizationChecker.isAdmin() && formationSession?.link && 
            <Row className='mt-2 text-center'>
                <Col>
                    Lien vers la visio: <br /><Button variant="primary" as="a" bsPrefix={'no-btn'} href={formationSession?.link} >{formationSession.link}</Button>  <br /><br />
                </Col>
            </Row>
            }
            {formationSession?.pdfFile &&
            <Row>
                <Col className="text-center">
                    <DownloadFileComponent path={formationSession.pdfFile} message={"Télécharger le pdf"} icon={true} />
                </Col>
            </Row>
            }
            {formationSession?.usersRegistered && formationSession?.usersRegisteredCount > 0 &&
            <Row className='users' >
                <strong>Inscrits</strong>
                <div className='users-table-container' >
                <div >
                    <FormationSessionRegisteredUsersTableComponentWithParams formationSession={formationSession} client={client}/>
                </div>
                </div>
            </Row>
            }
            {!clientView &&
                <Row>
                    {!AuthorizationChecker.isAdmin() &&
                        <Col className='actionButtons'>
                            {onGoingMessage}{participateMessage}{registrationMailMessage}
                            <FormationSessionUserActionButtonComponent onUnRegisteredConfirmed={handleUserHasJustUnRegistered} onRegisteredConfirmed={handleUserHasJustRegistered} formationSession={formationSession} userUniqueId={userUniqueId} typeOfActions={typeOfActions} />
                        </Col>
                    }

                    {(AuthorizationChecker.hasUpdateRights('formations') || AuthorizationChecker.hasDeleteRights('formations'))&& 
                        <Col className="item_details_btn_actions_sessions">
                            {AuthorizationChecker.hasUpdateRights('formations') ?
                                <Button onClick={() => openEditFormModal()} variant='secondary'><i className="fas fa-edit" /></Button>
                                : null}
                            {AuthorizationChecker.hasDeleteRights('formations') && formationSession?.isDeletable ?
                                <Button variant="danger" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i></Button>
                                : null}
                        </Col>
                    }
                </Row>

            }
            {modalComponent}
        </Container>
    );
};

export default FormationSessionDetailsComponent;