import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Col, Row } from "react-bootstrap";


import UploadVideoComponent from "../../components/videos/UploadVideoComponent.jsx";
import Loader from "../../components/widgets/Loader.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";


const UpdateVideoPage = (props) => {
    const { uniqueId } = useParams(); 
    
    const [isLoading, setIsLoading] = useState(false);

    const { videoById } = useStoreState(state => state.videos);
    const videoSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("videos"));

    const { fetchVideoById, postVideo, updateVideo } = useStoreActions((actions) => actions.videos);
    const { backButtonComponent } = useBackButton()

    useEffect(() => {
        setIsLoading(true);
        fetchVideoById(uniqueId)
            .finally(() => setIsLoading(false));

	}, []);

	return (
		<div className="container p-2">
            {
                (isLoading) ? 
                <Loader />
                : <> 
                    <Row className="mb-3">
                        <Col className="d-flex justify-content-between align-items-center">
                            <h2>
                                    Mise à jour de l'expérience immersive : {videoById && videoById.videoName ? videoById.videoName : "Mise à jour d'une expérience immersive"}
                            </h2>
                            {backButtonComponent}
                        </Col>
			        </Row>
                    <UploadVideoComponent 
                    videoToUpdate={videoById} 
                    postVideo={postVideo} 
                    updateVideo={updateVideo} 
                    action="update"
                    {...videoSlugs}
                    />
                </>
            }
			
		</div>
	);
};

export default UpdateVideoPage;
