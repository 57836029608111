import React, {useState} from 'react';

import { useStoreState, useStoreActions } from 'easy-peasy';
import {  Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast.jsx";

import {Tooltip, OverlayTrigger, Button, Spinner} from 'react-bootstrap';

import HelperMetiers360 from "../../../services/HelpersMetiers360";
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState';
import ButtonLoaderComponent from '../../widgets/ButtonLoaderComponent.jsx';
import AuthorizationChecker from '../../../services/AuthorizationChecker.js';
import PropTypes from 'prop-types';
import TransferHeadsetComponent from './TransferHeadsetComponent.jsx';


const HeadsetCard = (props) => {
    const { headset, setMainModalShow } = props;
    const { allPlaylists } = useCustomGetStoreState("playlists");
    const {allClients} = useCustomGetStoreState("clients");

    const toast = useToast();

    const { setOutOfOrderStatus } = useStoreActions(actions => actions.headsets);

    const [isSending, setIsSending] = useState(false);
    const [validatedStep, setValidatedStep] = useState(false);
    const [validatedStepChangeClient, setValidatedStepChangeClient] = useState(false);


    const storageUsed = headset.storageTotal && headset.storageLeft && headset.storageTotal-headset.storageLeft;
    const storageUsedPercentage = storageUsed && Math.floor(storageUsed/headset.storageTotal*100);

    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const playlistSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('playlists'));
	const {pages} = useStoreState(state=> state.actionSlugs);

    const client = allClients?.length && allClients.find(c => c.uniqueId === headset.clientUniqueId);

    const headsetClient = client ? <>
        <Link to={clientsSlugs.readOne.replace(':uniqueId', client.uniqueId)}>{client.clientName}</Link><br />
        </>
        :<>-Aucun-<br /></>;

    const headsetPlaylist = headset.playlistUniqueId ?
        allPlaylists.find(playlist => playlist.uniqueId == headset.playlistUniqueId)
        :null;

    const sendOutOfOrderStatus = (setAsOutOfOrder) => {
        setIsSending(true);
        setOutOfOrderStatus({uniqueId : headset?.uniqueId, setAsOutOfOrder: setAsOutOfOrder})
            .then(() => toast.success())
            .finally(() => {
                setIsSending(false);
                setValidatedStep(false);
            });
    }
        
    return (
        <>
        <div>
        Client : {headsetClient}
        Nom du casque : {headset?.headsetName}<br />
        Numéro de série casque : {headset.deviceId}<br />
        <i className="fas fa-vr-cardboard"></i> Type de casque : {headset?.typeOfHeadset?.name ?? <span className="text-danger">n.c</span>} <br/>
        <i className="far fa-calendar-plus"></i> création :  {HelperMetiers360.getdisplayDateType(headset?.createdAt?.date)}<br />
        Dernière mise à jour le : {HelperMetiers360.getdisplayDateType(headset?.updatedAt?.date)}</div>
        <div><strong>Etat du casque</strong><br />
        {headset?.isOutOfOrder && <p style={{ color: 'red', fontWeight: 'bold', margin: '0' }}><i className="fas fa-exclamation-triangle text-danger">&nbsp;</i>Casque hors service</p>}
        Playlist :&nbsp;
        {headsetPlaylist 
            ? <Link to={playlistSlugs.readOne.replace(':uniqueId', headset?.playlistUniqueId)}>{headsetPlaylist?.name}</Link>
            : headset?.playlistUniqueId
                ? <Spinner animation="border" size="sm" />
                : <>- Aucune -</>
        } <br />
        {headset?.lastPing && <>Dernier ping : {HelperMetiers360.getdisplayDateType(headset?.lastPing)}<br /></>}
        Version application : <span className={headset?.shouldBeupdatated && "text-danger"}>
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            Mettre à jour l'application avec le loader
                        </Tooltip>
                    }
                >
                        <span className="d-inline-block">
                        {(headset?.shouldBeupdatated || headset?.appVersion === 'n.c.' || headset?.appVersion === '?') && 
                        <i className="fas fa-exclamation-triangle text-danger">&nbsp;</i>
                        }
                        </span>
                        
                </OverlayTrigger>
                 {headset?.appVersion}
                 <br /> 
                {(headset?.shouldBeupdatated || headset?.appVersion === 'n.c.' || headset?.appVersion === '?') && 
                    <Button as="a" bsPrefix={'no-btn'}
                        target="_blank" href={pages.find(page => page.labelNav == "Mise à jour")?.url}>
                        Mettre à jour l'application avec le loader ?
                    </Button>
                }
            </span><br />
        {headset?.deviceOSVersion !== undefined && <>Version de l'OS : {headset.deviceOSVersion}<br /></>}
        {headset?.storageTotal !==undefined && headset?.storageLeft !== undefined && <>Utilisation mémoire du casque : {storageUsed ? 
                    <><meter value={storageUsed} min={0} max={headset?.storageTotal} low={headset?.storageTotal*0.80} high={headset?.storageTotal*0.90} optimum={headset?.storageTotal*0.7} />
                    &nbsp;{storageUsedPercentage.toString().concat(' %')}</>
                    : <span className="text-danger">Aucune donnée</span>}<br /></>}
        {headset?.isDownloading == 1 && <>En train de télécharger <br /></>}


        </div>

            {AuthorizationChecker.isAdmin() &&
                <>
                    <div className='d-flex align-items-center w-100 gap-3'>

                    {!headset?.isOutOfOrder ?
                        <div className='d-flex align-items-center'>
                    <ButtonLoaderComponent
                        variant={validatedStep ? 'danger' : 'warning'} size='sm' msg={<>{!validatedStep ? <><i className="fas fa-tools me-2" /> Indiquer hors service</> : <>Oui, indiquer comme défectueux</>}</>} isSending={isSending} disabled={isSending}
                        onClick={() => { if (!validatedStep) { setValidatedStep(true) } else { sendOutOfOrderStatus(true) } }}
                    />
                    {validatedStep &&
                        <>
                            <Button variant='secondary' className='ml-2' size='sm' onClick={() => setValidatedStep(false)}>Non</Button>
                            <p style={{ color: 'red', fontWeight: 'bold', margin: '0 0 0 1rem', lineHeight: '1.1' }}>Attention, êtes vous sûr de vouloir indiquer ce casque comme défectueux?</p>
                        </>}
                        </div>
                        : <div className='d-flex align-items-center w-100'>
                    <ButtonLoaderComponent
                        variant={validatedStep ? 'success' : 'warning'} size='sm' msg={<>{!validatedStep ? <><i className="fas fa-tools me-2" /> Indiquer en état de marche</> : <>Oui, il fonctionne</>}</>} isSending={isSending} disabled={isSending}
                        onClick={() => { if (!validatedStep) { setValidatedStep(true) } else { sendOutOfOrderStatus(false) } }}
                    />
                    {validatedStep &&
                        <>
                            <Button variant='secondary' className='ml-2' size='sm' onClick={() => setValidatedStep(false)}>Non</Button>
                            <p style={{ color: 'red', fontWeight: 'bold', margin: '0 0 0 1rem', lineHeight: '1.1' }}>Attention, êtes vous sûr de vouloir indiquer ce casque comme en état de marche?</p>
                        </>}
                        </div>}

                    <div className='d-flex align-items-center'>
                        {!validatedStepChangeClient && <Button
                            variant='info' size='sm' disabled={isSending}
                            onClick={() => { setValidatedStepChangeClient(true) }}>
                            <i className="fas fa-angle-double-right me-2" /> Transférer chez un autre client
                        </Button>}
                    </div>

                </div>
                {validatedStepChangeClient &&
                    <TransferHeadsetComponent headset={headset} setValidatedStepChangeClient={setValidatedStepChangeClient} setMainModalShow={setMainModalShow} />}
            </>
            }

        </>
    );

};

HeadsetCard.propTypes = {
    headset: PropTypes.object,
};

export default HeadsetCard;