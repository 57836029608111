import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';

const formationsInitialState = {
    allFormations: [],
    isFetchingAllFormations: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    formationById: null,
    isFetchingOneFormation: false,
}

const formations = {
    ...formationsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allFormations: state.allFormations,
                    isFetchingAllFormations: state.isFetchingAllFormations,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    formationById: state.formationById,
                    isFetchingOneFormation: state.isFetchingOneFormation
                } = formationsInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllFormations: action((state, payload) => {
        state.allFormations = payload;
    }),

    setIsFetchingAllFormations: action((state, payload) => {
        state.isFetchingAllFormations = payload;
    }),

    fetchFormations: thunk((actions, payload, helpers) => {
        const { isFetchingAllFormations, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.formations?.readAll?.url;

        if (!isFetchingAllFormations && isUpdateNecessary) {
            actions.setIsFetchingAllFormations(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllFormations(data);
                    actions.setIsUpdateNecessary(formationsInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllFormations(formationsInitialState.isFetchingAllFormations);
                })
        } else {
            return Promise.resolve();
        }
    }),

    // handlingOneFormation
    setFormationById: action((state, payload) => {
        state.formationById = payload;
    }),
    setIsFetchingOneFormation: action((state, payload) => {
        state.isFetchingOneFormation = payload;
    }),

    fetchFormationById: thunk((actions, payload, helpers) => {
        const { isFetchingOneFormation } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.formations?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneFormation) {
            actions.setIsFetchingOneFormation(true);
            actions.setFormationById(formationsInitialState.formationById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneFormation(formationsInitialState.isFetchingOneFormation);
                    actions.setFormationById(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve();
        }
    }),

    removeTagFromFormations: action((state, payload) => {
        state.allFormations = state.allFormations.map(module => {
            if (module.tags.find(tag => tag.uniqueId === payload)) {
                return {
                    ...module,
                    tags: module.tags.filter(tag => tag.uniqueId !== payload)
                }
            }
            return module;
        })
    }),


    addFormation: action((state, payload) => {
        state.allFormations.push(payload);
    }),

    updateAllFormations: action((state, payload) => {
        const index = state.allFormations.findIndex((module) => module.uniqueId === payload.uniqueId);

        state.allFormations[index] = {
            ...state.allFormations[index],
            ...payload,
        };

    }),

    removeFormation: action((state, payload) => {
        state.allFormations = state.allFormations.filter(module => module.uniqueId !== payload);
    }),

    // createOneFormation
    createFormation: thunk((actions, payload, helpers) => {
        const { setIsUpdateNecessary: setShouldUpdateTag } = helpers.getStoreActions().tags;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createFormationUrl = ACTIONS?.formations?.create?.url;
        const { formData } = payload;

        return axios.post(createFormationUrl, formData)
            .then((result) => {
                actions.addFormation(result.formationToAdd);
                actions.setFormationById(result.formationToAdd);
                setShouldUpdateTag(true);
                return result.formationToAdd;
            })
            .catch(error => Promise.reject(error));
    }),

    // updateFormation
    updateFormation: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const { setIsUpdateNecessary: setShouldUpdateTag } = helpers.getStoreActions().tags;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateFormationUrl = ACTIONS?.formations?.update?.url?.replace('uniqueId', uniqueId);

        return axios.post(updateFormationUrl, formData)
            .then((result) => {
                actions.updateAllFormations(result.formationToUpdate);
                actions.setFormationById(result.formationToUpdate);
                setShouldUpdateTag(true);
                return result.formationToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),

    deleteFormation: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { setIsUpdateNecessary: setShouldUpdateTag } = helpers.getStoreActions().tags;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const formData = new FormData();
        formData.set(`${ACTIONS?.formations?.delete?.formName}[_token]`, ACTIONS?.formations?.delete?.csrftoken);
        const deleteFormationUrl = ACTIONS?.formations?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.post(deleteFormationUrl, formData)
            .then((result) => {
                actions.removeFormation(uniqueId);
                setShouldUpdateTag(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    })
};

export default formations;