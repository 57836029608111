import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";

import Loader from "../../components/widgets/Loader.jsx";
import UploadFormationComponent from "../../components/formations/upload/UploadFormationComponent.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";

const UpdateFormationPage = () => {
    const formationsSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("formations"));
	const {formationById } = useStoreState(state => state.formations);
    const { fetchFormationById, isFetchingOneFormation } = useStoreActions(actions => actions.formations);

	const {uniqueId} = useParams();


	useEffect(() => {
        fetchFormationById(uniqueId)
	}, [uniqueId])

    const { backButtonComponent } = useBackButton()

    return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Edition d'un webinaire</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
				(!formationById || isFetchingOneFormation)
				? <Loader/>
                : <UploadFormationComponent 
					formationToUpdate={formationById}
					action="update"
					{...formationsSlugs}
				/>
            }
		</div>
	);
}

export default UpdateFormationPage;