/**
 * 
 * @param {Object} clients clients to edit client manager
 * @example <MultipleClientManagerEdition clients={clients} clientManagerRef={clientManagerRef} />
 */

import { Alert, Button, ListGroup, ListGroupItem, Spinner } from "react-bootstrap";
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable";
import SelectItemsComponent from "../../../widgets/selectItemsComponent/SelectItemsComponent";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useModal } from "../../../../hooks/useModal";

const MultipleClientManagerEdition = ({ clients, clientManagerRef }) => {
    const { updateClientCRM } = useStoreActions(actions => actions.clients);
    const { fetchContactsM360 } = useStoreActions(actions => actions.clients);
    const { contactsM360 } = useStoreState(actions => actions.clients);
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const [contactsM360List, setContactsM360List] = useState([]);
    const [showResultEdition, setShowResultEdition] = useState(false);
    const [isClientManagerSelected, setIsClientManagerSelected] = useState(false);
    const [serverResults, setServerResults] = useState([]);
    const [currentSending, setCurrentSending] = useState(false);

    useEffect(() => {
        if(contactsM360?.length > 0) {
            setContactsM360List(contactsM360);
        } else {
            fetchContactsM360()
                .then((contacts)=>{
                    setContactsM360List(contacts);
                })
        }
    }, []);

    const sortType = clients?.length > 0
        ? [
            {
                value: 'name', label: 'Nom de l\'établissement',
                test : HelperMetiers360.isArrayContainsValue(clients, "name"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
                display: (client) => client.name
            },
            {
                value: 'clientManager', label: 'Chargé⋅e de clientèle',
                test : true,
                method: (a, b) => {
                    const contactA = a.contactsM360?.length > 0 ? a.contactsM360[0].name : null;
                    const contactB = b.contactsM360?.length > 0 ? b.contactsM360[0].name : null;
                    return HelperMetiers360.localeCompareWithNullable(contactA, contactB);
                },
                display: (client) => client.contactsM360?.map(contact => contact.name)?.join(", ")
            }
        ]
        : []

    const showConfirmModal = () => {
        setModalData({
            ...modalData,
            header: <>Affectation d'un⋅e nouvelle⋅eau chargé⋅e de clientèle</>,
            content: <h5>Êtes-vous sûr⋅e de vouloir affecter un⋅e nouvelle⋅eau chargé⋅e 
                de clientèle à ces <b>{clients.length}</b> établissements ?</h5>,
            validateButton: 'Affecter',
            onValidate: () => {
                editClientManager();
                setModalShow(false);
            },
        });
        setModalShow(true);
    };

    const editClientManager = () => {
        const newClientManagerId = clientManagerRef?.current?.value;
        if(newClientManagerId) {
            editClientManagerOneByOne(newClientManagerId);
        }
    }

    const editClientManagerOneByOne = (newClientManagerId) => {
        setShowResultEdition(true);
        let updateClientCRMPromises = [];
        setCurrentSending(true);

        clients.forEach(client => {
            updateClientCRMPromises.push(
                updateClientCRM({data: {contactsM360: [newClientManagerId]}, uniqueId: client.uniqueId})
                    .then((res)=> {
                        let newResult = serverResults;
                        if(res) {
                            newResult.push({
                                name: client.name,
                                isOK: true,
                                msg:'Chargé⋅e de clientèle affecté⋅e'
                            });
                        } else {
                            newResult.push({
                                name: client.name,
                                isOK: false,
                                msg:'Erreur lors de l\'affectation du chargé⋅e de clientèle'
                            });
                        }
                        setServerResults(newResult);
                    })
                    .catch((error) => {
                        let newResult = serverResults;
                        newResult.push({
                            name: client.name,
                            isOK: false,
                            msg:'Erreur lors de l\'affectation du chargé⋅e de clientèle'
                        });
                        setServerResults(newResult);
                    })
            );
        });

        Promise.all(
            updateClientCRMPromises
        ).then(() => {
            setCurrentSending(false);
        })
    }

    const changeClientManagerSelection = () => {
        setIsClientManagerSelected(clientManagerRef?.current?.value !== '');
    }

    const serverResultsDisplay = serverResults?.map(res => <ListGroupItem key={HelperMetiers360.generateUniqueId()}>
        {res.name}&nbsp;
        <span className={"ms-3 text-"+ (res.isOK ? "success" : "danger") + " icon-" +
            (res.isOK ? "checkmark" : "cross")}> {res.msg}</span>
    </ListGroupItem>);

    const currentSendingDisplay = currentSending &&
        <ListGroupItem>Affectation en cours <Spinner size="sm" animation="border" /></ListGroupItem>;

    return <div>
        { modalComponent }
        { sortType.length > 0 && clients?.length > 0
            ? !showResultEdition
                ? <>
                    <div>
                        <h6>Vous êtes sur le point d'éditer le/la chargé·e de clientèle de tous ces établissements :</h6>
                    </div>
                    <div className="mt-5">
                        <DynamicTable
                            contentTable={clients}
                            contentSort={sortType}
                            index='uniqueId' />
                        <div className="mt-5 mb-5">
                            <label className="label-select">Nouvelle⋅eau chargé⋅e de clientèle&nbsp;: </label>
                            { contactsM360List.length > 0 
                                ? <SelectItemsComponent items={contactsM360List} selectRef={clientManagerRef} itemKey="id" itemValue="name"
                                    onChange={() => changeClientManagerSelection()} />
                                : <Spinner animation="border" size="sm" />
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button variant="success" disabled={!isClientManagerSelected} onClick={() => showConfirmModal()}>Valider</Button>
                        </div>
                    </div>
                </>
                : <>
                    {serverResults?.length > 0 && <ListGroup>{serverResultsDisplay}</ListGroup>}
                    {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}
                </>
            : <Alert variant="danger">Aucun établissement</Alert>
        }
    </div>
}
export default MultipleClientManagerEdition;