import React from "react";
import { Link } from "react-router-dom";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Button, Col, Row } from "react-bootstrap";

import UploadTagComponent from "../../components/tags/UploadTagComponent";

const UploadTagPage = (props) => {
	const {postTag} =  useStoreActions(actions => actions.tags);
    const tagSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("tags"));

	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'un tag</h2>
					<Button as={Link} to={tagSlugs.readAll}><i className="fas fa-arrow-circle-left"></i> Retour</Button>
				</Col>
			</Row>
            {
                <UploadTagComponent 
					postTag={postTag} 
					action="create"
					{...tagSlugs}
				/>
            }
		</div>
	);
};

export default UploadTagPage;
