import React, { useState} from "react";

import { useStoreState} from "easy-peasy";
import {Alert} from 'react-bootstrap';


import HelperMetiers360 from "../../services/HelpersMetiers360";


import { Image, OverlayTrigger, Tooltip} from "react-bootstrap";


import DynamicTable from "../widgets/dynamicTable/DynamicTable.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";

const FormationsByClientTableComponent = (props) => {
    const {formationsToDisplay, onSelect, scrollTargetRef, withParams} = props;
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;

  
    const [activeRowId, setActiveRowId] = useState(null);
   
    const getCategories = (formation) => {
        return allCategories.filter(category => formation.categories.includes(category.value))
        .map(category => category.label)
        ?.join(', ')
    };


    const getLevels = (formation) => {
        return allLevels.filter(level => formation.levels.includes(level.value))
        .map(level => level.label)
        ?.join(', ')
    };

    const getTools = (formation) => {
        return allTools.filter(tool => formation.tools.includes(tool.value))
        .map(tool => tool.label)
        ?.join(', ')
    };

    const sortType = formationsToDisplay ?
    [
        {
            value: 'image', label: 'Vignette',
            test: formationsToDisplay[0]?.links.image !== undefined,
            method: undefined,
            display: (formation) => (
                <OverlayTrigger
                    placement='right'
                    overlay={
                        <Tooltip>
                            <Image src={formation.links.image} />
                        </Tooltip>
                    }
                >
                    <Image src={formation.links.image} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay: (formation) => formation.links.image
        },
        {
            value: 'name', label: 'Nom',
            test: formationsToDisplay[0]?.name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (formation) => formation.name
        },
        {
            value: 'sessions', label: 'Inscriptions',
            test: formationsToDisplay[0]?.formationSessionsUsersRegisteredCount !== undefined,
            method: (a, b) => a?.formationSessionsUsersRegisteredCount - b?.formationSessionsUsersRegisteredCount,
            display: (formation) => formation.formationSessionsUsersRegisteredCount
        },
        {
            value: 'participations', label: 'Participations',
            test: formationsToDisplay.some(f => f.formationSessionsUsersHaveParticipatedCount >= 0),
            method: (a, b) => a?.formationSessionsUsersHaveParticipatedCount - b?.formationSessionsUsersHaveParticipatedCount,
            display: (formation) => formation.formationSessionsUsersHaveParticipatedCount
        },
        {
            value: 'categories', label: 'Categories',
            test: formationsToDisplay[0]?.categories !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(getCategories(a), getCategories(b)),
            display: (formation) => getCategories(formation)
        },
        {
            value: 'tools', label: 'Outils',
            test: formationsToDisplay[0]?.tools !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(getTools(a), getTools(b)),
            display: (formation) => getTools(formation)
        },
        {
            value: 'levels', label: 'Niveaux',
            test: formationsToDisplay[0]?.levels !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(getLevels(a), getLevels(b)),
            display: (formation) => getLevels(formation)
        },
        {
            value: 'pedagogicModules', label: 'Modules Pédagogiques',
            test: formationsToDisplay[0]?.pedagogicModulesCount !== undefined,
            method: (a, b) => a?.pedagogicModulesCount - b?.pedagogicModulesCount,
            display: (formation) => formation.pedagogicModulesCount
        },
        {
            value: 'releaseDate', label: 'Publication',
            test: formationsToDisplay[0]?.releaseDate !== undefined,
            method: (a, b) => new Date(b?.releaseDate) - new Date(a?.releaseDate),
            display: (formation) => formation.releaseDate == null ? 
            '-' 
            : HelperMetiers360.getdisplayDateType(formation.releaseDate, 'day')
        }
      ]:null;

        

        const handleClick = onSelect ? 
        (selectedId) => {
            onSelect(formationsToDisplay.filter( formation => formation.uniqueId == selectedId )[0]);
            setActiveRowId(selectedId);

            if (scrollTargetRef) {
                scrollTargetRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }
        :null;

        return (
        <>
            <div className="header-heading w-100">            
                {formationsToDisplay?.length > 1 ? 
                    <>
                    <h4 className="header-title">Webinaires inscrits</h4>
                    <Alert variant="info" className="mt-2">Webinaires où {AuthorizationChecker.isAdmin() ? "des utilisateur⋅rices du client" : "vos utilisateur⋅rices" } sont inscrit⋅es. Cliquez sur l'un d'entre eux pour voir les sessions associées</Alert>
                    </>
                :   <> 
                    <h4 className="header-title">Webinaire inscrit</h4>
                    </>
                }
            </div>

            
            <DynamicTable 
                contentTable = {formationsToDisplay}
                contentSort = {sortType}
                valueInitSort = "name"
                index = 'uniqueId'
                handleClick = {handleClick}
                className="table-formation"
                    activeRowId={activeRowId}
                withParams={withParams}
                filename="webinaires_etablissement"
                />
            
        </>
            )
}

export default FormationsByClientTableComponent