import React, {useState, useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';

import axios from '../../../../services/axios'
import useCancel from "../../../../hooks/useCancel";

import WebappAccessTableComponent from '../../../webappAccess/WebappAccessTableComponent.jsx';
import WebappCustomisationFormsComponent from '../webappCustomisationEdition/WebappCustomisationFormsComponent.jsx';
import Loader from '../../../widgets/Loader';

const ClientWebappTabComponent = ({setModalData, modalData, setModalShow}) => {
    const { clientById } = useStoreState(state => state.clients);

    const  { cancelToken } = useCancel();

    const {apiData } = useStoreState(state => state.actionSlugs);

    const [webappAccesses, setWebappAccesses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUrl = apiData.ACTIONS?.clients?.getProps?.getWebappAccesses?.url?.replace('uniqueId', clientById.uniqueId);
        axios.get(getUrl, {cancelToken: cancelToken()})
            .then(response => {
                setWebappAccesses(response);
            })
            .finally(() => setIsLoading(false));

    }, [])

    const editWebaccessCustomisationModal = () => {
        setModalData({
            ...modalData,
            header: <>Personnalisation de la webapp de {clientById.clientName}</>,
            content: <WebappCustomisationFormsComponent client={clientById} />,
            size: 'lg'
        });
        setModalShow(true);
    }

    return <Row>
        <Col className="tab-info-container" >
            <Col className="tab-info-card tab-info-card--noMinHeight">

                <div className="tab-info-card-header">
                    <div className="header-heading">
                        <p className="header-desc">Consulter les usages par code et personnalisez les expériences</p>
                    </div>
                    <div className="header-action">
                        <Button variant="secondary" onClick={editWebaccessCustomisationModal}><i className="fa fa-cog" /> Personnaliser la WebApp</Button>
                    </div>

                </div>                
                { (isLoading) && <Loader /> }
                <WebappAccessTableComponent webappAccesses={webappAccesses} />
                
            </Col>
        </Col>
    </Row>
}

export default ClientWebappTabComponent;