import React from "react";
import { useStoreState } from 'easy-peasy';

import { Form } from 'react-bootstrap';

const TagOperationFormComponent = (props) => {

    const {tagOperation = null} = props;

    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs)
    const token = ACTIONS['tagOperations'][tagOperation ? 'update' : 'create'].csrfToken;
    
    return (
        <div>
            <div>
               <Form id="tagOperation-form"> 
                    <Form.Group>
                        <Form.Label>Nom :</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            defaultValue={tagOperation?.name  ? tagOperation.name : ""}
                            placeholder="Exemple: informatique"
                        />
                        <Form.Control.Feedback type='invalid' name='feedback-name'></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
						<Form.Check 
                            type='switch' 
                            id='tag_private' 
                            name='isTester'
                            className="ms-3 mt-3"
                            label={<span>Cochez s'il s'agit d'un ensemble de clients testeurs</span> }
                            defaultChecked={tagOperation?.isTester}
                            >
						</Form.Check>
					</Form.Group>

                    <Form.Group >
                        <Form.Control
                            name="csrfToken"
                            defaultValue={token}
                            type="hidden"
                        />
                    </Form.Group>
                </Form>    
            </div>
        </div>

    );
};

export const submitTagOperationForm = ({tagOperation, allTagOperations, action}) => {
    let error = false;

    let formData = new FormData(document.getElementById('tagOperation-form'));

    let name = formData.get('name');

    document.getElementsByName('name')[0].classList.remove("is-invalid");

    if (name === "") {
		document.getElementsByName('name')[0].classList.add("is-invalid");
        document.getElementsByName(`feedback-name`)[0].innerHTML = "Le nom du tag est obligatoire";
        error = true;
    }
    if(name && !tagOperation && allTagOperations.find(tagOperation => tagOperation.name.toLowerCase() === name.toLowerCase())){
		document.getElementsByName('name')[0].classList.add("is-invalid");
        document.getElementsByName(`feedback-name`)[0].innerHTML = "Ce nom de tag existe déjà";
		error = true;
    }

    if (error) {
        return Promise.reject(new Error());
    } else {
        let data =  {name: formData.get('name'), isTester: formData.get('isTester')==='on', csrfToken: formData.get('csrfToken') }

        if(tagOperation){
            data = {data: data, uniqueId: tagOperation.uniqueId }
        }

        return action(data)
            .catch(() => {
                return Promise.reject(new Error());
            })
    }
}

export default TagOperationFormComponent;