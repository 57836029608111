import { action, actionOn } from "easy-peasy";

const breadcrumbsInitialState = {
    currentEntityName: null,
    isFetchingEntity: false
}

const breadcrumbs = {
    ...breadcrumbsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    currentEntityName: state.currentEntityName,
                    isFetchingEntity: state.isFetchingEntity
                } = breadcrumbsInitialState);
            }
        }
    ),

    onEntityByIdFetching: actionOn(
        (actions, storeActions) => [
            storeActions.clients.setIsFetchingOneClient,
            storeActions.groups.setIsFetchingOneGroup,
            storeActions.headsets.setIsFetchingOneHeadset,
            storeActions.pedagogicModules.setIsFetchingOnePedagogicModule,
            storeActions.formations.setIsFetchingOneFormation,
            storeActions.playlists.setIsFetchingOnePlaylist,
            storeActions.quizzes.setIsFetchingOneQuiz,
            storeActions.romes.setIsFetchingOneRome,
            storeActions.sequences.setIsFetchingOneSequence,
            storeActions.stories.setIsFetchingOneStory,
            storeActions.videos.setIsFetchingOneVideo,
            storeActions.orders.setIsFetchingOneOrder,
            storeActions.orders.setIsFetchingOneReturnOrder,
            storeActions.romes.setOneTransversalProp
        ],
        (state, target) => {
            state.isFetchingEntity = target.payload;
            //  reset previous currentEntityName while we are fetching
            if (target.payload === true) {
                state.currentEntityName = breadcrumbsInitialState.currentEntityName;
            }
        }
    ),

    onEntityByIdSet: actionOn(
        (actions, storeActions) => [
            storeActions.clients.setClientById,
            storeActions.groups.setGroupById,
            storeActions.headsets.setHeadsetById,
            storeActions.pedagogicModules.setPedagogicModuleById,
            storeActions.playlists.setPlaylistById,
            storeActions.quizzes.setQuizById,
            storeActions.romes.setRomeById,
            storeActions.sequences.setSequenceById,
            storeActions.stories.setStoryById,
            storeActions.videos.setVideoById,
            storeActions.formations.setFormationById,
            storeActions.orders.setOrderById,
            storeActions.orders.setReturnOrderById,
            storeActions.romes.setOneTransversalProp
        ],
        (state, target) => {
            const [
                setClientById,
                setGroupById,
                setHeadsetById,
                setPedagogicModuleById,
                setPlaylistById,
                setQuizById,
                setRomeById,
                setSequenceById,
                setStoryById,
                setVideoById,
                setFormationById,
                setOrderById,
                setReturnOrderById,
                setOneTransversalProp
            ] = target.resolvedTargets;

            switch (target.type) {
                case setClientById: {
                    state.currentEntityName = target.payload?.clientName
                    break;
                }
                case setRomeById: {
                    state.currentEntityName = target.payload?.label
                    break;
                }
                case setVideoById: {
                    state.currentEntityName = target.payload?.videoName
                    break;
                }
                case setOrderById: {
                    state.currentEntityName = target.payload?.recipientSociety
                    break;
                }
                case setReturnOrderById: {
                    state.currentEntityName = target.payload?.clientName
                    break;
                }
                case setOneTransversalProp: {
                    state.currentEntityName = target.payload?.label
                    break;
                }
                default: {
                    state.currentEntityName = target.payload?.name
                    break;
                }
            }
        }
    ),

    setCurrentEntityName: action((state, payload) => {
        state.currentEntityName = payload;
    })
}

export default breadcrumbs;