import axios from "../../../services/axios.js";
import { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import ChronogramComponent from "../../producerStats/ChronogramComponent";
import { useStoreState } from "easy-peasy";
import Loader from "../../widgets/Loader";
import GeoMap from "../../producerStats/GeoMap.jsx";
import ClientCategories from "../../producerStats/ClientCategories.jsx";
import MeanViewsDurationPercent from "../../producerStats/MeanViewsDurationPercent.jsx";
import './VideoDetailsAudience.scss';
import PropTypes from 'prop-types';

const VideoDetailsAudience = ({ videoUniqueId }) => {
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const [chronogramData, setChronogramData] = useState();
    const [geoMapData, setGeoMapData] = useState();
    const [clientCategoriesData, setClientCategoriesData] = useState();
    const [meanViewsDurationPercentData, setMeanViewsDurationPercentData] = useState();
    const [isChronogramFetching, setIsChronogramFetching] = useState(false);
    const [isGeoMapFetching, setIsGeoMapFetching] = useState(false);
    const [isClientCategoriesFetching, setIsClientCategoriesFetching] = useState(false);
    const [isMeanViewsDurationPercentFetching, setIsMeanViewsDurationPercentFetching] = useState(false);

    const noData = chronogramData && !chronogramData?.length &&
        meanViewsDurationPercentData && !meanViewsDurationPercentData?.length &&
        geoMapData && !geoMapData?.length &&
        clientCategoriesData && !clientCategoriesData?.length

    const fetchData = (graphType, setIsFetching, setGraphData) => {
        const postUrl = ACTIONS?.producerStats?.getStatsForContents?.url;
        setIsFetching(true);

        axios.post(postUrl, {
            entity: 'videos',
            uuid: [videoUniqueId],
            startDate: undefined,
            endDate: undefined,
            statType: graphType,
            aggregated: false
        }, {
            responseType: "json",
        })
            .then((response) => {
                setGraphData(response);
            })
            .finally(() => setIsFetching(false));
    }

    useEffect(() => {
        fetchData("chronogram", setIsChronogramFetching, setChronogramData);
        fetchData("geoMap", setIsGeoMapFetching, setGeoMapData);
        fetchData("clientCategories", setIsClientCategoriesFetching, setClientCategoriesData);
        fetchData("meanViewsDurationPercent", setIsMeanViewsDurationPercentFetching, setMeanViewsDurationPercentData);
    }, []);

    return <>
        <Row>
            {isChronogramFetching
                ? <Loader />
                : !!chronogramData?.length
                && <Col md={12} lg={6}>
                    <ChronogramComponent data={chronogramData} aggregated={false} />
                </Col>}
            {isMeanViewsDurationPercentFetching
                ? <Loader />
                : !!meanViewsDurationPercentData?.length
                && <Col md={12} lg={6}>
                    <MeanViewsDurationPercent data={meanViewsDurationPercentData} aggregated={false} />
                </Col>}
        </Row>
        <Row className="mt-4">
            {isClientCategoriesFetching
                ? <Loader />
                : !!clientCategoriesData?.length
                && <Col md={12} lg={6}>
                    <p className="graph-title mb-3">Nombre de vues par catégorie d'organisation</p>
                    <ClientCategories data={clientCategoriesData} showOnlyGraph={true} width="100%" height="85vh" />
                </Col>}
            {isGeoMapFetching
                ? <Loader />
                : !!geoMapData?.length
                && <Col md={12} lg={6}>
                    <p className="graph-title">Nombre de vues par région</p>
                        <GeoMap data={geoMapData} showOnlyGraph={true} height="80vh" />
                </Col>}
        </Row>
        {noData &&
            <Alert variant="warning">Aucune donnée à afficher</Alert>
        }
    </>;
}

VideoDetailsAudience.propTypes = {
    videoUniqueId: PropTypes.string.isRequired,
}

export default VideoDetailsAudience;