import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import {Form, Button, Alert} from 'react-bootstrap';
import { useParams } from "react-router-dom";

import PasswordFormGroup from '../../components/security/PasswordFormGroup.jsx';

import axios, { URLS } from "../../services/axios";
import ButtonLoaderComponent from '../../components/widgets/ButtonLoaderComponent.jsx';

const Reset = () => {
    const {resetToken} = useParams();

    const { apiRoutes } = useStoreState(actions => actions.params);

    const REACT_APP_API_CHECK_RESET = apiRoutes.checkReset;

    const [alertUser, setAlertUser] = useState(null);

    const [csrfToken, setCsrfToken] = useState('');
    const [sendUrl, setSendUrl] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const spinner = <ButtonLoaderComponent />;

    useEffect(() => {
        const formData = new FormData();
        formData.append('resetToken',resetToken);
        axios.post(REACT_APP_API_CHECK_RESET,formData)
            .then((response) => {
                setCsrfToken(response.csrfToken);
                setSendUrl(response.url);
            })
            .catch((error) => {

                setAlertUser(<Alert className="text-center" variant='warning'>
                    Une erreur est survenue<br />
                    <a href={URLS.BASE_URL}>Revenir à la page d'accueil</a></Alert>);
            })
    }, [REACT_APP_API_CHECK_RESET,URLS.BASE_URL,resetToken])




    const form = <Form>
        <PasswordFormGroup 
            userExist={true}
            onValid={(validation, validPass) => { setCheckPassword(validation); setPassword(validPass) }}  />
        <ButtonLoaderComponent 
            variant="primary" 
            className="mt-4 mb-0" 
            disabled={!checkPassword} 
            onClick={() => { onValidatePWD() }}
            msg='Changer le mot de passe'
            isSending={isSending}
            />
    </Form>;

    const onValidatePWD = () => {
        if (checkPassword) {
            setIsSending(true);
            const formData = new FormData();
            formData.append('resetToken',resetToken);
            formData.append("create_new_password[plainPassword][first]", password);
            formData.append("create_new_password[plainPassword][second]", password);
            formData.append("create_new_password[_token]", csrfToken);
            axios.post(sendUrl, formData).then((response) => {
                setIsSending(false);
                setAlertUser(<Alert className="text-center" variant='info'>
                    {response.msg}<br /><a href={URLS.BASE_URL}>Revenir à la page d'accueil</a>
                    </Alert>
                    );
            }).catch((reject) => {
                setIsSending(false);
                setAlertUser(<Alert className="text-center" variant='warning'>
                    Une erreur est survenue<br />
                    <a href={URLS.BASE_URL}>Revenir à la page d'accueil</a></Alert>);
            });
        }
    };

    return (<>
    {alertUser ? alertUser:form}
    </>);
};

export default Reset;