import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Col, Form } from 'react-bootstrap';

import SelectSingleClientComponent from '../../../widgets/selectClient/SelectSingleClientComponent.jsx';
import SelectMultipleClientsComponent from '../../../widgets/selectClient/SelectMultipleClientsComponent.jsx';
import useCustomGetStoreState from '../../../../hooks/useCustomGetStoreState.jsx';


/**
 * 
 * @param {Object} client client infos
 * @example <ParenthoodFormComponent client={client} />
 */

const ParenthoodFormComponent = (props) => {

    const { client } = props;

    const {apiData} = useStoreState(state => state.actionSlugs);

    const [selectedParent, setSelectedParent] = useState(client && client.clientParent ? [client.clientParent]:[]);
    const [selectedChildren, setSelectedChildren] = useState(client && client.clientChildren ? client.clientChildren.map(String):[]);
    const { allClients } = useCustomGetStoreState("clients");

    const { csrfToken } = apiData.ACTIONS['clients']['editProps']['editParenthood']

    return (
        <Form id="parenthood-form" method="post" encType="multipart/form-data" onSubmit={(e)=>e.preventDefault()}>
            <Form.Group >
            <Col className="py-4">
                <Form.Label><h4>Sélectionnez le parent de ce client :</h4></Form.Label>                
                    <SelectSingleClientComponent
                        nameSelect='parenthood[clientParent]'
                        selectId='parentSelect'
                        selectedClients = {selectedParent}
                        setSelectedClients = {setSelectedParent}
                        initialClients={allClients.filter(c => c.uniqueId !== client.uniqueId && !client.clientChildren.includes(c.uniqueId))} />
            </Col>
            </Form.Group>
            <Form.Group>
            <Col  className="py-4">
                <Form.Label><h4>Sélectionnez des enfants pour ce client :</h4></Form.Label>
                <SelectMultipleClientsComponent
                    nameSelect='parenthood[clientChildren][]'
                    selectId='childrenSelect'
                    selectedClients = {selectedChildren}
                    setSelectedClients = {setSelectedChildren}
                    initialClients={allClients.filter(c => c.uniqueId !== client.uniqueId && c.uniqueId !== client.clientParent)}
                />
            </Col>
            </Form.Group>
            <Form.Group>
                <Form.Control
                    name="parenthood[_token]"
                    defaultValue={csrfToken}
                    type="hidden"
                />
            </Form.Group>
        </Form>
    )
}

export const submitParenhoodForm = (updateParenthood, uniqueId) => {
    const formData = new FormData(document.getElementById('parenthood-form'));
    return updateParenthood({formData, uniqueId});
}

export default ParenthoodFormComponent;