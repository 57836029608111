import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';
import { store } from './index.js';

const subscriptionsInitialState = {
    allSubscriptions: [],
    isFetchingAllSubscriptions: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    subscriptionById: null,
    isFetchingOneSubscription: false,
    allSubscriptionsHeadsets: [],
    isFetchingSubscriptionsHeadsets: false,
}

const subscriptions = {
    ...subscriptionsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allSubscriptions: state.allSubscriptions,
                    isFetchingAllSubscriptions: state.isFetchingAllSubscriptions,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    subscriptionById: state.subscriptionById,
                    isFetchingOneSubscription: state.isFetchingOneSubscription,
                    allSubscriptionsHeadsets: state.allSubscriptionsHeadsets,
                    isFetchingSubscriptionsHeadsets: state.isFetchingSubscriptionsHeadsets
                } = subscriptionsInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllSubscriptions: action((state, payload) => {
        state.allSubscriptions = payload;
    }),

    setIsFetchingAllSubscriptions: action((state, payload) => {
        state.isFetchingAllSubscriptions = payload;
    }),

    fetchSubscriptions: thunk((actions, payload, helpers) => {
        const { isFetchingAllSubscriptions, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.subscriptions?.readAll?.url;

        if (!isFetchingAllSubscriptions && isUpdateNecessary) {
            actions.setIsFetchingAllSubscriptions(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllSubscriptions(data);
                    actions.setIsUpdateNecessary(subscriptionsInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingAllSubscriptions(false))
        } else {
            return Promise.resolve();
        }
    }),

    // handlingOneSubscription
    setSubscriptionById: action((state, payload) => {
        state.subscriptionById = payload;
    }),
    setIsFetchingOneSubscription: action((state, payload) => {
        state.isFetchingOneSubscription = payload;
    }),

    fetchSubscriptionById: thunk((actions, payload, helpers) => {
        const { isFetchingOneSubscription } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;


        const getUrl = ACTIONS?.subscriptions?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneSubscription) {
            actions.setIsFetchingOneSubscription(true);
            actions.setSubscriptionById(subscriptionsInitialState.subscriptionById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneSubscription(subscriptionsInitialState.isFetchingOneSubscription);
                    actions.setSubscriptionById(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve();
        }
    }),

    addSubscription: action((state, payload) => {
        state.allSubscriptions.push(payload);
    }),

    updateAllSubscriptions: action((state, payload) => {
        const index = state.allSubscriptions.findIndex((module) => module.uniqueId === payload.uniqueId);

        state.allSubscriptions[index] = {
            ...state.allSubscriptions[index],
            ...payload,
        };

    }),

    removeSubscription: action((state, payload) => {
        state.allSubscriptions = state.allSubscriptions.filter(subscription => subscription.uniqueId !== payload);
    }),
    
     // createOneSubscription
     createSubscription: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { addClient, updateClientsProps, mergeOneClient, updateClientsCRMProps, addSubscriptionByClientId, updateOneClientCRMById} = helpers.getStoreActions().clients;
 
        const createSubscriptionUrl = ACTIONS?.subscriptions?.create?.url?.replace('clientUniqueId', payload.client.uniqueId);

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(createSubscriptionUrl, payload.subscription, options)
            .then((result) => {
                if (result.clientToAdd) {
                    addClient(result.clientToAdd);
                }
                if (result.clientsPropToUpdate) {
                    updateClientsProps(result.clientsPropToUpdate);
                }
                if (result.clientCRMInfos) {
                    updateOneClientCRMById(result.clientCRMInfos);
                    mergeOneClient({ clientCRM: result.clientCRMInfos});
                    updateClientsCRMProps([{
                        uniqueId: result.clientCRMInfos.uniqueId,
                        isSubscriber: true
                    }]);
                }
                addSubscriptionByClientId(result.subscriptionToAdd);
                return result.subscriptionToAdd;
            })
            .catch(error => Promise.reject(error));
    }),

    // updateSubscription
    updateSubscription: thunk((actions, payload, helpers) => {
        const { data, uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const { updateSubscriptionByClientId, updateClientsProps} = helpers.getStoreActions().clients;

        const updateSubscriptionUrl = ACTIONS?.subscriptions?.update?.url?.replace('uniqueId', uniqueId);
        data.subscriptionCsrfToken = ACTIONS?.subscriptions?.update?.csrfToken;

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(updateSubscriptionUrl, data, options)
            .then((result) => {
                updateSubscriptionByClientId(result.subscriptionToUpdate);
                updateClientsProps(result.clientsPropToUpdate);
                return result.subscriptionToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),

    deleteSubscription: thunk((actions, payload, helpers) => {
        const {uniqueId} = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { removeSubscriptionByClientId, updateClientsProps} = helpers.getStoreActions().clients;
       
        payload.csrfToken = ACTIONS?.subscriptions?.delete?.csrfToken;
        const deleteSubscriptionUrl = ACTIONS?.subscriptions?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.post(deleteSubscriptionUrl, payload)
            .then((result) => {
                if(result?.clientsPropToUpdate){
                    updateClientsProps(result.clientsPropToUpdate);
                }
                removeSubscriptionByClientId(uniqueId);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    setIsFetchingSubscriptionsHeadsets: action((state, payload) => {
        state.isFetchingSubscriptionsHeadsets = payload;
    }),

    setAllSubscriptionsHeadsets: action((state, payload) => {
        state.allSubscriptionsHeadsets = payload;
    }),

    addSubscriptionsHeadset: action((state, payload) => {
        state.allSubscriptionsHeadsets.push(payload);
    }),

    updateSubscriptionsHeadset: action((state, payload) => {
        const existingHeadsetIndex = state.allSubscriptionsHeadsets.findIndex((headset) => {
            return headset.deviceId == payload.deviceId;
        })
        if (existingHeadsetIndex != -1) {
            state.allSubscriptionsHeadsets[existingHeadsetIndex] = {
                ...state.allSubscriptionsHeadsets[existingHeadsetIndex],
                ...payload,
            };
        }
    }),

    removeHeadsetFromSubscription: action((state, payload) => {
        const headsetIndex = state.allSubscriptionsHeadsets.findIndex((headset) => {
            return (headset.subscriptionUniqueId !== payload.subscriptionUniqueId
                && headset.uniqueId === payload.uniqueId);
        });

        if (headsetIndex !== -1) {
            state.allSubscriptionsHeadsets.splice(headsetIndex, 1);
        }
    }),

    fetchAllSubscriptionsHeadsets: thunk((actions, payload, helpers) => {
        const { isFetchingSubscriptionsHeadsets } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.subscriptions?.getSubEntities?.headsets?.readAll?.url?.replace('uniqueId', payload);

        if (!isFetchingSubscriptionsHeadsets) {
            actions.setIsFetchingSubscriptionsHeadsets(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllSubscriptionsHeadsets(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingSubscriptionsHeadsets(false))
        } else {
            return Promise.resolve();
        }
    }),
};

export default subscriptions;