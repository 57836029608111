import React, { useState, useRef } from 'react';
import Plot from 'react-plotly.js';
import Loader from '../widgets/Loader';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import Plotly from 'plotly.js/dist/plotly'
import PropTypes from 'prop-types';

const ClientCategories = ({ data = [], showOnlyGraph = false, width = '1000px', height = '1000px' }) => {

    const [proxiedData, isValid] = useCheckDataProps(data || []);

    const [infoDisplayed, setInfoDisplayed] = useState('video_views');

    const categories = proxiedData.map((item) => item.categories);
    const values = proxiedData.map((item) => item[infoDisplayed]);
    const totalValue = values.reduce((acc, curr) => acc + curr, 0);

    const graphRef = useRef(null);

    const handleLegendClick = (event) => {
        let hiddenCategories = event?.layout?.hiddenlabels ? [...event.layout.hiddenlabels] : [];
        if (!hiddenCategories.includes(event?.label)) {
            hiddenCategories?.push(event?.label);
        } else {
            hiddenCategories = hiddenCategories?.filter(item => item !== event?.label);
        }
        const visibleCategories = categories?.filter(c => !hiddenCategories.includes(c));
        const newTotal = proxiedData?.filter(item => visibleCategories?.includes(item.categories))?.map((item) => item[infoDisplayed])?.reduce((acc, curr) => acc + curr, 0);
        if (graphRef.current?.el) {
            Plotly.relayout(graphRef.current.el, {
                'annotations[0].text': `${newTotal}`,
            });
        }
    }

    return (
        data?.length ?
            !isValid ?
                <Alert variant="danger">Les données ne correspondent pas</Alert>
                : <div>
                    {!showOnlyGraph && <Row className='m-4'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Information:</Form.Label>
                                <Form.Select
                                    name="info-displayed"
                                    onChange={(e) => { setInfoDisplayed(e.target.value) }}
                                >
                                    <option value="video_views">Vue des vidéos</option>
                                    <option value="unique_clients_total">Clients ayant lu les vidéos</option>
                                    <option value="unique_devices_total">Casques ayant lu les vidéos</option>
                                </Form.Select>
                            </Form.Group >
                        </Col>
                    </Row>}
                    <div style={{ width, height, zIndex: "1" }}>
                        <Plot
                            ref={graphRef}
                            data={[
                                {
                                    values: values,
                                    labels: categories,
                                    type: 'pie',
                                    hole: 0.4,
                                    textinfo: 'none',
                                    hoverinfo: 'label+value+percent',
                                    hoverlabel: {
                                        bgcolor: 'white',
                                        bordercolor: 'black',
                                    },
                                }
                            ]}
                            style={{ width: '100%', height: '100%' }}
                            layout={{
                                annotations: [
                                    {
                                        font: {
                                            size: 20,
                                            color: '#000',
                                        },
                                        showarrow: false,
                                        text: `${totalValue}`,
                                        x: 0.5,
                                        y: 0.5,
                                    },
                                ],
                            }}
                            onLegendClick={(event) => handleLegendClick(event)} 
                        />
                    </div>
                </div>
            : <Loader />
    );
}

ClientCategories.propTypes = {
    data: PropTypes.array.isRequired,
    showOnlyGraph: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default ClientCategories;