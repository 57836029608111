import  React, {useCallback, useEffect, useMemo, useState} from 'react';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';

import useFreshworkWidget from '../../../hooks/useFreshworksWidget';

import './ScrollToTopButton.scss';

const ScrollToTopButton = () => {

    const {showWidget: showFreshworksWidget, hideWidget:hideFreshworksWidget} = useFreshworkWidget();

    const [isVisible, setIsVisible] = useState(false);

    const debouncedFilter = useMemo(() => {
        return debounce((scrolled) => {
           if (scrolled >= 300) {
               setIsVisible(true);
               hideFreshworksWidget();
           }
           else if (scrolled < 300) {
               setIsVisible(false);
               showFreshworksWidget();
           }
       }, 200)}, []);

    const scrollHandler = useCallback((e) => {
        const scrolled = document.documentElement.scrollTop;
        debouncedFilter(scrolled); 
    }, [debouncedFilter]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [scrollHandler]);

    return <>
    {
     isVisible &&   <Button id="scroll-to-top" onClick={scrollToTop}>
                        <i className="fas fa-caret-square-up"/> <span id="stt-text">&nbsp;Retour début</span>
                    </Button>
    }
    </>

}

export default ScrollToTopButton;