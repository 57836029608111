import {React} from 'react';
import './FaqComponent.scss';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';
import QuestionComponent from './QuestionComponent';
import VideosComponent from './videosComponent';

const FaqComponent = (props) => {
    const {faq} = props;

    const questionsDisplay = faq.questions ? faq.questions.map((question)=>{

                return(
                    <div key={'Faq_'.concat(HelperMetiers360.generateUniqueId())}>
                    
                    <QuestionComponent question = {question} />
                    </div>    
                )
            })
        :null;

            const videosDisplay = faq.videos ? <VideosComponent  videos={faq.videos} label = {faq.labelVideo}/> : null

    return (
        <>
        {videosDisplay}

        { questionsDisplay }

        </>
    );
};

export default FaqComponent;