import { useStoreState } from 'easy-peasy';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';

import ButtonLoaderComponent from '../../../widgets/ButtonLoaderComponent';


const QuestionFormComponent = ({question, quizUniqueId, deleteQuestionBackgroundAction}) => {

	const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;

	const backgroundFileInput = useRef(null);
	const [backgroundFileName, setBackgroundFileName] = useState('Sélectionnez un fichier');

	const [isSending, setIsSending] = useState(false);

	const [isBackgroundDeletable, setIsBackgroundDeletable] = useState(!!(question && question.links && question.links.background));

    const {csrfToken, formName} = ACTIONS['quizzes']['editProps']['questions'][question ? 'update': 'create'];
	const resetFormValue = () => {
		let form = document.getElementById('question-form');
		form.reset();
	}

	useEffect(() => {
		resetFormValue();
		setIsBackgroundDeletable(!!(question && question.links && question.links.background));
	}, [question])

	const deleteBackgroundUrl = () => {
		setIsSending(true)
		const options = {
			quizUniqueId,
			questionUniqueId: question.uniqueId
		}
		deleteQuestionBackgroundAction(options)
			.then(() => {
				setIsBackgroundDeletable(false);
			})
            .finally(() => setIsSending(false));
	}


	return (
		<div >
			<Form method="post" encType="multipart/form-data" id="question-form">
				<Row>
					<Form.Group as={Col} sm={12} md={6} >
						<Form.Label>Texte de la question</Form.Label>
						<Form.Control 
							name={`${formName}[caption]`} 
							as="textarea" 
							rows={5}
							defaultValue={question ? question.caption : ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez entrer le texte de la question</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} sm={12} md={6} >
						<Form.Label>Feedback bonne réponse</Form.Label>
						<Form.Control
							name={`${formName}[rightExplaination]`}
							as="textarea"
							rows={5}
							defaultValue={question && question.rightExplaination ? question.rightExplaination : ''}
						/>
					</Form.Group>

				</Row>

				<Row>

					<Form.Group as={Col} sm={12} md={6} >
						<Form.Label>Feedback mauvaise réponse</Form.Label>
						<Form.Control
							name={`${formName}[wrongExplaination]`}
							as="textarea"
							rows={5}
							defaultValue={question && question.wrongExplaination ? question.wrongExplaination : ''}
						/>
					</Form.Group>

					<Form.Group as={Col} sm={12} md={6} >
						<Form.Label>Feedback neutre</Form.Label>
						<Form.Control
							name={`${formName}[neutralExplaination]`}
							as="textarea"
							rows={5}
							defaultValue={question && question.neutralExplaination ? question.neutralExplaination : ''}
						/>
					</Form.Group>

				</Row>

				<Row>
					<Col sm={12} >
						<InputGroup>
							<InputGroup.Text>Ordre d'affichage</InputGroup.Text>
							<Form.Control
								name={`${formName}[position]`}
								type="number"
								defaultValue={question && question.position ? question.position : ''}
							/>
						</InputGroup>
					</Col>

				</Row>

				<br/>

				<Row>

					<Col sm={12} md={6} >
						<InputGroup>
                            <InputGroup.Text>Couleur texte</InputGroup.Text>
							<Form.Control
								name={`${formName}[color]`}
								type="color"
								defaultValue={question ? question.color : '#ffffff'}
							/>
						</InputGroup>
					</Col>

				</Row>

				<br/>

				<Row>
					<Col sm={12} md={6}>
					<Form.Label className='form-title'>Image de fond ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_BACKGROUND_MO} Mo max.)</Form.Label>
						<InputGroup>
                            <InputGroup.Text>Choisir image</InputGroup.Text>
							<div className="custom-file">
								<input
									id="backgroundInput"
									type="file"
									className="custom-file-input"
									name={`${formName}[background]`}
									accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
									ref={backgroundFileInput}
									onChange={() => setBackgroundFileName(backgroundFileInput.current.files[0].name)}
								/>

								<label className="custom-file-label" htmlFor="backgroundInput" data-browse="Parcourir">
									{backgroundFileName}
								</label>
							</div>
							<Form.Control
								type="hidden"
							/>
                        	<Form.Control.Feedback type='invalid' name='pdfFile'></Form.Control.Feedback>
							<Form.Control.Feedback type='invalid' name='feedback-file'></Form.Control.Feedback>
						</InputGroup>
					</Col>

					{isBackgroundDeletable &&
						<Col sm={2}>
							<ButtonLoaderComponent isSending={isSending} disabled={isSending} variant='danger' onClick={deleteBackgroundUrl} msg={<i className="fas fa-trash-alt"/>}  />
						</Col>
					}

				</Row>

				<br/>

				<Form.Check type='checkbox' id='question_multiple_choice' custom>
					<Form.Check.Input
						name={`${formName}[multipleChoice]`}
						type='checkbox'
						defaultChecked={question ? question.multipleChoice : false}
					/>
					<Form.Check.Label> Cochez s'il s'agit d'une question à choix multiple </Form.Check.Label>
				</Form.Check>

				<Form.Group >
					<Form.Control
						name="questionUniqueId"
						defaultValue={question ? question.uniqueId : ''}
						type="hidden"
					/>
				</Form.Group>

				<Form.Group >
					<Form.Control
						name={`${formName}[_token]`}
						value={csrfToken}
						type="hidden"
					/>
				</Form.Group>
			</Form>
		</div>
	);
}


export const submitQuestionForm = ({formName, question, quizUniqueId, questionPersistAction, fileContraints}) => {
    let error = false;
	
	const resetErrorDisplay = () => {
		document.getElementsByName(`${formName}[caption]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName(`${formName}[caption]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName("pdfFile")[0]?.classList.remove("is-invalid");
	}

	const submitForm = (formData) => {
        let options;
		
		if (question !== undefined && question !== null)  {
            options = {
				quizUniqueId: quizUniqueId,
				questionUniqueId: question.uniqueId,
				formData,
			}
        } else {
			options = {
				uniqueId: quizUniqueId,
				formData,
			}
		}

		return questionPersistAction(options)
			.catch(() => {
				return 'Il y a eu un probleme lors de l\'enregistrement de la question';
			})
	}
	
	let formData = new FormData(document.getElementById('question-form'));
	let backgroundInput = formData.get(`${formName}[background]`);
	
	resetErrorDisplay();

	if (!formData.get(`${formName}[caption]`)) {
		document.getElementsByName(`${formName}[caption]`)[0].classList.add("is-invalid");
		error = true;
	}

	if (backgroundInput?.size ) {
		if (backgroundInput.size > fileContraints.MAX_SIZE_IMAGE_BACKGROUND_KO ) {
			document.getElementsByName(`${formName}[caption]`)[0].classList.add("is-invalid");
			document.getElementsByName("pdfFile")[0].classList.add("is-invalid");
			document.getElementsByName(`feedback-file`)[0].innerHTML = "Image de fond trop volumineuse";
		}
		if (!fileContraints.IMAGE_MIME_TYPES.includes(backgroundInput.type) ) {
			document.getElementsByName(`${formName}[caption]`)[0].classList.add("is-invalid");
			document.getElementsByName("pdfFile")[0].classList.add("is-invalid");
			document.getElementsByName(`feedback-file`)[0].innerHTML = "Format de l'image de fond non autorisé";
		}
	}

	if (error) {
		return Promise.reject(new Error());
	} else {
		return submitForm(formData);
	}
}

export default QuestionFormComponent;