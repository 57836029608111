import React, { useState, useEffect } from "react";
import { useStoreActions } from 'easy-peasy';
import { Button, Row } from "react-bootstrap";
import OrderFormComponent from "./OrderFormComponent";
import { useParams} from "react-router-dom";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";
import SelectSingleClientComponent from "../../../widgets/selectClient/SelectSingleClientComponent";
import ReturnOrderFormComponent from "../../returnOrders/ReturnOrderFormComponent";

const UpdateOrderComponent = ({
    orderToUpdate,
    action,
    handleCreatingQuantity,
    clientsBase = null,
    setClientSelectionDone=null,
    orderType='preparatoryOrders'
}) => {
    const { fetchClientCRMById } = useStoreActions(actions => actions.clients);
    const { allClients } = useCustomGetStoreState('clients');

    const [clientCRM, setClientCRM] = useState(null);
    const [clientUniqueId, setUniqueIdClient] = useState([]);
    const {uniqueId} = useParams();

    useEffect(() => {
        action === 'update' && orderToUpdate?.clientUniqueId && setUniqueIdClient([orderToUpdate.clientUniqueId]);
    },[])

    useEffect(() => {
        if (clientUniqueId?.length != 0) {
            fetchClientCRM(clientUniqueId[0]);
        }
    }, [clientUniqueId]);

    useEffect(() => {
        if (uniqueId) {
            const isClient = allClients.findIndex((client)=>{ return client.uniqueId == uniqueId});
            if(isClient !== -1){
                fetchClientCRM(uniqueId);
            }
        }
    }, [uniqueId]);


   
    const fetchClientCRM = (uniqueId)=>{
        fetchClientCRMById(uniqueId)
        .then(response => { setClientCRM(response);
            if(setClientSelectionDone) {setClientSelectionDone(true);}
            if(handleCreatingQuantity){handleCreatingQuantity("one");}
        });
    }

    const setUrl = (uniqueId)=>{
        let location = document.location.href;
        window.history.replaceState(null, null, location.replace(location.substring(location.indexOf("/", location.indexOf("client")), location.lastIndexOf("/")), "/" + uniqueId)) 
    }

    const clientUnselected = (
        <Row>
            {orderType === 'preparatoryOrders' && <h2>Ajouter un bon de préparation</h2>}
            <p>Sélectionner un client : </p>
            <SelectSingleClientComponent
                nameSelect={"clientSelector"}
                selectedClients={clientUniqueId}
                setSelectedClients={setUniqueIdClient}
                initialClients = {clientsBase}
                mustRedirect={true}
            />
        </Row>
    );

    const clientName = allClients?.find(client => client?.uniqueId === clientCRM?.uniqueId)?.clientName;

    const clientSelected = clientCRM ? (
        <>
            <Row className="d-flex mb-2 mt-4">
                <h3 className="w-auto">
                    Client :&nbsp;<span className="fw-bold">{clientName}</span>
                </h3>
                <Button variant='secondary' className="ms-3 w-auto"
                    onClick={() => {
                        setClientCRM(null); 
                        setUniqueIdClient([]); 
                        orderType === 'preparatoryOrders' && setUrl(":uniqueId"); 
                        handleCreatingQuantity("");
                        setClientSelectionDone(false);
                    }}>
                        <i className="fas fa-edit"/> &nbsp;Choisir un autre client
                </Button>    
            </Row>
            <Row>{orderType === 'preparatoryOrders' 
                ? <OrderFormComponent client={clientCRM} />
                : <ReturnOrderFormComponent client={clientCRM} />}
            </Row>
        </>
    ):null;

    const creationCase = !clientCRM ? clientUnselected : clientSelected;
    const updateCase = orderType === 'preparatoryOrders' 
        ? <OrderFormComponent client={clientCRM} order={orderToUpdate} />
        : <ReturnOrderFormComponent client={clientCRM} order={orderToUpdate} />;

    return action === 'create' ? creationCase : updateCase;
}

export default UpdateOrderComponent;