import React from 'react';


const SelectParentClientComponent = (props) => {
    const {parentClientSelectorRef, onChange,  clients} = props;
    
    const parentClientsOptions=clients ?
        clients
            .sort((a, b) => a.clientName.localeCompare(b.clientName))
            .filter(client => client.childrenCount !=0 )
            .map(client => {
                return <option 
                    key={`option${client.uniqueId}`}
                    value={client.uniqueId}
                    >
                        {client.clientName}
                </option>
            })
        :null;

    const ClientsSelector = parentClientsOptions ? 
            <select 
                className="select-input"
                ref={parentClientSelectorRef}
                multiple={false}
                onChange = {onChange}
                >
                    <option value=''>--</option>
                    <option value='aucun'>-- Sans parent --</option>
                    {parentClientsOptions}
               
            </select>
        :null;

    return ClientsSelector;
}



export default SelectParentClientComponent;