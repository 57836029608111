import React, { useEffect, useState } from "react";
import { Row, Col, Alert, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import Loader from "../../components/widgets/Loader.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import { useStoreActions, useStoreState } from 'easy-peasy';
import ProfessionalsBoardComponent from "../../components/professionals/ProfessionalsBoardComponent.jsx";
import ProfessionalsTableComponent from "../../components/professionals/ProfessionalsTableComponent.jsx";
import PropTypes from 'prop-types';

const ProfessionalsTableWithParams = withURLParamsTableComponent(ProfessionalsTableComponent);

const ProfessionalsPageContent = ({ professionals, isFetching }) => {

    const { fetchAllProfessionals } = useStoreActions(actions => actions.romes);
    const { searchInput, search } = useSearchWithParams({
        placeholder: 'Rechercher par mots-clés (professionnel⋅le, métiers, centres d\'intérêt...)',
        delay: 400
    });
    const viewStylePreference = useStoreState(state => state.preferences.transversalPropsListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setTransversalPropsListStyle);

    const [filteredProfessionals, setFilteredProfessionals] = useState([]);

    useEffect(() => {
        professionals.length === 0 && !isFetching
            && fetchAllProfessionals();
    }, [])

    useEffect(() => {
        setFilteredProfessionals(professionals
            ?.filter(pro => !search
                || HelperMetiers360.isSearchInText(search, pro.name)
                || HelperMetiers360.isSearchInText(search, pro.mainJob?.label)
                || HelperMetiers360.isSearchInText(search, pro.jobAppellations?.map(job => job.label)?.join())
                || HelperMetiers360.isSearchInText(search, pro.areasOfInterest?.map(job => job.label)?.join())
                || HelperMetiers360.isSearchInText(search, pro.softSkills?.map(job => job.label)?.join())
                || HelperMetiers360.isSearchInText(search, pro.workingConditions?.map(job => job.label)?.join())
                || HelperMetiers360.isSearchInText(search, pro.videos?.map(job => job.videoName)?.join())
            )
        );
    }, [search, professionals]);

    const viewStyleType = [
        { value: 'cards', label: <i className="fas fa-address-card"></i>, view: <ProfessionalsBoardComponent professionals={filteredProfessionals} /> },
        { value: 'table', label: <i className="fas fa-table"></i>, view: <ProfessionalsTableWithParams professionals={filteredProfessionals} /> },
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    const isNoPros = !isFetching && professionals.length === 0;
    const isNoResult = !isNoPros && filteredProfessionals.length === 0;

    return (
        <>
            <Row className="item-page">
                <Col>
                    {searchInput}
                </Col>
                <Col className="col-auto">
                    <ToggleButtonGroup type="radio" name="option-view-pros" value={viewStyle} onChange={handleChange}>
                        {checkViewStyle}
                    </ToggleButtonGroup>
                </Col>
            </Row>
            <Row>
                {isFetching
                    ? <Loader />
                    : <>
                        {!isNoPros && viewStyleType.filter(type => type.value === viewStyle)[0].view}
                        {isNoPros && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun⋅e professionnel⋅le</Alert>}
                        {isNoResult && <Alert className="mt-3" variant="warning">Aucun⋅e professionnel⋅le ne correspond à votre recherche</Alert>}
                    </>}
            </Row>
        </>
    )
}

ProfessionalsPageContent.propTypes = {
    professionals: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
};

export default ProfessionalsPageContent;