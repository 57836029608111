import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useStoreActions} from "easy-peasy";

import SelectSingleClientComponent from '../../widgets/selectClient/SelectSingleClientComponent';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState';
import PropTypes from 'prop-types';

const MergeClientsComponent = (props) => {
    const {mergeClientsRef} = props;
    const { mergeTwoClients, fetchIsClientByIdDeletable } = useStoreActions(actions => actions.clients);

    const { allClientsCRM } = useCustomGetStoreState('clients');

    const [initialClientUUID,setInitialClientUUID] = useState([]);
    const [targetClientUUID,setTargetClientUUID] = useState([]);
    const [targetClientToCheckUUID,setTargetClientToCheckUUID] = useState([]);
    const [showMessageClientNotDeletable,setShowMessageClientNotDeletable] = useState(false);
   
 useEffect(() =>{
        if(initialClientUUID.length !==0 && targetClientUUID.length !==0 ){mergeClientsRef.current = {
            initialClientUUID:initialClientUUID,
            targetClientUUID:targetClientUUID,
            action:mergeTwoClients
        }}
        else{
            mergeClientsRef.current = null
        }
    },[initialClientUUID, targetClientUUID]);

    useEffect(() =>{
        setTargetClientUUID([]);
        setShowMessageClientNotDeletable(false);
        if(targetClientToCheckUUID.length > 0){
            let isSubscriber = subscribedClients.some(c => c?.uniqueId === targetClientToCheckUUID[0]);
            if(!isSubscriber){
                setTargetClientUUID(targetClientToCheckUUID);
            }
            else{
                fetchIsClientByIdDeletable(targetClientToCheckUUID[0]).then((result)=>{
                    if(result.isDeletable){
                        setTargetClientUUID(targetClientToCheckUUID);
                    }
                    else{
                        setShowMessageClientNotDeletable(true);
                    }
                })
            }
        }
    },[targetClientToCheckUUID]);

    const initialClientsList = allClientsCRM.filter(c => !c?.isActiveAxonaut);
    const targetClientList = allClientsCRM.filter(c => !c?.hasSubscriptions);
    const subscribedClients = allClientsCRM.filter(c => c?.isSubscriber);

    return <Container>
        <Row>
            <Alert variant='info'>Cette fonctionnalité permet la fusion entre un client <strong>non connu sur axonaut</strong>, 
            avec un client <strong>non abonné</strong> (<em>sans établissement ou avec établissement supprimable</em>).<br/>
            Les informations du client sélectionné à gauche seront appliquées au client sélectionné à droite, dans la mesure du possible.
            Mais, le cas non échéant, c'est les informations du client de droite qui priment.
            </Alert>
        </Row>
        <Row>
            <Col>
                <h4>Choix du client non connu sur axonaut</h4>
                <SelectSingleClientComponent 
                    initialClients={targetClientUUID?.length === 0 ?
                        initialClientsList
                        :initialClientsList.filter(c => targetClientUUID != c.uniqueId)}
                    optionLabel="name"
                    selectId="selectInitialClient"
                    selectedClients = {initialClientUUID}
                    setSelectedClients = {setInitialClientUUID}
                />
            </Col>
            <Col>
                <h4>Choix du client non abonné</h4>
                <SelectSingleClientComponent
                    initialClients={initialClientUUID?.length === 0 ? 
                        targetClientList
                        :targetClientList.filter(c => initialClientUUID != c.uniqueId)}
                    optionLabel="name"
                    selectId="selectTargetClient"
                    selectedClients = {targetClientToCheckUUID}
                    setSelectedClients = {setTargetClientToCheckUUID}
                />
                {showMessageClientNotDeletable &&
                    <Row>
                        <Alert variant='danger'>Ce client n'est pas supprimable , il ne peut donc pas être fusionné</Alert>
                    </Row>
                }
            </Col>
        </Row>
    </Container>;
}

export const submitMergedClients = (mergeClientsRef)=> {
    return mergeClientsRef.current.action({
        initialClientUUID:mergeClientsRef.current.initialClientUUID[0],
        targetClientUUID:mergeClientsRef.current.targetClientUUID[0]
    })
}

MergeClientsComponent.propTypes = {
    mergeClientsRef: PropTypes.any
}

export default MergeClientsComponent;