import React from 'react';

const SelectItemsComponent = (props) => {
    const { selectRef, onChange, items, itemKey, itemValue, itemValueDetails = null,
        hasError = false, width = null, maxWidth = null, labelNone = null } = props;
    
    const itemsOptions = items?.length > 0 && 
        items?.sort((a, b) => a?.[itemValue].localeCompare(b?.[itemValue]))
            .map(item => {
                return <option key={item?.[itemKey]} value={item?.[itemKey]}>
                    {item?.[itemValue] + (itemValueDetails ? " (" + item?.[itemValueDetails] + ")" : "")}
                </option>
            });

    return items?.length > 0 && 
        <select style={{width: width, maxWidth: maxWidth}}
            className={hasError ? 'error-input' : undefined}
            ref={selectRef}
            onChange = {onChange}>
                <option value=''>--</option>
                {labelNone && <option value='aucun'>-- Sans {labelNone} --</option>}
                { itemsOptions }
        </select>;
}

export default SelectItemsComponent;