import React, { useEffect, useState } from "react"
import { Button, Col, Row, Table, Form, Alert } from "react-bootstrap";
import './SelectProductComponent.scss';
import HelperMetiers360 from "../../../../../services/HelpersMetiers360";

const SelectProductComponent = ({allProducts, productsRef, noProduct, badQuantity, cannotSend, setNoProduct,
    setBadQuantity, commonProductsInfos, clientUniqueId, triggerUpdateCommonInfos, selectedIsRentingRef,
    productName = 'produit', displayRenting = true, refreshProductsRef = null, setRefreshProductsRef = null,
    isProductsListSetByUser = null, setIsProductsListSetByUser = null }) => {
    const [inputFocus, setInputFocus] = useState(null);
    const [isRenting, setIsRenting] = useState(selectedIsRentingRef?.current);

    const [productsList, setProductList] = useState(
        productsRef.current ?
            productsRef.current
            :[{
                uniqueId:null,
                quantity:null
            }]
        );
    const [isQuantityValid, setIsQuantityValid] = useState([]);
    const [isExistHeadsetProduct, setIsExistHeadsetProduct] = useState(false);

    useEffect(()=>{
        if(refreshProductsRef && setRefreshProductsRef !== null) {
            setProductList(productsRef.current);
            setRefreshProductsRef(false);
        } else if(productsList) {
            productsRef.current = productsList
        }

        if (cannotSend) {
            setBadQuantity(false);
            setNoProduct(false);
        }
        if(selectedIsRentingRef?.current !== null && selectedIsRentingRef?.current !== undefined) {
            setIsRenting(selectedIsRentingRef?.current);
        }
    },[productsList, refreshProductsRef]);

// Added to manage the following edge case: when the user validates the orders with errors, modifying anything in the products' section will remove the errors message for readability. Manage the case when a change to a quantity is this change, so focus remains on the modified quantity input

    useEffect(()=>{
        if (!cannotSend && inputFocus) {
            document.getElementById(`input${clientUniqueId?clientUniqueId:'common'}${inputFocus}`)?.focus();
        }
    },[cannotSend]);

    const updateProductsList = ({idx,item,value}) => {
        if(!isProductsListSetByUser && setIsProductsListSetByUser !== null)
            setIsProductsListSetByUser(true);
        const newProductsList = [...productsList];
        newProductsList[idx] = { ...newProductsList[idx], [item]: value };
        productsRef.current = newProductsList;
        setProductList(newProductsList)
        if (cannotSend) {
            setBadQuantity(false);
            setNoProduct(false);
            setInputFocus((item === 'quantity') ? idx : null);
        }
    };

    const removeRow = (idx) => {
        const newProductsList = [...productsList];
        newProductsList.splice(idx,1);
        productsRef.current = newProductsList
        setProductList([...newProductsList]);
    };


    const onAddRow = () => {
        const newProductsList = [...productsList];
        newProductsList.push({
            uniqueId:null,
            quantity:null
        });
        setProductList([...newProductsList]);
    }

  

    useEffect(() => {
        const tempList = [];
        productsList?.map((x,i)=>{
            tempList.push(!!x.quantity);
            if (x.quantity && parseInt(x.quantity) < 1){
                tempList[i] = false;
            }
        });
        setIsQuantityValid(tempList);

        const isExistHeadsetProduct = productsList.some(product => 
            allProducts.some(prod => prod.uniqueId === product.uniqueId && prod.reference !== "" && prod.reference !== null));
        setIsExistHeadsetProduct(isExistHeadsetProduct);

        if(!isExistHeadsetProduct) updateIsRenting(null);
    },[productsList]);

    useEffect(() => {
        if(commonProductsInfos !== undefined){
            setProductList([...commonProductsInfos]);
        }
    },[triggerUpdateCommonInfos]);

    const prodcutsRow = productsList?.map((product, idx) => (
        <tr  key={idx}>
            <td width="5%">{idx+1}</td>
            <td width="60%">
                <Form.Control className="select-product"
                    as="select" size="sm" aria-label="Choix produit" 
                    value={product.uniqueId || ""}
                    onChange={e => {updateProductsList({idx:idx, item:'uniqueId', value:e.target.value})}}
                    defaultValue={product.uniqueId ? product.uniqueId:''}
                    >
                    {!product.uniqueId && <option value="" disabled>Choisissez un {productName}</option> }
                    {allProducts.map((availableProduct) => (
                        <option 
                            key={'row_'+HelperMetiers360.generateUniqueId()+'_'+availableProduct.uniqueId}
                            value={availableProduct.uniqueId}
                        >
                            {availableProduct.name}
                        </option>
                        ))}
                </Form.Control>
            </td>
            <td className="td-quantity">
                <Form.Control  className="select-quantity" type="number" size="sm" defaultValue={product.quantity}
                    onChange={e => {updateProductsList({idx:idx, item:'quantity', value:e.target.value})}}
                    name="quantity"
                    min="1" 
                    id={`input${clientUniqueId?clientUniqueId:'common'}${idx}`}>
                </Form.Control>
            </td>
            <td><Button size="sm" variant="danger" onClick={e => {e.preventDefault();removeRow(idx)}}><i className="fas fa-trash-alt" /></Button></td>
        </tr>
    ));

    const updateIsRenting = value => { 
        if(selectedIsRentingRef) {
            selectedIsRentingRef.current = value
            setIsRenting(value);
        }
    };

    return <>
        <Row className="mt-5">
            <Col>
                <h3>{HelperMetiers360.capitalize(productName)}s</h3>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>{HelperMetiers360.capitalize(productName)}</th>
                        <th>Quantité</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {prodcutsRow}
                        <tr>
                            <td className="text-center" colSpan={4}>
                                <Button size="sm" onClick={onAddRow} variant="secondary">
                                    <i className="fas fa-plus" />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        {(cannotSend && noProduct) && <Alert variant="danger">Merci d'inclure au moins un {productName}</Alert>}
        {(cannotSend && badQuantity) && <Alert variant="danger">Merci de rensigner une quantité pour tous les {productName}s</Alert>}
        { displayRenting && isExistHeadsetProduct && <div className="d-flex justify-content-center">
            <div className='choices-selector-inline'>
                <div className={isRenting ? 'selected' : null}
                    onClick={() => updateIsRenting(true)}
                    onKeyDown={() => updateIsRenting(true)}>
                    Location
                </div>
                <div className={isRenting === false ? 'selected' : null}
                    onClick={() => updateIsRenting(false)}
                    onKeyDown={() => updateIsRenting(false)}>
                    Vente
                </div>
            </div>
        </div>}
    </>
};

export default SelectProductComponent;