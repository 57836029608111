import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import "./ReturnOrderDetailsComponent.scss";
import PropTypes from 'prop-types';
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link, useNavigate } from "react-router-dom";
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import Loader from "../../../widgets/Loader";
import OrderLinesTableComponent from "../../preparatoryOrders/OrderLinesTableComponent";
import HeadsetTableComponent from "../../../headset/HeadsetTableComponent";
import { useModal } from "../../../../hooks/useModal";
import ButtonLoaderComponent from "../../../widgets/ButtonLoaderComponent";
import { useToast } from "../../../../hooks/useToast";

const ReturnOrderDetailsComponent = ({ order }) => {
    const { fetchSubscriptionsByClientId} = useStoreActions(actions => actions.clients);
    const { fetchHeadsetsByUniqueIds} = useStoreActions(actions => actions.headsets);
    const { headsetsByUniqueIds, isFetchingHeadsetsByUniqueIds } = useStoreState(state => state.headsets);
    const { fetchContactByUniqueId } = useStoreActions(actions => actions.users);
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const orderSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('returnOrders'));
    const { validateReturnOrder, unvalidateReturnOrder, closeReturnOrder, deleteReturnOrder } = useStoreActions(actions => actions.orders);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending: setIsSendingModal } = useModal();
    const toast = useToast();
    const navigate = useNavigate();

    const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);
    const [isFetchingContact, setIsFetchingContact] = useState(false);
    const [subscriptions, setSubscriptions] = useState({});
    const [isSending, setIsSending] = useState(false);
    const [contact, setContact] = useState(null);

    useEffect(() => {
        setIsFetchingSubscription(true);
        setIsFetchingContact(true);

        fetchSubscriptionsByClientId(order.clientUniqueId)
            .then(subscriptions => {
                setSubscriptions(subscriptions?.filter(sub => order.subscriptionsUniqueIds?.includes(sub.uniqueId)));
            })
            .finally(() => setIsFetchingSubscription(false));

        fetchContactByUniqueId(order.contactUniqueId)
            .then(contactFetched => {
                setContact(contactFetched);
            setIsFetchingContact(false);
        });

        if(order.returnedProducts?.length > 0) {
            const returnedProductUniqueIds = order.returnedProducts?.map(returnedProduct => returnedProduct.headsetUniqueId);
            fetchHeadsetsByUniqueIds(returnedProductUniqueIds);
        }
    },[]);

    const headsetsReturned = useMemo(() => {
        if(order.returnedProducts?.length > 0 && headsetsByUniqueIds?.length > 0) {
            const returnedProductUniqueIds = order.returnedProducts?.map(returnedProduct => returnedProduct.headsetUniqueId);

            return headsetsByUniqueIds
                .filter(headset => returnedProductUniqueIds?.includes(headset.uniqueId))
                .map(headset => {
                    const returnedProduct = order.returnedProducts?.find(returnedProduct => 
                        returnedProduct.headsetUniqueId == headset.uniqueId);
                    return { ...headset, returnedTimestamp: returnedProduct?.returnedDate };
                });
        } else {
            return [];
        }
    }, [order.returnedProducts, headsetsByUniqueIds])

    const confirmCloseModal = () => {
        setModalData({
            ...modalData,
            header: <>Clôture d'un bon de retour</>,
            content: <h5>Etes-vous sûr⋅e de vouloir clôturer ce bon de retour ?</h5>,
            validateButton: 'Clôturer',
            cancelButton:'Annuler',
            onValidate: () => {
                setIsSendingModal(true);
                closeReturnOrder({uniqueId: order.uniqueId})
                    .then(() => {
                        toast.success()
                        setModalShow(false);
                    })
                    .finally(() => setIsSendingModal(false));
            },
        });
        setModalShow(true);
    };

    const onAction = (type) => {
        setIsSending(true);
        const payload = {uniqueId: order.uniqueId};

        let action = null;
        switch(type) {
            case "validate":
                action = validateReturnOrder;
                break;
            case "unvalidate":
                action = unvalidateReturnOrder;
                break;
            default:
                break;
        }

        action(payload)
            .finally(() => setIsSending(false));
    }

    const deleteOrderModal = (order) => {
        setModalData({
            ...modalData,
            header: <>Suppression d'un bon de retour</>,
            content: <h5>Etes-vous sûr⋅e de vouloir supprimer ce bon de retour ?</h5>,
            resetButton: 'Supprimer',
            cancelButton:'Annuler',
            onReset: () => {
                setIsSendingModal(true);
                deleteReturnOrder({ uniqueId: order.uniqueId })
                    .then(() => {
                        toast.open({ message: "Suppression du bon réalisée avec succès", variant: "success" })
                        setModalShow(false);
                        orderSlugs?.readAll && navigate(orderSlugs.readAll);
                    })
                    .finally(() => setIsSendingModal(false));
            },
        });
        setModalShow(true);
    };

    const updateOrderButton = orderSlugs?.update
        && <Button variant="secondary" as={Link} to={orderSlugs.update?.replace(':uniqueId', order?.uniqueId)}>
            <i className="fas fa-edit"></i>&nbsp;Éditer
        </Button>;

    const goToClient = (clientUniqueId) => {
        clientsSlugs && navigate(clientsSlugs.readOne.replace(':uniqueId', clientUniqueId))
    }

    const noValue = (name) => <span className="text-danger">
        <i className="fas fa-exclamation-triangle mr-1"/>{name ? (name + " n") : "N"}on indiqué
    </span>;

    const headsetsToReturn = order?.productsToReturn?.map((product) => {
        return {
            uniqueId: product?.uniqueId,
            id: product?.id,
            name: product?.product?.name,
            quantity: product?.quantity
        }
    });

    const returnHeadsetTypeReferences = [...new Set(order?.productsToReturn?.map(pR => pR.product.reference))];

    const renderHeadsetContent = ()=> {
        if (headsetsReturned?.length > 0) {
          return <HeadsetTableComponent 
            headsets={headsetsReturned} 
            returnHeadsets={true}
            returnHeadsetTypesExpected={returnHeadsetTypeReferences} />;
        } else {
          return <Alert variant="warning">Aucun casque retourné pour le moment</Alert>;
        }
      }

    const headsetsToReturnCount = order?.productsToReturn
        ? order?.productsToReturn.reduce((accumulator, current) =>
            accumulator + current.quantity
            , 0) 
        : noValue();

    const isEditable = order?.returnOrderStatus?.value === 'draft';
    const isValidatable = order?.returnOrderStatus?.value === 'draft' 
        && order?.clientUniqueId
        && order?.contactUniqueId
        && headsetsToReturnCount > 0
        && order?.subscriptionsUniqueIds?.length > 0
        && order?.provider?.uniqueId
        && order?.returnDueDate
    const isUnvalidatable = order.returnOrderStatus.value === 'validated';
    const isClosable = order.returnOrderStatus.value === 'processed';
    
    const validateButton = <ButtonLoaderComponent
        isSending={isSending}
        onClick={() => onAction("validate")}
        disabled={!isValidatable}
        variant="success"
        msg="Valider"
        iconClassName="fas fa-check text-white" />;

    return <Container>
        <Row className="row-midspace main-infos">
            <Col>
                <h3>
                    Client :
                    <Button className="btn-link ms-2" 
                        onClick={(e) => { e.preventDefault(); goToClient(order.clientUniqueId); }}>
                        <h3>{order.clientName}</h3>
                    </Button>
                </h3>

                { isFetchingSubscription
                    ? <div id="loader-subscription-return-order"><Loader/></div>
                    : subscriptions?.length > 0
                        ? subscriptions?.map(subscription => 
                            <div key={subscription.uniqueId}>
                                <p>
                                    <span className="fw-bold">
                                    {subscription.subscriptionName ?? "Abonnement"}
                                    </span>
                                    <span className="ms-2 subscription-dates">(
                                        {HelperMetiers360.getdisplayDateType(subscription.createdAt?.date, "day")}
                                        &nbsp;-&nbsp;
                                        {HelperMetiers360.getdisplayDateType(subscription.subscriptionEndDate, "day")}
                                    )</span>
                                </p>
                            </div>)
                        : <p><span className="fw-bold">{noValue("Abonnement")}</span></p>
                }

                <p>Retour prévu : &nbsp; <span className="fw-bold">{ 
                    order?.returnDueDate 
                        ? HelperMetiers360.getdisplayDateType(order.returnDueDate, "day")
                        : noValue()
                }</span></p>
                <p>Prestataire :&nbsp; <span className="fw-bold">{ order?.provider?.name ? order?.provider?.name : noValue() }</span></p>
                <p>Nombre de casques prévu :&nbsp; <span className="fw-bold">{ headsetsToReturnCount !== 0 ? headsetsToReturnCount : noValue() }</span></p>
                <p>Raison du retour :&nbsp; <span className="fw-bold">{ order?.typeOfReturn?.label ? order?.typeOfReturn?.label : noValue() }</span></p>
            </Col>

            <Col className="col-midspace">
                <h3>Informations de contact</h3>
                {isFetchingContact
                    ? <div id="loader-subscription-return-order"><Loader/></div>
                    : <>
                        <p>{ !contact && noValue("Contact") }</p>
                        <p><span className="fw-bold">
                            { contact?.firstName || contact?.lastName
                                ? HelperMetiers360.capitalize(contact?.firstName) + " " + contact?.lastName?.toUpperCase()
                                : ""}
                        </span></p>
                        <p>{ contact?.email && contact?.email }</p>
                        <p>{ contact?.phoneNumber && contact?.phoneNumber}</p>
                        <p>{ contact?.cellphoneNumber && contact?.cellphoneNumber}</p>
                    </>}
            </Col>
        </Row>

        {headsetsToReturn?.length > 0
            && <Row className="row-midspace mt-5">
                <Col>
                    <h3>Contenu du bon de retour</h3>
                    <OrderLinesTableComponent orderLines={headsetsToReturn}/>
                    { !headsetsToReturn.every((x) => x.quantity && parseInt(x.quantity) > 0)
                        && <div className="text-danger text-right mr-4 fw-bold">
                            <i className="fas fa-exclamation-triangle mr-1"/>Quantités non valides
                        </div>
                    }
                </Col>
            </Row>
        }


        {order?.returnOrderStatus?.value !== "draft" && (
        <Row className="row-midspace">
            <h3>Casques déjà retournés</h3>
            { headsetsReturned.length > headsetsToReturnCount
                && <Alert variant="danger">Attention : Il y a plus de casques retournés que prévu !</Alert>
            }
            {isFetchingHeadsetsByUniqueIds ? (
                <Loader/>
            ) : (
            renderHeadsetContent()
            )}
        </Row>
        )}


        {order.comment && <Row className="row-allcenter">
            <Col className="col-allcenter">
                <Card className="display-comments">
                    <Card.Header className="fw-bold">Commentaire :</Card.Header>
                    <Card.Body><p>{order.comment}</p></Card.Body>
                </Card>
            </Col>
        </Row>
        }

        <Row className="row-midspace row-allcenter mt-5">
            <Col className="col-allcenter">
                {isEditable && updateOrderButton}
            </Col>

            <Col className="col-allcenter">
                {order.isDeletable
                    && <Button variant="danger" onClick={() => deleteOrderModal(order)}>
                        <i className="fas fa-trash-alt"></i> &nbsp;Supprimer
                    </Button>
                }
            </Col>

            <Col className="d-flex flex-column align-items-center">
                {isEditable
                    && (!isValidatable
                        ? <OverlayTrigger placement="left" overlay={
                                <Tooltip>Il manque des informations pour pouvoir valider ce bon de retour</Tooltip>
                            }>
                            <span>{validateButton}</span>
                        </OverlayTrigger>
                        : validateButton)
                }
            </Col>

            {isUnvalidatable
                && <Col className="d-flex flex-column align-items-center">
                    <ButtonLoaderComponent
                        isSending={isSending}
                        onClick={() => onAction("unvalidate")}
                        variant="danger"
                        msg="Invalider"
                        iconClassName="fas fa-edit" />
                </Col>}

            {isClosable
                && <Col className="d-flex flex-column align-items-center">
                    <ButtonLoaderComponent
                        isSending={isSending}
                        onClick={() => confirmCloseModal()}
                        variant="success"
                        msg="Clôturer"
                        iconClassName="fas fa-check text-white" />
                </Col>}
        </Row>
        {modalComponent}
    </Container>;
}

ReturnOrderDetailsComponent.propTypes = {
  order: PropTypes.object.isRequired
};

export default ReturnOrderDetailsComponent;
