import { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";


const useCustomGetStoreState = (storeName) => {
    const store = useStoreState(state => state[storeName])
    const {setIsUpdateNecessary} = useStoreActions(actions => actions[storeName]);
    
    
    useEffect(() => {
        const fetchingAllKey = Object.keys(store).find(key => key.toLowerCase() === `isfetchingall${storeName}`.toLowerCase())

        if (!store.initialFetchDone && !store[fetchingAllKey]) {
            setIsUpdateNecessary(true);
        }
    }, [])


    //? je renvoie l'integralite du store, peut etre renvoyer juste le allEntity?
    return store;
}

export default useCustomGetStoreState;