import React, { useEffect } from "react";

import { useStoreActions, useStoreState } from 'easy-peasy';
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import PropTypes from 'prop-types';
import useBackButton from "../../hooks/useBackButton";
import WelcomeMessageFormComponent from "./WelcomeMessageFormComponent";
import Loader from "../widgets/Loader";

const CreateWelcomeMessage = ({ type = null }) => {

	const messageSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("welcomeMessage"));

	const { messageById } = useStoreState(state => state.welcomeMessage);
	const { fetchMessageById, isFetchingOneMessage } = useStoreActions(actions => actions.welcomeMessage);

	const { uniqueId } = useParams();

	useEffect(() => {
		if (type === 'edit' && uniqueId) {
			fetchMessageById(uniqueId);
		}
	}, [uniqueId]);

    const { backButtonComponent } = useBackButton()

	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>{type === "edit" ? "Modifier une bannière d'accueil" : "Ajout d'une bannière d'accueil"}</h2>
					{backButtonComponent}
				</Col>
			</Row>
			{type === 'edit' && (isFetchingOneMessage || !messageById) ?
				<Loader />
				: <WelcomeMessageFormComponent existingMessage={type === 'edit' ? messageById : null} />
			}

		</div>
	);
};

CreateWelcomeMessage.propTypes = {
	type: PropTypes.string,
};

export default CreateWelcomeMessage;
