import React from "react";
import DynamicTable from "../../widgets/dynamicTable/DynamicTable";
import HelperMetiers360 from "../../../services/HelpersMetiers360";

const ParcelTableComponent = ({
    parcels
}) => {

    const parcelDeliveryLines = (parcelDeliveryLine) => {
        return parcelDeliveryLine?.map((deliveryLine) => (
            <div>{deliveryLine.quantity + " " + deliveryLine.orderLineName }</div>
        ))
    }


    const sortType = parcels?.length > 0 ?
    [
        {
            value : 'orderNumber', label : "Numero d'ordre",
            test : HelperMetiers360.isArrayContainsValue(parcels, "orderNumber"),
            method : (a, b) =>  b.orderNumber  - a.orderNumber ,
            display: (parcel) => parcel.orderNumber ? parcel.orderNumber : '-'
        },
        {
            value : 'trackingNumber', label : 'Numero de suivi',
            test : HelperMetiers360.isArrayContainsValue(parcels, "trackingNumber"),
            method : (a, b) =>   b.trackingNumber  - a.trackingNumber,
            display: (parcel) => parcel.trackingNumber  ? parcel.trackingNumber : '-'
        },
        {
            value : 'trackingUrl', label : 'Suivi',
            test : HelperMetiers360.isArrayContainsValue(parcels, "trackingUrl"),
            display: (parcel) => parcel.trackingUrl  ?
                <a href={parcel.trackingUrl}>lien</a>
                : '-'
        },
        {
            value : 'statusLabel', label : 'Status',
            test : HelperMetiers360.isArrayContainsValue(parcels, "statusLabel"),
            method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.statusLabel, b.statusLabel),
            display: (parcel) => parcel.statusLabel ? parcel.statusLabel : '-'
        },
        {
            value : 'deliveryServiceName', label : 'Service de livraison',
            test : HelperMetiers360.isArrayContainsValue(parcels, "deliveryServiceName"),
            method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.deliveryServiceName, b.deliveryServiceName),
            display: (parcel) => parcel.deliveryServiceName ? parcel.deliveryServiceName : '-'
        },
        {
            value : 'deliveryLines', label : 'Contenu du colis',
            test : parcels?.find(parcel => parcel.deliveryLines.length > 0) !== undefined,
            method : (a, b) => b.deliveryLines.length - a.deliveryLines.length,
            display: (parcel) => parcelDeliveryLines(parcel.deliveryLines)
        }
    ]
    :null;

    return sortType && <DynamicTable
        contentTable = {parcels}
        contentSort = {sortType}
        valueInitSort = "orderNumber"
        index = 'uniqueId'
        filename="colis_bon_preparation" />;
};

export default ParcelTableComponent;