import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { Button, Form, Alert } from 'react-bootstrap';
import { useParams } from "react-router-dom";

import PasswordFormGroup from '../../components/security/PasswordFormGroup';

import axios, { URLS } from "../../services/axios";
import ButtonLoaderComponent from '../../components/widgets/ButtonLoaderComponent';
import HelperMetiers360 from "../../services/HelpersMetiers360";

import './Login.scss';
import './Register.scss';

const Register = ( ) => {
    const {inviteToken} = useParams();

    const { apiRoutes } = useStoreState(actions => actions.params);

    const REACT_APP_API_CHECK_ACTIVATION = apiRoutes.checkActivation;

    const [isSending, setIsSending] = useState(false);
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [csrfToken, setCsrfToken] = useState('');
    const [sendUrl, setSendUrl] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const [formIsValid, setFormIsValid] = useState(false);

    const [alertUser, setAlertUser] = useState(null);
    const [requiredFormations, setRequiredFormations] = useState([]);
    const [selectedFormationSession, setSelectedFormationSession] = useState('');

    useEffect(() => {
        setFormIsValid(checkPassword && document.getElementById('registerForm').checkValidity())
    }, [firstName, lastName, email, password, checkPassword]);
      
   
  
    const formationsOptions=requiredFormations ?
    requiredFormations
        .sort((a, b) =>  a?.formationName?.localeCompare(b?.formationName))
        .map(formation => {
            return <optgroup label={formation?.formationName}>
                      {
                         formation?.formationSessions?.map(session => {
                            return <option key={'option-formation-required-'.concat(session.uniqueId)} value={session.uniqueId} >
                                      session du { HelperMetiers360.getdisplayDateType(session.startsAt)}
                                    </option>
                        }) 
                      }
                    </optgroup>
        })
    :null;


  

    const spinner = <ButtonLoaderComponent />;

    useEffect(() => {
        const formData = new FormData();
        formData.append('inviteToken',inviteToken);
        axios.post(REACT_APP_API_CHECK_ACTIVATION,formData)
            .then((response) => {
                setCsrfToken(response.csrfToken);
                setEmail(response.email);
                setFirstName(response.firstName);
                setLastName(response.lastName);
                setSendUrl(response.url);
                setRequiredFormations(response.requiredFormations);
            })
            .catch((error) => {

                setAlertUser(<Alert className="text-center" variant='warning'>
                    Une erreur est survenue<br />
                    <a href={URLS.BASE_URL}>Revenir à la page d'accueil</a></Alert>);
            })
    }, [REACT_APP_API_CHECK_ACTIVATION,URLS.BASE_URL,inviteToken])


    const handleClickSubmit = () => {
        let errors = { };
       
        if(requiredFormations.length > 0){
            if(selectedFormationSession == ''){
               errors.formationSession = 'Veuillez choisir une session';
             }
        }
        if (Object.keys(errors).length) {
			setFormErrors(errors);
            return;
		}


        if (formIsValid) {
            setIsSending(true);
            const formData = new FormData();
            formData.append('inviteToken',inviteToken);
            formData.append("active_account[lastName]",lastName);
            formData.append("active_account[firstName]", firstName);
            formData.append("active_account[email]", email);
            formData.append("active_account[plainPassword][second]", password);
            formData.append("active_account[plainPassword][first]", password);
            if(selectedFormationSession != ''){
            formData.append("requiredFormationSessionId",selectedFormationSession[0]);
            }
            formData.append("active_account[_token]", csrfToken);
     
         
            axios.post(sendUrl, formData).then((response) => {
                setIsSending(false);
                setAlertUser(<Alert className="text-center" variant='info'>
                    {response.msg}<br />
                    <a href={URLS.BASE_URL}>Revenir à la page d'accueil</a></Alert>);
            }).catch((reject) => {
                setIsSending(false);
                reject.json().then( (content) => {
                    setFormErrors(content['errors'])
                })
            });
        }
    };

    const form = <Form id="registerForm">
        <Form.Text>
            Inscription de {email}
        </Form.Text>
        <Form.Group controlId="lastName">
            <Form.Label>Nom</Form.Label>
            <Form.Control
                required
                name='active_account[lastName]'
                type="text"
                placeholder="Entrer votre nom"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                isValid={lastName !== ''}
                isInvalid={formErrors.lastName}
            />
			<Form.Control.Feedback type="invalid">{formErrors.lastName}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="firstName">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
                required
                name='active_account[firstName]'
                type="text"
                placeholder="Entrer votre prénom"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                isValid={firstName !== ''}
                isInvalid={formErrors.firstName}
            />
			<Form.Control.Feedback type="invalid">{formErrors.firstName}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
                required
                name='active_account[email]'
                type="email"
                placeholder="Entrer votre adresse email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                isInvalid={formErrors.email}
            />
			<Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
            <PasswordFormGroup 
                userExist={false} 
                onValid={(validation, validPass) => { setCheckPassword(validation); setPassword(validPass) }}
            />
        </Form.Group>
        {requiredFormations.length > 0 &&
        <Form.Group>
        <Form.Label>Webinaire {requiredFormations[0].formationCategories.includes('kick-off')? "de lancement" : "prise en main"}</Form.Label>
            <Form.Select  isValid={selectedFormationSession != ''}  isInvalid={formErrors.formationSession} name='active_account[requiredFormationSessionId]' onChange={(e) => setSelectedFormationSession([...e.target.selectedOptions].map(opt => opt.value))} >
            <option disabled selected value>choisissez une date de session</option>
            {formationsOptions}	
            </Form.Select>
            <Form.Control.Feedback type="invalid">{formErrors.formationSession}</Form.Control.Feedback>
        </Form.Group>
         }

        <ButtonLoaderComponent 
            variant="primary" 
            className="mt-4 mb-0" 
            disabled={!formIsValid} 
            onClick={handleClickSubmit}
            msg="S'inscrire"
            isSending={isSending}
            />
    </Form>;

    return (<>{alertUser ? alertUser : form}</>)
}
export default Register