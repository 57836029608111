import { action } from "easy-peasy";

const preferencesInitialState = {
    videoPlayerType: 'flat',
    videoListStyle: 'cards',
    videoBoardSortType: 'date',
    storyListStyle: 'cards',
    storyBoardSortType: 'date',
    sequenceListStyle: 'cards',
    sequenceBoardSortType: 'date',
    quizListStyle: 'cards',
    quizBoardSortType: 'date',
    pedagogicModuleListStyle: 'cards',
    pedagogicModuleBoardSortType: 'date',
    formationListStyle: 'cards',
    formationBoardSortType: 'date',
    groupListStyle: 'table',
    professionalsListStyle: 'cards',
    transversalPropsListStyle: 'cards',
    codeRomesListContent: 'withContentM360',
    workingConditionsListContent: 'withContentM360',
    interactionsFilters: {
        dateFrom: "",
        dateTo: "",
        typeId: null,
        tagOpeId: null,
        contactM360Id: null,
        notDone: null,
        notAxonaut: null
    },
    tablesColumnsVisible : {}
}

const preferences = {
    ...preferencesInitialState,

    // video player style [video360 | flat]
    setVideoPlayerType: action((state, payload) => {
        state.videoPlayerType = payload
    }),

    // video list display style [cards | table]
    setVideoListStyle: action((state, payload) => {
        state.videoListStyle = payload;
    }),

    // For videoBoardComponent sort type [date | viewCount]    
    setvideoBoardSortType: action((state, payload) => {
        state.videoBoardSortType = payload;
    }),

    // story list display style [cards | table]
    setStoryListStyle: action((state, payload) => {
        state.storyListStyle = payload;
    }),

    // For storyBoardComponent sort type [date | viewCount | isLiked | isUnliked]    
    setStoryBoardSortType: action((state, payload) => {
        state.storyBoardSortType = payload;
    }),
    // sequence list display style [cards | table]
    setSequenceListStyle: action((state, payload) => {
        state.sequenceListStyle = payload;
    }),

    // For sequenceBoardComponent sort type [date | viewCount | isLiked | isUnliked]    
    setSequenceBoardSortType: action((state, payload) => {
        state.sequenceBoardSortType = payload;
    }),

    // quiz list display style [cards | table]
    setQuizListStyle: action((state, payload) => {
        state.quizListStyle = payload;
    }),

    // For quizBoardComponent sort type [date | name]    
    setQuizBoardSortType: action((state, payload) => {
        state.quizBoardSortType = payload;
    }),

    // pedagogic module list display style [cards | table]
    setPedagogicModuleListStyle: action((state, payload) => {
        state.pedagogicModuleListStyle = payload;
    }),

    // For pedagogicModuleBoardComponent sort type [date | name]
    setPedagogicModuleBoardSortType: action((state, payload) => {
        state.pedagogicModuleBoardSortType = payload;
    }),


    // formtion module list display style [cards | table]
    setFormationListStyle: action((state, payload) => {
        state.formationListStyle = payload;
    }),

    // For formationBoardComponent sort type [date | name]
    setFormationBoardSortType: action((state, payload) => {
        state.formationBoardSortType = payload;
    }),

    // group list display style [tree | table]
    setGroupListStyle: action((state, payload) => {
        state.groupListStyle = payload;
    }),

    // For interactionsPage filters
    setInteractionsFilters: action((state, payload) => {
        state.interactionsFilters = payload;
    }),

    setProfessionalsListStyle: action((state, payload) => {
        state.professionalsListStyle = payload;
    }),

    setCodeRomesListContent: action((state, payload) => {
        state.codeRomesListContent = payload;
    }),

    setWorkingConditionsListContent: action((state, payload) => {
        state.workingConditionsListContent = payload;
    }),

    setTransversalPropsListStyle: action((state, payload) => {
        state.transversalPropsListStyle = payload;
    }),

    // For table options
    addTableColumnsVisible: action((state, payload) => {
        const {tableName, tableColumnsVisible} = payload;

        if(state.tablesColumnsVisible[tableName] === undefined) {
            state.tablesColumnsVisible[tableName] = tableColumnsVisible;
        }
    }),
    updateTableColumnsVisible: action((state, payload) => {
        const {tableName, tableColumnsVisible} = payload;

        if(state.tablesColumnsVisible[tableName]) {
            state.tablesColumnsVisible[tableName] = {
                ...state.tablesColumnsVisible[tableName],
                ...tableColumnsVisible
            }
        }
    })
}

export default preferences;