import React, {useState} from 'react';
import { Accordion } from 'react-bootstrap';

const QuestionComponent = ({question}) => {

  const [clicked, setClicked] = useState(false);

  const getIcon =  () => {
        if(clicked){
            return(
                <i className="fas fa-duotone fa-minus-circle"></i>
            )
        }else{
            return(
                <i className="fas fa-duotone fa-plus-circle"></i>
            )
        }
    }

    const handleClick = () => {
        setClicked((prevClick) => {
            return !prevClick;
        });
    }

    return (
        <div>
            <Accordion className='accordion'>
                <Accordion.Item eventKey = "0" className='accordionItem'>
                    <Accordion.Header className='accordionHeader' onClick={handleClick}>{getIcon()}{question.questionLabel}</Accordion.Header>
                    <Accordion.Body className='accordionBody'>{question.answerLabel}</Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>

    );
};

export default QuestionComponent;