import React from 'react';
import { Button } from 'react-bootstrap';
import './IntroOutroComponent.scss'

const IntroPlayComponent = ({text, onValid, Background, intro=true}) => {
    return (
        <div className='imgQuiz' style={{backgroundImage: `url(${Background})`}}>

            <div className='intro'>
                <div className='textIntro'>
                    {text}
                    
                    <div className="containerStartButton">
                        <Button onClick={onValid}>
                            {intro ? 'Commencer':'Recommencer'}
                        </Button>
                    </div>

                </div> 
            </div>
        </div>
    );
};

export default IntroPlayComponent;