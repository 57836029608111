import React, {useState, useEffect} from 'react';
import { useStoreActions, useStoreState} from 'easy-peasy';

import { Alert, Button } from "react-bootstrap";

import AuthorizationChecker from '../../services/AuthorizationChecker';
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

import DynamicTable from '../widgets/dynamicTable/DynamicTable';
import { useModal } from '../../hooks/useModal';
import TagOperationFormComponent, { submitTagOperationForm } from "../../components/tagOperation/TagOperationFormComponent";
import { useToast } from '../../hooks/useToast.jsx';

/**
 * 
 * @param {Array} TagOperations array of all tagOperations to be rendered
 * @param {String} readAll  readAll slug to access the tag list page
 
 * @example
 *  <TagTableComponent tagOperations={tagOperations} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const TagOperationTableComponent = (props) => {
    const { tagOperations = [], readAll: tagOperationsPageLink, withParams } = props;
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const token = ACTIONS['tagOperations']['delete'].csrfToken;
    const {updateTagOperation} = useStoreActions(actions => actions.tagOperations);
    const {allTagOperations} = useStoreState(state => state.tagOperations);
    const toast = useToast();

    const tagOperationsToDisplay = tagOperations ? tagOperations : [];


    const { setIsUpdateNecessary: tagUpdateNecessary, deleteTagOperation } = useStoreActions(actions => actions.tagOperations);

    const [isSuccess, setIsSuccess] = useState(false);

    const {modalComponent, setModalShow, setModalData, modalData, setIsSending} = useModal();
  
  
    useEffect(() => {
        if (isSuccess) {
            setIsSuccess(false);
            tagUpdateNecessary(true);
        }
    }, [isSuccess]);

    if (tagOperations.length === 0) { return <></>; }

   
    const sortType = [
        {
            value: 'name', label: 'Nom',
            test:  HelperMetiers360.isArrayContainsValue(tagOperationsToDisplay, "name"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (tagOperation) => tagOperation.name
        },
        {
            value: 'tester', label: "Testeur",
            test : HelperMetiers360.isArrayContainsValue(tagOperationsToDisplay, "isTester"),
            method: (a, b) => {const aTester = a.isTester ? 'X':'-'; return aTester.localeCompare(b.isTester ? 'X':'-');},
            display: (tag) => tag.isTester ? 'X' : '-'
        },
        {
            value: 'countClients', label: 'Occurence',
            test: HelperMetiers360.isArrayContainsValue(tagOperationsToDisplay, "countClients"),
            method: (a, b) => a.countClients-b.countClients,
            display: (tagOperation) => tagOperation.countClients
        },
        {
            value: 'edit', label: 'Éditer',
            test: AuthorizationChecker.hasUpdateRights('tagOperations'),
            method: undefined,
            display: (tagOperation) => {
                const handleSubmit = () => {
                    setIsSending(true);
                    submitTagOperationForm({tagOperation, allTagOperations, action: updateTagOperation})
                        .then(() => {
                            toast.success();
                            setModalShow(false);
                        })
                        .finally(() => setIsSending(false));
                }
                const createEditModal = () => {
                    setModalData({
                        ...modalData,
                        header: <>Edition du tag operation M360</>,
                        content: <TagOperationFormComponent tagOperation={tagOperation} />,
                        cancelButton : 'Annuler',
                        onValidate: handleSubmit,
                        size: 'xl', 
                    });
                    setModalShow(true);
                }
                return (
                    <Button variant="secondary" size="sm" onClick={() => {createEditModal()}}><i className="fas fa-edit"></i></Button>
                )
            }
        },
        {
            value: 'delete', label: 'Supprimer',
            test: AuthorizationChecker.hasDeleteRights('tagOperations') ? true:false,
            method: undefined,
            display: (tagOperation) => {
                const createDeleteModal = () => {
                    setModalData({
                        ...modalData,
                        header: <>Suppresion du tag opération M360</>,
                        content: <>
                            <h5>
                                Êtes vous sûr⋅e de vouloir supprimer le tag opération M360 <strong> {tagOperation.name}</strong> &nbsp;?
                            </h5>
                            {tagOperation.countClients > 0 &&
                                <Alert variant='danger'>
                                    <strong>Attention :</strong> Le tag sera retiré de <strong>{tagOperation.countClients}</strong> clients !
                                </Alert>}
                        </>,
                        resetButton: 'Supprimer',
                        cancelButton: 'Annuler',
                        onReset: () => {
                            deleteTagOperation({uniqueId : tagOperation.uniqueId, data:{csrfToken: token}})
                                .then(() => {
                                    setIsSuccess(true);
                                    toast.success()
                                    setModalShow(false);
                                })
                                .finally(() => setIsSending(false));
                        },
                    });
                    setModalShow(true);
                }
                return <Button variant="danger" size="sm" onClick={createDeleteModal}>
                    <i className="fas fa-trash-alt"></i>
                </Button>
            }
        }
    ];

    return (
        <>
            <DynamicTable
                contentTable={tagOperationsToDisplay}
                contentSort={sortType}
                valueInitSort="name"
                index='uniqueId'
                withParams={withParams}
            />
              {modalComponent}
        </>
    );
}
export default TagOperationTableComponent;