import { isCancel, CancelToken } from 'axios';
import {useEffect, useRef, useCallback} from 'react';

const useCancel = () => {
    const tokenSource = useRef([]);
    const cancelToken = useCallback(() => {
         const source = CancelToken.source();
         tokenSource.current.push(source)
        return source.token;
    }, [])

    useEffect(() => {
        return () => {
            for (const source of tokenSource.current){
                if (source)
                    source.cancel();
                }
        }
    }, []);

    return { cancelToken, isCancel, tokenSource}
}

export default useCancel;