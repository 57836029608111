import React, { useState, useEffect} from 'react';
import { ListGroup, Spinner} from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';

import axios from "../../../../services/axios";
import useCancel from '../../../../hooks/useCancel';

import HelperMetiers360 from '../../../../services/HelpersMetiers360';
import useCustomGetStoreState from '../../../../hooks/useCustomGetStoreState';

import './ClientActivityComponent.scss';

const ClientActivityComponent = (props) => {
    const {client} = props;
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const { allUsers, initialFetchDone } = useCustomGetStoreState("users");
    const { allPlaylists } = useCustomGetStoreState("playlists");

    const [clientHeadsets, setClientHeadsets] = useState(null);

    const [webappAccesses, setWebappAccesses] = useState(null);

    const [headsetViews, setHeadsetViews] = useState(null);

    const  { cancelToken } = useCancel();

    const fetchClientHeadsets = () => {
        // We use this fetch rather than a filter on allHeadset because we need some particular data
        const getUrl = ACTIONS.clients?.getProps?.getHeadsets?.url?.replace('uniqueId', client.uniqueId);
        const myCancelToken = cancelToken();
        axios.get(getUrl, {cancelToken: myCancelToken})
            .then(response => {
                setClientHeadsets(response);
            })
    }

    const fetchWebAppAccesses = () => {
        const getUrl = ACTIONS.clients?.getProps?.getWebappAccesses?.url?.replace('uniqueId', client.uniqueId);
        const myCancelToken = cancelToken();
        axios.get(getUrl, {cancelToken: myCancelToken})
            .then(response => {
                setWebappAccesses(response);
            })
    };

    const fetchHeadsetViews = () => {
        const getUrl = ACTIONS.clients?.getProps?.getHeadsetViews?.url?.replace('uniqueId', client.uniqueId).replace('days','all');
        const myCancelToken = cancelToken();
        axios.get(getUrl, {cancelToken: myCancelToken})
            .then(response => {
                setHeadsetViews(response.data !== 0 ? response:0);
            })
    };

    useEffect(() => {
        fetchClientHeadsets();
        fetchWebAppAccesses();
        fetchHeadsetViews();
    }, []);

    if(!client || !allUsers) {
        return null;
    }

    const olderDate = new Date('2018-01-01');
    
    const clientUsers = allUsers
        .filter(user => user.clientUniqueId === client.uniqueId);
    const confirmedUsers = clientUsers
        ?.filter(user => HelperMetiers360.isUserConfirmed(user));
    const allLogsNoLoader = clientUsers
        ?.reduce((accumulator, user) => accumulator+user.allLogsNoLoader,0);
    const lastLogin = clientUsers
        ?.reduce((accumulator, user) => new Date(user.lastLogin) > accumulator ? 
            new Date(user.lastLogin)
            :accumulator,
            olderDate);
    const loaderUsage = clientUsers
        ?.reduce((accumulator, user) => accumulator+user.logs,0);
    const loaderLastLog = clientUsers
        ?.reduce((accumulator, user) => new Date(user.loaderLastLog) > accumulator ? 
            new Date(user.loaderLastLog)
            :accumulator,
            olderDate);

    const lastPing = clientHeadsets
        ?.reduce((accumulator, headset) => new Date(headset.lastPing) > accumulator ? 
            new Date(headset.lastPing)
            :accumulator,
            olderDate);
    const olderVersion = clientHeadsets
        ?.sort((a, b) =>  HelperMetiers360.convertAppVersionForSort(a.appVersion) 
            - HelperMetiers360.convertAppVersionForSort(b.appVersion))[0]?.appVersion;

    const someHeadsetShouldBeUpdated = clientHeadsets
        ?.find(headset =>  headset.shouldBeupdatated);

    const playlistCount = allPlaylists
        ?.filter(playlist => playlist.client.uniqueId === client.uniqueId).length;

    const webappUsageCount = webappAccesses
        ?.reduce((accumulator, webappaccess) => accumulator+webappaccess.usageCount,0);
    const webappStoryViewCount = webappAccesses
        ?.reduce((accumulator, webappaccess) => accumulator + webappaccess.storyViewCount,0);
    const webappLastUsage = webappAccesses
        ?.sort((a,b) =>  new Date(b.lastUsage?.date) - new Date(a.lastUsage?.date))[0]?.lastUsage.date;
    
    return (
        <div className='client-activity'>
            <h5>Backoffice :</h5>
            <ListGroup>
                <ListGroup.Item variant={initialFetchDone
                    ? confirmedUsers.length ? "success":"danger"
                    : ""}>
                    Utilisateur⋅rices confirmé⋅es : {confirmedUsers?.length}
                </ListGroup.Item>
                <ListGroup.Item variant={initialFetchDone
                    ? allLogsNoLoader ? "success":"danger"
                    : ""}>
                    Nombre de connexions : {allLogsNoLoader}
                </ListGroup.Item>
                <ListGroup.Item variant={initialFetchDone
                    ? lastLogin !== olderDate ? "success":"danger"
                    : ""}>Dernière connexion :&nbsp;
                    {lastLogin !== olderDate ? 
                        HelperMetiers360.getdisplayDateType(lastLogin)
                        :<>Jamais connecté</>}
                </ListGroup.Item>
            </ListGroup>
            <h5>Loader :</h5>
            <ListGroup>
                <ListGroup.Item variant={initialFetchDone
                    ? loaderUsage ? "success":"danger"
                    : ""}>
                    Nombre de connexions : {loaderUsage}
                </ListGroup.Item>
                {loaderLastLog !== olderDate && <ListGroup.Item variant="success">
                    Date dernière connexion : {HelperMetiers360.getdisplayDateType(loaderLastLog)}
                </ListGroup.Item>}
            </ListGroup>
            <h5>Casques :</h5>
            <ListGroup>
                {clientHeadsets && <ListGroup.Item>Nombre de casques : {clientHeadsets.length}</ListGroup.Item>}
                <ListGroup.Item variant={headsetViews != null && headsetViews<=clientHeadsets?.length ? "danger":""}>
                    Nombre d'expériences immersives vues : {headsetViews != null ? headsetViews : <Spinner animation='border' size='sm' />}
                </ListGroup.Item>
                <ListGroup.Item variant={clientHeadsets
                    ? lastPing !== olderDate ? "success":"danger"
                    : ""}>
                    Dernier ping d'un casque :&nbsp;
                    {lastPing !== olderDate ? HelperMetiers360.getdisplayDateType(lastPing):<>jamais pingué</>}
                </ListGroup.Item>
                <ListGroup.Item variant={clientHeadsets
                    ? !someHeadsetShouldBeUpdated ? "success":"danger"
                    : ""}>Version plus ancienne de l'app : {olderVersion ? olderVersion:'n.c.'}</ListGroup.Item>
                <ListGroup.Item>Nombre de playlists : {playlistCount != null ? playlistCount:'0'}</ListGroup.Item>
            </ListGroup>
            <h5>Webapp :</h5>
            <ListGroup>
                <ListGroup.Item variant={webappAccesses 
                    ? webappUsageCount === 0 ? "danger":"success"
                    : ""}>
                    Nombre d'utilisations webapp : {webappUsageCount}
                </ListGroup.Item>
                <ListGroup.Item variant={webappAccesses 
                    ? webappStoryViewCount === 0 ? "danger":"success"
                    : ""}>
                    Total des stories vues : {webappStoryViewCount ?webappStoryViewCount:'0' }
                </ListGroup.Item>
                <ListGroup.Item  variant={webappAccesses
                    ? webappLastUsage !== olderDate ? "success":"danger"
                    : ""}>
                    Dernière connexion webapp : {webappLastUsage !== olderDate ? 
                        HelperMetiers360.getdisplayDateType(webappLastUsage)
                        :<>Aucune connexion</>
                        }
                </ListGroup.Item>
            </ListGroup>
        </div>
    );
}
export default ClientActivityComponent