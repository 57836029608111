import { thunk, action, computed, actionOn } from "easy-peasy";
import computeRoutingData from "../routerUtils/computeRoutingData";
import axios from '../services/axios';

const actionSlugsInitialState = {
    apiData: null,
    isMenuFetched: false,
    slugList: null,
    pages: null
}

const actionSlugs = {
    ...actionSlugsInitialState,

    // listener that will listen for an action to execute something (change state for example)
    // here listening to the performReset called in resetStore, if so, we put our store back in default value
    onGlobalResetCalled: actionOn(
        // target resolver: we store in an array a list of action we will be listening for
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        // handler: if we are listening for multiple type of actions, we can execute logic depending on the type
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    apiData: state.apiData,
                    isMenuFetched: state.isMenuFetched,
                    slugList: state.slugList,
                    pages: state.pages
                } = actionSlugsInitialState)
            }
        }
    ),

    setApiData: action((state, payload) => {
        state.apiData = payload;
    }),

    setIsMenuFetched: action((state, payload) => {
        state.isMenuFetched = payload;
    }),

    setSlugList: action((state, payload) => {
        state.slugList = payload;
    }),

    setPages: action((state, payload) => {
        state.pages = payload;
    }),

    actionSlugsDispatcher: computed((state) => {
        return (menuItem) => state.slugList[menuItem] ? state.slugList[menuItem] : null;
    }),

    getActions: thunk((actions, payload, helpers) => {
        const API_MENUS = helpers.getStoreState().params.apiRoutes.pwaMenu;

        return axios.get(API_MENUS)
            .then(res => {
                const { pages, actionSlugs } = computeRoutingData(res);
                actions.setApiData(res);
                actions.setSlugList(actionSlugs);
                actions.setPages(pages);
            })
            .finally(() => {
                actions.setIsMenuFetched(true);
            })
    }),

}

export default actionSlugs;