import React, { useState, useEffect } from 'react';
import {Button, Col, Container, Row, Alert} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';

import FaqComponent from '../../components/faq/FaqComponent';
import QuestionComponent from '../../components/faq/QuestionComponent';
import VideosComponent from '../../components/faq/videosComponent';

import useDebounceSearch from '../../hooks/useDebounchSearch';

import HelperMetiers360 from '../../services/HelpersMetiers360.js';
import faqData from './faqData';

import './FaqPage.scss';

const FaqPage = () => {

    const { searchInput, search, resetSearchBar } = useDebounceSearch({ placeholder: 'Rechercher par question ou expérience immersive', delay: 400 })

    const [globalFaq, setGlobalFaq] = useState([]);

    // filtering with buttons
    const onSelectFaq = (category) => {
       return category == null
            ? faqData
            : faqData.filter(faq => faq.category === category)
    }

    const [selectedFaq, setSelectedFaq] = useState(onSelectFaq(null));

    const choiceButton = faqData
        .map((faq)=> 
            <Button className='choiceButton' key={'button_'.concat(HelperMetiers360.generateUniqueId())} onClick={()=>setSelectedFaq(onSelectFaq(faq.category))}>
                {faq.category}
            </Button>
        );

    // filtering with searchBar
    const initGlobalFaq = () => {
        const array = []
        for(const faq of faqData){
            if(faq.labelVideo && faq.videos){
                array.push({labelVideo: faq.labelVideo, videos: faq.videos});
            }
            
            for(const questions of faq.questions){
                array.push(questions);
            }
        }    
        setGlobalFaq([...array]);
    }
    
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    useEffect(()=> {
        if (search !== '')
            resetSearchBar()
    }, [selectedFaq])

    useEffect(() => {
        
        if(globalFaq.length == 0) {
            initGlobalFaq();
        }else{
            if (search === "") return;

            const res = globalFaq.filter(question => 
                HelperMetiers360.isSearchInText(search, question.questionLabel.props.children)
                    || HelperMetiers360.isSearchInText(search, question.labelVideo)
            );
            setFilteredQuestions(res)
        }
    }, [search, globalFaq]);

    const faqContainerGoTeam = <Container fluid>
        <Row>
            <Col>
                <h2>Les réponses aux questions les plus fréquentes</h2>
                <Alert variant="info">Explorez notre F.A.Q., nous avons surement la réponse à votre question !<br />
                Vous ne trouvez pas la réponse ? <Link to="/contacts">Contactez-nous !</Link></Alert>
            </Col>
        </Row>

        {/* <h2 className='titleFAQ'>Les réponses aux questions les plus fréquentes : </h2> */}
        <Row className="faq-action">
            <Col className='searchBar'>
                {searchInput}
            </Col>
            <Col className="buttonGroup">
                {choiceButton}
            </Col>
        </Row>


        <div >

            {
                    search.length === 0 ? 
                    selectedFaq.map((faq) => (
                        <div key={'label_'.concat(HelperMetiers360.generateUniqueId())}>
                            <h3  className='sectionTitle'>
                                {faq.label}
                            </h3>
                            {
                                <FaqComponent faq={faq} key={'faqComponent_'.concat(HelperMetiers360.generateUniqueId())}/>                                   
                            }
                        </div>
                        
                    )) :
                    
                    search.length != 0 && filteredQuestions.length == 0 ?
                            <p className='ErrorMessage'>
                                Aucune question ne correspond à votre recherche.
                            </p>:

                    filteredQuestions
                        .map((question) =>
                        
                            {
                                return (question.labelVideo && question.videos) ? 
                                    <VideosComponent videos={question.videos} label={question.labelVideo} key={'v_'.concat(HelperMetiers360.generateUniqueId())}/> :
                                    <QuestionComponent question = {question} key={'q_'.concat(HelperMetiers360.generateUniqueId())} />
                                    
                            }
                        )
                }
                
            <NavLink to='/contacts'>
                <div className='contactRedirect'>
                <Button className='ContactRedirection'> 
                    D'autres questions ? 
                </Button>
                </div>
            </NavLink>

        </div>
    </Container>;

    return faqContainerGoTeam;
};

export default FaqPage;