import React, {useState, useEffect, useRef} from "react";

import { CSVLink } from 'react-csv';


const CsvDownloadComponent = (props) => {
    const {contentTable,contentSort, setDownloadCSV, filename = "donnees"} = props;

    const [downloadDone, setDownloadDone] = useState(false);

    const btnRef = useRef(null)

    const headers = contentSort
        .filter(type => type.test)
        .map(type => {
            return {label: type.label, key: type.value};
    });

    const toCsv = contentTable
        .map((content) => {
            const row = {};
            contentSort.forEach(type => {
                if (type.test) {
                    if (type.flatDisplay !== undefined) {
                        row[type.value] = type.flatDisplay(content);
                    } else {
                        row[type.value] = type.display(content);
                    }
                }
            });
            return row;
        }
    );

    useEffect(() => {
        if (!downloadDone && btnRef) {
            setDownloadDone(true);
            setDownloadCSV(false);
            btnRef?.current?.click();
        }
      }, [downloadDone, btnRef]);

    return !downloadDone && <CSVLink 
        filename={filename+'_M360.csv'}
        headers={headers}
        data={toCsv}
        >
            <div ref={btnRef}>
                <i className="fas fa-download mr-2"/>Télécharger en csv
            </div>    
        </CSVLink>
}
export default CsvDownloadComponent;
