import React from "react";


import CatalogHeadsetViewComponent from "../../components/catalog/CatalogHeadsetViewComponent";


const CatalogPage = (props) => {


    return (
        <CatalogHeadsetViewComponent />
    )
}


export default CatalogPage;