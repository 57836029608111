import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Spinner } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import useBackButton from "../../hooks/useBackButton.jsx";
import { useModal } from "../../hooks/useModal.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import UploadFileFormComponent, { submitFileForm } from "../../components/widgets/uploadFileForm/UploadFileFormComponent.jsx";
import EditableImageComponent from "../../components/widgets/editableImage/EditableImageComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import VideosPageContent from "../video/VideosPageContent.jsx";
import RomesFilters from "./RomesFilters.jsx";
import RomesView from "./RomesView.jsx";
import RomesTableComponent from "../../components/romes/RomesTableComponent.jsx";
import SelectItemsComponent from "../../components/widgets/selectItemsComponent/SelectItemsComponent.jsx";
import ProfessionalsPageContent from "./ProfessionalsPageContent.jsx";
import './TransversalPropDetailPage.scss';

const TransversalPropDetailPage = ({ transversalProp, displayProsTab = true, displayRomesTabForEveryone = false,
    displayProfessionalFieldsFilter = false }) => {
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const { uniqueId } = useParams();
    const { isFetchingOneTransversalProp } = useCustomGetStoreState('romes');
    const { fetchOneTransversalProp, updateOneTransversalPropThumbnail } = useStoreActions(actions => actions.romes);
    const { oneTransversalProp } = useStoreState(state => state.romes);
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState("videos");
    const { backButtonComponent } = useBackButton();
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const { isMobile } = useStoreState(state => state.responsive);

    const defaultDisplayTab = displayProsTab ? "professionnals" : "videos";

    const viewStyleRomesPreference = useStoreState(state => state.preferences.codeRomesListContent);

    const [filteredRomes, setFilteredRomes] = useState([]);
    const [viewStyleRomes, setViewStyleRomes] = useState(viewStyleRomesPreference);

    const professionalFieldRef = useRef(null);
    const [searchParamsAppliedOnView, setSearchParamsAppliedOnView] = useState(false);

    const viewStyleRomesType = [
        {
            value: 'withContentM360', label: "Avec contenus M360", view: <RomesTableComponent romes={filteredRomes.filter(rome =>
                rome?.usagePros !== 0 || rome?.usageVideos !== 0 || rome?.usageStories !== 0
            )} />
        },
        { value: 'all', label: "Tous", view: <RomesTableComponent romes={filteredRomes} /> },
    ];

    const displayRomesTab = displayRomesTabForEveryone || AuthorizationChecker.isAdmin();

    const [searchParam, setSearchParam] = useSearchParams();
    const [tabKey, setTabKey] = useState(searchParam?.get('tab') ?? defaultDisplayTab);

    useEffect(() => {
        let params = { tab: tabKey }
        if (searchParam?.get('professionalField'))
            params.professionalField = searchParam?.get('professionalField')
        setSearchParam(params);
    }, [tabKey])

    useEffect(() => {
        searchParam?.get('tab') &&
            setTabKey(searchParam?.get('tab'));
        if (searchParam?.get('professionalField') && professionalFieldRef.current)
            professionalFieldRef.current.value = searchParam?.get('professionalField');
    }, [searchParam, professionalFieldRef.current])

    useEffect(() => {
        if (!isFetchingOneTransversalProp && !isFetchingAllVideos) {
            fetchOneTransversalProp({
                code: uniqueId,
                transversalProp
            })
        }
    }, [allVideos, oneTransversalProp, uniqueId]);

    const videos = useMemo(() => {
        return allVideos?.filter(video =>
            (oneTransversalProp?.videosByPro ?? oneTransversalProp?.videosByRome)?.includes(video.uniqueId))
    }, [allVideos, oneTransversalProp]);

    const [romesForProfessionalField, setRomesForProfessionalField] = useState([]);
    const [videosForProfessionalField, setVideosForProfessionalField] = useState([]);

    useEffect(() => {
        if (oneTransversalProp?.romes) {
            searchParam?.get('professionalField')
                ? !searchParamsAppliedOnView && applySelectProfessionalFieldOnTables()
                : setRomesForProfessionalField(oneTransversalProp?.romes);
        }
    }, [oneTransversalProp])

    useEffect(() => {
        if (videos) {
            setVideosForProfessionalField(videos);
        }
    }, [videos])

    const professionalFields = useMemo(() => {
        return displayProfessionalFieldsFilter && oneTransversalProp
            ? [... new Map(oneTransversalProp.romes?.map(rome => [rome.professionalField?.code, rome.professionalField])).values()]
            : []
    }, [oneTransversalProp]);

    const spinner = (titre) => <div className="disabledTitle">
        <p>{titre}</p>
        <Spinner
            as="span"
            animation="border"
            size="sm"
            aria-hidden="true"
            className='tabspin' />
    </div>;

    const updateThumbnailModal = () => {
        if (AuthorizationChecker.hasUpdateRights('romes')) {

            const validate = () => {
                setIsSending(true);
                return submitFileForm(uniqueId, updateOneTransversalPropThumbnail, transversalProp + "_updateThumbnail", fileContraints, "image", transversalProp)
                    .then(() => {
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            }
            setModalData({
                ...modalData,
                header: <>Modifier la vignette</>,
                content: <UploadFileFormComponent typeOfFile="image" fileFormName={transversalProp + "_updateThumbnail"} fileLabel="Vignette" token={ACTIONS[transversalProp]["updateThumbnail"]["csrfToken"]} />,
                cancelButton: 'Annuler',
                validateButton: "Valider",
                onValidate: validate,
                size: 'lg'
            });
            setModalShow(true);
        }
    }

    const applySelectProfessionalFieldOnTables = () => {
        if (oneTransversalProp) {
            setRomesForProfessionalField(professionalFieldRef.current?.value
                ? oneTransversalProp?.romes?.filter(rome =>
                    rome.professionalField?.code === professionalFieldRef.current?.value)
                : oneTransversalProp?.romes);

            setVideosForProfessionalField(professionalFieldRef.current?.value
                ? videos.filter(video => video.romes?.some(rome => rome.includes(professionalFieldRef.current?.value)))
                : videos);
            !searchParamsAppliedOnView && setSearchParamsAppliedOnView(true);
        }
    }

    const onFilterSlected = () => {
        let params = {};
        if (professionalFieldRef.current?.value)
            params.professionalField = professionalFieldRef.current?.value
        if (searchParam?.get('tab'))
            params.tab = searchParam?.get('tab')
        setSearchParam(params);

        setSearchParam({ ...searchParam, ...{ "professionalField": professionalFieldRef.current?.value } });
        applySelectProfessionalFieldOnTables()
    }

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className={"d-flex " + (isMobile ? "flex-column-reverse" : "justify-content-between")}>
                    {isFetchingOneTransversalProp ? <Loader /> : <>
                        <Col className={"d-flex " + (isMobile ? "flex-column" : "justify-content-between")}>
                            {oneTransversalProp?.thumbnail &&
                                <Col className={isMobile ? "d-flex justify-content-center" : "col-auto"}>
                                    <EditableImageComponent src={oneTransversalProp.thumbnail} onEdit={updateThumbnailModal} />
                                </Col>
                            }
                            <Col>{HelperMetiers360.transversalPropLabel(transversalProp) + " : " + oneTransversalProp?.label}</Col>
                        </Col>
                        <Col className={isMobile ? "d-flex justify-content-end" : "col-auto"}>
                            {backButtonComponent}
                        </Col>
                    </>
                    }
                </h2>
            </Row>
            {isFetchingOneTransversalProp ?
                <Loader />
                : <>
                    {displayProfessionalFieldsFilter && professionalFields.length > 0 &&
                        <div className={"d-flex flex-row pt-4 pe-5 select-professional-field"}>
                            <label className="label-select text-nowrap">Sous-domaine professionnel&nbsp;: </label>
                            <span className="d-flex flex-column w-100">
                                <SelectItemsComponent selectRef={professionalFieldRef} onChange={onFilterSlected}
                                    items={professionalFields} itemKey="code" itemValue="label" itemValueDetails="code" />
                            </span>
                        </div>}
                    <Tabs defaultActiveKey={defaultDisplayTab ? "professionnals" : "videos"} activeKey={tabKey} onSelect={(k) => setTabKey(k)}
                        className="mb-4" style={{ flexWrap: "wrap" }} mountOnEnter={true} unmountOnExit={true}>
                        {displayProsTab &&
                            <Tab eventKey="professionnals" title={oneTransversalProp?.pros
                                ? "Professionnel⋅les associé⋅es"
                                : spinner("Professionnel⋅les associé⋅es")}>
                                <ProfessionalsPageContent professionals={oneTransversalProp?.pros ?? []} isFetching={isFetchingOneTransversalProp} />
                            </Tab>}
                        <Tab eventKey="videos" title="Expériences immersives associées">
                            <VideosPageContent videos={videosForProfessionalField} isVideosLoading={isFetchingAllVideos} />
                        </Tab>
                        {displayRomesTab &&
                            <Tab eventKey="RomeCodes" title="Codes ROME associés">
                                {oneTransversalProp?.romes
                                    ? <>
                                        <RomesFilters
                                            romes={oneTransversalProp?.romes}
                                            setFilteredRomes={setFilteredRomes}
                                            viewStyle={viewStyleRomes}
                                            setViewStyle={setViewStyleRomes}
                                            viewStyleType={viewStyleRomesType} />
                                        <Row>
                                            <RomesView
                                                romes={oneTransversalProp?.romes}
                                                isFetching={isFetchingOneTransversalProp}
                                                filteredRomes={filteredRomes}
                                                view={viewStyleRomesType?.find(type => type.value === viewStyleRomes)?.view} />
                                        </Row>
                                    </>
                                    : <Loader />
                                }
                            </Tab>}
                    </Tabs>
                </>}
            {modalComponent}
        </Container>
    );
}

TransversalPropDetailPage.propTypes = {
    transversalProp: PropTypes.string.isRequired,
    displayProsTab: PropTypes.bool,
    displayRomesTabForEveryone: PropTypes.bool,
    displayProfessionalFieldsFilter: PropTypes.bool,
};

export default TransversalPropDetailPage;