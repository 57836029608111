import React from "react";
import { useStoreState } from 'easy-peasy';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ClientStatusFormComponent = ({clientStatus = null}) => {
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const token = ACTIONS.clientStatus[clientStatus ? 'update' : 'create'].csrfToken;
    
    return (
        <div>
            <div>
               <Form id="clientStatus-form"> 
                    <Form.Group>
                        <Form.Label>Nom :</Form.Label>
                        <Form.Control
                            type="text"
                            name="label"
                            defaultValue={clientStatus?.label ?? ""}
                            placeholder="Exemple: Clients spéciaux"
                        />
                        <Form.Control.Feedback type='invalid' name='feedback-label'></Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group >
                        <Form.Control
                            name="csrfToken"
                            defaultValue={token}
                            type="hidden"
                        />
                    </Form.Group>
                </Form>    
            </div>
        </div>

    );
};

export const submitClientStatusForm = ({clientStatus, allClientStatus, action}) => {
    let error = false;

    let formData = new FormData(document.getElementById('clientStatus-form'));

    let label = formData.get('label');

    document.getElementsByName('label')[0].classList.remove("is-invalid");

    if (label === "") {
		document.getElementsByName('label')[0].classList.add("is-invalid");
        document.getElementsByName(`feedback-label`)[0].innerHTML = "Le nom du statut est obligatoire";
        error = true;
    }
    if(label && allClientStatus.find(clientStatus => clientStatus.label.toLowerCase() === label.toLowerCase())){
		document.getElementsByName('label')[0].classList.add("is-invalid");
        document.getElementsByName(`feedback-label`)[0].innerHTML = "Ce nom de statut existe déjà";
		error = true;
    }

    if (error) {
        return Promise.reject(new Error());
    } else {
        let data =  {label: formData.get('label'), csrfToken: formData.get('csrfToken') }

        if(clientStatus){
            data = {data: data, uniqueId: clientStatus.uniqueId }
        } else {
            const valueFromLabel = data.label.toLowerCase()
                ?.split(' ')
                ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join('');
            data.value = valueFromLabel;
        }

        return action(data)
            .catch(() => {
                return Promise.reject(new Error());
            })
    }
}

ClientStatusFormComponent.propTypes = {
    clientStatus: PropTypes.object
};
export default ClientStatusFormComponent;