import React, {useState, useEffect} from 'react'
import {useStoreActions} from 'easy-peasy';
import AnswersFormComponent from './AnswersFormComponent.jsx';
import {Alert} from "react-bootstrap";

import './AnswersComponent.scss';
import AnswerCardComponent from './AnswerCardComponent.jsx';

const AnswersComponent = ({question, quizUniqueId}) => {
    
    const renderFormType = {
		hide: 'hide',
		update: 'update',
		create: 'create',
	};

    const [answers, setAnswers] = useState(question.answers.length? [...question.answers] : [])
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [mainViewType, setMainViewType] = useState(renderFormType.hide);
    const {updateAnswer, createAnswer} = useStoreActions((actions) => actions.quizzes);
    const [managerTitle, setManagerTitle] = useState('Ajouter ou éditer une reponse')

    useEffect(() => {
        setAnswers(question.answers.length? [...question.answers] : []);
        selectHideForm();
    }, [question]);

    const selectHideForm = () => {
        setManagerTitle('Ajouter ou éditer une reponse');
		setSelectedAnswer(null);
		setMainViewType(renderFormType.hide);
	}

	const selectEditForm = (answer) => {
        setManagerTitle(`Edition de '${answer.caption}'`);
        setSelectedAnswer({...answer});
		setMainViewType(renderFormType.update);
	}

	const selectCreateForm = () => {
        setManagerTitle('Ajout d\'une nouvelle reponse');
		setSelectedAnswer(null);
		setMainViewType(renderFormType.create);
	}

    const mainContentToRender = () => {
		switch (mainViewType) {
			case renderFormType.hide:
                return null
			case renderFormType.update: 
                return <AnswersFormComponent answer={selectedAnswer} quizUniqueId={quizUniqueId} questionUniqueId={question.uniqueId} answerAction={updateAnswer} />
			case renderFormType.create:
                return <AnswersFormComponent quizUniqueId={quizUniqueId} questionUniqueId={question.uniqueId} answerAction={createAnswer} />
			default:
                return null
		}
	}

    return (
        <div className="answer-manager">
             <Alert variant='info'>
                Les réponses au-dela de la 3ieme reponse ne seront pas affichées, à moins d'un traitement en LUA.
             </Alert>
            <div className="answer-list-container">
                <div className="answer-list">
                    { 
                        answers.map((answer) => (
                                <AnswerCardComponent 
                                    key={'answer' + answer.uniqueId}
                                    answer={answer} 
                                    selectedAnswer={selectedAnswer} 
                                    selectEditForm={selectEditForm}
                                />
                            )
                        )
                    }
                </div>
                <div className="answer-form-container">
                    <div className="answer-form-header">
                        <h3>{managerTitle}</h3>
                        {
                          mainViewType === renderFormType.hide
                          ? <i onClick={() => selectCreateForm()} variant='primary' className="fa fa-plus-circle"></i>
                          : <i onClick={() => selectHideForm()} variant='primary' className="fa fa-arrow-circle-left"></i>
                        }
                    </div>
                    {mainContentToRender()}
                </div>

            </div>

        </div>
    )
}

export default AnswersComponent;