import { action, actionOn, thunk, thunkOn } from "easy-peasy";
import HelperMetiers360 from "../services/HelpersMetiers360";

const allContentInitialState = {
    isFetching: false,
    contentLoaded: false,
    shouldRefreshContent: false,
}

const allContent = {
    ...allContentInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    isFetching: state.isFetching,
                    contentLoaded: state.contentLoaded,
                    shouldRefreshContent: state.shouldRefreshContent
                } = allContentInitialState);
            }
        }
    ),

    onSetIsUpdateNecessary: thunkOn(
        (actions, storeActions) => [
            storeActions.videos.setIsUpdateNecessary,
            storeActions.clients.setIsUpdateNecessary,
            storeActions.groups.setIsUpdateNecessary,
            storeActions.pedagogicModules.setIsUpdateNecessary,
            storeActions.playlists.setIsUpdateNecessary,
            storeActions.quizzes.setIsUpdateNecessary,
            storeActions.sequences.setIsUpdateNecessary,
            storeActions.tags.setIsUpdateNecessary,
            storeActions.tagOperations.setIsUpdateNecessary,
            storeActions.users.setIsUpdateNecessary,
            storeActions.webappAccesses.setIsUpdateNecessary,
            storeActions.headsets.setIsUpdateNecessary,
            storeActions.romes.setIsUpdateNecessary,
            storeActions.formations.setIsUpdateNecessary,
            storeActions.formationSessions.setIsUpdateNecessary,
            storeActions.orders.setIsUpdateNecessary,
            storeActions.clientStatus.setIsUpdateNecessary,
            storeActions.welcomeMessage.setIsUpdateNecessary
        ],
        (actions, target) => {

            if (target.payload === true) {
                const storeName = target.type.split('.')[1];
                actions.initContent({ storeName });
            }
        }
    ),

    initContent: thunk((actions, { storeName }, helpers) => {
        const storeActions = helpers.getStoreActions()[storeName];

        const actionKeys = Object.keys(storeActions);

        const fetchAllKey = actionKeys.find(key => {
            return (key.includes('fetch') && key.includes(HelperMetiers360.capitalize(storeName)))
        })

        const fetchAll = storeActions[fetchAllKey]
        return fetchAll()?.then(() => {
            storeActions.setInitialFetchDone(true);
        });
    }),

    setIsFetching: action((state, payload) => {
        state.isFetching = payload;
    }),

    setContentLoaded: action((state, payload) => {
        state.contentLoaded = payload;
    }),

    setShouldRefreshContent: action((state, payload) => {
        state.shouldRefreshContent = payload;
    })
}

export default allContent;