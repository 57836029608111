import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

import { useParams } from "react-router-dom";

import { Row, Col, Container, Card } from "react-bootstrap";

import Loader from "../../components/widgets/Loader";
import PedagogicModuleDetailsComponent from "../../components/pedagogicModules/moduleDetails/PedagogicModuleDetailsComponent";
import useBackButton from "../../hooks/useBackButton";

import "./PedagogicModuleDetailPage.scss";

const PedagogicModuleDetailPage = (props) => {
    const {uniqueId} = useParams();

    const { pedagogicModuleById } = useStoreState(state => state.pedagogicModules);
    const {fetchPedagogicModuleById, deletePedagogicModule} = useStoreActions(actions => actions.pedagogicModules);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true);
            
            fetchPedagogicModuleById(uniqueId)
                .finally(() => setIsLoading(false));
    }
    }, []);

    const { backButtonComponent } = useBackButton();

    return (
        <Container fluid>
            <Row>
                <Col className="thumbnail_header_item">
                {backButtonComponent}
                    {
                        (pedagogicModuleById && pedagogicModuleById.links && pedagogicModuleById.links.image) &&
                            <Card.Img src={pedagogicModuleById.links.image} /> 
                    }
                </Col>
            </Row>
        <Row className="d-flex flex-column">
            
           { 
               (isLoading || !pedagogicModuleById )
                    ? <Loader />
                    : <PedagogicModuleDetailsComponent 
                        pedagogicModule={pedagogicModuleById}
                        deletePedagogicModule={deletePedagogicModule}
                    /> 
            
            }
        </Row>
    </Container>
    )
}

export default PedagogicModuleDetailPage;