import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, OverlayTrigger, Tooltip, Alert, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useInfiniteLoadingLogic from "../../../hooks/useInfiniteLogic.jsx";
import DynamicTableInfinite from "../../widgets/dynamicTableInfinite/DynamicTableInfinite.jsx";
import {useStoreActions } from 'easy-peasy';
import { useModal } from "../../../hooks/useModal.jsx";
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";
import ClientCRMDetailViewComponent from "../ClientsDetail/clientCRMDetailView/ClientCRMDetailViewcomponent.jsx";

import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import AxonautLogoBubbleComponent from "../../widgets/axonautLogoBubble/AxonautLogoBubbleComponent.jsx";
import { useToast } from "../../../hooks/useToast.jsx";
import ClientFormComponent, { submitClientForm } from "../clientForm/ClientFormComponent.jsx";
import { useSearchParams } from "react-router-dom";

const displayName = (clientCRM) => clientCRM.idAxonaut !== null
    ? <Row className="d-flex align-items-center justify-content-center">
        <Col className="col-2">
            <AxonautLogoBubbleComponent message={!clientCRM.isActiveAxonaut ? "Supprimé d'Axonaut" : null} color={!clientCRM.isActiveAxonaut ? "grey" : null}/>
        </Col>
        <Col>{clientCRM.name}</Col>
    </Row>
    : clientCRM.name;

const displayLastInvoiceDate = (lastInvoiceDate) => lastInvoiceDate
    ? HelperMetiers360.getdisplayDateType(lastInvoiceDate, 'day')
    : <>-</>

const ClientsCRMTableComponent = ({ clientsCRM, withParams }) => {
    const [sortedClients, setSortedClients] = useState([]);
    const [clientsToDisplay, setClientsToDisplay] = useState([]);
    const [shouldSort, setShouldSort] = useState(true);
    const { fetchClientCRMById, deleteClientCRM} = useStoreActions(actions => actions.clients);
    const {updateClientCRM } = useStoreActions(actions => actions.clients);
    const toast = useToast();
    const [searchParam, setSearchParam] = useSearchParams();
    const confirmDeleteRef = useRef(null);

    useEffect(() => {
        setShouldSort(true);
        setSortedClients([...clientsCRM]);
    }, [clientsCRM]);


    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedClients, clientsToDisplay, setClientsToDisplay, 100);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    useEffect(() => {
        const clientUniqueIdFromParams = searchParam?.get("clientUniqueId");
        if(clientUniqueIdFromParams 
            && modalData.id === null
            && clientsCRM.some(client => client.uniqueId === clientUniqueIdFromParams)) {
            displayClientCRMsModal(clientUniqueIdFromParams);
        }
    }, [clientsCRM, searchParam])

    const contactsM360Display = (contactsM360) => {
        return contactsM360.reduce((accumulator, currentValue) => 
            <>
                {(accumulator.props.children !== undefined) && <>{accumulator},&nbsp;</>}
                <OverlayTrigger placement="top" key={currentValue.uniqueId}
                    overlay={<Tooltip>{currentValue.relationValue}</Tooltip>}>
                    <span className="contactM360">{currentValue.name}</span>
                </OverlayTrigger>
            </>
        , <></>);
    }


    const displayEdit = (clientCRM) => <Button variant="secondary" size="sm" 
        onClick={(e) => {e.stopPropagation();createEditModal(clientCRM)}}>
        <i className="fas fa-edit"></i>
    </Button>;

    const sortType = [
        {
            value : 'name', label : 'Établissement',
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "name"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (clientCRM) => displayName(clientCRM),
            flatDisplay: (clientCRM) => clientCRM.name
        },
        {
            value : 'subscriptionEndDate', label : "Date de fin d'abonnement",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "subscriptionEndDate"),
            method: (a, b) => {
                const dateA = a.subscriptionEndDate ? new Date(a.subscriptionEndDate) : a.isSubscriber ? new Date("1900-01-01") :null;
                const dateB = b.subscriptionEndDate ? new Date(b.subscriptionEndDate) : b.isSubscriber ? new Date("1900-01-01") :null;
                return HelperMetiers360.compareWithNullable(dateA, dateB);
              },
            display: (clientCRM) => clientCRM.subscriptionEndDate ? HelperMetiers360.getdisplayDateType(clientCRM.subscriptionEndDate, 'day') : clientCRM.isSubscriber ? "Pas d'abonnement" : null
        },
        {
            value: 'cp', label: "C.P.",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "defaultAddress"),
            method: (a, b) =>HelperMetiers360.localeCompareWithNullable(a.defaultAddress?.addressZipCode, b.defaultAddress?.addressZipCode),
            display: (clientCRM) => clientCRM.defaultAddress?.addressZipCode

        },
        {
            value: 'city', label: "Ville",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "defaultAddress"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.defaultAddress?.addressCity, b.defaultAddress?.addressCity),
            display: (clientCRM) => clientCRM.defaultAddress?.addressCity

        },
        {
            value: 'status', label: "Statut",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "status"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.status, b.status),
            display: (clientCRM) => clientCRM.status

        },
        {
            value: 'categories', label: "Catégories",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "categories"),
            method: (a, b) => HelperMetiers360.sortStringArray(a.categories, b.categories),
            display: (clientCRM) => clientCRM.categories?.join(", ")
        },
        {
            value: 'tagOpeM360', label: "Opé M360",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "tagOperations"),
            method: (a, b) => HelperMetiers360.sortStringArray(a.tagOperationsName, b.tagOperationsName),
            display: (clientCRM) => clientCRM.tagOperationsName?.join(", ")
        },
        {
            value: 'invoices', label: "Factures",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "invoices"),
            method: (a, b) => HelperMetiers360.compareWithNullable(a.invoices, b.invoices),
            display: (clientCRM) => clientCRM.invoices

        },
        {
            value: 'lastInvoiceDate', label: "Date facture",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "lastInvoiceDate"),
            method: (a, b) => new Date(b.lastInvoiceDate) - new Date(a.lastInvoiceDate),
            display: (clientCRM) => displayLastInvoiceDate(clientCRM.lastInvoiceDate)
        },
        {
            value: 'ContactsM360', label: "Contacts M360",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "contactsM360"),
            method: (a, b) => {
                const contactsM360A = a.contactsM360?.length > 0 ? a.contactsM360[0].name : null;
                const contactsM360B = b.contactsM360?.length > 0 ? b.contactsM360[0].name : null;
                return HelperMetiers360.localeCompareWithNullable(contactsM360A, contactsM360B);
            },
            display: (clientCRM) => clientCRM.contactsM360?.length > 0 && contactsM360Display(clientCRM.contactsM360),
            flatDisplay: (clientCRM) => clientCRM.contactsM360?.map(contact => contact.name)?.join(", ")
        },
        {
            value: 'orders', label: "Bons",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "orders"),
            method: (a, b) =>  HelperMetiers360.compareWithNullable(a.orders, b.orders),
            display: (clientCRM) => clientCRM.orders
        },
        {
            value: 'interactions', label: "Actions depuis dernière facture",
            test : HelperMetiers360.isArrayContainsValue(clientsCRM, "interactions"),
            method: (a, b) => HelperMetiers360.compareWithNullable(a.interactions, b.interactions),
            display: (clientCRM) => clientCRM.interactions
        },
        {
            value: 'update', label: "Éditer",
            test : AuthorizationChecker.hasUpdateRights('clients'),
            method: (a, b) => new Date(b?.clientName) - new Date(a?.clientName),
            display: (clientCRM) => displayEdit(clientCRM),
            flatDisplay: () => null
        }
    ];

    if(clientsCRM.length === 0 )
        {return <></>;}

        const deleteClient = (clientCRM) => {
            setModalData({
                ...modalData,
                header: <>Suppression de client</>,
                content: <>
                    <Alert variant="danger">
                        <h5>Êtes-vous sûr.e de vouloir supprimer le client&nbsp;
                            {clientCRM.name ?? ''}&nbsp;?
                        </h5>
                    </Alert>
                    <label htmlFor="confirm-control">Écrivez <b>SUPPRIMER</b> pour valider la suppression</label>
                    <Form.Control type='text' id="confirm-control" ref={confirmDeleteRef} />
                </>,
                cancelButton: 'Annuler',
                resetButton: 'Supprimer',
                onReset: () => {
                    if(confirmDeleteRef?.current?.value !== "SUPPRIMER") {
                        toast.open({ 
                            message: "Le message de confirmation ne correspond pas au message attendu : SUPPRIMER",
                            variant: "danger" 
                        })
                    } else {
                        setIsSending(true);
                        deleteClientCRM(clientCRM.uniqueId)
                            .then(() => {
                                toast.success();
                                setModalShow(false);
                            })
                            .finally(() => setIsSending(false));
                    }
                },
            });
            setModalShow(true);
        }
    
    
    const displayClientCRMsModal = (uniqueId) => {
        const clientCRM = clientsCRM.find(client => client.uniqueId == uniqueId);
        setSearchParam({'search': searchParam.get('search'), 'clientUniqueId': uniqueId});

        
        setModalData({
            ...modalData,
            header: <>Informations sur {clientCRM.name}</>,
            content: <ClientCRMDetailViewComponent client={clientCRM} displayInteractions={true} />,
            size: 'xl', 
            id: 'info-clientCRM-modal',
            onClose: () => setSearchParam({'search': searchParam.get('search'), 'clientUniqueId': ''})
        });
        setModalShow(true);
    }

    const createEditModal = (clientCRM) => {
        const handleSubmit = () => {
            setIsSending(true);
            const result = submitClientForm({action: 'update', client: clientCRM, updateClientCRM});

            result
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        fetchClientCRMById(clientCRM.uniqueId)
            .then(response => {
                setModalData({
                    ...modalData,
                    header: <>Edition du client</>,
                    content: <ClientFormComponent client={response} action='update' />,  
                    cancelButton: 'Annuler',
                    resetButton: 'Supprimer',
                    resetDisabledReason: !response.isDeletable ? 
                        <ul>
                            Ce client ne peut pas être supprimé:
                            {response.isClient &&<li>Il correspond à un établissement</li>}
                            {!response.meetsCRMDeletionRequirements &&<li>Il existe dans Axonaut</li>}
                        </ul>
                        : null,
                    onReset: () => deleteClient(clientCRM),  
                    onValidate: handleSubmit,
                    size: 'xl', 
                });
                setModalShow(true);
            });
    }

    return <>
        <DynamicTableInfinite
            contentTable = {clientsToDisplay}
            contentSort = {sortType}
            valueInitSort = "lastInvoiceDate"
            index = 'uniqueId'
            handleClick = {displayClientCRMsModal}
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedClients}
            sortedContent={sortedClients}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            filename="clients_CRM"
        />
        {modalComponent}
    </>
}

ClientsCRMTableComponent.propTypes = {
    clientsCRM: PropTypes.array.isRequired,
    withParams: PropTypes.bool.isRequired
};

export default ClientsCRMTableComponent