import React from 'react';
import {Button, Alert} from 'react-bootstrap';

import { URLS } from '../../services/axios.js';

import useFreshworkWidget from '../../hooks/useFreshworksWidget.jsx';
import "./ErrorsComponent.scss";

const ErrorsComponent = ({codeDescription, message}) => {

    const {openWidgetContactForm} = useFreshworkWidget();
    return (
        <div className="error-page">
            <div className="error-page__content">
                <Alert variant="warning">
                    <Alert.Heading>{codeDescription}</Alert.Heading>
                    <p>
                        {message}
                        &nbsp;<Button as="a" bsPrefix= {'no-btn'} onClick={() => {openWidgetContactForm()}}>Contactez-nous</Button>.
                    </p>
                </Alert>
                <Button variant="primary" as="a"  href={URLS.BASE_URL}><i className="fas fa-arrow-circle-left"></i> &nbsp;Retour à l'accueil</Button>
            </div>
        </div>
    )
}

export default ErrorsComponent;