import React from 'react';
import './CustomMultipleSelectComponent.scss';

/**
 * Custom Multiple Select Code Component
 *
 * @version 1.0.0
 * @param {array} options  the list of options to display
 * @param {function} selectOptions  the function that will be used to select an option (usually a setState)
 * @param {array} selectedOptions  the list of selected options stored in the parents component (usually as a state)
 * @param {string} optionLabel - the attribute of the option object that you want to display as the option's label
 * @param {string} valueLabel  the attribute of the option object that you want to use as the option's value
 * @param {array} lockedItems the optional locked initial selection for the component
 * @param {boolean} disabled lock the selection
 * 
 */

const CustomSelectMultipleComponent = ({ options=[], selectOptions, selectedOptions=[], optionLabel="name", optionLabelDescription=null,
    valueLabel="uniqueId", lockedItems=[], disabled = false, displayNoneOption = false }) => {

    const handleOptionClick = (value) => {
        if (lockedItems.includes(value)){
            return;
        }
        let newSelectedOptions = [...selectedOptions];
        if (newSelectedOptions.includes(value)) {
            newSelectedOptions = newSelectedOptions.filter(opt => opt !== value);
        } else {
            newSelectedOptions.push(value);
        }
        selectOptions(newSelectedOptions);
    };

    return (
        <div className={disabled ? "result-list disabled" : "result-list"}>
            <div className="custom-options">
                {displayNoneOption 
                    && <div onClick={(() => !disabled && selectOptions([]))}
                        className={"option-list-item " + (selectedOptions.length === 0
                            ? "selectedLine-mult" 
                            : "")}>
                        -- Aucun --</div>}
                {options?.map((option, index) => (
                    <div
                        key={`${index}${option[optionLabel]}`}
                        className={"option-list-item " + (lockedItems.includes(option[valueLabel]) 
                            ? "fixedLine" 
                            : selectedOptions.includes(option[valueLabel]) 
                                ? "selectedLine-mult" 
                                : "")
                        }
                        onClick={(() => !disabled && handleOptionClick(option[valueLabel]))}
                    >
                        {option[optionLabel] + (optionLabelDescription ? " - " + option[optionLabelDescription] : "")}
                    </div>
                ))}
            </div>
        </div>
    );
    
};

export default CustomSelectMultipleComponent;
