import React, {useState, useEffect} from 'react';
import { Form, Button } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';

import ButtonLoaderComponent from '../widgets/ButtonLoaderComponent.jsx';

const LoginForm = ({ resetAction, email }) => {
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const {login, setSessionExpired} = useStoreActions(action => action.auth);
    const {getActions} = useStoreActions(action => action.actionSlugs);
    const {sessionExpired} = useStoreState(state => state.auth);

    useEffect(() => {
        if (sessionExpired) {
            setErrorMessage('Votre session a expiré');
            setError(true)
        } else {
            setErrorMessage('');
            setError(false)
        }
    }, [sessionExpired]);

    const onValidate = () => {
        setIsSending(true);

        setError(false);
        setSessionExpired(false);

        const formData = new FormData(document.getElementById('login-form'));
        const value = Object.fromEntries(formData.entries());

        const data = JSON.stringify(value);

        login(data)
            .then(res => {
                getActions();
            })
            .catch((error) => {
                setError(error.error);
                setErrorMessage(`Erreur d'authentification`);
                setIsSending(false);
            })

    }

    return (
        <>
            <Form
                id="login-form"
                onSubmit={(e) => {
                    e.preventDefault(); onValidate();
                }}
                >
                {error ? <div className="alert alert-danger">{errorMessage}</div> : null}
                <Form.Group>
                    <Form.Label className="small mb-1">Email</Form.Label>
                    <Form.Control
                        type="email"
                        className="py-4"
                        id="inputEmail"
                        name="username"
                        defaultValue={email}
                        placeholder="Entrer votre adresse email"
                        required autoFocus />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="small mb-1">Mot de passe</Form.Label>
                    <Form.Control className="py-4" name="password" id="inputPassword" type="password" placeholder="Entrer votre mot de passe" required />
                </Form.Group>
                <Form.Group className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Button as="a" bsPrefix={'no-btn'} className="small" onClick={resetAction}>Mot de passe oublié ?</Button>
                    <ButtonLoaderComponent 
                        variant="primary"
                        className="px-2"
                        size="sm"
                        type="submit"
                        // onClick={onValidate}
                        isSending={isSending}
                        msg="Connexion"
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default LoginForm;