import React from 'react';
import { useStoreState, useStoreActions} from 'easy-peasy';
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";
import { Link } from 'react-router-dom';
import { Button  } from "react-bootstrap";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import { useModal } from "../../../../hooks/useModal";

import './FormationSessionDetailsComponent.scss';
import { useToast } from '../../../../hooks/useToast.jsx';

const FormationSessionRegisteredUsersTableComponent = (props) => {
    const {formationSession, client=null, withParams} = props;
    const {participateUserToFormationSession} = useStoreActions(actions => actions.formationSessions);
    const {setFormationsByClientId} = useStoreActions(actions => actions.clients);
    const {formationsByClientId} = useStoreState(state => state.clients);
    const clientActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const oneClientSlug = clientActionSlugs && clientActionSlugs['readOne'] ? clientActionSlugs['readOne']:null;
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();
   
    const usersToDisplay = formationSession.usersRegistered ? formationSession.usersRegistered : [];


    const userHasParticipated = (userRegistered) => {
       let hasParticipated =  formationSession?.usersHaveParticipated.find((userParticipated)=>{
         return userParticipated.uniqueId == userRegistered.uniqueId;
       });

       if(hasParticipated){
        return "Oui"
       }
       else{
        return "Non"
       }
    };


    if (usersToDisplay?.length === 0) { return <></>; }

    const sortType = usersToDisplay ?
    [
        {
            value : 'email', label : 'E-mail',
            test : usersToDisplay?.find((user)=>{ return user.email !==  undefined}) !== undefined,
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.email, b.email),
            display: (user) => user.email
        },
        {
            value: 'lastName', label: "Nom",
            test : usersToDisplay?.find((user)=>{ return user.lastName !==  undefined}) !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.lastName, b.lastName),
            display: (user) => user.lastName ? user.lastName : '-'
        },
        {
            value: 'firstName', label: "Prénom",
            test : usersToDisplay?.find((user)=>{ return user.firstName !==  undefined}) !== undefined,
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.firstName, b.firstName),
            display: (user) => user.firstName ? user.firstName : '-'
        }, 
        {
            value: 'clientName', label: "Établissement",
            test : usersToDisplay?.find((user)=>{ return user?.client.name !==  undefined}) !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.client?.name, b.client?.name),
            display: (user) => {
                if(!user?.client.name || !user?.client.uniqueId) return '-';
                return user.client.uniqueId && oneClientSlug ?
                    <Link to={oneClientSlug.replace(':uniqueId', user.client.uniqueId)}>{user.client.name}</Link>
                    : <>{user.client.name}</>;
            },
            flatDisplay: (user) => user?.client?.name ? user.client.name:'-'
        },
        {
            value: 'participated', label: "A participé",
            test : formationSession?.usersHaveParticipated != undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(userHasParticipated(a), userHasParticipated(b)),
            display: (user) => userHasParticipated(user)
        },
      ]:null;

      if (AuthorizationChecker.isAdmin()) {
        sortType.push(
            {
                value: 'changeparticipated', label: "",
                test : formationSession?.usersHaveParticipated !== undefined,
                display: (user) => <Button variant='light' size='sm'><i className='fas fa-edit' onClick={() => {updateModal(user)}}/></Button>
            },

        );
      };

    const updateModal =  (user) => {
        if (AuthorizationChecker.isAdmin()) {
        const hasParticipated = userHasParticipated(user) === "Oui" ? true : null;

          const validate = () => {
                setIsSending(true);
                participateUserToFormationSession({
                    formationSessionUniqueId: formationSession.uniqueId,
                    userUniqueId: user.uniqueId,
                    isUnparticipateRequested: hasParticipated,
                })
                .then(response => {
                    if (client && client.uniqueId === user.client.uniqueId && response?.clientFormationsToUpdate) {
                        const updatedFormations = [...formationsByClientId];
                        const formationToUpdateIndex = updatedFormations.findIndex(f=> f.uniqueId === formationSession.formation.uniqueId);
                        const infosToUpdate = response.clientFormationsToUpdate.find(f=> f.uniqueId === formationSession.formation.uniqueId);
                        updatedFormations[formationToUpdateIndex]['formationSessionsUsersHaveParticipatedCount'] = infosToUpdate.formationSessionsUsersHaveParticipatedCount;
                        setFormationsByClientId(updatedFormations);
                    }
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
          }

          setModalData({
            ...modalData,
            header: <>Modification de la participation</>,
            content: <h5>Êtes-vous sûr⋅e de vouloir passer <b>{user.email}</b> en <b>{hasParticipated && 'NON'}</b>&nbsp;participant ?</h5>,
            cancelButton: "Annuler",
            onValidate: validate,
            size: 'm'
          });
          setModalShow(true);
      }
    };

        return (
        <>
            <DynamicTable 
                contentTable = {usersToDisplay}
                contentSort = {sortType}
                valueInitSort = "email"
                index = 'uniqueId'
                    className="table-formation-users-registered"
                withParams={withParams}
                filename="participants_webinaire"
                />
                {modalComponent}
        </>
            )
}

export default FormationSessionRegisteredUsersTableComponent