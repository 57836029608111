import { useStoreState } from 'easy-peasy';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from '../../services/axios';
import ActivitiesCard from './ActivitiesCard.jsx';

import './ActivitiesPanel.scss';

const ActivitiesPanel = () => {
    const [activity, setactivity] = useState(0);

    const updatingInterval = 1000 * 60; // in milliseconds

    const { apiData :{URLs}} = useStoreState(state => state.actionSlugs);

    const getUrl = URLs?.getActivity?.url;

    const updateData = () => {
        axios.get(getUrl)
            .then((response) => {
                setactivity(response);
            })
    }

    const startFetchProcess = () => {
        updateData();
        const INTERVAL_ID = setInterval(updateData, updatingInterval);
        return INTERVAL_ID;
    }

    useEffect(() => {
        const INTERVAL_ID = startFetchProcess();
        return function () {
            clearInterval(INTERVAL_ID);
        }
    }, [])



    return (
            <Container className="welcome-stats">
                <div className="welcome-card">
                    <h1>Aujourd'hui </h1>
                    <Row>
                        <Col>
                            <ActivitiesCard 
                                activityName={'Stories vues'} 
                                activityData={activity.dailyStoriesViewed ? activity.dailyStoriesViewed : '-'} key={'dailyStoriesViewed'} 
                                />

                        </Col>

                        <Col>
                            <ActivitiesCard 
                            activityName={'Expériences immersives VR vues'} 
                                activityData={activity.dailyVideosViewed ? activity.dailyVideosViewed : '-'} key={'dailyVideosViewed'} 
                                />
                        </Col>

                        <Col>
                            <ActivitiesCard 
                                activityName={'Casques utilisés'} 
                                activityData={activity.dailyActiveHeadset ? activity.dailyActiveHeadset : '-'} key={'dailyActiveHeadset'} 
                                />
                        </Col>
                    </Row>
                </div>
                <div className="welcome-card">
                    <h1>Sur 7 jours </h1>

                    <Row>
                        <Col>
                            <ActivitiesCard 
                            activityName={'Stories vues'} 
                            activityData={activity.weeklyStoriesViewed ? activity.weeklyStoriesViewed : '-'} key={'weeklyStoriesViewed'} 
                            />
                        </Col>

                        <Col>
                            <ActivitiesCard 
                            activityName={'Expériences immersives VR vues'} activityData={activity.weeklyVideosViewed ? activity.weeklyVideosViewed : '-'} 
                                key={'weeklyVideosViewed'} />
                        </Col>

                        <Col>
                            <ActivitiesCard
                                activityName={'Casques utilisés'} 
                                activityData={activity.weeklyActiveHeadset ? activity.weeklyActiveHeadset : '-'} key={'weeklyActiveHeadset'} 
                                />
                        </Col>
                    </Row>
                </div>
                <div className="welcome-card">
                    <h1>Sur 30 jours</h1>

                    <Row>
                        <Col>
                            <ActivitiesCard 
                                activityName={'Stories vues'} 
                                activityData={activity.monthlyStoriesViewed ? activity.monthlyStoriesViewed : '-'} key={'monthlyStoriesViewed'} 
                                />
                        </Col>

                        <Col>
                            <ActivitiesCard 
                            activityName={'Expériences immersives VR vues'} 
                                activityData={activity.monthlyVideosViewed ? activity.monthlyVideosViewed : '-'} key={'monthlyVideosViewed'} 
                                />
                        </Col>

                        <Col>
                            <ActivitiesCard 
                                activityName={'Casques utilisés'} 
                                activityData={activity.monthlyActiveHeadset ? activity.monthlyActiveHeadset : '-'} key={'monthlyActiveHeadset'} 
                                />
                        </Col>
                    </Row>
                </div>
            </Container>
    )
}

export default ActivitiesPanel;