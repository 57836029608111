import React, { useState, useEffect} from 'react';
import { Container, Table, Button, Row, Col, Form } from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';
import HelperMetiers360 from "../../../services/HelpersMetiers360";
import SubscriptionFormComponent from '../../subscription/uploadForm/SubscriptionFormComponent.jsx';
import { useToast } from '../../../hooks/useToast.jsx';
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar.jsx";


const CheckMultiClientComponent = (props) => {
    const { clients, clientsCategories, onValidateStep, onCancel, clientsParent, nextStage } = props;
   
    const {fetchTagOperations, setIsUpdateNecessary, setIsFetchingAllTagOperations} = useStoreActions(actions => actions.tagOperations);
    const {allTagOperations} = useStoreState(state => state.tagOperations);
    const { contactsM360, allClients } = useStoreState(state => state.clients);
    const {fetchContactsM360} = useStoreActions(actions => actions.clients);
    const {allClientStatus} = useStoreState(state => state.clientStatus);
    const { fetchClientStatus } = useStoreActions(actions => actions.clientStatus);
    const [errors, setErrors] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const toast = useToast();

    const [selectedTagOpeIds, setSelectedTagOpeIds] = useState([]);
	const [selectedContactM360Ids, setSelectedContactM360Ids] = useState([]);

    useEffect(() => {
        if(clients?.length > 0 && clientsCategories?.length > 0 ){
            let errorsList = clients
                ?.reduce((acc, clientToMap) => {
                    let constraints = constraintsCheck(clientToMap);
                    let csvClienthasErrors = Object.values(constraints).some((error) =>
                        error
                    );
                    if (csvClienthasErrors) {
                        acc.push({ client: clientToMap, errors: constraints })
                    }
                    return acc
                }, [])
                ?.map(clientWithErrors => {
                    let clientErrors = clientWithErrors.errors;
                    let client = clientWithErrors.client;
                    return {
                        client: { ...client },
                        details: {
                            errorClientNameAlreadyExists: {
                                error: clientErrors.duplicatedClient || clientErrors.clientInAllClients,
                                message: "Le nom " + client.name + " existe déjà"
                            },
                            errorNoClientName: { error: clientErrors.noName, message: "Veuillez indiquer le nom du client" },
                            errorWrongUUID: { error: clientErrors.wrongUUID, message: "Aucun client avec cet uniqueId n'existe" },
                            errorWrongParent: { error: clientErrors.wrongParent && !clientErrors.wrongUUID, message: "Ce client n'est pas un enfant de " + clientsParent.clientName },
                            errorCategoryNotExists: { error: clientErrors.undefinedCategories, message: "Une des catégories indiquées n'existe pas" },
                            errorNoCategory: { error: clientErrors.noCategories, message: "Veuillez indiquer au moins une catégorie" }
                        }
                    }
                });
            setErrors(errorsList);
        }
    }, [clientsCategories, clients, allClients]);


    useEffect(() => {
        if(allClientStatus.length == 0){
            fetchClientStatus();
        }
        if(contactsM360.length == 0){
            fetchContactsM360();
        }
    }, []);




    useEffect(() => {
        if(allTagOperations.length < 1){
            setIsUpdateNecessary(true);
            setIsFetchingAllTagOperations(false);
            fetchTagOperations();
        }
    }, [allTagOperations]);



    const statusRow =
            <Form.Control
                    as="select" size="sm" aria-label="Choix statut" 
					name={`clientStatus`}
					isInvalid={formErrors?.clientStatus}
                    >
                    <option value="" >Choisissez un statut</option>
                    {allClientStatus?.map((status) => (
                        <option 
                            key={'row_status'+'_'+status.id}
                            value={status.id}
                        >
                            {status.label}
                        </option>
                        ))}
            </Form.Control>;



    const constraintsCheck = (clientToCheck) => {
        let wrongUUID = false;
        let wrongParent = false;

        let noName = !!(clientToCheck.name === "");
        let noCategories = !!(clientToCheck?.categories?.length < 1);

        if (clientToCheck?.uniqueId) {
            wrongUUID = !!(allClients.find(client => (client?.uniqueId === clientToCheck?.uniqueId)) === undefined);
            wrongParent = clientsParent.clientChildren.includes(clientToCheck?.uniqueId) ? false : true;
        }

        let clientInAllClients = !!(allClients
            .find(client => (!clientToCheck?.uniqueId || client?.uniqueId !== clientToCheck?.uniqueId) && client?.clientName?.toLowerCase() == clientToCheck?.name.toLowerCase()) !== undefined);

        let duplicatedClient = !!(clients
            .filter((otherCsvClient) => (clientToCheck?.uniqueId && clientToCheck?.uniqueId !== "" && otherCsvClient?.uniqueId === clientToCheck?.uniqueId) || otherCsvClient?.name?.toLowerCase() == clientToCheck?.name?.toLowerCase()).length > 1);

        let undefinedCategories = !clientToCheck?.categories
            ?.every(categoryToCheck => clientsCategories?.find((category) => 
                categoryToCheck.replace("-", "").replace("–", "") == category.name.replace("-", "").replace("–", "")
            ));

        return { noName, noCategories, clientInAllClients, duplicatedClient, undefinedCategories, wrongUUID, wrongParent }
    }



    const checkData = (e) => {
        e.preventDefault();
        setFormErrors({});
        let formDataSubscription = new FormData(document.getElementById('subscription-form'));
        let formDataClientsInfos = new FormData(document.getElementById('clients-add-multiple-infos-form'));

        let subscriptionEndDate = formDataSubscription.get(`subscriptionEndDate`);
        let headsetNumberPredicted= formDataSubscription.get(`headsetNumberPredicted`);
        let subscriptionName = formDataSubscription.get(`subscriptionName`);
        let clientStatus = formDataClientsInfos.get(`clientStatus`);

        const errorList = {};
        
        if (subscriptionName == "") {
            errorList.subscriptionName = 'Veuillez indiquer le nom de l\'abonnement'
        }
        if (!subscriptionEndDate) {
            errorList.subscriptionEndDate = 'Veuillez indiquer une date de fin de l\'abonnement'
        }
        if (!headsetNumberPredicted) {
            errorList.headsetNumberPredicted = 'Veuillez saisir le nombre de casque prévu pour l\'abonnement'
        }
        if (!clientStatus) {
            errorList.clientStatus = 'Veuillez sélectionner un statut'
        }
		if (Object.keys(errorList).length) {
			handleErrors({...errorList, global: 'Il y a eu un probleme lors de l\'enregistrement du client'});
            toast.open({ message: Object.values(errorList)?.join(". "), variant: "danger" });
		} else {
			setFormErrors({});
			let tagOperations = selectedTagOpeIds.filter((tag)=>{return (tag!=="all" && tag!=="")})
            let contactsM360 = selectedContactM360Ids.filter((contact)=>{return (contact!=="all" && contact!=="")})
            let clientsInfos = {tagOperations: tagOperations, contactsM360:contactsM360,clientStatus:clientStatus }
           
			let subscription = {
				subscriptionName:formDataSubscription.get(`subscriptionName`),
				headsetNumberPredicted: formDataSubscription.get(`headsetNumberPredicted`),
				subscriptionEndDate:formDataSubscription.get(`subscriptionEndDate`),
				subscriptionCsrfToken: formDataSubscription.get(`subscriptionCsrfToken`)
			};
            onValidateStep(clientsInfos, subscription);
            nextStage();
		}
	}


	const handleErrors = (errorsList) => {
		setFormErrors(errorsList)
	}



    const display = errors?.length == 0 ?
        <Container >
            <Row className="mb-2 mt-2 justify-content-center text-center">
                <Col >
                    <Row className="mb-5">
                        <Row className="mb-4">
					        <h4>Abonnement</h4>
						</Row>
				        <SubscriptionFormComponent action='create' /> 
                    </Row>  

                    { (allTagOperations?.length > 0 || allClientStatus.length > 0 || contactsM360.length > 0) &&
                    
                    <>
                    <Form name="clients-add-multiple-infos-form" id="clients-add-multiple-infos-form">
                    <Row className="mb-4">
                        <h4>Informations clients</h4>
                    </Row>
                    <Row>
                    { allTagOperations?.length > 0 &&
                        <Row className="mb-4">
                            <Row>
							<SelectMultipleItemsWithSearchBar idSelector="clientCRM-Tags-Select" label="Opé M360"
								allItems={allTagOperations} 
								selectedItemKeys={selectedTagOpeIds} setSelectedItemKeys={setSelectedTagOpeIds} 
								itemKey="id" itemValue="name" />
                            </Row>
                        </Row>  
                    }
                    {allClientStatus.length > 0 &&
				    <>
				    <hr />
                       <Row  className="mb-4 justify-content-center text-center">
					      <Col>
                            <p className='form-title'>Statut</p>
                            {statusRow}
                           <Form.Control.Feedback type='invalid'>{formErrors?.clientStatus}</Form.Control.Feedback>
                         </Col>
		               </Row>
				    </>
                    }

				    {contactsM360.length > 0 &&
				    <> 
				    <hr />
                    <Row  className="mb-4 justify-content-center text-center">
					     <Col>
							<SelectMultipleItemsWithSearchBar idSelector="clientCRM-ContactsM360-Select" label="Chargé⋅es de clientèle"
								allItems={contactsM360} 
								selectedItemKeys={selectedContactM360Ids} setSelectedItemKeys={setSelectedContactM360Ids} 
								itemKey="id" itemValue="name" />
                            <Form.Control.Feedback type='invalid'>{formErrors?.contactM360}</Form.Control.Feedback>
                        </Col>
		            </Row>
				   </>
                   }
                   </Row>
                   </Form>
                   </>
                   }
                </Col>  
            </Row>

            <Button variant="success" size="sm"   className="align-right" onClick={checkData}>
                Phase suivante <span className="icon-arrow-right"></span>
            </Button>
          
        </Container>
        : <Container>
            <h3>Certaines données ne sont pas corrects !</h3>
            <Table>
                <thead>
                    <tr>
                        <th>Nom du client</th>
                        <th>Categorie</th>
                        <th>Erreurs</th>
                    </tr>
                </thead>
                <tbody>
                    {errors?.map(error => <tr key={HelperMetiers360.generateUniqueId()}>
                        <td >
                            {error.client.name}
                        </td>
                        <td >
                            {error.client.categories.join(", ")}
                        </td>
                        <td className='bg-danger text-white'>
                            {Object.values(error.details).map((detail, index) =>
                                detail.error && (
                                    <div key={index}>
                                        {detail.message}
                                    </div>
                                )
                            )}
                        </td>
                    </tr>)}
                </tbody>
            </Table>
        </Container>
    return <>
        <Button variant="primary" size="sm" className="mb-3" onClick={onCancel}><span className="icon-arrow-left"></span> Revenir</Button>
        {display}
    </>;
}

export default CheckMultiClientComponent