import React, { useState, useEffect } from 'react';

import Dropzone from 'dropzone';

const UploadMediaComponent = (props) => {

    const {onFileSelected, onFileUploaded, triggerUploadFile} = props;

    const [myDropzone, setMyDropzone] = useState(null);

    Dropzone.autoDiscover = false;

    useEffect(()=> {
        const tmpMyDropzone = new Dropzone("#dropzone-content", {
            url: props.url,
            headers: props.headers,
            withCredentials: props.withCredentials,
            chunking: true,
            maxFilesize: props.maxFileSizeUpload,
            chunkSize: props.chunkSize,
            retryChunks: true,
            retryChunksLimit: props.retryChunksLimit,
            acceptedFiles: props.acceptedFiles,
            maxFiles: 1,
            autoProcessQueue: triggerUploadFile !== undefined ? false : true,
            addRemoveLinks: triggerUploadFile !== undefined ? true : false,
            dictRemoveFile: "Retirer le fichier",
            dictDefaultMessage: props.new ?"Charger un nouveau média":"Insérez votre média ici",
            dictCancelUpload: "Annuler ",
            dictCancelUploadConfirmation: "Etes-vous sûr⋅e de vouloir annuler le chargement ? "
        });

        tmpMyDropzone.on("addedfile", function(file) {
            onFileSelected(tmpMyDropzone.files.length);
        });

        tmpMyDropzone.on("removedfile", function(file) {
            onFileSelected(tmpMyDropzone.files.length);
        });

        tmpMyDropzone.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
        });
        
        tmpMyDropzone.on("complete", function(file) {
            if (file.status === 'canceled')
                return

            let uploadedFile={};

            let dataFromServer = JSON.parse(file.xhr.response);

            uploadedFile.fileName=dataFromServer['fileName'];
            uploadedFile.fileSize=file.size;

            onFileUploaded(uploadedFile);

        });

        setMyDropzone(tmpMyDropzone);

    }, [] );

    useEffect(()=> {
        if(myDropzone && props.acceptedFiles!=myDropzone.options.acceptedFiles){
            myDropzone.destroy();
            const tmpMyDropzone = new Dropzone("#dropzone-content", {
                url: props.url,
                headers: props.headers,
                withCredentials: props.withCredentials,
                chunking: true,
                maxFilesize: props.maxFileSizeUpload,
                chunkSize: props.chunkSize,
                retryChunks: true,
                retryChunksLimit: props.retryChunksLimit,
                acceptedFiles: props.acceptedFiles,
                maxFiles: 1,
                autoProcessQueue: triggerUploadFile !== undefined ? false : true,
                addRemoveLinks: triggerUploadFile !== undefined ? true : false,
                dictRemoveFile: "Retirer le fichier",
                dictDefaultMessage: props.new ?"Charger un nouveau média":"Insérez votre média ici",
                dictCancelUpload: "Annuler ",
                dictCancelUploadConfirmation: "Etes-vous sûr⋅e de vouloir annuler le chargement ? "
            });
    
            tmpMyDropzone.on("addedfile", function(file) {
                onFileSelected(tmpMyDropzone.files.length);
            });
    
            tmpMyDropzone.on("removedfile", function(file) {
                onFileSelected(tmpMyDropzone.files.length);
            });

            tmpMyDropzone.on("maxfilesexceeded", function(file) {
                this.removeAllFiles();
                this.addFile(file);
            });
            
            tmpMyDropzone.on("complete", function(file) {
    
                if (file.status === 'canceled')
                    return
                let uploadedFile={};
    
                let dataFromServer = JSON.parse(file.xhr.response);
    
                uploadedFile.fileName=dataFromServer['fileName'];
                uploadedFile.fileSize=file.size;
    
                onFileUploaded(uploadedFile);
    
            });
    
            setMyDropzone(tmpMyDropzone);
        }

    }, [props] );

    useEffect(()=> {
        if(myDropzone && triggerUploadFile) {
            myDropzone.processQueue();
        }
    }, [triggerUploadFile] );


    return (
        <div id="dropzone-content" className="dropzone" />
    )

}

export default UploadMediaComponent;