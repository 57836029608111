import React, {useState, useEffect} from "react";
import { Container, Row, Col, Button, Alert,  } from "react-bootstrap";
import { useStoreActions, useStoreState } from 'easy-peasy';
import TagOperationTableComponent from "../../components/tagOperation/TagOperationTableComponent";
import TagOperationFormComponent, { submitTagOperationForm } from "../../components/tagOperation/TagOperationFormComponent";

import Loader from "../../components/widgets/Loader.jsx";
import useDebounceSearch from "../../hooks/useDebounchSearch";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import { useModal } from "../../hooks/useModal.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import { useToast } from "../../hooks/useToast.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

const TagOperationTableComponentWithParams = withURLParamsTableComponent(TagOperationTableComponent);

const TagOperationsPage = (props) => {
    const { allTagOperations,isFetchingAllTagOperations } = useCustomGetStoreState("tagOperations");
    const tagOperationSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("tagOperations"));
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);
    const {createTagOperation} = useStoreActions(actions => actions.tagOperations);
    const toast = useToast();

    const {searchInput, search} = useDebounceSearch({placeholder: 'Rechercher par nom', delay: 400});

    const [filteredTagOperations, setFilteredTagOperations] = useState(allTagOperations);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    useEffect(() => {
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, []);

    useEffect(() => {
        setFilteredTagOperations(allTagOperations);
    }, [allTagOperations])

    useEffect(() => {
        setFilteredTagOperations([...
            allTagOperations?.filter(tagOperation => 
                HelperMetiers360.isSearchInText(search, tagOperation.name))
        ]);
    }, [search, allTagOperations])

    const openCreateTagOperationModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            submitTagOperationForm({allTagOperations, action: createTagOperation})
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Création d'un tag opération M360</>,
            content: < TagOperationFormComponent />,
            cancelButton : 'Annuler',
            onValidate: handleSubmit,
            size: 'xl', 
        });
        setModalShow(true);
    }; 

    const createTagOperationsButton = tagOperationSlugs.create
    ? <Button variant="success" onClick={() =>{ openCreateTagOperationModal()}} as="a" ><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
    : null;

    const isNoTagOperations = !isFetchingAllTagOperations && !allTagOperations.length
    const isNoResult = !isFetchingAllTagOperations && allTagOperations.length && !filteredTagOperations.length;

    return <>
        <Container fluid>
            <Row>
                <Col><h2>Tags opérations métiers 360</h2></Col>
            </Row>
            <Row className="item-page">
                <Col>
                    {!isFetchingAllTagOperations && searchInput}
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    {createTagOperationsButton}
                </Col>
            </Row>

            { (isFetchingAllTagOperations &&  allTagOperations.length < 1)  && <Loader /> }
            { filteredTagOperations.length > 0 && <TagOperationTableComponentWithParams tagOperations = {filteredTagOperations}/> }
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucun tag d'operation Métiers 360 ne correspond à votre recherche</Alert>}
            {isNoTagOperations && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun tag d'operation Métiers 360</Alert>}
            { modalComponent}
        </Container>
    </>;

}
export default TagOperationsPage;