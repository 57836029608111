import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Col, Row } from "react-bootstrap";


import UploadSequenceComponent from "../../components/sequences/UploadSequenceComponent.jsx";
import Loader from "../../components/widgets/Loader.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";


const UpdateSequencePage = (props) => {
    const { uniqueId } = useParams(); 
    
    const [isLoading, setIsLoading] = useState(false);

    const { sequenceById } = useStoreState(state => state.sequences);
    const sequenceSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("sequences"));

    const { fetchSequenceById, postSequence, updateSequence } = useStoreActions((actions) => actions.sequences);

    const { backButtonComponent } = useBackButton()

    useEffect(() => {
        setIsLoading(true);
        fetchSequenceById(uniqueId)
            .finally(() => setIsLoading(false));

	}, []);

	return (
		<div className="container p-2">
            {
                (isLoading || !sequenceById ) ? 
                <Loader />
                : <> 
                    <Row className="mb-3">
                        <Col className="d-flex justify-content-between align-items-center">
                            <h2>
                                Mise à jour du parcours : {sequenceById && sequenceById.name ? sequenceById.name : "Mise à jour d'un parcours"}
                            </h2>
                            {backButtonComponent}
                        </Col>
			        </Row>
                    <UploadSequenceComponent 
                    sequence={sequenceById} 
                    postSequence={postSequence} 
                    updateSequence={updateSequence} 
                    action="update"
                    {...sequenceSlugs}
                    />
                </>
            }
			
		</div>
	);
};

export default UpdateSequencePage;
