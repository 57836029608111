import React, { useEffect, useState } from "react";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import PropTypes from 'prop-types';
import InfiniteBoardComponent from "../widgets/infiniteBoardComponent/InfiniteBoardComponent";
import { Container, Form, Row } from "react-bootstrap";
import HelperMetiers360 from "../../services/HelpersMetiers360";
import { useStoreState } from "easy-peasy";

/**
 * 
 * @param {Array} transversalProps array of all transversalProps to be rendered
 * @param {String} transversalProp  string describing the transversalProps to be rendered (areasOfInterest, workingConditions, skills, largeProfessionalFields)
 
 * @example
 *  <TransversalPropsBoardComponent transversalProps={transversalProps} transversalProp={transversalProp} />
 */
const TransversalPropsBoardComponent = ({ transversalProps = [], transversalProp }) => {
    const transversalPropsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher(transversalProp));
    const [transversalPropsToDisplay, setTransversalPropsToDisplay] = useState([]);
    const [sortedTransversalProps, setSortedTransversalProps] = useState([]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedTransversalProps, transversalPropsToDisplay, setTransversalPropsToDisplay, 18);

    const sortType = [];
    HelperMetiers360.isArrayContainsValue(transversalProps, "label") &&
        sortType.push({ value: 'alpha', label: "Ordre alphabétique", method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label) });
    HelperMetiers360.isArrayContainsValue(transversalProps, "usagePros") &&
        sortType.push({ value: 'pros', label: "Nombre de professionnel⋅les", method: (a, b) => b.usagePros - a.usagePros });
    HelperMetiers360.isArrayContainsValue(transversalProps, "usageVideosByPro")
        && sortType.push({ value: 'usageVideosByPro', label: "Nombre d'expériences immersives", method: (a, b) => b.usageVideosByPro - a.usageVideosByPro });
    !HelperMetiers360.isArrayContainsValue(transversalProps, "usageVideosByPro")
        && HelperMetiers360.isArrayContainsValue(transversalProps, "usageVideosByRome")
        && sortType.push({ value: 'usageVideosByRome', label: "Nombre d'expériences immersives", method: (a, b) => b.usageVideosByRome - a.usageVideosByRome });

    const [sort, setSort] = useState(sortType[0]);

    useEffect(() => {
        if (transversalProps?.length && sort?.method) {
            setSortedTransversalProps([...transversalProps].sort(sort.method));
        }
    }, [sort, transversalProps]);

    const sortChoice = (e) => {
        const sortSelected = sortType.filter(type => type.value === e.target.value)[0];
        setSort(sortSelected);
    };

    const checkSort = sortType.map(
        (type) => <Form.Check inline name="sort" label={type.label} type='radio'
            value={type.value} key={type.value} id={type.value}
            onChange={sortChoice} checked={sort.value === type.value} />
    );

    return (
        <Container fluid>
            <Row className="mb-4"><div className="d-flex justify-content-between check-sort">{checkSort}</div></Row>
            <Row>
                <InfiniteBoardComponent
                    listOfMedia={transversalPropsToDisplay}
                    mediaType="transversalProp"
                    isLink={true}
                    fetchData={fetchData}
                    hasMore={hasMore}
                    readOne={transversalPropsSlugs.readOne}
                />
            </Row>
        </Container>
    );
}

TransversalPropsBoardComponent.propTypes = {
    transversalProps: PropTypes.array.isRequired,
    transversalProp: PropTypes.string.isRequired,
};

export default TransversalPropsBoardComponent;