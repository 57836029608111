import React, { useState, useEffect } from "react";
import { Form, Container,  Row } from 'react-bootstrap';
import useInfiniteLoadingLogic from "../../../../hooks/useInfiniteLogic.jsx";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import InfiniteBoardComponent from "../../../widgets/infiniteBoardComponent/InfiniteBoardComponent.jsx";

const StoryBoardComponent = ({ stories, readOne: readOneSlug, sortTypePreferenceTuple }) => {
    

    const [storyBoardSortType, setStoryBoardSortType ] = sortTypePreferenceTuple ? sortTypePreferenceTuple : [null, null];

    
    const sortType = [
        { value: 'name', label: "Nom", method: (a, b) => a.name.localeCompare(b.name) },
        { value: 'date', label: "Publication (plus récente d'abord)", method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate) },
        { value: 'viewCount', label: 'Vues', method: (a, b) => b.viewCount - a.viewCount },
        { value: 'isLiked', label: <>Nombre de <i className="far fa-thumbs-up"></i></>, method: (a, b) => b.isLiked - a.isLiked },
        { value: 'isUnliked', label: <>Nombre de <i className="far fa-thumbs-down"></i></>, method: (a, b) => b.isUnliked - a.isUnliked },
    ];
    
    const [sort, setSort] = useState(storyBoardSortType ? sortType.find(type => type.value === storyBoardSortType) : sortType[0]);
    
    const [sortedStories, setSortedStories] = useState(stories.sort(sort.method));
    const [storiesToDisplay, setStoriesToDisplay] = useState([]);

    useEffect(() => {
        setSortedStories([...stories.sort(sort.method)]);
    }, [sort, stories])

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedStories, storiesToDisplay, setStoriesToDisplay, 60);


    const sortChoice = (e) => {
        const sortSelected = sortType.filter(type => type.value === e.target.value)[0];
        setSort(sortSelected);
        // eslint-disable-next-line no-unused-expressions
        setStoryBoardSortType ? setStoryBoardSortType(sortSelected.value) : null;
    };

    const checkSort = sortType.map(
        type => <Form.Check inline name="sort" label={type.label} type='radio' value={type.value} key={type.value} id={type.value} onChange={sortChoice} checked={sort.value == type.value} />
    );

    return (
        <Container fluid>
            <Row className="mb-4"><div className="d-flex justify-content-around check-sort">{checkSort}</div></Row>
            <Row>
                <InfiniteBoardComponent
                    listOfMedia={storiesToDisplay}
                    mediaType={"story"}
                    readOne={readOneSlug}
                    isLink={true}
                    fetchData={fetchData}
                    hasMore={hasMore}
                    isAdmin = {AuthorizationChecker.isAdmin()}
                />
            </Row>
        </Container>


    );
}
export default StoryBoardComponent;
