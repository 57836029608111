import { OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * Compoment that renders a blue circle with Axonaut logo inside and tooltip to explain.
 */
const AxonautLogoBubbleComponent = ({message,color}) => <OverlayTrigger
    placement="top"
    overlay={
        <Tooltip>
            {"Issu d'Axonaut - " + (message ? message : " Actif sur Axonaut")}
        </Tooltip>
    }    
    >
    <div className={"bubbleLogoAxonaut" + (color ? " bubbleLogoAxonaut-"+color :"")}>
        <span></span>
    </div>
</OverlayTrigger>;

export default AxonautLogoBubbleComponent;