import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const ordersInitialState = {
    allOrders: [],
    allProducts: [],
    allProviders: [],
    allOrderStatus: [],
    isFetchingAllOrders: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    allOrdersByClientId: [],
    isFetchingAllOrdersByClientId: false,
    orderById: null,
    isFetchingOneOrder: false,
    
    allReturnOrders: [],
    allReturnOrderStatus: [],
    allReturnOrderTypes: [],
    allReturnProducts: [],
    isUpdateReturnOrdersNecessary: true,
    isFetchingAllReturnOrders: false,
    isFetchingOneReturnOrder: false,
    returnOrderById: null,
    allReturnOrdersByClientId: [],
    isFetchingAllReturnOrdersByClientId: false,
}

const orders = {
    ...ordersInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allOrders: state.allOrders,
                    allProducts: state.allProducts,
                    allProviders: state.allProviders,
                    allOrderStatus: state.allOrderStatus,
                    isFetchingAllOrders: state.isFetchingAllOrders,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    allOrdersByClientId: state.allOrdersByClientId,
                    isFetchingAllOrdersByClientId: state.isFetchingAllOrdersByClientId,
                    orderById: state.orderById,
                    isFetchingOneOrder: state.isFetchingOneOrder,

                    allReturnOrders: state.allReturnOrders,
                    allReturnOrderStatus: state.allReturnOrderStatus,
                    allReturnOrderTypes: state.allReturnOrderTypes,
                    allReturnProducts: state.allReturnProducts,
                    isFetchingAllReturnOrders: state.isFetchingAllReturnOrders,
                    isUpdateReturnOrdersNecessary: state.isUpdateReturnOrdersNecessary,
                    allReturnOrdersByClientId: state.allReturnOrdersByClientId,
                    isFetchingAllReturnOrdersByClientId: state.isFetchingAllReturnOrdersByClientId,
                } = ordersInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllOrders: action((state, payload) => {
        state.allOrders = payload;
    }),

    setAllProducts: action((state, payload) => {
        state.allProducts = payload;
    }),

    setAllProviders: action((state, payload) => {
        state.allProviders = payload;
    }),

    setAllOrderStatus: action((state, payload) => {
        state.allOrderStatus = payload;
    }),

    setIsFetchingAllOrders: action((state, payload) => {
        state.isFetchingAllOrders = payload;
    }),

    fetchOrders: thunk((actions, payload, helpers) => {
        const { isFetchingAllOrders, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.orders?.readAll?.url;

        if (!isFetchingAllOrders && isUpdateNecessary) {
            actions.setIsFetchingAllOrders(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllOrders(data);
                    actions.fetchProducts();
                    actions.setIsUpdateNecessary(ordersInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllOrders(ordersInitialState.isFetchingAllOrders);
                })
        } else {
            Promise.resolve();
        }
    }),

    setAllOrdersByClientId: action((state, payload) => {
        state.allOrdersByClientId = payload;
    }),

    setIsFetchingAllOrdersByClientId: action((state, payload) => {
        state.isFetchingAllOrdersByClientId = payload;
    }),

    removeFromAllOrders: action((state, payload) => {
        const indexToRemove = state.allOrders.findIndex(order => order.uniqueId === payload);
        (indexToRemove !== -1) && state.allOrders.splice(indexToRemove, 1);
    }),

    removeFromAllOrdersByClientId: action((state, payload) => {
        const indexToRemove = state.allOrdersByClientId.findIndex(order => order.uniqueId === payload);
        (indexToRemove !== -1) && state.allOrdersByClientId.splice(indexToRemove, 1);
    }),

    fetchOrdersByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingAllOrdersByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.orders?.readByClientId?.url?.replace("uniqueId", payload);

        if (!isFetchingAllOrdersByClientId) {
            actions.setIsFetchingAllOrders(true);
            actions.setAllOrdersByClientId(ordersInitialState.allOrdersByClientId);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllOrdersByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllOrders(ordersInitialState.isFetchingAllOrders);
                })
        } else {
            return Promise.resolve();
        }
    }),

    fetchProducts: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.orders?.getParams?.products?.url;
        return axios.get(getUrl)
            .then(data => {
                actions.setAllProducts(data);
            })
            .catch(error => Promise.reject(error))
    }),

    fetchProviders: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.orders?.getParams?.providers?.url;
        return axios.get(getUrl)
            .then(data => {
                actions.setAllProviders(data);
                return data;
            })
            .catch(error => Promise.reject(error))
    }),

    // handlingOneOrder
    setOrderById: action((state, payload) => {
        state.orderById = payload;
    }),
    setIsFetchingOneOrder: action((state, payload) => {
        state.isFetchingOneOrder = payload;
    }),

    fetchOrderById: thunk((actions, payload, helpers) => {
        const { isFetchingOneOrder } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.orders?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneOrder) {
            actions.setIsFetchingOneOrder(true);
            actions.setOrderById(ordersInitialState.orderById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneOrder(ordersInitialState.isFetchingOneOrder);
                    actions.setOrderById(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve();
        }
    }),

    addOrder: action((state, payload) => {
        state.allOrders.push(payload);
    }),

    updateAllOrders: action((state, payload) => {
        const index = state.allOrders.findIndex((order) => order.uniqueId === payload.uniqueId);

        state.allOrders[index] = {
            ...state.allOrders[index],
            ...payload,
        };

    }),

    // createOneOrder
    createOrder: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createOrderUrl = ACTIONS?.orders?.create?.url;
        payload.csrfToken = ACTIONS?.orders?.create?.csrfToken;

        return axios.post(createOrderUrl, payload)
            .then((result) => {
                actions.addOrder(result.orderToAdd);
                actions.setOrderById(result.orderToAdd);
                return result.orderToAdd;
            })
            .catch(error => Promise.reject(error));
    }),

    // deleteOneOrder
    deleteOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const formData = new FormData();
        formData.set(`${apiData.ACTIONS?.orders?.deleteOrder?.formName}[token]`, apiData.ACTIONS?.orders?.deleteOrder?.csrfToken)
        const deleteOrderUrl = apiData.ACTIONS?.orders?.deleteOrder?.url?.replace('uniqueId', uniqueId);

        return axios.delete(deleteOrderUrl, formData)
            .then((deletedOrder) => {
                const { clientCRMById } = helpers.getStoreState().clients;
                if (clientCRMById && clientCRMById.uniqueId === deletedOrder.clientUniqueId) {
                    actions.removeFromAllOrdersByClientId(deletedOrder.uniqueId);
                }
                actions.removeFromAllOrders(deletedOrder.uniqueId);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    // updateOrder
    updateOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.orders?.update?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.orders?.update?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllOrders(result.orderToUpdate);
                actions.setOrderById(result.orderToUpdate);
                return result.orderToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),

    // validateOrder
    validateOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.orders?.actions?.validate?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.orders?.actions?.validate?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllOrders(result.orderToUpdate);
                actions.setOrderById(result.orderToUpdate);
                return result.orderToUpdate;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    // unvalidateOrder
    unvalidateOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.orders?.actions?.unvalidate?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.orders?.actions?.unvalidate?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllOrders(result.orderToUpdate);
                actions.setOrderById(result.orderToUpdate);
                return result.orderToUpdate;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    fetchOrderStatus: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.orders?.getParams?.status?.url;
        return axios.get(getUrl)
            .then(data => {
                actions.setAllOrderStatus(data);
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),


    //#region Return Orders
    setAllReturnOrders: action((state, payload) => {
        state.allReturnOrders = payload;
    }),

    setIsUpdateReturnOrdersNecessary: action((state, payload) => {
        state.isUpdateReturnOrdersNecessary = payload;
    }),

    setIsFetchingAllReturnOrders: action((state, payload) => {
        state.isFetchingAllReturnOrders = payload;
    }),

    setIsFetchingOneReturnOrder: action((state, payload) => {
        state.isFetchingOneReturnOrder = payload;
    }),

    setReturnOrderById: action((state, payload) => {
        state.returnOrderById = payload;
    }),

    addReturnOrder: action((state, payload) => {
        state.allReturnOrders.push(payload);
    }),

    setAllReturnOrderStatus: action((state, payload) => {
        state.allReturnOrderStatus = payload;
    }),

    setAllReturnOrderTypes: action((state, payload) => {
        state.allReturnOrderTypes = payload;
    }),

    setAllReturnProducts: action((state, payload) => {
        state.allReturnProducts = payload;
    }),

    removeFromAllReturnOrders: action((state, payload) => {
        const indexToRemove = state.allReturnOrders.findIndex(order => order.uniqueId === payload);
        (indexToRemove !== -1) && state.allReturnOrders.splice(indexToRemove, 1);
    }),

    updateAllReturnOrders: action((state, payload) => {
        const index = state.allReturnOrders.findIndex((order) => order.uniqueId === payload.uniqueId);

        state.allReturnOrders[index] = {
            ...state.allReturnOrders[index],
            ...payload,
        };

    }),

    setAllReturnOrdersByClientId: action((state, payload) => {
        state.allReturnOrdersByClientId = payload;
    }),

    setIsFetchingAllReturnOrdersByClientId: action((state, payload) => {
        state.isFetchingAllReturnOrdersByClientId = payload;
    }),

    removeFromAllReturnOrdersByClientId: action((state, payload) => {
        const indexToRemove = state.allReturnOrdersByClientId.findIndex(order => order.uniqueId === payload);
        (indexToRemove !== -1) && state.allReturnOrdersByClientId.splice(indexToRemove, 1);
    }),

    fetchReturnOrderStatus: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.returnOrders?.getParams?.returnStatus?.url;

        return axios.get(getUrl)
            .then(data => {
                actions.setAllReturnOrderStatus(data);
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    fetchReturnOrderTypes: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.returnOrders?.getParams?.typesOfReturn?.url;

        return axios.get(getUrl)
            .then(data => {
                actions.setAllReturnOrderTypes(data);
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    fetchReturnOrders: thunk((actions, payload, helpers) => {
        const { isFetchingAllReturnOrders, isUpdateReturnOrdersNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.returnOrders?.readAll?.url;

        if (!isFetchingAllReturnOrders && isUpdateReturnOrdersNecessary) {
            actions.setIsFetchingAllReturnOrders(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllReturnOrders(data);
                    actions.setIsUpdateReturnOrdersNecessary(false);
                    actions.setIsFetchingAllReturnOrders(false);
                    return data;
                })
                .catch(error => {
                    actions.setIsFetchingAllReturnOrders(false);
                    return Promise.reject(error);
                })
        } else {
            return Promise.resolve();
        }
    }),

    fetchReturnOrdersByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingAllReturnOrdersByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.returnOrders?.readByClientId?.url?.replace("uniqueId", payload);

        if (!isFetchingAllReturnOrdersByClientId) {
            actions.setIsFetchingAllReturnOrders(true);
            actions.setAllReturnOrdersByClientId(ordersInitialState.allReturnOrdersByClientId);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllReturnOrdersByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllReturnOrders(false);
                })
        } else {
            return Promise.resolve();
        }
    }),

    fetchReturnOrderById: thunk((actions, payload, helpers) => {
        const { isFetchingOneReturnOrder } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.returnOrders?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneReturnOrder) {
            actions.setIsFetchingOneReturnOrder(true);
            actions.setReturnOrderById(ordersInitialState.orderById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setReturnOrderById(data);
                    return data;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(actions.setIsFetchingOneReturnOrder(false))
        } else {
            actions.setIsFetchingOneReturnOrder(false);
            return Promise.resolve();
        }
    }),

    createReturnOrder: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createReturnOrderUrl = ACTIONS?.returnOrders?.create?.url;
        payload.csrfToken = ACTIONS?.returnOrders?.create?.csrfToken;

        return axios.post(createReturnOrderUrl, payload)
            .then((result) => {
                actions.addReturnOrder(result.returnOrderToAdd);
                actions.setReturnOrderById(result.returnOrderToAdd);
                return result.returnOrderToAdd;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    deleteReturnOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const deleteOrderUrl = apiData.ACTIONS?.returnOrders?.delete?.url?.replace('uniqueId', uniqueId);
        const csrfToken = apiData.ACTIONS?.returnOrders?.delete?.csrfToken;

        return axios.post(deleteOrderUrl, {csrfToken})
            .then((deletedOrder) => {
                const { clientCRMById } = helpers.getStoreState().clients;
                if (clientCRMById?.uniqueId === deletedOrder.clientUniqueId) {
                    actions.removeFromAllReturnOrdersByClientId(deletedOrder.uniqueId);
                }
                actions.removeFromAllReturnOrders(deletedOrder.uniqueId);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    updateReturnOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.returnOrders?.update?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.returnOrders?.update?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllReturnOrders(result.returnOrderToUpdate);
                actions.setReturnOrderById(result.returnOrderToUpdate);
                return result.returnOrderToUpdate;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    validateReturnOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.returnOrders?.actions?.validate?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.returnOrders?.actions?.validate?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllReturnOrders(result);
                actions.setReturnOrderById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    unvalidateReturnOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.returnOrders?.actions?.unvalidate?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.returnOrders?.actions?.unvalidate?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllReturnOrders(result);
                actions.setReturnOrderById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    closeReturnOrder: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateOrderUrl = ACTIONS?.returnOrders?.actions?.close?.url?.replace('uniqueId', uniqueId);
        payload.csrfToken = ACTIONS?.returnOrders?.actions?.close?.csrfToken;

        return axios.post(updateOrderUrl, payload)
            .then((result) => {
                actions.updateAllReturnOrders(result);
                actions.setReturnOrderById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    fetchReturnProducts: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.returnOrders?.getParams?.products?.url;
        return axios.get(getUrl)
            .then(data => {
                actions.setAllReturnProducts(data);
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),
};

export default orders;