import React from "react";
import { DropdownButton, Navbar, Dropdown, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useStoreState, useStoreActions} from 'easy-peasy';

import PassWordComponent from "../../components/security/PasswordComponent";
import { useModal } from "../../hooks/useModal";

import "./Header.scss";

const Header = (props) => {
	
    const { isMobile, isTablet, navIsOpen } = useStoreState(state => state.responsive);
	const {setNavIsOpen} = useStoreActions(actions => actions.responsive);
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const { logout } = useStoreActions(actions => actions.auth);
    const { username } = useStoreState(state => state.auth);
    const navigate = useNavigate();

    const changePasswordModal = () => {
        setModalData({
            ...modalData,
            header: 'Changement de mot de passe',
            content: <PassWordComponent />,
            size: 'xl',
        });
        setModalShow(true);
    }


    const userDropDown = <DropdownButton align={"end"} style={{ right: 'calc(0%)!important' }} as={'span'} id="my-dropdown-button" title={username ? username : ""  /*USER.firstName ? USER.firstName:USER.userName*/} >
        <Dropdown.Item className="align-self-end" onClick={changePasswordModal}>Changer le mot de passe</Dropdown.Item>
        <Dropdown.Item
            className="align-self-end"
            onClick={() => {
                navigate('/');
                logout();
            }
            }
        >Déconnexion</Dropdown.Item>
    </DropdownButton>;
	
	const burger = navIsOpen ? <i className="burger-icon fa fa-times"></i> :  <i className="burger-icon fa fa-bars"></i>

	return (
		<header className='header'>
			<Navbar
			>
                    <Navbar.Brand
                        bg='primary'
                        className='px-0'
                        onClick={() => setNavIsOpen(!navIsOpen)}
                    >
                    {(isMobile || isTablet) && burger}
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className='justify-content-end align-items-center'>
                        <Navbar.Text>{userDropDown}</Navbar.Text>
                    </Navbar.Collapse>
			</Navbar>
            {modalComponent}
		</header>
	);
};

export default Header;
