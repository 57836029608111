import React from 'react';
import ButtonLoaderComponent from '../../widgets/ButtonLoaderComponent.jsx';
import PropTypes from 'prop-types';


const HeadsetCommentForm = ({isSending, newCommentContent, onValidate, contentIsEmpty, setContentIsEmpty}) => {

  return (
    <div className='comment-form'>
      <textarea rows={5} ref={newCommentContent} onChange={()=> {if (contentIsEmpty) {setContentIsEmpty(false)}}}></textarea>
      {contentIsEmpty && <p style={{color: 'red'}}>Merci d'inclure un contenu</p>}
      <ButtonLoaderComponent 
      onClick={onValidate}
      variant='success'
      className='mt-4' 
      msg="Valider"
      isSending={isSending}
      />
    </div>
  )
}

HeadsetCommentForm.propTypes = {
  isSending: PropTypes.bool,
  newCommentContent: PropTypes.any,
  onValidate: PropTypes.func,
  contentIsEmpty: PropTypes.bool,
  setContentIsEmpty: PropTypes.func,
};

export default HeadsetCommentForm