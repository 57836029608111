import React, { useRef, useState } from 'react';

const useCheckDataProps = (dataArray) => {
    const [isValid, setIsValid] = useState(true);
    const invalidDetected = useRef(false);

    const handler = {
        get: function (obj, prop) {
            if (prop in obj) {
                return obj[prop];
            } else if (!invalidDetected.current) {
                invalidDetected.current = true;
                setIsValid(false);
                return undefined;
            }
            return undefined;
        }
    }

    if (dataArray?.length) {

        const firstItemProxy = new Proxy(dataArray[0], handler);
        const proxy = [firstItemProxy, ...dataArray.slice(1)];
        return [proxy, isValid];

    } else {
        return [dataArray, isValid];
    }

};

export default useCheckDataProps;