import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import Loader from "../../components/widgets/Loader.jsx";
import { useModal } from '../../hooks/useModal.jsx';
import { useToast } from '../../hooks/useToast.jsx';
import UserTableComponent from '../../components/users/usersList/UserTableComponent.jsx';
import AuthorizationChecker from '../../services/AuthorizationChecker.js';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import { useStoreActions, useStoreState } from "easy-peasy";
import withURLParamsTableComponent from '../../HOC/withURLParamsTableComponent.jsx';
import CreateMultiUsersOrContactsComponent from '../../components/clients/multiUsersOrContacts/CreateMultiUsersOrContactsComponent.jsx';
import UsersFiltersComponent from '../../components/users/usersList/UsersFiltersComponent.jsx';
import CreateUpdateUserComponent, { submitUserForm } from '../../components/users/CreateUpdateUserComponent.jsx';
import TooltipInfo from '../../components/widgets/tooltipInfo/TooltipInfo.jsx';

const UserTableWithParams = withURLParamsTableComponent(UserTableComponent);

const UsersPage = () => {
    const { allUsers, isFetchingAllUsers: usersAreLoading, isMergeClientsPropsInAllUsersNecessary, isUpdateNecessary,
        isMergeContactsPropsInAllUsersNecessary, initialFetchDoneContacts } = useCustomGetStoreState("users");
    const { mergeClientsPropsInAllUsers, createUser, setCreateFormErrors, mergeContactsPropsInAllUsers, fetchContacts } = useStoreActions(actions => actions.users);
    const { allClients, isAllClientPropsAreMerged, initialFetchDone:initialFetchDoneClient } = useCustomGetStoreState("clients");
    const { fetchAllClientsAndMergeWithProps } = useStoreActions(actions => actions.clients);
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);

    const [filteredUsers, setFilteredUsers] = useState([]);
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    const toast = useToast();

    useEffect(() => {
        initialFetchDoneClient && fetchAllClientsAndMergeWithProps({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, [initialFetchDoneClient]);

    useEffect(() => {
        AuthorizationChecker.isAdmin() && fetchContacts();
    }, []);

    const shouldMergeClientsPropsInAllUsers = isMergeClientsPropsInAllUsersNecessary && isAllClientPropsAreMerged && !isUpdateNecessary;
    const shouldMergeContactsPropsInAllUsers = isMergeContactsPropsInAllUsersNecessary && isAllClientPropsAreMerged && !isUpdateNecessary;
    
    useEffect(() => {
        if(shouldMergeClientsPropsInAllUsers) mergeClientsPropsInAllUsers({hasAccessToClientsProps: AuthorizationChecker.hasReadAllRights('clientsCRM')});
    }, [shouldMergeClientsPropsInAllUsers]);

    useEffect(() => {
        if (AuthorizationChecker.isAdmin() && initialFetchDoneContacts && shouldMergeContactsPropsInAllUsers)
            mergeContactsPropsInAllUsers();
    }, [initialFetchDoneContacts, shouldMergeContactsPropsInAllUsers]);

    const allUsersEmails = useMemo(() => {
        if (!AuthorizationChecker.isAdmin()) {
            return allUsers
                ? allUsers.map(u => u.email)
                : [];
        } else {
            return [];
        }
    }, [allUsers]);

    const isNoUsers = !usersAreLoading && !allUsers.length;
    const isNoResult = !usersAreLoading && allUsers?.length > 0 && filteredUsers?.length === 0;

    const createMultiUserModal = () => {
        setModalData({
            ...modalData,
            header: <>Ajouter plusieurs utilisateur⋅rices</>,
            content: <CreateMultiUsersOrContactsComponent entityType="user" />,
            size: 'lg',
            id: 'client-create-multi-user-modal'
        });
        setModalShow(true);
    }

    const createUserModal = () => {
        const validate = () => {
            setIsSending(true);
            const { formName } = ACTIONS['users']['create'];
            return submitUserForm(createUser, formName, null, null, allUsersEmails, true, false)
                .then(() => {
                    toast.success();
                    setIsSending(false);
                    setModalShow(false);
                })
                .catch(error => {
                    error?.formError && setCreateFormErrors(error.formError);
                    setIsSending(false);
                })
        }
        setModalData({
            ...modalData,
            header: <>Ajout d'un⋅e utilisateur⋅rice</>,
            content: <CreateUpdateUserComponent action="create" clients={allClients} />,
            cancelButton: 'Annuler',
            onValidate: validate,
            size: 'lg'
        });
        setModalShow(true);
    };

    return <Container fluid>
        <Row>
            {!AuthorizationChecker.isAdmin() &&
                <Col>
                    <h2 className='d-flex'>
                        Vos différent⋅es utilisateur⋅rices
                        <TooltipInfo description='Les utilisateur⋅rices de votre réseau possédant un accès à leur espace.' />
                    </h2>
                </Col>
            }
            {AuthorizationChecker.isAdmin() && 
                <Row className='pe-0'>
                    <Col>
                        <Alert variant='info' className='mt-2'>
                            <i className="fas fa-plus me-2"/>
                            Pour créer un accès, merci de créer un contact dans l'onglet CRM puis de lui donner l'accès BO.
                            <br/>
                            <i className="fas fa-edit me-2"/>
                            Pour modifier, transférer ou supprimer un accès, merci d'utiliser l'onglet CRM de l'établissement.
                            <br/>
                        </Alert>
                    </Col>
                    <Col className='d-flex justify-content-end align-items-center pe-0' xs={3}>
                        <Button variant="success" onClick={createMultiUserModal}><i className="fas fa-plus"/>&nbsp; Ajout multiple</Button>
                    </Col>
                </Row>
            }
        </Row>


        <Row className="item-page">
            <Col>
                {!usersAreLoading && <UsersFiltersComponent allUsers={allUsers} setFilteredUsers={setFilteredUsers}
            allParentClients={allClients.filter(client => client.childrenCount > 0)} 
                    displayFilterZone={AuthorizationChecker.isAdmin()} />}     
            </Col>
            {!AuthorizationChecker.isAdmin() && AuthorizationChecker.hasCreateRights('users') && initialFetchDoneClient && allClients?.length > 0 &&
                <Col className="d-flex justify-content-end align-items-center">
                    <Button variant="success" onClick={createUserModal}><i className="fas fa-plus" />&nbsp; Ajout utilisateur⋅rice</Button>
                </Col>}
        </Row>
        <Row>
            { (usersAreLoading) && <Loader /> }
            {isNoResult && <Alert className="mt-3" variant="warning">
                Aucun⋅e utilisateur⋅rice ne correspond à votre recherche
                </Alert>}
            {isNoUsers && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun⋅e utilisateur⋅rice</Alert>}
            {filteredUsers.length > 0 && <UserTableWithParams users={filteredUsers} full={true}
                isLoading={AuthorizationChecker.hasReadAllRights('clientsCRM') && !isAllClientPropsAreMerged}/>}
        </Row>
        {modalComponent}
    </Container>
}

export default UsersPage;