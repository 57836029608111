import React, {useState, useEffect } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { useStoreActions } from "easy-peasy";

import Loader from "../../components/widgets/Loader.jsx";
import ClientsTableComponent from "../../components/clients/ClientsList/ClientsTableComponent.jsx";
import ClientsFiltersComponent from "../../components/clients/ClientsList/ClientsFiltersComponent.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";

import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";


import "./ClientsPage.scss";
import TooltipInfo from "../../components/widgets/tooltipInfo/TooltipInfo.jsx";

const ClientTableWithParams = withURLParamsTableComponent(ClientsTableComponent);

const template = (title, content) => <Container fluid>
            <Row>
                <Col>
            <h2 className="d-flex">
                {title}
                {!AuthorizationChecker.isAdmin()
                    && <TooltipInfo description="Voici tous les établissements rattachés à votre compte et les casques de chacun." />
                }
            </h2>

                </Col>
            </Row>
            <Row>
                {content}
            </Row>
</Container>;

const emptyContent = <Loader />;
const title = "Vos établissements";

const ClientsPage = () => {
    const { allClients, isFetchingAllClients, isGradualFetchClientsNecessary, isGradualFetchClientsCRMNecessary } = useCustomGetStoreState("clients");
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);

    const [filteredClients, setFilteredClients] = useState(allClients);

    useEffect(() => {
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, []);

    useEffect(() => {
        setFilteredClients(allClients);
    }, [allClients]);


    const clientsAreLoading = isFetchingAllClients || isGradualFetchClientsNecessary || isGradualFetchClientsCRMNecessary;
    const isNoClients = !clientsAreLoading && allClients.length === 0
    const isNoResult = !clientsAreLoading && allClients.length > 0 && filteredClients?.length === 0;

    const content = <>
        <Row>
            <Col>
                {!clientsAreLoading
                    && <ClientsFiltersComponent allClients={allClients} setFilteredClients={setFilteredClients} isAdmin={AuthorizationChecker.isAdmin()} />}
            </Col>
        </Row>
        <Row>
            { (clientsAreLoading) && <Loader /> }
            { filteredClients?.length > 0 && <ClientTableWithParams clients={filteredClients} /> }
            { isNoResult && <Alert className="mt-3" variant="warning">
                    Aucun établissement ne correspond à votre recherche
                </Alert>}
            { isNoClients && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun établissement</Alert>}
        </Row>
    </>;

    return template(title, content);
}

export default ClientsPage;
