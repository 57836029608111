import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import  { Alert, Image } from 'react-bootstrap';
import axios from '../../../services/axios';
import { useStoreState } from 'easy-peasy';
import Loader from '../../widgets/Loader';
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import useDebounceSearch from '../../../hooks/useDebounchSearch';
import DynamicTable from '../../widgets/dynamicTable/DynamicTable';

const HeadsetAccessibleQuizzes = ({ headsetDeviceId, withParams }) => {

    const {apiData: {URLs}} = useStoreState(state => state.actionSlugs);

    const [quizzesInHeadset, setQuizzesInHeadset] = useState([]);
    const [filteredQuizzesInHeadset, setFilteredQuizzesInHeadset] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const {searchInput, search } = useDebounceSearch({placeholder :"Rechercher un quiz"});

    useEffect(() => {
        setIsFetching(true);
        const getUrl = URLs['getQuizInHeadset'].url;
        
        const formData = new FormData();
        formData.set('device', headsetDeviceId);

        axios.post(getUrl, formData)
            .then(data => {
                if (data?.quizzes?.length > 0) {
                    const quizzesToDisplay = data.quizzes
                        .map(({uniqueId, name, links}) => {
                            return {
                                uniqueId,
                                name,
                                links
                            }
                        })
                    setQuizzesInHeadset([...quizzesToDisplay]);
                    setFilteredQuizzesInHeadset([...quizzesToDisplay])
                }
            })
            .finally(() => setIsFetching(false))
    }, [headsetDeviceId])

    useEffect(() => {
        setFilteredQuizzesInHeadset(quizzesInHeadset.filter(quiz => 
            HelperMetiers360.isSearchInText(search, quiz.name)
        ));
    }, [search])

    const displayThumbnail = (thumbnail) => 
        <Image src={thumbnail} className="img-overlay" thumbnail />;
    
    const sortType = [
        {
            value : 'thumbnail', label : 'Vignette',
            test : HelperMetiers360.isArrayContainsValue(filteredQuizzesInHeadset, "links"),
            method: null,
            display: (quiz) => displayThumbnail(quiz.links?.thumbnail),
            flatDisplay: (quiz) => quiz.links?.thumbnail
        },
        {
            value : 'name', label : 'Nom',
            test : HelperMetiers360.isArrayContainsValue(filteredQuizzesInHeadset, "name"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (quiz) => quiz.name
        }
    ];

    return <>{ isFetching
        ? <Loader />
        : quizzesInHeadset.length > 0
            ? <>
                <div className='w-100' style={{marginBottom: "-2em"}}>{searchInput}</div>
                { filteredQuizzesInHeadset.length > 0
                    ? <DynamicTable
                        contentTable={filteredQuizzesInHeadset}
                        contentSort={sortType}
                        valueInitSort="name"
                        index='uniqueId' 
                        filename="casque_quiz_accessibles"
                        withParams={withParams} />
                    : <Alert variant='warning' className='w-100'>Aucun quiz ne correspond à votre recherche</Alert>
                }
            </>
            : <Alert variant='danger' className='w-100'>Pas de quiz disponible</Alert>
    }</>
}

HeadsetAccessibleQuizzes.propTypes = {
    headsetDeviceId: PropTypes.string.isRequired,
    withParams: PropTypes.bool
}

export default HeadsetAccessibleQuizzes;