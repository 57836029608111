import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import Loader from "../../../components/widgets/Loader";
import PreparatoryOrderDetailsComponent from "../../../components/orders/preparatoryOrders/PreparatoryOrderDetails/PreparatoryOrderDetailsComponent";
import useBackButton from "../../../hooks/useBackButton";

const PreparatoryOrderDetail = () => {
    const {uniqueId} = useParams();

    const { fetchOrderById } = useStoreActions(actions => actions.orders);
    const { orderById } = useStoreState(state => state.orders);
    const { backButtonComponent } = useBackButton();
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        if (!isLoading) {
            setIsLoading(true);
            fetchOrderById(uniqueId)
                .finally(() => setIsLoading(false));
        }

        return () => setIsMounted(false);
    },[uniqueId]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className="d-flex justify-content-between">
                        Bon de préparation {orderById?.id && ' N° '+orderById.id}
                        {backButtonComponent}
                    </h2>
                </Col>
            </Row>
            <Row className="item_detail_page">
                { (!orderById || isLoading || !isMounted)
                    ? <Loader />
                    : <PreparatoryOrderDetailsComponent order={orderById}/>}
            </Row>
        </Container>
    );
}

export default PreparatoryOrderDetail;