
import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Image, InputGroup } from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';

import ButtonLoaderComponent from '../../../../widgets/ButtonLoaderComponent.jsx';
import './WebappMagicboxFormComponent.scss';
import { useToast } from '../../../../../hooks/useToast.jsx';

const WebappMagicboxFormComponent = ({ client, onValidate, onDelete}) => {
    const { apiData: {ACTIONS} } = useStoreState(state => state.actionSlugs);
    const fileInput = useRef(null);
    const toast = useToast();
    const [magicBoxLinks, setMagicBoxLinks] = useState((client.webappCustomisation.magicBoxLinks && client.webappCustomisation.magicBoxLinks[0])
                                                            ? [...client.webappCustomisation.magicBoxLinks]
                                                            : [] );
    const [description, setDescription] = useState('');
    const [fileInputName, setFileInputName] = useState('Choisir le fichier');
    const [formErrors, setFormErrors] = useState({});
    const [isSending, setIsSending] = useState(false);
    const {csrfToken, formName} = ACTIONS['clients']['editProps']['editMagicboxLink'];



    useEffect(() => {
        setMagicBoxLinks((client.webappCustomisation.magicBoxLinks && client.webappCustomisation.magicBoxLinks[0])
        ? [...client.webappCustomisation.magicBoxLinks]
        : [] )
    } , [client])


    const LinkList = magicBoxLinks.length ?
        magicBoxLinks.map((link) =>
           (<Row key={link.uniqueID}>
                <Col xs={12} md={4}>
                    <Image src={link.imgUrl} fluid/>
                </Col>
                <Col xs={12} md={8}>
                {
                    !client.webappCustomisation.fromParent ? 
                    <span className="action-icon"><i className="fas fa-trash-alt" onClick={() => onDelete(link.uniqueID)  } ></i></span>:
                    null
                    
                    }
                    <strong><a href={link.externalUrl}>{link.title}</a></strong><br />
                    {link.description}
                </Col>
            </Row>)) : <p>Pas encore de liens personnalisés dans la magicbox</p>;

    const onCheckData = () => {
        const formData = new FormData(document.getElementById(formName));
        const url = formData.get(`${formName}[url]`);
        const title = formData.get(`${formName}[title]`);
        const imageFile = formData.get(`${formName}[imageFile]`);
        let errorsList = {};
        var patternURL = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator


        if (!url || !patternURL.test(url)) {
            errorsList.url = 'Veuillez saisir une url valide';
        }
        if (!title) {
            errorsList.title = 'Veuillez saisir un titre';
        }
        if (!description) {
            errorsList.description = 'Veuillez saisir une description';
        }
        if (!imageFile.size) {
            errorsList.imageFile = 'Veuillez ajouter une image pour que le lien apparaisse dans la magic box';
        }

        if (Object.keys(errorsList).length) {
            setFormErrors(errorsList);
            return false
        }
        else {

            setFormErrors({});
            return true;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onCheckData() === true) {
            setIsSending(true)
            onValidate()
                .then(() => {
                    setFileInputName('Choisir le fichier');
                    setDescription('');
                    toast.success()
                })
                .catch((error) => {
                    setFormErrors(error.errors);
                })
                .finally(() => setIsSending(false));
        }
    }

    

    return (
        <>
            <Form id={formName} className="form-magicbox-link" method="post" encType="multipart/form-data" onSubmit={handleSubmit} noValidate>
            {/* <h5>Liens Magicbox</h5> */}
            { LinkList }
            <Form.Label ><strong>Ajout d'un lien</strong></Form.Label>
                <Row>
                    <Col>
                        <Form.Label>Lien :</Form.Label>
                        <Form.Control as={'input'}
                            name={`${formName}[url]`}
                            type="url"
                            placeholder="https://example.com"
                            isInvalid={formErrors.url != undefined}
                            required={formErrors.url != undefined}
                            />
                        <Form.Control.Feedback type='invalid'>{formErrors.url}</Form.Control.Feedback>
                    </Col>
                    <Col>
                        <Form.Label>titre :</Form.Label>
                        <Form.Control as={'input'}
                            name={`${formName}[title]`}
                            type="text"
                            placeholder="Titre affichés dans la magic box"
                            isInvalid={formErrors.title != undefined}
                            required={formErrors.title != undefined}
                            />
                        <Form.Control.Feedback type='invalid'>{formErrors.title}</Form.Control.Feedback>
                    </Col>
                </Row>
                <Row>
                    <Col className="my-2"> Description :
                        <textarea 
                            style={{width:'100%'}}
                            name={`${formName}[description]`}
                            type="text"
                            placeholder="description affichés dans la magic box"
                            required = {formErrors.description != undefined}
                            value={description} onChange={(e) => {setDescription(e.target.value);}} />
                        <Form.Control
                            type="hidden"
                            isInvalid={formErrors.description != undefined}
                        />  
                        <Form.Control.Feedback type='invalid'>{formErrors.description}</Form.Control.Feedback>
                    </Col>
                </Row>
                <InputGroup /*className="input-group mb-3"*/ >
                    {/* <div className="input-group-prepend"> */}
                        <InputGroup.Text id="inputGroupFileAddon02">Image correspondant dans la magicbox</InputGroup.Text>
                    {/* </div> */}
                    <div className="custom-file">
                        <input 
                            type="file" 
                            accept="image/png, image/jpeg"
                            className={"custom-file-input"+(formErrors.imageFile ? " is-invalid":null)}
                            id="inputGroupFile02" 
                            name={`${formName}[imageFile]`}
                            aria-describedby="inputGroupFileAddon02"
                            ref={fileInput}
                            onChange={()=>setFileInputName(fileInput.current.files[0].name)} />
                        <label className="custom-file-label" htmlFor="inputGroupFile02">
                            {fileInputName}
                        </label>
                    </div>
                    <Form.Control
                        type="hidden"
                        isInvalid={formErrors.imageFile != undefined}
                    />
                    <Form.Control.Feedback type='invalid'>{formErrors.imageFile}</Form.Control.Feedback>
                </InputGroup>
                <input type="hidden" name={`${formName}[_token]`} value={csrfToken} /> 
                <ButtonLoaderComponent
                    block={true}
                    type="submit"
                    variant='success'
                    size='sm'
                    className = "mt-4 mx-auto d-flex"
                    isSending={isSending}
                    active={!isSending}
                    disabled={isSending}
                    msg={<><i className="fas fa-check me-2 text-white"/> Valider</>}

                    />
                    
            </Form>
        </>
    )
}


export const submitMagicboxForm = (updateWebappMagicboxLink, uniqueId, formName) => {
    const form = document.getElementById(formName);
    const formData = new FormData(form);
    return updateWebappMagicboxLink({formData, uniqueId})
        .then(data => {
            form.reset();
            return data;
        })
    
};

export const deleteMagicboxLink = (deleteWebappMagicboxLink, uniqueId, linkUniqueId, csrfToken) => {
    const formData = new FormData();
    // const csrfToken = ACTIONS['clients']['editProps']['deleteMagicboxLink'].csrfToken;

    formData.set('token', csrfToken);
   return deleteWebappMagicboxLink({uniqueId, linkUniqueId, formData})
    .then((data) => {
        return data;
    }).catch((error) => {
       
    })
};

export default WebappMagicboxFormComponent;