import React from "react";
import { Alert, Container, Col, Row, Button } from "react-bootstrap";

const TestPage = (props) => {

    const titleDemo = <>
        <h1>Titre 1</h1>
        <h2>Titre 2</h2>
        <h3>Titre 3</h3>
        <h4>Titre 4</h4>
    </>;

    const buttonSamples = <>
    <Button variant="primary">Primary</Button>{' '}
        <Button variant="secondary">Secondary</Button>{' '}
        <Button variant="success">Success</Button>{' '}
        <Button variant="warning">Warning</Button>{' '}
        <Button variant="danger">Danger</Button>{' '}
        <Button variant="info">Info</Button>{' '}
        <Button variant="light">Light</Button>{' '}
        <Button variant="dark">Dark</Button>
        <Button variant="link">Link</Button>
    </>;

    const buttonSamples2 = <>
        <Button href="#">Link</Button> <Button type="submit">Button</Button>{' '}
        <Button as="input" type="button" value="Input" />{' '}
        <Button as="input" type="submit" value="Submit" />{' '}
        <Button as="input" type="reset" value="Reset" />
    </>;

    const buttonSize = <>
        <div className="mb-2">
        <Button variant="primary" size="lg">
            Large button
        </Button>{' '}
        <Button variant="secondary" size="lg">
            Large button
        </Button>
        </div>
        <div>
        <Button variant="primary" size="sm">
            Small button
        </Button>{' '}
        <Button variant="secondary" size="sm">
            Small button
        </Button>
        </div>
    </>;

    const colRowDemo = <Container>
    <Row>
        <Col>
        <strong>Une première colonne. Suivie de 3 colonnes en dessous.</strong><br/>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
    </Row>
    <Row>
        <Col>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
        <Col>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
        <Col>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel elementum orci. Praesent nec luctus tellus, convallis aliquet leo. Curabitur lacinia, tortor sed hendrerit feugiat, diam nulla facilisis sapien, ac rhoncus purus metus eleifend ipsum. Ut vitae sapien at dolor commodo tincidunt. Fusce vestibulum dui sit amet diam egestas ultricies. Quisque quis tortor erat. Vestibulum venenatis, erat eget imperdiet consequat, neque nibh elementum felis, id rutrum mi ipsum et lacus. Sed leo ipsum, fringilla ac finibus rhoncus, aliquet vel odio. Integer vel pulvinar nulla. Ut ut leo ipsum. Aliquam vehicula nibh ac est aliquam, ac ultricies tortor pharetra. Quisque placerat dolor nulla, non dictum ex pharetra placerat. Morbi quis lectus tincidunt, elementum neque vel, condimentum nisl.
        </Col>
    </Row>
    <Row>
        <Col>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
        <Col>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
        <Col>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
        <Col>
        Aenean tempus molestie massa, sed semper velit faucibus ut. Sed maximus nisi quis ligula faucibus, eu ornare quam fringilla. Sed neque quam, interdum a massa a, consequat mollis ipsum. Donec vitae ex lorem. Pellentesque a libero nisi. Sed euismod lectus ornare, maximus risus nec, feugiat lorem. In hac habitasse platea dictumst. Ut quis pellentesque leo. Cras pharetra viverra volutpat. Quisque id pulvinar turpis.
        </Col>
    </Row>
    </Container>;
      
    const alertSamples = [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
      ].map((variant) => (
        <Alert key={variant} variant={variant}>
          This is a {variant} alert—check it out!
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel elementum orci. Praesent nec luctus tellus, convallis aliquet leo. Curabitur lacinia, tortor sed hendrerit feugiat, diam nulla facilisis sapien, ac rhoncus purus metus eleifend ipsum. Ut vitae sapien at dolor commodo tincidunt. Fusce vestibulum dui sit amet diam egestas ultricies. Quisque quis tortor erat. Vestibulum venenatis, erat eget imperdiet consequat, neque nibh elementum felis, id rutrum mi ipsum et lacus. Sed leo ipsum, fringilla ac finibus rhoncus, aliquet vel odio. Integer vel pulvinar nulla. Ut ut leo ipsum. Aliquam vehicula nibh ac est aliquam, ac ultricies tortor pharetra. Quisque placerat dolor nulla, non dictum ex pharetra placerat. Morbi quis lectus tincidunt, elementum neque vel, condimentum nisl.
        </Alert>
      ));

    return <>
        {titleDemo}
        <h2>Démo boutons</h2>
        {buttonSamples}
        <h2>Démo boutons en tant qu'un autre élément</h2>
        {buttonSamples2}
        <h2>Démo taille boutons</h2>
        {buttonSize}
        <h2>Exemple de mise en page sous forme de colonne</h2>
        {colRowDemo}
        <h2>Exemple d'alertes</h2>
        {alertSamples}
        </>
}

export default TestPage;