import React, {useState} from "react";
import Loader from "../../../widgets/Loader";
import { ListGroup, ListGroupItem, Form, Button } from "react-bootstrap";

import HelperMetiers360 from "../../../../services/HelpersMetiers360";

const ClientInvoicesComponent = (props) => {
    const {client, onSelect, initSelected = null} = props;

    const [selected, setSelected] = useState(initSelected);
    const [numberToDisplay, setNumberToDisplay] = useState((initSelected && client.invoices.findIndex(x => x.number === selected) > 2) ? (client.invoices.findIndex(x => x.number === selected) + 1) :3);
    if(typeof client?.invoices != 'object') {return <Loader />}

    const onCheckButton = (e) => {
        const selectedInvoice = client?.invoices.find(invoice => invoice.number === e.target.value);
        setSelected(selectedInvoice?.number);
        onSelect(selectedInvoice?.number);
    }

    const deselectInvoice = () => {
        setSelected("");
        onSelect("");
    }

    const items = client?.invoices.slice(0,numberToDisplay)
          .sort((a,b) =>  new Date(b.date.date) - new Date(a.date.date)).map((invoice, idx) => {
            const label  = <>
                <a href={invoice.publicUrl} target="_blank">
                    Facture n° {invoice.number} du&nbsp;
                    {HelperMetiers360.getdisplayDateType(invoice.date.date, 'day')}
                </a> | {invoice.amount > 0 ? invoice.amount:<span className='text-danger'>{invoice.amount}</span>} {invoice.currency}&nbsp;|&nbsp;
                {invoice.paidAt ? 
                    <>payée le {HelperMetiers360.getdisplayDateType(invoice.paidAt.date, 'day')}</> 
                    : <>Non payée</>}</>;
            if(typeof(onSelect) != 'function') {
                return (
                    <ListGroupItem key={'listFact'+invoice.number}>
                        {label}
                    </ListGroupItem>
                );
            } else {
                return <Form.Check
                    type='radio'
                    key={'radioChck'+invoice?.number}
                    label={label}
                    value={invoice?.number}
                    id={invoice?.number}
                    checked={selected ? selected == invoice.number:false}
                    onChange={onCheckButton} />;
            }
        });

    const displayInvoices = onSelect ?
        <>{selected &&  <Button variant="warning btn-sm p-1 w-50 mt-2 mb-2" onClick={()=>deselectInvoice()}>Désélectionner la facture</Button>}
        <ListGroup>{items}</ListGroup></>
        :<>{items}</>;


    return (<div style={{width: "max-content"}}>{client.invoices.length == 0 ?
        "Pas de Factures"
        :displayInvoices}
        {(numberToDisplay < client?.invoices.length) && <Button variant="light" className="fw-bold mt-2 mb-4 w-100 p-0 border border-primary" onClick={()=>setNumberToDisplay((prev)=> prev + 3)}>...</Button>}
        </div>)
}

export default ClientInvoicesComponent