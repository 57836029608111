import { useEffect, useState, useRef } from 'react';
import { Container, Row } from 'react-bootstrap';

import { useStoreState, useStoreActions } from 'easy-peasy';

import Header from '../../layout/Header/index.jsx';
import SideMenu from "../../layout/SideMenu/index.jsx";
import Main from "../../layout/Main/index.jsx";
import useStories from "../../hooks/useStories";
import WelcomeBannerComponent from '../../components/welcomeMessage/WelcomeBannerComponent';


import useFreshworkWidget from '../../hooks/useFreshworksWidget.jsx';

import './LayoutPage.scss';

const LayoutPage = () => {
	const { username, userClientName } = useStoreState(state => state.auth);
	const { apiData } = useStoreState(state => state.actionSlugs);
	const { fetchActiveMessage } = useStoreActions(actions => actions.welcomeMessage);

	const { init: initFreshworkWidget, WindowFreshworksWidget } = useFreshworkWidget();

	const [banner, setBanner] = useState();

	const [bannerHeight, setBannerHeight] = useState(0);
	const bannerRef = useRef(null);

	useEffect(() => {
		if (bannerRef.current) {
			setBannerHeight(bannerRef.current.clientHeight);
		} else {
			setBannerHeight(null);
		}
	}, [banner]);

	useEffect(() => {
		initFreshworkWidget();
		const FreshworksWidget = WindowFreshworksWidget();
		FreshworksWidget('identify', 'ticketForm', {
			email: username,
			customFields: { cf_tablissement: userClientName }
		});
	}, []);

	useEffect(() => {
		if (apiData) fetchActiveMessage().then((msg) => setBanner(msg.data));
	}, [apiData]);

	useStories({ numberOfStoriesToFetch: 100 });

	return (
		<div className='app'>
			{banner && <div ref={bannerRef}>< WelcomeBannerComponent message={banner} setBanner={setBanner} /></div>}
			<Container fluid>
				<Row>
					<SideMenu /*pages={pages}*/ dynamicStyle={{ paddingTop: bannerHeight ?? 20 }} />
					<Header />
					<Main /*pages={pages}*/ />
				</Row>
			</Container>
		</div>
	);
}

export default LayoutPage;