import React, {useState, useEffect} from "react";
import { Container, Row, Col, Button, Alert,  } from "react-bootstrap";

import { useStoreState } from 'easy-peasy';

import TagTableComponent from "../../components/tags/TagTableComponent";

import { Link } from "react-router-dom";

import Loader from "../../components/widgets/Loader.jsx";
import useDebounceSearch from "../../hooks/useDebounchSearch";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import HelperMetiers360 from "../../services/HelpersMetiers360";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

const TagTableComponentWithParams = withURLParamsTableComponent(TagTableComponent);

const TagsPage = (props) => {
    const { allTags,isFetchingAllTags } = useCustomGetStoreState("tags");

    const { readAll } = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('tags'));

    const tagSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("tags"));

    const { searchInput, search } = useDebounceSearch({ placeholder: 'Rechercher par nom', delay: 400 })

    const [filteredTags, setFilteredTags] = useState(allTags);

    useEffect(() => {
        setFilteredTags(allTags);
    }, [allTags])

    useEffect(() => {
        const filterTags = (search) => {
            return allTags.filter(tag => HelperMetiers360.isSearchInText(search, tag.name))
        }

        setFilteredTags([...filterTags(search)]);
    }, [search, allTags])

    const createTagsButton = tagSlugs.create
    ? <Button variant="success" as={Link} to={tagSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
    : null;

    const isNoTags = !isFetchingAllTags && !allTags.length
    const isNoResult = !isFetchingAllTags && allTags.length && !filteredTags.length;

    return <>
        <Container fluid>
            <Row>
                <h2>Tags</h2>
            </Row>
            <Row className="item-page">
                <Col>
                    {!isFetchingAllTags && searchInput}
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    {createTagsButton}
                </Col>
            </Row>

            { isFetchingAllTags && <Loader /> }
            { filteredTags.length > 0 && <TagTableComponentWithParams tags = {filteredTags} readAll={readAll}/> }
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucun tag ne correspond à votre recherche</Alert>}
            {isNoTags && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun tag</Alert>}
        </Container>
    </>;
}
export default TagsPage;