import React, { useEffect, useState } from "react";
import { Col, Form, Row } from 'react-bootstrap';
import { useStoreActions, useStoreState} from 'easy-peasy';
import SubscriptionFormComponent from '../../subscription/uploadForm/SubscriptionFormComponent.jsx';
import './ClientFormComponent.scss';
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar.jsx";
import PropTypes from 'prop-types';

const ClientFormComponent = ({client, action}) => {
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
	const {fetchClientsCategories, fetchContactsM360 } = useStoreActions(actions => actions.clients);
    const {allClientStatus, isFetchingAllClientStatus} = useStoreState(state => state.clientStatus);
    const {fetchClientStatus} = useStoreActions(actions => actions.clientStatus);
	const {fetchTagOperations} = useStoreActions(actions => actions.tagOperations);
	const {contactsM360, clientsCategories} = useStoreState(state => state.clients);
	const {allTagOperations} = useStoreState(state => state.tagOperations);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [selectedTagOpeIds, setSelectedTagOpeIds] = useState([]);
	const [selectedContactM360Ids, setSelectedContactM360Ids] = useState([]);

    const {csrfToken, formName} = ACTIONS['clients'][action];

	useEffect(() => {
		allClientStatus?.length === 0 && fetchClientStatus();
    }, [allClientStatus]);

	useEffect(() => {
        allTagOperations?.length === 0 && fetchTagOperations();

		if(allTagOperations?.length > 0 && client?.tagOperationsName?.length > 0) {
			setSelectedTagOpeIds(client.tagOperationsName
				?.map(tagName => allTagOperations?.find(tagOpe => 
					tagOpe.name === tagName).id)
			);
		}
	}, [allTagOperations]);

	useEffect(() => {
		clientsCategories?.length === 0 && fetchClientsCategories();

		if(clientsCategories?.length > 0 && client && client.categories.length > 0) {
			setSelectedCategoryIds(client.categories
				?.map(categoryName => clientsCategories?.find(category => 
					category.name === categoryName).id)
			);
		}
	}, [clientsCategories]);

	useEffect(() => {
        contactsM360?.length === 0 && fetchContactsM360();

		if(contactsM360?.length > 0 && client && client.contactsM360.length > 0) {
			setSelectedContactM360Ids(client.contactsM360
				?.filter(contact => contact.relationValue == "sav")
				?.map(contactSelected => contactsM360?.find(contact => 
					contact.name === contactSelected.name).id)
			);
		}
	}, [contactsM360]);
	
	const statusRow = !isFetchingAllClientStatus &&
		<Form.Control
			as="select" size="sm" aria-label="Choix statut" 
			name='clientStatus'
			defaultValue={client?.status?.id ? client?.status.id:''}
			>
			<option value="" disabled>Choisissez un statut</option>
			{allClientStatus?.map((status) => (
				<option 
					key={'row_status'+'_'+status.id}
					value={status.id}
				>
					{status.label}
				</option>
				))}
		</Form.Control>;

	return (
		<div >
			<Form method="post" encType="multipart/form-data" id="client-form" className={formName}>
				<Row>
					<Form.Group as={Col}  >
						<Form.Label>Nom du client</Form.Label>
						<Form.Control
							readOnly = {!!client?.isActiveAxonaut}
							name={`name`} 
							defaultValue={client ? client?.name : ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer un nom</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
				<hr/>

               <Form.Group >
                <h4>Adresse de facturation</h4>

                <Row className="mt-4">
					<Form.Group as={Col} >
						<Form.Label>Voie</Form.Label>
						<Form.Control
							readOnly = {!!client?.isActiveAxonaut}
						    as="textarea"
							rows={5}
							disabled = {!!client?.isActiveAxonaut}
							name={`addressStreet`}
							defaultValue={client?.addressStreet ? client.addressStreet: ''}
						/>
					</Form.Group>
				</Row>
               
                <Row>
                   <Form.Group as={Col}  >
                       <Form.Label>Code postal</Form.Label>
                       <Form.Control
					   	readOnly = {!!client?.isActiveAxonaut}
                        name={`addressZipCode`}
                        defaultValue={client?.addressZipCode ? client?.addressZipCode :''}
                        />
                       </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col}  >
                    <Form.Label>Ville</Form.Label>
                    <Form.Control
					 readOnly = {!!client?.isActiveAxonaut}
                     name={`addressCity`}
                     defaultValue={client?.addressCity ? client?.addressCity : ""}
                    />
                   </Form.Group>
                </Row>

				<Row>
                    <Form.Group as={Col}  >
                    <Form.Label>Région</Form.Label>
                    <Form.Control
					 readOnly = {!!client?.isActiveAxonaut}
                     name={`addressRegion`}
                     defaultValue={client?.addressRegion ? client?.addressRegion : ""}
                    />
                   </Form.Group>
                </Row>

				<Row>
                    <Form.Group as={Col}  >
                    <Form.Label>Pays</Form.Label>
                    <Form.Control
					 readOnly = {!!client?.isActiveAxonaut}
                     name={`addressCountry`}
                     defaultValue={client?.addressCountry ? client?.addressCountry : ""}
                    />
                   </Form.Group>
                </Row>

                </Form.Group>

                <hr />
                <Row>
					{clientsCategories?.length > 0 &&
						<Col xs={allTagOperations?.length > 0 ? 6 : 12}>
							<SelectMultipleItemsWithSearchBar disabled={!!client?.isActiveAxonaut} idSelector="clientCRM-Category-Select" label="Catégories"
								allItems={clientsCategories} 
								selectedItemKeys={selectedCategoryIds} setSelectedItemKeys={setSelectedCategoryIds} 
								itemKey="id" itemValue="name" />
							<Form.Control.Feedback type='invalid' id="invalid-feedback-categories">Veuillez donner une catégorie au client</Form.Control.Feedback>
						</Col>
                    }
					{allTagOperations?.length > 0 &&
						<Col xs={clientsCategories?.length > 0 ? 6 : 12}>
							<SelectMultipleItemsWithSearchBar idSelector="clientCRM-Tags-Select" label="Opé M360"
								allItems={allTagOperations} 
								selectedItemKeys={selectedTagOpeIds} setSelectedItemKeys={setSelectedTagOpeIds} 
								itemKey="id" itemValue="name" />
						</Col>
					}
		        </Row>
                { allClientStatus &&
					<>
					<hr />
					<Row>
						<Col>
							<p className='form-title'>Statut</p>
							{statusRow}
							<Form.Control.Feedback type='invalid'>Veuillez donner un statut au client</Form.Control.Feedback>
						</Col>
						<Col>
							<SelectMultipleItemsWithSearchBar idSelector="clientCRM-ContactsM360-Select" label="Chargé⋅es de clientèle"
								allItems={contactsM360} 
								selectedItemKeys={selectedContactM360Ids} setSelectedItemKeys={setSelectedContactM360Ids} 
								itemKey="id" itemValue="name" />
						</Col>
					</Row>
					</>
               	}

				{ action === "create" &&
					<>
						<hr />
						<Row>
							<Row className="mb-4">
								<h4>Abonnement</h4>
							</Row>
							<SubscriptionFormComponent action='create' /> 
						</Row>
					</>
                }

				<Form.Group >
					<Form.Control
						name={`clientCsrfToken`}
						value={csrfToken}
						type="hidden"
					/>
				</Form.Group>
			</Form>
		</div>
	);
}

export const submitClientForm = ({action, client, updateClientCRM, createClientCRM}) => {
	
	const listSelectedValues = (name) => {
		const inputs = document.getElementsByName(name);
		return Array.from(inputs).map(input => input.value);
	}

	const resetErrorDisplay = () => {
		document.getElementsByName("name")[0]?.classList.remove("is-invalid");
		document.getElementById("invalid-feedback-categories")?.classList.remove("d-flex");
		document.getElementById("invalid-feedback-categories")?.classList.add("d-none");
		document.getElementsByName("clientStatus")[0]?.classList.remove("is-invalid");
		document.getElementsByName("subscriptionEndDate")[0]?.classList.remove("is-invalid");
		document.getElementsByName("headsetNumberPredicted")[0]?.classList.remove("is-invalid");
	}

	const submitForm = (data) => {
		if(action == 'update' && updateClientCRM){
			return updateClientCRM({data: data, uniqueId: client.uniqueId})
				.catch(() => {
					return Promise.reject(Error('Il y a eu un probleme lors de l\'enregistrement du client'));
				})
		} else if (action == 'create' && createClientCRM){
			return createClientCRM(data)
				.catch(() => {
					return Promise.reject(Error('Il y a eu un probleme lors de l\'enregistrement du client'));
				})
		} else {
			return Promise.reject(Error("Désolé, une erreur est survenue"));
		}
	}

    let error = false;
	let formDataClient = new FormData(document.getElementById('client-form'));
	let formDataSubscription;
	
	const name = formDataClient.get(`name`);
	const clientStatus = formDataClient.get(`clientStatus`);

	const listSelectedCategories = listSelectedValues('clientCRM-Category-Select');
	
	resetErrorDisplay();

	// Check this data only if not readonly so only if not Axonaut
	if(!client?.isActiveAxonaut) {
		if (!name) {
            document.getElementsByName("name")[0]?.classList.add("is-invalid");
			error = true;
		}

		if(listSelectedCategories.length < 1){
			document.getElementById("invalid-feedback-categories")?.classList.remove("d-none");
			document.getElementById("invalid-feedback-categories")?.classList.add("d-flex");
			error = true;
		}
	}

	if (!clientStatus || clientStatus == "") {
		document.getElementsByName("clientStatus")[0]?.classList.add("is-invalid");
		error = true;
	}

	if(action == "create"){
		formDataSubscription = new FormData(document.getElementById('subscription-form'));

		let subscriptionEndDate = formDataSubscription.get(`subscriptionEndDate`);
		let headsetNumberPredicted= formDataSubscription.get(`headsetNumberPredicted`);

		if (!subscriptionEndDate) {
            document.getElementsByName("subscriptionEndDate")[0]?.classList.add("is-invalid");
			error = true;
		}

		if (!headsetNumberPredicted) {
            document.getElementsByName("headsetNumberPredicted")[0]?.classList.add("is-invalid");
			error = true;
		}
	}

	if(error) {
		return Promise.reject(new Error());
	} else {
		let newClient = {};
		let data = {};
		formDataClient.forEach(function(value, key){
			newClient[key] = value;
		});
		
		newClient['categories'] = listSelectedValues('clientCRM-Category-Select');
		newClient['contactsM360'] = listSelectedValues('clientCRM-ContactsM360-Select');
		newClient['tagOperations'] = listSelectedValues('clientCRM-Tags-Select');

		if(action === "update"){
			data = newClient;
		}

		else{
			data["client"] = newClient;
			data["subscription"] = {
				subscriptionName: formDataSubscription.get(`subscriptionName`),
				headsetNumberPredicted: formDataSubscription.get(`headsetNumberPredicted`),
				subscriptionEndDate: formDataSubscription.get(`subscriptionEndDate`),
				subscriptionCsrfToken: formDataSubscription.get(`subscriptionCsrfToken`)
			};
		}

		return submitForm(data);
	}
}

ClientFormComponent.propTypes = {
    client: PropTypes.object,
    action: PropTypes.any
};

export default ClientFormComponent;