import React from "react";
import { Container, Row, Alert } from "react-bootstrap";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";

import "./GroupsPage.scss";
import GroupsDisplayComponent from "../../components/groups/GroupsDisplayComponent";

const GroupsPage = (props) => {

    const { allGroups } = useCustomGetStoreState("groups");
    const {allVideos} = useCustomGetStoreState("videos");
    const {allQuizzes} = useCustomGetStoreState("quizzes");

    return <Container fluid>
        <Row className="d-flex justify-content-start mb-3 px-2">
            <Alert variant='info' className="mb-0">
                Les groupes permettent de regrouper les expériences immersives et les quiz selon les règles suivantes :
                <ul>
                    <li>Un groupe peut contenir des expériences immersives, des quiz ou des groupes.</li>
                    <li>Tous les contenus qui ne sont pas explicitement dans un groupe se retrouvent dans le menu principal.</li>
                    <li>Pour qu'un contenu soit à la fois dans un groupe et dans le menu principal, il faut donc le placer explicitement dans le menu principal.</li>
                </ul>
            </Alert>
        </Row>
        <GroupsDisplayComponent groups={[...allGroups]} allVideos={[...allVideos]} allQuizzes={[...allQuizzes]} />
    </Container>
}

export default GroupsPage;