import React, { useState, useEffect} from "react";
import { useStoreState } from "easy-peasy";
import { Row, Form } from "react-bootstrap";

import UpdateOrderComponent from "../../components/orders/preparatoryOrders/Update/UpdateOrderComponent";
import UpdateMultipleOrdersComponent from "../../components/orders/preparatoryOrders/Update/UpdateMultipleOrdersComponent";
import useBackButton from "../../hooks/useBackButton";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";

const CreateOrders = ({ orderType }) => {
    const orderSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher(orderType === 'preparatoryOrders' 
        ? 'orders'
        : orderType));
    const [creatingOrdersQuantity, setCreatingOrdersQuantity] = useState("");

    const {allClients} = useCustomGetStoreState('clients');
    const {allOrders, allReturnOrders} = useCustomGetStoreState('orders');

    const allOrdersToManage = orderType === 'preparatoryOrders' ? allOrders : allReturnOrders;

    const [includeClientsWithActiveOrder, setIncludeClientsWithActiveOrder] = useState(false);
    const [clientsBase, setClientsBase] = useState(allClients);
    const [activeOrders, setActiveOrders] = useState(allOrdersToManage);

    const [clientSelectionDone, setClientSelectionDone] = useState(false);

    useEffect(() => {
        setActiveOrders([
            ...allOrdersToManage.filter((order) =>  ['draft', 'validated', 'inPreparation']
                    .includes(order?.statusValue || order?.returnOrderStatus?.value))
        ]);
    },[allOrdersToManage]);

    useEffect(() => {
        if (!includeClientsWithActiveOrder) {
            setClientsBase([
                ...allClients.filter(
                    client => activeOrders?.find(order => order.clientUniqueId === client.uniqueId ) === undefined
                        )
            ]);
        } else {
            setClientsBase(allClients);
        }
    },[includeClientsWithActiveOrder, allClients, activeOrders]);

    const { backButtonComponent } = useBackButton();

    const handleCreatingQuantity = (quantity)=>{
        setCreatingOrdersQuantity(quantity);
    }

    const orderName = orderType === 'preparatoryOrders' ?  'préparation' : 'retour';

    return (
        <div className="container p-2">
            <Row className="mb-3">
                <h2 className="d-flex justify-content-between align-items-center">
                    {(creatingOrdersQuantity !== "multiple"
                        ? "Création d'un bon de "
                        : "Création de bons de ") + orderName}
                    {backButtonComponent}
                </h2>
            </Row>
            {!clientSelectionDone 
                && <Row>
                    <Form.Check
                        type="checkbox"
                        onChange={()=> {setIncludeClientsWithActiveOrder((prev)=>!prev)}}
                        defaultChecked={includeClientsWithActiveOrder}
                        id="checkIncludeClients"
                        label = {"Inclure les clients ayant déjà un bon de "+ orderName +" en cours"} />
                </Row>}
            {creatingOrdersQuantity !== "multiple"
                && <Row className="mb-5">
                    <UpdateOrderComponent
                        handleCreatingQuantity={handleCreatingQuantity}
                        clientsBase={clientsBase}
                        action="create"
                        setClientSelectionDone={setClientSelectionDone}
                        orderType={orderType}
                    />
                </Row>}
            {orderType === 'preparatoryOrders' && creatingOrdersQuantity !== "one"
                && <Row className="mt-5">
                    <UpdateMultipleOrdersComponent
                    handleCreatingQuantity={handleCreatingQuantity}
                    clientsBase={clientsBase}
                        action="create"
                        setClientSelectionDone={setClientSelectionDone}
                    />
                </Row>}
        </div>
    );

}

export default CreateOrders;