import React from "react";
import { Container, Row, Alert, Button } from "react-bootstrap";

import InteractionsFiltersComponent from "../../components/interactions/interactionsFilters/InteractionsFiltersComponent";
import InteractionsTableComponent from "../../components/interactions/InteractionsTableComponent";
import { useStoreActions, useStoreState } from "easy-peasy";
import Loader from "../../components/widgets/Loader";
import { useModal } from "../../hooks/useModal";
import CreateMultipleInteractionsComponent from "../../components/interactions/CreateMultipleInteractionsComponent";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent";

const InteractionsTableComponentWithParams = withURLParamsTableComponent(InteractionsTableComponent);

const InteractionsPage = () => {
    const { fetchInteractions } = useStoreActions(actions => actions.interactions);
    const { interactionsFiltered, isFetchingInteractionsFiltered } = useStoreState(state => state.interactions);
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const tableInteraction = interactionsFiltered?.length > 0 
        ? <InteractionsTableComponentWithParams interactions={interactionsFiltered} displayClientColumns={true} />
        : <Alert variant="warning" className="mt-4">Aucune interaction</Alert>;

    const createMultiInteractionsModal = () => {
        setModalData({
            ...modalData,
            header: <>Ajout de plusieurs interactions</>,
            content: <CreateMultipleInteractionsComponent />,
            size: 'lg',
            id: 'client-create-multi-user-modal'
        });
        setModalShow(true);
    }

    return <Container fluid>
        {modalComponent}
        <Row>
            <h2 className="d-flex justify-content-between">
                Suivi clients
                <Button
                    variant="success"
                    className="tab-info-card-btn w-auto"
                    onClick={createMultiInteractionsModal}>
                    <i className="fas fa-plus"></i>&nbsp;Ajout multiple
                </Button>
            </h2>

        </Row>
        <Row>
            <Alert variant='info'>
                Cette page n'affiche pas plus de 250 interactions à la fois, il est donc conseillé de filtrer avec un maximum 
                d'informations pour afficher un résultat répondant à vos attentes.<br/>
                <u><b>Attention :</b></u> Les filtres ne s'appliquent pas automatiquement, il faut appuyer sur le bouton <b>"Lancer la recherche"</b>.
            </Alert>
        </Row>
        <Row>
            <InteractionsFiltersComponent fetchInteractions={fetchInteractions} />
        </Row>
        <Row>
            { isFetchingInteractionsFiltered
                ? <Loader />
                : tableInteraction
            }
        </Row>
    </Container>;
}

export default InteractionsPage;
