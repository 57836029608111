import React from "react";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";

import UploadPedagogicModuleComponent from "../../components/pedagogicModules/uploadForm/UploadPedagogicModuleComponent";
import useBackButton from "../../hooks/useBackButton";

const UploadPedagogicModulePage = (props) => {
	const {postPedagogicModule, updatePedagogicModule} =  useStoreActions(actions => actions.pedagogicModules);
    const pedagogicModuleSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("pedagogicModules"));

    const { backButtonComponent } = useBackButton()

	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'un module pédagogique</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <UploadPedagogicModuleComponent 
					postPedagogicModule={postPedagogicModule} 
					updatePedagogicModule={updatePedagogicModule} 
					action="create"
					{...pedagogicModuleSlugs}
				/>
            }
		</div>
	);
};

export default UploadPedagogicModulePage;