import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import useBackButton from "../../hooks/useBackButton.jsx";
import { useStoreActions, useStoreState } from 'easy-peasy';
import ProfessionalsPageContent from "./ProfessionalsPageContent.jsx";

const ProfessionalsPage = () => {

    const { allProfessionals, isFetchingAllProfessionals } = useStoreState(state => state.romes);
    const { fetchAllProfessionals } = useStoreActions(actions => actions.romes);
    const { backButtonComponent } = useBackButton();

    useEffect(() => {
        if (!allProfessionals?.length && !isFetchingAllProfessionals) {
            fetchAllProfessionals();
        }
    }, [allProfessionals, isFetchingAllProfessionals])

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    Professionnel⋅les
                    {backButtonComponent}
                </h2>
            </Row>
            <ProfessionalsPageContent professionals={allProfessionals} isFetching={isFetchingAllProfessionals} />
        </Container>
    )
}

export default ProfessionalsPage;