import { useStoreState } from 'easy-peasy';
import React, {useState, useEffect} from 'react';
import {  Link } from "react-router-dom";
import { Alert, Button } from 'react-bootstrap';

import useInfiniteLoadingLogic from '../../../hooks/useInfiniteLogic';

import HelperMetiers360 from '../../../services/HelpersMetiers360';
import DynamicTableInfinite from '../../widgets/dynamicTableInfinite/DynamicTableInfinite';
import AuthorizationChecker from '../../../services/AuthorizationChecker';

const HeadsetEvents = (props) => {
    const {events = []} = props;
    const videoActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('videos'));
    const oneVideoSlug = videoActionSlugs && videoActionSlugs['readOne'] ? videoActionSlugs['readOne']:null;
    const quizActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('quizzes'));
    const oneQuizSlug = quizActionSlugs && quizActionSlugs['readOne'] ? quizActionSlugs['readOne']:null;
    const groupActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('groups'));
    const oneGroupSlug = groupActionSlugs && groupActionSlugs['readOne'] ? groupActionSlugs['readOne']:null;
    const clientActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const oneClientSlug = clientActionSlugs && clientActionSlugs['readOne'] ? clientActionSlugs['readOne']:null;
	const {pages} = useStoreState(state=> state.actionSlugs);

    const [sortedEvents, setSortedEvents] = useState([]);
    const [eventsToDisplay, setEventsToDisplay] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    useEffect(() => {
        setShouldSort(true)
        setSortedEvents([...events]);
    
    }, [events]);

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedEvents, eventsToDisplay, setEventsToDisplay, 50);

    const eventType = { "play": "fas fa-play", "download": "fas fa-download", "answerQuiz": "fas fa-question", "enterGroup": "far fa-folder" };

    if(events.length === 0) {
        return <Alert variant="info" className='w-100'>Selon les informations que nous avons, il n'y a pas eu d'événement sur ce casque.<br />
        Avez-vous mis à jour l'application du casque à 
        <Button as="a" bsPrefix={'no-btn'} target="_blank" href={pages.find(page => page.labelNav == "Mise à jour")?.url}>
        l'aide du Loader</Button> ?</Alert>;
    }

    const contentSort = [
        {
            value : 'date', label : 'Date',
            test: HelperMetiers360.isArrayContainsValue(events, "time"),
            method: (a, b) => new Date(b.time.date) - new Date(a.time.date),
            display: (event) => event.time.date ? HelperMetiers360.getdisplayDateType(event.time.date):'-'
        },
        {
            value : 'type', label : 'Type',
            test: HelperMetiers360.isArrayContainsValue(events, "name"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (event) => <i className={eventType[event.name]}></i>,
            flatDisplay : (event) => event.name
        },
        {
            value : 'group', label : 'Espace',
            test: HelperMetiers360.isArrayContainsValue(events, "groupName"),
            method: (a,b) =>  HelperMetiers360.sortTextAfterTestExistence(a,b,'groupName'),
            display: (event) => {
                if(!event.groupName) return '-';
                return event.groupUniqueId && oneGroupSlug ?
                    <Link to={oneGroupSlug.replace(':uniqueId', event.groupUniqueId)}>{event.groupName}</Link>
                    : <>{event.groupName}</>;
            },
            flatDisplay: (event) => event?.groupName ? event.groupName:'-'
        },
        {
            value : 'video', label : 'Video',
            test: HelperMetiers360.isArrayContainsValue(events, "videoName"),
            method: (a,b) =>  HelperMetiers360.sortTextAfterTestExistence(a,b,'videoName'),
            display: (event) => {
                if(!event.videoName) return '-';
                const playbackDuration = event.playbackDuration ? <>({Math.trunc(event.playbackDuration/60) }min{event.playbackDuration % 60 }s)</>:null;
                return event.videoUniqueId && oneVideoSlug ?
                    <><Link to={oneVideoSlug.replace(':uniqueId', event.videoUniqueId)}>{event.videoName}</Link> {playbackDuration}</>
                    : <>{event.videoName} {playbackDuration}</>;
            },
            flatDisplay: (event) => event?.videoName ? event.videoName:'-'
        },
        {
            value: 'fromgroup', label: 'Depuis le groupe',
            test: HelperMetiers360.isArrayContainsValue(events, "fromGroup"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.fromGroup?.name, b.fromGroup?.name),
            display: (event) => {
                if (!event.fromGroup?.name) return '-';
                return event.fromGroup?.uniqueId && oneGroupSlug ?
                    <Link to={oneGroupSlug.replace(':uniqueId', event.fromGroup.uniqueId)}>{event.fromGroup.name}</Link>
                    : <>{event.fromGroup.name}</>;
            },
            flatDisplay: (event) => event?.fromGroup?.name ?? '-'
        },
        {
            value: 'accessTypeLabel', label: 'Accès',
            test: HelperMetiers360.isArrayContainsValue(events, "accessType"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.accessType?.label, b.accessType?.label),
            display: (event) => event.accessType?.label ?? '-'
        },
        // ! quiz events removed because quiz events are not stored on backend as that kind of event
        // {
        //     value : 'quiz', label : 'Quiz',
        //     test : true,
        //     method: (a,b) =>  HelperMetiers360.sortTextAfterTestExistence(a,b,'quizName'),
        //     display: (event) => {
        //         if(!event.quizName) return '-';
        //         return event.quizUniqueId && oneQuizSlug ?
        //             <Link to={oneQuizSlug.replace(':uniqueId', event.quizUniqueId)}>{event.quizName}</Link>
        //             : <>{event.quizName}</>;
        //     },
        //     flatDisplay: (event) => event?.quizName ? event.quizName:'-'
        // },
        {
            value : 'client', label : 'Client',
            test: HelperMetiers360.isArrayContainsValue(events, "clientUniqueId"),
            method: (a,b) =>  HelperMetiers360.sortTextAfterTestExistence(a,b,'clientName'),
            display: (event) => {
                if(!event.clientName) return '-';
                return event.clientUniqueId && oneClientSlug ?
                    <Link to={oneClientSlug.replace(':uniqueId', event.clientUniqueId)}>{event.clientName}</Link>
                    : <>{event.clientName}</>;
            },
            flatDisplay: (event) => event?.clientName ? event.clientName:'-'
        },
    ]

    return <DynamicTableInfinite
        contentTable = {eventsToDisplay}
        contentSort = {contentSort}
        valueInitSort = "date"
        index = 'uniqueId'
        fetchData = {fetchData}
        hasMore = {hasMore}
        setSortedContent={setSortedEvents}
        sortedContent={sortedEvents}
        sortState = {[shouldSort, setShouldSort]}
        downloadable={AuthorizationChecker.isAdmin()}
        isManuallyTriggered={true}
        filename="casque_journal_navigation"
        />;
};

export default HeadsetEvents;