import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import PasswordFormGroup from './PasswordFormGroup.jsx';

import ButtonLoaderComponent from '../widgets/ButtonLoaderComponent.jsx';
import { useStoreActions } from 'easy-peasy';
import { useToast } from '../../hooks/useToast.jsx';

const PassWordComponent = () => {
    const {changePassword} = useStoreActions(actions => actions.auth);

    const [isSending, setIsSending] = useState(false);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [isNewPassValid, setIsNewPassValid] = useState(false);

    const toast = useToast();

    const myPassword =
        <Form.Group>
            <Form.Label> Mot de passe actuel</Form.Label>
            <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Mot de passe actuel"
            />
        </Form.Group>;

    const passWordGroup = <PasswordFormGroup 
        onValid={(validation, validPass) => {
            setIsNewPassValid(validation);
            setNewPassword(validPass)
        }}
    />;

    const ModalPassword = <>
                <Form noValidate className="mb-3">
                    {myPassword}
                    {passWordGroup}
                </Form>
               
                <ButtonLoaderComponent
                    variant='success'
                    disabled={password === "" || !isNewPassValid}
                    isSending={isSending}
                    msg='Changer le mot de passe'
                    onClick={() => { onValidatePWD() }}
                />
     
    </>

    const onValidatePWD = () => {
        if (isNewPassValid) {
            const data = new FormData();
            
            data.append('pwd', newPassword);
            data.append('lastPassword', password);
            
            setIsSending(true);
            changePassword(data)
                .then((res) => {
                    if(res) toast.open({ message: "Le mot de passe a bien été modifié", variant: "success" })
                })
                .finally(() => {
                    setIsSending(false);
                })

        }
    };

    return ModalPassword;
}

export default PassWordComponent;