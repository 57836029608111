import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import HelperMetiers360 from '../../services/HelpersMetiers360';

const PasswordFormGroup = ({ onValid, userExist }) => {

    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    
    useEffect(() => {
        if (password!=='' && checkPassword !== '' 
            && HelperMetiers360.pwdRegExp.test(password) 
            && checkPassword === password) {
            onValid(true, password);
        }
        else { onValid(false, ''); }
    }, [password, checkPassword]);

    const disabled = !HelperMetiers360.pwdRegExp.test(password);

    const formGroup = <>
        <Form.Group controlId="PWD1">
            <Form.Label> {userExist ?  'Nouveau mot de passe': 'Mot de passe'} </Form.Label>
            <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Nouveau mot de passe"
                isValid={password != '' && HelperMetiers360.pwdRegExp.test(password)}
                isInvalid={!HelperMetiers360.pwdRegExp.test(password)} />
            <Form.Control.Feedback type="valid">Mot de passe valable</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">8 caractères minimum dont 1 lettre majuscule, 1 lettre minuscule et 1 chiffre.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="PWD2">
            <Form.Label>Répéter le mot de passe </Form.Label>
            <Form.Control
                onChange={(e) => setCheckPassword(e.target.value)}
                value={checkPassword}
                type="password"
                placeholder="Répéter le mot de passe"
                isValid={checkPassword != '' && HelperMetiers360.pwdRegExp.test(password) && checkPassword === password}
                isInvalid={!(checkPassword != '' && HelperMetiers360.pwdRegExp.test(password) && checkPassword === password)}
                disabled={disabled} />
        </Form.Group>
    </>;


    return formGroup;
}

export default PasswordFormGroup;