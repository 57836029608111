import React, {useRef, useEffect} from 'react';
import {Row, Col, Container, Button, Alert, Form, Tooltip, OverlayTrigger, Spinner} from 'react-bootstrap';
import { useStoreActions, useStoreState} from 'easy-peasy';
import { useNavigate} from "react-router-dom";
import ParenthoodFormComponent, { submitParenhoodForm } from '../ParenthoodEdition/ParenthoodFormComponent.jsx';
import EditClientVRBkgdImageComponent from '../backgroundImgEdition/EditClientVRBkgdImageComponent.jsx';
import ClientActivityComponent from '../clientActivity/ClientActivityComponent.jsx';
import VideoPlayGraph from '../../../activities/VideoPlayGraph.jsx';
import CreateMultiClientComponent from '../../../../components/clients/CreateMultiClientComponent.jsx';
import { useToast } from '../../../../hooks/useToast.jsx';


const ClientInfoTabComponent = ({ clientById, setModalData, modalData, setModalShow, setIsSending, parentListComponent, childrenListComponent }) => {
    const { updateParenthood, deleteClient, fetchIsClientByIdDeletable } = useStoreActions(actions => actions.clients);
    const { isFetchingIsClientByIdDeletable, isClientByIdDeletable } = useStoreState(state => state.clients);
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const toast = useToast();
    const confirmDeleteRef = useRef(null);
    const navigate = useNavigate();
    const goToClients = () => {
        navigate(clientsSlugs.readAll)
    }

    const createParenthoodModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            submitParenhoodForm(updateParenthood, clientById.uniqueId)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }
    
        setModalData({
            ...modalData,
            header: 'Gestion de la parentalité',
            content: <ParenthoodFormComponent client={clientById}/>,
            cancelButton: "Annuler",
            onValidate: handleSubmit,
            size: 'xl',
        });
        setModalShow(true);
    }


    const createMultiUserModal = () => {
        setModalData({
            ...modalData,
            header: <>Ajout de plusieurs clients enfants</>,
            content: <CreateMultiClientComponent clientsParent={clientById} />,
            size: 'xl'
        });
        setModalShow(true);
    }

    const deleteClientModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression de client</>,
            content: <>
                <Alert variant="danger">
                    <h5>Êtes-vous sûr⋅e de vouloir supprimer le client&nbsp;
                        {clientById.clientName ?? ''}&nbsp;?
                    </h5>
                </Alert>
                <label htmlFor="confirm-control">Écrivez <b>SUPPRIMER</b> pour valider la suppression</label>
                <Form.Control type='text' id="confirm-control" ref={confirmDeleteRef} />
            </>,
            resetButton: 'Supprimer',
            cancelButton: 'Annuler',
            onReset: () => {
                if(confirmDeleteRef?.current?.value !== "SUPPRIMER") {
                    toast.open({ 
                        message: "Le message de confirmation ne correspond pas au message attendu : SUPPRIMER",
                        variant: "danger" });
                } else {
                    setIsSending(true);
                    deleteClient(clientById.uniqueId).then(()=>{
                        toast.success();
                        setModalShow(false);
                        goToClients();
                    })
                    .finally(() => setIsSending(false));
                }
            },
        });
        setModalShow(true);
    }


    const spinner = (titre) => <div className="disabledTitle">
    <p>{titre}</p>
    <Spinner
        as="span"
        animation="border"
        size="sm"
        aria-hidden="true"
        className='tabspin' />
    </div>;




    useEffect(() => {
        clientById && fetchIsClientByIdDeletable(clientById.uniqueId);
    }, [clientById])


    return (
        <Container>
            <Row className="tab-info-container">
                <Col className="tab-info-card">
                    <h4>Indicateurs d'activité</h4>
                    <ClientActivityComponent client ={clientById} />
                </Col>
                <Col className="tab-info-card">
                    <h4>Image de fond des casques</h4>
                    <EditClientVRBkgdImageComponent client={clientById}/> 
                    <h4>Clients parents/enfants</h4>
                    <Row className="text-center d-flex flex-wrap">
                        <Col xs={12} md={6} >
                            <Button variant="success" className="tab-info-card-btn w-100" onClick={createParenthoodModal}>
                                <i className="fas fa-plus"></i> &nbsp;Ajouter parent ou enfants
                            </Button>
                        </Col>
                        <Col xs={12} md={6} >
                            <Button variant="success" className="tab-info-card-btn w-100" onClick={createMultiUserModal}>
                                <i className="fas fa-plus"></i> &nbsp;Créer des enfants
                            </Button>
                        </Col>
                    </Row>
                    { parentListComponent }
                    { childrenListComponent }
                </Col>
            </Row>
            <Row className="mb-5" >
               <Col  className="m360-col3-end">
               {isFetchingIsClientByIdDeletable ? 
                <Button variant='secondary' disabled className="w-auto d-flex justify-content-end">
                    {spinner("Supprimer")}
                </Button>
               :<>{
                    !isClientByIdDeletable ?
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip className='tooltip-delete-client'>
                                    <p className='text-start'>
                                        Vous ne pouvez pas supprimer cet établissement car il est lié, au minimum, à une des ressources suivantes :<br/><br/>
                                        <ul>
                                            <li>Casques</li>
                                            <li>Utilisateur⋅rices</li>
                                            <li>Enfants</li>
                                            <li>Groupes, expériences immersives ou quiz</li>
                                            <li>Bons de préparation</li>
                                        </ul>
                                    </p>
                                </Tooltip>
                            }>
                           <span>
                                <Button disabled variant='danger' className="w-auto">
                                   Supprimer
                                </Button>
                           </span>
                        </OverlayTrigger> 
                    :<Button variant='danger' className="w-auto d-flex justify-content-end" onClick={deleteClientModal}>
                            Supprimer
                     </Button>
                   
                }</>
                }
                </Col>
             </Row>
            <Row>
                <Col>
                    <VideoPlayGraph clientUniqueId={clientById.uniqueId} />
                </Col>
            </Row>
        </Container>
    );

}

export default ClientInfoTabComponent
