import React, { useState }  from 'react';
import { useStoreState } from 'easy-peasy';
import {Button, Form, Alert} from 'react-bootstrap';
import ButtonLoaderComponent from '../widgets/ButtonLoaderComponent';

import axios from "../../services/axios";

const GetResetLinkForm =({backAction, email}) => {
    const { apiRoutes } = useStoreState(actions => actions.params);
    const [stage, setStage] = useState(0);
    const [alertUser, setAlertUser] = useState(null);
    const [isSending, setIsSending] = useState(false);


    const onValidate= () => {
        setIsSending(true);
        const email = document.getElementById('inputEmail').value;
        const csrf_token = document.getElementById('csrf_token').value;
        axios.post(apiRoutes.resetPassword,{email,csrf_token}).then((response) => {
            setAlertUser(
                <Alert variant="info" className="text-center">
                    {response.msg}<br />
                    <Button as="a" bsPrefix={'no-btn'} onClick={backAction}>Revenir à la page d'accueil</Button>
                </Alert>);
            setStage(1);
        });
    }

    const form=<Form>
    <Form.Group controlId="inputEmail">
        <Form.Label className="small mb-1">Email</Form.Label>
        <Form.Control 
            type="email" 
            className="py-4" 
            name="email" 
            defaultValue={email} 
            placeholder="Entrer votre adresse email" 
            required autoFocus />
    </Form.Group>
    <input type="hidden" id="csrf_token" name="_csrf_token"
        value={null} // TODO voir comment gérer cette histoire de token si ce n'est plus SF qui sert le formulaire
    />
    <Form.Group className="d-flex align-items-center justify-content-between mt-4 mb-0">
        <Button as="a" bsPrefix={'no-btn'} className="small" onClick={backAction}>Revenir</Button>
        <ButtonLoaderComponent 
            variant="secondary"
            className="px-2"
            size="sm"
            onClick={onValidate}
            isSending={isSending}
            msg="Réinitialiser le mot de passe"
        />
    </Form.Group>
    </Form>;

    return (<>{stage === 0 ? form:alertUser}</>);
};

export default GetResetLinkForm;