import React, { useEffect, useState } from "react";
import { useStoreActions } from 'easy-peasy';

import { Container, Row, Col, Form } from "react-bootstrap";

import Loader from '../../components/widgets/Loader'

import AuthorizationChecker from "../../services/AuthorizationChecker";
import GroupDisplayContentComponent from "../../components/groups/GroupDisplayContentComponent";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import TooltipInfo from "../widgets/tooltipInfo/TooltipInfo";


const CatalogHeadsetViewComponent = (props) => {
    // Group state variables + actions
    const { fetchGroupById } = useStoreActions(actions => actions.groups);
    const { allGroups, groupById } = useCustomGetStoreState("groups");
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [publicOnly, setPublicOnly] = useState(false);

    const { uniqueId } = allGroups.find(group => group.idHeadset === 0 && group.uniqueId) || {};

    useEffect(() => {
        setIsMounted(true);
        if (uniqueId != null && !isLoading) {
            setIsLoading(true);
            fetchGroupById(uniqueId)
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => setIsMounted(false);
    }, [uniqueId]);

    return (
        <Container fluid>
          
            {<Row className="d-flex flex-column">
                <h2 className="d-flex">
                    Le casque Métiers360, comme si vous y étiez !
                    <TooltipInfo description="Voici ce que vous verrez en enfilant votre casque Métiers360. De quoi vous familiariser 
                        facilement avec son utilisation et ses contenus." />
                </h2>
                {
                    (!groupById || isLoading || !isMounted)
                        ? <Loader />
                        :<>{AuthorizationChecker.isAdmin() && 
                                <Container>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <Form.Check type="switch" label="Ne voir que les contenus publics" onChange={(event) => setPublicOnly(event.currentTarget.checked)} />
                                        </Col>
                                    </Row>
                                </Container>
                            }
                        <GroupDisplayContentComponent group={groupById} publicOnly={publicOnly}/></> 
                }
            </Row>}
        </Container>
    )
}


export default CatalogHeadsetViewComponent;