import { Container, Row, Col, Button } from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';

import {URLS} from '../../services/axios';

import AuthorizationChecker from '../../services/AuthorizationChecker';

import ActivitiesPanel from '../../components/activities/ActivitiesPanel';
import VideoPlayGraph from '../../components/activities/VideoPlayGraph';
import TooltipInfo from '../../components/widgets/tooltipInfo/TooltipInfo';


const StatPage = () => {
    const {client:userClientUniqueId} = useStoreState(state => state.auth);
    const {apiData} = useStoreState(state => state.actionSlugs);

    const downloadCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getHeadsetActivityByCSV.url.replace('ClientID',userClientUniqueId).replace('activityID','all');
        window.open(urlCSV);
    }

    const downloadwebappActiviyCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getwebappActivityByCSV.url.replace('ClientID',userClientUniqueId);
        window.open(urlCSV);
    }

    const RowActivityButton = 
    <Row>
        <Col className="mb-3 d-flex justify-content-around align-items-center">
            <Button onClick={downloadCSV}><i className="fas fa-download"/> Activités casques</Button>
            <Button onClick={downloadwebappActiviyCSV}><i className="fas fa-download"/> Activités webapp</Button>
        </Col>
    </Row>;


    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className='d-flex'>
                        Quelques chiffres
                        {!AuthorizationChecker.isAdmin()
                            && <TooltipInfo description='Quelles sont les expériences immersives les plus regardées sur votre parc de casques. 
                            Télécharger le détail au format csv.' />}
                    </h2>
                </Col>
            </Row>
            {!AuthorizationChecker.isAdmin() && RowActivityButton}
            <ActivitiesPanel />
            <VideoPlayGraph />
        </Container>
    )
}

export default StatPage;