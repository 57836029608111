import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';

const pedagogicModulesInitialState = {
    allPedagogicModules: [],
    isFetchingAllPedagogicModules: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    pedagogicModuleById: null,
    isFetchingOnePedagogicModule: false,
}

const pedagogicModules = {
    ...pedagogicModulesInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allPedagogicModules: state.allPedagogicModules,
                    isFetchingAllPedagogicModules: state.isFetchingAllPedagogicModules,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    pedagogicModuleById: state.pedagogicModuleById,
                    isFetchingOnePedagogicModule: state.isFetchingOnePedagogicModule
                } = pedagogicModulesInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllPedagogicModules: action((state, payload) => {
        state.allPedagogicModules = payload;
    }),

    setIsFetchingAllPedagogicModules: action((state, payload) => {
        state.isFetchingAllPedagogicModules = payload;
    }),

    fetchPedagogicModules: thunk((actions, payload, helpers) => {
        const { isFetchingAllPedagogicModules, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.pedagogicModules?.readAll?.url;

        if (!isFetchingAllPedagogicModules && isUpdateNecessary) {
            actions.setIsFetchingAllPedagogicModules(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllPedagogicModules(data);
                    actions.setIsUpdateNecessary(pedagogicModulesInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => {
                    Promise.reject(error);
                })
                .finally(() => {
                    actions.setIsFetchingAllPedagogicModules(pedagogicModulesInitialState.isFetchingAllPedagogicModules);
                })
        } else {
            Promise.resolve();
        }
    }),

    // handlingOnePedagogicModule
    setPedagogicModuleById: action((state, payload) => {
        state.pedagogicModuleById = payload;
    }),
    setIsFetchingOnePedagogicModule: action((state, payload) => {
        state.isFetchingOnePedagogicModule = payload;
    }),

    fetchPedagogicModuleById: thunk((actions, payload, helpers) => {
        const { isFetchingOnePedagogicModule } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.pedagogicModules?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOnePedagogicModule) {
            actions.setIsFetchingOnePedagogicModule(true);
            actions.setPedagogicModuleById(pedagogicModulesInitialState.pedagogicModuleById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOnePedagogicModule(pedagogicModulesInitialState.isFetchingAllPedagogicModules);
                    actions.setPedagogicModuleById(data);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
        } else {
            return Promise.resolve();
        }
    }),

    removeTagFromPedagogicModules: action((state, payload) => {
        state.allPedagogicModules = state.allPedagogicModules.map(module => {
            if (module.tags.find(tag => tag.uniqueId === payload)) {
                return {
                    ...module,
                    tags: module.tags.filter(tag => tag.uniqueId !== payload)
                }
            }
            return module;
        })
    }),

    pingDownloadEvent: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const pingUrl = ACTIONS?.pedagogicModules?.pingDownloadEvent?.url?.replace('uniqueId', payload);
        return axios.get(pingUrl)
            .catch(error => Promise.reject(error))
    }),


    addPedagogicModule: action((state, payload) => {
        state.allPedagogicModules.push(payload);
    }),

    updateAllPedagogicModule: action((state, payload) => {
        const index = state.allPedagogicModules.findIndex((module) => module.uniqueId === payload.uniqueId);

        state.allPedagogicModules[index] = {
            ...state.allPedagogicModules[index],
            ...payload,
        };

    }),

    removePedagogicModule: action((state, payload) => {
        state.allPedagogicModules = state.allPedagogicModules.filter(module => module.uniqueId !== payload);
    }),

    // createOnePedagogicModule
    postPedagogicModule: thunk((actions, payload, helpers) => {
        const { setIsUpdateNecessary: setShouldUpdateTag } = helpers.getStoreActions().tags;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createPedagogicModuleUrl = ACTIONS?.pedagogicModules?.create?.url;
        const { formData } = payload;

        return axios.post(createPedagogicModuleUrl, formData)
            .then((result) => {
                actions.addPedagogicModule(result.pedagogicModuleToAdd)
                setShouldUpdateTag(true);
                return result.pedagogicModuleToAdd;
            })
            .catch(error => Promise.reject(error));
    }),

    // updatePedagogicModule
    updatePedagogicModule: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const { setIsUpdateNecessary: setShouldUpdateTag } = helpers.getStoreActions().tags;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updatePedagogicModuleUrl = ACTIONS?.pedagogicModules?.update?.url?.replace('uniqueId', uniqueId);

        return axios.post(updatePedagogicModuleUrl, formData)
            .then((result) => {
                actions.updateAllPedagogicModule(result.pedagogicModuleToAdd)
                setShouldUpdateTag(true);
                return result.pedagogicModuleToAdd;
            })
            .catch(error => Promise.reject(error));
    }),

    deletePedagogicModule: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { setIsUpdateNecessary: setShouldUpdateTag } = helpers.getStoreActions().tags;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const formData = new FormData();
        formData.set(`${ACTIONS?.pedagogicModules?.delete?.formName}[_token]`, ACTIONS?.pedagogicModules?.delete?.csrftoken);
        const deletePedagogicModuleUrl = ACTIONS?.pedagogicModules?.delete?.url.replace('uniqueId', uniqueId);

        return axios.post(deletePedagogicModuleUrl, formData)
            .then((result) => {
                actions.removePedagogicModule(uniqueId);
                setShouldUpdateTag(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),
};

export default pedagogicModules;