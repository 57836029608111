import React, { useRef, useState, useEffect} from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import DeliveryInformationComponent from "./DeliveryInformationComponent/DeliveryInformationComponent";
import SelectProductComponent from "./SelectProductsComponent/SelectProductComponent";
import SelectProviderComponent from "./SelectProviderComponent./SelectProviderComponent";
import OrderFormComponent from "./OrderFormComponent";
import { useStoreActions, useStoreState} from 'easy-peasy';
import { useNavigate } from 'react-router';

const MulitpleOrdersFormComponent = ({
    clients,
    order:initDataOrder
}) => {

    const deliveryInformationsRef = useRef(initDataOrder ? {...initDataOrder}:{});
    const initProducts = initDataOrder?.orderlines ?
        initDataOrder.orderlines.map(orderline => {return {uniqueId:orderline.productUniqueId, quantity:orderline.quantity}})
        :null;
    const productsRef = useRef(initProducts);
    const providerRef = useRef(initDataOrder?.providerUniqueId);
    const selectedIsRentingRef = useRef(initDataOrder ? initDataOrder?.isRenting:null);
    const [comment, setComment] = useState(initDataOrder?.comment || '');
    const [noProduct, setNoProduct] = useState(!initDataOrder);
    const [badQuantity, setBadQuantity] = useState(false);
    const [cannotSend, setCannotSend] = useState(false);
    const [commonInfos, setCommonInfos] = useState({});
    const [validateAll, setValidateAll] = useState(false);
    const [validatedOrders, setValidatedOrders] = useState([]);
    const [validateErrors, setValidateErrors] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);
    const [checkedClientsNum, setCheckedClientsNum] = useState(clients?.length);
    const { allProducts } = useStoreState(state => state.orders);
    const { fetchProducts } = useStoreActions(actions => actions.orders);
    const navigate = useNavigate();
    const redirect = (url) => {
        navigate(url, {replace:true, state: {fromEdit:true}});
    }
    const ordersSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('orders'));
    const [triggerUpdateCommonInfos, setTriggerUpdateCommonInfos] = useState(false); 

    useEffect(() => {
        allProducts.length == 0 && fetchProducts();
    },[]);
  
    const onValidate = () => {
        setCheckedClientsNum(clients?.length);
        setValidateErrors(false);
        setValidateAll(true);
    }


    const handleErrors = () => {
        setValidateErrors(true);
        setValidateAll(false);
    }

    const handleValidation = (clientCRMUniqueId) => {
        validatedOrders.push(clientCRMUniqueId);
        setValidatedOrders([...validatedOrders])
        if(validatedOrders.length == clients.length){
            redirect(ordersSlugs.readAll);
        }
    }

    const handleChecked = () => {
        let checkedNum = checkedClientsNum - 1;
        setCheckedClientsNum(checkedNum);
        if(checkedClientsNum == 0){
            setValidateAll(false);
        }
    }

    const handleProducts = (products) => {
        commonInfos.products = products;
        setCommonInfos({...commonInfos});
    }


    const onValidateCommon = () => {
        let commonInfosVals = {change:true};
        commonInfosVals.deliveryInformations = {...deliveryInformationsRef.current, isRenting: selectedIsRentingRef.current};
        commonInfosVals.products = productsRef.current;
        commonInfosVals.provider = providerRef.current;
        commonInfosVals.comment = comment;
        setCommonInfos({...commonInfosVals});
        setTriggerUpdateCommonInfos((prev) => !prev);
    }


    useEffect(() => {
        if(clients?.length > 0){
           setSelectedClients(clients);
        }
    },[clients])

   

    const commonInfosForm = <>
        <DeliveryInformationComponent deliveryInformationsRef={deliveryInformationsRef} isToSetCommonInfos={true} />
        <SelectProductComponent  allProducts={allProducts} productsRef={productsRef} setNoProduct={setNoProduct} 
            setBadQuantity={setBadQuantity} noProduct={noProduct} badQuantity={badQuantity} cannotSend={cannotSend} 
            isToSetCommonInfos={true} selectedIsRentingRef={selectedIsRentingRef}/>
        <SelectProviderComponent providerRef={providerRef} />
        <Row>
                <Col>Commentaire :
                    <textarea
                    style={{width:'100%'}}
                    type="text"
                    placeholder="un commentaire ?"
                    value={comment} onChange={(e) => {setComment(e.target.value);}} />
                </Col>
        </Row>
        <Row >
            <Col className="mt-3 d-flex align-items-center justify-content-center">
                <Button variant= "success" onClick={()=>{onValidateCommon()}}>Valider</Button>
            </Col>
        </Row>
    </>


    const orderForms = <>
        {selectedClients?.sort(clientCRM => clientCRM?.name)
            .map((clientCRM)=>{
            return(
                <Row key={'oderForm'.concat(clientCRM.uniqueId)} className="mt-5 mb-5">
                    <Row className="mt-4 mb-4">
                        <h3>Client : <span className="fw-bold">{clientCRM?.name}</span></h3>
                    </Row>
                    {clientCRM && !validatedOrders.includes(clientCRM.uniqueId)? 
                    <OrderFormComponent triggerUpdateCommonInfos={triggerUpdateCommonInfos} handleProducts={handleProducts} client={clientCRM} multiple={true} validateAll={validateAll} commonInfos={commonInfos} handleErrors={handleErrors} handleValidation={handleValidation} handleChecked={handleChecked}/>
                    :clientCRM && validatedOrders.includes(clientCRM.uniqueId) ? <Alert variant="success" className="text-center"> Bon de préparation pour {clientCRM.name} créé avec succès</Alert>:null}
                </Row>
            )})
        }
        <Row className="mt-3 d-flex align-items-center justify-content-center" >
            {validateErrors && <Alert variant="danger" className="text-center"> {initDataOrder ? "Édition" : "Sauvegarde"} impossible pour un ou plusieurs bons</Alert>} 
        </Row>
        <Row >
            <Col className="mt-3 d-flex align-items-center justify-content-center">
                <Button variant= "success" onClick={()=>{onValidate()}}>Valider</Button>
            </Col>
        </Row>
     </>
    
    return (
        <Container>
            <Row>
                <h2>Informations communes à tous les bons</h2>
                {commonInfosForm}
            </Row>
            {selectedClients?.length > 0 &&
            <Row className="mt-5">
                <h2>Informations propres à chaque bon</h2>
                {orderForms}
            </Row>
            }
            
        </Container>
    );
}

export default MulitpleOrdersFormComponent;