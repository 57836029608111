import React from 'react';

import './Loader.scss';

/**
* Lodaer widget
*
* @version 1.0.0
* @example
*      <Loader />
*/
export default class Loader extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="_metiers360-loader-widget">
                <div className="child child-1"></div>
                <div className="child child-2"></div>
                <div className="child child-3"></div>
            </div>
        )

    }
}