import React, { useState, useEffect } from 'react';
import { Button, Container, ListGroup, ListGroupItem, Row, Spinner } from 'react-bootstrap';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';
import SelectMultipleClientsComponent from '../widgets/selectClient/SelectMultipleClientsComponent.jsx';
import FormInteractionComponent from './formInteraction/FormInteractionComponent.jsx';
import CardInteractionComponent from "./cardInteraction/CardInteractionComponent.jsx";
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import DynamicTable from '../widgets/dynamicTable/DynamicTable.jsx';
import { useStoreActions, useStoreState } from 'easy-peasy';

/**
 * @example <CreateMultipleInteractionsComponent />
 */
const CreateMultipleInteractionsComponent = () => {
    const { allClients } = useCustomGetStoreState("clients");
    const { createClientInteraction } = useStoreActions(actions => actions.interactions);
    const { contactsM360 } = useStoreState(state => state.clients);
    const { interactionTypes } = useStoreState(state => state.interactions);

    const [stage, setStage] = useState(0);
    const [currentSending, setCurrentSending] = useState(null);
    const [clientUniqueIds, setClientUniqueIds] = useState([])
    const [openFormType, setOpenFormType] = useState('add');
    const [interactionToAdd, setInteractionToAdd] = useState(null);
    const [interactionForCard, setInteractionForCard] = useState(null);
    const [serverResults, setServerResults] = useState([]);

    useEffect(() => {
        if(interactionToAdd) {
            goToNextStage();

            const interactionForCard = {...interactionToAdd};
            interactionForCard.type = interactionTypes.find(type => type.id == interactionForCard.type);
            interactionForCard.contactsM360 = interactionForCard.contactsM360.map(contactM360Id => contactsM360.find(contactM360 => contactM360.id == contactM360Id));
            setInteractionForCard(interactionForCard);
        }
    }, [interactionToAdd])

    const goToNextStage = () => {
        if(stage+1 < displayStages.length) setStage(stage+1)
    }
    const goToPreviousStage = () => {
        if(stage > 0) setStage(stage-1)
    }

    const onValidate = async () => {
        goToNextStage();
        let addInteractionPromises = [];
        setCurrentSending(true);
        
        clientsSelected
            .forEach(client => {
                addInteractionPromises.push(
                    createClientInteraction({ interaction: interactionToAdd, uniqueId: client.uniqueId })
                        .then((res)=> {
                            let newResult = serverResults;
                            if(res) {
                                newResult.push({
                                    name: client.name,
                                    isOK: true,
                                    msg:'Interaction ajoutée'
                                });
                            } else {
                                newResult.push({
                                    name: client.name,
                                    isOK: false,
                                    msg:'Erreur lors de l\'ajout de l\'interaction'
                                });
                            }
                            setServerResults(newResult);
                        })
                        .catch((error) => {
                            let newResult = serverResults;
                            newResult.push({
                                name: client.name,
                                isOK: false,
                                msg:'Erreur lors de l\'ajout de l\'interaction'
                            });
                            setServerResults(newResult);
                        })
                );
            });

        Promise.all(
            addInteractionPromises
        ).then(() => {
            setCurrentSending(false);
        })
    }

    const previousButton = <Button variant="primary" size="sm" className="align-right w-auto" 
        onClick={() => goToPreviousStage()}>
        <span className="icon-arrow-left me-2"></span> Phase précédente
    </Button>;

    const clientsSelected = clientUniqueIds.map(clientUId => {
        return {
            uniqueId: clientUId,
            name: allClients.find(client => client.uniqueId == clientUId).clientName
        }
    })

    const sortType = clientsSelected?.length > 0
        ? [
            {
                value: 'clientName', label: 'Établissement',
                test : HelperMetiers360.isArrayContainsValue(clientsSelected, "name"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
                display: (client) => client.name
            }
        ]
        : [];

    const clientsTable = clientsSelected.length > 0 && sortType.length > 0 && <DynamicTable className="ms-5 me-2"
        contentTable={clientsSelected}
        contentSort={sortType}
        index='uniqueId' />

    const serverResultsDisplay = serverResults?.map(res => <ListGroupItem key={HelperMetiers360.generateUniqueId()}>
        {res.name}&nbsp;
        <span className={"ms-3 text-"+ (res.isOK ? "success" : "danger") + " icon-" +
            (res.isOK ? "checkmark" : "cross")}> {res.msg}</span>
    </ListGroupItem>);

    const currentSendingDisplay = currentSending
        && <ListGroupItem>Edition en cours <Spinner size="sm" animation="border" /></ListGroupItem>;

    const stage0 = <Container className={stage !== 0 ? 'd-none' : null} key="0">
        <Row>
            <h5 className='mb-0'>Sélectionnez les établissements auxquels vous voulez ajouter une interaction commune</h5>
            <SelectMultipleClientsComponent selectedClients={clientUniqueIds} setSelectedClients={setClientUniqueIds} />
        </Row>
        <Button variant="primary" size="sm" className="align-right" 
            disabled={clientUniqueIds.length === 0} onClick={() => goToNextStage()}>
            Phase suivante <span className="icon-arrow-right ms-2"></span>
        </Button>
    </Container>;

    const stage1 = <Container className={stage !== 1 ? 'd-none' : null} key="1">
        <Row>
            {previousButton}
        </Row>
        <Row>
            <h5 className='mb-0 mt-4'>Renseignez les données de l'interaction qui sera ajoutée aux établissements choisis à l'étape précédente</h5>
        </Row>
        <Row className='my-4'>
            <FormInteractionComponent openFormType={openFormType} setOpenFormType={setOpenFormType} multipleAddition={true}
                setInteractionToAdd={setInteractionToAdd} />
        </Row>
    </Container>;

    const stage2 = <Container className={stage !== 2 ? 'd-none' : null} key="2">
        <Row>
            {previousButton}
        </Row>
        <Row>
            <h5 className='mb-0 mt-4'>Veuillez confirmer les informations avant le lancement de la procédure d'ajout multiple</h5>
        </Row>
        <Row className='my-4'>
            <Row>
                {clientsTable}
            </Row>
            <Row className='mt-5'>
                {interactionForCard 
                    && <CardInteractionComponent 
                        interaction={interactionForCard} 
                        editable={false}
                        disableIsDone={true} />}
            </Row>
        </Row>
        <Row className='justify-content-end'>
            <Button variant='success' className="w-auto d-flex" onClick={() => onValidate()}>
                <i className="fas fa-check me-2 action-interaction"></i>Confirmer
            </Button>
        </Row>
    </Container>;

    const stage3 = <Container className={stage !== 3 ? 'd-none' : null} key="3">
        {serverResults?.length > 0 && <ListGroup>{serverResultsDisplay}</ListGroup>}
        {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}
    </Container>

    const displayStages = [stage0, stage1, stage2, stage3];

    return <div>
        {displayStages.map(stage => stage)}
    </div>
}

export default CreateMultipleInteractionsComponent;