import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Form, Alert, Button } from 'react-bootstrap';
import Loader from '../../widgets/Loader.jsx';
import SelectSingleClientComponent from "../../widgets/selectClient/SelectSingleClientComponent.jsx";
import ButtonLoaderComponent from '../../widgets/ButtonLoaderComponent.jsx';
import axios from '../../../services/axios';
import { useModal } from "../../../hooks/useModal.jsx";
import { useToast } from '../../../hooks/useToast.jsx';
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState"


import "./TransferHeadsetComponent.scss";


const TransferHeadsetComponent = (props) => {
    const { headset = null, setValidatedStepChangeClient, setMainModalShow } = props;
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const { transferHeadset } = useStoreActions(actions => actions.headsets);
    const [clientUniqueId, setClientUniqueId] = useState([]);
    const [subscriptionsList, setSubscriptionsList] = useState();
    const [subscriptionUniqueId, setSubscriptionUniqueId] = useState();
    const [isFetching, setIsFetching] = useState(false);
    const { allClients } = useCustomGetStoreState("clients");

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending, isSending } = useModal();
    const toast = useToast();

    useEffect(() => {
        setSubscriptionUniqueId(null);
        if (clientUniqueId?.length) {
            setIsFetching(true)
            const getUrl = ACTIONS?.clients?.getProps?.getSubscriptions?.url?.replace('uniqueId', clientUniqueId[0]);
            return axios.get(getUrl)
                .then(data => {
                    setSubscriptionsList(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    setIsFetching(false);
                })
        }
    }, [clientUniqueId]);

    const subscriptionsListOptions = subscriptionsList
        ?.map(subscription =>
            <option
                key={'subscription' + subscription.uniqueId}
                value={subscription.uniqueId}
            >
                {subscription.subscriptionName ?? `Abonnement se terminant le ${subscription.subscriptionEndDate}`}
            </option>);

    const onSelectSubscription = (e) => {
        const selectedSubUUID = e.target.selectedOptions[0].value;
        setSubscriptionUniqueId(selectedSubUUID ?? null);
    }

    const openTransferModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            transferHeadset({ uniqueId: headset.uniqueId, headsetSubscriptionUniqueId: subscriptionUniqueId })
                .then(() => {
                    toast.success();
                    setModalShow(false);
                    setMainModalShow(false)
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Transfert du casque {headset?.headsetName ?? headset?.deviceId}</>,
            content: <>Valider le transfert depuis <strong>{allClients?.find(c => c.uniqueId == headset?.clientUniqueId)?.clientName}</strong> vers <strong>{allClients?.find(c => c.uniqueId == clientUniqueId)?.clientName}</strong>, abonnement <strong>{subscriptionsList?.find(s => s.uniqueId == subscriptionUniqueId)?.subscriptionName}</strong>?</>,
            cancelButton: "Annuler",
            onValidate: handleSubmit,
            size: 'xl',
        });
        setModalShow(true);
    };

    return (
        <div className='transfer-section'>
            <Form onSubmit={(event) => event.preventDefault()}>
                <Form.Group className="formSection selector-clientabo">
                    <Form.Label>Transférer le casque chez:</Form.Label>
                    <SelectSingleClientComponent
                        nameSelect={`clientUniqueId`}
                        selectedClients={clientUniqueId}
                        setSelectedClients={setClientUniqueId} />
                </Form.Group>

                {clientUniqueId?.length > 0 &&
                    <Form.Group className="formSection selector-clientabo">
                        <Form.Label>Associer à l'abonnement</Form.Label>
                        {!isFetching ?
                            subscriptionsList?.length ?
                                <Form.Select name="headset_subscription" onChange={onSelectSubscription}>
                                    <option value="">-- Sélectionner un abonnement --</option>
                                    {subscriptionsListOptions}
                                </Form.Select>
                                : <Alert variant="danger">Ce client n'a pas d'abonnement</Alert>
                            : <Loader />
                        }
                    </Form.Group>
                }
            </Form>
            <div className="buttons-changeClient">
            <Button variant='secondary' className='ml-2' size='sm' onClick={() => setValidatedStepChangeClient(false)}>Annuler le transfert</Button>
            {subscriptionUniqueId &&
                <ButtonLoaderComponent
                    variant='success' size='sm' msg="Transférer" isSending={isSending} disabled={isSending} onClick={openTransferModal}
                />}
            </div>
            {modalComponent}
        </div>
    )

}

export default TransferHeadsetComponent;