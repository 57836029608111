import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const clientStatusInitialState = {
    allClientStatus: [],
    isFetchingAllClientStatus: false,
    isUpdateNecessary: true,
    initialFetchDone: false,
}

const clientStatus = {
    ...clientStatusInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allClientStatus: state.allClientStatus,
                    isFetchingAllClientStatus: state.isFetchingAllClientStatus,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone
                } = clientStatusInitialState);
            }
        }
    ),

    setAllClientStatus: action((state, payload) => {
        state.allClientStatus = payload;
    }),

    setIsFetchingAllClientStatus: action((state, payload) => {
        state.isFetchingAllClientStatus = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    fetchClientStatus: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { isFetchingAllClientStatus, isUpdateNecessary } = helpers.getState();

        const getClientStatusUrl = ACTIONS?.clientStatus?.readAll?.url;
        if (getClientStatusUrl && !isFetchingAllClientStatus && isUpdateNecessary) {
            actions.setIsFetchingAllClientStatus(true);
            return axios.get(getClientStatusUrl)
                .then((result) => {
                    actions.setAllClientStatus(result);
                    actions.setIsFetchingAllClientStatus(false);
                    actions.setIsUpdateNecessary(false);
                    return result;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        } else {
            return Promise.resolve();
        }
    }),

    addClientStatus: action((state, payload) => {
        for (const element of payload) {
            const existingClientStatus = state.allClientStatus.find(status => status?.uniqueId === element.uniqueId);
            if (existingClientStatus === undefined) {
                state.allClientStatus.push(element);
            }
        }
    }),

    createClientStatus: thunk((actions, payload, helpers) => {
        const data = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const createClientStatusUrl = ACTIONS?.clientStatus?.create?.url;

        return axios.post(createClientStatusUrl, data)
            .then((result) => {
                actions.addClientStatus(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    updateClientStatusProps: thunk((actions, payload, helpers) => {
        const allClientStatus = helpers.getState().allClientStatus;

        const newAllClientStatus = allClientStatus
            .map(clientStatus => {
                const newClientStatus = payload?.find(newClientStatus => clientStatus.uniqueId === newClientStatus.uniqueId);
                return { ...clientStatus, ...newClientStatus }
            });

        actions.setAllClientStatus(newAllClientStatus);
    }),

    updateClientStatus: thunk((actions, payload, helpers) => {
        const { data, uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const updateClientStatusUrl = ACTIONS?.clientStatus?.update?.url?.replace('uniqueId', uniqueId);

        return axios.post(updateClientStatusUrl, data)
            .then((result) => {
                actions.updateClientStatusProps(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    removeClientStatus: action((state, payload) => {
        state.allClientStatus = state.allClientStatus.filter(status => status?.uniqueId !== payload);
    }),

    deleteClientStatus: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const deleteClientStatusUrl = ACTIONS?.clientStatus?.delete?.url?.replace('uniqueId', uniqueId);
        const data = {csrfToken: ACTIONS?.clientStatus?.delete?.csrfToken};

        return axios.post(deleteClientStatusUrl, data)
            .then((result) => {
                actions.removeClientStatus(uniqueId);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

}

export default clientStatus;