import { action, actionOn, thunk } from "easy-peasy";
import axios from "../services/axios";

const quizzesInitialState = {
    allQuizzes: [],
    isFetchingAllQuizzes: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    quizById: null,
    isFetchingOneQuiz: false,
    isQuizByIdUpdateNecessary: false,
    isUpdatingOneQuiz: false,
}

const quizzes = {
    ...quizzesInitialState,

    onGlobalResetCalled: actionOn(
        // target resolver: we store in an array a list of action we will be listening for
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        // handler: if we are listening for multiple type of actions, we can execute logic depending on the type
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allQuizzes: state.allQuizzes,
                    isFetchingAllQuizzes: state.isFetchingAllQuizzes,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    quizById: state.quizById,
                    isFetchingOneQuiz: state.isFetchingOneQuiz,
                    isQuizByIdUpdateNecessary: state.isQuizByIdUpdateNecessary,
                    isUpdatingOneQuiz: state.isUpdatingOneQuiz
                } = quizzesInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),
    setAllQuizzes: action((state, payload) => {
        state.allQuizzes = payload;
    }),
    setIsFetchingAllQuizzes: action((state, payload) => {
        state.isFetchingAllQuizzes = payload;
    }),
    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    fetchQuizzes: thunk((actions, payload, helpers) => {
        const { isFetchingAllQuizzes, isUpdateNecessary } = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const getAllUrl = apiData.ACTIONS?.quizzes?.readAll?.url;

        if (!isFetchingAllQuizzes && isUpdateNecessary) {
            actions.setIsFetchingAllQuizzes(true);
            return axios.get(getAllUrl)
                .then((data) => {
                    actions.setAllQuizzes(data);
                    actions.setIsFetchingAllQuizzes(quizzesInitialState.isFetchingAllQuizzes);
                    actions.setIsUpdateNecessary(quizzesInitialState.isUpdateNecessary);
                    return data;
                })
                .catch((error) => Promise.reject(error))
        }
        else {
            return Promise.resolve();
        }

    }),

    setQuizById: action((state, payload) => {
        state.quizById = payload;
    }),

    setIsFetchingOneQuiz: action((state, payload) => {
        state.isFetchingOneQuiz = payload;
    }),

    fetchQuizById: thunk((actions, payload, helpers) => {
        const { isFetchingOneQuiz } = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;


        const getOneUrl = apiData.ACTIONS?.quizzes?.readOne?.url?.replace('uniqueId', payload);
        if (!isFetchingOneQuiz) {
            actions.setIsFetchingOneQuiz(true);
            actions.setQuizById(quizzesInitialState.quizById);
            return axios(getOneUrl)
                .then((data) => {
                    actions.setQuizById(data);
                    return data
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingOneQuiz(quizzesInitialState.isFetchingOneQuiz);
                    actions.setIsQuizByIdUpdateNecessary(quizzesInitialState.isQuizByIdUpdateNecessary)
                });
        }
        else {
            return Promise.resolve();
        }
    }),

    /*
    **  QUIZZ UPDATE/CREATE
    */
    setIsQuizByIdUpdateNecessary: action((state, payload) => {
        state.isQuizByIdUpdateNecessary = payload;
    }),

    setIsUpdatingOneQuiz: action((state, payload) => {
        state.isUpdatingOneQuiz = payload;
    }),

    addQuizz: action((state, payload) => {
        state.allQuizzes.push(payload);
    }),

    updateAllQuizzes: action((state, payload) => {
        const index = state.allQuizzes.findIndex((quiz => quiz.uniqueId === payload.uniqueId));

        state.allQuizzes[index] = {
            ...state.allQuizzes[index],
            ...payload,
        }

    }),

    removeFromAllQuizzes: action((state, payload) => {
        const indexToRemove = state.allQuizzes.findIndex(quiz => quiz.uniqueId === payload);
        state.allQuizzes.splice(indexToRemove, 1);
    }),

    createQuiz: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const createPostUrl = apiData.ACTIONS?.quizzes?.create?.url;
        const { formData } = payload;

        return axios.post(createPostUrl, formData)
            .then((result) => {
                actions.addQuizz(result);
                actions.setQuizById(quizzesInitialState.quizById);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    updateQuiz: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const url = apiData.ACTIONS?.quizzes?.update?.url;
        const { formData, uniqueId } = payload;
        const updateQuizUrl = url?.replace('uniqueId', uniqueId);

        return axios.post(updateQuizUrl, formData)
            .then((result) => {
                actions.updateAllQuizzes(result);
                actions.setIsQuizByIdUpdateNecessary(true);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    deleteQuizBackground: thunk((actions, payload, helpers) => {
        const { uniqueId, actionName } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const { url, csrfToken } = apiData.ACTIONS?.quizzes?.editProps[actionName];
        const formData = new FormData();
        formData.append('token', csrfToken)
        const deleteUrl = url?.replace('uniqueId', uniqueId);
        const { isUpdatingOneQuiz } = helpers.getState();

        if (!isUpdatingOneQuiz) {
            actions.setIsUpdatingOneQuiz(true);
            return axios.post(deleteUrl, formData)
                .then((data) => data)
                .finally(() => actions.setIsUpdatingOneQuiz(quizzesInitialState.isUpdatingOneQuiz))
        }
    }),

    /*
    **  QUESTIONS UPDATE/CREATE
    */
    createQuestion: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const url = apiData.ACTIONS?.quizzes?.editProps?.questions?.create?.url;
        const { formData, uniqueId } = payload;

        const createQuestionUrl = url?.replace('uniqueId', uniqueId)
        return axios.post(createQuestionUrl, formData)
            .then((result) => {
                actions.setIsQuizByIdUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    updateQuestion: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const url = apiData.ACTIONS?.quizzes?.editProps?.questions?.update?.url;
        const { formData, questionUniqueId, quizUniqueId } = payload;

        const updateQuestionUrl = url?.replace('quizUniqueId', quizUniqueId)?.replace('questionUniqueId', questionUniqueId)
        return axios.post(updateQuestionUrl, formData)
            .then((result) => {
                actions.setIsQuizByIdUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    deleteQuestionBackground: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const url = apiData.ACTIONS?.quizzes?.editProps?.questions?.deleteBackground?.url;
        const { formData, questionUniqueId, quizUniqueId } = payload;

        const deleteBackgroundUrl = url?.replace('quizUniqueId', quizUniqueId)?.replace('questionUniqueId', questionUniqueId)
        return axios.post(deleteBackgroundUrl, formData)
            .then((result) => {
                actions.setIsQuizByIdUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    /*
    **  ANSWERS UPDATE/CREATE
    */
    createAnswer: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const url = apiData.ACTIONS?.quizzes?.editProps?.answers?.create?.url;
        const { formData, questionUniqueId, quizUniqueId } = payload;

        const createAnswerUrl = url?.replace('quizUniqueId', quizUniqueId)?.replace('questionUniqueId', questionUniqueId)
        return axios.post(createAnswerUrl, formData)
            .then((result) => {
                actions.setIsQuizByIdUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    updateAnswer: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const url = apiData.ACTIONS?.quizzes?.editProps?.answers?.update?.url;
        const { formData, answerUniqueId, questionUniqueId, quizUniqueId } = payload;

        const updateAnswerUrl = url?.replace('quizUniqueId', quizUniqueId)
            ?.replace('questionUniqueId', questionUniqueId)
            ?.replace('answerUniqueId', answerUniqueId);

        return axios.post(updateAnswerUrl, formData)
            .then((result) => {
                actions.setIsQuizByIdUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),
};

export default quizzes;