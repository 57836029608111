import React, {useState} from "react";
import { Col, Container, Row,Dropdown, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";
import useDebounceSearch from "../../../../hooks/useDebounchSearch";
import ButtonLoaderComponent from "../../../widgets/ButtonLoaderComponent";
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import './ClientCRMMergeComponent.scss';

const ClientCRMMergeComponent = (props) => {
    const {client, setMergeClient} = props;

    const [selectedClient,setSelectedClient] = useState(null);
    const [isSending, setIsSending] = useState(false);

    const {searchInput, search, resetSearchBar} = useDebounceSearch({placeholder :"Rechercher un client"});

    const {allClients} = useCustomGetStoreState('clients');
    const clientActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const oneClientSlug = clientActionSlugs && clientActionSlugs['readOne'] ? clientActionSlugs['readOne']:null;
    const { attachOneClientCRMToClient } = useStoreActions(actions => actions.clients);

    const clientsSelection = allClients
        .filter(client => !client?.isFactured
            && (search == '' || HelperMetiers360.isSearchInText(search, client.clientName))
        );

    const handleSelection = (uniqueId)=> {
        setSelectedClient(allClients.find(currentClient => currentClient.uniqueId == uniqueId));
    }
    
    const handleMerge= () => {
        setIsSending(true);
        attachOneClientCRMToClient({initClientUniqueId:client.uniqueId, targertClientUniqueId:selectedClient.uniqueId})
            .then((data) => {
                setIsSending(false);});
    }
    
    const clientsSelectionOptions = clientsSelection?.length ?
        clientsSelection
            .map(client => <Dropdown.Item as='button'
                key={"option-clients-"+client?.uniqueId}
                onClick={() => handleSelection(client?.uniqueId)}>
                    {client?.clientName}
                </Dropdown.Item>)
        : <Dropdown.Item key="option-clients-none" disabled>
            Pas de résultat
        </Dropdown.Item>;
    
    const displayMessage = selectedClient ?
        <Container className='text-center'>
            <Row>
                <Col>
                Vous avez choisi&nbsp;
                <Link to={oneClientSlug.replace(':uniqueId', selectedClient.uniqueId)}>
                    {selectedClient.clientName}
                </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button size="sm" onClick={() => {setSelectedClient(null);resetSearchBar();}} variant='secondary'>Annuler</Button>
                </Col>
                <Col>
                    <ButtonLoaderComponent
                        size="sm"
                        variant="danger"
                        onClick={() => handleMerge()} 
                        isSending={isSending}
                        disabled={isSending}
                        msg={<><i className="fas fa-save"/> &nbsp;Fusionner</>}
                    />
                </Col>
            </Row>
        </Container>
        :<Container><Row>
            <Col>
                Êtes-vous sûr⋅e qu'il n'existe pas déjà chez les clients abonnés non reliés ?
            </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                <Dropdown>
                    <Dropdown.Toggle as="div">
                    {searchInput}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{width: "100%", maxHeight: "20em", overflowY: "auto", overflowX: "hidden"}}>
                        {clientsSelectionOptions}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
        <Row><Col><Button onClick={(e) => setMergeClient(false)}>Non, c'est bon, il n'est pas dans cette liste, je veux créer un abonnement</Button></Col></Row>
        </Container>;

    
    return <Alert variant='info'>{displayMessage}</Alert>;
}

export default ClientCRMMergeComponent