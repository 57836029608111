import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';
import { store } from './index.js';

const formationSessionsInitialState = {
    allFormationSessions: [],
    isFetchingAllFormationSessions: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    allSessionsByFormationId: [],
    isFetchingAllSessionsByFormationId: false,
    allSessionsByCategory: [],
    isFetchingSessionsByCategory: false,
    formationSessionById: null,
    isFetchingOneFormationSession: false,
}

const formationSessions = {
    ...formationSessionsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allFormationSessions: state.allFormationSessions,
                    isFetchingAllFormationSessions: state.isFetchingAllFormationSessions,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    allSessionsByFormationId: state.allSessionsByFormationId,
                    isFetchingAllSessionsByFormationId: state.isFetchingAllSessionsByFormationId,
                    allSessionsByCategory: state.allSessionsByCategory,
                    isFetchingSessionsByCategory: state.isFetchingSessionsByCategory,
                    formationSessionById: state.formationSessionById,
                    isFetchingOneFormationSession: state.isFetchingOneFormationSession
                } = formationSessionsInitialState);
            }
        }
    ),


    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllFormationSessions: action((state, payload) => {
        state.allFormationSessions = payload;
    }),

    setIsFetchingAllFormationSessions: action((state, payload) => {
        state.isFetchingAllFormationSessions = payload;
    }),

    fetchAllFormationSessions: thunk((actions, payload, helpers) => {
        const { isFetchingAllFormationSessions, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.formationSessions?.readAll?.url;

        if (!isFetchingAllFormationSessions && isUpdateNecessary) {
            actions.setIsFetchingAllFormationSessions(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsUpdateNecessary(formationSessionsInitialState.isUpdateNecessary);
                    actions.setAllFormationSessions(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllFormationSessions(formationSessionsInitialState.isFetchingAllFormationSessions);
                })
        } else {
            return Promise.resolve();
        }
    }),

    setAllSessionsByFormationId: action((state, payload) => {
        state.allSessionsByFormationId = payload;
    }),

    setIsFetchingAllSessionsByFormationId: action((state, payload) => {
        state.isFetchingAllSessionsByFormationId = payload;
    }),

    fetchFormationSessionsByFormationId: thunk((actions, payload, helpers) => {

        const { isFetchingAllSessionsByFormationId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        let getUrl = "";
        if (payload?.clientUniqueId) {
            getUrl = ACTIONS?.clients?.getProps?.getFormationSessions?.url
                .replace('formationUniqueId', payload.formationUniqueId)
                .replace('clientUniqueId', payload.clientUniqueId);
        }
        else {
            getUrl = ACTIONS?.formations?.getSubEntities?.formationSessions?.readAll?.url?.replace('formationUniqueId', payload);
        }

        if (!isFetchingAllSessionsByFormationId) {
            actions.setIsFetchingAllSessionsByFormationId(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllSessionsByFormationId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllSessionsByFormationId(formationSessionsInitialState.isFetchingAllSessionsByFormationId);
                })
        } else {
            return Promise.resolve();
        }
    }),

    setAllSessionsByCategory: action((state, payload) => {
        state.allSessionsByCategory = payload;
    }),

    setIsFetchingSessionsByCategory: action((state, payload) => {
        state.isFetchingSessionsByCategory = payload;
    }),

    fetchFormationSessionsByCategory: thunk((actions, payload, helpers) => {
        const { isFetchingSessionsByCategory } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS.formationSessions?.readByCategory?.url?.replace('category', payload);

        if (getUrl && !isFetchingSessionsByCategory) {
            actions.setIsFetchingSessionsByCategory(true);

            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingSessionsByCategory(formationSessionsInitialState.isFetchingSessionsByCategory);
                    actions.setAllSessionsByCategory(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve();
        }
    }),

    // handlingOneFormationSession
    setFormationSessionById: action((state, payload) => {
        state.formationSessionById = payload;
    }),
    setIsFetchingOneFormationSession: action((state, payload) => {
        state.isFetchingOneFormationSession = payload;
    }),

    fetchFormationSessionById: thunk((actions, payload, helpers) => {
        const { isFetchingOneFormationSession } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.formations?.getSubEntities?.formationSessions?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneFormationSession) {
            actions.setIsFetchingOneFormationSession(true);
            actions.setFormationSessionById(null);

            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneFormationSession(formationSessionsInitialState.isFetchingOneFormationSession);
                    actions.setFormationSessionById(data);
                    actions.updateAllSessionsByFormationId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
        } else {
            return Promise.resolve();
        }
    }),

    addFormationSession: action((state, payload) => {
        state.allFormationSessions.push(payload);
        state.allSessionsByFormationId.push(payload);
    }),

    updateAllSessionsByFormationId: action((state, payload) => {
        const indexAll = state.allFormationSessions.findIndex((module) => module?.uniqueId === payload?.uniqueId);

        state.allFormationSessions[indexAll] = {
            ...state.allFormationSessions[indexAll],
            ...payload,
        };

        const index = state.allSessionsByFormationId.findIndex((module) => module?.uniqueId === payload?.uniqueId);

        state.allSessionsByFormationId[index] = {
            ...state.allSessionsByFormationId[index],
            ...payload,
        };

    }),


    removeFormationSession: action((state, payload) => {
        state.allFormationSessions = state.allFormationSessions.filter(module => module?.uniqueId !== payload);
        state.allSessionsByFormationId = state.allSessionsByFormationId.filter(module => module?.uniqueId !== payload);
    }),

    // createOneFormationSession
    createFormationSession: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createFormationSessionUrl = ACTIONS?.formations?.editProps?.formationSessions?.create?.url
            ?.replace('formationUniqueId', payload.formationUniqueId);
        const { formData } = payload;

        return axios.post(createFormationSessionUrl, formData)
            .then((result) => {
                actions.setFormationSessionById(result.formationSessionToAdd);
                actions.addFormationSession(result.formationSessionToAdd);
                store.getActions().formations.updateAllFormations(result.formationToUpdate);
                store.getActions().formations.setFormationById(result.formationToUpdate);
                return result.formationSessionToAdd;
            })
            .catch(error => Promise.reject(error));
    }),


    // updateFormationSession
    updateFormationSession: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateFormationSessionUrl = ACTIONS?.formations?.editProps?.formationSessions?.update?.url
            .replace('uniqueId', payload.formationSessionUniqueId);
        const { formData } = payload;
        return axios.post(updateFormationSessionUrl, formData)
            .then((result) => {
                actions.updateAllSessionsByFormationId(result.formationSessionToUpdate);
                actions.setFormationSessionById(result.formationSessionToUpdate);
                return result.formationSessionToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),


    deleteFormationSession: thunk((actions, payload, helpers) => {
        const uniqueId = payload.uniqueId;
        const formationUniqueId = payload.formationUniqueId;
        const { updateAllFormations, setFormationById } = helpers.getStoreActions().formations;
        const { updateMultipleUsersInStore } = helpers.getStoreActions().users;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const formData = new FormData();
        formData.set(`${ACTIONS?.formations?.editProps?.formationSessions?.delete?.formName}[_token]`,
            ACTIONS?.formations?.editProps?.formationSessions?.delete?.csrfToken);
        const deleteFormationUrl = ACTIONS?.formations?.editProps?.formationSessions?.delete?.url
            ?.replace('uniqueId', uniqueId).replace('formationUniqueId', formationUniqueId);

        return axios.post(deleteFormationUrl, formData)
            .then((result) => {
                actions.setFormationSessionById(formationSessionsInitialState.formationSessionById);
                actions.removeFormationSession(uniqueId);
                updateAllFormations(result.formationToUpdate);
                setFormationById(result.formationToUpdate);
                updateMultipleUsersInStore(result.usersToUpdate);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),


    registerUserToFormationSession: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const userRegistrationToFormationSessionUrl = ACTIONS?.formations?.editProps?.formationSessions?.userRegistration?.url;

        const data = JSON.stringify(payload);
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(userRegistrationToFormationSessionUrl, data, options)
            .then((result) => {
                actions.updateAllSessionsByFormationId(result.formationSessionToUpdate);
                actions.setFormationSessionById(result.formationSessionToUpdate);
                store.getActions().formations.updateAllFormations(result.formationToUpdate);
                return result.formationSessionToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),


    unregisterUserToFormationSession: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const userUnregistrationToFormationSessionUrl = ACTIONS?.formations?.editProps?.formationSessions?.userUnregistration?.url;

        const data = JSON.stringify(payload);
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(userUnregistrationToFormationSessionUrl, data, options)
            .then((result) => {
                actions.updateAllSessionsByFormationId(result.formationSessionToUpdate);
                actions.setFormationSessionById(result.formationSessionToUpdate);
                store.getActions().formations.updateAllFormations(result.formationToUpdate);
                return result.formationSessionToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),

    participateUserToFormationSession: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const userParticipationToFormationSessionUrl = ACTIONS?.formations?.editProps?.formationSessions?.userParticipation?.url;

        const data = JSON.stringify(payload);
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(userParticipationToFormationSessionUrl, data, options)
            .then((result) => {
                if (result) {
                    if (result.formationSessionToUpdate) {
                        actions.updateAllSessionsByFormationId(result.formationSessionToUpdate);
                        actions.setFormationSessionById(result.formationSessionToUpdate);
                    }
                    if(result.clientsFormationPropToUpdate){
                        store.getActions().clients.updateClientsProps(result.clientsFormationPropToUpdate);
                    }
                    return result;
                }
            })
            .catch(error => Promise.reject(error));
    })
};

export default formationSessions;