import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Alert, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import PropTypes from 'prop-types';

import TransversalPropsTableComponent from "../../components/romes/TransversalPropsTableComponent.jsx";

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import { useStoreActions, useStoreState } from "easy-peasy";
import useBackButton from "../../hooks/useBackButton.jsx";
import TransversalPropsBoardComponent from "../../components/romes/TransversalPropsBoardComponent.jsx";

const TransversalPropsPage = ({ transversalProp, displayCodeColumn = false }) => {

    const { allAreasOfInterest, allWorkingConditions, allSkills, allLargeProfessionalFields,
        isFetchingAllAreasOfInterest, isFetchingAllWorkingConditions, isFetchingAllSkills, isFetchingAllLargeProfessionalFields } = useCustomGetStoreState('romes');
    const { fetchAllAreasOfInterest, fetchAllWorkingConditions, fetchAllSkills, fetchAllLargeProfessionalFields } = useStoreActions(actions => actions.romes);

    const [allTransversalProps, setAllTransversalProps] = useState([]);
    const viewStylePreference = useStoreState(state => state.preferences.professionalsListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setProfessionalsListStyle);

    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés', delay: 400})
    
    const { backButtonComponent } = useBackButton();

    const pageTile = (transversalProp) => {
        switch (transversalProp) {
            case 'areasOfInterest':
                return "Centres d'intérêt";
            case 'workingConditions':
                return 'Contextes de travail';
            case 'skills':
                return 'Savoir-être professionnels';
            case 'largeProfessionalFields':
                return 'Domaines professionnels';
            default:
                return 'Non défini';
        }
    };

    useEffect(() =>{
        switch(transversalProp) {
            case 'areasOfInterest':
                if (allAreasOfInterest.length === 0 && !isFetchingAllAreasOfInterest) {
                    fetchAllAreasOfInterest()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allAreasOfInterest);
                setAllTransversalProps(allAreasOfInterest);
                break;
            case 'workingConditions':
                if (allWorkingConditions.length === 0 && !isFetchingAllWorkingConditions) {
                    fetchAllWorkingConditions()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allWorkingConditions);
                break;
            case 'skills':
                if (allSkills.length === 0 && !isFetchingAllSkills) {
                    fetchAllSkills()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allSkills);
                break;
            case 'largeProfessionalFields':
                if (allLargeProfessionalFields.length === 0 && !isFetchingAllLargeProfessionalFields) {
                    fetchAllLargeProfessionalFields()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allLargeProfessionalFields);
                break;
        }
    }, [allAreasOfInterest, allWorkingConditions, allSkills, allLargeProfessionalFields]);

    const filteredTransversalProps = useMemo(() => {
        if(!search) return allTransversalProps;
        else {
            const filterTransversalProps = (search) => {
                if (search === '') {
                    return allTransversalProps
                }
                return allTransversalProps.filter(transversalProp => HelperMetiers360.isSearchInText(search, transversalProp.codeRome)
                    || HelperMetiers360.isSearchInText(search, transversalProp.label)
                    || displayCodeColumn && HelperMetiers360.isSearchInText(search, transversalProp.code))
            }
            return [...filterTransversalProps(search)];
        }
    }, [search, allTransversalProps]);

    const viewStyleType = [
        {
            value: 'cards',
            label: <i className="fas fa-address-card"></i>,
            view: <TransversalPropsBoardComponent transversalProps={filteredTransversalProps} transversalProp={transversalProp} />
        },
        {
            value: 'table',
            label: <i className="fas fa-table"></i>,
            view: <TransversalPropsTableComponent transversalProps={filteredTransversalProps} transversalProp={transversalProp} displayCodeColumn={displayCodeColumn} />
        },
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    const isNoRome = allTransversalProps.length === 0
    const isNoResult = !isFetchingAllAreasOfInterest && !isFetchingAllWorkingConditions && !isFetchingAllSkills && allTransversalProps.length && filteredTransversalProps.length === 0;
    
    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    {pageTile(transversalProp)}
                    {backButtonComponent}
                </h2>
            </Row>
            <Row className="item-page">
                <Col>
                    {searchInput}
                </Col>
                <Col className="col-auto">
                    <ToggleButtonGroup type="radio" name="option-view-transversal" value={viewStyle} onChange={handleChange}>
                        {checkViewStyle}
                    </ToggleButtonGroup>
                </Col>
            </Row>
            <Row>
                {isFetchingAllAreasOfInterest || isFetchingAllWorkingConditions || isFetchingAllSkills || isFetchingAllLargeProfessionalFields
                    ? <Loader />
                    : <>
                        {filteredTransversalProps.length > 0 && viewStyleType.filter(type => type.value === viewStyle)[0].view}
                        {isNoRome && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun {HelperMetiers360.transversalPropLabel(transversalProp)}</Alert>}
                        {isNoResult && <Alert className="mt-3" variant="warning">Aucun {HelperMetiers360.transversalPropLabel(transversalProp)} ne correspond à votre recherche</Alert>}
                    </>}
            </Row>
        </Container>
    )
}

TransversalPropsPage.propTypes = {
    transversalProp: PropTypes.string.isRequired,
    displayCodeColumn: PropTypes.bool
};

export default TransversalPropsPage;