import { Alert } from "react-bootstrap";
import CardGroupMediaComponent from "../CardGroupMedia/CardGroupMediaComponent";
import DragableItemComponent from "../draggableList/DragableItemComponent";

/**
 * @param {bool}  draggable 
 * @param {string} mediaType media type videos|quiz|groups|modules
 * @param {callback} setList 
 * @param {callback} onReorderList if draggable true, callback to rearrange list in the new order 
 * @param {callback} onClickItem if draggalble false, the list will be a cardGroup and callback contains the logic to use when we click a card
 */

const SelectedMediaListComponent = ({draggable, mediaType, mediaList, setList,  onClickItem, onReorderList}) => {
    return (
        <>
            <Alert variant="success">
                {
                    draggable ? 
                    <DragableItemComponent list={mediaList} setList={setList} onClickItem={onClickItem} onReorderList={onReorderList}/>
                    :  <CardGroupMediaComponent mediaType={mediaType} mediaList={mediaList} onClickItem={onClickItem} isLink={false} />

                }
            </Alert>
        </>
    )

}

export default SelectedMediaListComponent;
