import React, { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import PropTypes from 'prop-types';

import Loader from "../../../../widgets/Loader";
import InteractionsListComponent from "../../../../interactions/InteractionsListComponent";
import FormInteractionComponent from "../../../../interactions/formInteraction/FormInteractionComponent";
import './ClientInteractionsTabComponent.scss'
import InteractionsTabFiltersComponent from "../../../../interactions/interactionsFilters/InteractionsTabFiltersComponent";

/**
 * This function renders the interactions tab of the client page
 * @returns A component that renders the ClientInteractionsTabComponent
 */
const ClientInteractionsTabComponent = (props) => {
    const { clientId } = props;

    const { interactionsByClientId, isFetchingInteractionsByClientId } = useStoreState(state => state.interactions);
    const { fetchInteractionsByClientId } = useStoreActions(actions => actions.interactions);
    
    // null: form closed, 'add': form open in creation, 'edit': form open in edition
    const [openFormType, setOpenFormType] = useState(null);
    const [interactionToEdit, setInteractionToEdit] = useState(null);
    const [interactionsFiltered, setInteractionsFiltered] = useState([]);
    const [areFiltersApplied, setAreFiltersApplied] = useState(false);

    useEffect(() => {
        fetchInteractionsByClientId(clientId)
            .then(interactions => setInteractionsFiltered(interactions));
    }, [])

    const titleInteractions = (nbInteraction) => <><p className="title-tab mb-0">Suivi client&nbsp;</p>
        {nbInteraction > 0 && <span>({ nbInteraction })</span>}</>;

    return <>{ isFetchingInteractionsByClientId
        ? <Loader />
        : <Row>
            <div id={ openFormType ? 'interactions-tab-with-form' : 'interactions-tab-alone'}>
                <Row className='d-flex justify-content-between align-items-center mb-3'>
                    <Col className="d-flex align-items-center">
                        {titleInteractions(interactionsFiltered.length)}
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="success" 
                            onClick={() => {setOpenFormType('add'); setInteractionToEdit(null);}}>
                            <i className="fas fa-plus"></i>&nbsp;Ajouter
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <InteractionsTabFiltersComponent allInteractions={interactionsByClientId} setInteractionsFiltered={setInteractionsFiltered}
                        areFiltersApplied={areFiltersApplied} setAreFiltersApplied={setAreFiltersApplied} />
                </Row>
                <InteractionsListComponent interactions={interactionsFiltered}
                    setOpenFormType={setOpenFormType} setInteractionToEdit={setInteractionToEdit} areFiltersApplied={areFiltersApplied} />
            </div>
            { openFormType && 
                <div id="form-interaction">
                    <FormInteractionComponent clientId={clientId} openFormType={openFormType}
                        setOpenFormType={setOpenFormType} interactionToEdit={interactionToEdit} />
                </div>
            }
        </Row>
    }</>
}

ClientInteractionsTabComponent.propTypes = {
    clientId: PropTypes.string.isRequired
};

export default ClientInteractionsTabComponent;
