import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Row, Col, Form } from "react-bootstrap";
import HelperMetiers360 from "../../../../../services/HelpersMetiers360";

const SelectProviderComponent = ({providerRef, commonProviderInfos, triggerUpdateCommonInfos}) => {
    const { allProviders } = useStoreState(state => state.orders);
    const { fetchProviders } = useStoreActions(actions => actions.orders);
    const [provider, setProvider] = useState(null);

    useEffect(() => {
        allProviders.length == 0 && fetchProviders();
        setProvider(providerRef.current);
    },[]);


    useEffect(() => {
        if(commonProviderInfos !== undefined){
            setProvider(commonProviderInfos);
        }
    },[triggerUpdateCommonInfos]);


    const onChangeProvider = (providerValue) => {
        providerRef.current = providerValue; 
        setProvider(providerValue)
    };

    
    const providerChoice = allProviders?.map(provider => <option key={'provider_'+HelperMetiers360.generateUniqueId()+provider.uniqueId}   value={provider.uniqueId}>{provider.name}</option>)

    return <Row className="mt-4">
        <Col>
            <h3>Prestataire</h3>
            <Form.Select size="sm" aria-label="Choix du prestataire" 
                onChange={e => { onChangeProvider(e.target.value)}}
                value={provider || ""}
                >
                {<option value="" >Choisissez un prestataire</option>}
                {providerChoice}
            </Form.Select>
        </Col>
    </Row>;
}
export default SelectProviderComponent;