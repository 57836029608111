import './Jumbotron.scss'

const Jumbotron = ({style, children}) => {
    return (
        <div className="jumbotron" style={style}>
            {children}
        </div>
    )
}

export default Jumbotron;