import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import { Alert } from "react-bootstrap";

/**
 * 
 * @param {Array} romes array of all romes to be rendered
 * @param {String} readAll  readAll slug to access the rome list page
 * @param {String} readOne  readOne slug to access a rome details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the rome creation page - optional, need according rights
 * @param {String} update  update slug to access the rome update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 
 * @example
 *  <MetiersTableComponent romes={romes} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const RomesTableComponent = ({ romes = [], withParams }) => {
    const [romeToDisplay, setRomeToDisplay] = useState([]);
    const [sortedRome, setSortedRome] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const romesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('romes'));
    const navigate = useNavigate();

    useEffect(() => {
        setShouldSort(true);
        setSortedRome([...romes]);
    }, [romes]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedRome, romeToDisplay, setRomeToDisplay, 100);

    const sortType = [
        {
            value: 'codeRome', label: 'Code ROME',
            test: HelperMetiers360.isArrayContainsValue(romes, "codeRome"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.codeRome, b.codeRome),
            display: (rome) => rome.codeRome
        },
        {
            value: 'label', label: 'Nom',
            test: HelperMetiers360.isArrayContainsValue(romes, "label"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (rome) => rome.label
        },
        {
            value: 'usagePros', label: 'Professionnel⋅les',
            test: HelperMetiers360.isArrayContainsValue(romes, "usagePros"),
            method: (a, b) => b.usagePros - a.usagePros,
            display: (rome) => rome.usagePros
        },
        {
            value: 'usageVideos', label: 'Expériences immersives',
            test: HelperMetiers360.isArrayContainsValue(romes, "usageVideos"),
            method: (a, b) => b.usageVideos - a.usageVideos,
            display: (rome) => rome.usageVideos
        },
        {
            value: 'usageStories', label: 'Stories',
            test: HelperMetiers360.isArrayContainsValue(romes, "usageStories"),
            method: (a, b) => b.usageStories - a.usageStories,
            display: (rome) => rome.usageStories
        },
        {
            value: 'riasecMajeur', label: 'Riasec majeur',
            test: HelperMetiers360.isArrayContainsValue(romes, "riasecMajeur"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(
                HelperMetiers360.riasecLabel(a.riasecMajeur),
                HelperMetiers360.riasecLabel(b.riasecMajeur)
            ),
            display: (rome) => {
                return HelperMetiers360.riasecLabel(rome.riasecMajeur);
            }
        },
        {
            value: 'riasecMineur', label: 'Riasec mineur',
            test: HelperMetiers360.isArrayContainsValue(romes, "riasecMineur"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(
                HelperMetiers360.riasecLabel(a.riasecMineur), 
                HelperMetiers360.riasecLabel(b.riasecMineur)
            ),
            display: (rome) => {
                return HelperMetiers360.riasecLabel(rome.riasecMineur);
            }
        },
    ];

    const handleClick = (codeRome) => {
        if(romesSlugs.readOne) {
            navigate(romesSlugs.readOne.replace(':uniqueId', codeRome));
        }
    }

    return romeToDisplay.length > 0
        ? <DynamicTableInfinite
        contentTable={romeToDisplay
            .map(rome => {
                return {
                    ...rome,
                    background: rome?.usagePros === 0 && rome?.usageVideos === 0 && rome?.usageStories === 0
                        ? 'repeating-linear-gradient(-45deg, #e9ecef, #e9ecef 8px, white 8px, white 20px)'
                        : null
                }
            })
        }
            contentSort={sortType}
            valueInitSort="codeRome"
            index='codeRome'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedRome}
            sortedContent={sortedRome}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
        handleClick={handleClick}
            tableName="romesTable"
            filename="codes_romes" />

        : <Alert className="mt-3" variant="warning"> Aucun code ROME ne correspond à votre recherche</Alert>;
}

export default RomesTableComponent;