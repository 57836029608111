import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios.js';

const interactionsInitialState = {
    interactionsFiltered: [],
    isFetchingInteractionsFiltered: false,
    interactionsByClientId: [],
    isFetchingInteractionsByClientId: false,
    interactionTypes: [],
    isUpdateNecessaryForInteractionTypes: true,
}

const interactions = {
    ...interactionsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    interactionsFiltered: state.interactionsFiltered,
                    isFetchingInteractionsFiltered: state.isFetchingInteractionsFiltered,
                    interactionsByClientId: state.interactionsByClientId,
                    isFetchingInteractionsByClientId: state.isFetchingInteractionsByClientId,
                    interactionTypes: state.interactionTypes,
                    isUpdateNecessaryForInteractionTypes: state.isUpdateNecessaryForInteractionTypes,
                } = interactionsInitialState);
            }
        }
    ),

    // #region setter
    setInteractionsFiltered: action((state, payload) => {
        state.interactionsFiltered = payload;
    }),
    setIsFetchingInteractionsFiltered: action((state, payload) => {
        state.isFetchingInteractionsFiltered = payload;
    }),
    setIsUpdateNecessaryForInteractionTypes: action((state, payload) => {
        state.isUpdateNecessaryForInteractionTypes = payload;
    }),
    setInteractionTypes: action((state, payload) => {
        state.interactionTypes = payload;
    }),
    // #endregion setter

    //#region Handle interactions filtered
    fetchInteractions: thunk((actions, payload, helpers) => {
        const { isFetchingInteractionsFiltered } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const { data } = payload;

        const getAllUrl = apiData.ACTIONS?.clientsCRM?.interactions?.search?.url;
        data.csrfToken = apiData.ACTIONS?.clientsCRM?.interactions?.search?.csrfToken;

        if (!isFetchingInteractionsFiltered) {
            actions.setIsFetchingInteractionsFiltered(true);
            return axios.post(getAllUrl, data)
                .then(data => {
                    actions.setInteractionsFiltered(data);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => actions.setIsFetchingInteractionsFiltered(false))
        }
        else {
            return Promise.resolve();
        }
    }),

    updateInteractionsFiltered: action((state, payload) => {
        const index = state.interactionsFiltered.findIndex((interaction) => interaction.uniqueId === payload.uniqueId);
        if(index !== -1){
            state.interactionsFiltered[index] = {
                ...payload,
            };
        }
    }),

    removeInteractionsFiltered: action((state, payload) => {
        state.interactionsFiltered = state.interactionsFiltered.filter(interaction => interaction.uniqueId !== payload);
    }),
    //#endregion Handle interactions filtered

    //#region Handle interactions for client
    addInteractionByClientId: action((state, payload) => {
        state.interactionsByClientId.push(payload);
    }),

    setInteractionsByClientId: action((state, payload) => {
        state.interactionsByClientId = payload;
    }),

    setIsFetchingInteractionsByClientId: action((state, payload) => {
        state.isFetchingInteractionsByClientId = payload;
    }),

    updateInteractionByClientId: action((state, payload) => {
        const index = state.interactionsByClientId.findIndex((interaction) => interaction.uniqueId === payload.uniqueId);
        state.interactionsByClientId[index] = {
            ...payload,
        };
    }),

    removeInteractionByClientId: action((state, payload) => {
        state.interactionsByClientId = state.interactionsByClientId.filter(interaction => interaction.uniqueId !== payload);
    }),

    fetchInteractionsByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingInteractionsByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clientsCRM?.getProps?.getInteractions?.url?.replace('uniqueId', payload);

        if (!isFetchingInteractionsByClientId) {
            actions.setIsFetchingInteractionsByClientId(true);
            actions.setInteractionsByClientId([]);
            return axios.get(getUrl)
                .then(data => {
                    actions.setInteractionsByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingInteractionsByClientId(false);
                })
        } else {
            return Promise.resolve();
        }
    }),
    //#endregion Handle interactions for client

    fetchInteractionTypes: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { isUpdateNecessaryForInteractionTypes } = helpers.getState();

        if(isUpdateNecessaryForInteractionTypes) {
            const getInteractionTypesURL = ACTIONS?.clientsCRM?.typesOfInteractions?.readAll?.url;

            return axios.get(getInteractionTypesURL)
                .then(data => {
                    actions.setInteractionTypes(data);
                    actions.setIsUpdateNecessaryForInteractionTypes(false);
                    return data;
                })
                .catch(error => Promise.reject(error))
        }
    }),

    createClientInteraction: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { updateOneClientCRM, updateClientsPropsForClientById } = helpers.getStoreActions().clients;

        const data = payload?.interaction;
        const uniqueId = payload?.uniqueId;

        const createInteractionUrl = ACTIONS?.clientsCRM?.interactions?.create?.url?.replace('uniqueId', uniqueId);
        data.csrfToken = ACTIONS?.clientsCRM?.interactions?.create?.csrfToken;

        return axios.post(createInteractionUrl, data)
            .then((result) => {
                if (result?.interactionToAdd) {
                    actions.addInteractionByClientId(result.interactionToAdd);
                }
                if(result?.clientCRMToUpdate) {
                    updateOneClientCRM(result.clientCRMToUpdate);
                    updateClientsPropsForClientById(result.clientCRMToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    updateClientInteraction: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const data = payload?.interaction;
        const uniqueId = payload?.uniqueId;

        const updateInteractionUrl = ACTIONS?.clientsCRM?.interactions?.update?.url?.replace('uniqueId', uniqueId);
        data.csrfToken = ACTIONS?.clientsCRM?.interactions?.update?.csrfToken;

        return axios.post(updateInteractionUrl, data)
            .then((result) => {
                if (result?.interactionToUpdate) {
                    actions.updateInteractionByClientId(result.interactionToUpdate);
                    actions.updateInteractionsFiltered(result.interactionToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    removeClientInteraction: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const csrfToken = ACTIONS?.clientsCRM?.interactions?.delete?.csrfToken;
        const deleteInteractionUrl = ACTIONS?.clientsCRM?.interactions?.delete?.url?.replace('uniqueId', payload?.uniqueId);
        const { updateOneClientCRM, updateClientsPropsForClientById } = helpers.getStoreActions().clients;

        return axios.post(deleteInteractionUrl, {csrfToken})
            .then((result) => {
                actions.removeInteractionByClientId(payload?.uniqueId);
                actions.removeInteractionsFiltered(payload?.uniqueId);
                if(result?.clientCRMToUpdate) {
                    updateOneClientCRM(result.clientCRMToUpdate);
                    updateClientsPropsForClientById(result.clientCRMToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error));
    }),
}

export default interactions;