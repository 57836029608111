import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import useSearchWithParams from "../../../hooks/useSearchWithParams.jsx";
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import SelectMultipleItemsComponent from "../../widgets/selectMultipleItemsComponent/SelectMultipleItemsComponent.jsx";
import Loader from "../../widgets/Loader.jsx";

const InteractionsTabFiltersComponent = ({ allInteractions, setInteractionsFiltered, areFiltersApplied, setAreFiltersApplied }) => {
    const { fetchContactsM360 } = useStoreActions(actions => actions.clients);
    const { contactsM360 } = useStoreState(state => state.clients);
    const { searchInput, search, resetSearchBar } = useSearchWithParams({
        placeholder: 'Rechercher par mots-clés (titre, description, contact ...)',
        delay: 400,
        width: '100%'
    });

    const [isFirstLoadFilters, setIsFirstLoadFilters] = useState(true);
    const [contactsM360Selected, setContactsM360Selected] = useState([]);
    const notAxonautRef = useRef(false);
    const minDateRef = useRef("");
    const maxDateRef = useRef("");
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    useEffect(() => {
        resetSearchBar();
        if (!contactsM360.length) {
            fetchContactsM360().then(() => setIsFirstLoadFilters(false));
        } else {
            setIsFirstLoadFilters(false);
        }
    }, []);

    useEffect(() => applyFilters(), [search, contactsM360Selected, allInteractions]);

    const applyFilters = () => {
        if (allInteractions?.length > 0) {
            const onlyNonAxonaut = notAxonautRef.current?.checked ?? false;
            const minDate = minDateRef.current?.value ?? null;
            const maxDate = maxDateRef.current?.value ?? null;

            const interactionsToDisplay = allInteractions
                ?.filter(interaction => {
                    const nonAxonautCondition = !onlyNonAxonaut || interaction.idAxonaut === null;
                    const minSubDateCondition = !minDate ||
                        (typeof interaction.date === 'string'
                            ? interaction.date?.split('T')[0]
                            : interaction.date
                        ) >= minDate;
                    const maxSubDateCondition = !maxDate ||
                        (typeof interaction.date === 'string'
                            ? interaction.date?.split('T')[0]
                            : interaction.date
                        ) <= maxDate;
                    const contactsRefCondition = contactsM360Selected.length === 0
                        || interaction?.contactsM360?.some(contactM360 =>
                            contactsM360Selected?.some(contactM360Selected =>
                                HelperMetiers360.isSearchInText(contactM360.name, contactM360Selected.name)));

                    return nonAxonautCondition && minSubDateCondition && maxSubDateCondition && contactsRefCondition;
                })
                ?.filter(interaction => !search ||
                    (HelperMetiers360.isSearchInText(search, interaction.title)
                        || HelperMetiers360.isSearchInText(search, interaction.content)
                        || HelperMetiers360.isSearchInText(search, interaction.contacts?.map(contact =>
                            contact?.firstName || contact?.lastName
                                ? contact?.firstName + contact.lastName
                                : contact?.email)?.join())
                        || HelperMetiers360.isSearchInText(search, interaction.contactsM360?.map(contactM360 => contactM360?.name)?.join())
                        || HelperMetiers360.isSearchInText(search, interaction.type?.name)
                    )
                );

            setAreFiltersApplied(search || onlyNonAxonaut || minDate || maxDate || contactsM360Selected.length !== 0);
            setInteractionsFiltered(interactionsToDisplay);
        }
    }

    const resetFilters = () => {
        resetSearchBar();
        if (notAxonautRef.current) notAxonautRef.current.checked = false;
        if (minDateRef.current) minDateRef.current.value = "";
        if (maxDateRef.current) maxDateRef.current.value = "";
        setContactsM360Selected([]);
        setAreFiltersApplied(false);
    }

    return <>
        <Row className="pe-0">
            <Col className="d-flex align-items-center" md={12} xl={4}>
                <Button id="btnFilters" className="cursor-pointer shadow"
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                    onKeyDown={() => setIsFiltersOpen(!isFiltersOpen)}>
                    <p className="me-3">Filtres</p>
                    {isFiltersOpen
                        ? <i className="fa fa-caret-up"></i>
                        : <i className="fa fa-caret-down"></i>
                    }
                </Button>
                {areFiltersApplied &&
                    <OverlayTrigger placement="top"
                        overlay={<Tooltip>Effacer les filtres</Tooltip>}>
                        <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { resetFilters() }}>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </OverlayTrigger>
                }
            </Col>
            <Col className="pe-0" md={12} xl={8}>
                {searchInput}
            </Col>
        </Row>
        {isFiltersOpen && <div className="bg-white my-0 mx-3 pb-3">
            <Row className="mt-3 mb-2 w-auto">
                <label className="label-select w-auto pe-0">Contacts&nbsp;M360&nbsp;: </label>
                {isFirstLoadFilters
                    ? <div id="loader-first-loading-filter"><Loader /></div>
                    : <span className="d-flex flex-column w-50">
                        <SelectMultipleItemsComponent itemsSelected={contactsM360Selected} setItemsSelected={setContactsM360Selected}
                            items={contactsM360} name="contacts" itemKey="id" itemValue="name" id='select-contactsM360' />
                    </span>
                }
            </Row>
            <Row className="justify-content-between">
                <Col className="m360-col3-start" md={12} lg={4} xl={4} xxl={4}>
                    <Form.Check id="notAxonaut" className="clickable" label="Non Axonaut"
                        onChange={applyFilters} ref={notAxonautRef} />
                </Col>
                <Col className="m360-col3-end justify-content-end" md={12} lg={6} xl={6} xxl={6}>
                    <div className="d-flex align-items-center">
                        <label className="label-select">Date&nbsp;: </label>
                        <input className="m-1" type="date" id="start" name="sub-start" max={maxDateRef.current?.value}
                            ref={minDateRef} onChange={applyFilters} />
                        <label htmlFor="end" className="m-0">au</label>
                        <input className="m-1" type="date" id="end" name="sub-end" min={minDateRef.current?.value}
                            ref={maxDateRef} onChange={applyFilters} />
                    </div>
                </Col>
            </Row>
        </div>}
    </>
}

InteractionsTabFiltersComponent.propTypes = {
    allInteractions: PropTypes.array.isRequired,
    setInteractionsFiltered: PropTypes.any.isRequired,
    areFiltersApplied: PropTypes.bool.isRequired,
    setAreFiltersApplied: PropTypes.any.isRequired
};

export default InteractionsTabFiltersComponent