import { Alert, Button } from 'react-bootstrap';
import './InteractionsListComponent.scss';
import CardInteractionComponent from './cardInteraction/CardInteractionComponent';
import { useState } from 'react';
import HelperMetiers360 from '../../services/HelpersMetiers360';
import PropTypes from 'prop-types';

const InteractionsListComponent = (props) => {
    const { interactions, setOpenFormType, setInteractionToEdit, areFiltersApplied } = props;
    const [seeAllInteractions, setSeeAllInteractions] = useState(interactions.length < 5);

    return interactions?.length > 0
        ? <div id='interactions-list-template'>
            { interactions
                .sort((interactionA, interactionB) => HelperMetiers360.compareWithNullable(interactionA.timestamp, interactionB.timestamp))
                .slice(0, seeAllInteractions ? interactions.length : 5)
                .map(interaction => <CardInteractionComponent key={interaction.uniqueId} interaction={interaction}
                    setOpenFormType={setOpenFormType} setInteractionToEdit={setInteractionToEdit} />) 
            }
            {!seeAllInteractions && !areFiltersApplied &&
                <Button variant="light" onClick={() => setSeeAllInteractions(true)}>
                    Voir toutes les interactions
                </Button> 
            }
        </div>
        : <Alert variant="danger" className="mt-2">Aucune interaction</Alert>;
}

InteractionsListComponent.propTypes = {
    interactions: PropTypes.array.isRequired,
    setOpenFormType: PropTypes.any,
    setInteractionToEdit: PropTypes.any,
    areFiltersApplied: PropTypes.bool
};
export default InteractionsListComponent;
