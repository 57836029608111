import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SelectTypeWidget from "../../widgets/SelectType/SelectTypeWidget.jsx";


const HeadsetFormCreationComponent = ({ headset, subscription }) => {
    
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);   
    const {typeOfHeadset: allHeadsetsTypes } = BACKOFFICE_DEFAULT_VALUES;
	const {typeOfHeadsetOwnership: allHeadsetOwnershipsTypes } = BACKOFFICE_DEFAULT_VALUES;

	const { csrfToken, formName } = ACTIONS['headsets']['create'];

    const { typeSelector: headsetTypeSelector } = SelectTypeWidget({
		elementType : "headsetTypeReference",
		id: "headset-type-select",
		allTypes: allHeadsetsTypes?.map((headset)=>{ return {...headset, value: headset.reference }}),
        label: 'Type de casques'
    });

	const { typeSelector: headsetOwnershipTypeSelector } = SelectTypeWidget({
		elementType : "headsetOwnershipType",
		id: "headset-ownership-select",
		allTypes: Object.entries(allHeadsetOwnershipsTypes).map((ownership)=>{ return {value: ownership[0], label: ownership[1] }}),
		defaultValue : null,
        label: 'Choisir le type de possession'
	});

    return <div>
			<Form method="post" encType="multipart/form-data" id="headset-form" className={formName}>
				
				<Row>
					<Form.Group as={Col}  >
						<Form.Label>Numéro de série du casque</Form.Label>
						<Form.Control 
							name={`headsetDeviceId`} 
							defaultValue={headset ? headset?.deviceId : ''}
						/>
						<Form.Control.Feedback type='invalid' name="feedback-name">Veuillez indiquer le numero de serie du casque</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
				<hr/>

                <Row>
					<Form.Group as={Col} >
						<Form.Label>Type de casque</Form.Label>
						{headsetTypeSelector}
						<Form.Control.Feedback type='invalid'>Veuillez indiquer le type de casquet</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<hr/>

                <Row>
					<Form.Group as={Col} >
						<Form.Label>Type de possession</Form.Label>
						{headsetOwnershipTypeSelector}
						<Form.Control.Feedback type='invalid'>Veuillez indiquer le type de possession de casquet</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<Row>
				    <Form.Group >
					<Form.Control
						name={`headsetCsrfToken`}
						value={csrfToken}
						type="hidden"
					/>
				    </Form.Group>
				</Row>

                <Row>
	                <Form.Group >
						<Form.Control
							name={`headsetSubscriptionUniqueId`}
							value={subscription.uniqueId}
							type="hidden"
						/>
						<Form.Control.Feedback type='invalid'>Veuillez donner une catégorie au client</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
                <Row>
	                <Form.Group >
	                <Form.Control
						name={`subscriptionId`}
						value={subscription.uniqueId}
						type="hidden"
					/>
					</Form.Group>
				</Row>
			</Form>
    </div>
}

export const submitHeadsetForm = ({allHeadsets, createHeadset, forceToUpdate}) => {
    let error = false;

	const listSelectedValues = (id) => {
		let valuesSelected = Array.prototype.slice.call(
			document.querySelectorAll(`#${id} option:checked`) ,0)
				.map(function(v,i,a) { 
					return v.value; 
				}
		);
		if(valuesSelected.length > 0 && valuesSelected.includes("") ){
			let emptyIndex = valuesSelected.findIndex((el)=>{
				return el === "";
			});
			valuesSelected.splice(emptyIndex, 1);
		}
		return valuesSelected;
	}

	const resetErrorDisplay = () => {
		document.getElementsByName("headsetDeviceId")[0]?.classList.remove("is-invalid");
		document.getElementById("headset-type-select")?.classList.remove("is-invalid");
		document.getElementById("headset-ownership-select").classList?.remove("is-invalid");
		document.getElementsByName("headsetDeviceId")[0].classList?.remove("is-invalid");
		document.getElementsByName(`feedback-name`)[0].innerHTML = "Veuillez indiquer le numero de serie du casque";
	}

	let formData = new FormData(document.getElementById('headset-form'));
	let headsetDeviceId = formData.get(`headsetDeviceId`);
	const listHeadsetTypeReference = listSelectedValues('headset-type-select');
	const listHeadsetOwnershipType = listSelectedValues('headset-ownership-select');

	resetErrorDisplay();

	if (!headsetDeviceId || headsetDeviceId == "") {
		document.getElementsByName("headsetDeviceId")[0].classList.add("is-invalid");
		error = true;
	}
	if (listHeadsetTypeReference.length === 0 || listHeadsetTypeReference[0] == "all") {
		document.getElementById("headset-type-select").classList.add("is-invalid");
		error = true;
	}
	if (listHeadsetOwnershipType.length === 0 || listHeadsetOwnershipType[0] == "all") {
		document.getElementById("headset-ownership-select").classList.add("is-invalid");
		error = true;
	}

	if(!error) {
		formData.append(`headsetTypeReference`, listHeadsetTypeReference[0]);
		formData.append(`headsetOwnershipType`, listHeadsetOwnershipType[0]);

		if(!forceToUpdate) {
			const existingHeadset = allHeadsets?.find((headset)=>{
				return headset?.deviceId?.toUpperCase() == headsetDeviceId.toUpperCase() 
			});

			if(existingHeadset !== undefined){
				document.getElementsByName("headsetDeviceId")[0].classList.add("is-invalid");
				document.getElementsByName(`feedback-name`)[0].innerHTML = "Ce numero existe déjà dans l'abonnement "
                    + (existingHeadset.subscriptionName ? "<b>" + existingHeadset.subscriptionName + "</b>" : "")
                    + " du client <b>" + existingHeadset.clientName + "</b>"
                    + ". Merci de le transférer si besoin en suivant la nouvelle procédure de transfert de casque : <a href=\"https://docs.google.com/document/d/1hOz9Kn5rgij2Z45NFWNJ449QJTNHi5mteTAtzdrhO_I/edit#bookmark=id.xpiz36ljwey4\" target=\"_blank\">Cliquez ici</a>";
				error = true;
			}
		}
	}

	if (error) {
		return Promise.reject(new Error());
	} else {
		return createHeadset(formData)
			.catch(() => {
				return new Error();
			});
	}
}

export default HeadsetFormCreationComponent;