import {action, actionOn, thunk} from 'easy-peasy';
import axios from '../services/axios'

const tagInitialState = {
    allTags: [],
    isFetchingAllTags: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
}

const tags = {
    ...tagInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ], 
        (state, target) => {
            const [performReset] = target.resolvedTargets;
        
            if (target.type === performReset) {
                ({
                    allTags: state.allTags,
                    isFetchingAllTags: state.isFetchingAllTags,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone
                } = tagInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
       state.initialFetchDone = payload; 
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllTags: action((state, payload) => {
        state.allTags = payload;
    }),

    setIsFetchingAllTags: action((state, payload) => {
        state.isFetchingAllTags = payload;
    }),

    fetchTags: thunk((actions, payload, helpers) => {
        const {isFetchingAllTags, isUpdateNecessary} = helpers.getState();
        const {apiData: {ACTIONS}} = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.tags?.readAll?.url;

        if (!isFetchingAllTags && isUpdateNecessary) {
            actions.setIsFetchingAllTags(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllTags(data);
                    actions.setIsFetchingAllTags(tagInitialState.isFetchingAllTags);
                    actions.setIsUpdateNecessary(tagInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => Promise.reject(error));
        } else {
            Promise.resolve();
        }
    }),
    
    // createOneTag
    postTag: thunk((actions, payload, helpers) => {
        const {apiData: {ACTIONS}} = helpers.getStoreState().actionSlugs;

        const createTagUrl = ACTIONS?.tags?.create?.url;
        const { formData } = payload;

        return axios.post(createTagUrl, formData)
        .then((result) => {
            actions.setIsUpdateNecessary(true);
            return result;
        })
        .catch(error => Promise.reject(error));
    }),

    // deleteOneTag
    deleteTag: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { removeTagFromPedagogicModules } = helpers.getStoreActions().pedagogicModules;
        const {apiData: {ACTIONS}} = helpers.getStoreState().actionSlugs;


        const formData = new FormData();
        formData.set(`${ACTIONS?.tags?.delete?.formName}[_token]`, ACTIONS?.tags?.delete?.csrftoken);
        const deleteTagUrl = ACTIONS?.tags?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.post(deleteTagUrl, formData)
            .then((result) => {
                actions.setIsUpdateNecessary(true);
                removeTagFromPedagogicModules(uniqueId);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),
};

export default tags;