import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Row, Col } from "react-bootstrap";
import useBackButton from "../../../hooks/useBackButton";
import Loader from "../../../components/widgets/Loader";
import UpdateOrderComponent from "../../../components/orders/preparatoryOrders/Update/UpdateOrderComponent";


const UpdatePreparatoryOrder = () => {
    const orderSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('orders'));
    const { orderById } = useStoreState(state => state.orders);
    const { fetchOrderById, isFetchingOneOrder } = useStoreActions(actions => actions.orders);

    const { uniqueId } = useParams();

    useEffect(() => {
        fetchOrderById(uniqueId)
    }, [uniqueId]);

    const { backButtonComponent } = useBackButton()

    return (
        <div className="container p-2">
            <Row className="mb-3">
                <Col className="d-flex justify-content-between align-items-center">
                    <h2>Edition d'un bon de préparation</h2>
                    {backButtonComponent}
                </Col>
            </Row>
            {
                (!orderById || isFetchingOneOrder)
                    ? <Loader />
                    : <UpdateOrderComponent
                        orderToUpdate={orderById}
                        action="update"
                    />
            }
        </div>
    );

}

export default UpdatePreparatoryOrder;