import React, {useRef, useEffect} from "react";
import { Form } from "react-bootstrap";

/* This code defines a functional component called `RowCSVDataComponent` that takes in props
(`csvContent`, `idx`, `dataStructure`, and `setDataStructure`). */
const RowCSVDataComponent = (props) => {
    const {csvContent, idx, dataStructure, setDataStructure} = props;

    const thisRow = useRef(null);

    const selectIdx = () => {
        const newIdx = thisRow.current?.options[thisRow.current.selectedIndex].value;
        const newDataStructure = [...dataStructure];
        newDataStructure[idx] = {...dataStructure[idx], csvIdx:newIdx};
        setDataStructure(newDataStructure);
    };

    useEffect(() => {
        selectIdx();
    }, []);
    
    if(!csvContent && !dataStructure[idx]) {return null;}
    return <tr>
        <td className="align-middle">{dataStructure[idx].label}&nbsp;:</td>
        <td className="align-middle">
            <Form.Select ref={thisRow} onChange={selectIdx} defaultValue={dataStructure[idx].csvIdx} size="sm">
                <option>--</option>
                {csvContent?.meta.fields.map(field => <option key={dataStructure[idx].field.concat(field)} value={field}>{field}</option>)}
            </Form.Select></td>
        {csvContent.data.length > 0 && 
            <td className="align-middle">{dataStructure[idx].csvIdx
                && dataStructure[idx].callBack !== undefined
                    ? dataStructure[idx].callBack(csvContent.data[0][dataStructure[idx].csvIdx])
                    : csvContent.data[0][dataStructure[idx].csvIdx]
                }</td>
        }
    </tr>;
}

export default RowCSVDataComponent