import React, { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import PropTypes from 'prop-types';

import Loader from "../../../../widgets/Loader";
import InteractionsTableComponent from "../../../../interactions/InteractionsTableComponent";
import withURLParamsTableComponent from "../../../../../HOC/withURLParamsTableComponent";

const InteractionsTableComponentWithParams = withURLParamsTableComponent(InteractionsTableComponent);

/**
 * This function renders the interactions of the client CRM detailed modale
 * @returns A component that renders the ClientCRMInteractionsComponent
 */
const ClientCRMInteractionsComponent = (props) => {
    const { clientId, setInteractionsCount } = props;

    const { interactionsByClientId, isFetchingInteractionsByClientId } = useStoreState(state => state.interactions);
    const { fetchContactsByClientId } = useStoreActions(actions => actions.clients);
    const { fetchInteractionsByClientId } = useStoreActions(actions => actions.interactions);

    useEffect(() => {
        fetchInteractionsByClientId(clientId);
        fetchContactsByClientId(clientId);
    }, [])

    useEffect(() => {
        setInteractionsCount(interactionsByClientId.length)
    }, [interactionsByClientId])

    return isFetchingInteractionsByClientId
        ? <Loader />
        : <InteractionsTableComponent 
            clientId={clientId}
            interactions={ interactionsByClientId } 
            displayAddButton={true} />
}

ClientCRMInteractionsComponent.propTypes = {
    clientId: PropTypes.string.isRequired,
    setInteractionsCount: PropTypes.any.isRequired
};

export default ClientCRMInteractionsComponent;
