const useFreshworkWidget = () => {
    const init = () => {
        window.fwSettings={
            'widget_id':101000007344
        };
        
        if("function"!=typeof window.FreshworksWidget){
            const n=function(){n.q.push(arguments)};
            n.q=[];
            window.FreshworksWidget=n;
        }

        const script = document.createElement('script');
        script.src = 'https://euc-widget.freshworks.com/widgets/101000007344.js';
        script.type = 'text/javascript';
        document.body.appendChild(script);
    }

    const WindowFreshworksWidget = () => {
        if("function"!=typeof window.FreshworksWidget) {
            init();
        }
        return window.FreshworksWidget;
    }

    function openWidget() {
        if("function"!=typeof window.FreshworksWidget) {
            init();
        }
        window.FreshworksWidget('open');
    }
    
    function openWidgetContactForm() {
        if("function"!=typeof window.FreshworksWidget) {
            init();
        }
        window.FreshworksWidget('open','ticketForm');
    }
    
    function hideWidget() {
        if("function"!=typeof window.FreshworksWidget) {
            init();
        }
        window.FreshworksWidget('hide', 'launcher');
    }

    function showWidget() {
        if("function"!=typeof window.FreshworksWidget) {
            init();
        }
        window.FreshworksWidget('show', 'launcher');
    }
    
    function openWidgetSolutionarticle(articleId) {
        if("function"!=typeof window.FreshworksWidget) {
            init();
        }
        window.FreshworksWidget('open', 'article', {
            id: articleId // article ID
          });
    }
    
    return  {init, WindowFreshworksWidget, openWidget, openWidgetContactForm, hideWidget, showWidget, openWidgetSolutionarticle}
}

export default useFreshworkWidget;