import React, {useState, useEffect} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from "react-router-dom";

import { Row, Col, Container, Card } from "react-bootstrap";

import Loader from '../../components/widgets/Loader.jsx';

import QuizDetailsComponent from '../../components/quizzes/quizDetails/QuizDetailsComponent.jsx';
import useBackButton from '../../hooks/useBackButton.jsx';

import "./QuizDetailsPage.scss";
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import AuthorizationChecker from '../../services/AuthorizationChecker.js';
import QuizPlayComponent from '../../components/quizzes/quizDetails/quizPlay/QuizPlayComponent.jsx';

const QuizDetailsPage = () => {
    const {uniqueId} = useParams();

    // Story state variables + actions
    const { quizById } = useStoreState(state => state.quizzes);
    const {fetchQuizById } = useStoreActions(actions => actions.quizzes);

    const { allPedagogicModules, isFetchingAllPedagogicModules} = useCustomGetStoreState("pedagogicModules");
    const [ quizPedagogicModules, setQuizPedagogicModules ] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=> {
        if (!isLoading) {
            setIsLoading(true)
            fetchQuizById(uniqueId)
                .finally(() => {
                    setIsLoading(false);
            })
        }
    }, [])

    useEffect(() => {
        if (quizById) {
            setQuizPedagogicModules(allPedagogicModules
                .filter(pedagogicModule => (
                    pedagogicModule.quizzes
                .some(quiz => quiz.uniqueId === quizById.uniqueId)
                )))
        }
    }, [allPedagogicModules, quizById])

    const { backButtonComponent } = useBackButton();

    const displayQuiz = AuthorizationChecker.hasUpdateRights('quizzes') ?
        <QuizDetailsComponent 
            quiz={quizById}
            quizPedagogicModules={quizPedagogicModules}
            isFetchingAllPedagogicModules={isFetchingAllPedagogicModules}
        /> 
        : <Container  fluid>
            <Row>
                <Col>
                    <QuizPlayComponent quiz = {quizById} />
                </Col>
            </Row>
        </Container>;

    return (
        <Container fluid>
            <Row>
                <Col className="thumbnail_header_item">
                    
                    {backButtonComponent}                    
                    {
                        (quizById && quizById.links && quizById.links.thumbnail) &&
                                <Card.Img src={quizById.links.thumbnail} /> 
                    }
                </Col>
            </Row>
                <Row className="d-flex flex-column">
                    
                { 
                    (isLoading || !quizById ) ?
                        <Loader />
                        :displayQuiz
                }
            </Row>
        </Container>
    )
    
}

export default QuizDetailsPage;