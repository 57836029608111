import React, { useState, useEffect, useRef } from 'react';
import { Badge, Row, Col, Button } from 'react-bootstrap';
import { useStoreActions } from 'easy-peasy';
import Loader from '../Loader.jsx';
import CustomSelectMultipleComponent from  "../../widgets/CustomSelectComponent/CustomSelectMultipleComponent.jsx"
import useDebounceSearch from "../../../hooks/useDebounchSearch";
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';
import SelectParentClientComponent from "../selectParentClient/SelectParentClientComponent.jsx";
import SelectTagOperationsComponent from "../SelectTagOperationsComponent/SelectTagOperationsComponent.jsx";
import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

import "./selectMultipleClientsComponent.scss";
import AuthorizationChecker from '../../../services/AuthorizationChecker.js';

/**
 * Select Client Code Component
 *
 * @version 2.0.0
 * @param {string} nameSelect  name of the form control
 * @param {array} selectedClients  array that will store the selected clients
 * @param {function} setSelectedClients  callback function
 * @param {string} selectId - unique Id for the select in case we use this component more than once in the same component
 * @param {string} title  optional label for the component
 * @param {array} initialClients  the initial part of the allClients you want to display (ex: only suscribed clients)
 * @param {boolean} canRemove  lock the initial selection

 * @example
 *      <SelectMultipleClientsComponent 
 *          nameSelect={nameOfSelect} 
 *          selectedClients = {ParentSelectedClientsHook} 
 *          setSelectedclients = {setParentSelectedClients} 
 *          selectId={selectId} />
 */

const SelectMultipleClientsComponent = (props) => {
    const {selectId = 'clientSelect', nameSelect = "clients", selectedClients, setSelectedClients, title,
        initialClients = null, canRemove = true, selectedUniqueIdParentClientDefault = null } = props;

    const { allClients, isAllClientPropsAreMerged } = useCustomGetStoreState("clients");
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);
    const {searchInput, search} = useDebounceSearch({placeholder: 'Rechercher par nom', delay: 400})

    const parentClientSelectorRef=useRef();
    const tagOperationsSelectorRef=useRef();

    const [clients, setClients] = useState();
    const [clientsOptions, setClientsOptions] = useState([]);
    const [initialSelection, setInitialSelection] = useState(canRemove ? [] : selectedClients);
    const [clientPropsFetched, setClientPropsFetched] = useState(false);

    useEffect(() => {
        if(!isAllClientPropsAreMerged){
            fetchAllPropsForAllClients({
                hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
        }
    }, []);

    useEffect(() => {
        if(!clientPropsFetched && HelperMetiers360.isArrayContainsValue(clientsOptions, "tagOperations"))
            setClientPropsFetched(true)
    }, [clientsOptions])

    useEffect(() => {
        if(selectedUniqueIdParentClientDefault && parentClientSelectorRef.current) {
            parentClientSelectorRef.current.value = selectedUniqueIdParentClientDefault;
            selectClients();
        }
    }, [parentClientSelectorRef.current]);

    useEffect(() => {
        if(initialClients) {
            setClients([...initialClients]);
        }
        if (initialClients && selectedClients?.length > 0) {
            const initialClientsIds = new Set(initialClients.map((x) => x.uniqueId));
            const filteredSelected = selectedClients.filter((selectedId) => initialClientsIds.has(selectedId));
            if (filteredSelected.length !== selectedClients.length) {
                setSelectedClients(filteredSelected);
            }
        }
    }, [initialClients]);

    useEffect(() => {
        if(!initialClients) {
            setClients(allClients.sort((a, b) => 
                a.clientName.localeCompare(b.clientName))
            );
        }
    }, [allClients]);

    useEffect(() => {
        selectClients();
    }, [clients, search]);

    const clientTokens= clients ? 
        clients
            .filter(client => selectedClients.includes(client.uniqueId))
            .map(client => 
                <Col key={`token${client.uniqueId}`}>
                    <Badge key={`token${client.uniqueId}`} variant="primary" size="sm" 
                    className={initialSelection.includes(client.uniqueId) ? 'p-2 client-badge badge-fixed' : clientsOptions?.includes(client) ? 'p-2 client-badge badge-listed' : 'p-2 client-badge'}
                    onClick={()=> setSelectedClients((prev)=> prev.filter(x=> initialSelection.includes(client.uniqueId) ? true : x !== client.uniqueId))}>
                        {client.clientName}
                    </Badge>
                </Col>
            )
        :null;

        const selectClients = () => {
            if (!clients) {
                setClientsOptions([]);
                return;
            }
            const filteredAndSortedClients = clients
                .filter(client => {
                    const parentCondition = !parentClientSelectorRef.current?.value || client.parentUniqueId === parentClientSelectorRef.current?.value || 
                    (parentClientSelectorRef.current?.value === "aucun" && !client.parentUniqueId);
                    const tagCondition = !tagOperationsSelectorRef.current?.value || client.tagOperationsName?.includes(tagOperationsSelectorRef.current?.value) || (tagOperationsSelectorRef.current?.value === "aucun" && !client.tagOperationsName?.length);
                    return HelperMetiers360.isSearchInText(search, client.clientName) && parentCondition && tagCondition;
                })
                .sort((a, b) => a.clientName.localeCompare(b.clientName));
            setClientsOptions(filteredAndSortedClients);
        };
        
    
    const handleAllListedListedClients = (e) => {
        if (e) {
            const selectedIdList = clientsOptions.map(client => client.uniqueId);
            setSelectedClients(prevSelectedClients => {
                const mergedSet = new Set([...prevSelectedClients, ...selectedIdList]);
                return [...mergedSet];
            });
        } else {
            const selectedIdList = clientsOptions?.map(client => client.uniqueId);
            setSelectedClients(prevSelectedClients => prevSelectedClients.filter(client => !selectedIdList?.includes(client) || initialSelection.includes(client)));
        }
    }

    const ParentClientSelector = allClients?.length ? 
    <div className="select-box"> <div className='parent-tag-list w-100'><span className='label col-6'>Enfants de :</span>
    <SelectParentClientComponent clients={allClients} parentClientSelectorRef={parentClientSelectorRef} onChange={selectClients}/>
    </div>
    </div>
    :null;

    const tagOperationsSelector = SelectTagOperationsComponent({})
        && <div className="select-box">
            <div className='parent-tag-list'>
                <span className='label col-6'>Opé M360 :</span>
                <SelectTagOperationsComponent tagOperationsSelectorRef={tagOperationsSelectorRef} onChange={selectClients}/>
            </div>
        </div>;

    const addCurrentClientsSelection = clientsOptions?.length > 0
        && <div className='two-buttons-side'>
            <Button variant={(clientsOptions?.length > 0 && !clientsOptions?.every((client)=>selectedClients.includes(client.uniqueId))) ?  "light" : "light disabled"} className="border border-dark btn-sm p-2" onClick={() => handleAllListedListedClients(true)}>Ajouter tous ces clients</Button>
            <Button variant={(clientsOptions?.length > 0 && clientsOptions.some((client)=> selectedClients.includes(client.uniqueId))) ?  "dark" : "dark disabled"} className="border border-light btn-sm p-2" onClick={() => handleAllListedListedClients(false)}>Retirer tous ces clients</Button>
        </div>;

    const selectControl = <>
        {title && <div className='titre'>{title}</div>}
        <div className="search-client">{searchInput}</div> 
        {!clientPropsFetched
            ? <div className="loader-tags"><Loader /></div>
            : <div className='parents-tags'>
                {ParentClientSelector}
                {tagOperationsSelector}
            </div>
        }
        {addCurrentClientsSelection}
    </>;

    const ClientsSelector = <Row className='form-client' style={{width: "100%"}}>
        {clients ? 
            <div className='select-multiple-client-widget '>
            <div className="left">
            {selectControl}
                <div className='searchblock'>
                    <CustomSelectMultipleComponent 
                        options={clientsOptions}
                        selectOptions={setSelectedClients}
                        selectedOptions={selectedClients}
                        optionLabel="clientName"
                        valueLabel="uniqueId"
                        lockedItems={initialSelection}
                    />
                { // transform the list of selected ids in different input, compatible with the current way of sending forms data
                    selectedClients.map(
                    (sel,i) => <input key={`${sel}${i}`} type="hidden" value={sel || ''} name={nameSelect} id={`${selectId}${i}`} />
                    )
                }
                </div>
            </div>

           <div className="right">
                <div className="selected-list">
                    {clientTokens}
                </div>
                <Button variant='danger' onClick={()=> setSelectedClients(initialSelection)}>Vider la sélection {selectedClients && selectedClients.length > 0 && `(${selectedClients.length})`}</Button>
            </div>
            </div>
        :<Loader />}
        </Row>;

    return (
        <>
            {ClientsSelector}
        </>
    );
}


export default SelectMultipleClientsComponent