import React, { useState, useEffect } from "react";
import { Form, Container, Row } from 'react-bootstrap';
import CardGroupMediaComponent from "../../../widgets/CardGroupMedia/CardGroupMediaComponent";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
/**
 * 
 * @param {Array} pedagogicModules array of all pedagogicModules to be rendered
 * @param {Array} sortTypePreferenceTuple Optionnal -  state and action from store to handle sort type preference
 *  [
 *      pedagogicModuleBoardSortType: value from store,
 *      setPedagogicModuleSortType: actionCreator
 *  ]
 * @param {String} readAll  readAll slug to access the pedagogicModule list page
 * @param {String} readOne  readOne slug to access a pedagogicModule details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the pedagogicModule creation page - optional, need according rights
 * @param {String} update  update slug to access the pedagogicModule update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 * @example
 *  <PedagogicModulesBoardComponent pedagogicModules={pedagogicModules} sortTypePreferenceType={[string, actionCreator]} readAll={readAllSlug} create={createSlug} {...}/>
 */

const PedagogicModulesBoardComponent = (props) => {
    const { pedagogicModules, readOne: readOneSlug} = props;

    return (
        <Container fluid>
            <Row>
                <CardGroupMediaComponent mediaType='pedagogicModule' isAdmin={AuthorizationChecker.isAdmin()} mediaList={pedagogicModules} readOne={readOneSlug} />
            </Row>
        </Container>


    );
}
export default PedagogicModulesBoardComponent;