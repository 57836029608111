import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import PropTypes from 'prop-types';
import RomesView from "./RomesView.jsx";
import RomesFilters from "./RomesFilters.jsx";
import RomesTableComponent from "../../components/romes/RomesTableComponent.jsx";

const RomesPageContent = ({ romes, isFetching }) => {

    const viewStylePreference = useStoreState(state => state.preferences.codeRomesListContent);

    const [filteredRomes, setFilteredRomes] = useState([]);
    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const viewStyleType = [
        {
            value: 'withContentM360', label: "Avec contenus M360", view: <RomesTableComponent romes={filteredRomes.filter(rome =>
                rome?.usagePros !== 0 || rome?.usageVideos !== 0 || rome?.usageStories !== 0
            )} />
        },
        { value: 'all', label: "Tous", view: <RomesTableComponent romes={filteredRomes} /> },
    ];

    return <>
        <RomesFilters
            romes={romes}
            setFilteredRomes={setFilteredRomes}
            viewStyle={viewStyle}
            setViewStyle={setViewStyle}
            viewStyleType={viewStyleType} />
        <RomesView
            romes={romes}
            isFetching={isFetching}
            filteredRomes={filteredRomes}
            view={viewStyleType?.find(type => type.value === viewStyle)?.view} />
    </>;
}

RomesPageContent.propTypes = {
    romes: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
};

export default RomesPageContent;