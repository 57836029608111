import React, { useState } from 'react';

import {Button, Col, Row} from 'react-bootstrap';
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import Loader from "../../../../components/widgets/Loader";

const FormationSessionUserActionButtonComponent = (props) => {
    const {onRegisteredConfirmed, onUnRegisteredConfirmed, typeOfActions, formationSession, userUniqueId} = props;
    const[isLoading, setIsLoading] = useState(false);
   
    const registrationActionToFormationSession = (actionType) => {
        const params = {
            formationSessionUniqueId : formationSession.uniqueId,
            userUniqueId : userUniqueId,
        };

        if(actionType === "register"){
            typeOfActions.register(params);
            onRegisteredConfirmed(true);
            onUnRegisteredConfirmed(false);
        }

        if(actionType === "unregister"){
            typeOfActions.unregister(params);
            onRegisteredConfirmed(false);
            onUnRegisteredConfirmed(true);
        }

    };

    const actionButtonsToDisplay = () => {
        if (!formationSession) return null;
        const { isSessionFinished, maxParticipants, places, isOpenedToRegistration, userIsRegisteredToSession, userHasParticipatedToSession, formation } = formationSession;
        if (isSessionFinished) {
            return <div className="mb-4 mt-2">Session terminée</div>;
        }
        if (!isOpenedToRegistration) {
            return <div className="mb-4 mt-2">Inscriptions ouvertes dès le {HelperMetiers360.getdisplayDateType(formation?.releaseDate, 'day')}</div>;
        }
        if (userIsRegisteredToSession && !userHasParticipatedToSession) {
            return <Button variant="danger" onClick={() => registrationActionToFormationSession("unregister")}>Se désinscrire</Button>;
        }
        if (((maxParticipants && places > 0) || !maxParticipants) && !userIsRegisteredToSession && !userHasParticipatedToSession) {
            return <Button variant="success" onClick={() => registrationActionToFormationSession("register")}>S'inscrire</Button>;
        }
        return null;
    };
          

    const participateToFormationSession = () => {
        const params = {
            formationSessionUniqueId : formationSession.uniqueId,
            userUniqueId : userUniqueId,
        };
        setIsLoading(true);
        typeOfActions.participate(params).then((result)=>{
            window.open(formationSession?.link)
            if(result?.removeRestrictions === "removeRestrictions"){
                window.location.reload();
            }
        }).finally(()=>{
            setIsLoading(false);
        });
    };

    return(
    <Row>
        {isLoading 
        ? <Loader/> 
        : <>
            <Col>
                {formationSession?.userCanParticipate && formationSession?.userIsRegisteredToSession && formationSession?.onGoing &&
                <div className="mb-4 mt-4">
                    <Button variant="success" onClick={() => participateToFormationSession()}>Participer</Button>
                </div>
                }
                <div className="mb-4 mt-4">
                    {actionButtonsToDisplay()}
                </div>
            </Col>
        </>
        }
    </Row>)
};

export default FormationSessionUserActionButtonComponent;