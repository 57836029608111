import React, { useEffect, useState } from 'react';

import CenteredModal from '../components/widgets/modal/Modal';
import Loader from '../components/widgets/Loader';

export const useModal = () => {
    const defaultAttributes = {
        header: "",
        content: <Loader />,
        validateButton: null,
        cancelButton: null,
        onCancel: null,
        onValidate: null,
        size: 'md',
        id: null,
        resetButton: null,
        onReset: null,
        resetDisabledReason: null,
        onClose: null
    };

    const [ modalShow, setModalShow] = useState(false);
    const [ isSending, setIsSending] = useState(false);
    const [ modalData, setModalData ] = useState(defaultAttributes);

    const onCloseModal = () => {
        setModalShow(false);
        setModalData(defaultAttributes);
        if(modalData.onClose) modalData.onClose();
    }

    useEffect(() => {
        if(!modalShow) {
            setModalData(defaultAttributes)
        }
    }, [modalShow])

    return {
        modalComponent: <CenteredModal 
            show={modalShow}
            header={modalData.header}
            content={modalData.content}
            validatebutton={modalData.validateButton}
            cancelbutton={modalData.cancelButton}
            onHide={() => {  onCloseModal(); }}
            validate={modalData.onValidate ? () => {
                const promise = modalData.onValidate();
                if (!!promise && typeof promise.then === 'function') {
                    return promise.then((data) => {
                            return data;
                        })
                        .catch(err => {
                            return err;        
                        });
                }
            } : null}
            cancel={modalData.onCancel ? modalData.onCancel : () => {  onCloseModal();}}
            size={modalData.size}
            id={modalData.id}
            resetButton={ modalData.resetButton }
            reset={ modalData.onReset ? modalData.onReset : null }
            isSending={isSending}
            resetDisabledReason={modalData.resetDisabledReason}
        />,

        modalShow,
        setModalShow,
        modalData,
        setModalData,
        setIsSending
    }
}