/**
 * 
 * @param {Object} client parent client to edit subscriptions' end dates
 * @param {Object} clients parent and children clients
 * @param {Object} subscriptionEndDateRef subscriptionEndDateRef ref to selector for new subscription end date
 * @example <MultipleSubscriptionEndDateEdition client={client} subscriptionEndDateRef={subscriptionEndDateRef} />
 */

import { Alert, Button, Col, ListGroup, ListGroupItem, Row, Spinner } from "react-bootstrap";
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable";
import SelectItemsComponent from "../../../widgets/selectItemsComponent/SelectItemsComponent";
import { useEffect, useRef, useState } from "react";
import { useStoreActions } from "easy-peasy";
import { useModal } from "../../../../hooks/useModal";
import Loader from "../../../widgets/Loader";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);


const MultipleSubscriptionEndDateEdition = ({ client, clients, subscriptionEndDateRef }) => {
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const { allTagOperations } = useCustomGetStoreState("tagOperations");
    const { updateSubscription } = useStoreActions(actions => actions.subscriptions);
    const { getClientAndChildrenSubscriptions } = useStoreActions(actions => actions.clients);

    const [partVisible, setPartVisible] = useState(1);
    const [areAllClientSubscriptionsFetched, setAreAllClientSubscriptionsFetched] = useState(false);
    const [subscriptionsList, setSubscriptionsList] = useState([]);
    const [subscriptionsListFiltered, setSubscriptionsListFiltered] = useState([]);
    const [isNewSubscriptionEndDateFilled, setIsNewSubscriptionEndDateFilled] = useState(false);
    const filterTagOperationsSelectorRef = useRef("");

    const [subscriptionsIndexesChecked, setSubscriptionsIndexesChecked] = useState([]);
    const [serverResults, setServerResults] = useState([]);
    const [currentSending, setCurrentSending] = useState(false);

    const [filterSubscriptionEndDate, setFilterSubscriptionEndDate] = useState();


    useEffect(() => {
        getClientAndChildrenSubscriptions(client.uniqueId)
            .then((result) => {
                if (result !== undefined) {
                    const subscriptions = result.flatMap(clientSub => {
                        const clientInfos = clients?.find(c => c.uniqueId === clientSub.clientUniqueId)
                        return (clientSub?.subscriptions?.map(sub => ({
                            uniqueId: sub.uniqueId,
                            clientName: clientInfos?.name,
                            tagOperations: clientInfos?.tagOperations,
                            subscriptionName: sub.subscriptionName,
                            subscriptionStartDate: sub.createdAt,
                            subscriptionEndDate: sub.subscriptionEndDate
                        })) || []);
                    }
                    );
                    setSubscriptionsList(subscriptions);
                    setSubscriptionsIndexesChecked(subscriptions.map((sub) => sub.uniqueId));
                    setSubscriptionsListFiltered(subscriptions);
                    setAreAllClientSubscriptionsFetched(true);
                }
            });
    }, []);


    useEffect(() => {
        applyFilters();
    }, [filterSubscriptionEndDate]);

    const sortType = subscriptionsListFiltered?.length > 0
        ? [
            {
                value: 'clientName', label: 'Nom de l\'établissement',
                test : HelperMetiers360.isArrayContainsValue(subscriptionsListFiltered, "clientName"),
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
                display: (subscription) => subscription.clientName
            },
            {
                value : 'tagOperations', label : 'Opé M360',
                test: HelperMetiers360.isArrayContainsValue(subscriptionsListFiltered, "tagOperations"),
                method: (a, b) => HelperMetiers360.sortStringArray(
                    a.tagOperations,
                    b.tagOperations),
                display: (subscription) => subscription.tagOperations?.length > 0 ? subscription.tagOperations.join(", ") : '-'
            },
            {
                value : 'subscriptionName', label : 'Nom d\'abonnement',
                test : true,
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.subscriptionName, b.subscriptionName),
                display: (subscription) => subscription.subscriptionName
            },
            {
                value : 'subscriptionEndDate', label : 'Dates d\'abonnement',
                test : true,
                method: (a, b) => new Date(b.subscriptionStartDate) - new Date(a.subscriptionStartDate),
                display: (subscription) => HelperMetiers360.getdisplayDateType(subscription.subscriptionStartDate, 'day')
                    + " - " + HelperMetiers360.getdisplayDateType(subscription.subscriptionEndDate, 'day')
            }
        ]
        : []

    const changeNewSubscriptionEndDate = () => {
        setIsNewSubscriptionEndDateFilled(subscriptionEndDateRef?.current?.value !== '');
    }

    const applyFilters = () => {
        const tagOperationId = filterTagOperationsSelectorRef.current?.value ?? null;
        const date = filterSubscriptionEndDate ? HelperMetiers360.formatDateForFormControl(filterSubscriptionEndDate) : null;
        const subscriptionsToDisplay = subscriptionsList.filter(subscription => {
            const dateCondition = !date || subscription.subscriptionEndDate === date;
            const tagCondition = !tagOperationId || subscription.tagOperations?.some(tag => tag == allTagOperations?.find(t => t.uniqueId == tagOperationId)?.name);
            return dateCondition && tagCondition;
        });

        setSubscriptionsListFiltered(subscriptionsToDisplay);
    }

    const showConfirmModal = () => {
        setModalData({
            ...modalData,
            header: <>Edition des dates de fin d’abonnement</>,
            content: <h5>Êtes-vous sûr⋅e de vouloir éditer les dates de fin de ces&nbsp;
                <b>
                    {subscriptionsListFiltered.filter(sub => subscriptionsIndexesChecked.includes(sub.uniqueId)).length}
                </b> 
                &nbsp;abonnements ?</h5>,
            validateButton: 'Éditer',
            cancelButton: 'Annuler',
            onValidate: () => {
                editSubscriptionEndDate();
                setModalShow(false);
            },
        });
        setModalShow(true);
    };

    const editSubscriptionEndDate = () => {
        const newSubscriptionEndDate = subscriptionEndDateRef?.current?.value;
        if(newSubscriptionEndDate) {
            editSubscriptionEndDateOneByOne(newSubscriptionEndDate);
        }
    }

    const editSubscriptionEndDateOneByOne = (newSubscriptionEndDate) => {
        setPartVisible(3);
        let updateSubscriptionEndDatePromises = [];
        setCurrentSending(true);

        subscriptionsListFiltered
            .filter(sub => subscriptionsIndexesChecked.includes(sub.uniqueId))
            .forEach(subscription => {
                updateSubscriptionEndDatePromises.push(
                    updateSubscription({data: {subscriptionEndDate: newSubscriptionEndDate}, uniqueId: subscription.uniqueId})
                        .then((res)=> {
                            let newResult = serverResults;
                            if(res) {
                                newResult.push({
                                    name: subscription.clientName + 
                                        (subscription.subscriptionName ? (" - " + subscription.subscriptionName) : ""),
                                    isOK: true,
                                    msg:'Date de fin d\'abonnement modifiée'
                                });
                            } else {
                                newResult.push({
                                    name: subscription.clientName + 
                                        (subscription.subscriptionName ? (" - " + subscription.subscriptionName) : ""),
                                    isOK: false,
                                    msg:'Erreur lors de l\'édition de la date de fin d\'abonnement'
                                });
                            }
                            setServerResults(newResult);
                        })
                        .catch((error) => {
                            let newResult = serverResults;
                            newResult.push({
                                name: subscription.clientName + 
                                    (subscription.subscriptionName ? (" - " + subscription.subscriptionName) : ""),
                                isOK: false,
                                msg:'Erreur lors de l\'édition de la date de fin d\'abonnement'
                            });
                            setServerResults(newResult);
                        })
                );
            });

        Promise.all(
            updateSubscriptionEndDatePromises
        ).then(() => {
            setCurrentSending(false);
        })
    }

    const serverResultsDisplay = serverResults?.map(res => <ListGroupItem key={HelperMetiers360.generateUniqueId()}>
        {res.name}&nbsp;
        <span className={"ms-3 text-"+ (res.isOK ? "success" : "danger") + " icon-" +
            (res.isOK ? "checkmark" : "cross")}> {res.msg}</span>
    </ListGroupItem>);

    const currentSendingDisplay = currentSending &&
        <ListGroupItem>Edition en cours <Spinner size="sm" animation="border" /></ListGroupItem>;

    const filterSubscriptionsPart1 = <>
        <div>
            <h6>Filtrez les abonnements sur lesquels vous voulez éditer les dates de fin :</h6>
            <p>(Décochez les abonnements pour lesquels il ne faut pas éditer la date de fin)</p>
        </div>
        <div className="mt-4">
            <div>
                <Row className="d-flex justify-content-between">
                    <Col className="col-7">
                        <label className="label-select">Date de fin d'abonnement actuelle&nbsp;: </label>
                        <DatePicker
                            showIcon
                            locale='fr'
                            id='start'
                            dateFormat="dd/MM/yyyy"
                            selected={filterSubscriptionEndDate}
                            onChange={(date) => setFilterSubscriptionEndDate(date)}
                            placeholderText="jj/mm/aaaa"
                        />
                    </Col>
                    <Col className="d-flex mt-2">
                        <label className="label-select">Opé M360&nbsp;: </label>
                        <SelectItemsComponent selectRef={filterTagOperationsSelectorRef} onChange={applyFilters}
                            items={allTagOperations} itemKey="uniqueId" itemValue="name" />
                    </Col>
                </Row>

                <div className="mt-3">
                    { sortType.length > 0 && subscriptionsList?.length > 0
                        ? <DynamicTable
                            withCheckBoxes={true}
                            lineIndexesChecked={subscriptionsIndexesChecked}
                            setLineIndexesChecked={setSubscriptionsIndexesChecked}
                            contentTable={subscriptionsListFiltered}
                            contentSort={sortType}
                            index='uniqueId' />
                        : <Alert variant="danger">Aucun abonnement</Alert>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Button variant="primary" 
                    disabled={subscriptionsIndexesChecked.length === 0 || subscriptionsListFiltered?.length === 0} 
                    onClick={() => setPartVisible(2)}>Suivant</Button>
            </div>
        </div>
    </>

    const editSubscriptionsDatePart2 = <>
        <div>
            <h6>Vous êtes sur le point d'éditer les dates de fin de tous ces abonnements :</h6>
        </div>
        <div className="mt-5">
            <DynamicTable
                contentTable={subscriptionsListFiltered.filter(sub => subscriptionsIndexesChecked.includes(sub.uniqueId))}
                contentSort={sortType}
                index='uniqueId' />
            <div className="mt-5 mb-5">
                <label className="label-select">Nouvelle date de fin d'abonnement&nbsp;: </label>
                <input type="date" id="start" name="sub-end-date" ref={subscriptionEndDateRef}
                    onChange={() => changeNewSubscriptionEndDate()} />
            </div> 
            <div className="d-flex justify-content-center">
                <Button variant="secondary" onClick={() => setPartVisible(1)} className="me-3">Précédent</Button>
                <Button variant="success" disabled={!isNewSubscriptionEndDateFilled} onClick={() => showConfirmModal()}>Valider</Button>
            </div>
        </div>
    </>

    const showResultsPart3 = <>
        {serverResults?.length > 0 && <ListGroup>{serverResultsDisplay}</ListGroup>}
        {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}
    </>

    const partToDisplay = () => {
        switch(partVisible) {
            case 1:
                return filterSubscriptionsPart1;
            case 2:
                return editSubscriptionsDatePart2;
            case 3:
                return showResultsPart3;
            default:
                return <></>
        }
    }

    return <div>
        { modalComponent }
        { areAllClientSubscriptionsFetched
            ? partToDisplay()
            : <Loader />
        }
    </div>
}
export default MultipleSubscriptionEndDateEdition;