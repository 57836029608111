import React, {useEffect, useRef} from 'react';
import { useStoreState } from 'easy-peasy';
import {Row, Col, Card, ListGroup, Container, Alert, Button, Image} from 'react-bootstrap';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

import AuthorizationChecker from '../../../services/AuthorizationChecker.js';

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import { Link } from "react-router-dom";

import './SequenceDetailsComponent.scss';
import Loader from '../../widgets/Loader.jsx';

/**
 * 
 * @param {Object} sequence object with all the sequence data to be displayed on the page
 * @param {String} readAll  readAll slug to access the sequence list page
 * @param {String} readOne  readOne slug to access a sequence details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the sequence creation page - optional, need according rights
 * @param {String} update  update slug to access the sequence update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights 
 * @example
 * 
 * <SequenceDetailsComponent sequence={sequence} update={updateSlug} {...}>
 */

const SequenceDetailsComponent = (props) => {
    const {sequence, sequenceStories, isFetchingStories} = props;

    const { readOne: readOneStory } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));

    const sequenceSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('sequences'));
    const clientSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));

    const privateStatusDisplay = sequence.private !== undefined;
    const privateStatus =  privateStatusDisplay &&
        sequence.private ?
        <><i className="fas fa-lock" />&nbsp; Parcours privé</>
		: <><i className="fas fa-lock-open" />&nbsp; Parcours public</>

    const freemiumStatus = AuthorizationChecker.isAdmin() && sequence?.freemium && 
        <><ListGroup.Item><i className="fab fa-creative-commons-nc-eu" />&nbsp; Cette sequence est freemium</ListGroup.Item></>

    const defaultMessageProducers = AuthorizationChecker.isAdmin() ? <ListGroup.Item><p> Pas de producteurs </p> </ListGroup.Item>:null;
    const sequenceProducers = sequence.producers?.length > 0 ?
    sequence.producers.map((producer) => (
        <ListGroup.Item as={Link} key={producer.id} action to={clientSlugs.readOne.replace(':uniqueId', producer.id)}><i className="mr-2"/> {producer.clientName}</ListGroup.Item>
        ))
        : defaultMessageProducers;

    const isUsedInStory = !!sequence.stories?.length;

    const listGroup = useRef(null);

    useEffect(() =>{
        if(listGroup.current.children.length == 0) {
            listGroup.current.remove();
        }
    },[sequence])

    return (
        <>
            <Container className="item_details">
                <Row>
                    <Col>
                        <Card className="item_details-card">

                            <Card.Body className="item_details-body">
                                {sequence?.links?.thumbnail && <Image className="item_details-thumbnail" src={sequence.links.thumbnail.default}/>}
                
                                
                                
                            <Card.Title className="item_details_card_title">{sequence && sequence.name ? sequence.name : "Détails de la séquence" }</Card.Title>
                
                            {AuthorizationChecker.hasUpdateRights('sequences') ?
                                <div className="item_details_btn_actions">
                                    <Button variant="secondary" as={Link} to={sequenceSlugs.update.replace(':uniqueId', sequence && sequence.uniqueId)}>
                                        <i className="fas fa-edit"></i> &nbsp;Éditer
                                    </Button>
                                </div>
                                :''
                            }
                
                                <ListGroup ref={listGroup}>
                                    {sequence.releaseDate != null && <ListGroup.Item>
                                            <strong>Publication :</strong> {HelperMetiers360.getdisplayDateType(sequence.releaseDate, 'day')}
                                    </ListGroup.Item>}
                                    {sequence.lastUpdated  != null && <ListGroup.Item>
                                            <strong>Mise à jour :</strong> {HelperMetiers360.getdisplayDateType(sequence.lastUpdated, 'day')}
                                    </ListGroup.Item>}
                                    {sequence.position  != null && <ListGroup.Item>
                                            <strong>Position : </strong>{sequence.position}
                                    </ListGroup.Item>}
                                    {/* <ListGroup.Item >
                                    <strong>Vues : </strong> {sequence.viewCount ? sequence.viewCount:0}
                                    </ListGroup.Item> */}
                                    {privateStatusDisplay && <ListGroup.Item>
                                            <strong>Statut : </strong> {privateStatus}
                                    </ListGroup.Item>}
                                    {freemiumStatus}
                                    {sequenceProducers != null && <ListGroup.Item>
                                        <strong>Producteurs : </strong>
                                            {sequenceProducers}
                                    </ListGroup.Item>}
                                    {isUsedInStory}
                                </ListGroup>

                                <div className="item_details_description">
                                {
                                    sequence.description &&
                                        <>
                                            <Card.Title className="item_details_description_title">
                                                Description
                                            </Card.Title>

                                            <span className="text-muted" dangerouslySetInnerHTML={{ __html: sequence.description }}></span>
                                        </>
                                }
                                </div>
                                <div className="associated_card">
                                    {isUsedInStory && !isFetchingStories &&
                                        <div className="associated_card_item">
                                            <Card.Title className="associated_card_title">
                                                Stories
                                            </Card.Title>
                                        <CardGroupMediaComponent mediaType="sequence" mediaList={sequenceStories} readOne={readOneStory} />
                                        </div>
                                    }
                                    {isFetchingStories && <Loader />}
                                </div>
                                </Card.Body>
                        </Card>
                    </Col> 
                </Row>
            </Container>
        </>
    );
}

export default SequenceDetailsComponent;