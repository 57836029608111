import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { Alert } from 'react-bootstrap';

import './Video360Component.scss';

const Video360Component = ({ video }) => {
	const mediaRef = useRef(null);
	const containerRef = useRef(null);
	const textureRef = useRef();
	const rendererRef = useRef();
	const sceneRef = useRef();
	const cameraRef = useRef();
	const controlsRef = useRef();
	const timerId = useRef(null);
	const isVideoPlayedRef = useRef(false);

	const [isFullScreen, setIsFullScreen] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [isWebGLSupported, setIsWebGLSupported] = useState(true);
	const [controlsVisible, setControlsVisible] = useState(true);
	const [zoom, setZoom] = useState(100);


	const videoHeight = 500;
	const zoomMaxValue = 140;
	const zoomMinValue = 30;



	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};


	const checkWebGLSupport = () => {
		try {
            const canvas = document.createElement('canvas');
			return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
		} catch (e) {
			return false;
		}
	};

	useEffect(() => {
		if (cameraRef.current) {
			cameraRef.current.fov = zoomMaxValue - zoom;
			cameraRef.current.updateProjectionMatrix();
		}
	}, [zoom]);

	useEffect(() => {
		if (isFullScreen) {
			window.addEventListener('mousemove', handleMouseMove);
		} else {
			window.removeEventListener('mousemove', handleMouseMove);
		}

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			clearTimeout(timerId.current);
		};
	}, [isFullScreen]);


	useEffect(() => {


		if (!checkWebGLSupport()) {
			setIsWebGLSupported(false);
			return;
		}
		if (video.videoSource) {

			let videoElement;
			let texture;
			let renderer;
			let scene;
			let camera;
			let controls;
			let mesh;
			let geometry;
			let material;

			const init = async () => {
				if (rendererRef.current) return;
				videoElement = mediaRef.current = document.createElement('video');
				videoElement.src = video.videoSource;
				videoElement.crossOrigin = 'anonymous';
				videoElement.loop = true;
				videoElement.muted = false;
				videoElement.load();

				videoElement.onloadeddata = async () => {

					texture = textureRef.current = new THREE.VideoTexture(videoElement);
					geometry = new THREE.SphereGeometry();
					geometry.scale(-1, 1, 1);
					material = new THREE.MeshBasicMaterial({ map: texture });
					mesh = new THREE.Mesh(geometry, material);
					mesh.rotation.y = -Math.PI / 2;

					scene = sceneRef.current = new THREE.Scene();
					scene.add(mesh);

					camera = cameraRef.current = new THREE.PerspectiveCamera();
					camera.position.set(0, 0, 0.1);

					try {
						renderer = rendererRef.current = new THREE.WebGLRenderer();
						containerRef?.current?.prepend(renderer.domElement);
						handleResize();
					} catch (error) {
						console.error('Failed to create WebGL context:', error);
						setIsWebGLSupported(false);
						return;
					}

					if (renderer.domElement) {
						renderer.domElement.style.cursor = 'grab';
					}

					controls = controlsRef.current = new OrbitControls(camera, renderer.domElement);
					controls.enableDamping = true;

					controls.rotateSpeed = -0.5;



					const animate = () => {
						requestAnimationFrame(animate);
						controls.update();
						renderer.render(scene, camera);
					};
					animate();

					window.addEventListener('resize', handleResize);

					videoElement.ontimeupdate = () => {
						setCurrentTime(videoElement.currentTime);
						setDuration(videoElement.duration);
					};

					setDuration(mediaRef.current.duration);

				};
			};



			const handleDeviceOrientation = (event) => {
				if (!camera) return;
				const { alpha, beta, gamma } = event;
				camera.rotation.set(beta, gamma, (alpha));
				controls.update();
			};

			const handleResize = () => {
				if (!camera || !renderer || !containerRef.current) return;
				const currentContainer = containerRef.current;
				const isCurrentlyFullScreen = !!document.fullscreenElement;
				const containerHeight = isCurrentlyFullScreen ? currentContainer.clientHeight : videoHeight;
				camera.aspect = currentContainer.clientWidth / containerHeight;
				camera.updateProjectionMatrix();
				renderer.setSize(currentContainer.clientWidth, containerHeight);
				renderer.domElement.style.width = "100%";
				renderer.domElement.style.height = containerHeight;
			};



			const handleFullScreenChange = () => {
				const isFullScreen = !!document.fullscreenElement;
				setIsFullScreen(isFullScreen);
				const currentZoom = zoom;
				if (isFullScreen) {
					(currentZoom - 40) > zoomMinValue && setZoom(currentZoom - 40);

				}
				else {
					(currentZoom + 40) < zoomMaxValue && setZoom(currentZoom + 40);
				}
			};


			window.addEventListener('keydown', handleKeyboardEvent);
			window.addEventListener('deviceorientation', handleDeviceOrientation);
			document.addEventListener('fullscreenchange', handleFullScreenChange);

			const clean360Viewer = () => {
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('deviceorientation', handleDeviceOrientation);
				window.removeEventListener('fullscreenchange', handleFullScreenChange);
				window.removeEventListener('keydown', handleKeyboardEvent);
				document.removeEventListener('fullscreenchange', handleFullScreenChange);


				if (mediaRef.current) {
					mediaRef.current.pause();
					mediaRef.current.src = '';
				}

				if (rendererRef.current) {
					rendererRef.current.dispose();
				}

				if (textureRef.current) {
					textureRef.current.dispose();
				}

				if (controlsRef.current) {
					controlsRef.current.dispose();
				}

				if (sceneRef.current) {
					sceneRef.current.clear();
				}
			}

			init();


			return () => {
				clean360Viewer();
			};
		}
	}, []);



	useEffect(() => {
		const handleWheel = (event) => {
			event.preventDefault();
			setZoom((prevZoom) => {
				const delta = event.deltaY < 0 ? -1 : 1;
				let newZoom = prevZoom + delta;
				newZoom = Math.max(zoomMinValue, Math.min(newZoom, zoomMaxValue));

				if (newZoom !== prevZoom) {
					return newZoom;
				}

				return prevZoom;
			});
		};

		const container = containerRef.current;
		container?.addEventListener('wheel', handleWheel, { passive: false });

		return () => {
			container?.removeEventListener('wheel', handleWheel);
		};
	}, [zoomMinValue, zoomMaxValue]);


	const handleKeyboardEvent = (event) => {
		if (!mediaRef.current) return;

		switch (event.key) {
			case ' ':
				playPause();
				break;
			case 'ArrowRight':
				if (mediaRef.current.currentTime + 10 <= mediaRef.current.duration) {
					mediaRef.current.currentTime += 10;
				}
				break;
			case 'ArrowLeft':
				if (mediaRef.current.currentTime - 10 >= 0) {
					mediaRef.current.currentTime -= 10;
				}
				break;
			case 'f':
				handleFullScreen();
				break;
			default:
				break;
		}
	};



	const handleFullScreen = () => {
		if (containerRef.current) {
			if (!document.fullscreenElement) {
				if (containerRef.current.requestFullscreen) {
					containerRef.current.requestFullscreen();
				} else if (containerRef.current.webkitRequestFullScreen) {
					const canvas = containerRef.current.getElementsByTagName("canvas")?.[0];
					if(canvas?.webkitRequestFullScreen) {
						canvas.webkitRequestFullScreen();
					} else {
						containerRef.current.webkitRequestFullScreen();
					}
				} else if (containerRef.current.mozRequestFullScreen) {
					containerRef.current.mozRequestFullScreen();
				} else if (containerRef.current.msRequestFullscreen) {
					containerRef.current.msRequestFullscreen();
				}
			} else if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		}
	};


	const handleSeek = (e) => {
		const time = parseFloat(e.target.value);
		if (mediaRef.current) {
			mediaRef.current.currentTime = time;
		}
	};

	const handleMouseMove = () => {
		if (isFullScreen) {
			setControlsVisible(true);
			clearTimeout(timerId.current);
			timerId.current = setTimeout(() => {
				setControlsVisible(false);
			}, 3000);
		}
	};


	const playPause = () => {
		if (!isVideoPlayedRef.current) {
			playVideo();
		} else {
			pauseVideo();
		}
	};

	const playVideo = () => {
		mediaRef.current?.play();
		isVideoPlayedRef.current = true;
	}

	const pauseVideo = () => {
		mediaRef.current?.pause();
		isVideoPlayedRef.current = false;
	}


	const controlStylesFullScreen = {
		position: 'absolute',
		bottom: '10px',
		left: '10px',
		zIndex: 10,
		display: controlsVisible ? 'flex' : 'none',
		justifyContent: "space-between",
		width: "99%",
	};

	return (
		<div
			ref={containerRef}
			onMouseMove={handleMouseMove}
			onDoubleClick={handleFullScreen}
		>
			{isWebGLSupported ? (
				<div style={isFullScreen ? controlStylesFullScreen : undefined} className="controls">
					<div className="control-button">
						{(!isVideoPlayedRef.current)
							? <i className="fas fa-play clickable" onClick={() => playVideo()} /> : <i className="fas fa-pause clickable" onClick={() => pauseVideo()} />
						}
					</div>
					<input
						type="range"
						min="0"
						max={duration || 0}
						step="0.1"
						value={currentTime}
						onChange={handleSeek}
						className="time-range"
					/>
					<div className="time-display">
						<span className="hover-time">{formatTime(currentTime)}/{formatTime(duration)}</span>
					</div>
					<div className="control-button">
						{!isFullScreen ? <i className="fas fa-expand clickable" onClick={() => handleFullScreen()} /> : <i className="fas fa-compress clickable" onClick={() => handleFullScreen()} />}
					</div>
				</div>
			) : (
				<Alert className="text-center" variant="danger">Impossible de lire cette video</Alert>
			)}
		</div>
	);
};

export default Video360Component;
