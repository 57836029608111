import React, { useEffect } from 'react';
import { Container, Table, Button } from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';
import HelperMetiers360 from "../../../services/HelpersMetiers360";
import AuthorizationChecker from '../../../services/AuthorizationChecker';


import './SummaryTableComponent.scss';

const SummaryTableComponent = (props) => {
    const { clients, clientsCommonInfos, subscription, dataStructure, onValidate, onCancel } = props;
    const { allClientsCRM } = useStoreState(state => state.clients);
    const { fetchAllPropsForAllClients, isAllClientPropsAreMerged } = useStoreActions(actions => actions.clients);

    useEffect(() => {
        if (!isAllClientPropsAreMerged) {
            fetchAllPropsForAllClients({
                hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')
            });
        }
    }, []);


    const dataToBeSentTable = (clients) => {
        return clients.map((csvClient) => {
            const csvClientHasUniqueIdKey = csvClient.hasOwnProperty("uniqueId");
            const csvClientHasUniqueId = csvClientHasUniqueIdKey && csvClient["uniqueId"] !== "" && csvClient["uniqueId"] !== null;
            let clientInState;
            if (csvClientHasUniqueId) {
                clientInState = allClientsCRM.find((client) => client.uniqueId === csvClient["uniqueId"]);
            }
            return (
                <tr key={HelperMetiers360.generateUniqueId()}>
                    {dataStructure.map((data) =>
                        data.field === "categories" ? (
                            <td key={HelperMetiers360.generateUniqueId()}>
                                {clientInState && (clientInState[data.field]?.join(",") !== csvClient[data.field]?.join(",")) && (
                                    <>
                                        <div>
                                            {clientInState[data.field]?.join(",")}
                                        </div>
                                        <div>
                                            <i className="fas fa-solid fa-arrow-down  text-danger"></i>
                                        </div>
                                    </>
                                )}
                                {csvClient[data.field]?.join(",")}
                            </td>
                        ) : (
                            <td key={HelperMetiers360.generateUniqueId()}>
                                    {clientInState
                                        && (clientInState[data.field].trim().toLowerCase() != csvClient[data.field].trim().toLowerCase())
                                        && <>
                                        <div>
                                            {clientInState[data.field]}
                                        </div>
                                        {csvClient[data.field] && <div>
                                            {(!data.group || data.group !== "address")
                                                ? <i className="fas fa-solid fa-arrow-down text-danger" />
                                                : <i className="fas fa-solid fa-plus text-success" />
                                            }
                                        </div>}
                                    </>
                                    }
                                {csvClient[data.field]}
                            </td>
                        )
                    )}
                </tr>
            );
        });
    };

    const headerRows = dataStructure.map((data) => <th key={'head_' + data.field}>{data.label}</th>);

    const display = allClientsCRM ?
        <Container >
            <h3>Tout semble prêt</h3>
            Voici ce que nous allons envoyer :
            <div className="custom-tbody">
                <Table >
                    <thead>
                        <tr>
                            {headerRows}
                        </tr>
                    </thead>
                    <tbody >
                        {dataToBeSentTable(clients)}
                    </tbody>
                </Table>
            </div>


            <Button variant="success" size="sm" className="align-right" onClick={() => { onValidate(clientsCommonInfos, subscription); }}>
                <span className="icon-checkmark"></span> C'est parti !
            </Button>

        </Container> : null

    return <>
        <Button variant="primary" size="sm" className="mb-3" onClick={onCancel}><span className="icon-arrow-left"></span> Revenir</Button>
        {display}
    </>;
}

export default SummaryTableComponent