import { createStore, persist, thunk, useStoreRehydrated } from "easy-peasy";
import PropTypes from 'prop-types';

import allContent from "./allContent";
import clients from "./client";
import videos from './videos';
import groups from './groups';
import romes from './romes';
import formations from './formations';
import formationSessions from './formationSessions';
import responsive from './responsive'
import actionSlugs from './actionSlugs';
import preferences from "./preferences";
import quizzes from './quizzes';
import stories from './stories';
import sequences from './sequences';
import users from './users';
import headsets from './headsets';
import pedagogicModules from './pedagogicModules';
import webappAccesses from './webappAccesses';
import tags from "./tags";
import tagOperations from "./tagOperations";
import auth from './auth';
import playlists from "./playlists";
import breadcrumbs from "./breadcrumbs";
import navigation from "./navigation";
import params from "./params";
import subscriptions from "./subscriptions";
import orders from "./orders";
import interactions from "./interactions";
import clientStatus from "./clientStatus";
import welcomeMessage from "./welcomeMessage";
import toasts from "./toasts";

export const store = createStore({
	actionSlugs,
	allContent,
	auth,
	breadcrumbs,
	clients,
    clientStatus,
	formations,
	formationSessions,
	groups,
	headsets,
	interactions,
	navigation,
	orders,
	params,
	pedagogicModules,
	playlists,
	preferences: persist(
		preferences,
		{
			storage: 'localStorage'
		}
	),
	quizzes,
	responsive,
	romes,
	sequences,
	stories,
	subscriptions,
	tagOperations,
	tags,
    toasts,
	users,
	videos,
	webappAccesses,
	welcomeMessage,
	resetStore: {
		performReset: thunk((actions, payload, helpers) => {
		})
	}
});

export function WaitForStateRehydration({ children }) {
	const isRehydrated = useStoreRehydrated()

	return isRehydrated ? children : null;
}

WaitForStateRehydration.propTypes = {
    children: PropTypes.object
};