import React from "react";
import { useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";
import UploadQuizComponent from "../../components/quizzes/upload/UploadQuizComponent";
import useBackButton from "../../hooks/useBackButton";

const UploadQuizPage = () => {
    const quizzesSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("quizzes"));

    const { backButtonComponent } = useBackButton()

    return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'un quiz</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <UploadQuizComponent 
					action="create"
					{...quizzesSlugs}
				/>
            }
		</div>
	);
}

export default UploadQuizPage;