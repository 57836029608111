import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";

import Loader from "../../../widgets/Loader";
import GroupsDisplayComponent from "../../../groups/GroupsDisplayComponent";

const ClientGroupsTabComponent = (props) => {
    const {client} = props;

    const { isFetchingAccessibleGroupsByClientId, isFetchingAccessibleVideosByClientId,
        isFetchingAccessibleQuizzesByClientId } = useCustomGetStoreState('clients');
    const { fetchAccessibleGroupsByClientId, fetchAccessibleVideosByClientId, fetchAccessibleQuizzesByClientId } = useStoreActions(actions => actions.clients);
    const { accessibleVideosByClientId, accessibleQuizzesByClientId, accessibleGroupsByClientId } = useStoreState(state => state.clients);

    useEffect(() => {
        fetchAccessibleGroupsByClientId(client?.uniqueId);
        fetchAccessibleVideosByClientId(client?.uniqueId);
        fetchAccessibleQuizzesByClientId(client?.uniqueId);
    }, [])

    
    return isFetchingAccessibleGroupsByClientId 
        || isFetchingAccessibleVideosByClientId
        || isFetchingAccessibleQuizzesByClientId
        ? <Loader />
        : <GroupsDisplayComponent 
            groups={accessibleGroupsByClientId}
            allVideos={accessibleVideosByClientId}
            allQuizzes={accessibleQuizzesByClientId} />
}

export default ClientGroupsTabComponent;