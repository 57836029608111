import React, { useState, useEffect} from "react";
import {Col, Row, Button } from "react-bootstrap";
import MultipleOrdersFormComponent from "./MultipleOrdersFormComponent";
import { useStoreActions } from 'easy-peasy';
import SelectMultipleClientsComponent from "../../../widgets/selectClient/SelectMultipleClientsComponent";

const UpdateMultipleOrdersComponent = ({
    orderToUpdate,
    action,
    handleCreatingQuantity,
    clientsBase = null,
    setClientSelectionDone=null
}) => {
    const { fetchClientCRMById } = useStoreActions(action => action.clients);
    const [selectedClients, setSelectedClients] = useState([]);
    const [validatedClients, setValidatedClients] = useState(false);
    const [allClientsCRMById, setAllClientsCRMById] = useState([]);

    useEffect(() => {
        if(validatedClients){
            if(setClientSelectionDone) {setClientSelectionDone(true);}
            handleCreatingQuantity("multiple");
            fetchEachClientsCRM();
        }
    }, [validatedClients]);



    const fetchEachClientsCRM = ()=>{
        let client = [];
        selectedClients.map((clientCRMUniqueId)=>{
            fetchClientCRMById(clientCRMUniqueId)
            .then(response => {
                client.push(response);
                setAllClientsCRMById([...client])
            });
        });
    }


    const clientsUnselected = (
        <>
         <Row>
             <h2>Ajouter plusieurs bons de préparation</h2><br/>
             <p>Sélectionnez plusieurs clients et cliquez sur "Valider"</p>
                    <SelectMultipleClientsComponent
					nameSelect={`multiClientSelector`}
					selectedClients = {selectedClients?.length ? selectedClients:[]}
					setSelectedClients = {setSelectedClients} 
                    constraintTypes={['orders']}
                    initialClients = {clientsBase}
			        />
               <Row >
                    <Col className="mt-3 d-flex align-items-center justify-content-center">
                       <Button variant= "success" onClick={()=>{setValidatedClients(selectedClients.length > 0 ? true : false)}}>Valider</Button>
                   </Col>
               </Row>
         </Row>
         </>
    );
    const clientsSelected = selectedClients 
        && <Row>
            <MultipleOrdersFormComponent clients={allClientsCRMById} />
        </Row>;

    const creationCase = validatedClients ? clientsSelected : clientsUnselected ;
    const updateCase = <MultipleOrdersFormComponent clients={selectedClients} order={orderToUpdate} />

    return action === 'create' ? creationCase : updateCase;
}

export default UpdateMultipleOrdersComponent;