import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import './FormInteractionComponent.scss';
import SelectItemsComponent from '../../widgets/selectItemsComponent/SelectItemsComponent';
import { useEffect, useRef, useState } from 'react';
import SelectMultipleItemsComponent from '../../widgets/selectMultipleItemsComponent/SelectMultipleItemsComponent';
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Loader from '../../widgets/Loader';
import { useModal } from '../../../hooks/useModal';
import PropTypes from 'prop-types';
import { useToast } from '../../../hooks/useToast';

const FormInteractionComponent = (props) => {
    const { clientId, openFormType, setOpenFormType, interactionToEdit,
        multipleAddition = false, setInteractionToAdd } = props;

    const { fetchContactsM360, fetchContactsByClientId } = useStoreActions(actions => actions.clients);
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const { contactsM360, contactsByClientId } = useStoreState(state => state.clients);
    const { interactionTypes } = useStoreState(state => state.interactions);
    const { fetchInteractionTypes, createClientInteraction, updateClientInteraction,
        removeClientInteraction } = useStoreActions(actions => actions.interactions);
    const toast = useToast();

    const typeRef = useRef(null);
    const dateRef = useRef(null);
    const titleRef = useRef(null);
    const detailsRef = useRef(null);
    const [contactsSelected, setContactsSelected] = useState([]);
    const [contactsM360Selected, setContactsM360Selected] = useState([]);
    const [contactsListMap, setContactsListMap] = useState([]);
    const [interactionDone, setInteractionDone] = useState(interactionToEdit ? interactionToEdit.isDone : true);

    const [isLoadingListForSelect, setIsLoadingListForSelect] = useState(true);
    const [isNoteType, setIsNoteType] = useState(false);

	const [formErrors, setFormErrors] = useState({
        type: false,
        date: false,
        contacts: false,
        contactsM360: false,
        title: false,
        content: false,
    });

    useEffect(() => {
        Promise.all([
            fetchInteractionTypes(),
            fetchContactsM360(),
            clientId && fetchContactsByClientId(clientId)
        ]).then(() => {
            setIsLoadingListForSelect(false);
        });
    }, []);

    const todayDateTimeForm = () => {
        let today = new Date();
        today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
        today.setMilliseconds(null);
        today.setSeconds(null);

        return today.toISOString().slice(0, -1);
    }

    useEffect(() => {
        // Set default date time picker on today if it's creation form
        if(openFormType === 'add' && typeRef.current && dateRef.current && titleRef.current && detailsRef.current) {
            typeRef.current.value = null;
            dateRef.current.value = todayDateTimeForm();
            titleRef.current.value = null;
            detailsRef.current.value = null;
            setContactsSelected([]);
            setContactsM360Selected([]);
        } 
        // Set all values if edition form
        else if(openFormType === 'edit' && interactionToEdit
            && typeRef.current && dateRef.current && titleRef.current && detailsRef.current) {

            typeRef.current.value = interactionTypes.find(type => type.name === interactionToEdit.type.name).id;
            dateRef.current.value = HelperMetiers360.timestampToFormControlDateTime(interactionToEdit.timestamp);
            titleRef.current.value = interactionToEdit.title;
            detailsRef.current.value = interactionToEdit.content?.replaceAll('<br>', '\n');

            setContactsM360Selected(contactsM360.filter(contactM360 =>
                interactionToEdit.contactsM360?.findIndex(contactM360Edit => contactM360Edit.name === contactM360.name) !== -1
            ));
            typeSelectedChange();
        }

        // Set list of contacts
        if(clientId && contactsByClientId.length > 0) {
            const contactsMap = contactsByClientId.map((contact) => {
                return { 
                    key: contact.uniqueId,
                    value: contact.firstName !== null || contact.lastName !== null
                        ? HelperMetiers360.capitalize(contact.firstName) + " " + contact.lastName?.toUpperCase()
                        : contact.email
                }
            });

            setContactsListMap(contactsMap);
            // Set contacts selected values if edition form
            openFormType === 'edit' && interactionToEdit?.contacts?.length > 0 &&
                setContactsSelected(contactsMap.filter(contact =>
                    interactionToEdit.contacts?.findIndex(contactEdit => contactEdit.uniqueId === contact.key) !== -1
                ));
        }
    }, [isLoadingListForSelect, openFormType, interactionToEdit])

    const checkFormHasError = () => {
        setFormErrors({
            type: typeRef.current?.value === "",
            date: dateRef.current?.value === "",
            contactsM360: contactsM360Selected.length === 0,
            title: titleRef.current?.value === "",
            content: detailsRef.current?.value === "",
        })

        return typeRef.current?.value === "" || dateRef.current?.value === "" || contactsM360Selected.length === 0
            || titleRef.current?.value === "" || detailsRef.current?.value === "";
    }

    const openModalRemoveInteraction = () => {
        if(interactionToEdit?.uniqueId) {
            const validate = () => {
                removeClientInteraction({ uniqueId: interactionToEdit.uniqueId })
                    .then(() => {
                        toast.open({ 
                            message: "Interaction supprimée avec succès", 
                            variant: "success"
                        });
                        setOpenFormType(null);
                    })
            };

            setModalData({
                ...modalData,
                header: "Suppression d'une interaction",
                content: <h5>Êtes-vous sûr⋅e de vouloir supprimer cette interaction ?</h5>,  
                cancelButton: "Annuler",
                resetButton: "Supprimer",
                onReset: validate,
                id: 'remove-interaction-modal'
            });
            setModalShow(true);
        }
    }

    const submitForm = () => {
        const timestampInteraction = HelperMetiers360.displayDateTotimestamp(isNoteType
            ? todayDateTimeForm()
            : dateRef.current?.value
        );

        const newInteraction = {
            title: titleRef.current?.value,
            content: detailsRef.current?.value,
            type: typeRef.current?.value,
            contactsM360: contactsM360Selected.map((contactM360) => contactM360.id),
            contacts: contactsSelected.map((contact) => contact.key),
            timestamp: timestampInteraction,
            // If interaction with note type => No need isDone
            isDone: !dateRef.current?.parentElement?.classList?.contains("visually-hidden") 
                ? interactionDone
                : true
        }

        if(!checkFormHasError()) {
            if(!multipleAddition) {
                createOrUpdateInteraction(newInteraction);
            } else {
                setInteractionToAdd(newInteraction);
            }
        }
    }
    
    const createOrUpdateInteraction = (newInteraction) => {
            const result = openFormType === 'add'
                ? createClientInteraction({ interaction: newInteraction, uniqueId: clientId })
                : updateClientInteraction({ interaction: newInteraction, uniqueId: interactionToEdit?.uniqueId });

            result
                .then((result) => {
                    toast.open({
                        message: "Interaction " + (openFormType === 'add' ? "ajoutée" : "modifiée") + " avec succès",
                        variant: "success"
                    });
                    if(result !== undefined) setOpenFormType(null);
                })
    }

    const typeSelectedChange = () => {
        if(typeRef.current && typeRef.current?.value === "6") {
            setIsNoteType(true);
            setInteractionDone(true);
        } else {
            setIsNoteType(false);
        }
    };

    const dateChange = () => {
        // If date interaction after today : interaction not done
        if(HelperMetiers360.displayDateTotimestamp(dateRef.current?.value) > Math.round(+new Date()/1000)) {
            setInteractionDone(false);
        } else {
            setInteractionDone(true);
        }
    };

    return isLoadingListForSelect
        ? <Loader />
        : <div id='interaction-form-template' className='shadow-element'>
            {modalComponent}
            { !multipleAddition 
                && <Row>
                    <Col>
                        <button type="button" className="btn-close" aria-label="Fermer" 
                            onClick={() => setOpenFormType(null)}></button>
                    </Col>
                </Row>
            }
            <div className='mt-3'>
                <div className="input-form-interaction">
                    <label className="label-select" htmlFor='select-type'>Type&nbsp;*&nbsp;: </label>
                    <SelectItemsComponent selectRef={typeRef} items={interactionTypes}
                        hasError={formErrors.type} onChange={() => typeSelectedChange()}
                        itemKey="id" itemValue="name" width="100%" id='select-type' />
                </div>
                <div className={isNoteType ? "visually-hidden" : "input-form-interaction d-flex justify-content-between"}>
                    <div>
                        <label className="label-select" htmlFor='date'>Date prévue&nbsp;*&nbsp;: </label>
                        <input type="datetime-local" name="date" id='date-interaction' onChange={() => dateChange()}
                            className={formErrors.date ? 'error-input' : undefined} ref={dateRef} />
                    </div>
                    <div className='choices-selector-inline'>
                        <div className={interactionDone ? 'selected' : null} 
                            onClick={() => setInteractionDone(true)} onKeyDown={() => setInteractionDone(true)}>
                            ✓ Fait</div>
                        <div className={!interactionDone ? 'selected' : null}
                            onClick={() => setInteractionDone(false)} onKeyDown={() => setInteractionDone(false)}>
                            À faire</div>
                    </div>
                </div>
                { !multipleAddition 
                    && <div className="input-form-interaction">
                        <label className="label-select" htmlFor='select-contacts'>Contacts&nbsp;client&nbsp;: </label>
                        { contactsListMap.length > 0
                            ? <SelectMultipleItemsComponent itemsSelected={contactsSelected} setItemsSelected={setContactsSelected}
                                items={contactsListMap} name="contacts" itemKey="key" itemValue="value"  id='select-contacts' />
                            : <p className='no-clients'>-- Aucun contact client --</p>
                        }
                    </div>
                }
                <div className="input-form-interaction">
                        <label className="label-select" htmlFor='select-contactsM360'>Contacts&nbsp;M360&nbsp;*&nbsp;: </label>
                        <SelectMultipleItemsComponent itemsSelected={contactsM360Selected} setItemsSelected={setContactsM360Selected}
                            items={contactsM360} hasError={formErrors.contactsM360}
                            name="contactsM360" itemKey="id" itemValue="name"  id='select-contactsM360'/>
                    </div>
                <div className="input-form-interaction">
                    <Form.Control className={'mt-4 ms-0 '+(formErrors.title && 'error-input')} ref={titleRef} type='text'
                        placeholder="Titre *" />
                </div>
                <div className="input-form-interaction">
                    <textarea ref={detailsRef} placeholder="Détails *" rows={8}
                        className={'form-control ms-0 '+(formErrors.content && 'error-input')} />
                </div>
            </div>
            { Object.values(formErrors).some(error => error) &&
                <Alert variant="danger">Veuillez renseigner tous les champs nécessaires.</Alert>
            }
            <Row className={ openFormType === 'edit' ? 'justify-content-between' : 'justify-content-end'}>
                { openFormType === 'edit' 
                    && <Button variant='danger' className="w-auto d-flex me-4" onClick={() => openModalRemoveInteraction()}>
                        Supprimer
                    </Button>
                }
                <div className='d-flex justify-content-end w-auto'>
                    { !multipleAddition 
                        && <Button variant='secondary' className="w-auto d-flex me-4" onClick={() => setOpenFormType(null)}>
                            Annuler
                        </Button>
                    }
                    { !multipleAddition 
                        ? <Button variant='success' className="w-auto d-flex" onClick={() => submitForm()}>
                            <i className="fas fa-check me-2 action-interaction"></i>Valider
                        </Button>
                        : <Button variant="primary" size="sm" className="align-right" onClick={() => submitForm()}>
                            Phase suivante <span className="icon-arrow-right ms-2"></span>
                        </Button>
                    }
                </div>
            </Row>
        </div>
}

FormInteractionComponent.propTypes = {
    clientId: PropTypes.string,
    openFormType: PropTypes.string.isRequired,
    setOpenFormType: PropTypes.any.isRequired,
    interactionToEdit: PropTypes.object,
    multipleAddition: PropTypes.bool,
    setInteractionToAdd: PropTypes.any
}

export default FormInteractionComponent;
