import React, { useState, useEffect } from "react";
import {  Row, Image } from "react-bootstrap";

import DynamicTable from "../dynamicTable/DynamicTable";
import useDebounceSearch from "../../../hooks/useDebounchSearch";
import InfinitePaginationListComponent from "../InfinitePaginationList/InfinitePaginationListComponent";
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

const SelectItemComponent = (props) => {
    const { list, mediaSearchCallBack, searchTitle, onSelect, maxVisibleItem, displayType = "table", mediaType } = props;

	const listToDisplay = list ? list : [];

    const [filteredList, setFilteredList] = useState(listToDisplay.slice(0, maxVisibleItem));
    const { searchInput, search } = useDebounceSearch({ placeholder: searchTitle, delay: 400 });

    useEffect(() => {
        if (mediaSearchCallBack) {
            setFilteredList(
                [...mediaSearchCallBack(list, mediaType, search)]
            );
        } else {
            setFilteredList([
                ...list.filter((listItem) => HelperMetiers360.isSearchInText(search, listItem?.name))
            ]);
        }
    }, [search, list]);

    if (listToDisplay.length === 0) {
		return <></>;
	}

    const sortType = [
        {
            value : 'thumbnail', label : 'Vignette',
            test : listToDisplay[0].thumbnail !== undefined,
            method: null,
            display: (listItem) => <Image src={listItem.thumbnail} thumbnail />
        },
        {
            value : 'name', label : 'Nom',
            test : listToDisplay[0].name !== undefined,
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (listItem) => listItem.name
        }
    ];

    const displayOptions = {
        "table": <DynamicTable 
                    contentTable={filteredList.slice(0, maxVisibleItem)}
                    contentSort = {sortType}
                    valueInitSort = "name"
                    index = 'uniqueId'
                    handleClick={onSelect}
                    />,
        "cards": <InfinitePaginationListComponent
                    mediaType={mediaType}
                    mediaList={filteredList}
                    onClickItem={onSelect}
                    maxVisibleItem={maxVisibleItem}
                 />
    }

    return <>
        <Row className="m-2">{list.length > 1 && searchInput}</Row>
        <Row>
            { displayOptions[displayType]}
        </Row>
    </>;
}

export default SelectItemComponent;