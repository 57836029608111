import React, { useRef } from 'react';
import {Form, InputGroup, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';

const AddressFormComponent = ({ address=null, formName }) => {
    const addressNameRef = useRef(null);
    const companyNameRef = useRef(null);
    const addressStreetRef = useRef(null);
    const addressCityRef = useRef(null);
    const addressZipCodeRef = useRef(null);
    const addressRegionRef = useRef(null);
    const addressCountryRef = useRef(null);

    return (
        <div className='form-create-user'>
            <Form id={formName} onSubmit={(event) => event.preventDefault()}>
                <Row>
                    <Col>
					<InputGroup>
                        <InputGroup.Text>Intitulé :</InputGroup.Text>
						<Form.Control
                            ref={addressNameRef}
							name={`addressName`}
                            defaultValue={address ? address.addressName : ''}
						/>
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
                        <InputGroup.Text>Structure :</InputGroup.Text>
						<Form.Control
                            ref={companyNameRef}
							name={`companyName`}
                            defaultValue={address ? address.companyName : ''}
						/>
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
                        <InputGroup.Text>Voie :</InputGroup.Text>
						<Form.Control
                            as="textarea"
                            rows={5}
                            ref={addressStreetRef}
							name={`addressStreet`}
                            defaultValue={address ? address.addressStreet : ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer une voie</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
						<InputGroup.Text>Code postal :</InputGroup.Text>
						<Form.Control
                            ref={addressZipCodeRef}
							name={`addressZipCode`}
                            defaultValue={address ? address.addressZipCode : ''}
                        />
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
						<InputGroup.Text>Ville :</InputGroup.Text>
						<Form.Control
                            ref={addressCityRef}
							name={`addressCity`}
                            defaultValue={address ? address.addressCity : ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer une ville</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
					<InputGroup>
						<InputGroup.Text>Région :</InputGroup.Text>
						<Form.Control
                             ref={addressRegionRef}
							name={`addressRegion`}
                            defaultValue={address ? address.addressRegion: ''}
						/>
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
						<InputGroup.Text>Pays :</InputGroup.Text>
						<Form.Control
                            ref={addressCountryRef}
							name={`addressCountry`}
                            defaultValue={address ? address.addressCountry : 'France'}
						/>
					</InputGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    )

}

export const submitAddressForm = ({formName, action, uniqueId, updateAddressesForOrders}) => {
	const resetErrorDisplay = () => {
        document.getElementsByName("addressStreet")[0]?.classList.remove("is-invalid");
        document.getElementsByName("addressCity")[0]?.classList.remove("is-invalid");
	}

    const formData = new FormData(document.getElementById(formName));

    const newAddress = {
        addressName: formData.get("addressName"),
        companyName: formData.get("companyName"),
        addressStreet: formData.get("addressStreet"),
        addressCity: formData.get("addressCity"),
        addressZipCode: formData.get("addressZipCode"),
        addressRegion: formData.get("addressRegion"),
        addressCountry: formData.get("addressCountry"),
    }
    
    resetErrorDisplay();
    
    if(newAddress.addressStreet === "" || newAddress.addressCity === "") {
        if(newAddress.addressStreet === "") {
            document.getElementsByName("addressStreet")[0].classList.add("is-invalid");
        }
        if(newAddress.addressCity === "") {
            document.getElementsByName("addressCity")[0].classList.add("is-invalid");
        }
        return Promise.reject(new Error());
    } else {
        return action({ address: newAddress, uniqueId: uniqueId })
            .then((result) => {
                if(result){
                    if(updateAddressesForOrders){
                        updateAddressesForOrders(result);
                    }
                }
            })
    }
}

AddressFormComponent.propTypes = {
    address: PropTypes.object,
    formName: PropTypes.string.isRequired
};

export default AddressFormComponent;