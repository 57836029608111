import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { Form, Image, Accordion, Button } from 'react-bootstrap';
import { useStoreState, useStoreActions } from "easy-peasy";

import { Editor } from "@tinymce/tinymce-react";

import SelectItemGroupComponent from "./SelectItemGroupComponent.jsx";
import SelectMultipleClientsComponent from "../widgets/selectClient/SelectMultipleClientsComponent.jsx";
import ButtonLoaderComponent from "../widgets/ButtonLoaderComponent.jsx";
import Loader from "../widgets/Loader.jsx";
import SelectMediaModuleComponent from "../widgets/SelectMediaModule/SelectMediaModuleComponent.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import UploadFilesComponent from "../widgets/uploadFiles/UploadFilesComponent.jsx";
import { useToast } from "../../hooks/useToast.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

/**
 * 
 * 	CreateGroupComponent
 *  component with a form to create a new gropu or update an existing one
 * @param {callback} postGroup callback function that will POST a new video
 * @param {callback} updateGroup callback function that will update (PUT) an existing video
 * @param {Object} groupToUpdate Optionnal - Object with the infos of the group in case of an update, or null/undefined
 * @param {String} readAll  readAll slug to access the group list page
 * @param {String} readOne  readOne slug to access a group details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the group creation page - optional, need according rights
 * @param {String} update  update slug to access the group update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 * @param {Object} returnLink object with informations (link, label) for the return button
 * @example
 * 
 * <CreateGroupComponent postGroup={callback} updateGroup={callback} groupToUpdate={Object} returnLink={returnLink} readOne={readOneSlug} readAll={readAllSlug} {...}/> 
 */

const CreateGroupComponent = (props) => {

	const navigate = useNavigate();
	const { action, postGroup, updateGroup, groupToUpdate, readAll: readAllGroupsSlug, readOne: readOneGroupSlug } = props;
    const toast = useToast();

    const { apiData: { ACTIONS, BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const {fetchRemainingSpecialGroups} = useStoreActions(actions => actions.groups);

    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;

    const { allPedagogicModules, isFetchingAllPedagogicModules } = useCustomGetStoreState("pedagogicModules");

    const createdFormRef = useRef(null);

    const [isSending, setIsSending] = useState(false);

    const [publishDate, setPublishDate] = useState(groupToUpdate?.releaseDate ? new Date(groupToUpdate.releaseDate) : null);

    const [groupName, setGroupName] = useState(groupToUpdate && groupToUpdate.name ? groupToUpdate.name : '')
    const [isPrivateGroup, setIsPrivateGroup] = useState(groupToUpdate?.private ? groupToUpdate.private : false)
    const [isDirectlyAccessible, setIsDirectlyAccessible] = useState(action === 'create' ? true : (groupToUpdate?.isDirectlyAccessible ? groupToUpdate.isDirectlyAccessible : false))
    const [isInteractive, setIsInteractive] = useState(action === 'create' ? false : (groupToUpdate?.isInteractive ? groupToUpdate.isInteractive : false))
    const [isPreviewOnly, setIsPreviewOnly] = useState(action === 'create' ? false : (groupToUpdate?.isPreviewOnly ? groupToUpdate.isPreviewOnly : false))
    const [isSpecialGroupCreated, setIsSpecialGroupCreated] = useState(false);
    const [withCustomBackgroundImage, setWithCustomBackgroundImage] = useState(action === 'create' ? false : (!!groupToUpdate?.links?.background))
    const [editorContent, _setEditorContent] = useState(groupToUpdate?.description ? groupToUpdate.description : '')
    const editorContentRef = useRef(editorContent)
    const setEditorContent = data => {
        editorContentRef.current = data;
        _setEditorContent(data);
    }

    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
    }
    const [selectedClients, setSelectedClients] = useState(groupToUpdate && groupToUpdate.producers ? groupToUpdate.producers.map(producer => producer.id).map(String) : []);

    const pedagogicModuleSelected = useRef(groupToUpdate && groupToUpdate.pedagogicModules ? groupToUpdate.pedagogicModules : [])

    const [remainingSpecialGroups, setRemainingSpecialGroups] = useState([]);
    useEffect(() => {
        if(action === "create") {
            fetchRemainingSpecialGroups()
                .then((response) => {setRemainingSpecialGroups(response);});
        } 
    },[]);

    useEffect(() => {
        pedagogicModuleSelected.current = groupToUpdate?.pedagogicModules ? groupToUpdate.pedagogicModules : [];
    }, [groupToUpdate])

    const thumbnailFileInputNameDefaultValue = groupToUpdate?.links?.thumbnail ? 'Changer la vignette' : 'Choisir le fichier';
    const videoPreviewFileInputNameDefaultValue = groupToUpdate?.links?.videoPreview ? "Changer la preview vidéo" : 'Choisir le fichier';
    const backgroundFileInputNameDefaultValue = groupToUpdate?.links.background ? "Changer l'image de fond" : 'Choisir le fichier';

	const [thumbnailFileInputName, setThumbnailFileInputName] = useState(thumbnailFileInputNameDefaultValue);
	const [backgroundFileInputName, setBackgroundFileInputName] = useState(backgroundFileInputNameDefaultValue);
    const [withVideoPreview, setWithVideoPreview] = useState(action === 'create' ? false : (!!groupToUpdate?.links?.videoPreview))
    const [videoPreviewFileInputName, setVideoPreviewFileInputName] = useState(videoPreviewFileInputNameDefaultValue);

    const [selectedItems, setSelectedItems] = useState(groupToUpdate?.items ?
        groupToUpdate.items.sort((a, b) => a.displayOrder - b.displayOrder)
        : []);


    const { uploadFilesContainer, arrayOfNewFiles: arrayOfNewFiles, arrayOfFilesToEdit: arrayOfFilesToEdit, arrayOfFilesToDelete: arrayOfFilesToDelete, replaceAll: replaceAll, setFilesErrors: setFilesErrors } = UploadFilesComponent({
        forbiddenFileNames: fileContraints.FORBIDDEN_NAMES.groups,
        filesToUpdate: groupToUpdate?.files ?
            groupToUpdate?.files
                .map((fileToUpdate) => {
                    return {
                        ...fileToUpdate,
                        edit: false,
                        newFile:
                        {
                            fileName: fileToUpdate.localName.substring(0, fileToUpdate.localName.lastIndexOf('.')),
                            fileExtension: fileToUpdate.localName.split('.').pop(),
                            newFileLocalName: "",
                            file: null
                        }
                    }
                }
                )
            : null
    });

	const token = ACTIONS['groups'][action].csrfToken;

    const backgroundFileDeleteable = (backgroundFileInputName!==backgroundFileInputNameDefaultValue || groupToUpdate?.links?.background);
    const previewFileDeleteable = (videoPreviewFileInputName!==videoPreviewFileInputNameDefaultValue || groupToUpdate?.links?.videoPreview);

    const checkData = () => {
        setFilesErrors([]);

        let errorsList = [];

        createdFormRef.current = document.getElementById('groupCreateData-form');
        let formData = new FormData(createdFormRef.current);

        let thumbnail = formData.get('group[thumbnail]');
        let background = formData.get('group[background]');
        let videoPreview = formData.get('group[videoPreview]');

        for (let i = 0; i < arrayOfNewFiles.length; i++) {
            if (fileContraints.FORBIDDEN_NAMES.groups.includes(arrayOfNewFiles[i].fileName)) {
                errorsList.push('Nom de fichiers interdits : ' + fileContraints.FORBIDDEN_NAMES.groups?.join(", "));
                break;
            }
            if (arrayOfNewFiles[i].file && arrayOfNewFiles[i].fileName == "") {
                errorsList.push('Veuillez nommer vos nouveaux fichiers');
                break;
            }
        }

        if (arrayOfFilesToEdit) {
            for (let i = 0; i < arrayOfFilesToEdit.length; i++) {
                if (arrayOfFilesToEdit[i]?.edit == true) {
                    if (fileContraints.FORBIDDEN_NAMES.groups.includes(arrayOfFilesToEdit[i].newFile.fileName)) {
                        errorsList.push('Nom de fichiers interdits : ' + fileContraints.FORBIDDEN_NAMES.groups?.join(", "));
                        break;
                    }
                    if (arrayOfFilesToEdit[i].newFile.fileName == "") {
                        errorsList.push('Veuillez nommer vos fichiers à éditer');
                        break;
                    }
                }
            }
        }

        if (!formData.get('group[name]')) {
            errorsList.push('Veuillez saisir le nom du groupe');
        }

        if ((!groupToUpdate) && (!thumbnail)) {
            errorsList.push('Veuillez sélectionner une vignette');
        }


        if (thumbnail.size) {
            if (thumbnail.size > fileContraints.MAX_SIZE_IMAGE_BYTES) {
                errorsList = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(thumbnail.type)) {
                errorsList = "Format de la vignette non autorisé";
            }
        }

        if (background?.size) {
            if (background.size > fileContraints.MAX_SIZE_IMAGE_BACKGROUND_KO) {
                errorsList = "Image de fond trop volumineux";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(background.type)) {
                errorsList = "Format du fond non autorisé";
            }
        }


        if (isPreviewOnly && !groupToUpdate?.links?.videoPreview && !videoPreview?.size) {
            errorsList.push("Vous devez ajouter une preview vidéo ou décocher le fait qu'elle devra remplacer le contenu du groupe");
        }

        if (videoPreview?.size) {
            if (videoPreview.size > fileContraints.MAX_SIZE_VIDEO_KO) {
                errorsList.push("Preview vidéo trop volumineuse");
            }
            if (!fileContraints.VIDEO_MIME_TYPES.includes(videoPreview?.type)) {
                errorsList.push("Format de la preview non autorisé");
            }
        }

        if (!publishDate) {
            errorsList.push('Veuillez sélectionner une date de publication');
        }

        if (action === 'update' && !withVideoPreview) {
            formData.append("group[deleteVideoPreview]", true);
        }

        if (errorsList?.length) {
            toast.open({ message: errorsList.join(', '), variant: "danger" });
        }
        else {
            setIsSending(true);

            let toDelete = arrayOfFilesToDelete;
            if (replaceAll) {
                toDelete = arrayOfFilesToEdit.map((file) => { return file.uniqueId });
            }

            formData.append(`group[releaseDate]`, HelperMetiers360.formatDateForFormControl(publishDate));

            const simplifiedItems = selectedItems.map((item, index) => { return { uniqueId: item.uniqueId, type: item.type, displayOrder: index } });
            formData.append('items', JSON.stringify(simplifiedItems))
            formData.append("group[description]", editorContentRef.current);
            for (const pedagogicModule of pedagogicModuleSelected.current) {
                formData.append('group[pedagogicModules][]', pedagogicModule.uniqueId.toString())
            }

            if (action === 'update' && !withCustomBackgroundImage) {
                formData.append("group[deleteBackground]", true);
            }

            for (let i = 0; i < arrayOfNewFiles?.length; i++) {
                if (arrayOfNewFiles[i].file) {
                    let fileName = arrayOfNewFiles[i].fileName;
                    if (!arrayOfNewFiles[i].fileName.includes(".")) {
                        fileName = arrayOfNewFiles[i].fileName + "." + arrayOfNewFiles[i].fileExtension
                    }
                    formData.append("group[files][]", arrayOfNewFiles[i].file, fileName)
                }
            }

            for (let i = 0; i < toDelete.length; i++) {
                formData.append("group[filesToDelete][]", toDelete[i])
            }


            let filesToEditNewNames = [];

            for (let i = 0; i < arrayOfFilesToEdit?.length; i++) {
                if (arrayOfFilesToEdit[i].edit) {
                    let fileName = arrayOfFilesToEdit[i].newFile.fileName;
                    if (!arrayOfFilesToEdit[i].newFile.fileName.includes(".")) {
                        fileName = arrayOfFilesToEdit[i].newFile.fileName + "." + arrayOfFilesToEdit[i].newFile.fileExtension
                    }
                    filesToEditNewNames.push(fileName);
                    formData.append("group[filesToEdit][]", arrayOfFilesToEdit[i].uniqueId);
                    formData.append("group[filesToEditNewData][]", arrayOfFilesToEdit[i].newFile.file);
                }
            }

            if (filesToEditNewNames.length > 0) {
                formData.append('filesToEditNewNames', JSON.stringify(filesToEditNewNames));
            }

            action === 'create'
                ? postGroup({ formData })
                    .then((result) => {
                        toast.open({ message: "Création du groupe réalisée avec succès", variant: "success" });
                        navigate(readOneGroupSlug.replace(':uniqueId', result?.groupToUpdate.uniqueId),
                            {
                                replace: true,
                                state: { fromEdit: true }
                            });
                    })
                    .catch(() => {
                        setIsSending(false);
                    })
                : updateGroup({ formData, uniqueId: groupToUpdate.uniqueId })
                    .then(() => {
                        toast.open({ message: "Modification du groupe réalisée avec succès", variant: "success" });
                        navigate(readOneGroupSlug.replace(':uniqueId', groupToUpdate.uniqueId),
                            {
                                replace: true,
                                state: { fromEdit: true }
                            });
                    })
                    .catch(() => setIsSending(false))

        }
    }

    const specialGroupOptions = remainingSpecialGroups.length ?
        remainingSpecialGroups
            .map(specialGroup => <option
                key={'specialGroup'+specialGroup?.name}
                value={specialGroup.name} >
                    {'Groupe du '.concat(specialGroup.description)}
                </option>)
        :null;
    const onSelectSpecialGroup = (e) => {
        const selectedSpecialGroupValue = e.target.selectedOptions[0].value;
        if (selectedSpecialGroupValue) {
            const description = remainingSpecialGroups
                .find(specialGroup => specialGroup.name == selectedSpecialGroupValue )
                .description
                ;
            description && setGroupName(description);
            setSelectedItems([]);
            setIsSpecialGroupCreated(true);
        } else {
            setIsSpecialGroupCreated(false);
            setGroupName("");
        } 
    }

    return (
        <div>
            <Form id="groupCreateData-form" method="post" encType="multipart/form-data">

              <Form.Group controlId="group_name" className="formSection">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                      name="group[name]"
                      placeholder="Entrer le nom du groupe"
                      value={groupName}
                      onChange={(e => setGroupName(e.target.value))}
                  />
              </Form.Group>

              <Form.Group controlId="group_position" className="formSection">
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Entrer la position du groupe si dans le menu principal"
                      name="group[position]"
                      defaultValue={ groupToUpdate ? groupToUpdate.position : ""}
                  />
              </Form.Group>

              {specialGroupOptions && <Form.Group controlId="special_group">
                <Form.Label>Groupe spécial</Form.Label>
                    <Form.Select
                        onChange={onSelectSpecialGroup}
                        name="special_group">
                        <option value="">--</option>
                        {specialGroupOptions}
                    </Form.Select>
                </Form.Group>}


              <Form.Group className="formSection">
                <Form.Label>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroupFileAddon01">
                            {groupToUpdate && groupToUpdate.links.thumbnail ? <Image src={groupToUpdate.links.thumbnail} rounded thumbnail className={"max-width-200"} /> : 'Choisir la vignette'}
                        </span>
                    </div>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            name="group[thumbnail]"
                            accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
                            aria-describedby="inputGroupFileAddon01"
                            onChange={(event) => setThumbnailFileInputName(event.target.files[0].name)}
                        />
                        <label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Parcourir">
                            {thumbnailFileInputName}
                        </label>
                    </div>
                </div>
              </Form.Group>

                <Form.Group className={"formSection"} 
                    style={{ marginBottom: withCustomBackgroundImage && backgroundFileDeleteable && !groupToUpdate?.links?.background ? "5em" : "1em" }}
                >
                <Form.Label>Image de fond ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_BACKGROUND_MO} Mo max.)</Form.Label>
                    {withCustomBackgroundImage ?
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupFileAddon02">
                                    {groupToUpdate?.links?.background ? <Image src={groupToUpdate.links.background} rounded thumbnail className={"max-width-200"} /> : "choisir l'image"}
                                </span>
                            </div>
                            <div className="custom-file d-flex flex-column">
                                <div>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile02"
                                        name="group[background]"
                                        accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
                                        aria-describedby="inputGroupFileAddon02"
                                        onChange={(event) => setBackgroundFileInputName(event.target.files[0].name)}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile02" data-browse="Parcourir">
                                        {backgroundFileInputName}
                                    </label>
                                </div>
    
                                {backgroundFileDeleteable && <div className="w-100 mt-3 ml-3 align-items-start ">
                                    <Button variant="danger" onClick={() => {
                                        setWithCustomBackgroundImage(false);
                                        setBackgroundFileInputName(backgroundFileInputNameDefaultValue);
                                    }
                                    }>
                                        Supprimer l'image de fond
                                    </Button>
                                </div>}
                            </div>
                        </div>
                        :<div className="d-flex justify-content-left">
                            <Button variant="success" className="w-40" onClick={() => {
                                setWithCustomBackgroundImage(true);
                            }
                            }>
                                <i className="fas fa-plus"></i> &nbsp;Ajouter une image de fond
                            </Button>
                        </div>
                    }
                </Form.Group>

                <Form.Group className="formSection">
                    <Form.Label>Preview vidéo ({fileContraints.VIDEO_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_VIDEO_MO} Mo max.) - facultatif</Form.Label><br />
                    <i>La preview sera affichée pour présenter le groupe dans le BO uniquement</i>
                    {withVideoPreview
                        ? <>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon02">
                                        {groupToUpdate?.links?.videoPreview ?
                                            <div style={{ width: '200px' }}>
                                                <video controls style={{ width: '100%' }}>
                                                    <source src={groupToUpdate.links?.videoPreview} type={"video/mp4"} />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div> : "choisir la vidéo"}
                                    </span>
                                </div>
                                <div className="custom-file d-flex flex-column">
                                    <div>
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile03"
                                        name="group[videoPreview]"
                                        accept={fileContraints.VIDEO_MIME_TYPES?.join(',')}
                                        aria-describedby="inputGroupFileAddon03"
                                        onChange={(event) => setVideoPreviewFileInputName(event.target.files[0].name)}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile03" data-browse="Parcourir">
                                        {videoPreviewFileInputName}
                                    </label>
                                    </div>
                                    {previewFileDeleteable && <div className="w-100 mt-3 ml-3 align-items-start">
                                        <Button variant="danger" onClick={() => {
                                            setWithVideoPreview(false);
                                            setVideoPreviewFileInputName(videoPreviewFileInputNameDefaultValue);
                                            groupToUpdate.links.videoPreview = null;
                                        }}>
                                            Supprimer la preview vidéo
                                        </Button>
                                    </div>}
                                </div>
                            </div>
                            <div 
                                className={withVideoPreview && previewFileDeleteable && !groupToUpdate?.links?.videoPreview ? "mt-5" : ""}
                                >
                                <Form.Check type='checkbox' id='group_isPreviewOnly' name='group[isPreviewOnly]' inline>
                                    <Form.Check.Input
                                        type='checkbox'
                                        onChange={(event) => setIsPreviewOnly(event.target.checked)}
                                        name='group[isPreviewOnly]'
                                        defaultChecked={isPreviewOnly}
                                    />
                                    <Form.Check.Label><span>Cochez si la preview vidéo doit remplacer l'affichage du contenu du groupe (sinon la preview s'affichera en dessous du contenu)</span></Form.Check.Label>
                                </Form.Check>
                            </div>
                        </>
                        : <div className="d-flex justify-content-left">
                            <Button variant="success" className="w-40" onClick={() => setWithVideoPreview(true)}>
                                <i className="fas fa-plus"></i> &nbsp;Ajouter une preview vidéo
                            </Button>
                        </div>
                    }
                </Form.Group>


                <Form.Group className="formSection">
                <Form.Label>Publication</Form.Label>
                <DatePicker
                    showIcon
                    locale='fr'
                    dateFormat="dd/MM/yyyy"
                    selected={publishDate}
                    onChange={(date) => {
                            setPublishDate(date);
                        }
                    } 
                    placeholderText="jj/mm/aaaa"
                />
            </Form.Group>


            <Form.Group className="formSection" >
                    <Form.Check type='checkbox' id='group_isInteractive' name='group[isInteractive]' inline>
                        <Form.Check.Input
                            type='checkbox'
                            onChange={(event) => {
                                setIsInteractive(event.target.checked);
                            }
                            }
                            name='group[isInteractive]'
                            defaultChecked={isInteractive}
                        />
                        <Form.Check.Label> <span>Cochez s'il s'agit d'un groupe interactif (ex: groupe Exploration)</span></Form.Check.Label>
                    </Form.Check>
                </Form.Group>

            <Form.Group className="formSection">
                <Form.Check type='checkbox' id='group_direct_access_code' name='group[isDirectlyAccessible]' inline>
                    <Form.Check.Input
                        type='checkbox'
                        onChange={(event) => {
                            setIsDirectlyAccessible(event.target.checked)
                        }
                        }
                        name='group[isDirectlyAccessible]'
                        defaultChecked={isDirectlyAccessible}
                    />
                    <Form.Check.Label> <span>Cochez si le groupe est directement accessible via un code</span></Form.Check.Label>
                </Form.Check>
            </Form.Group>


            <Form.Group className="formSection">
                <Form.Check type='checkbox' id='group_private' name='group[private]' inline>
                    <Form.Check.Input
                        type='checkbox'
                        onChange={(event) => {
                            if (event.target.checked) setIsPrivateGroup(true)
                            else setIsPrivateGroup(false)
                        }
                        }
                        name='group[private]'
                        defaultChecked={isPrivateGroup}
                    />
                    <Form.Check.Label> <span>Cochez s'il s'agit d'un groupe privé</span> <i className={isPrivateGroup ? "fas fa-lock" : "fas fa-lock-open"}> </i> </Form.Check.Label>
                </Form.Check>
            </Form.Group>

            <Form.Group className="formSection">
                <Form.Label>Producteur(s)</Form.Label>
                <SelectMultipleClientsComponent
                    nameSelect={`group[producers][]`}
                    selectedClients = {selectedClients}
                    setSelectedClients = {setSelectedClients} />
            </Form.Group>

            <Form.Group className="formSection">
                <Form.Label>Description</Form.Label>
                <Editor
                    name="group[description]"
                    initialValue={groupToUpdate ? groupToUpdate.description : ""}
                    apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                    init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                        'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                    ],
                    toolbar:
                        'undo redo | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | charmap emoticons | link | removeformat | help'
                        }}
                        onEditorChange={handleEditorChange}
                    />
                </Form.Group>

            <Form.Group className="formSection">
                <Accordion defaultActiveKey={['0', '1']} alwaysOpen className="my-3 create-group-accordion" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header as="div" style={{}}>
                            <i className="icon-books mr-2" style={{fontSize: "2rem"}}/>
                            Module pédagogique correspondant au groupe
                        </Accordion.Header>
                        <Accordion.Body>
                        {isFetchingAllPedagogicModules ?
                            <Loader />
                            : <SelectMediaModuleComponent 
                                allMedias={allPedagogicModules} 
                                mediaResultRef={pedagogicModuleSelected} 
                                draggable={true} 
                                mediaType={'module'} 
                                // onChange={onSelectPedagogicModules} was used to automatically replace the contents with the module's content
                            />
                        }        
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header as="div" >
                            <i className="fas fa-shopping-basket mr-2" style={{fontSize: "2rem"}}/>
                            &nbsp;<span>Contenu</span>
                        </Accordion.Header>
                        <Accordion.Body> 
                            <SelectItemGroupComponent groupToUpdate={groupToUpdate} initSelectItems={selectedItems} onSelectItem={setSelectedItems} isSpecialGroupCreated={isSpecialGroupCreated} />   
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header as="div" >
                            <i className="fas fa-file mr-2" style={{fontSize: "2rem"}}/>
                            &nbsp;<span>Fichiers</span>
                        </Accordion.Header>
                        <Accordion.Body className="d-flex justify-content-center align-items-center">
                            {uploadFilesContainer}            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Form.Group>
            <Form.Group >
                <Form.Control
                    name="group[_token]"
                    defaultValue={token}
                    type="hidden"
                />
            </Form.Group>

            </Form>

            <br />

            <ButtonLoaderComponent
                // disabled={isSending || !(selectedItems && selectedItems.length != 0)}
                // a group must be emptiable in order to be deleted
                disabled={isSending}
                active={!isSending}
                onClick={checkData}
                isSending={isSending}
                msg={groupToUpdate && groupToUpdate.uniqueId ? <><i className="fas fa-save" /> &nbsp;Éditer</> : <><i className="fas fa-save" /> &nbsp;Sauvegarder</>}
            />
        </div>
    );
}

export default CreateGroupComponent;