import React from 'react';
import { Spinner, Button } from 'react-bootstrap';

/**
 * ButtonLoader widget
 *
 * @version 1.0.0
 * @param {boolean} disabled  default false
 * @param {function} onClick  callback function
 * @param {boolean} isSending  default false
 * @param {string} msg button message
 * @param {string} variant  bootstrap className variant - default 'variant'
 * @example
 *      <ButtonLoaderComponent 
 *          variant='secondary'
 *          disabled={false}
 *          onClick={() => CallBack()} 
 *          msg="Envoyer" 
 *          isSending={false} />
 */

 
const ButtonLoaderComponent = (props) => {

    const { variant, disabled, msg, onClick, isSending, block, iconClassName, ...other  } = props;
    const message = msg ? msg : <><i className="fas fa-share-square"/> Envoyer</>;

    const spinner =
        <>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />
            <span className="sr-only"> Envoi en cours </span>
            </>;

    const messageWithIcon = (className, msg) => <span className='d-flex'><i className={className}></i>&nbsp;&nbsp;{msg}</span>

    return (

        <div className={block ? "d-grid" : "" }>
            <Button
                {...other}
                variant={variant ? variant : 'secondary'}
                disabled={disabled ? disabled : false}
                onClick={onClick}
            >
                { isSending 
                    ? spinner 
                    : iconClassName 
                        ? messageWithIcon(iconClassName, message)
                        : message }
            </Button>
        </div>

    );

}

export default ButtonLoaderComponent;