import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip, Button, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useStoreActions, useStoreState} from 'easy-peasy';

import useSearchWithParams from "../../../hooks/useSearchWithParams.jsx";
import { useToast } from "../../../hooks/useToast.jsx";
import PropTypes from 'prop-types';
import SelectItemsComponent from '../../widgets/selectItemsComponent/SelectItemsComponent.jsx';
import './ClientsFiltersComponent.scss';
import Loader from '../../widgets/Loader.jsx'
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import SelectMultipleItemsComponent from "../../widgets/selectMultipleItemsComponent/SelectMultipleItemsComponent.jsx";

const ClientsFiltersComponent = (props) => {
    const { allClients, setFilteredClients, isClientsCRM = false, isAdmin = false } = props;

    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (établissement, C.P., ville...)', delay: 400 });

    const toast = useToast();

    const [searchParam, setSearchParam] = useSearchParams();

    const { fetchContactsM360, fetchClientsCategories, searchClientsCRMIdsByContact } = useStoreActions(actions => actions.clients);
    const { contactsM360, clientsCategories } = useStoreState(state => state.clients);
    const { allClientStatus } = useStoreState(state => state.clientStatus);
    const { allTagOperations } = useStoreState(state => state.tagOperations);
    const {fetchClientStatus} = useStoreActions(actions => actions.clientStatus);
    const {fetchTagOperations} = useStoreActions(actions => actions.tagOperations);

    const [areFiltersApplied, setAreFiltersApplied] = useState(false);
    const [isFirstLoadFilters, setIsFirstLoadFilters] = useState(true);
    const [isUpdatingFiltersFromUrl, setIsUpdatingFiltersFromUrl] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const [parentList, setParentList] = useState([]);

    const minDateRef = useRef("");
    const maxDateRef = useRef("");
    const currentSubscriberRef = useRef(false);
    const parentClientSelectorRef = useRef(null);
    const statusClientRef = useRef(null);
    const exclusiveContactsM360Ref = useRef(false);
    const exclusiveOpeM360Ref = useRef(false);
    const exclusiveCategoriesRef = useRef(false);
    const testerOnlyRef = useRef(false);
    const haveInvoicesRef = useRef(false);

    const [contactsSelected, setContactsSelected] = useState([]);
    const [opesM360Selected, setOpesM360Selected] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [clientLinkedFilter, setClientLinkedFilter] = useState('all');

    const [isSearching, setIsSearching] = useState(false);

    const onlyTesterCorrespondingTags = allTagOperations.filter(tag => tag.isTester);

    const setFiltersFromUrl = ({ contactsM360, clientsCategories, tagOperations }) => {

        setIsUpdatingFiltersFromUrl(true);

        const newSearchParamMinDate = searchParam.get('minSubEnd');
        const newSearchParamMaxDate = searchParam.get('maxSubEnd');
        const newSearchParamSub = searchParam.get('currentSubscriberOnly');
        const newSearchParamContacts = searchParam.get('contactsM360');
        const newSearchParamExclContacts = searchParam.get('contactsM360Exclusive');
        const newSearchParamTagOpe = searchParam.get('opeM360Selected');
        const newSearchParamExclOpe = searchParam.get('opeM360Exclusive');
        const newSearchParamParent = searchParam.get('parentSelected');
        const newSearchParamCategory = searchParam.get('categoriesSelected');
        const newSearchParamExclCategories = searchParam.get('categoriesExclusive');
        const newSearchParamStatus = searchParam.get('statusClientSelected');
        const newSearchParamTester = searchParam.get('testerOnly');
        const newSearchParamClientLinked = searchParam.get('clientLinked');
        const newSearchParamHaveInvoices = searchParam.get('haveInvoices');

        if (newSearchParamMinDate && minDateRef?.current) {
            minDateRef.current.value = newSearchParamMinDate;
        }
        if (newSearchParamMaxDate && maxDateRef?.current) {
            maxDateRef.current.value = newSearchParamMaxDate;
        }
        if (newSearchParamSub && currentSubscriberRef?.current) {
            currentSubscriberRef.current.checked = JSON.parse(newSearchParamSub);
        }
        if (isClientsCRM && newSearchParamClientLinked && clientLinkedFilter) {
            setClientLinkedFilter(newSearchParamClientLinked);
        }
        if (isClientsCRM && newSearchParamHaveInvoices && haveInvoicesRef?.current) {
            haveInvoicesRef.current.checked = JSON.parse(newSearchParamHaveInvoices);
        }
        if (newSearchParamContacts) {
            const contactsIdSelected = newSearchParamContacts.split(",");
            let contactsM360Selected = contactsM360?.filter(contactM360 => 
                contactsIdSelected?.some(id => id == contactM360.id));
            if(contactsIdSelected?.some(id => id == 0)) contactsM360Selected?.unshift({id: 0, name: "-- Sans contacts M360 --"});
            setContactsSelected(contactsM360Selected);
        }
        if(newSearchParamExclContacts && exclusiveContactsM360Ref?.current) {
            exclusiveContactsM360Ref.current.checked = JSON.parse(newSearchParamExclContacts);
        }
        if (newSearchParamTagOpe) {
            const opeIdSelected = newSearchParamTagOpe.split(",");
            let opeM360Selected = tagOperations?.filter(tag => opeIdSelected.some(id => id == tag.id));
            if(opeIdSelected?.some(id => id == 0)) opeM360Selected?.unshift({id: 0, name: "-- Sans opé M360 --"});
            setOpesM360Selected(opeM360Selected);
        }
        if(newSearchParamExclOpe && exclusiveOpeM360Ref?.current) {
            exclusiveOpeM360Ref.current.checked = JSON.parse(newSearchParamExclOpe);
        }
        if (!isClientsCRM && newSearchParamParent && parentClientSelectorRef?.current) {
            parentClientSelectorRef.current.value = newSearchParamParent;
        }
        if (newSearchParamStatus && statusClientRef?.current) {
            statusClientRef.current.value = newSearchParamStatus;
        }
        if (newSearchParamCategory) {
            const categoryIdSelected = newSearchParamCategory.split(",");
            let catSelected = clientsCategories?.filter(cat => categoryIdSelected?.some(id => id == cat.id));
            if(categoryIdSelected?.some(id => id == 0)) catSelected?.unshift({id: 0, name: "-- Sans catégories --"});
            setCategoriesSelected(catSelected);
        }
        if(newSearchParamExclCategories && exclusiveCategoriesRef?.current) {
            exclusiveCategoriesRef.current.checked = JSON.parse(newSearchParamExclCategories);
        }
        if (!isClientsCRM && newSearchParamTester && testerOnlyRef?.current) {
            testerOnlyRef.current.checked = JSON.parse(newSearchParamTester);
        }

        setIsFiltersOpen((newSearchParamMinDate && newSearchParamMinDate !== "")
            || (newSearchParamMaxDate && newSearchParamMaxDate !== "" )
            || (newSearchParamSub && newSearchParamSub !== "false") 
            || (isClientsCRM && newSearchParamClientLinked && newSearchParamClientLinked !== "all")
            || (isClientsCRM && newSearchParamHaveInvoices && newSearchParamHaveInvoices !== "false") 
            || (newSearchParamContacts && newSearchParamContacts !== "") || (newSearchParamExclContacts && newSearchParamExclContacts !== "false") 
            || (newSearchParamTagOpe && newSearchParamTagOpe !== "") || (newSearchParamExclOpe && newSearchParamExclOpe !== "false") 
            || (!isClientsCRM && newSearchParamParent && newSearchParamParent !== "") 
            || (newSearchParamCategory && newSearchParamCategory !== "") || (newSearchParamExclCategories && newSearchParamExclCategories !== "false")
            || (newSearchParamStatus && newSearchParamStatus !== "")
            || (!isClientsCRM && newSearchParamTester && newSearchParamTester !== "false") 
        );

        setIsUpdatingFiltersFromUrl(false);

    }


    const applyFiltersOnTable = () => {
        const minDate = minDateRef.current?.value ?? null;        
        const maxDate = maxDateRef.current?.value ?? null;
        const currentSubscriber = currentSubscriberRef.current?.checked ?? false;
        const clientLinked = clientLinkedFilter ?? "all";
        const haveInvoices = haveInvoicesRef.current?.checked ?? false;
        const parentId = parentClientSelectorRef.current?.value ?? null;
        const statusLabel = statusClientRef.current?.value ?? null;
        const contactsM360Exclusive = exclusiveContactsM360Ref.current?.checked ?? false;
        const opeM360Exclusive = exclusiveOpeM360Ref.current?.checked ?? false;
        const categoriesExclusive = exclusiveCategoriesRef.current?.checked ?? false;
        const onlyTester = testerOnlyRef.current?.checked ?? false;

        const clientsToDisplay = allClients
            ?.filter(client => {
                const minSubDateCondition = !minDate || client.subscriptionEndDate >= minDate;
                const maxSubDateCondition = !maxDate || client.subscriptionEndDate <= maxDate;
                const currentSubscriberCondition = !currentSubscriber || new Date(client.subscriptionEndDate) >= new Date();
                const clientLinkedCondition = !isClientsCRM || clientLinked == "all" || clientLinked == "linked" && client.isSubscriber || clientLinked == "unlinked" && !client.isSubscriber;
                const haveInvoicesCondition = !isClientsCRM || !haveInvoices || client.invoices !== 0;
                const contactsRefCondition = contactsConditionFromSearch(client?.contactsM360, contactsM360Exclusive);
                const tagCondition = opeM360OrCategoriesConditionFromSearch(client?.tagOperationsName, opeM360Exclusive, opesM360Selected);
                const parentRefCondition = isClientsCRM || !parentId || client.parentUniqueId === parentId || (parentId === "aucun" && !client.parentUniqueId);
                const categoryRefCondition = opeM360OrCategoriesConditionFromSearch(client?.categories, categoriesExclusive, categoriesSelected);
                const statusRefCondition = !statusLabel || client.status === statusLabel || (statusLabel === "aucun" && !client.status);
                const isTesterCondition = isClientsCRM || !onlyTester || opeM360OrCategoriesConditionFromSearch(client?.tagOperationsName, false, onlyTesterCorrespondingTags);

                return minSubDateCondition && maxSubDateCondition && currentSubscriberCondition && contactsRefCondition 
                    && tagCondition && parentRefCondition && categoryRefCondition && statusRefCondition && isTesterCondition
                    && clientLinkedCondition && haveInvoicesCondition;
            })
            ?.filter(client => 
                HelperMetiers360.isSearchInText(search, client.clientName)
                || HelperMetiers360.isSearchInText(search, client.name)
                || HelperMetiers360.isSearchInText(search, client.addressZipCode)
                || HelperMetiers360.isSearchInText(search, client.addressCity)
                || HelperMetiers360.isSearchInText(search, client.tagOperationsName?.join())
                || HelperMetiers360.isSearchInText(search, client.contactsM360?.map(contact => contact.name)?.join())
                || HelperMetiers360.isSearchInText(search, client.categories?.join())
                || HelperMetiers360.isSearchInText(search, client.status)
            );

        setFilteredClients(clientsToDisplay);
        setAreFiltersApplied(search || minDate || maxDate || currentSubscriber || parentId || clientLinkedFilter !== "all" || haveInvoices
            || contactsSelected.length > 0 || opesM360Selected.length > 0 || categoriesSelected.length > 0 || statusLabel || onlyTester);

    }

    const contactsConditionFromSearch = (clientContactsM360, contactsM360Exclusive) => {
        if(contactsSelected.length === 0) {
            return true;
        } else if(contactsM360Exclusive) {
            if(contactsSelected.length === 1 && contactsSelected[0].id == 0) {
                return !clientContactsM360?.length;
            } else {
                return clientContactsM360?.map(contactM360 => contactM360.id).sort().join() 
                    == contactsSelected?.map(contactM360 => contactM360.id).sort().join()
            }
        } else {
            return (contactsSelected[0].id == 0 && !clientContactsM360?.length)
                || clientContactsM360?.some(contact => 
                    contactsSelected?.some(contactSelected => contact.id == contactSelected.id)) 
        }
    }

    const opeM360OrCategoriesConditionFromSearch = (clientList, isExclusive, listSelected) => {
        if(listSelected.length === 0) {
            return true;
        } else if(isExclusive) {
            if(listSelected.length === 1 && listSelected[0].id == 0) {
                return !clientList?.length;
            } else {
                return clientList?.sort().join() 
                    == listSelected?.map(item => item.name).sort().join()
            }
        } else {
            return (listSelected[0].id == 0 && !clientList?.length)
                || clientList?.some(item => 
                    listSelected?.some(itemSelected => item == itemSelected.name)) 
        }
    }

    const setParamsToUrl = (newParams) => {
        const paramsToSet = {}
        for (const [key, value] of searchParam.entries()) {
            paramsToSet[key] = value;
        }
        for (const [key, value] of newParams) {
            paramsToSet[key] = value;
        }
        setSearchParam(paramsToSet);
    }

    const resetFilters = () => {
        setParamsToUrl([
            ['search', ""],
            ['minSubEnd', ""],
            ['maxSubEnd', ""],
            ['currentSubscriberOnly', false],
            ['contactsM360', ""],
            ['contactsM360Exclusive', false],
            ['opeM360Selected', ""],
            ['opeM360Exclusive', false],
            ['parentSelected', ""],
            ['categoriesSelected', ""],
            ['categoriesExclusive', false],
            ['statusClientSelected', ""],
            ['testerOnly', false],
            ['clientLinked', "all"],
            ['haveInvoices', false],
        ]);
        if(minDateRef.current?.value) minDateRef.current.value = "";
        if(maxDateRef.current?.value) maxDateRef.current.value = "";
        if(currentSubscriberRef.current) currentSubscriberRef.current.checked = false;
        setContactsSelected([]);
        if(exclusiveContactsM360Ref.current) exclusiveContactsM360Ref.current.checked = false;
        setOpesM360Selected([]);
        if(exclusiveOpeM360Ref.current) exclusiveOpeM360Ref.current.checked = false;
        if (!isClientsCRM && parentClientSelectorRef.current?.value) parentClientSelectorRef.current.value = "";
        setCategoriesSelected([]);
        if(exclusiveCategoriesRef.current) exclusiveCategoriesRef.current.checked = false;
        if(statusClientRef.current?.value) statusClientRef.current.value = "";
        if (!isClientsCRM && testerOnlyRef.current) testerOnlyRef.current.checked = false;
        isClientsCRM && setClientLinkedFilter("all");
        if (isClientsCRM && haveInvoicesRef.current) haveInvoicesRef.current.checked = false;

        applyFiltersOnTable();
    }


    const onFilterChange = () => {
        if (!isUpdatingFiltersFromUrl && !isFirstLoadFilters) {
            const paramsToUrl = [['search', search ?? ""]];
            isAdmin && paramsToUrl.push(['minSubEnd', minDateRef.current?.value ?? ""],
                ['maxSubEnd', maxDateRef.current?.value ?? ""],
                ['contactsM360', contactsSelected.length > 0 ? contactsSelected.map(contact => contact.id).join(',') : ""],
                ['contactsM360Exclusive', exclusiveContactsM360Ref?.current?.checked !== undefined ? exclusiveContactsM360Ref?.current.checked : false],
                ['opeM360Selected', opesM360Selected.length > 0 ? opesM360Selected.map(ope => ope.id).join(',') : ""],
                ['opeM360Exclusive', exclusiveOpeM360Ref?.current?.checked !== undefined ? exclusiveOpeM360Ref?.current.checked : false],
                ['categoriesSelected', categoriesSelected.length > 0 ? categoriesSelected.map(cat => cat.id).join(',') : ""],
                ['categoriesExclusive', exclusiveCategoriesRef?.current?.checked !== undefined ? exclusiveCategoriesRef?.current.checked : false],
                ['currentSubscriberOnly', currentSubscriberRef?.current?.checked ?? ""],
                ['statusClientSelected', statusClientRef.current?.value ?? ""]);

            isClientsCRM
                ? paramsToUrl.push(['clientLinked', clientLinkedFilter ?? "all"], ['haveInvoices', haveInvoicesRef?.current?.checked ?? ""])
                : paramsToUrl.push(['parentSelected', parentClientSelectorRef.current?.value ?? ""], ['testerOnly', testerOnlyRef.current?.checked ?? ""]);

            setParamsToUrl(paramsToUrl)
        }
        applyFiltersOnTable();
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const contactsM360Promise = !contactsM360.length ? fetchContactsM360() : Promise.resolve(null);
                const clientsCategoriesPromise = !clientsCategories.length ? fetchClientsCategories() : Promise.resolve(null);
                const clientStatusPromise = !allClientStatus.length ? fetchClientStatus() : Promise.resolve(null);
                const tagOperationsPromise = !allTagOperations.lenght ? fetchTagOperations() : Promise.resolve(null);
    
                const [contactsM360Result, clientsCategoriesResult, clientStatusResult, tagOperationsResult] = await Promise.all([
                    contactsM360Promise,
                    clientsCategoriesPromise,
                    clientStatusPromise,
                    tagOperationsPromise
                ]);
    
                !isClientsCRM && setParentList(allClients.filter(client => client.childrenCount > 0));
    
                setFiltersFromUrl({
                    contactsM360: contactsM360Result || contactsM360,
                    clientsCategories: clientsCategoriesResult || clientsCategories,
                    tagOperations: tagOperationsResult || allTagOperations
                });
    
            } catch (error) {
                toast.open({ message: "Erreur lors de la récupération des filtres", variant: "danger" });
            } finally {
                setIsFirstLoadFilters(false);
            }
        };
    
        if (isAdmin) { fetchData() } else { setIsFirstLoadFilters(false) };
    }, []);
    

    useEffect(() => {
        onFilterChange();
    }, [search, allClients, contactsSelected, opesM360Selected, categoriesSelected, isFirstLoadFilters, clientLinkedFilter]);

    const searchByContact = () => {
        if (search) {
            setIsSearching(true);
            searchClientsCRMIdsByContact(search)
                .then((res) => {
                    if (res[0]?.length) {
                        const matchingIds = new Set(res[0].map(id => id.uniqueId));
                        const clientsFound = allClients.filter(c => matchingIds.has(c.uniqueId));
                        setFilteredClients(clientsFound);
                    }
                })
                .finally(() => setIsSearching(false));
        }
    };

    return (<>
        <Row className={"d-flex align-items-center pe-0"}>
            {isAdmin &&
            <Col className="d-flex align-items-center">
                <Button id="btnFilters" className="cursor-pointer shadow"
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                    onKeyDown={() => setIsFiltersOpen(!isFiltersOpen)}>
                    <p className="me-3">Filtres</p>
                    { isFiltersOpen 
                        ? <i className="fa fa-caret-up"></i>
                        : <i className="fa fa-caret-down"></i>
                    }
                </Button>
                { areFiltersApplied && 
                    <OverlayTrigger placement="top"
                        overlay={ <Tooltip>Effacer les filtres</Tooltip> }>
                        <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { resetFilters()} }>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </OverlayTrigger>
                }
                { isFirstLoadFilters && 
                    <div id="loader-first-loading-filter"><Loader/></div>
                }
                </Col>}
            <Col className="pe-0">
                <div className="searchBlock">
                    {searchInput}
                    {isClientsCRM && search &&
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip>
                                    Rechercher par contacts (email, téléphone)
                                </Tooltip>
                            }
                        >
                            <span>
                                <Button className="contactSearch" onClick={searchByContact}>
                                    {isSearching
                                        ? <Spinner as="span" animation="border" size="sm" aria-hidden="true" />
                                        : <i className="fas fa-address-book" />
                                    }
                                </Button>
                            </span>
                        </OverlayTrigger>}
                </div>
            </Col>
        </Row>
        {isAdmin &&
        <div id="filtersZone" className={!isFiltersOpen ? "hide-filters" : undefined}> 
            <Row className="d-flex align-items-center justify-content-between">
                <Col className="m360-col3-start" md={12} lg={6} xl={6} xxl={4}>    
                    <div className="d-flex align-items-center">
                        <label className="label-select" id="end-subscription-label">Fin d'abonnement&nbsp;: </label>
                        <input className="m-1" type="date" id="start" name="sub-start" max={maxDateRef.current?.value} 
                            ref={minDateRef} onChange={onFilterChange} />
                        <label htmlFor="end" className="m-0">au</label>
                        <input className="m-1" type="date" id="end" name="sub-end" min={minDateRef.current?.value}
                            ref={maxDateRef} onChange={onFilterChange} />
                    </div>
                </Col>
                <Col className="m360-col3-center" md={6} lg={6} xxl={4}>
                    <Form.Check id="currentSubscription" className="clickable"
                        label="Abonnements valides" onChange={onFilterChange} ref={currentSubscriberRef}/>
                </Col>
                <Col className="m360-col3-end" md={6} lg={6} xxl={4}>
                    <label className="label-select">Contacts&nbsp;M360&nbsp;: </label>
                    <span className="d-flex flex-column w-100">
                        <SelectMultipleItemsComponent itemsSelected={contactsSelected} setItemsSelected={setContactsSelected}
                            items={contactsM360} name="contacts" itemKey="id" itemValue="name"  id='select-contacts'
                            labelNone="contacts M360" />
                        { contactsSelected.length > 0 &&
                            <Form.Check id="exclusiveContactsM360" className="clickable" label="Exclusif"
                                onChange={onFilterChange} ref={exclusiveContactsM360Ref}/>
                        }
                    </span>
                </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-between mt-2">
                <Col className="m360-col3-start" md={6} lg={6} xxl={4}>
                    <label className="label-select">Opé&nbsp;M360&nbsp;: </label>
                    <span className="d-flex flex-column w-100">
                        <SelectMultipleItemsComponent itemsSelected={opesM360Selected} setItemsSelected={setOpesM360Selected}
                            items={allTagOperations} name="opeM360" itemKey="id" itemValue="name"  id='select-opeM360'
                            labelNone="opé M360" />
                        { opesM360Selected.length > 0 &&
                            <Form.Check id="exclusiveOpeM360" className="clickable" label="Exclusif"
                                onChange={onFilterChange} ref={exclusiveOpeM360Ref}/>
                        }
                    </span>
                </Col>
                    <Col className="m360-col3-center" md={6} lg={6} xxl={4}>
                    <label className="label-select">Catégories&nbsp;: </label>
                    <span className="d-flex flex-column w-100">
                        <SelectMultipleItemsComponent itemsSelected={categoriesSelected} setItemsSelected={setCategoriesSelected}
                            items={clientsCategories} name="categories" itemKey="id" itemValue="name"  id='select-categories'
                            labelNone="catégories" />
                        { categoriesSelected.length > 0 &&
                            <Form.Check id="exclusiveCategories" className="clickable" label="Exclusif"
                                onChange={onFilterChange} ref={exclusiveCategoriesRef}/>
                        }
                    </span>
                </Col>
                    <Col className="m360-col3-end" md={6} lg={6} xxl={4}>
                    <label className="label-select">Statut&nbsp;: </label>
                    <span className="d-flex flex-column w-100">
                        <SelectItemsComponent selectRef={statusClientRef} onChange={onFilterChange}
                                items={allClientStatus} itemKey="label" itemValue="label" labelNone={"statut"} />
                    </span>
                </Col>
                </Row>
                <Row className="d-flex align-items-center mt-2">
                    {isClientsCRM
                        ? <>
                            <Col className="m360-col3-start" md={6} lg={6} xxl={4}>
                                <Form.Check id="haveInvoices" className="clickable"
                                    label="Seulement les clients facturés" onChange={onFilterChange} ref={haveInvoicesRef} />
                            </Col>
                            <Col className="m360-col3-center" md={6} lg={6} xxl={4}>
                                <div className="d-flex align-items-center">
                                    <label className="label-select">Clients liés à un établissement&nbsp;: </label>
                                    <div className='choices-selector-inline'>
                                        <div className={clientLinkedFilter === 'linked' ? 'selected' : null}
                                            onClick={() => setClientLinkedFilter('linked')} onKeyDown={() => setClientLinkedFilter('linked')}>
                                            Oui</div>
                                        <div className={clientLinkedFilter === 'all' ? 'selected' : null}
                                            onClick={() => setClientLinkedFilter('all')} onKeyDown={() => setClientLinkedFilter('all')}>
                                            Tous</div>
                                        <div className={clientLinkedFilter === 'unlinked' ? 'selected' : null}
                                            onClick={() => setClientLinkedFilter('unlinked')} onKeyDown={() => setClientLinkedFilter('unlinked')}>
                                            Non</div>
                                    </div>
                                </div>
                            </Col>
                        </>
                        : <>
                            <Col className="m360-col3-start" md={6} lg={6} xxl={4}>
                                <div className="d-flex align-items-center">
                                    <label className="label-select">Enfants de&nbsp;: </label>
                                    <SelectItemsComponent selectRef={parentClientSelectorRef} onChange={onFilterChange}
                                        items={parentList} itemKey="uniqueId" itemValue="clientName" labelNone={"parent"} />
                                </div>
                            </Col>
                            <Col className="m360-col3-center" md={6} lg={6} xxl={4}>
                                <Form.Check id="testerOnly" className="clickable"
                                    label="Clients testeurs" onChange={onFilterChange} ref={testerOnlyRef} />
                            </Col>
                        </>}
            </Row>
            </div>}
    </>)
}

ClientsFiltersComponent.propTypes = {
    allClients: PropTypes.array.isRequired,
    setFilteredClients: PropTypes.any.isRequired,
    isClientsCRM: PropTypes.bool,
    isAdmin: PropTypes.bool
};

export default ClientsFiltersComponent