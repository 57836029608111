import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Image } from 'react-bootstrap';

/**
 * 
 * @param {Array} transversalProps array of all transversalProps to be rendered
 * @param {String} transversalProp  string describing the transversalProps to be rendered (areasOfInterest, workingConditions, skills, largeProfessionalFields)
 * @param {Bool} displayCodeColumn  if code column has to be displayed
 
 * @example
 *  <TransversalPropsTableComponent transversalProps={transversalProps} transversalProp={transversalProp} displayCodeColumn={displayCodeColumn} />
 */
const TransversalPropsTableComponent = ({ transversalProps = [], transversalProp, displayCodeColumn = false }) => {
    const [transversalPropsToDisplay, setTransversalPropsToDisplay] = useState([]);
    const [sortedtransversalProp, setSortedtransversalProp] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const transversalPropsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher(transversalProp));
    const navigate = useNavigate();

    useEffect(() => {
        setShouldSort(true);
        setSortedtransversalProp([...transversalProps]);
    }, [transversalProps]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedtransversalProp, transversalPropsToDisplay, setTransversalPropsToDisplay, 100);

    if (transversalProps.length === 0) {
        return <></>;
    }

    const sortType = [
        {
            value: 'thumbnail', label: 'Vignette',
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'thumbnail'),
            display: (transversalProp) => (
                <OverlayTrigger
                    placement='right'
                    overlay={
                        <Tooltip>
                            <Image src={transversalProp.thumbnail} />
                        </Tooltip>
                    }
                >
                    <Image src={transversalProp.thumbnail} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay: (transversalProp) => transversalProp.thumbnail
        },
        {
            value: 'code', label: "Code",
            test: displayCodeColumn && HelperMetiers360.isArrayContainsValue(transversalProps, 'code'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.code, b.code),
            display: (transversalProp) => transversalProp.code
        },
        {
            value: 'label', label: HelperMetiers360.transversalPropLabel(transversalProp),
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'label'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (transversalProp) => transversalProp.label
        },
        {
            value: 'category', label: 'Catégorie',
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'category'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.category, b.category),
            display: (transversalProp) => HelperMetiers360.categoryWorkingConditionsLabel(transversalProp.category)
        },
        {
            value: 'usagePros', label: 'Professionnel⋅les',
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'usagePros'),
            method: (a, b) => b.usagePros - a.usagePros,
            display: (transversalProp) => transversalProp.usagePros
        },
        {
            value: 'usageVideosByPro', label: 'Expériences immersives',
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'usageVideosByPro'),
            method: (a, b) => b.usageVideosByPro - a.usageVideosByPro,
            display: (transversalProp) => transversalProp.usageVideosByPro
        },
        {
            value: 'usageVideosByRome', label: 'Expériences immersives',
            test: !HelperMetiers360.isArrayContainsValue(transversalProps, 'usageVideosByPro')
                && HelperMetiers360.isArrayContainsValue(transversalProps, 'usageVideosByRome'),
            method: (a, b) => b.usageVideosByRome - a.usageVideosByRome,
            display: (transversalProp) => transversalProp.usageVideosByRome
        },
    ];

    const handleClick = (codetransversalProp) => {
        if (transversalPropsSlugs.readOne) {
            navigate(transversalPropsSlugs.readOne.replace(':uniqueId', codetransversalProp));
        }
    }

    return (
        <DynamicTableInfinite
            contentTable={transversalPropsToDisplay}
            contentSort={sortType}
            valueInitSort="label"
            index='code'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedtransversalProp}
            sortedContent={sortedtransversalProp}
            sortState={[shouldSort, setShouldSort]}
            handleClick={handleClick}
            filename={HelperMetiers360.transversalPropLabel(transversalProp).toLocaleLowerCase().replace(" ", "_")}
        />
    );
}

TransversalPropsTableComponent.propTypes = {
    transversalProps: PropTypes.array,
    displayCodeColumn: PropTypes.bool,
    transversalProp: PropTypes.string.isRequired
};

export default TransversalPropsTableComponent;