import React from "react";
import Plot from 'react-plotly.js';
import Loader from '../widgets/Loader';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const RANGE_VALUE = 20;

const MeanViewsDuration = ({ data = [], aggregated }) => {
    const [proxiedData, isValid] = useCheckDataProps(data || []);

    const maxVideoLength = aggregated ? proxiedData?.[0]?.longest_video : Math.max(...proxiedData.map(item => item.video_length));

    const getDurationRange = (duration) => {
        let rangeStart = Math.floor(duration / 20) * 20;
        if (duration >= maxVideoLength) { rangeStart = Math.floor(maxVideoLength / 20) * 20 } // correction car certains events montrent des durées de lecture > durée de la vidéo (personne ayant mis du temps à quitter la vidéo?)
        const rangeEnd = rangeStart + 19;
        return `${rangeStart}-${rangeEnd}`;
    };

    const generateEmptyRange = (rangeValue, maxValue) => {
        const emptyRange = {};
        for (let i = 0; i < maxValue; i += rangeValue) {
            const rangeStart = i;
            const rangeEnd = i + rangeValue - 1;
            const rangeKey = `${rangeStart}-${rangeEnd}`;
            emptyRange[rangeKey] = { x: rangeKey, y: 0 };
        }
        return emptyRange;
    };

    const generateGroupedData = (proxiedData, aggregated) => {
        const emptyRange = generateEmptyRange(RANGE_VALUE, maxVideoLength);
        if (aggregated) {
            const aggregatedData = proxiedData.reduce((acc, item) => {
                const range = getDurationRange(item.playbac_duration);
                if (!acc[range]) {
                    acc[range] = { ...emptyRange[range] };
                }
                acc[range].y += item.video_views;
                return acc;
            }, { ...emptyRange });

            const x = Object.keys(aggregatedData).sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]));
            const y = x.map(range => aggregatedData[range].y);

            return [{
                x,
                y,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
                name: 'Total Views',
            }];
        } else {
            const groupedData = proxiedData.reduce((acc, item) => {
                const range = getDurationRange(item.playbac_duration);
                const emptyRangeForVideo = generateEmptyRange(RANGE_VALUE, item.video_length);
                if (!acc[item.video_id]) {
                    acc[item.video_id] = { ...emptyRangeForVideo };
                }
                if (!acc[item.video_id]?.[range]?.video_name) {
                    acc[item.video_id][range] = {
                        x: range,
                        y: 0,
                        video_name: item.video_name,
                        totalLength: item.video_length
                    };
                }
                acc[item.video_id][range].y += item.video_views;
                return acc;
            }, {});


            return Object.values(groupedData).map(videoData => {
                const x = Object.keys(videoData)
                    .filter(range => {
                        const [rangeStart] = range.split('-').map(Number);
                        return rangeStart <= Math.floor(videoData?.[range]?.totalLength) || !videoData?.[range]?.totalLength;
                    })
                    .sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]));
                const y = x.map(range => videoData[range].y);

                return {
                    x,
                    y,
                    type: 'scatter',
                    mode: 'lines+markers',
                    name: videoData[x[0]]?.video_name,
                };
            });
        }
    };

    const traces = generateGroupedData(proxiedData, aggregated);

    return (
        data?.length ?
            !isValid ?
                <Alert variant="danger">Les données ne correspondent pas</Alert>
                : <Plot
                    data={traces}
                    layout={{
                        autosize: true,
                        title: 'Durées de lecture par nombre de vues',
                        xaxis: {
                            title: {
                                text: 'Durées de lecture (en s)',
                                font: {
                                    size: 14,
                                    color: '#7f7f7f',
                                },
                            },
                            range: [0, Math.ceil(maxVideoLength / RANGE_VALUE)],
                        },
                        yaxis: {
                            title: {
                                text: 'Nombre de lectures',
                                font: {
                                    size: 14,
                                    color: '#7f7f7f',
                                },
                                standoff: 60,
                            },
                        },
                        margin: {
                            l: 50,
                            r: 50,
                            b: 50,
                            t: 50,
                            pad: 4,
                        },
                    }}
                    useResizeHandler={true}
                    style={{ width: '100%', height: '100%' }}
                />
            : <Loader />
    );
}

MeanViewsDuration.propTypes = {
    data: PropTypes.array.isRequired,
    aggregated: PropTypes.bool.isRequired
}

export default MeanViewsDuration;
