import React from 'react';
import {useStoreActions} from 'easy-peasy';
import { Tabs, Tab } from 'react-bootstrap';

import { useModal } from '../../../hooks/useModal';

import QuizFormComponent from './QuizFormComponent';
import QuestionComponent from './question/QuestionComponent';

const UploadQuizComponent = ({
        quizToUpdate,
		update: updateOneQuizSlug,
        action,
		readOne: readOneQuizSlug
    }) => {

		const {deleteQuizBackground, updateQuiz, createQuiz, createQuestion, updateQuestion, deleteQuestionBackground } = useStoreActions(actions => actions.quizzes);
		const {modalComponent, ...modalProps} = useModal();

    return (
		<>
		 <Tabs defaultActiveKey="quiz-create-tab" id="quiz-upload-tabs" className="d-flex">
			<Tab eventKey="quiz-create-tab" title="Gestion du quiz" unmountOnExit={true}> 
				<QuizFormComponent 
					createQuiz={createQuiz}
					updateQuiz={updateQuiz}
					quizToUpdate={quizToUpdate}
					deleteQuizBackgroundAction={deleteQuizBackground}
					readOneQuizSlug={readOneQuizSlug}
					action={action}
				/>
			</Tab>
			<Tab eventKey="question-create-tab" title="Gestion des questions/reponses" unmountOnExit={true} disabled={action!=='update' }>
			
				<QuestionComponent
						quizToUpdate={quizToUpdate}
						createQuestionAction={createQuestion}
						updateQuestionAction={updateQuestion}
						deleteQuestionBackgroundAction={deleteQuestionBackground}
						modalProps={modalProps}
					/>
			
			</Tab>
		</Tabs>
			
		{modalComponent}
			
		</>
    )
}

export default UploadQuizComponent;