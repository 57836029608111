import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ParcelTableComponent from "../ParcelTableComponent";
import OrderLinesTableComponent from "../OrderLinesTableComponent";
import "./PreparatoryOrderDetailsComponent.scss";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";
import { useModal } from "../../../../hooks/useModal";
import HeadsetTableComponent from "../../../headset/HeadsetTableComponent";
import ButtonLoaderComponent from "../../../widgets/ButtonLoaderComponent";
import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { useToast } from "../../../../hooks/useToast";

const PreparatoryOrderDetailsComponent = ({
    order
}) => {
    const {fetchClientCRMById} = useStoreActions(action => action.clients);
    const [client,setClient] = useState({});
    const [isSending, setIsSending] = useState(false);
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const navigate = useNavigate();
    const {allHeadsets} = useCustomGetStoreState("headsets")
    const {validateOrder, unvalidateOrder, deleteOrder} = useStoreActions(actions => actions.orders);
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const toast = useToast();
    const [orderHeadsets, setOrderHeadsets] = useState([]);

    useEffect(() => {
        fetchClientCRMById(order.clientUniqueId)
            .then((data => {
                setClient({...client,...data});
            }));
    },[]);

    useEffect(() => {
      setOrderHeadsets(allHeadsets.filter((headset) => order?.headsets?.includes(headset.uniqueId)));
  }, [allHeadsets]);

    const orderSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('orders'));

    const selectedInvoice = order?.invoiceNumber && client?.invoices?.find(invoice => invoice.number === order.invoiceNumber);
    const displayInvoice = selectedInvoice ? 
        <>
            <a href={selectedInvoice.publicUrl} target="_blank">
                Facture du&nbsp;
                {HelperMetiers360.getdisplayDateType(selectedInvoice.date.date, 'day')}
            </a> | {selectedInvoice.amount > 0 ? selectedInvoice.amount:<span className='text-danger'>{selectedInvoice.amount}</span>} 
            {selectedInvoice.currency}&nbsp;|&nbsp;
            {selectedInvoice.paidAt ? 
                <>payée le {HelperMetiers360.getdisplayDateType(selectedInvoice.paidAt.date, 'day')}</> 
                : <>Non payée</>}
        </>
        :null;    

    const onValidateOrder = () => {
        setIsSending(true);
        const payload = {uniqueId:order.uniqueId};
        validateOrder(payload)
            .then(() => toast.success())
            .finally(() => setIsSending(false));
    }

    const onUnvalidateOrder = () => {
        setIsSending(true);
        const payload = {uniqueId:order.uniqueId};
        unvalidateOrder(payload)
            .then(() => toast.success())
            .finally(() => setIsSending(false));
    }

    const createWarningModal = () => {
      setModalData({
          ...modalData,
          header: <>Invalidation du bon de préparation</>,
          content: <h5>Ce bon a été transmis au prestataire par email. <br/>
            Il est nécessaire de le contacter afin que les modifications soient prises en compte. </h5>,
          resetButton: 'Invalider quand même',
          cancelButton: 'Annuler',
          onReset: () => {
            onUnvalidateOrder();
          },
      });
      setModalShow(true);
  }

    const goToClient = (uniqueIdClient) => {
      navigate(clientsSlugs.readOne.replace(':uniqueId', uniqueIdClient))
  }

  const deleteOrderModal = (order) => {
    setModalData({
        ...modalData,
        header: <>Suppression d'un bon de préparation</>,
        content: <h5>Etes-vous sûr⋅e de vouloir supprimer le bon du {HelperMetiers360.getdisplayDateType(order.updateDate.date, 'short')} ?</h5>,
        resetButton: 'Supprimer',
        cancelButton:'Annuler',
        onReset: () => {
            deleteOrder({ uniqueId: order.uniqueId })
                .then(() => {
                    setModalShow(false);
                    navigate(orderSlugs.readAll);
                })
        },
    });
    setModalShow(true);
  };

    const isEditable = order.statusValue === 'draft';
    const isValidatable = order.statusValue === 'draft' 
        && order?.recipientName !== null && order?.recipientName !== "" 
        && order?.recipientAdress1 !== null && order?.recipientAdress1 !== ""  
        && order?.postalCode !== null && order?.postalCode !== "" 
        && order?.city !== null && order?.city !== ""  
        && order?.providerName !== null && order?.providerName !== ""  
        && order?.deliveryDate !== null && order?.deliveryDate !== ""  
        && order?.emailRecipient !== null && order?.emailRecipient !== ""  
        && order?.phoneRecipient !== null && order?.phoneRecipient !== "" 
        && order?.orderlines.every((x) => x.quantity && parseInt(x.quantity) > 0);
    const isUnvalidatable = order.statusValue === 'validated';

    const updateOrderButton = orderSlugs.update ?
        <Button variant="secondary" as={Link} to={orderSlugs.update.replace(':uniqueId', order?.uniqueId)}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
        : null;

    const noValue = (name) => <span className="text-danger">
        <i className="fas fa-exclamation-triangle mr-1"/>{name ? (name + " n") : "N"}on indiqué
    </span>;

    return <Container>
        <Row className="row-midspace main-infos">
          <Col>
            <h3>
                Client : 
                <Button className="btn-link ms-2" onClick={(e) => { e.preventDefault(); goToClient(client?.uniqueId); }}>
                    <h3>{client?.name}</h3>
                </Button>
            </h3>

            { displayInvoice 
                ? <p>{displayInvoice}</p>
                : <div className="text-muted">
                    Pas de facture correspondante
                </div>
            }

            <p>Livraison souhaitée : &nbsp; <span className="fw-bold">{ 
                order?.deliveryDate 
                    ? HelperMetiers360.getdisplayDateType(order.deliveryDate, "day")
                    : noValue()
            }</span></p>

            <p>Prestataire :&nbsp; <span className="fw-bold">{ order?.providerName ? order?.providerName : noValue() }</span></p>
        </Col>

        <Col className="col-midspace">
            <h3>Informations de livraison</h3>
            <p><span className="fw-bold">{ order?.recipientName ? order?.recipientName : noValue("Contact") }</span></p>
            <p>{ order?.recipientSociety ? order?.recipientSociety : noValue("Etablissement de contact") }</p>
            <p>{ order?.recipientAdress1 ? order?.recipientAdress1 : noValue("Adresse de contact") }</p>
            <p>{ order?.recipientAdress2 ? order?.recipientAdress2 : <></> }</p>
            <p>{ order?.postalCode ? order?.postalCode : noValue("CP de contact") }</p>
            <p>{ order?.city ? order?.city : noValue("Ville de contact") }</p>
            <p>{ order?.phoneRecipient ? order?.phoneRecipient : noValue("Téléphone de contact") }</p>
            <p>{ order?.emailRecipient ? order?.emailRecipient : noValue("Email de contact") }</p>
        </Col>

        <Col className="col-midspace">
                <h3>Autres informations</h3>
                {order.isRenting !== null && <p className="fw-bold">{order.isRenting ? 'Location':'Vente'}</p>}
                <p>Mode Kiosk : <span className="fw-bold">{order.isKiosk ? 'Oui':'Non'}</span></p>
                <p>Inventaire client : <span className="fw-bold">{order.isClientInventory ? 'Oui':'Non'}</span></p>
            </Col>
        </Row>
        {order.orderlines.length > 0 &&
        <Row className="row-midspace">
            <Col>
                <h3>Contenu du bon de préparation</h3>
                    <OrderLinesTableComponent orderLines={order.orderlines}/>
                    {!order?.orderlines.every((x) => x.quantity && parseInt(x.quantity) > 0) && 
                        <div className="text-danger text-right mr-4 fw-bold">
                        <i className="fas fa-exclamation-triangle mr-1"/>Quantités non valides
                        </div>
                    }
            </Col>
        </Row>
        }
         {order.headsets.length > 0 &&
        <Row className="row-midspace">
            <Col>
                <h3>Casques associés</h3>
                <HeadsetTableComponent headsets={orderHeadsets} />    
            </Col>
        </Row>
        }
        {order.parcels.length > 0 &&
        <Row className="row-midspace">
            <Col>
                <h3>Colis</h3>
                    <ParcelTableComponent parcels={order.parcels}/>
            </Col>
        </Row>
        }
        <Row className="row-allcenter">
          <Col className="col-allcenter">
            <Card className="display-comments">
              <Card.Header className="fw-bold">Commentaire :</Card.Header>
              <Card.Body>{order.comment && <p>{order.comment}</p>}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="row-midspace row-allcenter">
            <Col className="col-allcenter">
              {isEditable && <div>{updateOrderButton}</div>}
            </Col>
            <Col className="col-allcenter">
              {order.isDeletable && 
              <Button variant="danger" onClick={() => deleteOrderModal(order)}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
              }
            </Col>
            <Col className="d-flex flex-column align-items-center">
              {isEditable && !isValidatable && !isUnvalidatable && 
                  <Alert variant='danger' className="warning-info">
                    Il manque des informations pour pouvoir valider ce bon de préparation
                  </Alert>}
                {isValidatable && <>
                  {order.isEmailSent && 
                    <Alert variant="warning" className="w-50">Attention ce bon a déjà été envoyé au prestataire logistique. Par conséquent, la validation de ce bon envoie un mail immédiatement au prestataire pour l'informer des modifications</Alert>
                  }
                  <ButtonLoaderComponent
                    isSending={isSending}
                    onClick={onValidateOrder}
                    variant="success"
                    msg={<><i className="fas fa-check-circle"></i>&nbsp;Valider</>} />
                    </>
                }
                {(isUnvalidatable && !order.isEmailSent) && <ButtonLoaderComponent 
                    isSending={isSending}
                    onClick={onUnvalidateOrder}
                    variant="danger"
                    msg={<><i className="fas fa-edit"></i>&nbsp;Invalider</>} />}
                {(isUnvalidatable && order.isEmailSent) && 
                <>
                <Alert variant="warning" className="w-50">Attention, ce bon a déjà été transmis au prestataire externe.</Alert>
                <ButtonLoaderComponent 
                    isSending={isSending}
                    onClick={()=>{createWarningModal()}}
                    variant="danger"
                    msg={<><i className="fas fa-edit"></i>&nbsp;Invalider</>} />
                </>}
            </Col>
        </Row>
        {modalComponent}
    </Container>;
}

PreparatoryOrderDetailsComponent.propTypes = {
    order: PropTypes.object.isRequired
};

export default PreparatoryOrderDetailsComponent;
