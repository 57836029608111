import React, { useEffect, useRef, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router';
import { Col, Form, InputGroup, Alert, Row } from 'react-bootstrap';


import SelectMultipleClientsComponent from '../../widgets/selectClient/SelectMultipleClientsComponent.jsx';
import ButtonLoaderComponent from '../../widgets/ButtonLoaderComponent.jsx';
import HelperMetiers360 from "../../../services/HelpersMetiers360";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);


const QuizFormComponent = ({
        createQuiz,
        updateQuiz,
        quizToUpdate,
        deleteQuizBackgroundAction,
		readOneQuizSlug,
        action
}) => {
    const navigate = useNavigate();
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;

	const {csrfToken, formName} = ACTIONS['quizzes'][action];

    const thumbnailFileInput = useRef(null);
	const [thumbnailFileName, setThumbnailFileName] = useState((quizToUpdate && quizToUpdate.links.thumbnail) ? 'changez le fichier' :'Sélectionnez un fichier');

	const introBackgroundFileInput = useRef(null);
	const [introBackgroundFileName, setIntroBackgroundFileName] = useState((quizToUpdate && quizToUpdate.links.introBackground) ? 'changez le fichier' :'Sélectionnez un fichier');

	const outroBackgroundFileInput = useRef(null);
	const [outroBackgroundFileName, setOutroBackgroundFileName] = useState((quizToUpdate && quizToUpdate.links.outroBackground) ? 'changez le fichier' :'Sélectionnez un fichier');

	const [isIntroBackgroundDeletable, setIsIntroBackgroundDeletable] = useState(!!(quizToUpdate && quizToUpdate.links.introBackground));
	const [isOutroBackgroundDeletable, setIsOutroBackgroundDeletable] = useState(!!(quizToUpdate && quizToUpdate.links.outroBackground));

	const [isPrivate, setIsPrivate] = useState(quizToUpdate ? quizToUpdate.private : true);

    const [selectedClients, setSelectedClients] = useState(quizToUpdate && quizToUpdate.producers ? quizToUpdate.producers.map(producer => producer.id).map(String) : []);

    const [isSending, setIsSending] = useState(false);
    const [isSendingIntroBg, setIsSendingIntroBg] = useState(false);
    const [isSendingOutroBg, setIsSendingOutroBg] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const [formErrors, setFormErrors] = useState({});
	const [isDirectlyAccessible, setIsDirectlyAccessible] = useState(action === 'create' ? true : (quizToUpdate?.isDirectlyAccessible ? quizToUpdate.isDirectlyAccessible : false))

	const [publishDate, setPublishDate] = useState(quizToUpdate?.releaseDate ? new Date(quizToUpdate?.releaseDate) : null);

	const redirect = (url) => {
		navigate(url, {replace:true, state: {fromEdit:true}});
	}

	useEffect(() => {
		setIsIntroBackgroundDeletable(!!(quizToUpdate && quizToUpdate.links.introBackground));
		setIsOutroBackgroundDeletable(!!(quizToUpdate && quizToUpdate.links.outroBackground));
	}, [quizToUpdate])

    const checkData = (e) => {
	    e.preventDefault();
	    let formData = new FormData(document.getElementById('quiz-form'));

	    let name = formData.get(`${formName}[name]`);		
		let thumbnail = formData.get(`${formName}[thumbnail]`);
		let introBackgroundInput = formData.get(`${formName}[introBackgroundInput]`);
		let outroBackgroundInput = formData.get(`${formName}[outroBackgroundInput]`);
		const errorList = {};

	    if (!name) {
			errorList.name = 'Veuillez saisir un nom pour ce quiz'
	    }

	    if (!publishDate) {
			errorList.releaseDate = 'Veuillez saisir une date de publication pour ce quiz'
	    }

		if ((!quizToUpdate) && (!thumbnail.size )) {
			errorList['thumbnail'] = 'Veuillez sélectionner une vignette';
        }

		if (thumbnail.size ) {
            if (thumbnail.size > fileContraints.MAX_SIZE_IMAGE_BYTES ) {
                errorList['thumbnail'] = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(thumbnail.type) ) {
                errorList['thumbnail'] = "Format de la vignette non autorisé";
            }
        }
        

		if (introBackgroundInput?.size ) {
            if (introBackgroundInput.size > fileContraints.MAX_SIZE_IMAGE_BACKGROUND_KO ) {
                errorList['introBackground'] = "Image de fond d'introduction trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(introBackgroundInput.type) ) {
                errorList['introBackground'] = "Format de l'image de fond d'introduction non autorisé";
            }
        }

		if (outroBackgroundInput?.size ) {
            if (outroBackgroundInput.size > fileContraints.MAX_SIZE_IMAGE_BACKGROUND_KO ) {
                errorList['outroBackground'] = "Image de fond de fin trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(outroBackgroundInput.type) ) {
                errorList['outroBackground'] = "Format de l'image de fond de fin non autorisé";
            }
        }



		if (Object.keys(errorList).length) {
            //errorList.global = 'Il y a eu un probleme lors de l\'enregistrement du quiz';
			setFormErrors(errorList);
		}
	    else {
		    setIsSending(true)
		    setFormErrors({});
		    submitForm();
	    }

    }

    const handleBadRequest = (error) => {
		const errorList = {};
		if (error && error.errors) {
			for (const key of Object.keys(error.errors)) {
				errorList[key] = error.errors[key]
			}
		}
		errorList.global = 'Il y a eu un probleme lors de l\'enregistrement du quiz';
		setFormErrors(errorList);
	}

    const submitForm = () => {
	    let formData = new FormData(document.getElementById('quiz-form'));

		let ActionFn;


		formData.append(`${formName}[releaseDate]`, HelperMetiers360.formatDateForFormControl(publishDate));

        const options = {
            formData,
            uniqueId: action === 'update' ? quizToUpdate.uniqueId : undefined
        }

        if (action === 'create') {
			ActionFn = createQuiz;
      
        } else {
			ActionFn = updateQuiz
		
		}
		ActionFn(options)
		.then(data => {
			if (action === 'create') {
				const {uniqueId : createdQuizUniqueId} = data;
				redirect(readOneQuizSlug.replace(':uniqueId', createdQuizUniqueId));
			} else if (action === 'update') {
				redirect(readOneQuizSlug.replace(':uniqueId', quizToUpdate.uniqueId));
			} else {
				setIsSuccess(true);
				setIsSending(false);
			}

		})
		.catch((error) => {
            handleBadRequest(error);
			setIsSending(false);
		})
        
    }

    const deleteIntroBackground = () => {
		setIsSendingIntroBg(true);
        const options = {
            uniqueId: quizToUpdate.uniqueId,
            actionName: 'deleteIntroBackground'
        }
        deleteQuizBackgroundAction(options)
			.then( () => {
				setIsIntroBackgroundDeletable(false);
			})
			.finally(() => {
				setIsSendingIntroBg(false)
			});
    }

	const deleteOutroBackground = (uniqueId) => {
		setIsSendingOutroBg(true)

		const options = {
            uniqueId: quizToUpdate.uniqueId,
            actionName: 'deleteOutroBackground'
        }
        deleteQuizBackgroundAction(options)
			.then( () => {
				setIsOutroBackgroundDeletable(false);

			})
			.finally(() => {
				setIsSendingOutroBg(false)
			});
	}

    return (
        <div className='quiz-form'>
	        <Form method="post" encType="multipart/form-data" id="quiz-form" className={formName}>
				{ formErrors.global ? <Alert variant="danger"> {formErrors.global}</Alert> : null}
				{isSuccess  ? <Alert variant="success">chargement terminé</Alert> : null}
		        <Row>
			        <Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Nom</InputGroup.Text>
					        <Form.Control
						        placeholder="Exemple: Qu'est-ce que l'entreprise ?"
						        name={`${formName}[name]`}
						        isInvalid={formErrors.name !== undefined}
						        defaultValue={quizToUpdate ? quizToUpdate.name : ''}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>

			        <Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Ordre</InputGroup.Text>
					        <Form.Control
						        placeholder="Exemple: 19"
						        name={`${formName}[position]`}
						        type="number"
						        isInvalid={formErrors.position !== undefined}
						        defaultValue={quizToUpdate ? quizToUpdate.displayOrder : ''}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.displayOrder}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>
		        </Row>

				<br/>

		        <Row>
			        <Col sm={12} md={6}>
					<Form.Label className='form-title'>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
				        <InputGroup>
						    <InputGroup.Text>Choisir la vignette</InputGroup.Text>
					        <div className="custom-file">
						        <input
							        id="thumbnailInput"
							        type="file"
							        name={`${formName}[thumbnail]`}
							        className={"custom-file-input" + (formErrors.thumbnail != undefined ? " is-invalid" : "")}
							        accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
							        ref={thumbnailFileInput}
							        onChange={() => setThumbnailFileName(thumbnailFileInput.current.files[0].name)}
						        />

						        <label className="custom-file-label" htmlFor="thumbnailInput" data-browse="Parcourir">
							        {thumbnailFileName}
						        </label>
					        </div>
					        <Form.Control
						        type="hidden"
						        isInvalid={formErrors.thumbnail !== undefined}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.thumbnail}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>

			        <Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Publication</InputGroup.Text>
							<DatePicker
								showIcon
								locale='fr'
								dateFormat="dd/MM/yyyy"
								selected={publishDate}
								onChange={(date) => {
										setFormErrors((prev) => ({...prev, releaseDate: null}));
										setPublishDate(date);
									}
								} 
								placeholderText="jj/mm/aaaa"
                    		/>
					        {formErrors?.releaseDate && <div className='invalid-feedback' style={{display: "block"}}>Veuillez indiquer une date de publication</div>}
				        </InputGroup>
			        </Col>
		        </Row>

		        <br/>

		        <Row>
			        <Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Couleur texte introduction</InputGroup.Text>
					        <Form.Control
						        name={`${formName}[introColor]`}
						        type="color"
						        defaultValue={quizToUpdate ? quizToUpdate.introColor : "#ffffff"}
						        isInvalid={formErrors.introColor !== undefined}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.introColor}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>

			        <Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Couleur texte fin</InputGroup.Text>
					        <Form.Control
						        name={`${formName}[outroColor]`}
						        type="color"
						        defaultValue={quizToUpdate ? quizToUpdate.outroColor : "#ffffff"}
						        isInvalid={formErrors.outroColor !== undefined}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.outroColor}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>
		        </Row>

		        <br/>

				<Row>
			        <Form.Group as={Col} sm={12} md={6} >
				        <Form.Label>Texte d'introduction</Form.Label>
						<Form.Control 
							name={`${formName}[introText]`}
							as="textarea" 
							rows={5}
					        defaultValue={quizToUpdate ? quizToUpdate.introText : ''}
						/>
				        <Form.Control.Feedback type='invalid'>{formErrors.introText}</Form.Control.Feedback>
			        </Form.Group>

					<Form.Group as={Col} sm={12} md={6} >
						<Form.Label>Texte de fin</Form.Label>
						<Form.Control 
							name={`${formName}[outroText]`}
							as="textarea" 
							rows={5}
					        defaultValue={quizToUpdate ? quizToUpdate.outroText : ''}
						/>
						<Form.Control.Feedback type='invalid'>{formErrors.outroText}</Form.Control.Feedback>
					</Form.Group>
				</Row>

		        <Row>
			        <Col sm={12} md={6}>
					<Form.Label className='form-title'>Image de fond introduction ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_BACKGROUND_MO} Mo max.)</Form.Label>
				        <InputGroup>
						    <InputGroup.Text>Choisir Image</InputGroup.Text>
					        <div className="custom-file">
						        <input
							        id="introBackgroundInput"
							        type="file"
							        name={`${formName}[introBackground]`}
							        className={"custom-file-input" + (formErrors.introBackground != undefined ? " is-invalid" : "")}
							        accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
							        ref={introBackgroundFileInput}
							        onChange={() => setIntroBackgroundFileName(introBackgroundFileInput.current.files[0].name)}
						        />

						        <label className="custom-file-label" htmlFor="introBackgroundInput" data-browse="Parcourir">
							        {introBackgroundFileName}
						        </label>
					        </div>

					        {isIntroBackgroundDeletable ?
						        <ButtonLoaderComponent variant='danger' isSending={isSendingIntroBg} disabled={isSendingIntroBg} onClick={deleteIntroBackground} msg={<i className="fas fa-trash-alt"/>}/> 
						        : null
					        }

					        <Form.Control
						        type="hidden"
						        isInvalid={formErrors.introBackground !== undefined}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.introBackground}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>

			        <Col sm={12} md={6}>
					<Form.Label className='form-title'>Image de fond fin ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_BACKGROUND_MO} Mo max.)</Form.Label>
				        <InputGroup>
						    <InputGroup.Text>Choisir Image</InputGroup.Text>
					        <div className="custom-file">
						        <input
							        id="outroBackgroundInput"
							        type="file"
							        name={`${formName}[outroBackground]`}
							        className={"custom-file-input"+ (formErrors.outroBackground != undefined ? " is-invalid" : "")}
							        accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
							        ref={outroBackgroundFileInput}
							        onChange={() => setOutroBackgroundFileName(outroBackgroundFileInput.current.files[0].name)}
						        />

						        <label className="custom-file-label" htmlFor="outroBackgroundInput" data-browse="Parcourir">
							        {outroBackgroundFileName}
						        </label>
					        </div>

					        {isOutroBackgroundDeletable ?
						        <ButtonLoaderComponent variant='danger' isSending={isSendingOutroBg} disabled={isSendingOutroBg} onClick={deleteOutroBackground} msg={<i className="fas fa-trash-alt"/>}/> 
						        : null
					        }

					        <Form.Control
						        type="hidden"
						        isInvalid={formErrors.outroBackground !== undefined}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.outroBackground}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>
		        </Row>

				<br/>
				
				<Form.Check type='checkbox' id='quiz_direct_access_code' name='quiz[isDirectlyAccessible]' inline>
					<Form.Check.Input
						type='checkbox'
						onChange={(event) => {
							setIsDirectlyAccessible(event.target.checked)
						}
						}
						name='quiz[isDirectlyAccessible]'
						defaultChecked={isDirectlyAccessible}
					/>
					<Form.Check.Label> <span>Cochez si le quiz est directement accessible via un code</span></Form.Check.Label>
				</Form.Check>
					
		        <br/>

				<Form.Check type='checkbox' id='quiz_private' name={`${formName}[private]`} inline>
                        <Form.Check.Input
                            type='checkbox'
                            onChange={ (event) => {
                                if (event.target.checked) {setIsPrivate(true)}
                                else {setIsPrivate(false)}
                                }
                            }
                            name={`${formName}[private]`}
                            defaultChecked={isPrivate}
                        />
                        <Form.Check.Label> <span>Cochez s'il s'agit d'un quiz privé</span> <i className={isPrivate ? "fas fa-lock" : "fas fa-lock-open"}> </i> </Form.Check.Label>
                </Form.Check>

				<Row>
					<Col className="mb-3 mt-4" sm={12} md={12}>
						<Form.Label><h3>Producteur(s)</h3></Form.Label>
						<SelectMultipleClientsComponent
							nameSelect={`${formName}[producers][]`}
							selectedClients = {selectedClients}
							setSelectedClients = {setSelectedClients} />
					</Col>
				</Row>

		        <Form.Group >
			        <Form.Control
				        name={`${formName}[_token]`}
				        value={csrfToken}
				        type="hidden"
			        />
		        </Form.Group>

					<ButtonLoaderComponent
						active={!(isSendingIntroBg || isSendingOutroBg)}
						onClick={checkData} 
						isSending={isSending}
						disabled={isSending || isSendingIntroBg || isSendingOutroBg}
						msg={quizToUpdate && quizToUpdate.uniqueId ? <><i className="fas fa-save"/> &nbsp;Éditer</>:<><i className="fas fa-save"/> &nbsp;Sauvegarder</>}
					/>

	        </Form>
        </div>
    )
}

export default QuizFormComponent;