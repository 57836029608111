import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import HelperMetiers360 from '../../../services/HelpersMetiers360';

const SubscriptionFormComponent = ({subscription, action}) => {
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    
    const {csrfToken, formName} = ACTIONS['subscriptions'][action];
 
	return (
		<div >
			<Form method="post" encType="multipart/form-data" id="subscription-form" className={formName}>
				<Row>
					<Form.Group as={Col}  >
						<Form.Label>Nom de l'abonnement <i>(visible par le client)</i></Form.Label>
						<Form.Control 
							name={`subscriptionName`} 
							defaultValue={subscription ? subscription?.subscriptionName : 'Abonnement ' + HelperMetiers360.getdisplayDateType(Date.now(),'day') }
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer le nom de l'abonnement</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
				<hr/>

                <Row>
					<Form.Group as={Col} >
						<Form.Label>Date de fin</Form.Label>
						<Form.Control
							name={`subscriptionEndDate`}
							type="date"
							defaultValue={subscription?.subscriptionEndDate ? 
								HelperMetiers360.formatDateForFormControl(subscription.subscriptionEndDate) 
								: ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer une date de fin de l'abonnement</Form.Control.Feedback>
					</Form.Group>
				</Row>
               
               <hr/>
            
                <Row>
                   <Form.Group as={Col}  >
                       <Form.Label>Nombre de casques prévu</Form.Label>
                       <Form.Control
                        name={`headsetNumberPredicted`}
                        type="number"
                        defaultValue={subscription?.headsetNumberPredicted}
						min={0}
                        />
                        <Form.Control.Feedback type='invalid'>Veuillez saisir le nombre de casque prévu pour l'abonnement</Form.Control.Feedback>
					</Form.Group>
                </Row>

				<Row>
				    <Form.Group >
					<Form.Control
						name={`subscriptionCsrfToken`}
						value={csrfToken}
						type="hidden"
					/>
				    </Form.Group>
				</Row>
			</Form>
		</div>
	);
}

export const submitSubscriptionForm = ({action, client, subscription, updateSubscription, createSubscription}) => {
    let error = false;

	const resetErrorDisplay = () => {		
		document.getElementsByName('subscriptionName')[0]?.classList.remove("is-invalid");
		document.getElementsByName('subscriptionEndDate')[0]?.classList.remove("is-invalid");
		document.getElementsByName('headsetNumberPredicted')[0]?.classList.remove("is-invalid");
	}

	const submitForm = (data) => {
        if(action == 'update' && updateSubscription && subscription){
		    return updateSubscription({data: data, uniqueId: subscription.uniqueId})
				.catch(() => {
					return new Error();
				})
        }  
		else if (action == 'create' && createSubscription && client){
			return createSubscription({subscription: data, client: {uniqueId: client.uniqueId, clientName:client.name }})
				.catch(() => {
					return new Error();
				})
		} else {
			return Promise.reject(new Error());
		}
	}

	let formData = new FormData(document.getElementById('subscription-form'));
	
	resetErrorDisplay();

	let subscriptionEndDate = formData.get(`subscriptionEndDate`);
	let headsetNumberPredicted= formData.get(`headsetNumberPredicted`);
	let subscriptionName = formData.get(`subscriptionName`)
		
	if (subscriptionName == "") {
		document.getElementsByName('subscriptionName')[0].classList.add("is-invalid");
		error = true;
	}
	if (!subscriptionEndDate) {
		document.getElementsByName('subscriptionEndDate')[0].classList.add("is-invalid");
		error = true;
	}
	if (!headsetNumberPredicted) {
		document.getElementsByName('headsetNumberPredicted')[0].classList.add("is-invalid");
		error = true;
	}

	if (error) {
		return Promise.reject(new Error());
	} else {
		const data = {
			subscriptionName:formData.get(`subscriptionName`),
			headsetNumberPredicted: headsetNumberPredicted,
			subscriptionEndDate:subscriptionEndDate,
			subscriptionCsrfToken:formData.get(`subscriptionCsrfToken`)
		};
		return submitForm(data);
	}
}

export default SubscriptionFormComponent;