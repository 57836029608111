import  React, {useCallback, useRef, useState} from 'react';
import { debounce } from 'lodash';
import { InputGroup, Form } from 'react-bootstrap';

/**
 * 
 * custom hook that handles the search form in our table views
 * We don't populate search for each keystroke anymore
 * we use debounce for loadash to delay the set search and wait for the user to stop typing for {delay} ms
 * before triggering setSearch which will trigger the filter on the list.
 * 
 * @param {string} placeHolder string to personnalise input placeholder
 * @param {number} delay delay of the debounce in ms ()
 * @returns {{searchInput, search}}
 * 
 * @example const {searchInput, search} = useDebounceSearch({placeHolder: "any string", delay: 500})
 */

const useDebounceSearch = ({placeholder = "Rechercher par mots-clés", delay = 300, defaultValue = '', width = null, autocomplete = 'on'}) => {
    const [search, setSearch] = useState(defaultValue);
    const currentSearch = useRef(defaultValue);
    const formRef = useRef(null);

    const searchInput =
    <InputGroup.Text id="inputGroupPrepend">
        <i className="fas fa-search"></i>
    
        <Form.Control
            id="searchBar"
            ref={formRef}
            autoComplete={autocomplete}
            type='text'
            placeholder={placeholder}
            aria-describedby="inputGroupPrepend"
            defaultValue={defaultValue}
            onChange={(e) => handleSearch(e)}
            style={{"width": width !== null && width}}
            />
        { search && <p className="search-clear-icon" onClick={() => resetSearchBar()}  onKeyDown={() => resetSearchBar()}>X</p> }
    </InputGroup.Text>;

    const debouncedFilter = useCallback(
        debounce((search) => {
            setSearch(search.current.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase());
        }, delay),
     []);

    const handleSearch = (e) => {
        currentSearch.current = e.target.value;
       debouncedFilter(currentSearch);
    }

    const resetSearchBar = () => {
      setSearch("");
      if(formRef.current) {formRef.current.value = ""};
    }

    return { searchInput, search, resetSearchBar }
}

export default useDebounceSearch;