import React, { useState, useEffect } from "react";
import { Row, Col, ToggleButton, ToggleButtonGroup, Button, Alert } from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from "react-router-dom";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import Loader from "../../components/widgets/Loader.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import VideoTableComponent from "../../components/videos/videosList/VideosTableComponent.jsx";
import VideoBoardComponent from "../../components/videos/videosList/VideoBoardComponent.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import "./VideosPage.scss";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import PropTypes from 'prop-types';

const VideoTableWithParams = withURLParamsTableComponent(VideoTableComponent);

const VideosPageContent = ({ videos, isVideosLoading, displayCreationBtn = false }) => {

    // preferences from the store that is hydrated from local storage
    const viewStylePreference = useStoreState(state => state.preferences.videoListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setVideoListStyle);

    const videoBoardSortType = useStoreState(state => state.preferences.videoBoardSortType);
    const setvideoBoardSortType = useStoreActions(actions => actions.preferences.setvideoBoardSortType);
    const videoSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("videos"));

    const [filteredVideos, setFilteredVideos] = useState(videos);

    const { searchInput, search } = useSearchWithParams({
        placeholder: 'Rechercher par mots-clés (nom, titre secondaire, description...)',
        delay: 400,
    })

    const viewStyleType = [
        { value: 'cards', label: <i className="fas fa-address-card"></i>, view: <VideoBoardComponent videos={filteredVideos} sortTypePreferenceTuple={[videoBoardSortType, setvideoBoardSortType]} {...videoSlugs} /> },
        { value: 'table', label: <i className="fas fa-table"></i>, view: <VideoTableWithParams videos={filteredVideos} {...videoSlugs} /> },
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference ?? 'cards');

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    useEffect(() => {
        setFilteredVideos(videos)
    }, [videos]);

    useEffect(() => {
        setFilteredVideos(
            [...videos.filter(
                video => HelperMetiers360.isSearchInText(search, video.videoName)
                    || HelperMetiers360.isSearchInText(search, video.subHeading)
                    || HelperMetiers360.isSearchInText(search, video.description)
                    || (AuthorizationChecker.isAdmin() && HelperMetiers360.isSearchInText(search, video.commentM360))
                    || HelperMetiers360.isSearchInText(search, video.romes?.join())
                    || HelperMetiers360.isSearchInText(search, video.producers
                        ?.map(producer => producer.clientName)?.join())
            )]
        );

    }, [search, videos]);

    const isNoVideos = !isVideosLoading && videos.length === 0;
    const isNoResult = !isVideosLoading && videos.length > 0 && filteredVideos.length === 0;

    return <>
        <Row className="item-page">
            <Col>
                {searchInput}
            </Col>
            <Col className="col-auto">
                <ToggleButtonGroup type="radio" name="option-view-romes" value={viewStyle} onChange={handleChange}>
                    {checkViewStyle}
                </ToggleButtonGroup>
            </Col>
            {displayCreationBtn && videoSlugs.create
                && <Col className="col-auto">
                    <Button variant="success" as={Link} to={videoSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
                </Col>}
        </Row>

        <Row>
            {isVideosLoading && <Loader />}
            {filteredVideos.length > 0 && viewStyleType.filter(type => type.value === viewStyle)[0].view}
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucune expérience immersive ne correspond à votre recherche</Alert>}
            {isNoVideos && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucune expérience immersive</Alert>}
        </Row>
    </>;
};

VideosPageContent.propTypes = {
    videos: PropTypes.array.isRequired,
    isVideosLoading: PropTypes.bool.isRequired,
    displayCreationBtn: PropTypes.bool,
};

export default VideosPageContent;