import React, { useEffect, useState } from 'react';

import useInfiniteLoadingLogic from '../../../hooks/useInfiniteLogic.jsx';
import DynamicTableInfinite from '../../widgets/dynamicTableInfinite/DynamicTableInfinite.jsx';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import AuthorizationChecker from '../../../services/AuthorizationChecker.js';

const UserTableLogsComponent = (props) => {
    const { users, withParams } = props;
    const { isFetchingAllClients } = useCustomGetStoreState("clients");

    const [sortedUsers, setSortedUsers] = useState([]);
    const [usersToDisplay, setUsersToDisplay] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    useEffect(() => {
        setShouldSort(true)
        setSortedUsers([...users]);
    }, [users]);

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedUsers, usersToDisplay, setUsersToDisplay, 50);

    if (!users.length || isFetchingAllClients) return <></>;

    const contentSort = [
        {
            value: 'createdAt', label: 'Date',
            test: users[0].createdAt !== undefined,
            method: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            display: (user) => HelperMetiers360.getdisplayDateType(user.createdAt, AuthorizationChecker.isAdmin() ? 'long' : 'day')
        },
        {
            value: 'device', label: "Appareil",
            test: users[0]?.device !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.device, b.device),
            display: (user) => user.device
        },
        {
            value: 'deviceVersion', label: "Version d'appareil",
            test: users[0]?.deviceVersion !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.deviceVersion, b.deviceVersion),
            display: (user) => user.deviceVersion ? user.deviceVersion : 'N/A'
        },

    ]

    return  <>
        
        <DynamicTableInfinite
            contentTable = {usersToDisplay}
            contentSort = {contentSort}
            index = 'id'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedUsers}
            sortedContent={sortedUsers}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            />
    </>;
}

export default UserTableLogsComponent;