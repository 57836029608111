import  { useEffect, useState } from "react";

import useCancel from "./useCancel.jsx";
import { useStoreState, useStoreActions } from 'easy-peasy';

// /api/admin/story/get-some-as-json
const useStories = (props) => {
    let {numberOfStoriesToFetch} = (props ? props : {});
	const [storyLength, setStoryLength] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [lastResponseLength, setLastResponseLength] = useState(0);
	const [lastIndex, setLastIndex] = useState(-1);

	const {isCancel, cancelToken, tokenSource} = useCancel();
	const {fetchSomeStories} = useStoreActions(actions => actions.stories);
	const {isUpdateNecessary} = useStoreState(state => state.stories);

    const fetchStories = (firstFetch = false) => {
		
		const firstIndex = lastIndex + 1;
		setIsLoading(true);
		const params = {
			firstFetch,
			from: firstIndex,
			number: numberOfStoriesToFetch,
		}
		return fetchSomeStories({ params, cancelToken: cancelToken() })
			.then((response) => {
				setIsLoading(false);
				setLastResponseLength(response.length);
				setLastIndex( response[0] ? response.slice(-1)[0].id : 0);
				setStoryLength((prev) => prev + response.length);
			})
			.catch((err) => {
				// DON'T UPDATE STATE IF CANCEL REQUEST TO AVOID MEM LEAKS
				if (!isCancel(err)) {
					setIsLoading(false);
				}
			});
	};

	useEffect(() => {

		if ( isUpdateNecessary) {
			fetchStories(true)
		}
		// WE DON'T NEED TO CANCEL REQUEST ON UNMOUNT, USECANCEL DOES IT FOR US
	}, [isUpdateNecessary]);

	useEffect(() => {
		// WE HAVE TO CANCEL THE REQUEST HERE BECAUSE USEEFFECT WITH DEPENDANCIES NOT HANDLED BY
		// USE CANCEL
		if (!isLoading && (lastResponseLength === numberOfStoriesToFetch)) {
			fetchStories();
		}
	}, [storyLength]);

	return { isLoading }
}

export default useStories;