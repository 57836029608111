import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonLoaderComponent from "../ButtonLoaderComponent.jsx";
import './Modal.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * centered modal widget
 *
 * @version 1.0.0
 * @param {function} validate  callback function
 * @param {function} cancel  callback function
 * @param {string} content  body content
 * @param {string} header  title content
 * @param {string} validatebutton  validation button label
 * @param {string} cancelbutton  cancel button label
 * @param {boolean} isSending  default false
 * @param {string} id id to add custom style to the modal
 * @example
 *      <CenteredModal
            show={modalControl.modalShow}
            header={'En-tête du modal'}
            content={'Le contenu du modal'}
            validatebutton={"Valider"}
            cancelbutton={"Fermer"}
            onHide={() => setModalControls({ ...modalControl, modalShow: false })}
            validate={modalControl.onValidate ? modalControl.onValidate : null}
            cancel={modalControl.onCancel ? modalControl.onCancel : () => setModalControls({ ...modalControl, modalShow: false })}
        />
 */

CenteredModal.propTypes = {
  validate: PropTypes.any,
  cancel: PropTypes.any,
  content: PropTypes.any,
  header: PropTypes.any,
  validatebutton: PropTypes.any,
  cancelbutton: PropTypes.any,
  isSending: PropTypes.bool,
  reset: PropTypes.any,
  resetButton: PropTypes.any,
  resetDisabledReason: PropTypes.string
};

export default function CenteredModal(props) {
    const {validate, cancel, content, header, validatebutton, cancelbutton, isSending, reset, resetButton,
      resetDisabledReason=null, ...other} = props;
    return (
      
      <Modal
        {...other}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {header ?? 'Titre par défaut'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content ?? 'Contenu par défaut'}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant= "secondary"
            onClick={cancel}>{cancelbutton
              ?? <><i className="fas fa-window-close me-2"></i>Fermer</>}
          </Button> 
          {reset
            ? resetDisabledReason 
              ? <OverlayTrigger placement="top" overlay={<Tooltip>{resetDisabledReason}</Tooltip>}>
                  <span><Button variant="danger" disabled>{resetButton ?? "réinitialiser"}</Button></span>
                </OverlayTrigger>
              : <ButtonLoaderComponent onClick={reset} variant="danger" 
                msg={resetButton ?? "réinitialiser"} isSending={isSending} /> 
            : null
          }
          {validate ? 
            <ButtonLoaderComponent
              onClick={props.validate}
              variant= "success"
              msg={validatebutton
                ?? <div className="d-flex"><i className="fas fa-check me-2"></i>Valider</div>}
              isSending={isSending}
            />
            : null 
          }
        </Modal.Footer>
      </Modal>
    );
  }