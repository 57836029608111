import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useStoreActions, useStoreState} from 'easy-peasy';
import PropTypes from 'prop-types';

import useSearchWithParams from "../../../hooks/useSearchWithParams";
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';

import SelectItemsComponent from '../../../components/widgets/selectItemsComponent/SelectItemsComponent.jsx';
import './InteractionsFiltersComponent.scss';
import Loader from '../../../components/widgets/Loader';
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);


const InteractionsFiltersComponent = ({ fetchInteractions }) => {
    const { searchInput, search, resetSearchBar } = useSearchWithParams({ 
        placeholder: 'Rechercher par mots-clés (titre, type, établissement...)', 
        delay: 400
    });

    const interactionsFiltersPreference = useStoreState(state => state.preferences.interactionsFilters);
    const setInteractionsFiltersPreference = useStoreActions(actions => actions.preferences.setInteractionsFilters);

    const { fetchContactsM360 } = useStoreActions(actions => actions.clients);
    const { fetchInteractionTypes } = useStoreActions(actions => actions.interactions);
    const { allTagOperations } = useCustomGetStoreState("tagOperations");
    const { contactsM360 } = useStoreState(state => state.clients);
    const { interactionTypes } = useStoreState(state => state.interactions);

    const [areFiltersApplied, setAreFiltersApplied] = useState(false);
    const [isFirstLoadFilters, setIsFirstLoadFilters] = useState(true);

    const [isFiltersOpen, setIsFiltersOpen] = useState(true);

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    
    const contactsM360SelectorRef = useRef(null);
    const typesSelectorRef = useRef(null);
    const tagOperationsSelectorRef = useRef(null);
    const notAxonautRef = useRef(false);
    const notDoneRef = useRef(false);

    useEffect(() => {
        Promise.all([
            fetchInteractionTypes(),
            fetchContactsM360()
        ]).then(() => {
            setFiltersFromPreferences();
            fetchInteractionsFromAPI();
            setIsFirstLoadFilters(false);
        });
    }, []);

    useEffect(() => {
        checkIfFiltersApply();
    }, [search]);

    const setFiltersFromPreferences = () => {
       
        setMinDate(interactionsFiltersPreference.dateFrom);
        setMaxDate(interactionsFiltersPreference.dateTo);
        if (contactsM360SelectorRef?.current) {
            contactsM360SelectorRef.current.value = interactionsFiltersPreference.contactM360Id;
        }
        if (typesSelectorRef?.current) {
            typesSelectorRef.current.value = interactionsFiltersPreference.typeId;
        }
        if (tagOperationsSelectorRef?.current) {
            tagOperationsSelectorRef.current.value = interactionsFiltersPreference.tagOpeId;
        }
        if (notDoneRef?.current) {
            notDoneRef.current.checked = interactionsFiltersPreference.notDone;
        }
        if (notAxonautRef?.current) {
            notAxonautRef.current.checked = interactionsFiltersPreference.notAxonaut;
        }

        checkIfFiltersApply();
    }

    const resetFilters = () => {
        resetSearchBar();
        setMinDate(null);
        setMaxDate(null);
        if(typesSelectorRef.current?.value) typesSelectorRef.current.value = "";
        if(tagOperationsSelectorRef.current?.value) tagOperationsSelectorRef.current.value = "";
        if(contactsM360SelectorRef.current?.value) contactsM360SelectorRef.current.value = "";
        if(notDoneRef.current) notDoneRef.current.checked = false;
        if(notAxonautRef.current) notAxonautRef.current.checked = false;

        setAreFiltersApplied(false);
    }

    const checkIfFiltersApply = () => {
        const typeId = typesSelectorRef?.current?.value ?? null;
        const tagOperationId = tagOperationsSelectorRef?.current?.value ?? null;
        const contactM360Id = contactsM360SelectorRef?.current?.value ?? null;
        const notDone = notDoneRef?.current?.checked ?? false;
        const notAxonaut = notAxonautRef?.current?.checked ?? false;

        setAreFiltersApplied(search || minDate || maxDate || typeId || contactM360Id 
            || tagOperationId || notDone || notAxonaut);
    }

    const applyFilters = () => {
        const typeId = typesSelectorRef?.current?.value ?? null;
        const tagOperationId = tagOperationsSelectorRef?.current?.value ?? null;
        const contactM360Id = contactsM360SelectorRef?.current?.value ?? null;
        const notDone = notDoneRef?.current?.checked ?? false;
        const notAxonaut = notAxonautRef?.current?.checked ?? false;

        setInteractionsFiltersPreference({
            dateFrom: minDate,
            dateTo: maxDate,
            typeId: typeId,
            tagOpeId: tagOperationId,
            contactM360Id: contactM360Id,
            notDone: notDone,
            notAxonaut: notAxonaut
        });
        fetchInteractionsFromAPI();
        setAreFiltersApplied(search || minDate || maxDate || typeId || contactM360Id 
            || tagOperationId || notDone || notAxonaut);
    }

    const fetchInteractionsFromAPI = () => {
        fetchInteractions({
            data: {
                keyWord: search,
                fromDate: minDate
                    ? HelperMetiers360.displayDateTotimestamp(HelperMetiers360.formatDateForFormControl(minDate) + " 00:00:00")
                    : null,
                toDate: maxDate
                    ? HelperMetiers360.displayDateTotimestamp(HelperMetiers360.formatDateForFormControl(maxDate) + " 23:59:59")
                    : null,
                type: typesSelectorRef?.current?.value !== "" && typesSelectorRef?.current?.value !== undefined
                    ? [parseInt(typesSelectorRef?.current?.value)]
                    : [],
                tagOperations: tagOperationsSelectorRef?.current?.value !== "" && tagOperationsSelectorRef?.current?.value !== undefined
                    ? tagOperationsSelectorRef?.current?.value === "aucun"
                        ? 'aucun'
                        : [parseInt(tagOperationsSelectorRef?.current?.value)]
                    : [],
                contactsM360: contactsM360SelectorRef?.current?.value !== "" && contactsM360SelectorRef?.current?.value !== undefined
                    ? [parseInt(contactsM360SelectorRef?.current?.value)]
                    : [],
                isDone: notDoneRef?.current?.checked 
                    ? false
                    : null,
                isNotAxonaut: notAxonautRef?.current?.checked === true
                    ? true
                    : null
            }
        });
    }

    return (<>
        <Row className={"d-flex align-items-center pe-0"}>
            <Col className="d-flex align-items-center">
                <div id="btnFilters" className="cursor-pointer shadow"
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                    onKeyDown={() => setIsFiltersOpen(!isFiltersOpen)}>
                    <p>Zone de recherche</p>
                    { isFiltersOpen 
                        ? <i className="fa fa-caret-up ms-3"></i>
                        : <i className="fa fa-caret-down ms-3"></i>
                    }
                </div>
                { areFiltersApplied && 
                    <OverlayTrigger placement="top"
                        overlay={ <Tooltip>Effacer les filtres</Tooltip> }>
                        <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { resetFilters()} }>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </OverlayTrigger>
                }
                { isFirstLoadFilters && 
                    <div id="loader-first-loading-filter"><Loader/></div>
                }
            </Col>
            <Col className="pe-0">
                {searchInput}
            </Col>
        </Row>
        <div id="filtersZone" className={!isFiltersOpen ? "hide-filters" : undefined}> 
            <Row className="d-flex align-items-center justify-content-between">
                <Col className="m360-col3-start" md={12} lg={6} xl={4}>    
                    <div className="d-flex align-items-center">
                        <label className="label-select">Date&nbsp;: </label>
                        <DatePicker
                            showIcon
                            locale='fr'
                            id='start'
                            dateFormat="dd/MM/yyyy"
                            selected={minDate}
                            onChange={(date) => setMinDate(date)}
                            placeholderText="jj/mm/aaaa"
                            maxDate={maxDate}
                        />

                        <label htmlFor="end" className="m-0">&nbsp;au</label>
                        <DatePicker
                            showIcon
                            locale='fr'
                            id='end'
                            dateFormat="dd/MM/yyyy"
                            selected={maxDate}
                            onChange={(date) => setMaxDate(date)}
                            placeholderText="jj/mm/aaaa"
                            minDate={minDate}
                        />
                    </div>
                </Col>
                <Col className="m360-col3-center" md={6} lg={4}>
                    { interactionTypes?.length > 0 
                        ? <div className="d-flex align-items-center">
                            <label className="label-select">Type&nbsp;: </label>
                            <SelectItemsComponent selectRef={typesSelectorRef} onChange={checkIfFiltersApply}
                                items={interactionTypes} itemKey="id" itemValue="name" maxWidth={"28em"} />
                        </div>
                        : <div className="loader-lists"><Loader /></div>
                    }
                </Col>
                <Col className="m360-col3-end" md={6} lg={4}>
                    { allTagOperations?.length > 0 
                        ? <div className="d-flex align-items-center">
                            <label className="label-select">Opé M360&nbsp;: </label>
                            <SelectItemsComponent selectRef={tagOperationsSelectorRef} 
                                onChange={checkIfFiltersApply}
                                items={allTagOperations} itemKey="id" itemValue="name" maxWidth={"28em"} 
                                labelNone={"opération"} />
                        </div>
                        : <div className="loader-lists"><Loader /></div>
                    }
                </Col>
            </Row>
            <Row className="d-flex align-items-center mt-2">
                <Col className="m360-col3-start" md={4}>
                    { contactsM360?.length > 0 
                        ? <div className="d-flex align-items-center">
                            <label className="label-select">Contact M360&nbsp;: </label>
                            <SelectItemsComponent selectRef={contactsM360SelectorRef} 
                                onChange={checkIfFiltersApply}
                                items={contactsM360} itemKey="id" itemValue="name" maxWidth={"28em"} />
                        </div>
                        : <div className="loader-lists"><Loader /></div>
                    }
                </Col>
                <Col className="m360-col3-center" md={4}>
                    <Form.Check id="notDone" className="clickable" label="Interactions non réalisées" 
                        onChange={checkIfFiltersApply} ref={notDoneRef}/>
                </Col>
                <Col className="m360-col3-end" md={4}>
                    <Form.Check id="notAxonaut" className="clickable" label="Interactions non Axonaut" 
                        onChange={checkIfFiltersApply} ref={notAxonautRef}/>
                </Col>
            </Row>
            <div className="mt-3 d-flex justify-content-center">
                <Button variant="success" onClick={applyFilters}>Lancer la recherche</Button>
            </div>
        </div>
    </>)
}

InteractionsFiltersComponent.propTypes = {
    fetchInteractions: PropTypes.any.isRequired
};

export default InteractionsFiltersComponent