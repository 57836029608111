import React, {useState} from 'react';
import {Container, Row, Button} from 'react-bootstrap';
import './MultiUsersOrContactsInfoComponent.scss';
import { CSVLink } from 'react-csv';
import SelectMultipleClientsComponent from '../../widgets/selectClient/SelectMultipleClientsComponent';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState';

const MultiUsersOrContactsInfoComponent = ({entityType, clientUniqueIdsSelected = [], selectedUniqueIdParentClientDefault = null}) => {
    const { allClients } = useCustomGetStoreState("clients");
    const isEntityUser = entityType === "user";

    const [clientUniqueIds, setClientUniqueIds] = useState(clientUniqueIdsSelected)

    const headersCsv = [
        { label: "Nom client", key: "firstname" },
        { label: "UniqueId client", key: "clientUniqueId" },
        { label: "Email", key: "email" },
        { label: "Prénom", key: "firstName" },
        { label: "Nom", key: "lastname" },
        { label: "Fonction", key: "job" },
        { label: "Téléphone", key: "phoneNumber" },
        { label: "Mobile", key: "cellphoneNumber" },
    ]

    const dataCsv = clientUniqueIds.map(uniqueId => {
        const clientName = allClients.find(c => c.uniqueId === uniqueId)?.clientName;
        return {
            firstname: clientName,
            clientUniqueId: uniqueId,
            email: null,
            firstName: null,
            lastname: null,
            job: null,
            phoneNumber: null,
            cellphoneNumber: null,
        }            
    });

    return <>
        Pour ajouter plusieurs { isEntityUser ? 'utilisateur⋅rices' : 'contacts' } à plusieurs clients, il faut se munir d'un fichier csv comportant à minima
        les uniqueId des clients et les emails des { isEntityUser ? 'utilisateur⋅rices' : 'contacts' }.<br/>
        Pour vous aider à la préparation de ce fichier vous avez la possibilité de sélectionner plusieurs clients et de télécharger :&nbsp;
        <strong>un fichier csv prêt à l'emploi</strong> (incluant l'uniqueId et le nom des clients sélectionnés)
        <Container>
            <Row>
                <SelectMultipleClientsComponent
                    selectedClients = {clientUniqueIds}
                    setSelectedClients = {setClientUniqueIds} 
                    selectedUniqueIdParentClientDefault={selectedUniqueIdParentClientDefault} />
            </Row>
            <Row className='d-flex justify-content-center'>
                <CSVLink className='text-decoration-none w-auto'
                    filename={"ajout_multiple_"
                        + (isEntityUser ? "utilisateurs_" : "contacts_")
                        + new Date().toISOString().split('T')[0] + ".csv"}
                    headers={headersCsv}
                    data={dataCsv}
                    >
                    <Button variant="primary" size="sm" className='w-auto'>
                        <i className="fas fa-download"/>&nbsp;Télécharger le fichier csv
                    </Button>
                </CSVLink>
            </Row>
        </Container>
    </>;
}

export default MultiUsersOrContactsInfoComponent;