import { useEffect, useRef, useState } from "react";
import { Alert, CardGroup } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader";
import MiniCardMediaComponent from "../miniCardMedia/MiniCardMediaComponent";
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import PropTypes from 'prop-types';


const InfinitePaginationListComponent = ({ mediaList: originalList, mediaType, onClickItem = null, maxVisibleItem, deleteMedia = null}) => {

    const startIndex = useRef(0);
    const [hasMore, setHasMore] = useState(true);
    const [listToDisplay, setListToDisplay] = useState([]);
    const scrollableDiv = useRef(null);

    maxVisibleItem = maxVisibleItem ? + maxVisibleItem : undefined


    useEffect(() => {
        if ((scrollableDiv?.current.offsetHeight === scrollableDiv?.current.scrollHeight) && hasMore) {
            fetchNext(maxVisibleItem)
        }
    })

    useEffect(() => {
            startIndex.current = 0;
            firstFetch(maxVisibleItem);
    }, [originalList]);

    useEffect(() => {
        if (originalList.length <= startIndex.current) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [listToDisplay, originalList])

    const firstFetch = (step = 10) => {
        const endIndex = step;

        setListToDisplay((prev) => {
            const next = originalList.slice(0, endIndex);
            return [...next];
        })

        startIndex.current += step;
    }

    const fetchNext = (step = 10) => {
        let endIndex = +startIndex.current + step;
        let start = startIndex.current;
        startIndex.current += step;
        
        setListToDisplay((prev) => {
            const next = originalList.slice(start, endIndex);
            return [...prev, ...next];
        })


    }

    const cards = mediaType === "comments" ?
        listToDisplay?.map(c => (
            <div key={c.uniqueId} className='card-comment shadow-element'>
                {c.isManuallyAdded && <div className='btn-delete-comment' onClick={() => {deleteMedia(c)}}>
                    <i className="fas fa-trash-alt"></i>
                </div>}
                <div className='content-comment display-linebreak'>{c.content}</div>
                <div className='footer-comment'>
                    <p className='date-comment'>{HelperMetiers360.getdisplayDateType(c.createdAt?.date, 'day')}</p>
                    <p className='author-comment'>{c.author}</p>
                </div>
            </div>
        ))
        : listToDisplay
        ?.map(media => {
            return (
                <div className={`playlist-${mediaType}-card`} key={media.uniqueId}>
                    <MiniCardMediaComponent media={media} mediaType={mediaType} isLink={false}  onClickItem={onClickItem}/>
                </div>
            )
        });


    return <div id={`${mediaType}-scrollable-elem`} ref={scrollableDiv} style={{  maxHeight: 700, overflowY: 'auto', marginBottom: "1rem"}}>
        <InfiniteScroll
            scrollableTarget={`${mediaType}-scrollable-elem`}
            dataLength={listToDisplay.length} //This is important field to render the next data
            next={() => fetchNext(maxVisibleItem)}
            hasMore={hasMore}
            loader={<Loader />}
            scrollThreshold={0.99}>
            <CardGroup className="justify-content-around">
                {cards.length > 0 && cards}
                {!cards.length && <Alert variant="warning" className="w-100">Aucun resultat</Alert>}
            </CardGroup>
        </InfiniteScroll>
    </div>;
}

InfinitePaginationListComponent.propTypes = {
    mediaList: PropTypes.array,
    mediaType: PropTypes.string,
    onClickItem: PropTypes.any,
    maxVisibleItem: PropTypes.number,
    deleteMedia: PropTypes.any,
};

export default InfinitePaginationListComponent;