import { Container } from 'react-bootstrap';

import ActivitiesPanel from '../../components/activities/ActivitiesPanel';
import VideoPlayGraph from '../../components/activities/VideoPlayGraph';
import CatalogHeadsetViewComponent from '../../components/catalog/CatalogHeadsetViewComponent';
import AuthorizationChecker from '../../services/AuthorizationChecker';


import "./WelcomePage.scss";

const WelcomePage = () => {
    const displayContent = AuthorizationChecker.isAdmin() ?
        <>
            <ActivitiesPanel />
            <VideoPlayGraph />
        </>
        : <CatalogHeadsetViewComponent />;

    return (
        <Container>
            <div className='d-flex flex-column align-items-center justify-content-center mt-4 welcome'>
                <h1> Bienvenue dans le Backoffice de Métiers360</h1>
            </div>
            {displayContent}
        </Container>
    )
}

export default WelcomePage;