import React from "react";
import { Row, Col, Form } from 'react-bootstrap';
import { useStoreState } from "easy-peasy";
import AuthorizationChecker from "../../services/AuthorizationChecker";
import PropTypes from 'prop-types';
import { Editor } from "@tinymce/tinymce-react";

const VideoCommentM360Component = (props) => {
    const {video, editorContentRef} = props;
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);

    const token = ACTIONS['videos']['editCommentM360'].csrfToken;

    if(!AuthorizationChecker.hasUpdateRights('videos')) return null;

    return <Form id="video_comment_m360"> 
    <Form.Control name="csrf_token" defaultValue={token} type="hidden" />
    <Row>
        <Col>
                <Form.Label>Commentaire sur l'expérience immersive</Form.Label>
            <Editor
                name="description"
                initialValue={video?.commentM360 ?? ''}
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                    'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                ],
                toolbar:
                    'undo redo | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | charmap emoticons | link | removeformat | help'
                }}
                onEditorChange = {(content, editor) => editorContentRef.current = content}
            />
        </Col>
    </Row>
    </Form>
    ;
}

export const submitCommentM360VideoForm = (props) => {
    const {uniqueId, action, editorContentRef} = props;
    const formData = new FormData(document.getElementById('video_comment_m360'));
    formData.append("video_comment_m360", editorContentRef.current ?? '');
    const payload = { uniqueId: uniqueId, formData:formData };
    return action(payload);
}
VideoCommentM360Component.propTypes = {
    video: PropTypes.object,
    editorContentRef: PropTypes.any
}
export default VideoCommentM360Component