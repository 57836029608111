import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, usePDF } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ButtonLoaderComponent from '../ButtonLoaderComponent';

import logo from '../../../images/logo140.png';


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontWeight: 'light',
        fontSize: 11,
        padding: 20,
    },
    sectionlogo: {
        width: '100%',
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: '20%',
        borderRadius: '50%',
    },
    table: {
        width: '100%',
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        borderBottom: '1px solid black',
    },
    header: {
        backgroundColor: '#45ABFF',
        color: 'white',
        borderTop: '1px solid black',
    },
    headerCol: {
        fontFamily: 'Helvetica-Bold',
    },
    headerCol0: {
        backgroundColor: '#5b5b5b'
    },
    col: {
        borderRight: '1px solid black',
        margin: 0,
        padding: 5,
        textAlign: 'center',
    },
    col0: {
        width: '10%',
        borderLeft: '1px solid black',
    },
    col1: {
        width: '35%',
    },
    col2: {
        width: '40%',
    },
    col3: {
        width: '15%',
    },
    contentCol: {
        color: '#021f3f',
    },
    contentColVideo: {
        color: '#2784D1',
    },
    videoNames: {
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        width: '100%'
    },
    videoSubHeading: {
        fontSize: 8.5,
        marginTop: 4,
    },
    videoCodeAccess: {
        fontSize: 9,
        color: '#5b5b5b',
    },
    centerVertical: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    pageCounter: {
        textAlign: 'right',
    }
})

const dateString = new Date().toLocaleDateString('fr-FR');

const JobsVideosDocument = ({ jobs }) => {
    return (<Document title='liste-experiences-par-metiers' author='Métiers360'>
        <Page size="A4" style={styles.page}>
            <View>
                <Text>A jour du {dateString}</Text>
            </View>
            <View style={styles.sectionlogo}>
                <Image source={logo} style={styles.logo} />
            </View>
            <View style={styles.table}>
                <View style={[styles.row, styles.header]}>
                    <Text style={[styles.col, styles.col0, styles.headerCol, styles.headerCol0]}>N°</Text>
                    <Text style={[styles.col, styles.col1, styles.headerCol]}>Métier</Text>
                    <Text style={[styles.col, styles.col3, styles.headerCol]}>Code Rome</Text>
                    <View style={[styles.col, styles.col2, styles.headerCol]}>
                        <Text>Expériences immersives</Text>
                        <Text style={styles.videoCodeAccess}>Code d'accès direct</Text>
                    </View>
                </View>
                {jobs?.map((j, i) => {
                    return (
                        <View key={j.code} style={styles.row} wrap={false}>
                            <View style={[styles.col, styles.col0, styles.contentCol, styles.centerVertical]}>
                                <Text>{i + 1}</Text>
                            </View>
                            <View style={[styles.col, styles.col1, styles.contentCol, styles.centerVertical]}>
                                <Text>{j.label}</Text>
                            </View>
                            <View style={[styles.col, styles.col3, styles.contentCol, styles.centerVertical]}>
                                <Text>{j.rome}</Text>
                            </View>
                            <View style={[styles.col, styles.col2, styles.contentColVideo, styles.centerVertical]}>
                                {j.videos?.map(video => <View key={video.videoName} style={styles.videoNames}>
                                    <Text>{video.videoName}</Text>
                                    <Text style={styles.videoSubHeading}>{video.subHeading}</Text>
                                    {video.isDirectlyAccessible && <Text style={styles.videoCodeAccess}>{video.directAccessCode}</Text>}
                                </View>)}
                            </View>
                        </View>)
                })}

            </View>

            <Text style={styles.pageCounter} fixed
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`} />
        </Page>
    </Document>);

};

const DownloadJobsVideosPDF = (props) => {

    const { jobs, title, buttonText, fileName } = props;

    const [downloadDone, setDownloadDone] = useState(false);

    const [instance] = usePDF({ document: <JobsVideosDocument title={title} jobs={jobs} /> });

    useEffect(() => {
        if (!instance.loading && jobs?.length && !downloadDone) {
            setDownloadDone(true);
            const link = document.createElement('a');
            link.href = instance.url;
            link.download = `${fileName}-${dateString}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [instance.loading, jobs, downloadDone]);

    const isLoading = (instance.loading && !instance.url) || !jobs?.length;

    return instance.error
        ? <Button variant="danger" ><i className="fas fa-exclamation-triangle mr-1" />{buttonText}</Button>
        : <ButtonLoaderComponent variant="primary" isSending={isLoading} disabled={isLoading} id="btn-pdf-videos-by-jobs"
            href={instance.url} download={`${fileName}-${dateString}.pdf`}
            iconClassName="fas fa-download" msg={buttonText} />;
};

JobsVideosDocument.propTypes = {
    jobs: PropTypes.array.isRequired
};
DownloadJobsVideosPDF.propTypes = {
    jobs: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired
};

export default DownloadJobsVideosPDF;
