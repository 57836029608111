import React, {useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Card, ListGroup, Container, Button, Image} from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';

import QuizPresentationComponent from './QuizPresentationComponent.jsx';
import QuizQuestionsTableComponent from './QuizQuestionsTableComponent.jsx';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';
import { useModal } from '../../../hooks/useModal.jsx';

import AuthorizationChecker from '../../../services/AuthorizationChecker.js';
import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';
import Loader from '../../widgets/Loader.jsx';
import QuizPlayComponent from './quizPlay/QuizPlayComponent.jsx';

import './QuizDetailsComponent.scss';

const QuizDetailsComponent = (props) => {
    const { quiz, quizPedagogicModules, isFetchingAllPedagogicModules } = props;
    
    const { readOne: readOneGroup } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('groups'));
    const { readOne: readOnePedagogicModule } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    
    const quizzesSlug = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('quizzes'));
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));

    const {modalComponent, modalData, setModalData, setModalShow} = useModal();
    const privateStatus = quiz.private !== undefined &&
        quiz.private ?
        <><i className="fas fa-lock" />&nbsp; Quiz privé</>
		: <><i className="fas fa-lock-open" />&nbsp; Quiz public</>;
    const producersList = quiz.producers?.length > 0 ?
        quiz.producers.map((producer) => (
            <ListGroup.Item as={Link} key={producer.id} action to={clientsSlugs.readOne.replace(':uniqueId', producer.id)}><i className="mr-2"/> {producer.clientName}</ListGroup.Item>
        ))
        :null;
    const isUsedInGroup = quiz.groups !== null; 
    const isUsedInPedagogicModule = !isFetchingAllPedagogicModules && quizPedagogicModules?.length > 0 ;

    const listGroup = useRef(null);

    useEffect(() =>{
        if(listGroup.current.children.length == 0) {
            listGroup.current.remove();
        }
    },[quiz])

    const showQuiz = () => {
        setModalData({
            ...modalData,
            header: <>Quiz : {quiz.name}</>,
            content: <QuizPlayComponent quiz = {quiz}/>,
            size: 'xl',
        })
        setModalShow(true);
    }
    
    return (
        <>
        <Container className="item_details">
                <Row>
                    <Col>
                        <Card className="item_details-card">
                            <Card.Body className="item_details-body">
                                    {quiz?.links?.thumbnail && <Image className="item_details-thumbnail" src={quiz.links.thumbnail}/>}

                                    <Card.Title className="item_details_card_title">{quiz && quiz.name ? quiz.name : "Détails du quiz" }</Card.Title>
                                        
                                        
                                        {AuthorizationChecker.hasUpdateRights('quizzes') ?
                                            <div className="item_details_btn_actions">
                                                <Button variant="secondary" as={Link} to={quizzesSlug.update.replace(':uniqueId', quiz && quiz.uniqueId)}>
                                                    <i className="fas fa-edit"></i> &nbsp;Éditer
                                                </Button>
                                                
                                            </div>
                                                :""
                                        }
                                        
                                    <ListGroup ref={listGroup}>
                                        {quiz.releaseDate != null && <ListGroup.Item>
                                            <strong>Publication :</strong> {HelperMetiers360.getdisplayDateType(quiz.releaseDate, 'day')}
                                        </ListGroup.Item>}
                                        {quiz.lastUpdated != null && <ListGroup.Item>
                                            <strong>Mise à jour :</strong> {HelperMetiers360.getdisplayDateType(quiz.lastUpdated, 'day')}
                                        </ListGroup.Item>}

                                        {quiz.private != null && <ListGroup.Item>
                                            <strong>Statut : </strong> {privateStatus}
                                        </ListGroup.Item>}
                                       
                                        {producersList  != null && <ListGroup.Item>
                                            <strong>Producteurs : </strong>
                                            {producersList}
                                        </ListGroup.Item>}
                                    
                                        {isUsedInPedagogicModule}
                                        {isUsedInGroup}
                                        {quiz.directAccessCode != null && (quiz.isDirectlyAccessible) && <ListGroup.Item>
                                            <strong>Code d'accès :</strong> {quiz.directAccessCode}
                                        </ListGroup.Item>}
                                    </ListGroup>
                                    <div className='containerButton'>
                                        {!!quiz.questions?.length &&
                                        <Button className='Commencer-btn' onClick={() => showQuiz()}><i className="far fa-eye"></i> &nbsp;Voir le quiz</Button>
                                        }
                                    </div>
                                    <div className="quiz_presentation">
                                        <QuizPresentationComponent quiz={quiz} />
                                    </div>
                                    { quiz.questions?.length > 0 &&
                                        <div className="quiz_questions">
                                            <Card.Title className="quiz_questions_title">
                                                Liste des questions
                                            </Card.Title>
                                            <QuizQuestionsTableComponent questions={quiz.questions} />
                                        </div>
                                    }
                                    <div className="associated_card">
                                        {isUsedInPedagogicModule && !isFetchingAllPedagogicModules &&
                                        <div className="associated_card_item">
                                            <Card.Title className="associated_card_title">
                                                Modules pédagogiques
                                            </Card.Title>
                                            <CardGroupMediaComponent mediaType='pedagogicModules' mediaList={quizPedagogicModules} readOne={readOnePedagogicModule} />
                                        </div>
                                        }
                                        {isUsedInGroup &&
                                        <div className="associated_card_item">
                                            <Card.Title className="associated_card_title">
                                                Groupes
                                            </Card.Title>
                                            <CardGroupMediaComponent mediaType='groups' mediaList={quiz.groups} readOne={readOneGroup} />
                                        </div>
                                        }
                                        {isFetchingAllPedagogicModules && <Loader />}
                                    </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
        </Container>
        {modalComponent}
        </>
    );
}

export default QuizDetailsComponent;