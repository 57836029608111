import React from 'react';
import {ListGroup} from 'react-bootstrap';
import HelperMetiers360 from '../../../../services/HelpersMetiers360.js';

const RomeItemsTabComponent = (props) => {
    const { rome, tabName } = props;

    const getRomeTabContent = (tabName) => {
        switch (tabName) {
            case 'definition':
                return rome.definition ? rome.definition:'Pas de définition du métier';
            case 'condition':
                return rome.condition ? rome.condition:"Conditions d'exercice non définies";
            case 'access':
                return rome.access ? rome.access:"Conditions d'accès à l'emploi métier non définies";
            default:
                return '';
        }
    }

    const tabContent = getRomeTabContent(tabName);
    const tabContentList = tabContent.split(HelperMetiers360.newLineRegExp);
    const tabContentListItems = tabContentList.map((item, index) => {
        return <ListGroup.Item key={index} className="content-text">{item}</ListGroup.Item>
    });

    return (
        <>
            <ListGroup>
                {tabContentListItems}
            </ListGroup>
        </>
    );
}

export default RomeItemsTabComponent