import React from 'react';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';


const SelectTagOperationsComponent = (props) => {
    const {tagOperationsSelectorRef, onChange} = props;

    const { allTagOperations } = useCustomGetStoreState("tagOperations");
    const tagOperationsOptions=allTagOperations ?
    allTagOperations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(tag => {
                return <option 
                    key={`option${tag.uniqueId}`}
                    value={tag.name}
                    >
                        {tag.name}
                </option>
            })
        :null;

    const ClientsSelector = tagOperationsOptions ? 
            <select 
                className="select-input"
                ref={tagOperationsSelectorRef}
                multiple={false}
                onChange = {onChange}
                >
                    <option value=''>--</option>
                    <option value='aucun'>-- Sans opération --</option>
                    {tagOperationsOptions}
               
            </select>
        :null;

    return ClientsSelector;
}



export default SelectTagOperationsComponent;