import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Container, Row, Alert, Button } from "react-bootstrap";

import Loader from "../../components/widgets/Loader";

import ClientDetailViewComponent from "../../components/clients/ClientsDetail/clientDetailView/ClientDetailViewComponent.jsx";

import useFreshworkWidget from '../../hooks/useFreshworksWidget.jsx';
import TooltipInfo from "../../components/widgets/tooltipInfo/TooltipInfo.jsx";

const MyAccountDetailPage = (props) => {

    const { clientById, allClients } = useStoreState(state => state.clients);
    const { fetchClientById } = useStoreActions(actions => actions.clients);
    const { client: clientUniqueId  } = useStoreState(state => state.auth);
    const { fetchSubscriptionsByClientId } = useStoreActions(actions => actions.clients);

    const {openWidgetContactForm} = useFreshworkWidget();

    const [isLoading, setIsLoading] = useState(!clientById);
    const [clientSubscriptions, setClientSubscriptions] = useState();

    useEffect(() => {
        setIsLoading(true);
        if (!clientById || clientById.uniqueId !== clientUniqueId) {
            fetchClientById(clientUniqueId).finally(() => {setIsLoading(false)});
        } else {
            setIsLoading(false);
        }
        fetchSubscriptionsByClientId(clientUniqueId)
            .then(response => setClientSubscriptions(response))
    }, [clientUniqueId]);

    const dateNow = new Date();
    const allSubscriptionsExpired = clientSubscriptions?.length > 0 ? clientSubscriptions.every((subscription)=>new Date(subscription.subscriptionEndDate) < dateNow) : null;

    const alertEndDateSubscription = 
    (allSubscriptionsExpired) &&
        <Alert className="mt-3" variant="warning">
            {clientSubscriptions?.length === 1 ? "Votre abonnement est arrivé" : "Tous vos abonnements sont arrivés"} à expiration. <br />
            Pour renouveler, veuillez contacter notre équipe commerciale à <Button as="a" bsPrefix={'no-btn'} onClick={() => {openWidgetContactForm()}}>
            l'aide du formulaire de contact</Button>.
        </Alert>;

    return !clientById || isLoading 
        ? <Loader /> 
        : <Container fluid>
            <Row>
                <h2 className="d-flex">
                    Vos données en un coup d'oeil
                    <TooltipInfo description="Les informations essentielles sur votre compte se trouvent ici." />
                </h2>
                {alertEndDateSubscription}
            </Row>
            <ClientDetailViewComponent clientById={clientById} allClients={allClients} />
        </Container>;
}

export default MyAccountDetailPage;