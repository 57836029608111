import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Button, Col, Row } from "react-bootstrap";

import Loader from "../../components/widgets/Loader.jsx";
import UploadQuizComponent from "../../components/quizzes/upload/UploadQuizComponent.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";



const UpdateQuizPage = () => {
    const quizzesSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("quizzes"));
	const {quizById, isQuizByIdUpdateNecessary} = useStoreState(state => state.quizzes);
    const { fetchQuizById, isFetchingOneQuiz } = useStoreActions(actions => actions.quizzes);

	const {uniqueId} = useParams();


	useEffect(() => {
		if (isQuizByIdUpdateNecessary || !quizById) {
			fetchQuizById(uniqueId);
		}
	}, [uniqueId, isQuizByIdUpdateNecessary])

    const { backButtonComponent } = useBackButton()

    return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Edition d'un quiz</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
				(!quizById || isFetchingOneQuiz)
				? <Loader/>
                : <UploadQuizComponent 
					quizToUpdate={quizById}
					action="update"
					{...quizzesSlugs}
				/>
            }
		</div>
	);
}

export default UpdateQuizPage;