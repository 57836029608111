import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import HelperMetiers360 from "../../services/HelpersMetiers360";
import WorkingConditionsTableComponent from "../../components/romes/WorkingConditionsTableComponent";
import Loader from "../../components/widgets/Loader";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import useSearchWithParams from "../../hooks/useSearchWithParams";
import { Col, Container, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import useBackButton from "../../hooks/useBackButton";

const WorkingConditionsPage = () => {

    const { allWorkingConditions, isFetchingAllWorkingConditions } = useCustomGetStoreState("romes");
    const { fetchAllWorkingConditions } = useStoreActions(actions => actions.romes);
    const viewStylePreference = useStoreState(state => state.preferences.workingConditionsListContent);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setWorkingConditionsListContent);
    const { backButtonComponent } = useBackButton();

    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (contexte de travail, catégorie)', delay: 400 });
    const [filteredWorkingConditions, setFilteredWorkingConditions] = useState([]);
    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const viewStyleType = [
        {
            value: 'withContentM360', label: "Avec contenus M360", view: <WorkingConditionsTableComponent
                workingConditions={filteredWorkingConditions.filter(workingCondition =>
                    workingCondition?.usagePros !== 0 || workingCondition?.usageVideosByPro !== 0
                )} />
        },
        { value: 'all', label: "Tous", view: <WorkingConditionsTableComponent workingConditions={filteredWorkingConditions} /> },
    ];

    useEffect(() => {
        allWorkingConditions.length === 0 && !isFetchingAllWorkingConditions &&
            fetchAllWorkingConditions();
    }, [])

    useEffect(() => {
        setFilteredWorkingConditions(allWorkingConditions
            ?.filter(workingCondition => !search
                || HelperMetiers360.isSearchInText(search, workingCondition.label)
                || HelperMetiers360.isSearchInText(search, HelperMetiers360.categoryWorkingConditionsLabel(workingCondition.category))
            )
        );
    }, [search, allWorkingConditions]);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    return <Container fluid>
        <Row className="item-page">
            <h2 className="d-flex justify-content-between">
                Contextes de travail
                {backButtonComponent}
            </h2>
        </Row>
        <Row className="item-page">
            <Col>
                {searchInput}
            </Col>
            <Col className="col-auto">
                <ToggleButtonGroup type="radio" name="option-view-videos" value={viewStyle} onChange={handleChange}>
                    {checkViewStyle}
                </ToggleButtonGroup>
            </Col>
        </Row>
        <Row>
            {isFetchingAllWorkingConditions
                ? <Loader />
                : viewStyleType?.find(type => type.value === viewStyle)?.view
            }
        </Row>
    </Container>;
}

export default WorkingConditionsPage;