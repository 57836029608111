import React from "react";
import {useStoreActions, useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";
import UploadSequenceComponent from "../../components/sequences/UploadSequenceComponent";
import useBackButton from "../../hooks/useBackButton";

const UploadSequencePage = (props) => {
	const {postSequence, updateSequence} =  useStoreActions((actions) => actions.sequences);
    const sequenceSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("sequences"));

    const { backButtonComponent } = useBackButton()

	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'un parcours</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <UploadSequenceComponent 
					postSequence={postSequence} 
					updateSequence={updateSequence} 
					action="create"
					{...sequenceSlugs}
				/>
            }
		</div>
	);
};

export default UploadSequencePage;
