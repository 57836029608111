import { action, actionOn } from "easy-peasy";

const navigationInitialState = {
  shouldNavigate: false,
    path: '',
    history: []
}

const navigation = {
  ...navigationInitialState,

  onGlobalResetCalled: actionOn(
    (actions, storeActions) => [
      storeActions.resetStore.performReset,
    ],
    (state, target) => {
      const [performReset] = target.resolvedTargets;

      if (target.type === performReset) {
        ({
          shouldNavigate: state.shouldNavigate,
            path: state.path,
            history: state.history,
        } = navigationInitialState)
      }
    }
  ),

  setShouldNavigate: action((state, payload) => {
    state.shouldNavigate = payload;
  }),

  setPath: action((state, payload) => {
    state.path = payload;
    state.shouldNavigate = true;
  }),

    setHistory: action((state, payload) => {
        state.history = payload;
    }),
}

export default navigation;