import React, {useState} from 'react';
import { Accordion, Row, Col, Container, Card } from 'react-bootstrap';
import { useModal } from '../../hooks/useModal.jsx';
import '../../pages/faq/FaqPage.scss';

import HelperMetiers360 from '../../services/HelpersMetiers360.js';

const VideosComponent = ({videos, label}) => {
    const [clicked, setClicked] = useState(false);
    
        const {modalComponent, modalData, setModalData, setModalShow} = useModal();
    
      const getIcon =  () => {
            if(clicked){
                return(
                    <i className="fas fa-duotone fa-minus-circle"></i>
                )
            }else{
                return(
                    <i className="fas fa-duotone fa-plus-circle"></i>
                )
            }
        }
    
        const handleClick = () => {
            setClicked((prevClick) => {
                return !prevClick;
            });
        }
    
    
        const showVideo = (link, header) => {
            setModalData({
                ...modalData,
                header: <>{header}</>,
                content: <>
                            <div style={{overflow:"hidden", width: "100%", minHeight: "auto", textAlign:"center"}}>
                                 <iframe src={link} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen width='680px' height="480px"></iframe>
                            </div>
                        </>,
                validateButton: <><i className="fas fa-window-close"></i> &nbsp;Fermer</>,
                cancelButton: '',
                size: "xl",
            })

            setModalShow(true);
        }

    return (
        <div >
            <Accordion className='accordion'>
                <Accordion.Item eventKey = "0" className='accordionItem'>
                    <Accordion.Header className='accordionHeader' onClick={handleClick}>{
                    
                    getIcon()} {label}
                                        
                    </Accordion.Header>


                    <Accordion.Body className='accordionBody'>
                        <Container fluid="md" className='container'>
                            <Row className='row-faq'>
                                    {
                                    videos.map((video)=>(
                                    <Col key={'Videos_'.concat(HelperMetiers360.generateUniqueId())} className="col" >
                                        <Card className="align-self-stretch">
                                            <div className='content' onClick={() => showVideo(video.link, video.title)}>
                                                <div className='text'>
                                                    <i aria-hidden="true" className="far fa-play-circle fa-2x"></i>
                                                    {video.title}
                                                </div>
                                                    {video.image}
                                            </div>
                                            </Card>
                                    </Col> 
                                    ))
                                }
                            </Row>
                        </Container>
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {modalComponent}

        </div>
)};

export default VideosComponent;