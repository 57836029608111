import React, { useEffect, useState } from 'react';
import { Button, Form, Container, Col, Row } from 'react-bootstrap';
import QuizPlayAnswers from './QuizPlayAnswers';
import './QuestionPlayComponent.scss';
import './QuizPlayAnswers.scss';


const QuestionPlayComponent = ({question, prev, next, bgUrl} ) => {

    // Quiz logic 
    const [checkedAnswerId, setcheckedAnswerId] = useState([]);
    const [checkedAnswerBool, setcheckedAnswerBool] = useState([]);
        
    // state displayed validate button if at least one answer is selected
    const [showValidButton, setshowValidButton] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [feedback, setFeedback] = useState();
    const [isSondage, setIsSondage] = useState();
    const [showFeedback, setShowFeedback] = useState(false);
    
    //useEffect which verify if answers array's length is equal to 0 ==> validate button hide and for each question array is reset and validate button is hide again
    useEffect(()=>{
        if(checkedAnswerId.length == 0){
            setshowValidButton(false);
        }
        setcheckedAnswerId([]);
        setcheckedAnswerBool([]);
        setshowValidButton(false);
        setIsValid(false);
        setFeedback();
        setShowFeedback(false);
    }, [question])
    
    
    //useEffect which is listening and the answers array. When the array's length is greater than or equal to 1 ==> validate button displayed
    useEffect(()=>{
        if(checkedAnswerId.length >= 1){
            setshowValidButton(true);
        }
        if(checkedAnswerId.length >= 1 && showFeedback === true){
            setshowValidButton(false);
        }
    }, [checkedAnswerId]);

    const validAnswer = (id, bool) => {
        if(checkedAnswerId.find(curId => curId === id)){
            setcheckedAnswerId(checkedAnswerId.filter(e => e !== id));
            setcheckedAnswerBool(checkedAnswerBool.filter(e => e !== bool));
        }else{
            setcheckedAnswerId([...checkedAnswerId, id]);
            setcheckedAnswerBool([...checkedAnswerBool, bool]);
        }
    }
    
    
    const trueAnswerCount = question?.answers.filter(item => item.isTrue).length;
    
    const validQuiz = () => {
        setshowValidButton(false);
        if(trueAnswerCount === 0) {
            setFeedback(question.neutralExplaination);
            setIsSondage(true);
        }
        else {
            // condition feedback positif
            if(trueAnswerCount === checkedAnswerBool.filter(item => item === true).length && !checkedAnswerBool.includes(false)){
                setFeedback(question.rightExplaination);
            }
            else {
                setFeedback(question.wrongExplaination);
            }
        }
        setIsValid(true);
    }
    
    const displayFeedback = () => {
        setIsValid(false);
        setShowFeedback(true);
    }
    
    const answersDisplay = question?.answers
        .map((answer)=> <QuizPlayAnswers 
                answers={answer} 
                checkedAnswerId={checkedAnswerId}
                sondage={isSondage}
                clickHandler={() => validAnswer(answer.uniqueId, answer.isTrue)} 
                key={answer.caption}
                valid={isValid}
            />
        );

    return (
        <Container className='imgQuiz' style={{backgroundImage: `url(${bgUrl})`}}>
            <div className="question">
                <div className='textAnswer'>
                    <div className='textQuestion'>{question.caption}</div>
                    <Form>
                        <div className="mb-3 containerAnswer display">
                            {answersDisplay} 
                            {showValidButton && 
                                <div className="containerValiderButton">
                                    <Button className={''} onClick={validQuiz}>Valider</Button>
                                </div>
                            }
                            {isValid && feedback &&
                            <div className="containerPlusButton"> 
                                <Button onClick={displayFeedback} className='enSavoirPlus'>En Savoir plus</Button>
                            </div>
                            }
                            <div className='feedBack'>
                                {showFeedback && feedback}
                            </div>
                        </div>
                    </Form>
                </div>
                <Button className={'previous'} onClick={prev}>Retour</Button>
                <Button className={'next'} onClick={next}>Suivant</Button>
            </div>
        </Container>
    );
};

export default QuestionPlayComponent;