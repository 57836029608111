import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import Loader from "../../../widgets/Loader";
import SubscriptionTableComponent from '../../../subscription/SubscriptionTableComponent.jsx';
import HeadsetPanelComponent from '../../../headset/HeadsetPanelComponent.jsx';
import { useStoreState, useStoreActions } from "easy-peasy";
import { useModal } from "../../../../hooks/useModal.jsx";
import SubscriptionFormComponent, { submitSubscriptionForm } from '../../../subscription/uploadForm/SubscriptionFormComponent.jsx';
import HeadsetFormCreationComponent, { submitHeadsetForm } from '../../../headset/HeadsetFormCreation/HeadsetFormCreationComponent.jsx';
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import HelperMetiers360 from '../../../../services/HelpersMetiers360';
import { useToast } from '../../../../hooks/useToast.jsx';
import useCustomGetStoreState from '../../../../hooks/useCustomGetStoreState.jsx';
import withURLParamsTableComponent from '../../../../HOC/withURLParamsTableComponent.jsx';

const SubscriptionTableComponentWithParams = withURLParamsTableComponent(SubscriptionTableComponent);

/**
 * This function renders the subscription tab of the client page
 * @returns A component that renders the ClientSubscriptionTabComponent and HeadsetPanelComponent
 */
const ClientSubscriptionTabComponent = (props) => {
    const { client, fromAccountPage = false } = props;
    const {createSubscription} = useStoreActions(actions => actions.subscriptions);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const { subscriptionsByClientId, isFetchingSubscriptionsByClientId } = useStoreState(state => state.clients);
    const { fetchSubscriptionsByClientId } = useStoreActions(actions => actions.clients);
    const toast = useToast();

	const {allHeadsets} = useCustomGetStoreState("headsets");
	const {createHeadset} = useStoreActions(actions => actions.headsets);

    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const headsetRef = useRef(null);

    const subscriptionEndDate = selectedSubscription ? HelperMetiers360.getdisplayDateType(selectedSubscription.subscriptionEndDate, 'day'):'';

    const subscriptionHeadsetCount = selectedSubscription ? selectedSubscription.headsetCount : '';

    const subscriptionHeadsetTitle = selectedSubscription ? "Casque"+(subscriptionHeadsetCount > 1 ? "s":"")+" de l'abonnement valable jusqu'au "+subscriptionEndDate : AuthorizationChecker.isAdmin() ? "Tous les casques du client" : "Tous vos casques";
    let subscriptionHeadsetDescription = '';
    subscriptionHeadsetCount > 1 && (subscriptionHeadsetDescription ="Cliquez sur un casque pour voir le détail");
    subscriptionHeadsetCount === 1  && (subscriptionHeadsetDescription ="Cliquez sur le casque pour voir le détail");
    subscriptionHeadsetCount === 0  && (subscriptionHeadsetDescription ="Aucun casque n'est associé à cet abonnement");

    const CardClassName = fromAccountPage ? 
        "tab-info-card tab-info-card--noMinHeight tab-info-card--noBackground-color pt-0 pb-0" 
        : "tab-info-card tab-info-card--noMinHeight ";

    const HeaderClassName = fromAccountPage ? 
        "tab-info-card-header mt-0" 
        : "tab-info-card-header mb-0";        
        

    useEffect(() => {
        fetchSubscriptionsByClientId(client?.uniqueId);
    }, [])

    useEffect(() => {
        if (subscriptionsByClientId.length === 1) {
            setSelectedSubscription(subscriptionsByClientId[0]);
        }
    }, [subscriptionsByClientId])

    const clientHeadsetsCount = useMemo(() => {
        return subscriptionsByClientId
            ? subscriptionsByClientId.reduce((hcount, sub) => hcount + sub.headsetCount, 0)
            : null;
    }, [subscriptionsByClientId]);

    const openCreateSubscriptionModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            submitSubscriptionForm({action: 'create', client: client, createSubscription: createSubscription})
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Ajout d'un abonnement</>,
            content: <SubscriptionFormComponent client={client} action='create' />,
            cancelButton: "Annuler",
            onValidate: handleSubmit,
            size: 'xl',
        });
        setModalShow(true);
    };


    const openCreateHeadsetModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            submitHeadsetForm({allHeadsets, createHeadset})
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Ajouter un casque</>,
            content: <HeadsetFormCreationComponent
                subscription={selectedSubscription} />,
            cancelButton: "Annuler",
            onValidate: handleSubmit,
            size: 'xl',
        });
        setModalShow(true);
    };

   
    return (
        <>
            <Row>
                <Col className="tab-info-container ">
                    <Col className={CardClassName}>
                        <div className="tab-info-card-header" >
                            <div className="header-heading w-100">
                                <Row className='tab-info-card-header mb-3'>
                                    <Col>
                                        <p className="header-desc">Gérer vos abonnements</p>
                                    </Col>
                                    {AuthorizationChecker.hasCreateRights('subscriptions') &&  <Col className="header-action" >
                                        <Button variant="success" onClick={() => openCreateSubscriptionModal()} ><i className="fas fa-plus"></i>&nbsp;Ajouter abonnement</Button>
                                    </Col>}
                                </Row>
                                {subscriptionsByClientId?.length < 1 ? isFetchingSubscriptionsByClientId ? <Loader />
                                    : <Alert variant="danger" className="mt-2">Pas d'abonnements</Alert>
                                    : <>
                                        {subscriptionsByClientId?.length > 1 &&
                                        <Alert variant="info" className="mt-2">Cliquez sur un abonnement pour voir les casques associés</Alert>}
                                        <SubscriptionTableComponentWithParams onSelect={setSelectedSubscription} scrollTargetRef={headsetRef} subscriptions={subscriptionsByClientId} />
                                    </>}
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>
            <Row>
                { ((selectedSubscription || !!clientHeadsetsCount) && subscriptionsByClientId?.length > 0) &&
                    <Col className="tab-info-container" ref={headsetRef}>
                        <Col className={CardClassName}>
                            <div className={HeaderClassName}>
                                <div className="header-heading w-100">
                                    <Row className='tab-info-card-header mb-3'>
                                        <Col>
                                            <p className="header-desc">{subscriptionHeadsetTitle}</p>
                                            <p>{selectedSubscription?.subscriptionName}</p>
                                        </Col>
                                        {AuthorizationChecker.hasCreateRights('headsets') && selectedSubscription &&
                                        <Col className="header-action" >
                                            <Button variant="success" onClick={() => openCreateHeadsetModal()} ><i className="fas fa-plus"></i>&nbsp;Ajouter un casque</Button>
                                        </Col>}
                                    </Row>
                                    {selectedSubscription ?
                                        <Alert variant="info" className="mt-2">{subscriptionHeadsetDescription}</Alert>
                                        : <Alert variant="info" className="mt-2">Sélectionnez un abonnement pour voir les casques associés</Alert>}

                                </div>
                            </div>
                            <HeadsetPanelComponent client={client} subscription={selectedSubscription} />
                        </Col>
                    </Col>
                }
            </Row>
            {modalComponent}
        </>
    )
}

export default ClientSubscriptionTabComponent;