import React ,  {useEffect, useRef, useState} from 'react';
import { useStoreActions, useStoreState} from 'easy-peasy';
import { Row, Card, ListGroup, Alert, Container, Button, Image , Accordion } from 'react-bootstrap';
import HelperMetiers360 from '../../../services/HelpersMetiers360.js';
import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';
import DownloadFileComponent from '../../widgets/DownloadFile/DownloadFileComponent.jsx';
import FormationSessionDetailsComponent from '../upload/formationSession/FormationSessionDetailsComponent.jsx';
import FormationSessionFormComponent, { submitSessionForm } from '../upload/formationSession/FormationSessionFormComponent.jsx';
import { useModal } from "../../../hooks/useModal.jsx";
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";

import { Link , useNavigate, useParams } from "react-router-dom";

import './FormationDetailsComponent.scss';
import { useToast } from '../../../hooks/useToast.jsx';

const FormationDetailsComponent  = (props) => {
    const { formation, formationSessions, deleteFormation } = props;
    const {otherId} = useParams();
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const editorContentRefDescription = useRef(null);

    const formationSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('formations'));
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const [init, setInit] = useState(true);
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;
	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const toast = useToast();

    const {setFormationSessionById ,fetchFormationSessionById, updateAllSessionsByFormationId } = useStoreActions(actions => actions.formationSessions);
    const {formationSessionById} = useStoreState(actions => actions.formationSessions);
    const [activeKey, setActiveKey] = useState("");
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const {createFormationSession} = useStoreActions(actions => actions.formationSessions);

    const navigate = useNavigate();

    
    
    const { readOne: readOnePedagogicModule } = useStoreState(actions => { 
        if(actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'))
        return actions.actionSlugs.actionSlugsDispatcher('pedagogicModules') 
        else
        return {readOne: null} });



    const clientsList = formation?.clients?.length > 0 ?
        formation?.clients.map((client) => (
            <ListGroup.Item as={Link} key={client.uniqueId} action to={clientsSlugs?.readOne.replace(':uniqueId', client.uniqueId)}><i className="mr-2"/>{client.name}</ListGroup.Item>
        ))
        :null;


    const createFormationSessionButton = AuthorizationChecker.hasUpdateRights('formations') ?  <Button variant="success" onClick={() => {createFormationSessionPopup()}}><i className="fas fa-plus"></i> &nbsp;Ajouter</Button> : null;
    
   
    useEffect(() => {
        if (formationSessions?.length > 0 ){
            if(init){
               
                if (otherId) {
                    const formationSessionUniqueIdFromMail = otherId;
                    setAccordionInfos(formationSessionUniqueIdFromMail);
                }
            }
            else{
               if (formationSessionById) {
                    activeKey != formationSessionById?.uniqueId && setAccordionInfos(formationSessionById?.uniqueId);
               }
               else{
                   setActiveKey(null);  
                   setInit(false);
               }
            }
        }
    }, [formationSessions]);

   
    useEffect(() => {
        if(formationSessionById && formation?.hasOwnProperty('isOpenedToRegistration')){

           const interval = setInterval(() => {updateSessionsInfos(formationSessionById)},1000*60 );

           return () => {
              clearInterval(interval);
           };
           
        }
    }, [formationSessionById]);
  

    const setAccordionInfos = ((sessionUniqueId)=>{

        const isSessionOfFormations = formationSessions?.find((session) => {
            return session.uniqueId == sessionUniqueId;
        });

        if (isSessionOfFormations){
            handleSessionChange(isSessionOfFormations);
        }
        else{
            setFormationSessionById(null);
            setActiveKey(null);
        }

        setInit(false);
    });


    const handleSessionChange = ((session)=>{
        setFormationSessionById(session);
        setActiveKey(activeKey == session?.uniqueId ? "" : session?.uniqueId);
    });


    const updateSessionsInfos = ((session)=>{
        fetchFormationSessionById(session?.uniqueId);
    });


    


    const createFormationSessionPopup = () => {
        const handleSubmit = () => {
            setIsSending(true);
            submitSessionForm({formName: ACTIONS?.formations?.editProps?.formationSessions?.create.formName,
                formation, fileContraints, formationSessionPersistAction: createFormationSession,
                editorContentRefDescription})
                    .then(() => {
                        toast.open({ message: "Création de la session webinaire réalisée avec succès", variant: "success" });
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: "Ajout d'une session",
            content: <FormationSessionFormComponent fileContraints={fileContraints} 
                editorContentRefDescription={editorContentRefDescription}/>,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'xl',
        });
        setModalShow(true);
    };

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression d'un webinaire</>,
            content: <> 
                {formation?.sessions?.length > 0 &&
                    <Alert variant="danger"> 
                        <h5>Ce webinaire contient <b>{formation?.sessions?.length}&nbsp;
                        {formation?.sessions?.length > 1 ? 'sessions' : 'session'}</b></h5>
                    </Alert>}
                {formation?.sessions?.length > 0
                    ? <h5>Veuillez supprimer ces sessions pour pouvoir continuer.</h5>
                    : <h5>Êtes-vous sûr⋅e de vouloir supprimer le webinaire&nbsp;<b>{formation?.name}</b>&nbsp;?</h5>}
                </>,
            resetButton: 'Supprimer',
            cancelButton: formation?.sessions?.length == 0 && 'Annuler',
            onReset: formation?.sessions?.length === 0 ? () => {
                deleteFormation({ uniqueId: formation?.uniqueId })
                    .then(() => {
                        redirect(formationSlugs.readAll);
                    })
            } : null
        });
        setModalShow(true);
    }

    const redirect = (url) => {
        navigate(url);
    }

    const accordionSessions = ((session) =>
    <Accordion.Item key={session.uniqueId} eventKey={session.uniqueId}>
        <Accordion.Header onClick={() => handleSessionChange(session)}>
            <i className={session.uniqueId === activeKey ? "fa fa-caret-down arrow-accordion" : "fa fa-caret-right arrow-accordion"} aria-hidden="true" />
            Session du : {HelperMetiers360.getdisplayDateType(session?.startsAt)}&nbsp;
            {session.userIsRegisteredToSession && !session.isSessionFinished ? <span className='text-danger'>(vous êtes inscrit)</span> : null}
            {session.userHasParticipatedToSession && session.isSessionFinished ? <span className='text-muted'>(vous avez participé)</span> : null}
        </Accordion.Header>
        <Accordion.Body>
            <FormationSessionDetailsComponent formationSession={formationSessionById} formationToUpdate={formation} />
        </Accordion.Body>
    </Accordion.Item>
    );

    const hasUdateOrRemoveRights = AuthorizationChecker.hasUpdateRights('formations') 
        || AuthorizationChecker.hasDeleteRights('formations');

    const accordionUpcomingSessions = formationSessions
    .filter((session) => {
        return new Date(session?.endsAt) > new Date();
    })
    .sort((a,b) => {
           return new Date(a?.startsAt) - new Date(b?.startsAt);
    })
    .map((sessions) => accordionSessions(sessions));

    const accordionPastSessions = formationSessions
    .filter((session) => {
        return new Date(session?.endsAt) < new Date();
    })
    .sort((a,b) => {
           return new Date(b?.startsAt) - new Date(a?.startsAt);
    })
    .map((sessions) => accordionSessions(sessions));

    return (
        <>
           <Container className="item_details container-formation">
              <Card className="item_details-card ">
                   <Card.Body className="item_details-body">
                       {formation?.links?.image && 
                           <Image className="item_details-thumbnail" src={formation.links.image}/>
                       }
                           <Card.Title className="item_details_card_title">
                             {formation && formation.name}
                           </Card.Title >
                       {
                          hasUdateOrRemoveRights && 
                             <div className="item_details_btn_actions">
                                 {AuthorizationChecker.hasUpdateRights('formations') ?
                                    <Button variant="secondary" as={Link} to={formationSlugs.update.replace(':uniqueId', formation && formation?.uniqueId)}>
                                        <i className="fas fa-edit"></i> &nbsp;Éditer
                                    </Button>
                                   : null
                                  }
                                  {AuthorizationChecker.hasDeleteRights('formations') ?
                                     <Button variant="danger" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
                                   : null
                                  }
                             </div>
                       }
                       <Row className='w-100'>
                            <div className={hasUdateOrRemoveRights ? 'row w-100' : 'col-md-7 col-12'}>
                                <Card className="item_details-card main-infos">
                                    <Card.Body>
                                        <div className={hasUdateOrRemoveRights ? 'row w-100' : undefined}>
                                            <div className={hasUdateOrRemoveRights ? 'col' : undefined}>
                                                <ListGroup>
                                                    {formation?.releaseDate != null &&
                                                    <ListGroup.Item>
                                                        <strong>Publication :</strong> {HelperMetiers360.getdisplayDateType(formation?.releaseDate, 'day')}
                                                    </ListGroup.Item>
                                                    }
                                                    {formation?.clients && formation?.clients?.length != 0 &&
                                                        <ListGroup.Item><strong>{formation?.clients?.length>1 ? 'Etablissements destinataires':'Etablissement destinataire'} :&nbsp;</strong>
                                                        <ListGroup.Item>{clientsList} </ListGroup.Item>
                                                        </ListGroup.Item>
                                                    }    
                                                    {formation?.categories?.length != 0 &&
                                                        <ListGroup.Item><strong>Categorie{formation?.categories.length>1 ? 's':null} :&nbsp;</strong>
                                                        {allCategories
                                                        .filter(category => formation.categories.includes(category.value))
                                                        .map(category => category.label)
                                                        ?.join(', ')}
                                                    </ListGroup.Item>
                                                    }
                                                    {formation?.levels?.length != 0 &&
                                                        <ListGroup.Item><strong>Niveau :&nbsp;</strong>
                                                            {allLevels
                                                            .filter(level => formation.levels.includes(level.value))
                                                            .map(level => level.label)
                                                            ?.join(', ')}
                                                        </ListGroup.Item>
                                                    }

                                                    {formation?.tools?.length != 0 &&
                                                        <ListGroup.Item><strong>Outil{formation.tools.length>1 ? 's':null} :&nbsp;</strong>
                                                            {allTools
                                                            .filter(tool => formation.tools.includes(tool.value))
                                                            .map(tool => tool.label)
                                                            ?.join(', ')}
                                                        </ListGroup.Item>
                                                    }
                                                </ListGroup>

                                                <div className="item_details_description p-0">
                                                    {formation?.pdfFile !== null &&
                                                    <div className="mb-4 mt-2">
                                                        <DownloadFileComponent path={formation.pdfFile} message={"Télécharger le pdf"} icon={true}/> 
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={hasUdateOrRemoveRights ? 'col description' : undefined}>
                                                {formation?.description && 
                                                    <>
                                                    <Card.Title className="item_details_description_title">
                                                    Description
                                                    </Card.Title>
                                                    <span className="text-muted" dangerouslySetInnerHTML={{__html: formation.description}}></span>
                                                </>}
                                            </div>
                                        </div>
                                        {formation?.pedagogicModules?.length > 0 &&
                                            <div className="associated_card">
                                                <div className="associated_card_item">
                                                <>
                                                <Card.Title className="associated_card_title">
                                                Modules pédagogiques
                                                </Card.Title>
                                                <CardGroupMediaComponent mediaType='pedagogicModule'  mediaList={formation.pedagogicModules} readOne={readOnePedagogicModule} />
                                                </>
                                                </div>
                                            </div>
                                        }
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className={hasUdateOrRemoveRights ? 'row w-100' : 'col-md-5 col-12'}>
                                <Card  className="item_details-card list-sessions" >
                                    <div className="list-sessions-title-row">
                                        <h3>Session{formationSessions?.length>1 ? 's':null}</h3>
                                        {createFormationSessionButton}
                                    </div>
                                    <Accordion activeKey={activeKey} flush alwaysOpen>
                                        {formationSessions?.length != 0 
                                            ? <>
                                                <div className="list-sessions-title-row">
                                                    {accordionPastSessions.length > 0 && accordionUpcomingSessions.length > 0 && 
                                                        <h4>À venir</h4>
                                                    }
                                                    {accordionUpcomingSessions}
                                                </div>
                                                <div className="list-sessions-title-row">
                                                    {accordionPastSessions.length > 0 && accordionUpcomingSessions.length > 0 && 
                                                        <h4>Passées</h4>
                                                    }
                                                    {accordionPastSessions}
                                                    </div>
                                            </>
                                            : <div className="list-sessions-title-row">
                                                Aucune session prévue pour l'instant
                                            </div>
                                        }
                                    </Accordion>
                                </Card>                   
                            </div>
                        </Row>
                    </Card.Body>   
                </Card>   
            </Container>
            {modalComponent}
        </>
    );
}
export default FormationDetailsComponent;