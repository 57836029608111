import React, { useEffect } from 'react';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {Form, Row} from 'react-bootstrap';

const CreateUserFromContactComponent = (props) => {
    const {} = props;
    const { allSessionsByCategory } = useStoreState(state => state.formationSessions);
    const { fetchFormationSessionsByCategory } = useStoreActions(state => state.formationSessions);
    const { setCreateFormErrors } = useStoreActions(actions => actions.users);
    const { apiData: {ACTIONS} } = useStoreState(state => state.actionSlugs);

    const {formName, csrfToken} = ACTIONS['users']['createFromContact'];
    const formationSessionsURLExists = ACTIONS.formationSessions?.readByCategory?.url;

    useEffect(() => {
        formationSessionsURLExists && fetchFormationSessionsByCategory("1")
        setCreateFormErrors({});
      }, [])

    return (
        <div className='form-create-user'>
            <Form id={formName} onSubmit={(event) => event.preventDefault()}>
            <input type="hidden" name={`token`} value={csrfToken} /> 
                {formationSessionsURLExists && <Row className='mt-2'>
                    <Form.Group >
                        <Form.Check
                            disabled={allSessionsByCategory?.length == 0}
                            type="checkbox"
                            name={`firstFormationRequired`}
                            defaultChecked={false}
                            label = "Nécessite une formation initiale"
                            id="firstFormationRequired"
                        />
                    </Form.Group>
                </Row>}
            </Form>
        </div>
    )

}


export const submitContactToUserForm = (action, formName, uniqueId) => {
    const formData = new FormData(document.getElementById(formName));
    const required = formData.get('firstFormationRequired');
    if(required){
        formData.append('firstFormationRequired', "required");
    }
    else{
        formData.append('firstFormationRequired', "");
    }

    const payload = {
        formData,
        uniqueId: uniqueId,
    }

    return action(payload)
}

export default CreateUserFromContactComponent;