import React, {useState, useEffect} from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';

import { OverlayTrigger, Tooltip, ListGroup, Button } from "react-bootstrap";

import HelperMetiers360 from '../../services/HelpersMetiers360.js';
import AuthorizationChecker from '../../services/AuthorizationChecker';

import DynamicTable from '../widgets/dynamicTable/DynamicTable';
import { useModal } from '../../hooks/useModal';
import { useToast } from '../../hooks/useToast.jsx';

/**
 * 
 * @param {Array} Tags array of all tags to be rendered
 * @param {String} readAll  readAll slug to access the tag list page
 
 * @example
 *  <TagTableComponent tags={tags} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const TagTableComponent = (props) => {
    const { tags = [], readAll: tagsPageLink, withParams } = props;

    const tagsToDisplay = tags ? tags : [];

    const { setIsUpdateNecessary: tagUpdateNecessary, deleteTag } = useStoreActions(actions => actions.tags);

    const [isSuccess, setIsSuccess] = useState(false);

    const {modalComponent, setModalShow, setModalData, modalData} = useModal();
    const moduleSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    const toast = useToast();

    useEffect(() => {
        if (isSuccess) {
            setIsSuccess(false);
            tagUpdateNecessary(true);
        }
    }, [isSuccess]);

    if (tags.length === 0) { return <></>; }


    const sortType = [
        {
            value: 'name', label: 'Nom',
            test: tagsToDisplay[0].name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (tag) => tag.name
        },
        {
            value: 'pedagogicModules', label: 'Modules',
            test: tagsToDisplay[0].pedagogicModules !== undefined,
            method: (a, b) => a.pedagogicModules.length - b.pedagogicModules.length,
            display: (tag) => {
                if(tag.pedagogicModules.length === 0){
                    return 0;
                }
                const listOfModules = tag.pedagogicModules.map((module) =>
                    <ListGroup.Item as={Link} to={moduleSlugs.readOne.replace(':uniqueId', module.uniqueId)} key={'module-'+module.uniqueId}>{module.name}</ListGroup.Item>
                );
                return (
                    <OverlayTrigger
                        trigger={['click']}
                        placement='right'
                        overlay={<Tooltip>
                                    <ListGroup className='text-primary text-left'>{listOfModules}</ListGroup>
                                </Tooltip>}
                        rootClose
                    >
                        <Button>{tag.pedagogicModules.length}</Button>
                    </OverlayTrigger>
                )
            }
        },
        {
            value: 'createdAt', label: 'Publication',
            test: tagsToDisplay[0].createdAt !== undefined,
            method: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            display: (tag) => HelperMetiers360.getdisplayDateType(tag.createdAt, 'day')
        },
        {
            value: 'uniqueId', label: 'Supprimer',
            test: AuthorizationChecker.isAdmin() ? true:false,
            method: undefined,
            display: (tag) => {
                const createDeleteModal = () => {
                    setModalData({
                        ...modalData,
                        header: <>Suppresion du tag</>,
                        content: <h5>Etes vous sûr⋅e de vouloir supprimer le tag 
                             <strong> {tag.name}</strong> &nbsp;?</h5>,
                        resetButton: 'Supprimer',
                        cancelButton: 'Annuler',
                        onReset: () => {
                            deleteTag({uniqueId: tag.uniqueId})
                                .then(() => {
                                    toast.success()
                                    setModalShow(false);
                                })
                        },
                    });
                    setModalShow(true);
                }
                return (
                <Button variant="danger" size="sm" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i></Button>
                )
            }
        }
    ];

    return (
        <>
            <DynamicTable
                contentTable={tagsToDisplay}
                contentSort={sortType}
                valueInitSort="name"
                index='uniqueId'
                withParams={withParams}
            />
              {modalComponent}
        </>
    );
}
export default TagTableComponent;