import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';

const sequencesInitialState = {
    allSequences: [],
    isFetchingSequences: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    sequenceById: null,
    isFetchingOneSequence: false,
}

const sequences = {
    ...sequencesInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allSequences: state.allSequences,
                    isFetchingSequences: state.isFetchingSequences,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    sequenceById: state.sequenceById,
                    isFetchingOneSequence: state.isFetchingOneSequence
                } = sequencesInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsFetchingSequences: action((state, payload) => {
        state.isFetchingSequences = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllSequences: action((state, payload) => {
        state.allSequences = payload;
    }),

    fetchAllSequences: thunk((actions, payload, helpers) => {
        const { isFetchingSequences, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.sequences?.readAll?.url;

        if (!isFetchingSequences && isUpdateNecessary) {
            actions.setIsFetchingSequences(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllSequences(data);
                    actions.setIsUpdateNecessary(sequencesInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingSequences(false))
        } else {
            Promise.resolve();
        }
    }),

    // handlingOneSequence
    setSequenceById: action((state, payload) => {
        state.sequenceById = payload;
    }),
    setIsFetchingOneSequence: action((state, payload) => {
        state.isFetchingOneSequence = payload;
    }),

    fetchSequenceById: thunk((actions, payload, helpers) => {
        const { isFetchingOneSequence } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.sequences?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneSequence) {
            actions.setIsFetchingOneSequence(true);
            actions.setSequenceById(sequencesInitialState.sequenceById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneSequence(sequencesInitialState.isFetchingOneSequence);
                    actions.setSequenceById(data);
                })
                .catch(error => Promise.reject(error));
        } else {
            return Promise.resolve();
        }
    }),

    // UPDATING STORE AFTER POST/UPDATE
    addSequence: action((state, payload) => {
        state.allSequences.push(payload);
    }),

    updateAllSequences: action((state, payload) => {
        const index = state.allSequences.findIndex(sequence => sequence.uniqueId === payload.uniqueId);

        state.allSequences[index] = {
            ...state.allSequences[index],
            ...payload,
        }
    }),

    updateSequenceStoriesAffected: thunk((actions, payload, helpers) => {
        const { updateAllStories } = helpers.getStoreActions().stories;

        for (const story of payload) {
            updateAllStories(story);
        }

    }),

    // posting one sequence
    postSequence: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createSequenceUrl = ACTIONS?.sequences?.create?.url;
        const { formData } = payload;

        return axios.post(createSequenceUrl, formData)
            .then(({ storiesToUpdate, sequenceToUpdate }) => {
                actions.addSequence(sequenceToUpdate);
                actions.updateSequenceStoriesAffected(storiesToUpdate);
                return sequenceToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),

    // updating one sequence
    updateSequence: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const { formData, uniqueId } = payload;

        const updateSequenceUrl = ACTIONS?.sequences?.update?.url?.replace('uniqueId', uniqueId);

        return axios.post(updateSequenceUrl, formData)
            .then(({ storiesToUpdate, sequenceToUpdate }) => {
                actions.updateAllSequences(sequenceToUpdate);
                actions.updateSequenceStoriesAffected(storiesToUpdate);
                return sequenceToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),

    // deleting one sequence
    deleteSequence: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const formData = new FormData();
        formData.set(`${ACTIONS?.sequences?.delete?.formName}[_token]`, ACTIONS?.sequences?.delete?.csrftoken)
        const deleteSequenceUrl = ACTIONS?.sequences?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.post(deleteSequenceUrl, formData)
            .then((result) => {
                actions.setIsUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),
};

export default sequences;