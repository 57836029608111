import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const messagesInitialState = {
    allMessages: [],
    isFetchingAllMessages: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    messageById: null,
    isFetchingOneMessage: false,
}

const messages = {
    ...messagesInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allMessages: state.allMessages,
                    isFetchingAllMessages: state.isFetchingAllMessages,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    messageById: state.messageById,
                    isFetchingOneMessage: state.isFetchingOneMessage,
                } = messagesInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllMessages: action((state, payload) => {
        state.allMessages = payload;
    }),

    setIsFetchingAllMessages: action((state, payload) => {
        state.isFetchingAllMessages = payload;
    }),

    setMessageById: action((state, payload) => {
        state.messageById = payload;
    }),
    setIsFetchingOneMessage: action((state, payload) => {
        state.isFetchingOneMessage = payload;
    }),

    addMessage: action((state, payload) => {
        state.allMessages.push(payload);
    }),

    updateAllMessages: action((state, payload) => {
        const index = state.allMessages.findIndex((message) => message.uniqueId === payload.uniqueId);

        state.allMessages[index] = {
            ...state.allMessages[index],
            ...payload,
        };

    }),

    removeFromAllMessages: action((state, payload) => {
        const indexToRemove = state.allMessages.findIndex(message => message.uniqueId === payload);
        (indexToRemove !== -1) && state.allMessages.splice(indexToRemove, 1);
    }),

    fetchAllWelcomeMessages: thunk((actions, payload, helpers) => {
        const { isFetchingAllMessages, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.welcomeMessage?.readAll?.url;

        if (!isFetchingAllMessages && isUpdateNecessary) {
            actions.setIsFetchingAllMessages(true);

            return axios.get(getUrl)
                .then(data => {
                    actions.setAllMessages(data);
                    actions.setIsUpdateNecessary(messagesInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    actions.setIsFetchingAllMessages(messagesInitialState.isFetchingAllMessages);
                })
        } else {
            return Promise.resolve();
        }
    }),
   
    fetchUnavailableDates: thunk((actions, payload, helpers) => {

        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const getUrl = apiData.ACTIONS['welcomeMessage']['fetchUnavailableDates'].url?.replace('uniqueId', payload);

        return axios.get(getUrl)
            .then(data => {
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    editValidated: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { uniqueId } = payload;
        const postUrl = ACTIONS?.welcomeMessage?.editValidated?.url.replace('uniqueId', uniqueId);
        payload.token = ACTIONS?.welcomeMessage?.editValidated?.csrfToken;

        return axios.post(postUrl, payload)
            .then(data => {
                actions.updateAllMessages(data);
                actions.setMessageById(data);
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    deactivateMessage: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { uniqueId } = payload;
        const postUrl = ACTIONS?.welcomeMessage?.deactivate?.url.replace('uniqueId', uniqueId);
        payload.token = ACTIONS?.welcomeMessage?.deactivate?.csrfToken;

        return axios.post(postUrl, payload)
            .then(data => {
                actions.updateAllMessages(data);
                actions.setMessageById(data);
                return data;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    createMessage: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createMessageUrl = ACTIONS?.welcomeMessage?.create?.url;
        payload.token = ACTIONS?.welcomeMessage?.create?.csrfToken;

        return axios.post(createMessageUrl, payload)
            .then((result) => {
                actions.addMessage(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),

    deleteMessage: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const deleteMessageUrl = apiData.ACTIONS?.welcomeMessage?.delete?.url?.replace('uniqueId', uniqueId);
        payload.token = ACTIONS?.welcomeMessage?.delete?.csrfToken;

        return axios.post(deleteMessageUrl, payload)
            .then((deletedMessage) => {
                actions.removeFromAllMessages(deletedMessage.uniqueId);
                return Promise.resolve();
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),


    updateMessage: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const updateMessageUrl = ACTIONS?.welcomeMessage?.update?.url?.replace('uniqueId', uniqueId);
        payload.token = ACTIONS?.welcomeMessage?.update?.csrfToken;

        return axios.post(updateMessageUrl, payload)
            .then((result) => {
                actions.updateAllMessages(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),


    fetchMessageById: thunk((actions, payload, helpers) => {
        const { isFetchingOneMessage} = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const getOneUrl = apiData.ACTIONS['welcomeMessage']['readOne'].url.replace('uniqueId', payload);

        if (!isFetchingOneMessage) {
            actions.setIsFetchingOneMessage(true);
            actions.setMessageById(null);

            return axios.get(getOneUrl)
                .then((data) => {
                    actions.setIsFetchingOneMessage(messagesInitialState.isFetchingOneMessage);
                    actions.setMessageById(data);
                })
                .catch(error => Promise.reject(error));
        } else {
            return Promise.resolve();
        }
    }),


    fetchActiveMessage: thunk((actions, payload, helpers) => {
        const { isFetchingOneMessage} = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const getOneUrl = apiData?.ACTIONS['welcomeMessage']['getActiveWelcomeMessage'].url;

        if (!isFetchingOneMessage) {
            actions.setIsFetchingOneMessage(true);
            actions.setMessageById(null);

            return axios.get(getOneUrl)
                .then((data) => {
                    actions.setIsFetchingOneMessage(messagesInitialState.isFetchingOneMessage);
                    return data;
                })
                .catch(error => Promise.reject(error));
        } else {
            return Promise.resolve();
        }
    }),

};

export default messages;