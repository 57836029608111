import React, { useState, useEffect } from 'react';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';
import CustomSelectSingleComponent from '../CustomSelectComponent/CustomSelectSingleComponent.jsx';

/**
 * Select Single Client Code Component
 *
 * @version 2.0.0
 * @param {string} nameSelect  name of the form control
 * @param {array} selectedClients  array that will store selected client(s), with or without an initial selection
 * @param {function} setSelectedClients  callback function
 * @param {string} selectId - unique Id for the select in case we use this component more than once in the same component
 * @param {array} initialClients   the initial part of the allClients you want to display (ex: only suscribed clients), will be AllClients by default
 * @param {boolean} mustRedirect  true if the select is meant to directly redirect to another page (creating an order for instance) - false if it's supposed to store a selected value

 * @example
 *      <SelectSingleClientComponent 
 *          nameSelect={nameOfSelect} 
 *          selectedClients = {ParentSelectedClientsHook} 
 *          setSelectedclients = {setParentSelectedClients} 
 *          selectId={selectId} />
 */

const SelectSingleClientComponent = (props) => {
    const {selectId = 'clientSelection', nameSelect = "clientSelect", selectedClients, setSelectedClients,
        initialClients = [], mustRedirect = false, optionLabel="clientName" } = props;

    const { allClients } = useCustomGetStoreState("clients");

    const [clients, setClients] = useState([]);

    useEffect(() => {
        setClients(initialClients.length > 0 ? initialClients : allClients);
    }, [initialClients, allClients]);

    return (
        <CustomSelectSingleComponent 
            selectId={selectId}
            options={clients}
            nameSelect={nameSelect}
            selectedOptions={selectedClients}
            setSelectedOptions={setSelectedClients}
            optionLabel={optionLabel}
            mustRedirect={mustRedirect}/>
    );
}

export default SelectSingleClientComponent