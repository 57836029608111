import { action } from "easy-peasy";

const responsiveInitialState = {
	isMobile: false,
    isTablet: false,
    navIsOpen: false
}

const responsive = {
	...responsiveInitialState,

	setIsMobile: action((state, payload) => {
		state.isMobile = payload;
	}),

    setIsTablet: action((state, payload) => {
        state.isTablet = payload;
    }),

	setNavIsOpen: action((state, payload) => {
		state.navIsOpen = payload;
    }),
}

export default responsive;