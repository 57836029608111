import React, { useState } from "react";
import {Alert, Tabs, Tab} from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

import WebappMagicboxFormComponent, { submitMagicboxForm, deleteMagicboxLink } from './magicBoxForm/WebappMagicboxFormComponent';
import WebappSkinFormComponent, {submitWebappSkinForm} from './skinForm/WebappSkinFormComponent';
import WebappMenuFormComponent, { deleteMenuForm, submitMenuForm } from './menuForm/WebappMenuFormComponent';

const WebappCustomisationFormsComponent = (props) => {

    const { clientById: client } = useStoreState(state => state.clients);

    const {updateWebappSkin, updateWebappMagicboxLink, deleteWebappMagicboxLink, updateWebappMenu, createWebappMenu, deleteWebappMenu } = useStoreActions(actions => actions.clients);
    const { apiData: {ACTIONS} } = useStoreState(state => state.actionSlugs);

    const parenthoodMsgAlert = client.webappCustomisation && client.webappCustomisation.fromParent ? 
					<Alert variant='danger'>Customisation héritée du parent</Alert>:
					null;

    const [key, setKey] = useState('webAppRender');

    return (
        <>
            {parenthoodMsgAlert}
            <Tabs defaultActiveKey="webAppRender" id="uncontrolled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey="webAppRender" title="Rendu de la Webapp" unmountOnExit={true}>
                    <WebappSkinFormComponent 
                    client={client}
                    onValidate= {() => submitWebappSkinForm(updateWebappSkin, client.uniqueId)}
                    />
                </Tab>
                <Tab eventKey="magicBoxLink" title="Liens Magicbox" unmountOnExit={true}>
                    <WebappMagicboxFormComponent 
                        client={client}
                        onValidate= {() => submitMagicboxForm(updateWebappMagicboxLink, client.uniqueId, ACTIONS['clients']['editProps']['editMagicboxLink'].formName)}
                        onDelete={(linkUniqueId) => deleteMagicboxLink(deleteWebappMagicboxLink, client.uniqueId, linkUniqueId, ACTIONS['clients']['editProps']['deleteMagicboxLink'].csrfToken)}
                        />
                </Tab>
                <Tab eventKey="menu" title="Menu" unmountOnExit={true}>
                    <WebappMenuFormComponent
                        client={client}
                        onValidate={() => {
                            const actionFn = client.webappCustomisation.webappMenu ? updateWebappMenu : createWebappMenu;
                            const webappId = client.webappCustomisation.webappMenu ? client.webappCustomisation.webappMenu.uniqueId : null;

                            return submitMenuForm(actionFn, client.uniqueId, webappId);
                        }}
                        onDelete={() => {
                            const webappId = client.webappCustomisation.webappMenu ? client.webappCustomisation.webappMenu.uniqueId : null;
                            return deleteMenuForm(deleteWebappMenu, client.uniqueId, webappId, ACTIONS['clients']['editProps']['deleteWebappMenu'].csrfToken);
                        }

                        }
                    />
                </Tab>
            </Tabs>
        </>
    )
}

export default WebappCustomisationFormsComponent;