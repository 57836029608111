import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';

const webappAccessesInitialState = {
    allWebappAccesses: [],
    isFetchingWebappAccesses: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
}

const webappAccesses = {
    ...webappAccessesInitialState,
    
    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allWebappAccesses: state.allWebappAccesses,
                    isFetchingWebappAccesses: state.isFetchingWebappAccesses,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone
                } = webappAccessesInitialState)
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsFetchingWebappAccesses: action((state, payload) => {
        state.isFetchingWebappAccesses = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setAllWebappAccesses: action((state, payload) => {
        state.allWebappAccesses = payload;
    }),

    fetchAllWebappAccesses: thunk((actions, payload, helpers) => {
        const { isFetchingWebappAccesses, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.webappAccesses?.readAll?.url;

        if (!isFetchingWebappAccesses && isUpdateNecessary) {
            actions.setIsFetchingWebappAccesses(true);

            return axios.get(getUrl)
                .then(data => {
                    actions.setAllWebappAccesses(data);
                    actions.setIsUpdateNecessary(webappAccessesInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingWebappAccesses(webappAccessesInitialState.isFetchingWebappAccesses);
                })
        } else {
            Promise.resolve();
        }
    }),
};

export default webappAccesses;