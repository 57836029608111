import React, {useRef,useState, useEffect} from "react";
import SelectSubscriptionsComponent from "../preparatoryOrders/Update/SelectSubscriptionsComponent/SelectSubscriptionsComponent";
import ClientContactsComponent from "../../clients/ClientsDetail/clientCRMDetailView/ClientContactsComponent";
import HelperMetiers360 from "../../../services/HelpersMetiers360";
import { Alert, Col, Form, InputGroup, Row } from "react-bootstrap";
import SelectProviderComponent from "../preparatoryOrders/Update/SelectProviderComponent./SelectProviderComponent";
import SelectProductComponent from "../preparatoryOrders/Update/SelectProductsComponent/SelectProductComponent";
import { useStoreActions, useStoreState } from "easy-peasy";
import ButtonLoaderComponent from "../../widgets/ButtonLoaderComponent";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { useModal } from "../../../hooks/useModal";
import withURLParamsTableComponent from "../../../HOC/withURLParamsTableComponent";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);


const ClientContactsComponentWithParams = withURLParamsTableComponent(ClientContactsComponent);

const ReturnOrderFormComponent = ({ client, order:initDataOrder }) => {
    const { createReturnOrder, updateReturnOrder } = useStoreActions(actions => actions.orders);
    const { allReturnProducts } = useStoreState(state => state.orders);
    const { fetchReturnProducts } = useStoreActions(actions => actions.orders);
    const { allReturnOrderTypes } = useStoreState(state => state.orders);
    const { fetchReturnOrderTypes } = useStoreActions(actions => actions.orders);
    const { fetchHeadsetsByClientId } = useStoreActions(actions => actions.clients);
    const { headsetsByClientId } = useStoreState(state => state.clients);

    const [isSending, setIsSending] = useState(false);
    const [subscriptionUniqueIdsChecked, setSubscriptionUniqueIdsChecked] = useState(initDataOrder?.subscriptionsUniqueIds ?? []);

    const [returnDate, setReturnDate] = useState(initDataOrder?.returnDueDate ? new Date(initDataOrder?.returnDueDate) : null);

    const productsRef = useRef(initDataOrder?.productsToReturn
        ? initDataOrder.productsToReturn?.map(product => { return {
            uniqueId: product?.product?.uniqueId, 
            quantity: product.quantity
        }})
        : null);
    const providerRef = useRef(initDataOrder?.provider?.uniqueId);
	const returnOrdersSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('returnOrders'));
    const navigate = useNavigate();

    const [noSubscriptionSelected, setNoSubscriptionSelected] = useState(false);
    const [noContact, setNoContact] = useState(false);
    const [noDate, setNoDate] = useState(false);
    const [noReason, setNoReason] = useState(false);
    const [noProvider, setNoProvider] = useState(false);
    const [noProduct, setNoProduct] = useState(false);
    const [badQuantity, setBadQuantity] = useState(false);
    const [cannotSend, setCannotSend] = useState(false);

    const [selectedContact, setSelectedContact] = useState(initDataOrder?.contactUniqueId);
    const [reasonValue, setReasonValue] = useState(initDataOrder?.typeOfReturn?.value ?? "");
    const [comment, setComment] = useState(initDataOrder?.comment ?? '');
    const [refreshProductsRef, setRefreshProductsRef] = useState(false);
    const [isProductsListSetByUser, setIsProductsListSetByUser] = useState(false);
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending: setIsModalSending } = useModal();

    const redirect = (url) => {
        navigate(url, {replace:true, state: {fromEdit:true}});
    }

    useEffect(() => {
        allReturnProducts.length == 0 && fetchReturnProducts();
        allReturnOrderTypes.length == 0 && fetchReturnOrderTypes();
        initDataOrder?.contactUniqueId && setSelectedContact(initDataOrder?.contactUniqueId);
    },[]);

    useEffect(() => {
        client?.uniqueId && fetchHeadsetsByClientId(client?.uniqueId);
    }, [client]);

    useEffect(() => {
        if(!isProductsListSetByUser && !initDataOrder) {
            if(subscriptionUniqueIdsChecked.length > 0 && headsetsByClientId.length > 0) {
                const headsetSelectable = headsetsByClientId
                    ?.filter(headset => subscriptionUniqueIdsChecked?.includes(headset.subscriptionUniqueId));
                const headsetsSelectableRefQuantity = headsetSelectable
                    ?.reduce((accumulator, current) => {
                        const newRef = current.typeOfHeadset?.reference;
                        const headsetRefAlreadyFindIndex = accumulator.findIndex(acc => acc.reference == newRef);
                        if(headsetRefAlreadyFindIndex > -1) {
                            let oldRef = accumulator[headsetRefAlreadyFindIndex];
                            oldRef.quantity = oldRef.quantity + 1;
                            accumulator[headsetRefAlreadyFindIndex] = oldRef;
                        } else {
                            accumulator.push({reference: newRef, quantity: 1})
                        }
                        return accumulator;
                    }, [])
                const productsToSet = allReturnProducts
                    ?.filter(product => headsetsSelectableRefQuantity?.some(headset => headset.reference == product.reference))
                    ?.map(product => {
                        return {
                            uniqueId: product.uniqueId,
                            quantity: headsetsSelectableRefQuantity?.find(headset => headset.reference == product.reference)?.quantity
                        }});
                productsRef.current = productsToSet;
            } else {
                productsRef.current = [{ uniqueId: null, quantity: null }];
            }
            setRefreshProductsRef(true);
        }
    }, [subscriptionUniqueIdsChecked, headsetsByClientId])

    const sendForm = () => {
        const payload = {
            subscriptionsUniqueIds: subscriptionUniqueIdsChecked,
            clientUniqueId: client?.uniqueId,
            productsToReturn: productsRef.current,
            typeOfReturn: reasonValue,
            returnDueDate: HelperMetiers360.formatDateForFormControl(returnDate),
            providerUniqueId: providerRef.current,
            contactUniqueId: selectedContact,
            comment: comment
        }
        initDataOrder?.uniqueId && (payload.uniqueId = initDataOrder.uniqueId);

        setIsSending(true);

        const action = initDataOrder ? updateReturnOrder : createReturnOrder;

        return action(payload)
            .then(data => {
                setIsSending(false);
                redirect(returnOrdersSlugs.readOne.replace(':uniqueId', data.uniqueId));
            })
            .catch(error => {
                setIsSending(false);
                setCannotSend(true);
            })
    }

    const openConfirmModalBeforeValidate = () => {
        setModalData({
            ...modalData,
            header: <>Création d'un bon de retour</>,
            content: <h5>ATTENTION : La liste des casques a été remplie automatiquement après la sélection des abonnements.
                <br/><br/>
                Êtes-vous sûr⋅e de vouloir créer ce bon de retour sans avoir modifié ces informations ?</h5>,
            cancelButton: 'Annuler',
            onValidate: () => {
                setIsModalSending(true);
                sendForm().finally(() => setIsModalSending(false));
            },
        });
        setModalShow(true);
    }

    const cleanErrors = () => {
        setCannotSend(false);
        setNoSubscriptionSelected(false);
        setNoContact(false);
        setNoDate(false);
        setNoReason(false);
        setNoProduct(false);
        setBadQuantity(false);
        setNoProvider(false);
    }

    const onValidate = () => {
        const hasNoSubscription = subscriptionUniqueIdsChecked?.length === 0;
        const hasNoContact = !selectedContact;
        const hasNoDate = !returnDate;
        const hasNoReason = !reasonValue;
        const hasNoProducts = !productsRef.current 
            || productsRef.current.length === 0 
            || productsRef.current.every((prod) => !prod.uniqueId);
        const hasInvalidQuantity = !hasNoProducts 
            && !productsRef.current
                .filter((prod)=>prod.uniqueId)
                .every((prod)=> prod.quantity && parseInt(prod.quantity) > 0);
        const hasNoProvider = !providerRef?.current;

        if (hasNoSubscription || hasNoContact || hasNoDate || hasNoReason || hasNoProducts 
            || hasInvalidQuantity || hasNoProvider) {
            setCannotSend(true);
            setNoSubscriptionSelected(hasNoSubscription);
            setNoContact(hasNoContact);
            setNoDate(hasNoDate);
            setNoReason(hasNoReason);
            setNoProduct(hasNoProducts);
            setBadQuantity(hasInvalidQuantity);
            setNoProvider(hasNoProvider);
        } else {
            cleanErrors();

            // In creation mode, if products list has not been edited manually by user => Show confirm dialog
            if(!initDataOrder && !isProductsListSetByUser) {
                openConfirmModalBeforeValidate();
            } else {
                sendForm();
            }
        }
    }

    const reasonsChoice = allReturnOrderTypes?.map(reason => 
        <option key={'reason_'+reason.value} value={reason.value}>{reason.label}</option>
    );

    return <>
        {modalComponent}
        <SelectSubscriptionsComponent client={client} subscriptionUniqueIdsChecked={subscriptionUniqueIdsChecked} 
            setSubscriptionUniqueIdsChecked={setSubscriptionUniqueIdsChecked} multipleSelection={true}
            isNoSubscriptionSelected={noSubscriptionSelected} cannotSend={cannotSend} multiple={false} />

        <h3 className="mt-5">Contact</h3>
        <ClientContactsComponentWithParams client={client} editable={true} updatable={false} downloadable={false}
                selectedContact={selectedContact} onSelect={(contact) => setSelectedContact(contact.uniqueId)}
                isNoContactSelected={noContact} />
    
        <h3 className="mt-5">Autres informations</h3>
        <Row className="mt-4">
            <Col>
                <InputGroup>
                    <InputGroup.Text>Date de retour prévue</InputGroup.Text>
                    <DatePicker
                        showIcon
                        locale='fr'
                        dateFormat="dd/MM/yyyy"
                        selected={returnDate}
                        onChange={(date) => {
                                setReturnDate(date);
                            }
                        } 
                        placeholderText="jj/mm/aaaa"
                    />
                </InputGroup>
                {(cannotSend && noDate) && <Alert variant="danger" className="mt-2">Sélectionnez une date</Alert>}  
            </Col>
            <Col>
                <Form.Select size="sm" aria-label="Choisir une raison de retour" value={reasonValue ?? ""}
                    onChange={e => { setReasonValue(e.target.value)}} >
                    {<option value="" >Choisir une raison de retour</option>}
                    {reasonsChoice}
                </Form.Select>
                {(cannotSend && noReason) && <Alert variant="danger" className="mt-3">Sélectionnez une raison</Alert>}  
            </Col>
        </Row>

        <SelectProductComponent allProducts={allReturnProducts} productsRef={productsRef} setNoProduct={setNoProduct} 
            setBadQuantity={setBadQuantity} noProduct={noProduct} badQuantity={badQuantity} cannotSend={cannotSend} 
            clientUniqueId={client?.uniqueId} productName="casque" displayRenting={false} 
            refreshProductsRef={refreshProductsRef} setRefreshProductsRef={setRefreshProductsRef}
            isProductsListSetByUser={isProductsListSetByUser} setIsProductsListSetByUser={setIsProductsListSetByUser} />

        <SelectProviderComponent providerRef={providerRef} />
        {(cannotSend && noProvider) && <Alert variant="danger" className="mt-3">Sélectionnez un prestataire</Alert>}  

        <h3 className="mt-5">Commentaire</h3>
        <Row className="ms-1">
            <textarea style={{width:'100%'}} type="text" value={comment ?? ""} onChange={(e) => setComment(e.target.value)} />
        </Row>

        <Row className="mt-5">
            <Col>
                <ButtonLoaderComponent variant="success" onClick={onValidate} isSending={isSending}
                    disabled={isSending} msg="Valider" iconClassName="fas fa-check text-white" />
            </Col>
            <Col>
                {cannotSend 
                    && <Alert variant="danger" className="text-center"> 
                        {initDataOrder ? "Édition" : "Sauvegarde"} impossible
                    </Alert>
                }
            </Col>
        </Row>
    </>;
}

ReturnOrderFormComponent.propTypes = {
    client: PropTypes.object,
    order: PropTypes.object
};

export default ReturnOrderFormComponent;