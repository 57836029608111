import React, {useState, useEffect } from "react";
import { Row, Form, Container } from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';



const UploadFileFormComponent = (props) => {
    const { typeOfFile, fileFormName, fileLabel, token} = props;
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

    const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const [fileInputName, setfileInputName] = useState('Choisir le fichier');

    const [fileTypesNames, setFileTypesNames] = useState('');
    const [fileTypes, setFileTypes] = useState('');
    const [fileMaxSizeMo, setFileMaxSizeMo] = useState('');
    const [fileMaxSizeBytes, setFileMaxSizeBytes] = useState('');
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
       if(typeOfFile === "image"){
            setFileTypesNames(fileContraints.IMAGE_MIME_TYPES_NAMES?.join(','));
            setFileMaxSizeMo(fileContraints.MAX_SIZE_IMAGE_MO);
            setFileMaxSizeBytes(fileContraints.MAX_SIZE_IMAGE_BYTES);
            setFileTypes(fileContraints.IMAGE_MIME_TYPES?.join(','));
       }
    }, [typeOfFile]);



    const updateFile = (file) => {
        setFormErrors({});
        setfileInputName(file.name);

        let typeOfFileAuthorized = "";
        let errorsMessages = [];

        if(typeOfFile === "image"){
            typeOfFileAuthorized = fileContraints.IMAGE_MIME_TYPES;
        }
    
        if(!typeOfFileAuthorized.includes(file.type)){
            errorsMessages.push("File type not authorized");
        };
        
        if(file.size  > fileMaxSizeBytes){
            errorsMessages.push("The file is too big");
        };
        
        if(errorsMessages.length > 0){
            setFormErrors({errorsMessages:errorsMessages});
        }
    }
    
    return <Container className="mt-3 mb-3">
        
        <Form id={fileFormName + "_form"}    
               method="post" 
               encType="multipart/form-data" > 
            <Form.Control name="csrf_token" defaultValue={token} type="hidden" />
            <div>
                <Row>
                        <Form.Group className="formSection">
                            <Form.Label> {fileLabel} ({fileTypesNames})  ({fileMaxSizeMo} Mo max.)</Form.Label>
                            <div className="input-group mb-4">
                                <Form.Control
                                    type="file"
                                    className="custom-file-input"
                                    name="file"
                                    accept={fileTypes}
                                    onChange={(event) => { updateFile(event.target.files[0]) }}
                                    isInvalid={Object.keys(formErrors).length !== 0}
                                />
                                <label className="custom-file-label" htmlFor={fileFormName} data-browse="Parcourir">
                                    {fileInputName}
                                </label>
                                <Form.Control.Feedback type='invalid'>{formErrors.errorsMessages ? formErrors.errorsMessages.join(" ,") : ""}</Form.Control.Feedback>
                            </div>
                        </Form.Group>
                </Row>
            </div>
        </Form>
    </Container>;
}

export const submitFileForm = (uniqueId, action, fileFormName, fileContraints, fileType, entityName = null) => {
    
    const formData = new FormData(document.getElementById(fileFormName + "_form"));

    const file = formData.get("file");
    let typeOfFileAuthorized = "";
    let error = false;

    if(fileType === "image"){
        typeOfFileAuthorized = fileContraints.IMAGE_MIME_TYPES;
    }

    if(!typeOfFileAuthorized.includes(file.type)){
        error = true;
    };

    if(file.size  > fileContraints.MAX_SIZE_IMAGE_FILE_BYTES){
        error = true;
    };

    if(error){
        return Promise.reject(new Error());  
    };

    const payload = { uniqueId, formData, entityName };
    return action(payload);
}


export default UploadFileFormComponent;