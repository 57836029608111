import React from 'react';
import { Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import AuthorizationChecker from '../../../../services/AuthorizationChecker';

const QuestionCardComponent = ({question, selectEditForm, selectAnswersForm}) => {
    const listAnswers = (answers) => {
        return answers.map((answer) => (
            <ListGroup.Item key={'quiz-form-answer-'+answer.uniqueId}>{`.${answer.caption}`} {answer.isTrue ? <i style={{color: "green"}} className="fa fa-check-circle"/> : ""}</ListGroup.Item>
        ))
    }

    return ( 
        <div className="question-element" key={'form-question' + question.uniqueId} >
            <div className='question-caption' dangerouslySetInnerHTML={{ __html: question.caption }}/>
                <div className='question-actions'>
                    {AuthorizationChecker.hasUpdateRights('quizzes', 'questions') ? <i className="fas fa-edit text-primary" onClick={() => {selectEditForm(question)}}/> : null}
                    {AuthorizationChecker.hasDeleteRights('quizzes', 'questions') ? <i className="fas fa-trash-alt" /> : null}
                    { 
                        question.answers.length
                        ? <OverlayTrigger
                            placement='left'
                            overlay={
                            <Tooltip>
                                    <ListGroup className='text-primary text-left'>{listAnswers(question.answers)}</ListGroup>
                                </Tooltip>
                            }
                        >
                        <Button variant="secondary" onClick={()=> {selectAnswersForm(question)}}>Réponses : {question.answers.length}</Button>
                        </OverlayTrigger>
                        :    <Button variant="secondary" onClick={()=> {selectAnswersForm(question)}}>Réponses : {question.answers.length}</Button>
                    
                    }
            </div>
        </div>
    )
}

export default QuestionCardComponent;