import React, { useState} from 'react';
import { Container, Table, Button, Row, Form, Alert } from "react-bootstrap";
import { useStoreState } from 'easy-peasy';
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import HelperMetiers360 from "../../../services/HelpersMetiers360";


const CheckMultiUsersOrContactsComponent = (props) => {
    const { entityType, entities, dataStructure, formationsAuthorized, onValidate,
        onCancel, onChangeIsFormationRequired, onChangeFormationRequiredUniqueId,
        createUserAccessForContacts = null, setCreateUserAccessForContacts = null,
        setFormationForUserAccessForContacts = null } = props;
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;
    const { allClients } = useCustomGetStoreState('clients');

    const [selectedFormationType, setSelectedFormationType] = useState(null);
    const [selectedFormation, setSelectedFormation] = useState('');

    const checkEmail = (email) => {
        const validEmail = () => {
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return emailRegex.test(email);
        }
        if ((entityType !== "contact" && (!email || !validEmail(email))) || (entityType === "contact" && email && !validEmail(email))) {
            return false
        }
        return true;
    };

    const errors = entities
        .filter(entity => !checkEmail(entity.email) || !allClients.find(client => client.uniqueId == entity.clientUniqueId))
        .map(entity => {
            const client = allClients.find(client => client.uniqueId == entity.clientUniqueId);
            return {
                ...entity,
                errorEmail: !checkEmail(entity.email),
                clientDisplay: client ? client.clientName : 'Pas de client correspondant !',
                errorClient: client == undefined
            }
        });
        
    const formationTypesOptions = allCategories?.length > 0 &&
        allCategories
            // Display only formation type : "Lancement" and "Prise en main outil"
            ?.filter(cat => cat.value === "kick-off" || cat.value === "technique")
            ?.sort((a, b) =>  a?.label?.localeCompare(b?.label))
            ?.map(type => 
                <option key={'option-formation-type-'.concat(type.value)} 
                    value={type.value}>
                    {type.label}
                </option>
            );

    const formationsOfTypeSelectedOptions = formationsAuthorized
        .filter(formation => formation.categories.includes(selectedFormationType))
        .sort((a, b) =>  a?.name?.localeCompare(b?.name))
        .map(formation => 
            <option key={'option-formation-'.concat(formation.uniqueId)} 
                value={formation.uniqueId}>
                {formation.name}
            </option>
        );

    const handleSelectFormationType = (event) => {
        const formationTypeSelected = [...event.target.selectedOptions].map(opt => opt.value)[0]
        setSelectedFormationType([...event.target.selectedOptions].map(opt => opt.value)[0]);
        setSelectedFormation('');

        onChangeIsFormationRequired(formationTypeSelected !== '');
        onChangeFormationRequiredUniqueId('');
    }

    const handleSelectFormation = (event) => {
        const formationSelected = [...event.target.selectedOptions].map(opt => opt.value)[0]
        const formationRequired = formationSelected !== '';

        setSelectedFormation(formationSelected);
        onChangeIsFormationRequired(formationRequired);
        onChangeFormationRequiredUniqueId(formationRequired ? formationSelected : '');
    }

    const headerRows = dataStructure.map((data) => <th key={'head_'+data.field}>{data.label}</th>);

    const disabledNextBtn = ( selectedFormationType === "kick-off" && selectedFormation === '');

    const display = errors.length == 0 ?
        <Container>
            <h3>Tout semble prêt</h3>
            Voici ce que nous allons envoyer :
            <Table>
                <thead>
                    <tr>
                        {headerRows}
                    </tr>
                </thead>
                <tbody>
                    {entities.slice(0, 2).map(entity => <tr key={HelperMetiers360.generateUniqueId()}>
                        {dataStructure.map((data) => data.field != 'clientUniqueId'
                            ? <td key={HelperMetiers360.generateUniqueId()}>{entity[data.field]}</td>
                            : <td key={HelperMetiers360.generateUniqueId()}>
                                {allClients.find(client => client.uniqueId == entity.clientUniqueId).clientName}
                            </td>)}
                    </tr>)}
                    { entities.length > 2 && 
                        <tr><td colSpan={dataStructure.length} align="center"><i className="fas fa-ellipsis-h"></i></td></tr>
                    }
                </tbody>
            </Table>

            { entityType === "contact" && <>
                    <Form.Check type="checkbox" className="type-checkbox mt-5" label="Créer un compte utilisateur pour chaque contact"
                        id="create-user" onChange= {(e) => setCreateUserAccessForContacts(e.target.checked)} />
                    { createUserAccessForContacts && <Form.Check type="checkbox" className="type-checkbox" 
                        label="Chaque création de compte utilisateur nécessite une formation initiale"
                        id="formation-user" onChange= {(e) => setFormationForUserAccessForContacts(e.target.checked)} />}
                </>
            }
           
           { entityType === "user" &&
                <Row className="mb-2 mt-2 justify-content-center text-center">
                    <p>Choisissez le type de webinaire auquel doivent participer les utilisateur⋅rices :</p>
                    <Form.Select onChange={(e) => handleSelectFormationType(e)}>
                        <option value=''>-- Aucun --</option>
                        {formationTypesOptions}	
                    </Form.Select>
                </Row>
            }
            { entityType === "user" && selectedFormationType === "kick-off" &&
                <Row className="mb-2 mt-2 justify-content-center text-center">
                    <p>Choisissez le webinaire :</p>
                    { formationsOfTypeSelectedOptions.length > 0
                        ? <Form.Select onChange={(e) => handleSelectFormation(e)}>
                            <option value="">-- Aucun --</option>
                            {formationsOfTypeSelectedOptions}	
                        </Form.Select>
                        : <Alert>Aucun webinaire de type 
                            {allCategories.find(c => c.value === selectedFormationType)?.label ?? "??"}
                        </Alert>
                    }
                </Row>
            }

            <Button variant="primary" size="sm" disabled={disabledNextBtn} className="align-right" onClick={onValidate}>
                <span className="icon-checkmark"></span> C'est parti !
            </Button>
        </Container>
        : <Container>
            <h3>Certaines données ne sont pas corrects !</h3>
            <Table>
                <thead>
                    <tr>
                        <th>email</th>
                        <th>id client</th>
                        <th>Client correspondant</th>
                    </tr>
                </thead>
                <tbody>
                    {errors.map(entity => <tr key={HelperMetiers360.generateUniqueId()}>
                        <td className={entity.errorEmail ? 'bg-danger text-white' : ''}>{entity.email}</td>
                        <td>{entity.clientUniqueId}</td>
                        <td className={entity.errorClient ? 'bg-danger text-white' : ''}>{entity.clientDisplay}</td>
                    </tr>)}
                </tbody>
            </Table>
        </Container>



    return <>
        <Button variant="primary" size="sm" className="mb-3" onClick={onCancel}><span className="icon-arrow-left"></span>&nbsp;&nbsp;Revenir</Button>
        {display}
    </>;
}

export default CheckMultiUsersOrContactsComponent