import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import useDebounceSearch from "./useDebounchSearch";

const useSearchWithParams = (props) => {

    const [searchParam, setSearchParam] = useSearchParams();
    const {searchInput, search, resetSearchBar} = useDebounceSearch({...props,  defaultValue: searchParam.get('search') || ""})
    const location = useLocation();

    useEffect(() => {
        const params = {};
        
        for (const [key, value] of searchParam.entries()) {
            params[key] = value;
        }

        params.search = search;
        setSearchParam(params);

	}, [search])

    useEffect(() => {
        if (!searchParam.get("search") && search) {
            resetSearchBar();
        }

    }, [location.search])

    return {searchInput, search, resetSearchBar}
}


export default useSearchWithParams;