import React, { useEffect } from "react";
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Loader from "../../../widgets/Loader";

import "./ClientAddressesTabComponent.scss";

const ClientAddressesTabComponent = ({ clientById }) => {

    const { fetchAddressesByClientId } = useStoreActions(actions => actions.clients);
    const { addressesByClientId, isFetchingAddressesByClientId } = useStoreState(state => state.clients);


    useEffect(() => {
        fetchAddressesByClientId(clientById.uniqueId);
    }, []);

    return (
        <Container className="tab-info-card tab-info-card--noMinHeight tab-info-card--noBackground-color" style={{ width: "fit-content", minWidth: "90%" }}>
            <Row className='tab-info-card-header mb-3'>
                <Col className="header-heading">
                    <p className="header-desc">Adresse enregistrée</p>
                </Col>
            </Row>
            <Row>
                <div className="addresses-container">
                    {isFetchingAddressesByClientId ?
                        <Loader />
                        : addressesByClientId?.length ?
                            addressesByClientId.map(a => {
                                return (
                                    <div className="address-card" key={a.uniqueId}>
                                        <p><strong>{a.companyName}</strong></p>
                                        <p>
                                            {a.addressStreet} <br />
                                            {a.addressZipCode} <br />
                                            {a.addressCity} <br />
                                            {a.addressRegion} <br />
                                            {a.addressCountry}
                                        </p>
                                    </div>
                                )
                            })
                            : <Alert variant="warning">Cet établissement n'a pas d'adresse enregistrée.</Alert>
                    }
                </div>
            </Row>
        </Container>
    );
}

export default ClientAddressesTabComponent;