import React from "react";
import { Alert, Row, Col } from "react-bootstrap";
import ClientInvoicesComponent from "../../../../clients/ClientsDetail/clientCRMDetailView/ClientInvoicesComponent";

const SelectInvoiceComponent = ({
    client,
    selectedInvoiceRef
}) => {

    const onSelect = invoiceNumber => {selectedInvoiceRef.current = invoiceNumber};

    return <Row className="mt-3">
        <Col>
            <h3>Choix facture</h3>
            {client?.invoices?.length <1 && <Alert variant="info">Ce client n'est pas facturé</Alert>}
            {client?.invoices?.length >0 && <ClientInvoicesComponent client={client} initSelected = {selectedInvoiceRef.current} onSelect={onSelect}/>}
        </Col>
    </Row>;
}

export default SelectInvoiceComponent;
