import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Container, Row, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import Loader from '../../widgets/Loader.jsx';

import './RomeDetailsContentsComponent.scss';
import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

const RomeDetailsContentsComponent = (props) => {
    const { romePros, romeStories, isFetchingStories, romeVideos, isFetchingAllVideos, isFetchingAllProfessionals, isExpand, setIsExpand } = props;
    const { readOne: readOneStory } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));
    const { readOne: readOneVideo } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));

    const [isVideosOpen, setIsVideosOpen] = useState(true);
    const [isStoriesOpen, setIsStoriesOpen] = useState(true);
    const [isProsOpen, setIsProsOpen] = useState(true);

    const isUsedForPro = !!romePros?.length;
    const isUsedInStory = !!romeStories?.length;
    const isUsedInVideo = !!romeVideos?.length;

    const prosList = romePros?.sort((a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name));
    const videosList = romeVideos?.sort((a, b) => HelperMetiers360.localeCompareWithNullable(a.videoName, b.videoName));
    const storiesList = romeStories?.sort((a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name));

    return (<Card className="contents_details_card">
        <Card.Title className="contents_details_title fw-bold d-flex justify-content-between mx-4">
            Ressources associées
            <div onClick={() => setIsExpand(!isExpand)} className='expand-icon cursor-pointer'>
                {isExpand ? <i className="fa fa-compress" /> : <i className="fa fa-expand" />}
            </div>
        </Card.Title>
        <Card.Body className="contents_details_body">
            {(isUsedInStory || isUsedInVideo || isUsedForPro)
                ? <Container>
                    <Row>
                        {isUsedForPro && !isFetchingAllProfessionals &&
                            <div className="related_content_details_item mb-4">
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between"
                                    onClick={() => setIsProsOpen(!isProsOpen)}>
                                    {romePros.length} professionnel⋅le{romePros.length > 1 ? 's' : ''}
                                    {isProsOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isProsOpen
                                    && <CardGroupMediaComponent mediaType="professional" mediaList={prosList} readOne={readOneVideo} onlySummaryInfos={!isExpand} nbCol={isExpand ? 3 : 6} />
                                }
                            </div>
                        }
                        {isUsedInVideo && !isFetchingAllVideos &&
                            <div className="related_content_details_item">
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between"
                                    onClick={() => setIsVideosOpen(!isVideosOpen)}>
                                    {romeVideos.length} {romeVideos.length > 1 ? 'expériences immersives' : 'expérience immersive'}
                                    {isVideosOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isVideosOpen
                                    && <CardGroupMediaComponent mediaType="videos" mediaList={videosList} readOne={readOneVideo} nbCol={isExpand ? 3 : 6} />
                                }
                            </div>
                        }
                        {isUsedInStory && !isFetchingStories &&
                            <div className="related_content_details_item">
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between"
                                    onClick={() => setIsStoriesOpen(!isStoriesOpen)}>
                                    {romeStories.length} {romeStories.length > 1 ? 'stories' : 'story'}

                                    {isStoriesOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isStoriesOpen
                                    && <CardGroupMediaComponent mediaType="stories" mediaList={storiesList} readOne={readOneStory} nbCol={isExpand ? 3 : 6} />
                                }
                            </div>
                        }
                        {(isFetchingAllVideos || isFetchingStories || isFetchingAllProfessionals) && <Loader />}
                    </Row>
                </Container>
                : <p className='ms-4'>Aucune ressource</p>
            }
        </Card.Body>
    </Card>);
}

RomeDetailsContentsComponent.propTypes = {
    romePros: PropTypes.array.isRequired,
    romeVideos: PropTypes.array.isRequired,
    romeStories: PropTypes.array.isRequired,
    isFetchingStories: PropTypes.bool.isRequired,
    isFetchingAllVideos: PropTypes.bool.isRequired,
    isFetchingAllProfessionals: PropTypes.bool.isRequired,
    isExpand: PropTypes.bool.isRequired,
    setIsExpand: PropTypes.any.isRequired,
};

export default RomeDetailsContentsComponent;