import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useDynamicTableWithParams = ({withParams = false, sort, setSort, revert, setRevert, contentSort, initSort}) => {
    
    const [searchParam, setSearchParam] = useSearchParams();
    const [firstRenderDone, setFirstRenderDone] = useState(false);

    const stringToBool = (string) => {
        return string === 'true';
    }

    const setParamsToUrl = (newParams) => {
        const paramsToSet = {}

        for (const [key, value] of searchParam.entries()) {
            paramsToSet[key] = value;
        }

        for (const [key, value] of newParams) {
            paramsToSet[key] = value;
        }
        setSearchParam(paramsToSet);
    }

 // Handle params from URL on first render, if any
 useEffect(() => {
    if (!withParams)
        return
    
    const newSearchParam = searchParam.get('sortType');
    if (newSearchParam ) {
        setSort(contentSort.find(type => type.value === newSearchParam) || initSort)
    } else {
        setSort(initSort)
    }
    const newSortRevert = searchParam.get('sortRevert');
    if (newSortRevert) {
        setRevert(stringToBool(newSortRevert))
    } else {
        setRevert(false);
    }

    setFirstRenderDone(true);

}, [])

    useEffect(() => {
        if (!withParams)
        return

        firstRenderDone && sort.value && setParamsToUrl([
            ['sortRevert', revert],
            ['sortType', sort.value],
        ])

    }, [sort, revert])

    return {}

}

export default useDynamicTableWithParams;