import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import useBackButton from "../../hooks/useBackButton.jsx";
import JobAppellationsTableComponent from "../../components/romes/JobAppellationsTableComponent.jsx";
import DownloadJobsVideosPDF from "../../components/widgets/generatePDFs/jobsVideoTable.jsx";

import { useStoreActions, useStoreState } from 'easy-peasy';

const JobAppellationsTableWithParams = withURLParamsTableComponent(JobAppellationsTableComponent);

const JobAppellationsPage = () => {

    const { allJobAppellations, allJobAppellationsFull, isFetchingAllJobAppellations, isFetchingAllJobAppellationsFull } = useStoreState(state => state.romes);
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState('videos');
    const { fetchAllJobAppellations, fetchAllJobAppellationsFull } = useStoreActions(actions => actions.romes);

    const [formattedJobAppellations, setFormattedJobAppellations] = useState([]);
    const [generateAppellationJobsPdf, setGenerateAppellationJobsPdf] = useState(false);

    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (métier, code ROME, domaine professionnel...)', delay: 400 });

    const { backButtonComponent } = useBackButton();

    const btnPdfJobsLabel = "Pdf expériences immersives par métier";

    useEffect(() => {
        if (!allJobAppellations?.length && !isFetchingAllJobAppellations) {
            fetchAllJobAppellations();
        }
    }, [allJobAppellations, isFetchingAllJobAppellations])

    useEffect(() => {
        if (!allJobAppellationsFull?.length && !isFetchingAllJobAppellationsFull) {
            fetchAllJobAppellationsFull();
        }
    }, [allJobAppellationsFull, isFetchingAllJobAppellationsFull])

    useEffect(() => {
        if (!isFetchingAllJobAppellationsFull && !isFetchingAllVideos
            && allJobAppellationsFull.length !== 0 && allVideos.length !== 0
        ) {
            setFormattedJobAppellations(
                allJobAppellationsFull.map(job => {
                    const videoUniqueIds = job.pros?.flatMap(pro => pro.videos);

                    return {
                        ...job, 
                        videos: allVideos?.filter(video => videoUniqueIds.includes(video.uniqueId))
                    }
                })
            );
        }
    }, [allJobAppellationsFull, allVideos, isFetchingAllJobAppellationsFull, isFetchingAllVideos])

    const filteredJobAppellations = useMemo(() => {
        if(!search) return formattedJobAppellations;
        else {
            const filterJobAppellations = (search) => {
                if (search === '') {
                    return formattedJobAppellations
                }
                return formattedJobAppellations
                    .filter(job => HelperMetiers360.isSearchInText(search, job.rome)
                        || HelperMetiers360.isSearchInText(search, job.label) 
                        || HelperMetiers360.isSearchInText(search, job.grandDomaine?.label)
                        || HelperMetiers360.isSearchInText(search, job.pros?.map(pro => pro.name)?.join())
                        || HelperMetiers360.isSearchInText(search, job.videos?.map(video => video?.videoName)?.join())
                    )
            }
            return [...filterJobAppellations(search)];
        }
    }, [search, formattedJobAppellations, allJobAppellationsFull, isFetchingAllJobAppellationsFull]);

    const isNoJobs = !isFetchingAllJobAppellationsFull && allJobAppellationsFull.length === 0;
    const isNoResult = !isNoJobs && filteredJobAppellations.length === 0;

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    Métiers
                    {backButtonComponent}
                </h2>
            </Row>
            <Row className="mb-3">
                <div className="p-0">
                    {generateAppellationJobsPdf
                        ? <DownloadJobsVideosPDF title={btnPdfJobsLabel} buttonText={btnPdfJobsLabel}
                            fileName="tableau-experiences-par-metier"
                            jobs={formattedJobAppellations?.sort((a, b) => a.label.localeCompare(b.label))} />
                        : <Button variant="primary" onClick={() => { setGenerateAppellationJobsPdf(true) }}
                            disabled={formattedJobAppellations?.length === 0 || allVideos?.length === 0}>
                            <i className="fas fa-download mr-1" />{btnPdfJobsLabel}
                        </Button>}
                </div>
            </Row>
            <Row>
                {searchInput}
            </Row>
            <Row>
                {(isFetchingAllJobAppellationsFull || isFetchingAllVideos) ?
                    <Loader />
                    : <>
                        {!isNoJobs && <JobAppellationsTableWithParams jobAppellations={filteredJobAppellations} />}
                        {isNoJobs && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun métier</Alert>}
                        {isNoResult && <Alert className="mt-3" variant="warning">Aucun métier ne correspond à votre recherche</Alert>}
                    </>}
               
            </Row>
        </Container>
    )
}

export default JobAppellationsPage;