import { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useNavigate, useLocation } from "react-router-dom";

import Unlogged from './pages/security/Unlogged';
import LayoutPage from './pages/layoutPage/LayoutPage';
import Loader from './components/widgets/Loader';


import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';

function App() {
  const { sharedSlugs, isFetchNecessary:isFetchParamsNecessary } = useStoreState(state => state.params);
  const { fetchParams } = useStoreActions(actions => actions.params);

  const { isLogged, isLogging, shouldCheckAuth } = useStoreState(state => state.auth);
  const {shouldNavigate, path } = useStoreState(state => state.navigation );
  const { getAuthStatus,  } = useStoreActions(actions => actions.auth);

  const {getActions} = useStoreActions(actions => actions.actionSlugs);

  const navigate = useNavigate();

  useEffect(() => {
    if(isFetchParamsNecessary) {
      fetchParams();
    }
  }, [isFetchParamsNecessary, fetchParams]);

  useEffect(() => {
    if (!isFetchParamsNecessary && shouldCheckAuth) {
      getAuthStatus()
        .then(res => {
          getActions();
        });
    }
    if(isLogged){
        const payload = {checkAtInterval: true} 
        const interval = setInterval(() => {getAuthStatus(payload);},1000*60*20);
        return () => {
            clearInterval(interval);
        };
    }
  }, [getAuthStatus, getActions, shouldCheckAuth, isFetchParamsNecessary]);

  useEffect(() => {
    if(shouldNavigate) {
      navigate(path);
    }
  },[shouldNavigate, path, navigate]);

  const location = useLocation();
  const userActivation = location.pathname.includes(sharedSlugs.activation);

  return (
    <>
      {
        (!isLogging && isLogged && !userActivation) && <LayoutPage />
      }
      {
        ((!isLogging && !isLogged) || userActivation) && <Unlogged />  
      }
      {
        isLogging && <Loader />
      }
    </>
    );
}

export default App;
