import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import AuthorizationChecker from '../../../../services/AuthorizationChecker.js';
import HelperMetiers360 from '../../../../services/HelpersMetiers360.js';

const RomeConditionsTabComponent = (props) => {
    const { rome } = props;
    const conditions = rome.contextesTravail?.length > 0 ? rome.contextesTravail : [];

    const workingConditionsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("workingConditions"));
    const OneWorkingConditionSlugs = workingConditionsSlugs?.readOne ?? null;

    const conditionsByCategory = [];
  
    conditions.forEach((condition) => {
        const conditionCategory = condition.category;

        const conditionCategoryIndex = conditionsByCategory.findIndex((conditionItem) => 
            conditionItem.category === conditionCategory);

        if (conditionCategoryIndex === -1) {
            conditionsByCategory.push({
                category: conditionCategory,
                conditions: [condition]
            });
        } else {
            conditionsByCategory[conditionCategoryIndex].conditions.push(condition);
        }
    });

    return conditionsByCategory.map((conditionCategory) => {
        return <div key={HelperMetiers360.generateUniqueId()}>
            <h4 className='mt-4'>{ HelperMetiers360.categoryWorkingConditionsLabel(conditionCategory.category) ?? conditionCategory.category }</h4>
            <ul>
                {conditionCategory.conditions.map((condition) => 
                    <li key={'woco_'+condition.code}>
                        { AuthorizationChecker.isAdmin() && OneWorkingConditionSlugs
                            ? <Link to={OneWorkingConditionSlugs.replace(':uniqueId', condition.code)}>
                                {condition.label}</Link>
                            : <>{condition.label}</>
                        }
                    </li>)
                }
            </ul>
        </div>
    });

}

RomeConditionsTabComponent.propTypes = {
    rome: PropTypes.object.isRequired
};

export default RomeConditionsTabComponent