import React, { useEffect, useState, useMemo } from "react";
import axios from "../../services/axios.js";
import { Form, Row, Col, Alert, Container, Button } from 'react-bootstrap';
import ChronogramComponent from "../../components/producerStats/ChronogramComponent";
import GeoMap from "../../components/producerStats/GeoMap";
import ClientCategories from "../../components/producerStats/ClientCategories";
import MeanViewsDuration from "../../components/producerStats/MeanViewsDuration";
import MeanViewsDurationPercent from "../../components/producerStats/MeanViewsDurationPercent";
import Loader from "../../components/widgets/Loader";
import SelectMultipleItemsComponent from "../../components/widgets/selectMultipleItemsComponent/SelectMultipleItemsComponent";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import { useStoreState } from 'easy-peasy';


import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";
import "./ProducerStatsPage.scss";

registerLocale('fr', fr);

const DEBOUNCE_DELAY = 500;

const StatsPage = () => {

    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState('videos');
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);

    const [selectedVideos, setSelectedVideos] = useState([]);
    const [aggregateData, setAggregateData] = useState(false);
    const [graph, setGraph] = useState("");
    const [graphData, setGraphData] = useState();
    const [isFetching, setIsFetching] = useState(false);

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    const graphToDisplay = {
        chronogram: { component: <ChronogramComponent data={graphData} aggregated={selectedVideos.length <= 1 ? false : aggregateData} />, canAggregate: true },
        geoMap: { component: <GeoMap data={graphData} />, canAggregate: false, dateFilter: true },
        clientCategories: { component: <ClientCategories data={graphData} />, canAggregate: false, dateFilter: true },
        meanViewsDuration: { component: <MeanViewsDuration data={graphData} aggregated={selectedVideos.length <= 1 ? false : aggregateData} />, canAggregate: true, dateFilter: true },
        meanViewsDurationPercent: { component: <MeanViewsDurationPercent data={graphData} aggregated={selectedVideos.length <= 1 ? false : aggregateData} />, canAggregate: true, dateFilter: true }
    }

    function removeVideo(video) {
        const tempArray = selectedVideos.filter(v => v.uniqueId !== video.uniqueId);
        setSelectedVideos(tempArray);
    }

    const videos = useMemo(() => {
        if (AuthorizationChecker.isAdmin()) {
            return allVideos;
        } else {
            return allVideos?.filter(video => video?.isProducedByClient);
        }
    }, [allVideos]);

    useEffect(() => {

        setGraphData(null);
        if (!graphToDisplay?.[graph]?.canAggregate) { setAggregateData(false) }
        let timeoutId;

        const fetchData = () => {
            if (selectedVideos.length <= 1) { setAggregateData(false) };
            const postUrl = ACTIONS?.producerStats?.getStatsForContents?.url;
            if (selectedVideos?.length && !!graph) {
                setIsFetching(true);
                axios.post(postUrl, {
                    entity: 'videos',
                    uuid: selectedVideos?.map(v => v.uniqueId),
                    startDate: minDate,
                    endDate: maxDate,
                    statType: graph,
                    aggregated: selectedVideos.length > 1 && graphToDisplay?.[graph]?.canAggregate && aggregateData
                }, {
                    responseType: "json",
                }).then((response) => {
                    setGraphData(response);
                }).finally((response) => {
                    setIsFetching(false);
                });
            }
        };

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(fetchData, DEBOUNCE_DELAY);

        return () => clearTimeout(timeoutId);
    }, [selectedVideos, graph, aggregateData, minDate, maxDate]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className='d-flex'>
                        Mesures d'impact
                    </h2>
                </Col>
            </Row>

            {!videos || isFetchingAllVideos ?
                <Loader />
                : videos?.length < 1 ?
                    <Alert variant='warning'>Aucune vidéo produite</Alert>
                : <>
                    <div className="filter-box">
                        <Row className="selectors-row">
                            <Col>
                                    <label>Expériences immersives:</label>
                                <span className="d-flex flex-column w-100">
                                    <SelectMultipleItemsComponent itemsSelected={selectedVideos} setItemsSelected={setSelectedVideos}
                                        items={videos} name="videos" itemKey="uniqueId" itemValue="videoName" id='select-videos' />
                                    {graphToDisplay?.[graph]?.canAggregate && selectedVideos?.length > 1 &&
                                        <Form.Check id="aggregatevideos" className="clickable" label="Aggréger les données" onChange={() => setAggregateData((prev) => !prev)} />}
                                </span>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Graphique:</Form.Label>
                                    <Form.Select
                                        name="graph"
                                        onChange={(e) => setGraph(e.target.selectedOptions[0].value)}
                                        disabled={isFetching}
                                    >
                                        <option value="">--</option>
                                        <option value="chronogram">Vues/Semaines</option>
                                        <option value="geoMap">Carte géographique</option>
                                        <option value="clientCategories">Catégories d'organisations</option>
                                        {AuthorizationChecker.isAdmin() &&
                                            <>
                                            <option value="meanViewsDuration">Durée moyenne de lecture</option>
                                            <option value="meanViewsDurationPercent">Durée moyenne de lecture (%)</option>
                                            </>}
                                    </Form.Select>
                                </Form.Group >
                            </Col>
                        </Row>
                        {graphToDisplay?.[graph]?.dateFilter && <Row className="date-row">
                            <Col>
                                <label>Dates:</label>
                                <span className="d-flex flex-column w-100">
                                    <div className="d-flex w-100">
                                        <DatePicker
                                            showIcon
                                            locale='fr'
                                            id='start'
                                            dateFormat="dd/MM/yyyy"
                                            selected={minDate}
                                            onChange={(date) => setMinDate(date)}
                                            placeholderText="jj/mm/aaaa"
                                            maxDate={maxDate}
                                        />
                                        <label htmlFor="end" className="m-2">&nbsp;au</label>
                                        <DatePicker
                                            showIcon
                                            locale='fr'
                                            id='end'
                                            dateFormat="dd/MM/yyyy"
                                            selected={maxDate}
                                            onChange={(date) => setMaxDate(date)}
                                            placeholderText="jj/mm/aaaa"
                                            minDate={minDate}
                                        />
                                        <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { setMinDate(null); setMaxDate(null) }}>
                                            <i className="fas fa-redo"></i>
                                        </Button>
                                    </div>
                                </span>
                            </Col>
                        </Row>}
                    </div>
                    <Row className="w-100">
                        <div className="graph-container">
                            {!selectedVideos?.length ?
                                <Alert variant="info">Merci de sélectionner une vidéo</Alert>
                                : (!graph || graph == "") ?
                                    <Alert variant="info">Merci de sélectionner un type de graphique</Alert>
                                    : isFetching ?
                                        <Loader />
                                        : graphData == null ?
                                            <Loader />
                                            : graphData?.length ?
                                                graphToDisplay?.[graph]?.component
                                                : <Alert variant="warning">Pas de données disponibles</Alert>
                            }
                        </div>
                    </Row>
                    <Row>
                        <div className="selectedVideos">
                            {selectedVideos?.map((video) => {
                                return (
                                    <div className="video_badge"
                                        key={`badge${video.uniqueId}`}
                                        onClick={() => removeVideo(video)}>
                                        {video.videoName}
                                    </div>)
                            })}
                        </div>
                    </Row>
                </>
            }
        </Container >
    );
}

export default StatsPage;