import React, {useEffect} from "react";
import { Table } from "react-bootstrap";
import RowCSVDataComponent from "./RowCSVDataComponent";

/**
 * This is a React component that renders a table with rows for selecting keys corresponding to data in
 * a CSV file.
 * @returns This code returns a React functional component called `SelectCSVDataComponent`. It renders
 * a table with three columns: "Donnée" (Data), "Clé du fichier csv" (CSV file key), and "Exemple de
 * valeur" (Example value). The table rows are generated dynamically based on the `dataStructure` prop
 * passed to the component. The component also uses the `useEffect
 * 
 * @example of dataStructure : [
 *      {field:'email',csvIdx:null, label:<>Mail :</>},
 *      {field:'clientUniqueId',csvIdx:null, label:<>Unique Id client :</>}
 *      ]
 * rowChoosen get true when every csvIdx are set
 * 
 */
const SelectCSVDataComponent = (props) => {
    const {csvContent, dataStructure, setDataStructure, fieldsNecessary = [], setRowChoosen} = props;

    useEffect(()=> {
        const newRowChoosen = dataStructure
            .filter(data => fieldsNecessary.includes(data.field))
            .reduce((acc, data) => acc && data.csvIdx!='--', true);
        setRowChoosen(newRowChoosen);
    }, [dataStructure])

    if(!csvContent) {return null;}

    const tableRows = dataStructure
        .map((value, idx) => <RowCSVDataComponent 
                            csvContent={csvContent}
                            key={value.field}
                            idx = {idx}
                            dataStructure={dataStructure} 
                            setDataStructure={setDataStructure} 
                            />
        );

    return <>
        Sélectionner les clés correspondant aux données :
        <Table size="sm" striped >
            <thead>
                <tr>
                    <td>Donnée</td>
                    <td>Clé du fichier csv</td>
                    <td>Exemple de valeur</td>
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </Table>
    </>;
}

export default SelectCSVDataComponent