import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Form, Col, Row, Container } from 'react-bootstrap';

import Loader from '../../../widgets/Loader.jsx';
import ButtonLoaderComponent from '../../../widgets/ButtonLoaderComponent.jsx';
import { useModal } from '../../../../hooks/useModal.jsx';

import './EditClientVRBkgdImageComponent.scss';

const EditClientVRBkgdImageComponent = (props) => {
    const { client } = props;

    const { updateClientBg, deleteClientBg } = useStoreActions(actions => actions.clients);
    const { apiData } = useStoreState(state => state.actionSlugs)

    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    
    const createModal = () => {
        setModalData({
            ...modalData,
            header:"Image de fond du casque",
            content:<div className='text-center'><img width={"100%"} src={client.bkgImg} alt="background" /></div>,
        });
        setModalShow(true);
    };

	const {csrfToken, formName} = apiData.ACTIONS['clients']['editProps']['editBackgroundImage'];


    const handleAction = (action, payload) => {
        return action(payload)
            .then(response => {
                setIsLoading(false);
            })
            .catch(content => {
                setFormErrors({ backgroundImage: content.errors });
                setIsLoading(false);
            });
    }

    const onImageSelect = () => {
        setIsLoading(true);
        const uploadedFile = document.getElementById('formFileInput');
        if(uploadedFile.files[0].size > 32*10**6){
            setFormErrors({backgroundImage:'Le fichier est trop gros. Taille limite : 32 Mo.'});
            setIsLoading(false);
            return;
         }

        const formData = new FormData(document.getElementById('SendImg-form'));
        handleAction(updateClientBg, {formData, uniqueId: client.uniqueId});
    }

    const onDeleteImage = () => {
        setIsLoading(true);
        handleAction(deleteClientBg, { uniqueId: client.uniqueId });
    }


    return (
        <Container>
            <Row>
                <Col className='image-uploader'>
                        {isLoading ? <Loader /> : <img src={client.bkgImg} onClick={createModal} alt="upload" style={{ cursor: "pointer" }} />}
                        {(client.bkgImg && !client.bkgImg.includes('default')) ? 
                            <ButtonLoaderComponent 
                                size="sm"
                                onClick={onDeleteImage} 
                                msg="Revenir à l'image par défaut"
                                isSending={isLoading}
                                disabled={isLoading}
                                />:null}
                    {<>
                        {modalComponent}
                    </>}
                </Col>
                <Col className="d-flex align-self-end">
                    <Form id="SendImg-form" method="post" encType="multipart/form-data">
                        <Form.Group controlId="formFileInput" className='form-upload-file'>
                            <Form.Label>Éditer l'image de fond du casque</Form.Label>
                            <Form.Control
                                type="file"
                                name={`background[backgroundImage]`}
                                accept="image/png, image/jpeg, image/webp"
                                onChange={onImageSelect}
                                isInvalid={formErrors.backgroundImage !== undefined}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors.backgroundImage}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                name="background[_token]"
                                defaultValue={csrfToken}
                                type="hidden"
                            />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    );

}

export default EditClientVRBkgdImageComponent;