import { action, actionOn, thunk } from "easy-peasy";
import { store } from './index.js';
import axios from '../services/axios';

const clientsInitialState = {
    allClients: [],
    isFetchingAllClients: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    propsFetched: {},
    allClientsCRM: [],
    isFetchingAllClientsCRM: false,
    isUpdateNecessaryForCRM: true,
    clientById: null,
    isFetchingOneClient: false,
    clientCRMById: null,
    clientsCategories: [],
    formationsByClientId: [],
    isClientByIdDeletable: false,
    isFetchingFormationsByClientId: false,
    isFetchingIsClientByIdDeletable: false,
    allSessionsByIdForClient: [],
    isFetchingAllSessionsByIdForClient: false,
    formationSessionByIdForClient: null,
    isFetchingOneFormationSessionForClient: false,
    subscriptionsByClientId: [],
    headsetsByClientId: [],
    isFetchingSubscriptionsByClientId: false,
    isFetchingHeadsetsByClientId: false,
    accessibleGroupsByClientId: [],
    isFetchingAccessibleGroupsByClientId: false,
    isUpdatingOneClient: false,
    contactsM360: [],
    isUpdateNecessaryForContactsM360: true,
    accessibleVideosByClientId: [],
    isFetchingAccessibleVideosByClientId: false,
    accessibleQuizzesByClientId: [],
    isFetchingAccessibleQuizzesByClientId: false,
    isGradualFetchClientsNecessary: true,
    isGradualFetchClientsCRMNecessary: true,
    isAllClientPropsAreMerged: false,
    contactsByClientId: [],
    isUpdateNecessaryForClientInteractionTypes: true,
    isFetchingMultipleSubscriptions: false,
    addressesByClientId: [],
    isFetchingAddressesByClientId: false,
    isSendingAddressData: false,
    updatedCRMKeysInAllClients: [],
}

const clients = {
    ...clientsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allClients: state.allClients,
                    isFetchingAllClients: state.isFetchingAllClients,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    propsFetched: state.propsFetched,
                    allClientsCRM: state.allClientsCRM,
                    isFetchingAllClientsCRM: state.isFetchingAllClientsCRM,
                    isUpdateNecessaryForCRM: state.isUpdateNecessaryForCRM,
                    clientById: state.clientById,
                    isFetchingOneClient: state.isFetchingOneClient,
                    clientCRMById: state.clientCRMById,
                    clientsCategories: state.clientsCategories,
                    formationsByClientId: state.formationsByClientId,
                    isFetchingFormationsByClientId: state.isFetchingFormationsByClientId,
                    isClientByIdDeletable: state.isClientByIdDeletable,
                    isFetchingIsClientByIdDeletable: state.isFetchingIsClientByIdDeletable,
                    allSessionsByIdForClient: state.allSessionsByIdForClient,
                    isFetchingAllSessionsByIdForClient: state.isFetchingAllSessionsByIdForClient,
                    formationSessionByIdForClient: state.formationSessionByIdForClient,
                    isFetchingOneFormationSessionForClient: state.isFetchingOneFormationSessionForClient,
                    subscriptionsByClientId: state.subscriptionsByClientId,
                    headsetsByClientId: state.headsetsByClientId,
                    isFetchingSubscriptionsByClientId: state.isFetchingSubscriptionsByClientId,
                    isFetchingHeadsetsByClientId: state.isFetchingHeadsetsByClientId,
                    accessibleGroupsByClientId: state.accessibleGroupsByClientId,
                    isFetchingAccessibleGroupsByClientId: state.isFetchingAccessibleGroupsByClientId,
                    isUpdatingOneClient: state.isUpdatingOneClient,
                    contactsM360: state.contactsM360,
                    isUpdateNecessaryForContactsM360: state.isUpdateNecessaryForContactsM360,
                    accessibleVideosByClientId: state.accessibleVideosByClientId,
                    isFetchingAccessibleVideosByClientId: state.isFetchingAccessibleVideosByClientId,
                    accessibleQuizzesByClientId: state.accessibleQuizzesByClientId,
                    isFetchingAccessibleQuizzesByClientId: state.isFetchingAccessibleQuizzesByClientId,
                    isGradualFetchClientsNecessary: state.isGradualFetchClientsNecessary,
                    isGradualFetchClientsCRMNecessary: state.isGradualFetchClientsCRMNecessary,
                    isAllClientPropsAreMerged: state.isAllClientPropsAreMerged,
                    contactsByClientId: state.contactsByClientId,
                    isUpdateNecessaryForClientInteractionTypes: state.isUpdateNecessaryForClientInteractionTypes,
                    isFetchingMultipleSubscriptions: state.isFetchingMultipleSubscriptions,
                    addressesByClientId: state.addressesByClientId,
                    isFetchingAddressesByClientId: state.isFetchingAddressesByClientId,
                    isSendingAddressData: state.isSendingAddressData,
                    updatedCRMKeysInAllClients: state.updatedCRMKeysInAllClients,
                } = clientsInitialState);
            }
        }
    ),

    // handling all clients
    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setAllClients: action((state, payload) => {
        state.allClients = payload;
    }),
    setNewClient: action((state, payload) => {
        for (const element of payload) {
            state.allClients.push(element);
        }
    }),
    setNewClientCRM: action((state, payload) => {
        for (const element of payload) {
            state.allClientsCRM.push(element);
        }
    }),
    setIsFetchingAllClients: action((state, payload) => {
        state.isFetchingAllClients = payload;
    }),
    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),
    setPropsFetched: action((state, payload) => {
        state.propsFetched[payload] = true;
    }),
    setAllClientsCRM: action((state, payload) => {
        state.allClientsCRM = payload;
    }),
    setIsFetchingAllClientsCRM: action((state, payload) => {
        state.isFetchingAllClientsCRM = payload;
    }),
    setIsUpdateNecessaryForCRM: action((state, payload) => {
        state.isUpdateNecessaryForCRM = payload;
    }),
    setIsGradualFetchClientsNecessary: action((state, payload) => {
        state.isGradualFetchClientsNecessary = payload;
    }),
    setIsGradualFetchClientsCRMNecessary: action((state, payload) => {
        state.isGradualFetchClientsCRMNecessary = payload;
    }),
    setIsAllClientPropsAreMerged: action((state, payload) => {
        state.isAllClientPropsAreMerged = payload;
    }),

    setIsUpdateNecessaryForContactsM360: action((state, payload) => {
        state.isUpdateNecessaryForContactsM360 = payload;
    }),

    setIsFetchingMultipleSubscriptions: action((state, payload) => {
        state.isFetchingMultipleSubscriptions = payload;
    }),


    // Merge props between allClientsCRM and allClients only if not already merged


    mergeAllClients: thunk((actions, payload, helpers) => {
        const { allClients, allClientsCRM, isAllClientPropsAreMerged, updatedCRMKeysInAllClients, propsFetched } = helpers.getState();
    
        const keysToUpdate = ["tagOperations", "tagOperationsName", "categories", "contactsM360", "interactions", "addressCity", "addressZipCode", "status"];

        if(allClientsCRM?.length > 0 && allClients?.length > 0) {

            if (allClients[0].isFactured === undefined || !isAllClientPropsAreMerged) {

                const clientsCRMMap = new Map(allClientsCRM.map(client => [client.uniqueId, client]));

                const newAllClients = allClients.map(client => {
                    const clientCRM = clientsCRMMap.get(client.uniqueId);
                    const updatedClient = { ...client };
            
                    if (client.isFactured === undefined) {
                        updatedClient.isFactured = !!clientCRM;
                    }
                    if (!isAllClientPropsAreMerged) {
                        keysToUpdate.forEach(key => {
                            if (client[key] === undefined && clientCRM && clientCRM[key] !== undefined) {
                                updatedClient[key] = clientCRM[key];
                                if(!updatedCRMKeysInAllClients.includes(key)) {
                                    updatedCRMKeysInAllClients.push(key);
                                }
                            }
                        });
                    }
                    return updatedClient;
                });
                actions.setAllClients(newAllClients);
                if (keysToUpdate.every(key => updatedCRMKeysInAllClients.includes(key))) {
                    actions.setIsAllClientPropsAreMerged(true);
                }

            }

            if (allClientsCRM[0].isSubscriber === undefined || (allClientsCRM[0].subscriptionEndDate === undefined && propsFetched.getSubscriptions)) {

                const clientsMap = new Map(allClients.map(client => [client.uniqueId, client]));

                const newAllClientsCRM = allClientsCRM.map(clientCRM => {
                    const client = clientsMap.get(clientCRM.uniqueId);
                    const updatedClientCRM = { ...clientCRM };

                    updatedClientCRM.isSubscriber = !!client;

                    if (updatedClientCRM.subscriptionEndDate === undefined && propsFetched.getSubscriptions) {
                        updatedClientCRM.subscriptionEndDate = client?.subscriptionEndDate ?? null;
                    }

                    if (client) {
                        updatedClientCRM.hasSubscriptions = client.subscriptionCount > 0;
                    }
                    return updatedClientCRM;
                });

                actions.setAllClientsCRM(newAllClientsCRM);
        
            }

        }
    }),
    

    mergeOneClient: thunk((actions, payload, helpers) => {
        const { allClients, allClientsCRM } = helpers.getState();
        const clientUpdated = payload?.client;
        const clientCRMUpdated = payload?.clientCRM;

        if(clientUpdated !== undefined || clientCRMUpdated !== undefined) {
            const indexClientToUpdate = clientCRMUpdated !== undefined && 
                allClients.findIndex(client => client.uniqueId === clientCRMUpdated.uniqueId);
            const indexClientCRMToUpdate = clientUpdated !== undefined && 
                allClientsCRM.findIndex(clientCRM => clientCRM.uniqueId === clientUpdated.uniqueId);

            if(clientCRMUpdated !== undefined && indexClientToUpdate !== undefined) {
                const newClient = {
                    uniqueId: clientCRMUpdated.uniqueId,
                    isFactured: true,
                };

                const keysToUpdate = ["tagOperations", "tagOperationsName", "categories", "contactsM360", "interactions", "addressCity", "addressZipCode", "status"];
                keysToUpdate.forEach(key => {
                    if (key === "status" && clientCRMUpdated[key] !== undefined) {
                        newClient[key] = clientCRMUpdated[key]?.label; 
                    } else if(clientCRMUpdated[key] !== undefined) {
                        newClient[key] = clientCRMUpdated[key];
                    }
                });

                actions.updateClientsProps([newClient]);
            } else if(clientUpdated !== undefined && indexClientCRMToUpdate !== undefined) {
                actions.updateClientsCRMProps([{
                    uniqueId: clientUpdated.uniqueId,
                    isSubscriber: true
                }]);
            }
        }
    }),

    addClient: thunk((actions, payload) => {
        actions.setNewClient(payload);
        actions.mergeOneClient({ client: payload });
    }),

    addClientCRM: thunk((actions, payload) => {
        actions.setNewClientCRM(payload);
    }),

    fetchClients: thunk((actions, payload, helpers) => {
        const { isFetchingAllClients, isUpdateNecessary } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.clients?.readAll?.url;
        if (!isFetchingAllClients && isUpdateNecessary) {
            actions.setIsFetchingAllClients(true);
            return axios.get(getAllUrl)
                .then(data => {
                    actions.setAllClients(data);
                    actions.mergeAllClients();
                    actions.setIsFetchingAllClients(clientsInitialState.isFetchingAllClients);
                    actions.setIsUpdateNecessary(clientsInitialState.isUpdateNecessary);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),


    fetchClientsCRM: thunk((actions, payload, helpers) => {
        const { isFetchingAllClientsCRM, isUpdateNecessaryForCRM } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;
        return actions.fetchClients()
            .finally(() => {
                const getClientCRMUrl = apiData.ACTIONS?.clientsCRM?.readAll?.url;
                if (!isFetchingAllClientsCRM && isUpdateNecessaryForCRM && getClientCRMUrl) {
                    actions.setIsFetchingAllClientsCRM(true);
                    return axios.get(getClientCRMUrl)
                        .then(data => {
                            actions.setAllClientsCRM(data);
                            actions.mergeAllClients();
                            actions.setIsFetchingAllClientsCRM(clientsInitialState.isFetchingAllClientsCRM);
                            actions.setIsUpdateNecessaryForCRM(false);
                            return data;
                        })
                        .catch((error) => {
                            return Promise.reject(error);
                        })
                }
            });

    }),


    // Fetch clients/clientsCRM without merging infos
    fetchClientsWithNoMerge: thunk((actions, payload, helpers) => {
        const { isFetchingAllClients, isUpdateNecessary } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.clients?.readAll?.url;

        if (!isFetchingAllClients && isUpdateNecessary) {

            actions.setIsFetchingAllClients(true);
            return axios.get(getAllUrl)
                .then(data => {
                    actions.setAllClients(data);
                    actions.setIsFetchingAllClients(clientsInitialState.isFetchingAllClients);
                    actions.setIsUpdateNecessary(clientsInitialState.isUpdateNecessary);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),
    fetchClientsCRMWithNoMerge: thunk((actions, payload, helpers) => {
        const { isFetchingAllClientsCRM, isUpdateNecessaryForCRM } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getClientCRMUrl = apiData.ACTIONS?.clientsCRM?.readAll?.url;
        if (!isFetchingAllClientsCRM && isUpdateNecessaryForCRM && getClientCRMUrl) {

            actions.setIsFetchingAllClientsCRM(true);
            return axios.get(getClientCRMUrl)
                .then(data => {
                    actions.setAllClientsCRM(data);
                    actions.setIsFetchingAllClientsCRM(clientsInitialState.isFetchingAllClientsCRM);
                    actions.setIsUpdateNecessaryForCRM(false);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }

    }),


    // Fetch all clients informations and gather all the clients/clientsCRM infos in allClients. Used for pages outside Etablissements where we need clients data (headsetPage). Perform merge only once in the end.
    fetchAllClientsAndMergeWithProps: thunk((actions, payload, helpers) => {

        const { hasAccessToAllPropsClientsCRM } = payload;

        const initialPromises = [
            actions.fetchClientsWithNoMerge()
        ];
        if (hasAccessToAllPropsClientsCRM) {
            initialPromises.push(actions.fetchClientsCRMWithNoMerge());
        }    
        return Promise.all(initialPromises)
        .then(() => {
            const additionalPromises = [
                actions.gradualFetchClientsDataWithNoMerge()
            ];
            if (hasAccessToAllPropsClientsCRM) {
                additionalPromises.push(actions.gradualFetchClientsCRMDataWithNoMerge());
            }
            return Promise.all(additionalPromises);
        })
        .then(() => {
            actions.mergeAllClients();
        })
        .catch((error) => {
            return Promise.reject(error);
        })
    }),

    // Gradual fetch of all props for allClients and allClientsCRM + merge shared data (tagOperation, contactsM360...) if necessary
    fetchAllPropsForAllClients: thunk((actions, payload, helpers) => {
        const { hasAccessToAllPropsClientsCRM } = payload;

        return Promise.all([
            hasAccessToAllPropsClientsCRM && actions.fetchClientsCRM(),
            actions.fetchClients()
        ])
        .then(() => {
            actions.gradualFetchClientsData();
            hasAccessToAllPropsClientsCRM 
                ? actions.gradualFetchClientsCRMData()
                : actions.setIsGradualFetchClientsCRMNecessary(false);
        })
        .catch(error => Promise.reject(error));
    }),

    gradualFetchClientsData: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { propsFetched, isGradualFetchClientsNecessary } = helpers.getState();

        if(isGradualFetchClientsNecessary && apiData.ACTIONS.clients) {
            const propsForAll = apiData.ACTIONS.clients.getPropsForAll ;
            const gradualAccessRoutes = propsForAll 
                ? Object.keys(propsForAll).map(keyProp => {
                        return {
                            name: keyProp,
                            url: propsForAll[keyProp].url,
                        };
                    })
                : [];
            if(gradualAccessRoutes.length > 0) {
                let gradualFetchToBeDone = [];
                gradualAccessRoutes.forEach(prop => {
                    if(propsFetched[prop?.name] === undefined) {
                        gradualFetchToBeDone.push(actions.fetchClientsProps(prop));
                    }
                });
                Promise.all(gradualFetchToBeDone).then(function(results) {
                    actions.mergeAllClients();
                    actions.setIsGradualFetchClientsNecessary(false);
               });
            } else {
                actions.setIsGradualFetchClientsNecessary(false);
            }
        }
    }),
    
    gradualFetchClientsCRMData: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { propsFetched, isGradualFetchClientsCRMNecessary } = helpers.getState();

        if(isGradualFetchClientsCRMNecessary && apiData.ACTIONS.clientsCRM) {
            const propsForAll = apiData.ACTIONS.clientsCRM.getPropsForAll;
            const gradualAccessRoutes = propsForAll
                ? Object.keys(propsForAll).map(keyProp => {
                        return {
                            name: keyProp,
                            url: propsForAll[keyProp].url,
                        };
                    })
                : [];

                if(gradualAccessRoutes.length > 0) {
                    let gradualFetchToBeDone = [];
                    gradualAccessRoutes.forEach(prop => {
                        if(propsFetched[prop?.name] === undefined) {
                            gradualFetchToBeDone.push(actions.fetchClientsCRMProps(prop));
                        }
                    });
                    Promise.all(gradualFetchToBeDone).then(function(results) {
                        actions.mergeAllClients();
                        actions.setIsGradualFetchClientsCRMNecessary(false);
                   });
                } else {
                    actions.setIsGradualFetchClientsCRMNecessary(false); 
                }
        }
    }),

    // Gradually fetch clients data without merging clients/clientsCRM. Used for fetchAllClientsAndMergeWithProps
    gradualFetchClientsDataWithNoMerge: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { propsFetched, isGradualFetchClientsNecessary } = helpers.getState();

        if(isGradualFetchClientsNecessary && apiData.ACTIONS.clients) {
            const propsForAll = apiData.ACTIONS.clients.getPropsForAll ;
            const gradualAccessRoutes = propsForAll 
                ? Object.keys(propsForAll).map(keyProp => {
                        return {
                            name: keyProp,
                            url: propsForAll[keyProp].url,
                        };
                    })
                : [];
            if (gradualAccessRoutes.length > 0) {
                let gradualFetchToBeDone = gradualAccessRoutes.map(prop => {
                    if (propsFetched[prop?.name] === undefined) {
                        return actions.fetchClientsProps(prop);
                    }
                    return Promise.resolve();
                });
    
                return Promise.all(gradualFetchToBeDone).then(function(results) {
                    actions.setIsGradualFetchClientsNecessary(false);
                });
            } else {
                actions.setIsGradualFetchClientsNecessary(false);
            }
        }

        return Promise.resolve(); 
    }),
    gradualFetchClientsCRMDataWithNoMerge: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { propsFetched, isGradualFetchClientsCRMNecessary } = helpers.getState();

        if(isGradualFetchClientsCRMNecessary && apiData.ACTIONS.clientsCRM) {
            const propsForAll = apiData.ACTIONS.clientsCRM.getPropsForAll;
            const gradualAccessRoutes = propsForAll
                ? Object.keys(propsForAll).map(keyProp => {
                        return {
                            name: keyProp,
                            url: propsForAll[keyProp].url,
                        };
                    })
                : [];

                if (gradualAccessRoutes.length > 0) {
                    let gradualFetchToBeDone = gradualAccessRoutes.map(prop => {
                        if (propsFetched[prop?.name] === undefined) {
                            return actions.fetchClientsCRMProps(prop);
                        }
                        return Promise.resolve(); 
                    });

                    return Promise.all(gradualFetchToBeDone).then(function(results) {
                        actions.setIsGradualFetchClientsCRMNecessary(false);
                    });
                } else {
                    actions.setIsGradualFetchClientsCRMNecessary(false);
                }
            }
            return Promise.resolve();
    }),


    fetchClientsProps: thunk((actions, payload) => {
        const prop = payload;
        if (prop?.url) {
            return axios.get(prop?.url)
                .then(data => {
                    actions.updateClientsProps(data);
                    actions.setPropsFetched(prop?.name);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
    }),

    fetchClientsCRMProps: thunk((actions, payload) => {
        const prop = payload;
        actions.setPropsFetched(prop?.name);

        if (prop?.url) {
            return axios.get(prop?.url)
                .then(data => {
                    actions.updateClientsCRMProps(data);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
    }),

    updateClientsProps: thunk((actions, payload, helpers) => {
        const allClients = helpers.getState().allClients;
        const allClientsCRM = helpers.getState().allClientsCRM;

        const { setIsMergeClientsPropsInAllUsersNecessary } = helpers.getStoreActions().users;
        const { setIsMergeClientsPropsInAllHeadsetsNecessary } = helpers.getStoreActions().headsets;
        
        const clientMap = new Map(payload?.map(client => [client.uniqueId, client]));
        const newAllClients = allClients.map(client => {
        const additionalProps = clientMap.get(client.uniqueId);
        return { ...client, ...additionalProps };
        });
        actions.setAllClients(newAllClients);
        setIsMergeClientsPropsInAllUsersNecessary(true);
        setIsMergeClientsPropsInAllHeadsetsNecessary(true);

        if (payload?.some(c => c.subscriptionEndDate)) {
            const newAllClientsCRM = allClientsCRM?.map(client => {
                const additionalProps = clientMap.get(client.uniqueId);
                if (additionalProps && 'subscriptionEndDate' in additionalProps) {
                    return { ...client, subscriptionEndDate: additionalProps.subscriptionEndDate };
                }
                return client;
            });
            actions.setAllClientsCRM(newAllClientsCRM);
        }

    }),

    updateClientsPropsForClientById: thunk((actions, payload, helpers) => {
        if(payload?.uniqueId) {
            const allClients = helpers.getState().allClients;
            const indexOldClient = allClients.findIndex(client => client.uniqueId == payload.uniqueId);
            allClients[indexOldClient] = { ...allClients[indexOldClient], ...payload }

            actions.setAllClients(allClients);
        }
    }),

    updateClientsCRMProps: thunk((actions, payload, helpers) => {
        const allClientsCRM = helpers.getState().allClientsCRM;

        const clientCRMMap = new Map(payload?.map(clientCRM => [clientCRM.uniqueId, clientCRM]));
        const newAllClientsCRM = allClientsCRM.map(clientCRM => {
        const additionalProps = clientCRMMap.get(clientCRM.uniqueId);
        return { ...clientCRM, ...additionalProps };
        });

        actions.setAllClientsCRM(newAllClientsCRM);
    }),

    updateOneClientCRM: thunk((actions, payload, helpers) => {
        const allClientsCRM = helpers.getState().allClientsCRM;
        let key = payload?.uniqueId;

        const newAllClientsCRM = allClientsCRM
            .map(client => client.uniqueId === key ? { ...client, ...payload } : { ...client });

        actions.setAllClientsCRM(newAllClientsCRM);
    }),

    removeOneClientCRM: thunk((actions, payload, helpers) => {
        const allClientsCRM = helpers.getState().allClientsCRM;
        let key = payload?.uniqueId;

        const newAllClientsCRM = allClientsCRM
            .filter(client => client.uniqueId !== key);
        actions.setAllClientsCRM(newAllClientsCRM);
    }),

    updateOneClientCRMFromAttach: thunk((actions, payload, helpers) => {
        const allClientsCRM = helpers.getState().allClientsCRM;
    
        const newAllClientsCRM = allClientsCRM
            .map(client => client.uniqueId === payload?.oldUniqueId 
                ? { ...client, ...payload?.newClientCRM }
                : { ...client });

        actions.setAllClientsCRM(newAllClientsCRM);
    }),

    updateOneClientCRMById: thunk((actions, payload, helpers) => {
        actions.setClientCRMById(payload);
    }),

    updateWhiteListClient: thunk((actions, payload) => {
        payload?.clientFullToUpdate && actions.setClientById(payload['clientFullToUpdate']);

        // Update allClients in store if there are several to update
        if(payload?.clientWhiteListsToUpdate?.length > 1) {
            actions.updateClientsProps(payload?.clientWhiteListsToUpdate);
        // Update only 1 of allClients in store if it's alone
        } else if(payload?.clientWhiteListsToUpdate.length === 1) {
            actions.updateClientsPropsForClientById(payload?.clientWhiteListsToUpdate[0]);
        }
    }),

    // handlingOneClient
    setClientById: action((state, payload) => {
        state.clientById = payload;
    }),
    setIsFetchingOneClient: action((state, payload) => {
        state.isFetchingOneClient = payload;
    }),

    fetchClientById: thunk((actions, payload, helpers) => {
        const { isFetchingOneClient } = helpers.getState();

        const { apiData } = helpers.getStoreState().actionSlugs;

        const getOneUrl = apiData.ACTIONS?.clients?.readOne?.url?.replace('uniqueId', payload);


        if (!isFetchingOneClient) {
            actions.setIsFetchingOneClient(true);
            actions.setClientById(clientsInitialState.clientById);
            actions.setSubscriptionsByClientId(clientsInitialState.subscriptionsByClientId);

            return axios.get(getOneUrl)
                .then(data => {
                    actions.setClientById(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingOneClient(clientsInitialState.isFetchingOneClient);
                })
        } else {
            return Promise.resolve();
        }
    }),


    removeClient:  action((state, payload) => {
        state.allClients = state.allClients.filter(client => client.uniqueId !== payload);
    }),

    deleteClient: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const csrfToken =  apiData.ACTIONS?.clients?.delete?.csrfToken;
        const deleteUrl = apiData.ACTIONS?.clients?.delete?.url?.replace('uniqueId', payload);
      
        return axios.post( deleteUrl, {csrfToken})
            .then(data => {
                actions.setClientById(null);
                actions.removeClient(payload);
                actions.updateClientsCRMProps([{
                    uniqueId: payload,
                    isSubscriber: false
                }]);
                return data;
            })
        .catch(error => Promise.reject(error))
    }),


    setClientCRMById: action((state, payload) => {
        state.clientCRMById = payload;
    }),

    fetchClientCRMById: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getOneUrl = apiData.ACTIONS?.clientsCRM?.readOne?.url?.replace('uniqueId', payload);

        if (getOneUrl) {
            actions.setClientCRMById(clientsInitialState.clientCRMById);
            return axios.get(getOneUrl).then((client) => {
                if (client) {
                    actions.setClientCRMById(client);
                }
                return client;
            });
        }
    }),

    searchClientsCRMIdsByContact: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getIdsUrl = apiData.ACTIONS?.clientsCRM?.getIdsByContacts?.url?.replace('search', payload);

        return axios.get(getIdsUrl)
            .then((ids) => {
                return ids;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),


    attachOneClientCRMToClient: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const allClients = helpers.getState().allClients;
        const { initClientUniqueId, targertClientUniqueId } = payload;

        const postAttachClientsUrl = apiData.ACTIONS?.clientsCRM?.attach?.url;
        if (postAttachClientsUrl) {
            return axios.post(postAttachClientsUrl, { initClientUniqueId: initClientUniqueId, targertClientUniqueId: targertClientUniqueId })
                .then((data) => {
                    let clientSimpleNewData = data.client.simpleData;
                    clientSimpleNewData.isSubscriber = allClients.some((client) => client.uniqueId === data.client.simpleData.uniqueId);
                    actions.updateOneClientCRMFromAttach({ 
                        newClientCRM: clientSimpleNewData, 
                        oldUniqueId: initClientUniqueId
                    });
                    actions.updateOneClientCRMById(data.client.fullData);
                    return data;
                })
        }
    }),


    mergeTwoClients: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const allClients = helpers.getState().allClients;
        const { initialClientUUID, targetClientUUID } = payload;

        const postMergeClientsUrl = apiData.ACTIONS?.clientsCRM?.merge?.url;
        if (postMergeClientsUrl) {
            return axios.post(postMergeClientsUrl, { initClientUniqueId: initialClientUUID, targertClientUniqueId: targetClientUUID })
                .then((data) => {
                    const clientSimpleNewData = data.client.simpleData;
                    clientSimpleNewData.isSubscriber = allClients.some((client) => client.uniqueId === initialClientUUID);
                    actions.updateOneClientCRM(clientSimpleNewData);
                    actions.updateClientsCRMProps(data.client.props)
                    actions.removeOneClientCRM({uniqueId:targetClientUUID});
                    return data;
                })
        }
    }),


    // createOneClient
    createClientCRM: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const data = payload;

        const createClientUrl = ACTIONS?.clients?.create?.url;

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(createClientUrl, data, options)
            .then((result) => {
                if (result?.clientCRMToAdd) {
                    actions.addClientCRM(result.clientCRMToAdd);
                }
                if (result?.clientToAdd) {
                    actions.addClient(result.clientToAdd);
                }
                if (result?.clientParentToUpdate) {
                    actions.setClientById(result.clientParentToUpdate)
                }
                if (result?.clientParentToUpdateSimple) {
                    actions.updateClientsProps(result.clientParentToUpdateSimple);
                }
                if (result?.tagsOperationsToUpdate) {
                    store.getActions().tagOperations.updateTagsOperationsProps(result.tagsOperationsToUpdate);
                }
                if (result?.clientsCRMPropsToUpdate) {
                    actions.updateClientsCRMProps(result.clientsCRMPropsToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    // createOneClient
    updateClientCRM: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { setIsMergeClientsPropsInAllUsersNecessary } = helpers.getStoreActions().users;
        const { setIsMergeClientsPropsInAllHeadsetsNecessary } = helpers.getStoreActions().headsets;

        const { data, uniqueId } = payload;

        const updateClientUrl = ACTIONS?.clients?.update?.url?.replace('uniqueId', uniqueId);
        data.clientCsrfToken = ACTIONS?.clients?.update?.csrfToken;

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        return axios.post(updateClientUrl, data, options)
            .then((result) => {
                actions.updateOneClientCRMById(result.clientCRMFullToUpdate);
                actions.updateOneClientCRM(result.clientCRMToUpdate);
                actions.updateClientsProps(result.clientsToUpdate);
                actions.updateClientsCRMProps(result.clientsCRMPropsToUpdate);
                actions.mergeOneClient({ clientCRM: result.clientCRMFullToUpdate });
                if (result?.tagsOperationsToUpdate) {
                    store.getActions().tagOperations.updateTagsOperationsProps(result.tagsOperationsToUpdate);
                }
                if (result?.clientCRMFullToUpdate?.defaultAddress) {
                actions.updateAddressByClientId(result.clientCRMFullToUpdate.defaultAddress);
                }

                setIsMergeClientsPropsInAllUsersNecessary(true);
                setIsMergeClientsPropsInAllHeadsetsNecessary(true);

                return result.clientCRMToUpdate;
            })
            .catch(error => Promise.reject(error));
    }),



    removeClientCRM:  action((state, payload) => {
        state.allClientsCRM = state.allClientsCRM.filter(client => client.uniqueId !== payload);
    }),

    deleteClientCRM: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { updateTagsOperationsProps } = helpers.getStoreActions().tagOperations;
        const csrfToken =  apiData.ACTIONS?.clientsCRM?.delete?.csrfToken;
        const deleteUrl = apiData.ACTIONS?.clientsCRM?.delete?.url?.replace('uniqueId', payload);
      
        return axios.post( deleteUrl, {csrfToken})
            .then(result => {
                actions.setClientCRMById(null);
                actions.removeClientCRM(payload);
                if (result?.tagsOperationsToUpdate) {
                    updateTagsOperationsProps(result.tagsOperationsToUpdate);
                }
                return result;
            })
        .catch(error => Promise.reject(error))
    }),


    setClientsCategories: action((state, payload) => {
        state.clientsCategories = payload;
    }),


    fetchClientsCategories: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const getClientCategoriesUrl = ACTIONS?.clients?.getParams?.clientsCategories?.url;

        return axios.get(getClientCategoriesUrl)
            .then((result) => {
                actions.setClientsCategories(result);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    // handlingFormationsByClientId
    setFormationsByClientId: action((state, payload) => {
        state.formationsByClientId = payload;
    }),
    setIsFetchingFormationsByClientId: action((state, payload) => {
        state.isFetchingFormationsByClientId = payload;
    }),

    fetchFormationsByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingFormationsByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getFormations?.url?.replace('uniqueId', payload);

        if (!isFetchingFormationsByClientId) {
            actions.setIsFetchingFormationsByClientId(true);

            return axios.get(getUrl)
                .then(data => actions.setFormationsByClientId(data))
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingFormationsByClientId(false))
        } else {
            return Promise.resolve();
        }
    }),


    setIsClientByIdDeletable: action((state, payload) => {
        state.isClientByIdDeletable = payload;
    }),

    setIsFetchingIsClientByIdDeletable: action((state, payload) => {
        state.isFetchingIsClientByIdDeletable  = payload;
    }),

    fetchIsClientByIdDeletable: thunk((actions, payload, helpers) => {
        const { isFetchingIsClientByIdDeletable } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getIsClientDeletable?.url?.replace('uniqueId', payload);

        if (!isFetchingIsClientByIdDeletable) {
            actions.setIsFetchingIsClientByIdDeletable(true);

            return axios.get(getUrl)
                .then(data => {
                    actions.setIsClientByIdDeletable(data.isDeletable);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingIsClientByIdDeletable(false))
        } else {
            return Promise.resolve();
        }
    }),

    setAllSessionsByIdForClient: action((state, payload) => {
        state.allSessionsByIdForClient = payload;
    }),

    setIsFetchingAllSessionsByIdForClient: action((state, payload) => {
        state.isFetchingAllSessionsByIdForClient = payload;
    }),

    fetchFormationSessionsByIdForClient: thunk((actions, payload, helpers) => {

        const { isFetchingAllSessionsByIdForClient } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;


        const getUrl = ACTIONS?.clients?.getProps?.getFormationSessions?.url?.replace('formationUniqueId', payload);

        if (!isFetchingAllSessionsByIdForClient) {
            actions.setIsFetchingAllSessionsByIdForClient(true);
            actions.setAllSessionsByIdForClient(clientsInitialState.allSessionsByIdForClient);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllSessionsByIdForClient(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAllSessionsByIdForClient(clientsInitialState.isFetchingAllSessionsByIdForClient);
                })
        } else {
            return Promise.resolve();
        }
    }),

    // handlingOneFormationSession
    setFormationSessionByIdForClient: action((state, payload) => {
        state.formationSessionByIdForClient = payload;
    }),
    setIsFetchingOneFormationSessionForClient: action((state, payload) => {
        state.isFetchingOneFormationSessionForClient = payload;
    }),

    fetchFormationSessionByIdForClient: thunk((actions, payload, helpers) => {
        const { isFetchingOneFormationSessionForClient } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getOneFormationSesssion?.url?.replace('uniqueId', payload);

        if (!isFetchingOneFormationSessionForClient) {
            actions.setIsFetchingOneFormationSessionForClient(true);
            actions.setFormationSessionByIdForClient(clientsInitialState.formationSessionByIdForClient);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneFormationSessionForClient(clientsInitialState.isFetchingOneFormationSessionForClient);
                    actions.setFormationSessionByIdForClient(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingOneFormationSessionForClient(clientsInitialState.isFetchingOneFormationSessionForClient);
                })
        } else {
            return Promise.resolve();
        }
    }),

    addSubscriptionByClientId: action((state, payload) => {
        state.subscriptionsByClientId.push(payload);
    }),

    setSubscriptionsByClientId: action((state, payload) => {
        state.subscriptionsByClientId = payload;
    }),

    setIsFetchingSubscriptionsByClientId: action((state, payload) => {
        state.isFetchingSubscriptionsByClientId = payload;
    }),

    updateSubscriptionByClientId: action((state, payload) => {
        const index = state.subscriptionsByClientId.findIndex((subscription) => subscription.uniqueId === payload.uniqueId);
        state.subscriptionsByClientId[index] = {
            ...payload,
        };
    }),

    removeSubscriptionByClientId: action((state, payload) => {
        state.subscriptionsByClientId = state.subscriptionsByClientId.filter(subscription => subscription.uniqueId !== payload);
    }),


    fetchSubscriptionsByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingSubscriptionsByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
  
        const getUrl = ACTIONS?.clients?.getProps?.getSubscriptions?.url?.replace('uniqueId', payload);

        if (!isFetchingSubscriptionsByClientId) {
            actions.setIsFetchingSubscriptionsByClientId(true);
            if(!payload){
                actions.setSubscriptionsByClientId(clientsInitialState.subscriptionsByClientId);
                actions.setIsFetchingSubscriptionsByClientId(clientsInitialState.isFetchingSubscriptionsByClientId);
                return Promise.resolve();
            }
            return axios.get(getUrl)
                .then(data => {
                    actions.setSubscriptionsByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingSubscriptionsByClientId(clientsInitialState.isFetchingSubscriptionsByClientId);
                })
        } else {
            return Promise.resolve();
        }
    }),

    setIsFetchingHeadsetsByClientId: action((state, payload) => {
        state.isFetchingHeadsetsByClientId = payload;
    }),

    addHeadsetsByClientId: action((state, payload) => {
        state.headsetsByClientId.push(payload);
    }),

    setHeadsetsByClientId: action((state, payload) => {
        state.headsetsByClientId = payload;
    }),

    updateHeadsetsByClientId: action((state, payload) => {
        const index = state.headsetsByClientId.findIndex((headset) => headset.uniqueId === payload.uniqueId);
        state.headsetsByClientId[index] = {
            ...payload,
        };
    }),

    removeHeadsetsByClientId: action((state, payload) => {
        state.headsetsByClientId = state.headsetsByClientId.filter(headset => headset.uniqueId !== payload);
    }),

    fetchHeadsetsByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingHeadsetsByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getHeadsets?.url?.replace('uniqueId', payload);

        if (!isFetchingHeadsetsByClientId) {
            actions.setIsFetchingHeadsetsByClientId(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setHeadsetsByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingHeadsetsByClientId(clientsInitialState.isFetchingHeadsetsByClientId);
                })
        } else {
            return Promise.resolve();
        }
    }),
    

    //#region Handle interactions for client
    addInteractionByClientId: action((state, payload) => {
        state.interactionsByClientId.push(payload);
    }),

    setInteractionsByClientId: action((state, payload) => {
        state.interactionsByClientId = payload;
    }),

    setIsFetchingInteractionsByClientId: action((state, payload) => {
        state.isFetchingInteractionsByClientId = payload;
    }),

    updateInteractionByClientId: action((state, payload) => {
        const index = state.interactionsByClientId.findIndex((interaction) => interaction.uniqueId === payload.uniqueId);
        state.interactionsByClientId[index] = {
            ...payload,
        };
    }),

    removeInteractionByClientId: action((state, payload) => {
        state.interactionsByClientId = state.interactionsByClientId.filter(interaction => interaction.uniqueId !== payload);
    }),

  //#region Handle addrresses for client
    addAddressByClientId: action((state, payload) => {
        state.addressesByClientId.push(payload);
    }),

    setAddressesByClientId: action((state, payload) => {
        state.addressesByClientId = payload;
    }),

    setIsFetchingAddressesByClientId: action((state, payload) => {
        state.isFetchingAddressesByClientId = payload;
    }),

    updateAddressByClientId: action((state, payload) => {
        const index = state.addressesByClientId.findIndex((address) => address.uniqueId === payload.uniqueId);
        state.addressesByClientId[index] = {
            ...payload,
        };
    }),

    removeAddressByClientId: action((state, payload) => {
        state.addressesByClientId = state.addressesByClientId.filter(address => address.uniqueId !== payload);
    }),

    fetchAddressesByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingAddressesByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clientsCRM?.getProps?.getAddresses?.url?.replace('uniqueId', payload);

        if (!isFetchingAddressesByClientId) {
            actions.setIsFetchingAddressesByClientId(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAddressesByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAddressesByClientId(clientsInitialState.isFetchingAddressesByClientId);
                })
        } else {
            return Promise.resolve();
        }
    }),



    setIsSendingAddressData: action((state, payload) => {
        state.isSendingAddressData = payload;
    }),

    createClientAddress: thunk((actions, payload, helpers) => {
        const { isSendingAddressData } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const data = payload?.address;
        const uniqueId = payload?.uniqueId;

        const createAddressUrl = ACTIONS?.clientsCRM?.addresses?.create?.url?.replace('uniqueId', uniqueId);
        data.csrfToken = ACTIONS?.clientsCRM?.addresses?.create?.csrfToken;

        if (!isSendingAddressData) {
            actions.setIsSendingAddressData(true);
            return axios.post(createAddressUrl, data)
            .then((result) => {
                if (result?.addressToAdd) {
                    actions.addAddressByClientId(result.addressToAdd);
                }
                if(result?.clientCRMToUpdate) {
                    actions.updateOneClientCRM(result.clientCRMToUpdate);
                }
                if(result?.clientCRMFullToUpdate) {
                    actions.updateOneClientCRMById(result.clientCRMFullToUpdate);
                }
                if (result?.clientsCRMPropsToUpdate) {
                    actions.updateClientsCRMProps(result.clientsCRMPropsToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error))
            .finally(() => {
                actions.setIsSendingAddressData(false)
            });
        } 
        else{
            return Promise.resolve();
        }
    }),

    updateAddress: thunk((actions, payload, helpers) => {
        const { isSendingAddressData } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const data = payload?.address;
        const uniqueId = payload?.uniqueId;

        const updateAddressUrl = ACTIONS?.clientsCRM?.addresses?.update?.url?.replace('uniqueId', uniqueId);
        data.csrfToken = ACTIONS?.clientsCRM?.addresses?.update?.csrfToken;

        if (!isSendingAddressData) {
            actions.setIsSendingAddressData(true);
            return axios.post(updateAddressUrl, data)
            .then((result) => {
                if (result?.addressToUpdate) {
                    actions.updateAddressByClientId(result.addressToUpdate);
                }
                if(result?.clientCRMToUpdate) {
                    actions.updateOneClientCRM(result.clientCRMToUpdate);
                }
                if(result?.clientCRMFullToUpdate) {
                    actions.updateOneClientCRMById(result.clientCRMFullToUpdate);
                }
                if (result?.clientsCRMPropsToUpdate) {
                    actions.updateClientsCRMProps(result.clientsCRMPropsToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error))
            .finally(() => {
                actions.setIsSendingAddressData(false)
            });
        } 
        else{
            return Promise.resolve();
        }
    }),

    removeAddress: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const csrfToken = ACTIONS?.clientsCRM?.addresses?.delete?.csrfToken;
        const deleteAddressUrl = ACTIONS?.clientsCRM?.addresses?.delete?.url?.replace('uniqueId', payload?.uniqueId);
        actions.setIsSendingAddressData(true);
        return axios.post(deleteAddressUrl, {csrfToken})
            .then((result) => {
                actions.removeAddressByClientId(payload?.uniqueId);
                if(result?.clientCRMToUpdate) {
                    actions.updateOneClientCRM(result.clientCRMToUpdate);
                    actions.updateClientsPropsForClientById(result.clientCRMToUpdate);
                }
                return result;
            })
            .catch(error => Promise.reject(error))
            .finally(() => {
                actions.setIsSendingAddressData(false)
            });
    }),

    //#region Handle contacts for clients  
    setContactsByClientId: action((state, payload) => {
        state.contactsByClientId = payload;
    }),

    fetchContactsByClientId: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clientsCRM?.getProps?.getContacts?.url?.replace('uniqueId', payload);

        return axios.get(getUrl)
            .then(data => {
                actions.setContactsByClientId(data);
                return data;
            })
            .catch(error => Promise.reject(error))
    }),

    //#region Handle accessible groups for client view  
    setAccessibleGroupsByClientId: action((state, payload) => {
        state.accessibleGroupsByClientId = payload;
    }),
    setIsFetchingAccessibleGroupsByClientId: action((state, payload) => {
        state.isFetchingAccessibleGroupsByClientId = payload;
    }),

    fetchAccessibleGroupsByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingAccessibleGroupsByClientId } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getAccessibleGroups?.url?.replace('uniqueId', payload);

        if (!isFetchingAccessibleGroupsByClientId) {
            actions.setIsFetchingAccessibleGroupsByClientId(true);
            actions.setAccessibleGroupsByClientId(clientsInitialState.accessibleGroupsByClientId);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAccessibleGroupsByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAccessibleGroupsByClientId(false);
                })
        } else {
            return Promise.resolve();
        }
      }),
    //#endregion

    //#region Handle accessible videos for client view  
    setAccessibleVideosByClientId: action((state, payload) => {
        state.accessibleVideosByClientId = payload;
    }),
    setIsFetchingAccessibleVideosByClientId: action((state, payload) => {
        state.isFetchingAccessibleVideosByClientId = payload;
    }),

    fetchAccessibleVideosByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingAccessibleVideosByClientId } = helpers.getState();
        const { apiData: {ACTIONS} } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getAccessibleVideos?.url?.replace('uniqueId', payload);
          
        if (!isFetchingAccessibleVideosByClientId) {
            actions.setIsFetchingAccessibleVideosByClientId(true);
            actions.setAccessibleVideosByClientId(clientsInitialState.accessibleVideosByClientId);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAccessibleVideosByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingAccessibleVideosByClientId(false))
        } else {
            return Promise.resolve();
        }
      }),
    //#endregion
    
    //#region Handle accessible quizzes for client view
    setAccessibleQuizzesByClientId: action((state, payload) => {
        state.accessibleQuizzesByClientId = payload;
    }),
    setIsFetchingAccessibleQuizzesByClientId: action((state, payload) => {
        state.isFetchingAccessibleQuizzesByClientId = payload;
    }),

    fetchAccessibleQuizzesByClientId: thunk((actions, payload, helpers) => {
        const { isFetchingAccessibleQuizzesByClientId } = helpers.getState();
        const { apiData: {ACTIONS} } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getAccessibleQuizzes?.url?.replace('uniqueId', payload);
        if (!isFetchingAccessibleQuizzesByClientId) {
            actions.setIsFetchingAccessibleQuizzesByClientId(true);
            actions.setAccessibleQuizzesByClientId(clientsInitialState.accessibleQuizzesByClientId);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAccessibleQuizzesByClientId(data);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingAccessibleQuizzesByClientId(
                        clientsInitialState.isFetchingAccessibleQuizzesByClientId
                    );
                })
        } else {
            return Promise.resolve();
        }
    }),
    //#endregion



    initClientData: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;

        return Promise.all([
            actions.fetchClientById(uniqueId)
                .catch(error => Promise.reject(error)),
        ]).then(([oneClient]) => oneClient.isError 
            && Promise.reject(oneClient.error));
    }),

    // EDITION
    setIsUpdatingOneClient: action((state, payload) => {
        state.isUpdatingOneClient = payload;
    }),

    updateClientProperty: thunk((actions, payload, helpers) => {
        const { key, value } = payload;
        const clientById = helpers.getState().clientById;
        clientById[key] = value;

        actions.setClientById(clientById);
    }),

    postEditPropsForm: thunk((actions, payload, helpers) => {
        const { uniqueId, formData, actionName, IdsList } = payload;

        const { apiData } = helpers.getStoreState().actionSlugs;

        const url = apiData.ACTIONS?.clients?.editProps[actionName].url;

        let postUrl;
        if (uniqueId)
            postUrl = url?.replace('uniqueId', uniqueId);
        else {
            postUrl = Object.keys(IdsList).reduce((accumulator, IdKey) => {
                return accumulator.replace(IdKey, IdsList[IdKey]);
            }, url)
        }
        const { isUpdatingOneClient } = helpers.getState();

        if (!isUpdatingOneClient) {
            actions.setIsUpdatingOneClient(true);
            return axios.post(postUrl, formData || {})
                .then((data) => {
                    return data;
                })
                .finally(() => {
                    actions.setIsUpdatingOneClient(clientsInitialState.isUpdatingOneClient);
                })
        }
    }),

    updateClientBg: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editBackgroundImage',
        }

        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.updateClientProperty(({ key: 'bkgImg', value: data.imgURL }));
                return data;
            })
            .catch(error => Promise.reject(error))
    }),

    deleteClientBg: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'deleteBackgroundImage',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.updateClientProperty(({ key: 'bkgImg', value: data.imgURL }));
                return data;
            })
            .catch(error => Promise.reject(error))

    }),

    updateParenthood: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editParenthood',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                actions.setIsUpdateNecessary(true);
                return data;
            })
            .catch(error => Promise.reject(error))
    }),

    createWebappMenu: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: "createWebappMenu"
        };

        return actions.postEditPropsForm(payloadWithAction)
            .then(data => {
                actions.setClientById(data);
                return data;
            })
    }),

    updateWebappMenu: thunk((actions, payload, helpers) => {
        const { formData, uniqueId, webappUniqueId } = payload;

        const payloadWithAction = {
            formData,
            actionName: 'editWebappMenu',
            IdsList: {
                uniqueId,
                webappMenuUniqueId: webappUniqueId
            }
        }

        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                return data;
            });
    }),

    deleteWebappMenu: thunk((actions, payload, helpers) => {
        const { uniqueId, webappMenuUniqueId, formData } = payload;

        const payloadWithAction = {
            formData,
            actionName: 'deleteWebappMenu',
            IdsList: {
                uniqueId,
                webappMenuUniqueId
            }
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                return data;
            });
    }),

    deleteWebappMagicboxLink: thunk((actions, payload, helpers) => {
        const { uniqueId, linkUniqueId, formData } = payload;

        const payloadWithAction = {
            formData,
            actionName: 'deleteMagicboxLink',
            IdsList: {
                uniqueId,
                linkUniqueId
            }
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                return data;
            });
    }),

    updateWebappMagicboxLink: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editMagicboxLink'
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                return data;
            })
    }),

    updateWebappSkin: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editWebappSkin',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                actions.setIsUpdateNecessary(true);
                return data;
            })
            .catch(error => Promise.reject(error))
    }),
    
    updateWhitelistGroup: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editWhitelistGroup',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.updateWhiteListClient(data);
            })
            .catch(error => Promise.reject(error))
    }),

    updateWhitelistVideo: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editWhitelistVideo',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.updateWhiteListClient(data);
            })
            .catch(error => Promise.reject(error))
    }),

    updateWhitelistQuiz: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editWhitelistQuiz',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.updateWhiteListClient(data);
            })
            .catch(error => Promise.reject(error))
    }),

    updateWhitelistStory: thunk((actions, payload, helpers) => {
        const payloadWithAction = {
            ...payload,
            actionName: 'editWhitelistStory',
        }
        return actions.postEditPropsForm(payloadWithAction)
            .then((data) => {
                actions.setClientById(data);
                return data;
            })
            .catch(error => Promise.reject(error))
    }),

    setContactsM360: action((state, payload) => {
        state.contactsM360 = payload;
    }),

    fetchContactsM360: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const { isUpdateNecessaryForContactsM360 } = helpers.getState();

        if(isUpdateNecessaryForContactsM360) {
            const getContactsM360URL = ACTIONS?.clientsCRM?.getContactsM360?.url;

            return axios.get(getContactsM360URL)
                .then(data => {
                    actions.setContactsM360(data);
                    actions.setIsUpdateNecessaryForContactsM360(false);
                    return data;
                })
                .catch(error => Promise.reject(error))
        }
    }),


    getClientAndChildrenSubscriptions: thunk((actions, payload, helpers) => {
        const { isFetchingMultipleSubscriptions } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.clients?.getProps?.getClientAndChildrenSubscriptions?.url?.replace('uniqueId', payload);

        if (!isFetchingMultipleSubscriptions) {
            actions.setIsFetchingMultipleSubscriptions(true);
            return axios.get(getUrl)
                .then(data => {
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingMultipleSubscriptions(false);
                })
        } else {
            return Promise.resolve();
        }
    })
}

export default clients;