const iconClassNameRef ={
	'myAccount' : 'profile',
	'clients' : 'office',
	'users': 'user-M360',
	'myWebappAccess' : 'mobile',
	'headsets': 'VR-M360',
	'webappAccesses': 'mobile',
	'videos' : 'film',
	'video360' : 'VR-M360',
	'groups' : 'square-M360',
	'catalogue' : 'VR2-M360',
	'stories': 'newspaper',
	'sequences' : 'compass2',
	'quizzes' : 'bubble2',
	'pedagogicModules' : 'books',
	'tags' : 'list-M360',
	'tagOperations' : 'price-tag',
	'clientStatus' : 'drawer',
	'Pédagogie' : 'books',
	'Module pédagogique' : 'books',
	'formations' : 'library',
	'formationSessions': 'calendar',
	'playlists' : 'list-numbered',
	'image' : 'file-picture',
	'default' : 'user-x-M360',
	'contacts' : 'mail-M360',
	'faq' : 'question',
	'loader' : 'save-M360',
	'stats' : 'stats-bars',
	'audience': 'stats-bars',
	'romes' : 'briefcase',
	'romesHome' : 'briefcase',
	'clientsCRM' : 'profile',
	'orders' : 'box-remove',
	'interactions' : 'interactions',
	'welcomeMessage' : 'banner-communication',
	'lab' : 'lab'
  };

  export default iconClassNameRef;