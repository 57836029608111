import React, {useState, useEffect} from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Alert, Form, Row, Col } from "react-bootstrap";
import axios from '../../../../../services/axios';
import Loader from "../../../../widgets/Loader";
import HelperMetiers360 from "../../../../../services/HelpersMetiers360";
import SubscriptionTableComponent from "../../../../subscription/SubscriptionTableComponent";
import withURLParamsTableComponent from "../../../../../HOC/withURLParamsTableComponent";

const SubscriptionTableComponentWithParams = withURLParamsTableComponent(SubscriptionTableComponent);

const SelectSubscriptionsComponent = ({ client, subscriptionUniqueIdsChecked, setSubscriptionUniqueIdsChecked, 
    isNoSubscriptionSelected, cannotSend, multiple, multipleSelection = false }) => {

    const {fetchSubscriptionsByClientId} = useStoreActions(actions => actions.clients);
    const { setSubscriptionById} = useStoreActions(actions => actions.subscriptions);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState(["fetching"]);
    const [showAllSubscriptions, setShowAllSubscriptions] = useState(true);
    const [expiredSubSelected, setExpiredSubSelected] = useState(false);
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isFetchingSubscriptions, setIsFetchingSubscriptions] = useState(multiple ? true : false);

    useEffect(() => {
        if(client?.uniqueId) {
            fetchSubscriptions();
        } else {
            subscriptions && setSubscriptionById(null);
        }
    }, [client]);

    useEffect(() => { 
        const dateNow = new Date();
        const activeSubs = subscriptions.filter((subscription) => new Date(subscription.subscriptionEndDate) >= dateNow);
        setFilteredSubscriptions(activeSubs);
    },[subscriptions]);

    useEffect(() => {
        const dateNow = new Date();
        const selectedSubObjectEndDates = subscriptions
            ?.filter(sub => subscriptionUniqueIdsChecked?.includes(sub.uniqueId))
            ?.map(sub => sub.subscriptionEndDate);

        if (selectedSubObjectEndDates?.some(subEndDate => new Date(subEndDate) < dateNow)) {
            setShowAllSubscriptions(true);
            setExpiredSubSelected(true);
        } else {
            setExpiredSubSelected(false);
        }
    },[subscriptionUniqueIdsChecked, subscriptions]);

    const fetchSubscriptions = ()=> {
        setIsFetchingSubscriptions(true);
        let action = fetchSubscriptionsByClientId(client?.uniqueId);
         
        if(multiple){
            const getUrl = ACTIONS['clients']['getProps']['getSubscriptions'].url.replace('uniqueId', client?.uniqueId);
            action = axios.get(getUrl);
        }

        action
            .then(response => setSubscriptions(response))
            .finally(()=> setIsFetchingSubscriptions(false));
    }

    const onSelectSubscription = subscription => {
        if(!multipleSelection) {
            setSubscriptionUniqueIdsChecked([subscription.uniqueId]);
        }
    }

    const subscriptionTable = (showAllSubscriptions ? subscriptions : filteredSubscriptions)?.length < 1 
        ? <Alert variant="danger" className="mt-2">
            {showAllSubscriptions ? "Ce client n'a pas d'abonnements" : "Ce client n'a pas d'abonnements actifs"}
        </Alert>
        : <SubscriptionTableComponentWithParams
            onSelect={onSelectSubscription}
            subscriptions={showAllSubscriptions ? subscriptions : filteredSubscriptions}
            updatable={false}
            multipleSelection={multipleSelection}
            subscriptionUniqueIdsChecked={subscriptionUniqueIdsChecked}
            setSubscriptionUniqueIdsChecked={setSubscriptionUniqueIdsChecked}
        />;

    return <Row>
        <Col>
            <div className="d-flex align-items-center">
                <h3 className="me-4">Abonnement</h3>
                {(subscriptions?.length > filteredSubscriptions?.length) && !isFetchingSubscriptions
                    && <Form.Check
                        type="checkbox"
                        onChange={()=> setShowAllSubscriptions((prev)=>!prev)}
                        defaultChecked={showAllSubscriptions}
                        label = "Afficher aussi les abonnements expirés"
                        id={"sentOrders"+HelperMetiers360.generateUniqueId()}
                    />}
            </div>
            {expiredSubSelected 
                && <Alert className="ms-2 mb-0" variant='warning'><i className="fas fa-exclamation-triangle mr-1"/>
                    Un abonnement expiré est sélectionné
                </Alert>}
            {(cannotSend && isNoSubscriptionSelected) && <Alert variant="danger">Sélectionnez {multipleSelection && "au moins"} un abonnement</Alert>}  
            {(isFetchingSubscriptions || (filteredSubscriptions?.length && filteredSubscriptions[0] === "fetching") ) ? <Loader /> : subscriptionTable}
        </Col>
    </Row>;
}

export default SelectSubscriptionsComponent;