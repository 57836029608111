import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useStoreState,useStoreActions } from 'easy-peasy';
import Loader from "../../../components/widgets/Loader.jsx";
import withURLParamsTableComponent from "../../../HOC/withURLParamsTableComponent.jsx";
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState.jsx";
import PreparatoryOrderTableComponent from "../../../components/orders/preparatoryOrders/PreparatoryOrderTableComponent.jsx";
import OrdersFilters from "../../../components/orders/OrdersFilters/OrdersFilters.jsx";

const PreparatoryOrderTableComponentWithParams = withURLParamsTableComponent(PreparatoryOrderTableComponent);


const PreparatoryOrdersList = () => {

    const {allOrders, isFetchingAllOrders} = useCustomGetStoreState('orders');
    const {allClients} = useCustomGetStoreState('clients');
    const { allClientsCRM } = useStoreState(state => state.clients);
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);
    const { allOrderStatus } = useStoreState(state => state.orders);
    const { fetchOrderStatus } = useStoreActions(actions => actions.orders);

    const [enrichedOrders, setEnrichedOrders] = useState(allOrders);
    const [filteredOrders, setFilteredOrders] = useState(allOrders);

    useEffect(() => {
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
        allOrderStatus.length == 0 && fetchOrderStatus();
    }, []);

    useEffect(() => {
        if (allClients?.length >0) {
            setEnrichedOrders(allOrders.map(order => {
                    const client = allClients.find(client => client.uniqueId === order.clientUniqueId);
                    const clientCRM = allClientsCRM.find(client => client.uniqueId === order.clientUniqueId);
                    return {...order, 
                        clientName:client?.clientName, 
                        tagOperations:clientCRM?.tagOperations,
                        contactM360Name: client?.contactsM360
                            ?.filter(contactM360 => contactM360.relationValue === "sav")
                            ?.map(contactM360 => contactM360.name)
                            ?.join(", ")
                    }}));
            // ! maybe this should be done in a thunkOn of orders to have it in allOrders
        }
    },[allClients, allOrders])

    return <Container fluid>
        <Row className="mb-2 mt-4">
            <OrdersFilters allOrders={enrichedOrders} setFilteredOrders={setFilteredOrders}
                excludedStatus='expédiés' allStatus={allOrderStatus} />
        </Row>
        <Row>
            {isFetchingAllOrders && <Loader />}
            {allOrders.length > 0 && <PreparatoryOrderTableComponentWithParams orders={filteredOrders} />}
        </Row>
    </Container>
}

export default PreparatoryOrdersList;