import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Col, Row } from "react-bootstrap";

import UploadPedagogicModuleComponent from "../../components/pedagogicModules/uploadForm/UploadPedagogicModuleComponent.jsx";

import Loader from "../../components/widgets/Loader.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";

const UpdatePedagogicModulePage = (props) => {
	const { uniqueId } = useParams();

	const [isLoading, setIsLoading] = useState(false);

	const { pedagogicModuleById } = useStoreState(state => state.pedagogicModules);

	const { fetchPedagogicModuleById, postPedagogicModule, updatePedagogicModule } = useStoreActions((actions) => actions.pedagogicModules);

    const pedagogicModuleSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("pedagogicModules"));

	useEffect(() => {
		setIsLoading(true);
		fetchPedagogicModuleById(uniqueId)
            .finally(() => setIsLoading(false));
	}, []);

    const { backButtonComponent } = useBackButton()

	return (
		<div className="container p-2">
            {
                (isLoading) ? 
                <Loader/>
                : <> 
                    <Row className="mb-3">
                        <Col className="d-flex justify-content-between align-items-center">
                            <h2>
                                Mise à jour du module : {pedagogicModuleById && pedagogicModuleById.name ? pedagogicModuleById.name : "Mise à jour d'un module pédagogique"}
                            </h2>
                            {backButtonComponent}
                        </Col>
			        </Row>
                    <UploadPedagogicModuleComponent
                    pedagogicModuleToUpdate={pedagogicModuleById} 
                    postPedagogicModule={postPedagogicModule} 
                    updatePedagogicModule={updatePedagogicModule} 
                    action="update"
                    {...pedagogicModuleSlugs}
                    />
                </>
            }
			
		</div>
	
	);
};

export default UpdatePedagogicModulePage;