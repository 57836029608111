import {useEffect, useRef, useState} from 'react';
/**
 * 
 * custom hook that handle logic if we want to use infinite loading
 * firstFetch To reset the data to be displayed in case there is a modification in the original list (filter or reorder of said list)
 * fetchNext: function will get the next set of data to be displayed and we need to pass to the infiniteLoading component
 * hasMore: state variable of type bool that indicate if there's no further items to be displayed
 * 
 * 
 * @param {array} originalList state array of the original list we want to display by chunk via infinite loading
 * @param {array} listToDisplay state array of a list that will be populated as we scroll down
 * @param {useState setter} setListToDisplay state setter related to listToDisplay
 * @param {number} steps number of element to populate in array each time
 * 
 * @returns {hasMore, fetchNext}
 */

const useInfiniteLoadingLogic = (originalList, listToDisplay, setListToDisplay, steps) => {        
    const startIndex = useRef(0);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
            startIndex.current = 0;
            firstFetch();
    }, [originalList]);

    useEffect(() => {
        setHasMore(originalList.length > startIndex.current)
    }, [listToDisplay, originalList])

    const firstFetch = (step = steps) => {
        const endIndex = step;

        setListToDisplay((prev) => {
            const next = originalList.slice(0, endIndex);
            return [...next];
        })

        startIndex.current += step;
    }

    const fetchNext = (step = steps) => {
        
        const endIndex = startIndex.current + step;
        startIndex.current += step;
        
        setListToDisplay([...originalList.slice(0, endIndex)]);
    }

    return {  hasMore, fetchNext }
}

export default useInfiniteLoadingLogic;