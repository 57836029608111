import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { useParams } from "react-router-dom";

import { Row, Col, Container, Card } from "react-bootstrap";

import Loader from '../../components/widgets/Loader.jsx';
import StoryDetailsComponent from '../../components/stories/storyDetails/StoryDetailsComponent.jsx';
import useBackButton from '../../hooks/useBackButton.jsx';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';

import "./StoryDetailPage.scss";

const StoryDetailPage = (props) => {

    const { uniqueId } = useParams();
    
    // Story state variables + actions
    const { storyById } = useStoreState(state => state.stories);
    const { fetchStoryById, deleteStory } = useStoreActions(actions => actions.stories);

    //Sequences state variables + actions
    const { allSequences, isFetchingSequences } = useCustomGetStoreState("sequences");
    const {allVideos, isFetchingAllVideos} = useCustomGetStoreState("videos");

    const [isLoading, setIsLoading] = useState(false);
    const [storySequences, setStorySequences] = useState([]);

    const [storyVideos, setStoryVideos] = useState([]);

    useEffect(() => {

        if (!isLoading) {
            setIsLoading(true);
            fetchStoryById(uniqueId)
                .finally(() => setIsLoading(false));
        }

    }, []);

    useEffect(() => {
        if (storyById) {
            setStorySequences(allSequences
                .filter(sequence => (
                    sequence.stories.includes(storyById.uniqueId)
                )))
        }
    }, [allSequences, storyById])

    useEffect(() => {
        if (storyById) {
            setStoryVideos(allVideos
                .filter(video => (
                    video.uniqueId === storyById.fromVideoUniqueId
                )))
        }
    }, [allVideos, storyById])

    const { backButtonComponent } = useBackButton()

    return (
        <Container fluid>
            <Row>
                <Col className="thumbnail_header_item">
                {backButtonComponent}

                    {
                        (storyById && storyById.links && storyById.links.thumbnail) &&
                            <Card.Img src={storyById.links.thumbnail[512]} /> 
                    }
                </Col>
            </Row>
            <Row className="d-flex flex-column">
                {
                    (!storyById || isLoading) ?
                        <Loader />
                        : <>
                            <StoryDetailsComponent
                                story={storyById}
                                storySequences={storySequences}
                                isFetchingSequences={isFetchingSequences}
                                storyVideos={storyVideos}
                                isFetchingAllVideos={isFetchingAllVideos}
                                deleteStory={deleteStory}
                            />
                        </>
                }
            </Row>
        </Container>

    )
}

export default StoryDetailPage;