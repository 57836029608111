import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Row, Col } from "react-bootstrap";
import useBackButton from "../../../hooks/useBackButton";
import Loader from "../../../components/widgets/Loader";
import UpdateOrderComponent from "../../../components/orders/preparatoryOrders/Update/UpdateOrderComponent";


const UpdatePreparatoryOrder = () => {
    const returnOrderSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('returnOrders'));
    const { returnOrderById } = useStoreState(state => state.orders);
    const { fetchReturnOrderById, isFetchingOneReturnOrder } = useStoreActions(actions => actions.orders);

    const { uniqueId } = useParams();

    useEffect(() => {
        fetchReturnOrderById(uniqueId);
    }, [uniqueId]);

    const { backButtonComponent } = useBackButton()

    return (
        <div className="container p-2">
            <Row className="mb-3">
                <Col className="d-flex justify-content-between align-items-center">
                    <h2>Edition d'un bon de retour</h2>
                    {backButtonComponent}
                </Col>
            </Row>
            {
                (!returnOrderById || isFetchingOneReturnOrder)
                    ? <Loader />
                    : <UpdateOrderComponent orderToUpdate={returnOrderById} action="update" orderType="returnOrder" />
            }
        </div>
    );

}

export default UpdatePreparatoryOrder;