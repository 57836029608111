import React from 'react';
import { useParams } from 'react-router-dom';

import ErrorsComponent from '../../components/errors/ErrorsComponent.jsx';


const ErrorPage = () => {
    let {errorCode} = useParams();

    const errors = {
        400:{
            codeDescription:"Une erreur a eu lieu",
            message:"La requête que vous avez envoyée est incorrecte. Si vous pensez que c'est une erreur,"
        },
        403:{
            codeDescription:"Vous n'avez pas les droits pour accéder à cette ressource",
            message:"Vous ne pouvez pas accéder à cette ressource. Si vous pensez que c'est une erreur,"
        },
        404:{
            codeDescription:"La page que vous recherchez n'existe pas",
            message:"La page que vous recherchez n'existe pas. Si vous pensez que c'est une erreur,"
        },

    };
    if (!errorCode) {
        errorCode = 404;
    }
    else if (! (errorCode in errors)) {
        errorCode = 400;
    }
    return (
        <ErrorsComponent 
        codeDescription={errors[errorCode].codeDescription}
        message={errors[errorCode].message} />
    )
}

export default ErrorPage;