import React from 'react';
import { Button} from 'react-bootstrap';



const DownloadFileComponent = (props) => {

    const {path, message, icon = false, as = null, bsPrefix = null} = props;
    
    
    const onDownloadFile = () => {
        window.open(path);
        // const a = document.createElement('a')
        // a.href = path;
        // a.download = path.split('/').pop();
        // a.target="_blank";
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
        // This code seems unecessary complicated to my point of view
    }
   
    return (     <Button variant="primary" as={as} bsPrefix={bsPrefix} onClick={onDownloadFile}> 
                 
                 { icon ?
                 <i className="fas fa-download"></i> : null
                 }
                 
                 &nbsp;{message}
                 </Button> 
    );
}

export default DownloadFileComponent;