import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap';

import ButtonLoaderComponent from '../../../widgets/ButtonLoaderComponent';

const AnswersFormComponent = ({answer, quizUniqueId, questionUniqueId, answerAction}) => {
	const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const [isTrue, setIsTrue] = useState(answer ? answer.isTrue : false);

	const [formErrors, setFormErrors] = useState({});
	const [isSuccess, setIsSuccess] = useState(false);
	const [isSending, setIsSending] = useState(false);
	
    const {csrfToken, formName} = ACTIONS['quizzes']['editProps']['answers'][answer ? 'update': 'create'];
	
	useEffect(() => {
		resetFormValue();
	}, [answer])

	const resetFormValue = () => {
        let form = document.getElementById('answer-form');
		form.reset();
		if (answer) {
			setIsTrue(answer.isTrue);
		}
		else {
			setIsTrue(false);
		}
	}

	const checkData = (e) => {
		e.preventDefault();
		setIsSending(true)
		let formData = new FormData(document.getElementById('answer-form'));
		const errorList = {};

		if (!formData.get(`${formName}[caption]`)) {
			errorList.caption = 'Veuillez entrer une reponse';
		}

		if (Object.keys(errorList).length) {
			setIsSending(false);
			setIsSuccess(false);
			errorList.global = 'Il y a eu un probleme lors de l\'enregistrement de la reponse';
			setFormErrors(errorList)
		} else {
			setFormErrors({});
			submitForm();

		}
	}

	const submitForm = () => {
		let formData = new FormData(document.getElementById('answer-form'));
        let options = {
            formData,
            quizUniqueId,
            questionUniqueId,
        }

        if (answer){
            options.answerUniqueId = answer.uniqueId
        }

		// api.post(persistUrl, formData)
        answerAction(options)
        .then((response) => {
			setIsSending(false);
			setIsSuccess(true);
			// updateAnswers();

		}).catch((errors) => {
			handleBadRequest(errors);
			setIsSuccess(false);
			setIsSending(false);
		})
	}

	const handleBadRequest = (error) => {
		const errorList = {};
		if (error && error.errors) {
			for (const key of Object.keys(error.errors)) {
				errorList[key] = error.errors[key]
			}
		}
		errorList.global = 'Il y a eu un probleme lors de l\'enregistrement de la reponse';
		setFormErrors(errorList);
	}


	return(
		<div className='answer-form'>

			<Form method="post" encType="multipart/form-data" id="answer-form">
					{ formErrors.global ? <Alert variant="danger"> {formErrors.global} </Alert> : null }
					{ isSuccess ? <Alert variant="success"> Chargement terminé ! </Alert> :null }
				
				<Row>
					<Form.Group as={Col} sm={12} >
						<Form.Label>Texte de la réponse</Form.Label>
						<Form.Control 
							name={`${formName}[caption]`} 
							as="textarea" 
							isInvalid={formErrors.caption !== undefined}
							rows={5}
					        defaultValue={answer ?  answer.caption : ""}
							/>
						<Form.Control
							type="hidden"
							isInvalid={formErrors.caption !== undefined}
						/>
						<Form.Control.Feedback type='invalid'>{formErrors.caption}</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<Form.Check type='checkbox' id='answer_is_true' custom>
					<Form.Check.Input
						name={`${formName}[isTrue]`}
						type='checkbox'
						checked={isTrue}
						onChange={(e) => {setIsTrue(e.target.checked)}}
					/>
					<Form.Check.Label> Cochez s'il s'agit d'une bonne réponse </Form.Check.Label>
				</Form.Check>

				<Form.Group >
					<Form.Control
						name={`${formName}[_token]`}
						value={csrfToken}
						type="hidden"
					/>
				</Form.Group>

				<Row>
					<ButtonLoaderComponent 
						className='d-flex'
						variant='success'
						onClick={checkData} 
						isSending={isSending}
						disabled={isSending}
						msg={<><i className="fas fa-check text-white me-2"/> Valider</>}
					/>
				</Row>

			</Form>

		</div>
	)
    

}

export default AnswersFormComponent;