import React, { useState, useRef, useEffect } from 'react';
import { useStoreState, useStoreActions} from 'easy-peasy';
import {Row, Col, Alert} from 'react-bootstrap';
import FormationsByClientTableComponent from '../../../formations/FormationsByClientTableComponent.jsx';
import FormationSessionsTableComponent from '../../../formations/upload/formationSession/FormationSessionsTableComponent';
import Loader from '../../../widgets/Loader';

import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import withURLParamsTableComponent from '../../../../HOC/withURLParamsTableComponent.jsx';

const FormationsByClientTableComponentWithParams = withURLParamsTableComponent(FormationsByClientTableComponent);
const FormationSessionsTableComponentWithParams = withURLParamsTableComponent(FormationSessionsTableComponent);

/**
 * This function renders the formation tab of the client page
 * @returns A component that renders the ClientFormationTabComponent
 */
const ClientFormationTabComponent = (props) => {
    const {client,fromAccountPage = false} = props;
    const [selectedFormation, setSelectedFormation] = useState(null);
    const [formationSessionDescription, setFormationSessionDescription] = useState(null);
    const headsetRef = useRef(null);

    const formationName = selectedFormation ? ` - ${selectedFormation.name}` : '';

    const formationSessionCount = selectedFormation ? selectedFormation.sessionsCount : '';

    const formationSessionTitle = formationSessionCount > 1 ? `Sessions${formationName}` : `Session${formationName}`;
   
    const CardClassName = fromAccountPage ? "tab-info-card tab-info-card--noMinHeight tab-info-card--noBackground-color" :"tab-info-card tab-info-card--noMinHeight";
    
    const {formationsByClientId, isFetchingFormationsByClientId} = useStoreState(state => state.clients);
    const {fetchFormationsByClientId} = useStoreActions(actions => actions.clients);
    const {allSessionsByFormationId, isFetchingAllSessionsByFormationId} = useStoreState(state => state.formationSessions);
    const {fetchFormationSessionsByFormationId} = useStoreActions(actions => actions.formationSessions);

    useEffect(() => {
        if(selectedFormation){
            const payload = {
                clientUniqueId:client.uniqueId,
                formationUniqueId:selectedFormation?.uniqueId
            }
            fetchFormationSessionsByFormationId(payload);
            setFormationSessionDescription(selectedFormation?.sessionsCount > 0 ? "Sessions où " + (AuthorizationChecker.isAdmin() ? "des utilisateur⋅rices du client":"vos utilisateur⋅rices") + " sont inscrit⋅es. Cliquez sur l'une d'elles pour voir le detail" : "Aucune session n'est associée à ce webinaire")
        }
    }, [selectedFormation])




    useEffect(() => {
        fetchFormationsByClientId(client?.uniqueId);
    }, [])




    return (
        <>
            <Row>
                <Col className="tab-info-container">
                    <Col className={CardClassName}>
                    { isFetchingFormationsByClientId ? <Loader /> :
                    formationsByClientId?.length < 1  ? <Alert variant="danger">Aucun webinaire</Alert> :
                    <FormationsByClientTableComponentWithParams formationsToDisplay={formationsByClientId} onSelect={setSelectedFormation} scrollTargetRef={headsetRef} />
                    }
                    </Col>
                </Col>
            </Row>

            { formationsByClientId?.length > 0 &&
            <Row>
            <Col className="tab-info-container" ref={headsetRef}>
                    <Col className={CardClassName}>
                     
                        <div className='tab-info-card-header'>
                            <div className="header-heading w-100">
                                    <h4 className="header-title">{formationSessionTitle}</h4>
                                    {selectedFormation ?
                                    <Alert variant="info" className="mt-2">{formationSessionDescription}</Alert>  
                                    : <Alert variant="info" className="mt-2">Selectionnez un webinaire pour voir les sessions associées</Alert>}
                                   
                            </div>
                        </div>
                        
                        {isFetchingAllSessionsByFormationId ? <Loader /> :
                            !selectedFormation  ? null :
                                !allSessionsByFormationId ?
                                    <Alert variant="danger">Aucune session ne correspond à ce webinaire</Alert> :
                                    <FormationSessionsTableComponentWithParams formationSessionsList={allSessionsByFormationId} formation={selectedFormation} client={client}/> 
                        }
                    </Col>
                </Col>
            </Row>
            }
        </>
    )
}

export default ClientFormationTabComponent;