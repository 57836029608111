import React from 'react';
import { Button } from "react-bootstrap";
import { useStoreActions } from 'easy-peasy';

import PropTypes from 'prop-types';

import './WelcomeBannerComponent.scss';

const WelcomeBannerComponent = ({ message, canClose  = true, setBanner = null, preview = false }) => {

    const { updateLastMessageClosed } = useStoreActions(actions => actions.users);


    const closeForever = () => {
        setBanner && setBanner(null);
        updateLastMessageClosed({messageId : message.uniqueId})
    }

    if (message) {
        return (
            <div className={`${preview? "welcomePreview" : "welcomeBanner"} wb${message.category}`}>
                <span dangerouslySetInnerHTML={{ __html: message.content }}></span>
                {message.button &&
                    <Button size='sm' className='ml-4 extraButton' variant='success' target='_blank' 
                    href={`${!message.button?.link?.startsWith('http') ? "http://" : ""}${message.button.link}`}>{message.button.label}
                    </Button>
                }
                {canClose &&
                <span className='closeX' role="button" onClick={closeForever}>X</span>}
            </div>
        )
    }
}

WelcomeBannerComponent.propTypes = {
	message: PropTypes.object.isRequired,
    canClose: PropTypes.bool,
    setBanner: PropTypes.any,
    preview: PropTypes.bool,
};


export default WelcomeBannerComponent