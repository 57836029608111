import React, {useRef,useState, useEffect} from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useNavigate } from 'react-router';
import { Row, Col, Alert } from "react-bootstrap";
import SelectSubscriptionsComponent from "./SelectSubscriptionsComponent/SelectSubscriptionsComponent";
import SelectInvoiceComponent from "./SelectInvoiceComponent/SelectInvoiceComponent";
import DeliveryInformationComponent from "./DeliveryInformationComponent/DeliveryInformationComponent";
import SelectProductComponent from "./SelectProductsComponent/SelectProductComponent";
import SelectProviderComponent from "./SelectProviderComponent./SelectProviderComponent";
import ButtonLoaderComponent from "../../../widgets/ButtonLoaderComponent";

const OrderFormComponent = ({
    client,
    order:initDataOrder,
    multiple,
    commonInfos,
    validateAll,
    handleValidation,
    handleErrors,
    handleChecked,
    triggerUpdateCommonInfos
}) => {

    initDataOrder && Object.defineProperties(initDataOrder,{
        uniqueId: {enumerable: false},
        orderlines: {enumerable: false},
        status: {enumerable:false},
        statusValue: {enumerable:false},
        clientUniqueId: {enumerable:false},
        subscriptionUniqueId: {enumerable:false},
        invoiceNumber: {enumerable:false},
        comment: {enumerable:false},
    });

    const {createOrder, updateOrder} = useStoreActions(actions => actions.orders);
    const { allProducts } = useStoreState(state => state.orders);
    const { fetchProducts } = useStoreActions(actions => actions.orders);

    const [isSending, setIsSending] = useState(false);
    const [comment, setComment] = useState(initDataOrder?.comment || '');

    const [subscriptionUniqueIdsChecked, setSubscriptionUniqueIdsChecked] = useState(initDataOrder?.subscriptionUniqueId ? [initDataOrder.subscriptionUniqueId] : []);
    const selectedInvoiceRef = useRef(initDataOrder ? initDataOrder?.invoiceNumber:null);
    const selectedIsRentingRef = useRef(initDataOrder ? initDataOrder?.isRenting:null);
    const deliveryInformationsRef = useRef(initDataOrder ? {...initDataOrder}:{});
    const initProducts = initDataOrder?.orderlines ?
        initDataOrder.orderlines.map(orderline => {return {uniqueId:orderline.productUniqueId, quantity:orderline.quantity}})
        :null;
    const productsRef = useRef(initProducts);
    const providerRef = useRef(initDataOrder?.providerUniqueId);

	const ordersSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('orders'));
    const navigate = useNavigate();
    const redirect = (url) => {
        navigate(url, {replace:true, state: {fromEdit:true}});
    }

    const [noProduct, setNoProduct] = useState(!initDataOrder);
    const [badQuantity, setBadQuantity] = useState(false);
    const [isQuantityValid, setIsQuantityValid] = useState([]);
    const [noRentingValue, setNoRentingValue] = useState(false);
    const [cannotSend, setCannotSend] = useState(false);
    const [isNoSubscriptionSelected, setIsNoSubscriptionSelected] = useState(false);
 
    useEffect(() => {
        allProducts.length == 0 && fetchProducts();
    },[]);

    const onValidate = () => {
        const hasNoSubscription = subscriptionUniqueIdsChecked?.length === 0;
        const hasNoProducts = !productsRef.current || productsRef.current.length === 0 || productsRef.current.every((prod) => !prod.uniqueId);
        const hasInvalidQuantity = !hasNoProducts && !productsRef.current.filter((prod)=>prod.uniqueId).every((prod)=> prod.quantity && parseInt(prod.quantity) > 0);
        const existHeadsetProduct = productsRef.current.some(product => 
            allProducts.some(prod => prod.uniqueId === product.uniqueId && prod.reference !== "" && prod.reference !== null));
        const isNoRentingValueForHeadsetCommand = existHeadsetProduct && selectedIsRentingRef.current === null;

        if (hasNoSubscription || hasNoProducts || hasInvalidQuantity || isNoRentingValueForHeadsetCommand) {
            setCannotSend(true);
            setIsNoSubscriptionSelected(hasNoSubscription); 
            setNoProduct(hasNoProducts);
            setNoRentingValue(isNoRentingValueForHeadsetCommand);
            if (hasInvalidQuantity) {
                setBadQuantity(true);
                const tempList = [];
                productsRef.current.forEach((x, i) => {
                    tempList.push(!!x.quantity);
                    if (x.quantity && parseInt(x.quantity) < 1) {
                    tempList[i] = false;
                    }
                });
                setIsQuantityValid(tempList);
            }
            setErrors(client?.uniqueId);
            if(handleChecked !== undefined){
                handleChecked(client?.uniqueId)
            }
        } else {
            const payload = {
                deliveryInformations:{...deliveryInformationsRef.current, isRenting: selectedIsRentingRef.current},
                clientUniqueId:client?.uniqueId,
                subscriptionUniqueId:subscriptionUniqueIdsChecked[0],
                invoiceNumber:selectedInvoiceRef.current,
                products:productsRef.current,
                providerUniqueId:providerRef.current,
                comment:comment
            }
            initDataOrder?.uniqueId && (payload.uniqueId = initDataOrder.uniqueId);

            setIsSending(true);

            const actionToValidate = initDataOrder ? updateOrder:createOrder;

            actionToValidate(payload)
                .then(data => {
                    const {uniqueId : createdPreparatoryOrderUniqueId} = data;
                    setIsSending(false);
                    if(!multiple){
                        redirect(ordersSlugs.readOne.replace(':uniqueId', createdPreparatoryOrderUniqueId));
                    }
                    else if (handleValidation !== undefined){
                        handleValidation(client?.uniqueId)
                    }
                })
                .catch(() => {
                    setIsSending(false);
                    setErrors(client?.uniqueId);
                }).finally(()=>{
                    if(handleChecked !== undefined){
                        handleChecked(client?.uniqueId)
                    }
                })
        }
    }
       
    const cannotSendAlert = () => {
        if (cannotSend && (badQuantity || noProduct) && isNoSubscriptionSelected) {
            return <Alert variant="danger" className="text-center"> 
            {initDataOrder ? "Édition" : "Sauvegarde"} impossible: vérifiez les produits et sélectionnez un abonnement</Alert>;
        } else if (cannotSend && (badQuantity || noProduct)){
            return <Alert variant="danger" className="text-center"> 
            {initDataOrder ? "Édition" : "Sauvegarde"} impossible: vérifiez les produits</Alert>;
        } else if (cannotSend && isNoSubscriptionSelected) {
            return <Alert variant="danger" className="text-center"> 
            {initDataOrder ? "Édition" : "Sauvegarde"} impossible: sélectionnez un abonnement</Alert>;
        } else if (cannotSend && noRentingValue) {
            return <Alert variant="danger" className="text-center"> 
            {initDataOrder ? "Édition" : "Sauvegarde"} impossible: sélectionnez "Location" ou "Vente"</Alert>;
        } else {
            return null;
        }
    }


    const setErrors = (clientUniqueId) => {
        setCannotSend(true);
        if(handleErrors !== undefined){
            handleErrors(clientUniqueId);
        }
    }


    useEffect(() => {
        if(commonInfos !== undefined){
            setComment(commonInfos?.comment);
            deliveryInformationsRef.current = {... deliveryInformationsRef.current,...commonInfos?.deliveryInformations};
            productsRef.current = commonInfos?.products;
            providerRef.current = commonInfos?.provider;
            selectedIsRentingRef.current = commonInfos?.deliveryInformations?.isRenting;
        }
    }, [triggerUpdateCommonInfos]);


    useEffect(() => {
        if(validateAll !== undefined && validateAll){
            onValidate();
        }
    }, [validateAll]);
    
   
    return <>
        <SelectSubscriptionsComponent client={client} subscriptionUniqueIdsChecked={subscriptionUniqueIdsChecked} 
            setSubscriptionUniqueIdsChecked={setSubscriptionUniqueIdsChecked} isNoSubscriptionSelected={isNoSubscriptionSelected} 
            cannotSend={cannotSend} multiple={multiple} />
        {client?.uniqueId && <>
            <SelectInvoiceComponent client={client} selectedInvoiceRef={selectedInvoiceRef} />
            <DeliveryInformationComponent orderToUpdate={initDataOrder} triggerUpdateCommonInfos={triggerUpdateCommonInfos} 
                client={client} deliveryInformationsRef={deliveryInformationsRef} 
                commonDeliveryInfos={commonInfos?.deliveryInformations}  multiple={multiple}/>
            <SelectProductComponent allProducts={allProducts} triggerUpdateCommonInfos={triggerUpdateCommonInfos}
                        productsRef={productsRef} setNoProduct={setNoProduct} setBadQuantity={setBadQuantity} noProduct={noProduct} 
                        badQuantity={badQuantity} cannotSend={cannotSend} commonProductsInfos={commonInfos?.products}
                        clientUniqueId={client.uniqueId} selectedIsRentingRef={selectedIsRentingRef}/>
            <SelectProviderComponent triggerUpdateCommonInfos={triggerUpdateCommonInfos} providerRef={providerRef} commonProviderInfos={commonInfos?.provider} />
            <Row className="my-5">
                <Col>Commentaire :
                    <textarea
                        style={{width:'100%'}}
                        type="text"
                        placeholder="un commentaire ?"
                        value={comment} onChange={(e) => {setComment(e.target.value);}} />
                </Col>
            </Row>
        </>}
        {!multiple && <Row>
            <Col>
                <ButtonLoaderComponent
                    onClick={onValidate}
                    isSending={isSending}
                    disabled={isSending}
                    msg={initDataOrder ? "Éditer" : "Sauvegarder"}
                    iconClassName="fas fa-save" />
            </Col>
            <Col>
                {cannotSendAlert()}
            </Col>
        </Row>}
    </>;
}

export default OrderFormComponent;