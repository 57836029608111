import React from 'react';
import { useStoreState, } from 'easy-peasy';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {URLS} from '../../../services/axios';
import { CSVLink } from 'react-csv';



const MultiClientInfoComponent = ({ client }) => {
    const { apiData } = useStoreState(state => state.actionSlugs);
    const { allClients } = useStoreState(state => state.clients);

    const downloadCategoriesCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getClientsCategories.url;
        window.open(urlCSV);
    };

    const headersTemplateCsv = [
        { label: "Nom d'établissement", key: "name" },
        { label: "UniqueId d'établissement", key: "uuid" },
        { label: "Catégorie", key: "categories" },
        { label: "Voie", key: "addressStreet" },
        { label: "Code postal", key: "addressZipCode" },
        { label: "Ville", key: "addressCity" }
    ]
    const dataTemplateCsv = [];

    const headersClientsCsv = [
        { label: "Nom client", key: "firstname" },
        { label: "UniqueId client", key: "clientUniqueId" },
    ]

    const dataClientsCsv = allClients
        ?.filter(c => client.clientChildren.includes(c.uniqueId))
        ?.map(client => {
            return {
                firstname: client.clientName,
                clientUniqueId: client.uniqueId
            }
        });

    return <>
        Pour ajouter plusieurs clients enfants, il faut se munir d'un fichier csv comportant 
        les noms d'établissement, leur catégorie et leur adresse (voie, ville, code postale).<br/>
        Pour vous aider à la préparation de ce fichier vous avez la possibilité de télécharger :&nbsp;
        <strong>un fichier csv prêt à l'emploi</strong>, <strong>la liste des catégories</strong> et <strong>la liste des unique IDs des enfants</strong> 
        <Container>
            <Row className="mt-2">
                <Col>
                    <CSVLink
                        filename={"ajout_multiple_clients_enfants_" + new Date().toISOString().split('T')[0] + ".csv"}
                        headers={headersTemplateCsv}
                        data={dataTemplateCsv}>
                        <Button variant="primary" size="sm">
                            <i className="fas fa-download"/>&nbsp;Télécharger le fichier csv
                        </Button>
                    </CSVLink>
                </Col>
                <Col>
                    <Button variant="primary" size="sm" onClick={downloadCategoriesCSV}>
                        <i className="fas fa-download"/>&nbsp;Télécharger les catégories
                    </Button>
                </Col>
                < Col>
                    <CSVLink className='text-decoration-none w-auto'
                        filename={"enfants_uniqueId"
                            + new Date().toISOString().split('T')[0] + ".csv"}
                        headers={headersClientsCsv}
                        data={dataClientsCsv}
                    >
                        <Button variant="primary" size="sm" className='w-auto'>
                            <i className="fas fa-download" />&nbsp;Télécharger les unique IDs des enfants
                        </Button>
                    </CSVLink>
                </ Col>
            </Row>
        </Container>
    </>;
}

export default MultiClientInfoComponent;