import React from "react";
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';

import DynamicTable from "../widgets/dynamicTable/DynamicTable";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

import './WebappAccessTableComponent.scss';

const WebappAccessTableComponent = (props) => {
    const {webappAccesses = [], withParams} = props;
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const oneClientSlug = clientsSlugs && clientsSlugs.readOne? clientsSlugs.readOne : null;

    if(webappAccesses.length === 0) {
        return null;
    }

    const sortType = webappAccesses ?
        [
            {
                value : 'code', label : 'Code',
                test : webappAccesses[0].code !== undefined,
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.code, b.code),
                display: (webappAccess) => webappAccess.code
            },
            {
                value : 'email', label : 'E-mail',
                test : webappAccesses[0].email !== undefined,
                method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.email, b.email),
                display: (webappAccess) => webappAccess.email ? webappAccess.email: '-'
            },
            {
                value : 'usageCount', label : 'Utilisation',
                test : webappAccesses[0].usageCount !== undefined,
                method: (a,b) =>  b.usageCount - a.usageCount,
                display: (webappAccess) => webappAccess.usageCount
            },
            {
                value : 'eventsCount', label : 'Evénéments',
                test : webappAccesses[0].eventsCount !== undefined,
                method: (a,b) =>  b.eventsCount - a.eventsCount,
                display: (webappAccess) => webappAccess.eventsCount
            },
            {
                value : 'login', label : 'Login',
                test : webappAccesses[0].loginCount !== undefined,
                method: (a,b) =>  b.loginCount - a.loginCount,
                display: (webappAccess) => webappAccess.loginCount
            },
            {
                value : 'storyViewCount', label : 'Stories vues',
                test : webappAccesses[0].storyViewCount !== undefined,
                method: (a,b) =>  b.storyViewCount - a.storyViewCount,
                display: (webappAccess) => webappAccess.storyViewCount
            },
            {
                value : 'sequenceViewCount', label : 'Parcours vus',
                test : webappAccesses[0].sequenceViewCount !== undefined,
                method: (a,b) =>  b.sequenceViewCount - a.sequenceViewCount,
                display: (webappAccess) => webappAccess.sequenceViewCount
            },
            {
                value : 'externalLinkCount', label : 'Clics lien',
                test : webappAccesses[0].externalLinkCount !== undefined,
                method: (a,b) =>  b.externalLinkCount - a.externalLinkCount,
                display: (webappAccess) => webappAccess.externalLinkCount
            },
            {
                value : 'likeCount', label : 'Like',
                test : webappAccesses[0].likeCount !== undefined,
                method: (a,b) =>  b.likeCount - a.likeCount,
                display: (webappAccess) => webappAccess.likeCount
            },
            {
                value : 'dislikeCount', label : 'Dislike',
                test : webappAccesses[0].dislikeCount !== undefined,
                method: (a,b) =>  b.dislikeCount - a.dislikeCount,
                display: (webappAccess) => webappAccess.dislikeCount
            },
            {
                value : 'greenFactView', label : 'Vue greenfact',
                test : webappAccesses[0].greenFactView !== undefined,
                method: (a,b) =>  b.greenFactView - a.greenFactView,
                display: (webappAccess) => webappAccess.greenFactView
            },
            {
                value: 'clientName', label: "Établissement",
                test : webappAccesses[0].clientName != undefined,
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
                display: (webappAccess) => {

                    if(!webappAccess.clientName) {return '-'}

                    return oneClientSlug && webappAccess.clientUniqueId ?
                        <Link to={oneClientSlug.replace(':uniqueId', webappAccess.clientUniqueId)}>
                            {webappAccess.clientName}
                        </Link>
                        : <>{webappAccess.clientName}</>;
                },
                flatDisplay: (webappAccess) =>  webappAccess.clientName
            }
        ]:
        null;

    return (
        <DynamicTable 
            contentTable = {webappAccesses}
            contentSort = {sortType}
            valueInitSort = "code"
            index = 'code'
            withParams={withParams}
            filename="codes_acces_webapp"
            />
        )

}

export default WebappAccessTableComponent