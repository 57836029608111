import React, { useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import PreparatoryOrdersList from "./preparatoryOrder/PreparatoryOrdersList";
import ReturnOrdersList from "./returnOrder/ReturnOrdersList";
import { Link, useNavigate } from "react-router-dom";
import { useStoreState } from "easy-peasy";

const OrdersPage = ({ orderType = 'preparatoryOrders' }) => {
    const orderSlugs = {
        'preparatoryOrders': useStoreState(state => state.actionSlugs.actionSlugsDispatcher('orders')),
        'returnOrders': useStoreState(state => state.actionSlugs.actionSlugsDispatcher('returnOrders')),
    }
    const [key, setKey] = useState(orderType);
    const navigate = useNavigate();

    const changeTab = (key) => {
        setKey(key);
        navigate(orderSlugs[key]?.readAll);
    }

    const orderName = key === 'preparatoryOrders' ?  'préparation' : 'retour';

    return <Container fluid>
        <Row>
            <Col>
                <h2 className="d-flex align-items-center">
                    Bons de {orderName}
                    <i className={"ms-4 icon-box-" + (key === 'preparatoryOrders' ? "remove text-success" : "add text-danger")}></i>
                </h2>
            </Col>
        </Row>
        <Row className="justify-content-end me-2" style={{marginBottom: '-3.5em'}}>
            <Button className="w-auto" variant="success" as={Link} to={orderSlugs[key]?.create}>
                <i className="fas fa-plus"></i> &nbsp;Ajouter bon de&nbsp;{orderName}
            </Button>
        </Row>
        <Tabs defaultActiveKey="preparatoryOrders" activeKey={key} onSelect={(k) => changeTab(k)}>
            <Tab eventKey="preparatoryOrders" title="Bons de préparation"  mountOnEnter={true} unmountOnExit={false}> 
                <PreparatoryOrdersList />
            </Tab>
            <Tab eventKey="returnOrders" title="Bons de retour"  mountOnEnter={true} unmountOnExit={false}> 
                <ReturnOrdersList />
            </Tab>
        </Tabs>
    </Container>
}

export default OrdersPage;