import React from 'react';
import { Badge, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './TooltipListComponent.scss';

/**
 * 
 * @param {Array} inputList array of all object elements to be listed in tooltip
 * @param {String} keyToDisplay  which key in each inputList element to be displayed
 * @param {Function} generateIdCallback  callback to define how each key would be rendered
 * @param {Boolean} isSending  boolean to signify if we need to wait before displaying info (generally a state variable isLoading of sort)
 * @param {boolean} isAnswer  boolean for a special case: If the inputList is an array of answers, we need to diplay an icon for the right answer
 
 * @example
        <TooltipListComponent
                    inputList={answersList}
                    keyToDisplay='caption'
                    generateIdCallback={generateIdCallback}
                    isAnswers={true}
                /> * 
 */

const TooltipListComponent = ({inputList, keyToDisplay, generateIdCallback, isFetching = false, isAnswers = false}) => {

    const listOfGroups = inputList.map((content, index) => (
        <ListGroup.Item key={generateIdCallback(content.uniqueId)}>
            {`${index+1}. ${content[keyToDisplay]}`} { isAnswers && content['isTrue'] ? <i style ={{color: "green"}} className="fa fa-check-circle"/> : ""}
        </ListGroup.Item>));

    return  (
        <OverlayTrigger
            placement='right'
            overlay={
            <Tooltip>
                <ListGroup className='text-primary text-left'>{listOfGroups}</ListGroup>
            </Tooltip>
            }
        >
           <Badge pill style={{ padding: '0.5rem', fontSize:'0.8rem' }}>{isFetching ? '...' : listOfGroups.length}</Badge>
        </OverlayTrigger>
    )
}

export default TooltipListComponent;