import React from 'react';
import AuthorizationChecker from '../../../../services/AuthorizationChecker';

const AnswerCardComponent = ({selectedAnswer, answer, selectEditForm}) => {
   
    const active = selectedAnswer && selectedAnswer.uniqueId === answer.uniqueId ? 'answer-element--active' : '';
   
    return (
        <div className={`answer-element ${active}`} key={'answer_' + answer.uniqueId} >
            <div className='answer-caption' dangerouslySetInnerHTML={{ __html: answer.caption }}/>
               
            <div className='correct-answer'>
                {answer.isTrue ? <i className="far fa-check-circle"/> : null}
            </div>

            <div className='answer-actions'>
                {AuthorizationChecker.hasUpdateRights('quizzes', 'answers') ? <i className="fas fa-edit text-primary" onClick={() => {selectEditForm(answer)}}/> : null }
                {AuthorizationChecker.hasDeleteRights('quizzes', 'answers') ?<i className="fas fa-trash-alt" /> : null}
            </div>
        </div>
    )

}

export default AnswerCardComponent;