import React, {useState, useEffect} from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import QuestionFormComponent, { submitQuestionForm } from './QuestionFormComponent.jsx';
import AnswersComponent from '../answers/AnswersComponent.jsx';
import QuestionCardComponent from './QuestionCardComponent.jsx';

import './QuestionComponent.scss'
import { useToast } from '../../../../hooks/useToast.jsx';
import { useStoreState } from 'easy-peasy';

const QuestionComponent = ({
    quizToUpdate,
    createQuestionAction,
    updateQuestionAction,
    deleteQuestionBackgroundAction,
    modalProps
}) => {

    const renderFormType = {
		hide: 'hide',
		update: 'update',
		create: 'create',
        answers: 'answers',
	};

    const [questionList, setQuestionList] = useState(quizToUpdate.questions ? [...quizToUpdate.questions] : []);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [mainViewType, setMainViewType] = useState(renderFormType.hide);
    const toast = useToast();
	const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    
    const { setModalShow, modalData, setModalData, setIsSending } = modalProps;

    useEffect(() => {
        setQuestionList(quizToUpdate.questions ? [...quizToUpdate.questions] : []);

        
        (!selectedQuestion) ?  selectHideForm(): setSelectedQuestion({...quizToUpdate.questions.find((question) => question.uniqueId === selectedQuestion.uniqueId)});
    }, [quizToUpdate])
    
    useEffect(() => {
        mainContentToRender();
    }, [mainViewType, questionList, selectedQuestion])


    const selectHideForm = () => {
		setSelectedQuestion(null);
		setMainViewType(renderFormType.hide);
	}

	const selectEditForm = (question) => {
		setSelectedQuestion({...question});
		setMainViewType(renderFormType.update);
	}

	const selectCreateForm = () => {
		setSelectedQuestion(null);
		setMainViewType(renderFormType.create);
        mainContentToRender();
	}

    const selectAnswersForm = (question) => {
		setSelectedQuestion({...question});
        setMainViewType(renderFormType.answers);
	}

    const mainContentToRender = () => {
        const handleSubmit = (questionPersistAction) => {
            setIsSending(true);
            submitQuestionForm({formName:'question', question: selectedQuestion, 
                quizUniqueId: quizToUpdate.uniqueId, questionPersistAction, fileContraints} )
                    .then(() => {
                        toast.success()
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
        }
        
		switch (mainViewType) {
			case renderFormType.hide:
				setModalShow(false);
                break;
			case renderFormType.update: {
                setModalData({
                    ...modalData,
                    header: "Edition d'une question",
                    content: <QuestionFormComponent question={selectedQuestion} quizUniqueId={quizToUpdate.uniqueId} 
                        deleteQuestionBackgroundAction={deleteQuestionBackgroundAction} />,
                    cancelButton: 'Annuler',
                    onValidate: () => handleSubmit(updateQuestionAction),
                    size: 'xl',
                });
                setModalShow(true);
                break;
            }
			case renderFormType.create:{
                setModalData({
                    ...modalData,
                    header: "Ajout d'une question",
                    content: <QuestionFormComponent quizUniqueId={quizToUpdate.uniqueId}/>,
                    cancelButton: 'Annuler',
                    onValidate: () => handleSubmit(createQuestionAction),
                    size: 'xl',
                });
                setModalShow(true);
                break;
            }
            case renderFormType.answers:{
                setModalData({
                    ...modalData,
                    header: "Gestion des réponses",
                    content: <AnswersComponent question={selectedQuestion} quizUniqueId={quizToUpdate.uniqueId}/>,
                    size: 'xl',
                });
                setModalShow(true);
                break;
            }
			default:
				setModalShow(false);
                break;
		}
	}

    return (
    <div >
        <Row className="mb-3">
            <Col className="d-flex justify-content-end align-items-center">
                <Button onClick={() => selectCreateForm()} variant='success'><i className="fas fa-plus"></i> &nbsp;Ajouter une question</Button>
            </Col>
        </Row>
            <div className="questions-container">
            {
                questionList.map((question) => {
                    return (
                        <QuestionCardComponent key={'question-elem'+ question.uniqueId} question={question} selectEditForm={selectEditForm} selectAnswersForm={selectAnswersForm}/>
                    )
                })
            }
            </div>
    </div>
    )

}

export default QuestionComponent;