import React from "react";
import { Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useStoreActions, useStoreState } from "easy-peasy";

import './ProfessionalComponent.scss'
import AuthorizationChecker from "../../services/AuthorizationChecker";
import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast";
import { Link } from "react-router-dom";

const ProfessionalComponent = ({ pro, fromVideoUniqueId = null, isDeletable = false, onlySummaryInfos = false }) => {
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const { deleteProFromVideo } = useStoreActions(actions => actions.videos);
    const romesSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('romes'));
    const workingConditionsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('workingConditions'));
    const areasOfInterestSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('areasOfInterest'));
    const skillsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('skills'));

    const toast = useToast();

    const hasDeleteRight = AuthorizationChecker.hasDeleteRights('videos');

    const onDelete = (pro) => {
        if (hasDeleteRight) {
            setModalData({
                ...modalData,
                header: <>Suppression d'un⋅e professionnel⋅le</>,
                content: <h5>Êtes-vous sûr⋅e de vouloir supprimer <b>{pro.name}</b>&nbsp;?</h5>,
                resetButton: 'Supprimer',
                cancelButton: 'Annuler',
                onReset: fromVideoUniqueId ?
                    () => {
                        setIsSending(true);
                        deleteProFromVideo({ videoUniqueId: fromVideoUniqueId, proUniqueId: pro.uniqueId })
                            .then(() => {
                                toast.success();
                                setModalShow(false);
                            })
                            .finally(() => setIsSending(false));
                    }
                    : null,
            });
            setModalShow(true);
        }
    }

    const itemList = (item, slugs) => <Link
        key={pro.uniqueId + "_" + item.code}
        to={slugs?.readOne?.replace(':uniqueId', item.code)}
        className="text-decoration-none">
        <li>
            {item.label}
            <i className="fa fa-link ms-2"></i>
        </li>
    </Link>;

    return <Row className="card-pro mx-0 p-3 flex-column">
        <div className="card-pro-content">
            {hasDeleteRight && isDeletable &&
                <div className="btn-delete-pro" onClick={() => onDelete(pro)}>
                    <i className="fas fa-trash-alt"></i>
                </div>}
            <div className="photo">
                <img src={pro.thumbnail} alt="profil du professionnel" />
            </div>
            <div className="w-100 p-0">
                <h5 className="text-center fw-bold mt-3">{pro.name}</h5>
                {pro.mainJob
                    && <h6 className="text-center mb-4">
                        {pro.mainJob?.label}&nbsp;
                        {!onlySummaryInfos && <>(<Link to={romesSlugs?.readOne?.replace(':uniqueId', pro.mainJob?.rome)}>
                            {pro.mainJob?.rome}
                        </Link>)</>}
                    </h6>
                }
                {!onlySummaryInfos && <div className="w-100">
                    {(pro.jobAppellations?.length > 0) && <div className="mb-3">
                        <p className="item-title">Autres expertises</p>
                        <ul>
                            {pro.jobAppellations.map(jobAppellation =>
                                <li key={pro.uniqueId + "_" + jobAppellation.code}>
                                    {jobAppellation.label}&nbsp;
                                    (<Link to={romesSlugs?.readOne?.replace(':uniqueId', jobAppellation?.rome)}>
                                        {jobAppellation?.rome}
                                    </Link>)
                                </li>)
                            }
                        </ul>
                    </div>}
                    {pro.areasOfInterest?.length > 0 && <div className="mb-3">
                        <p className="item-title"><i className="fa fa-heart fa-lg me-2" />Centres d'intérêt</p>
                        <ul>
                            {pro.areasOfInterest.map(areaOfInterest => itemList(areaOfInterest, areasOfInterestSlugs))}
                        </ul>
                    </div>}
                    {pro.softSkills?.length > 0 && <div className="mb-3">
                        <p className="item-title"><i className="fa fa-user-cog fa-lg me-2" />Savoir-être professionnels</p>
                        <ul>
                            {pro.softSkills.map(softSkill => itemList(softSkill, skillsSlugs))}
                        </ul>
                    </div>}
                    {pro.workingConditions?.length > 0 && <div className="mb-3">
                        <p className="item-title"><i className="fa fa-briefcase fa-lg me-2" />Contextes de travail</p>
                        <ul>
                            {pro.workingConditions.map(workingCondition => itemList(workingCondition, workingConditionsSlugs))}
                        </ul>
                    </div>}
                </div>}
            </div>
        </div>
        {modalComponent}
    </Row>
}

ProfessionalComponent.propTypes = {
    pro: PropTypes.object.isRequired,
    fromVideoUniqueId: PropTypes.string,
    isDeletable: PropTypes.bool
};

export default ProfessionalComponent;