import React from 'react';
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";

import DynamicTable from '../../widgets/dynamicTable/DynamicTable.jsx';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';
import TooltipListComponent from '../../widgets/tooltipList/TooltipListComponent.jsx';

import "./QuizQuestionsTableComponent.scss"

const QuizQuestionsTableComponent = ({questions}) => {
    const questionsToDisplay = questions ? questions : [];

    if (questions.length === 0 )
    {return <></>;}

    const sortType = [
        {
            value: 'background', label: 'image de fond',
            test: questionsToDisplay[0].links.background !== undefined,
            // test: questionsToDisplay[0].thumbnail !== undefined,
            method: null,
            display: (question) => (
                !question.links.background ? "pas d'image" :
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            <Image src={question.links.background}/>
                        </Tooltip>
                    }    
                >
                    <Image src={question.links.background} className="img-overlay" thumbnail />
                </OverlayTrigger>
            )
        },
        {
            value: 'caption', label: "énoncé",
            test : questionsToDisplay[0].caption !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.caption, b.caption),
            display: (question) => question.caption
        },
        {
            value: 'position', label: "position",
            test : questionsToDisplay[0].position !== undefined,
            method: (a, b) => b.position - a.position,
            display: (question) => question.position
        },
        {
            value: 'rightExplaination', label: "feedback positif",
            test : questionsToDisplay[0].rightExplaination !== undefined,    
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.rightExplaination, b.rightExplaination),
            display: (question) => question.rightExplaination ? HelperMetiers360.textTruncate(question.rightExplaination, 50) : '-',
        },
        {
            value: 'wrongExplaination', label: "feedback négatif",
            test : true,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.wrongExplaination, b.wrongExplaination),
            display: (question) => question.wrongExplaination ? HelperMetiers360.textTruncate(question.wrongExplaination, 50) : '-',
        },
        {
            value: 'neutralExplaination', label: "feedback neutre",
            test : true,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.neutralExplaination, b.neutralExplaination),
            display: (question) => question.neutralExplaination ? HelperMetiers360.textTruncate(question.neutralExplaination, 50) : '-',
        },
        
        {
            value: 'answers', label: "nb de reponses",
            test : questionsToDisplay[0].answers !== undefined,
            method: (a, b) => {
              return b.answers.length - a.answers.length;  
            },
            display: (question) => {
                
                const answersList = question.answers.map((answer) => {
                    return {
                        uniqueId: answer.uniqueId,
                        caption: answer.caption,
                        isTrue: answer.isTrue
                    }
                })

                const generateIdCallback = (uniqueId) => {
                    return `answer-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={answersList}
                    keyToDisplay='caption'
                    generateIdCallback={generateIdCallback}
                    isAnswers={true}
                />
            }
        },
        {
            value: 'color', label: "couleur du texte",
            test : questionsToDisplay[0].color !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.color, b.color),
            display: (question) => <div className="show-color-container"><div style={{backgroundColor: question.color}} className="show-color"/></div>
        },
        {
            value: 'multipleChoice', label: "choix multiple",
            test : questionsToDisplay[0].multipleChoice !== undefined,
            method: (a, b) => {const aChoice = a.multipleChoice ? 'X':'-'; return aChoice.localeCompare(b.multipleChoice ? 'X':'-');},
            display: (question) => question.multipleChoice ? 'X' : '-'
        },
    ];
    
    return (
        <>
            <DynamicTable 
                contentTable = {questionsToDisplay}
                contentSort = {sortType}
                valueInitSort = "caption"
                index = 'uniqueId'
                handleClick={undefined}
                className="quiz-answers-table"
                />
        </> 
    );
}

export default QuizQuestionsTableComponent