import React, {useMemo, useEffect} from "react";
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Col, Container, Image } from "react-bootstrap";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import './GroupDisplayContentComponent.scss';

const GroupDisplayContentComponent = (props) => {
    const {group:currentGroup, setHasItemsCallback=null, publicOnly = false} = props;

    // get BACKOFFICE_DEFAULT_VALUES from apiData
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { apiData: { USER } } = useStoreState(state => state.actionSlugs);

    const {allVideos} = useCustomGetStoreState("videos");
    const {allQuizzes} = useCustomGetStoreState("quizzes");
    const {allGroups} = useCustomGetStoreState("groups");
    const groupSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("groups"));
    const videosSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("videos"));
    const quizzesSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("quizzes"));



    const items = useMemo(() => {
        const items = [];
        
        if(currentGroup && (allGroups.length || allVideos.length || allQuizzes.length)) {
            allGroups.forEach(group => {
                const groupInSelectedGroup = group.groups?.find((item) => item.uniqueId === currentGroup.uniqueId);
                if (!(group.private && publicOnly) && (!!groupInSelectedGroup)) {
                        items.push({
                            uniqueId : group.uniqueId,
                            displayOrder : groupInSelectedGroup ? groupInSelectedGroup.displayOrder:group.position,
                            thumbnail : group.links.thumbnail,
                            type : 'group',
                        });
                }
            });
            allVideos.forEach(video => {
                const videoInSelectedGroup = video.groups?.find((item) => item.uniqueId === currentGroup.uniqueId);
                if (!(video.private && publicOnly) && videoInSelectedGroup) {
                    items.push({
                        uniqueId : video.uniqueId,
                        displayOrder : videoInSelectedGroup ? videoInSelectedGroup.displayOrder:video.position,
                        thumbnail : video.links.thumbnail,
                        type : 'video',
                    });
                }
            });
            allQuizzes.forEach(quiz => {
                const quizInSelectedGroup = quiz.groups?.find((item) => item.uniqueId === currentGroup.uniqueId);
                if (!(quiz.private && publicOnly) && quizInSelectedGroup) {
                    items.push({
                        uniqueId : quiz.uniqueId,
                        displayOrder : quizInSelectedGroup ? quizInSelectedGroup.displayOrder:quiz.position,
                        thumbnail : quiz.links.thumbnail,
                        type : 'quiz',
                    });
                }
            });
        }
       
        return items
    }, [allQuizzes, allVideos, allGroups, currentGroup, publicOnly]);

    useEffect(() => {
        setHasItemsCallback && setHasItemsCallback(items.length > 0);   
    }, [items])

    const cardsItems = items
        .sort((a,b) => a.displayOrder - b.displayOrder)
        // .map((item) => <Col><Card><Card.img src={item.thumbnail} /></Card></Col> );
        .map((item) => {
            let link = null;
            switch (item.type) {
                case 'group':
                    link = groupSlugs.readOne.replace(':uniqueId', item.uniqueId);
                    break;
                case 'video':
                    link = videosSlugs.readOne.replace(':uniqueId', item.uniqueId);
                    break;
                case 'quiz':
                    link = quizzesSlugs.readOne.replace(':uniqueId', item.uniqueId);
                    break;
                default:
                    break;
            }
            let cardItem = 
                <Image 
                    className={"img-"+item.type} 
                    src={item.thumbnail} 
                    thumbnail 
                />;
                
            if(link) {
                cardItem = <Link  to={link}>{cardItem} </Link>;
            }

            return <Col key={item.uniqueId} className="item-thumbnail">{cardItem}</Col>
        });


    const customBackgroundImage = currentGroup?.links?.background;
    const defaultBackgroundImage = BACKOFFICE_DEFAULT_VALUES.bkgImage;
    const clientCustomBackgroundImage = USER?.client?.bkgImage;
    const backgroundImage = currentGroup?.idHeadset == 0 ?
        (clientCustomBackgroundImage ?? (customBackgroundImage ?? defaultBackgroundImage))
        : (customBackgroundImage ?? defaultBackgroundImage);
    
    return <>
        <Container className = 'group' style={{backgroundImage:"url(" + backgroundImage + ")"}} >
            {cardsItems}
        </Container>
    </>;

}

export default GroupDisplayContentComponent;