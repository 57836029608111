import React, { useState } from 'react';

import LoginForm from '../../components/security/LoginForm.jsx';
import GetResetLinkForm from '../../components/security/GetResetLinkForm.jsx';

import logo from '../../images/logo140.png';

import './Login.scss';

const Login = ()=>{
    const [formType, setFormType] = useState('login');
    const [email, setEmail] = useState('');

    const onSwapForm = () => {
        let inputEmail=document.getElementById('inputEmail');
        setEmail(inputEmail?.value);
        setFormType(formType === 'login' ? 'reset':'login')
    }

    return formType === 'reset' ? 
        <GetResetLinkForm backAction={onSwapForm} email={email} />
        :<LoginForm resetAction={onSwapForm} email={email}/>;

}

export default Login;