import React, { useState } from "react";
import { Container, Row, Alert, Button } from "react-bootstrap";
import Papa from "papaparse";

import SelectCSVDataComponent from "../../widgets/selectCSVData/SelectCSVDataComponent";
import MultiUsersOrContactsInfoComponent from "./MultiUsersOrContactsInfoComponent";
import CheckMultiUsersOrContactsComponent from "./CheckMultiUsersOrContactsComponent";

const GetMultiUsersOrContactsComponent = (props) => {
    const { entityType, entitiesRef, onValidate, formationsAuthorized, onChangeIsFormationRequired, 
        onChangeFormationRequiredUniqueId, dataStructureTarget, clientUniqueIdsSelected=[],
        selectedUniqueIdParentClientDefault=null, createUserAccessForContacts = null,
        setCreateUserAccessForContacts = null, setFormationForUserAccessForContacts = null} = props;

    const [stage, setStage] = useState(0);
    const [csvContent, setCsvContent] = useState(null);
    const [rowChoosen, setRowChoosen] = useState(false);
    const [dataStructure, setDataStructure] = useState(dataStructureTarget.map((data) => {return {...data}}));

    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                // Remove empty lines in csv
                results.data = results.data.filter(d => !Object.values(d).every(v => v === ""));
                setCsvContent(results);
            },
        });

        setStage(1);
    };

    const checkPhase = () => {
        entitiesRef.current = csvContent.data
            .map(row => dataStructure.reduce((acc,data) => {
                    acc[data.field] = row[data.csvIdx];
                    if(data.callBack) {acc[data.field]=data.callBack(acc[data.field])}
                    return acc;
                },{})
            );
        setStage(2);
    }

    const checkDataButton = <Button variant="primary" size="sm" disabled={!rowChoosen} className="align-right" onClick={checkPhase}>
            Phase suivante <span className="icon-arrow-right"></span>
        </Button>;
    
    const stage0 = <Container>
            <Row>
                <Alert variant="info">
                    <MultiUsersOrContactsInfoComponent entityType={entityType} clientUniqueIdsSelected={clientUniqueIdsSelected}
                        selectedUniqueIdParentClientDefault={selectedUniqueIdParentClientDefault} />
                </Alert>
            </Row>
            <Row>
                <div className="custom-file">
                    <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        accept=".csv"
                        onChange={changeHandler}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Parcourir">
                        Choisir un fichier csv
                    </label>
                </div>
            </Row>
        </Container>;

    const stage1 = <Container>
                <Button variant="primary" size="sm" className="mb-3" onClick={() => setStage(0)}>
                    <span className="icon-arrow-left"></span> Phase précédente
                </Button>
            <Row>
                <SelectCSVDataComponent 
                    csvContent={csvContent} 
                    dataStructure={dataStructure} 
                    setDataStructure={setDataStructure} 
                    fieldsNecessary={["clientUniqueId", "email"]}
                    setRowChoosen={setRowChoosen} />
            </Row>
            {checkDataButton}
        </Container>;

    const stage2 = <CheckMultiUsersOrContactsComponent
        entityType={entityType}
        entities={entitiesRef.current} 
        dataStructure = {dataStructure}
        formationsAuthorized={formationsAuthorized}
        onValidate={onValidate} 
        onChangeIsFormationRequired={onChangeIsFormationRequired} 
        onChangeFormationRequiredUniqueId={onChangeFormationRequiredUniqueId}  
        onCancel={() => setStage(1)}
        createUserAccessForContacts={createUserAccessForContacts}
        setCreateUserAccessForContacts={setCreateUserAccessForContacts}
        setFormationForUserAccessForContacts={setFormationForUserAccessForContacts} />

    const display = [stage0, stage1, stage2];

    return <>{display[stage]}</>
}

export default GetMultiUsersOrContactsComponent;