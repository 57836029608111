import React, { useState, useEffect } from 'react';
import {Form, Alert} from 'react-bootstrap';

import { useStoreState, useStoreActions } from 'easy-peasy';
import {  Link } from "react-router-dom";

import AuthorizationChecker from '../../../services/AuthorizationChecker';
import ButtonLoaderComponent from '../../widgets/ButtonLoaderComponent.jsx';

import './HeadsetUpdateFormComponent.scss';
import Loader from '../../widgets/Loader';

import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState';
import { useToast } from '../../../hooks/useToast.jsx';

const HeadsetUpdateFormComponent = ({headset, subscriptions = []}) => {
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const {typeOfHeadset: allHeadsetsTypes } = BACKOFFICE_DEFAULT_VALUES;
    const {typeOfHeadsetOwnership: allHeadsetOwnershipsTypes } = BACKOFFICE_DEFAULT_VALUES;
    const { updateHeadset } = useStoreActions((actions => actions.headsets));
    const { allPlaylists } = useCustomGetStoreState('playlists');
    const { client:useClientUniqueId } = useStoreState(state => state.auth);
   
    const toast = useToast();

    const playlistSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('playlists'));

    const [isSending, setIsSending] = useState(false);

    const playlistListOptions = allPlaylists?.filter(playlist => playlist.client.uniqueId == headset.clientUniqueId || playlist.uniqueId && playlist.uniqueId == headset.playlistUniqueId )
        .map(playlist => 
            <option 
                key={'playlist'+playlist.uniqueId}
                value={playlist.uniqueId} 
                >
                {playlist.name}
            </option>);

    const subscriptionsListOptions = subscriptions
        ?.map(subscription => 
            <option 
                key={'subscription'+subscription.uniqueId}
                value={subscription.uniqueId} 
                >
                {subscription.subscriptionName ?? `Abonnement se terminant le ${subscription.subscriptionEndDate}`}
            </option>); 
            
    
    const headsetsTypeListOptions = allHeadsetsTypes
        ?.map(headsetType => 
            <option 
                key={'headset_type'+headsetType.reference}
                value={headsetType.reference} 
                >
                {headsetType.label}
            </option>);
            

    const headsetsOwnershipOptions = Object.entries(allHeadsetOwnershipsTypes)
            ?.map(ownershipType => 
                <option 
                    key={'headset_ownership'+ownershipType[0]}
                    value={ownershipType[0]} 
                    >
                    {ownershipType[1]}
                </option>);   
                
               

    const onValidate = (event) => {
        event.preventDefault();
        setIsSending(true);
        const formData = new FormData(document.getElementById('CreateHeadset-form'));
        updateHeadset({formData, uniqueId: headset.uniqueId})
            .then(() => toast.success())
            .finally(() => setIsSending(false));
    }

    const canEditName = !AuthorizationChecker.isAdmin() || useClientUniqueId === headset.clientUniqueId;

    return (
            <>
            <Form 
                id="CreateHeadset-form" 
                method="post" 
                encType="multipart/form-data"
                onSubmit={onValidate}
            >
                <Form.Group>
                    <Form.Label>Nom du casque</Form.Label>
                    <Form.Control 
                        name="headset_Name"
                        placeholder="Entrer le nom du casque" 
                        defaultValue={headset ? headset.headsetName : null}
                        readOnly={!canEditName ? true:false}
                    />
                    {!canEditName ? <Form.Text className="text-muted">
                        Le nom est contrôlé par le client
                    </Form.Text>:null}
                </Form.Group>
                <Form.Control
                    name="headset_deviceId"
                    type="hidden"
                    placeholder="Entrer le numéro du casque"
                    defaultValue={headset ? headset.deviceId : null}
                    readOnly
                />
                <br/>
                <Form.Label> Playlist assignée</Form.Label>
                &nbsp;<small>(<Link to={playlistSlugs.readAll}>Voir toutes les playlists</Link>)</small>
                    {playlistListOptions ?
                        <Form.Select name="headset_playlist" defaultValue={headset && headset.playlistUniqueId ? headset.playlistUniqueId : null}>
                        <option value="">Aucune playlist</option>
                            {playlistListOptions}
                        </Form.Select>
                        :<Loader />
                    }
                <br/>
                {AuthorizationChecker.isAdmin() ?
                    <>
                    
                    <Form.Label>Abonnement</Form.Label>
                        {subscriptionsListOptions ?
                            <Form.Select name="headset_subscription" defaultValue={headset?.subscriptionUniqueId ? headset.subscriptionUniqueId : null}>
                                {subscriptionsListOptions}
                            </Form.Select>
                            :<Loader />
                        }
                    <br/>

                    <Form.Label>Type de casque</Form.Label>
                        {headsetsTypeListOptions ?
                            <Form.Select name="headset_type" defaultValue={headset && headset.typeOfHeadset.reference ? headset.typeOfHeadset.reference : null}>
                                {headsetsTypeListOptions}
                            </Form.Select>
                            :<Loader />
                        }
                    <br/>

                    <Form.Label>Type de possession</Form.Label>
                        {headsetsOwnershipOptions ?
                            <Form.Select name="headset_ownership" defaultValue={headset && headset.ownership? headset.ownership : null}>
                                {headsetsOwnershipOptions}
                            </Form.Select>
                            :<Loader />
                        }
                    <br/>

                    <div>
                        <Form.Check
                            inline
                            type='checkbox'
                            label={'Conserver le contenu du casque'}
                            id={'headset_keepContent'}
                            name="headset[keepContent]"
                            value={true}
                            defaultChecked={headset.keepContent}
                        />
                    </div>
                    <Form.Text className="text-muted">
                        En décochant cette case, les contenus déjà téléchargés dans le casque mais non visibles dans le casque 
                        (dans le cadre de l'application d'une playlist par exemple) seront supprimés.<br />
                        Ils seront retéléchargés s'ils devaient être affichés à nouveau.
                    </Form.Text>
                    <br />

                    </>
                    :null
                    }

                <input type="hidden" name="headsetUniqueId" value={headset ? headset.uniqueId : undefined} />

                <ButtonLoaderComponent
                    block={true} type="submit" variant='success' size='sm' className="mt-2 mx-auto d-flex" msg={<><i className="fas fa-check text-white me-2"/> Valider</>} isSending={isSending} disabled={isSending}
                />
        </Form>
        </>
    )
}

export default HeadsetUpdateFormComponent;