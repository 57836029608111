import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios';

const storiesInitialState = {
    allStories: [],
    isFetchingStories: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    storyById: null,
    isFetchingOneStory: false,
}

const stories = {
    ...storiesInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allStories: state.allStories,
                    isFetchingStories: state.isFetchingStories,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    storyById: state.storyById,
                    isFetchingOneStory: state.isFetchingOneStory
                } = storiesInitialState)
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsFetchingStories: action((state, payload) => {
        state.isFetchingStories = payload;
    }),

    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setSomeStories: action((state, payload) => {
        state.allStories = [...state.allStories, ...payload];
    }),

    setAllStories: action((state, payload) => {
        state.allStories = payload;
    }),

    fetchSomeStories: thunk((actions, payload, helpers) => {
        const { isFetchingStories, isUpdateNecessary } = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;


        const getUrl = apiData.ACTIONS?.stories?.getSome?.url;
        const numberToFetch = payload?.params?.number;

        if (!isFetchingStories && isUpdateNecessary) {
            actions.setIsFetchingStories(true);
            
            return axios.get(getUrl, payload)
                .then(data => {
                    payload.params.firstFetch
                        ? actions.setAllStories(data)
                        : actions.setSomeStories(data);
                    if (data.length < numberToFetch) {
                        actions.setIsUpdateNecessary(storiesInitialState.isUpdateNecessary);
                        actions.setInitialFetchDone(true);
                    }
                    return data;
                })
                .catch(error => {
                    actions.setAllStories(storiesInitialState.allStories);
                    return Promise.reject(error);
                })
                .finally(() => {
                    actions.setIsFetchingStories(storiesInitialState.isFetchingStories);
                })
        } else {
            Promise.resolve();
        }
    }),

    // ACTIONS TO UPDATE ALLSTORIES AFTER CREATE/UPDATE
    addStory: action((state, payload) => {
        state.allStories.push(payload);
    }),

    updateAllStories: action((state, payload) => {
        const index = state.allStories.findIndex(story => story.uniqueId === payload.uniqueId);

        state.allStories[index] = {
            ...state.allStories[index],
            ...payload,
        }
    }),

    removeFromAllStories: action((state, payload) => {
        const indexToRemove = state.allStories.findIndex(story => story.uniqueId === payload);

        state.allStories.splice(indexToRemove, 1);
    }),

    //  HANDLING ONE STORY
    setStoryById: action((state, payload) => {
        state.storyById = payload;
    }),
    setIsFetchingOneStory: action((state, payload) => {
        state.isFetchingOneStory = payload;
    }),

    fetchStoryById: thunk((actions, payload, helpers) => {
        const { isFetchingOneStory } = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const getUrl = apiData.ACTIONS?.stories?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneStory) {
            actions.setIsFetchingOneStory(true);
            actions.setStoryById(storiesInitialState.storyById);
            return axios.get(getUrl)
                .then(data => {
                    actions.setIsFetchingOneStory(storiesInitialState.isFetchingOneStory);
                    actions.setStoryById(data);
                })
                .catch(error => Promise.reject(error));
        } else {
            return Promise.resolve();
        }
    }),

    // posting one story
    postStory: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const createStoryUrl = apiData.ACTIONS?.stories?.create?.url;
        const { formData } = payload;

        return axios.post(createStoryUrl, formData)
            .then((result) => {
                actions.addStory(result);
                actions.setStoryById(storiesInitialState.storyById);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    // updating one story
    updateStory: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const updateStoryUrl = apiData.ACTIONS?.stories?.update?.url?.replace('uniqueId', uniqueId);

        return axios.post(updateStoryUrl, formData)
            .then((result) => {
                actions.updateAllStories(result);
                actions.setStoryById(storiesInitialState.storyById);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

    // deleting one story
    deleteStory: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const formData = new FormData();
        formData.set(`${apiData.ACTIONS?.stories?.delete?.formName}[_token]`, apiData.ACTIONS?.stories?.delete?.csrftoken);
        const deleteStoryUrl = apiData.ACTIONS?.stories?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.post(deleteStoryUrl, formData)
            .then((result) => {
                actions.removeFromAllStories(uniqueId);
                actions.setStoryById(storiesInitialState.storyById);
                return result;
            })
            .catch(error => Promise.reject(error));
    }),

};

export default stories;