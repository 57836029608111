const iconInteractionTypes ={
	'Meeting' : 'fas fa-calendar-alt',
	'Email' : 'fas fa-envelope',
	'Phone': 'fas fa-phone-alt',
	'Mail' : 'fas fa-inbox',
	'Sms': 'fas fa-comment-dots',
	'Note': 'fas fa-file-alt',
	'Task': 'fas fa-clipboard-list'
  };

  export default iconInteractionTypes;