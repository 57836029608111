import React, {useState, useEffect, useRef} from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { useStoreActions } from 'easy-peasy';

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";

import Loader from "../../components/widgets/Loader";
import ClientsCRMTableComponent from "../../components/clients/ClientsList/ClientsCRMTableComponent";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker";
import { useModal } from "../../hooks/useModal.jsx";

import ClientFormComponent, { submitClientForm } from "../../components/clients/clientForm/ClientFormComponent.jsx";
import { useToast } from "../../hooks/useToast.jsx";
import MergeClientsComponent, {submitMergedClients} from "../../components/clients/mergeClients/MergeClientsComponent.jsx";
import ClientsFiltersComponent from "../../components/clients/ClientsList/ClientsFiltersComponent.jsx";
import TooltipInfo from "../../components/widgets/tooltipInfo/TooltipInfo.jsx";

const ClientCRMTableWithParams = withURLParamsTableComponent(ClientsCRMTableComponent);

const ClientCRMPage = () => {

    const {allClientsCRM, isFetchingAllClientsCRM} = useCustomGetStoreState('clients');
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);
    const toast = useToast();
    const { createClientCRM } = useStoreActions(actions => actions.clients);

    const [filteredClients, setFilteredClients] = useState([]);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    const mergeClientsRef = useRef(null);

    useEffect(() => {
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, []);

    const isNoResult = !isFetchingAllClientsCRM && allClientsCRM.length && !filteredClients.length;

    const openCreateClientModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            const result = submitClientForm({action: 'create', createClientCRM});

            result
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Création d'un client</>,
            content: <ClientFormComponent action="create" />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'xl', 
        });
        setModalShow(true);
    };

    const mergeClientsModal = () => {
        const handleSubmit = () => {
            if(!mergeClientsRef?.current){
                toast.open({ message: "Veuillez sélectionner les clients à fusionner", variant: "danger" })
                return;
            }
            setIsSending(true);
            const result = submitMergedClients(mergeClientsRef);

            result
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }
        setModalData({
            ...modalData,
            header: <>Fusionner des clients</>,
            content: <MergeClientsComponent mergeClientsRef = {mergeClientsRef} />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            validateButton: 'Fusionner',
            size: 'xl'
        });
        setModalShow(true);
    }

    return <Container fluid>
        <Row>
            <Col>
                <h2 className="d-flex">
                    <Col className="d-flex">
                        Tous nos clients
                        <TooltipInfo description="Cette page rassemble tous les clients avec qui nous sommes en interaction.
                            Lorsque ces clients ont un abonnement et des casques, il est possible d'accéder à leur page." />
                    </Col>
                    <Col className='d-flex justify-content-end align-items-center pe-0' xs={3}>
                        <Button variant="success" onClick={mergeClientsModal}><i className="fas fa-object-group" />&nbsp; Fusionner des clients</Button>
                    </Col>
                </h2>
            </Col>
        </Row>
        <Row>
            <Col>
                {!isFetchingAllClientsCRM
                    && <ClientsFiltersComponent
                        allClients={allClientsCRM}
                        setFilteredClients={setFilteredClients}
                        isClientsCRM={true}
                        isAdmin={AuthorizationChecker.isAdmin()} />}
            </Col>
        </Row>
        <Row>

            { (isFetchingAllClientsCRM) && <Loader /> }
            { filteredClients.length > 0 && <ClientCRMTableWithParams clientsCRM={filteredClients} /> }
            {isNoResult && <Alert className="mt-3" variant="warning">
                Aucun établissement ne correspond à votre recherche. Pour en créer un , <Button as="a" bsPrefix={'no-btn'} onClick={() => { openCreateClientModal() }}>cliquez ici</Button>
                </Alert>}
        </Row>
        {modalComponent}
    </Container>;
}

export default ClientCRMPage;