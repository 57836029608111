import React, { useState } from 'react';
import './QuizPlayComponent.scss'
import QuestionPlayComponent from './QuestionPlay/QuestionPlayComponent';
import {  useStoreState } from 'easy-peasy';
import IntroPlayComponent from './QuizIntroOutro/IntroPlayComponent';
import { Alert,  Row, Col, Container, } from "react-bootstrap";

const QuizPlayComponent = ({quiz}) => {

    const {apiData : {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

    //sort questions position
    const questionList = [
        ...quiz.questions.sort((a, b) => {
            if(a.position && b.position) {
                return a.position - b.position
            }
            if(!a.position && !b.position){
                return 0
            }
            if(!a.position){
                return -1
            }
            return 1
        }
    )];

    //Initial value  for current question
    const [currentQuestionPosition, setCurrentQuestionPosition] = useState(0);
    const states = ["intro", "quiz", "outro"];
    const [currentState, setCurrentState] = useState(0);

    // function to change the question by its position value
    const setNextQuestion = () => {
        if(currentQuestionPosition == questionList.length - 1){
            changeState();
        }
        else {
            setCurrentQuestionPosition(currentQuestionPosition + 1);
        }
    }

    //function to return to the previous question
    const setPreviousQuestion = () => {
        if(currentQuestionPosition > 0){
            setCurrentQuestionPosition(currentQuestionPosition - 1);
        }
    }

    const changeState = () => {
            if(currentState < states.length - 1)
                setCurrentState(currentState + 1);
            else{
                setCurrentState(0);
                setCurrentQuestionPosition(0);
            }           
    }

    const changeBackground = (background) => {
        if (background === null){ 
            return BACKOFFICE_DEFAULT_VALUES.bkgImage;
        }else{
            return background;
        }
    }

    const quizDisplay = {
        intro: <IntroPlayComponent 
            text={quiz.introText} 
            onValid={changeState} 
            Background={changeBackground(quiz.links.introBackground)}/>,
        quiz: <QuestionPlayComponent 
            question={questionList[currentQuestionPosition]} 
            prev={setPreviousQuestion} 
            next={setNextQuestion} 
            bgUrl={changeBackground(questionList[currentQuestionPosition]?.links?.background)}/>,
        outro: <IntroPlayComponent 
            Background={changeBackground(quiz.links.outroBackground)} 
            text={quiz.outroText} 
            onValid={changeState}
            intro = {false}
            />
    };

    return (
        <>
            <div className='divQuiz'>
                {questionList !== undefined && questionList[0]?.links ?
                    quizDisplay[states[currentState]]
                    : <> <div className="no-quiz-question">
                            <Alert variant="info"> Le quiz <strong>"{quiz.name}"</strong> n'a pas encore de question pour le moment.</Alert>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default QuizPlayComponent;