import React, { useEffect, useState } from "react";
import { Container, Row, Button } from "react-bootstrap";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";
import DownloadRomeVideosPDF from "../../components/widgets/generatePDFs/romesVideosList.jsx";
import RomesPageContent from "./RomesPageContent.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import { useStoreActions } from "easy-peasy";

const RomesPage = () => {
    const { allRomes, isFetchingAllRomes } = useCustomGetStoreState("romes");
    const { allVideos } = useCustomGetStoreState("videos");
    const { fetchAllRomes } = useStoreActions(actions => actions.romes);
    const { backButtonComponent } = useBackButton();
    const btnPdfRomesLabel = "Pdf expériences immersives par codes ROME";
    const [generateJobs, setGenerateJobs] = useState(false);

    useEffect(() => {
        allRomes.length === 0 && !isFetchingAllRomes
            && fetchAllRomes();
    }, [])

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    Codes ROME
                    {AuthorizationChecker.isAdmin() && backButtonComponent}
                </h2>
            </Row>
            <Row className="mb-3">
                <div className="p-0">
                    {generateJobs
                        ? <DownloadRomeVideosPDF romes={allRomes} videos={allVideos} btnPdfLabel={btnPdfRomesLabel} />
                        : <Button variant="primary" onClick={() => { setGenerateJobs(true) }}
                            disabled={allRomes?.length === 0 || allVideos?.length === 0}>
                            <i className="fas fa-download mr-1" />{btnPdfRomesLabel}
                        </Button>}
                </div>
            </Row>
            <RomesPageContent romes={allRomes} isFetching={isFetchingAllRomes} />
        </Container>
    )
}

export default RomesPage;