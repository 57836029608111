import React, { useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import GetMultiClientComponent from './multiClient/GetMultiClientComponent ';
import HelperMetiers360 from '../../services/HelpersMetiers360';
import AuthorizationChecker from '../../services/AuthorizationChecker';

const CreateMultiClientComponent = ({ clientsParent, client }) => {
    const { apiData} = useStoreState(state => state.actionSlugs);
    const { clientsCategories, allClientsCRM } = useStoreState(state => state.clients);
    const { fetchClientsCategories, fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);

    const { csrfToken } = apiData.ACTIONS['clients']['create'];
    const { createClientCRM, updateClientCRM, isAllClientPropsAreMerged } = useStoreActions(actions => actions.clients);
    const { allTagOperations } = useStoreState(state => state.tagOperations);

    const clientsRef = useRef(null);

    const [showMultiClientComponent, setShowMultiClientcomponent] = useState(true);
    const [logSent, setLogSent] = useState([]);
    const [currentSending, setCurrentSending] = useState(null);

    const callBackCategories = (categories) => {
        return categories?.split(',')
                .map(v => v.replace(/^\s+|\s+$/g, ""))
                .filter((item,index,self) => self.indexOf(item)==index);
    }

    const callBackUniqueId = (uniqueId) => {
        if (uniqueId && uniqueId !== "") {
            uniqueId.trim();
            uniqueId.toLowerCase();
        }
        return uniqueId;
    }

    const dataStructureTarget = [
        {field:'name', label:<>Nom d'établissement</>, csvIdx: "Nom d'établissement"},
        {
            field: 'uniqueId', label: <>UniqueId d'établissement</>, csvIdx: "UniqueId d'établissement",
            callBack: (value) => callBackUniqueId(value)
        },
        {field:'categories', label:<>Catégorie</>, csvIdx: "Catégorie",
            callBack:(value) => callBackCategories(value)},
        { field: 'addressStreet', label: <>Voie</>, group: "address", csvIdx: "Voie" },
        { field: 'addressZipCode', label: <>Code postal</>, group: "address", csvIdx: "Code postal" },
        { field: 'addressCity', label: <>Ville</>, group: "address", csvIdx: "Ville" },
    ];

    useEffect(() => {
        if(clientsCategories.length == 0){
            fetchClientsCategories();
        };
        if (!isAllClientPropsAreMerged) {
            fetchAllPropsForAllClients({
                hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')
            });
        }
    }, []);



    const isAddressDifferent = (csvClientInState, csvClient) => {
        return csvClient.addressStreet.trim().toLowerCase() !== csvClientInState.addressStreet.trim().toLowerCase()
            || csvClient.addressZipCode.trim().toLowerCase() !== csvClientInState.addressZipCode.trim().toLowerCase()
            || csvClient.addressCity.trim().toLowerCase() !== csvClientInState.addressCity.trim().toLowerCase()
    }

    const isEmptyAddress = (csvClient) => {
        return csvClient.addressStreet === ""
            && csvClient.addressZipCode === ""
            && csvClient.addressCity === ""
    }

    const onValidate = async (clientsCommonInfos, subscription) => {
        setShowMultiClientcomponent(false);
        const doNextPromise = (key) => {
            const csvClient = clientsRef.current[key];
            const csvClientHasUniqueIdKey = csvClient.hasOwnProperty("uniqueId");
            const csvClientHasUniqueId = csvClientHasUniqueIdKey && csvClient["uniqueId"] !== "" && csvClient["uniqueId"] !== null;
            let csvClientInState;

            if (csvClientHasUniqueId) {
                csvClientInState = allClientsCRM.find((client) => csvClient.uniqueId === client.uniqueId);
            }

            if (csvClientInState) {
                if (isAddressDifferent(csvClientInState, csvClient)) {
                    if (!isEmptyAddress(csvClient)) {
                        csvClient["address"] = {
                            addressName: clientsCommonInfos?.tagOperations?.length > 0
                                ? clientsCommonInfos?.tagOperations?.map(tag => allTagOperations.find((tagInAllTags) => tagInAllTags.id === tag)?.name).join(', ')
                                : 'Opération en masse',
                            companyName: csvClient.name,
                            addressStreet: csvClient.addressStreet,
                            addressZipCode: csvClient.addressZipCode,
                            addressCity: csvClient.addressCity
                        };
                    }
                    delete csvClient.addressStreet;
                    delete csvClient.addressZipCode;
                    delete csvClient.addressCity;
                }

                if (clientsCommonInfos?.tagOperations?.length > 0) {
                    const csvClientInStateTagsIds = csvClientInState.tagOperations.map((clientInStateTag) => {
                        const tagId = allTagOperations.find((tagInAllTags) => tagInAllTags.name === clientInStateTag.name)?.id;
                        return tagId;
                    });
                    const mergedTags = [...csvClientInStateTagsIds, ...clientsCommonInfos.tagOperations]
                    csvClient["tagOperations"] = [...new Set(mergedTags)];
                }
            }

            else {
                if (clientsCommonInfos?.tagOperations?.length > 0) {
                    csvClient["tagOperations"] = clientsCommonInfos.tagOperations;
                }
            }


            if (clientsCommonInfos?.contactsM360?.length > 0) {
                csvClient["contactsM360"] = clientsCommonInfos.contactsM360;
            };

            if (clientsCommonInfos?.clientStatus !== "") {
                csvClient["clientStatus"] = clientsCommonInfos.clientStatus;
            };

            csvClient["categories"] = csvClient.categories
                    .map((filecat)=> {
                        let categoryId = clientsCategories
                            .find((category) => 
                                filecat.replace("-", "").replace("–", "") === category.name.replace("-", "").replace("–", ""));

                        categoryId === undefined && logSent.push({
                            name: csvClient.name,
                            isFailed: true,
                            msg:"Une des catégories indiquées n'existe pas"
                        });
                        setLogSent(logSent);

                        return categoryId.id.toString();
                    });

            let payload;
            let request;
            if (!csvClientHasUniqueId) {
                csvClientHasUniqueIdKey && delete csvClient.uniqueId;
                csvClient["clientCsrfToken"] = csrfToken;
                payload = {
                    clientParent: clientsParent.uniqueId,
                    client: csvClient,
                    subscription: subscription,
                }
                request = createClientCRM(payload);
            }
            else {
                csvClient["subscription"] = subscription;
                payload = {
                    data: csvClient,
                    uniqueId: csvClient.uniqueId,
                }
                request = updateClientCRM(payload);
            }


            setCurrentSending(csvClient.name);


            request.then((msg) => {
                        logSent.push({
                            name: csvClient.name,
                            isOK: true,
                            msg:'Enregistré sur le serveur'
                        });
                        setLogSent(logSent);
                    })
                    .catch(err => {
                        logSent.push({
                            name: csvClient.name,
                            isFailed: true,
                            msg:err
                        });
                        setLogSent(logSent);
                    })
                    .finally(() => {
                        setCurrentSending(null);
                        key++;
                        if(key < clientsRef.current.length) {doNextPromise(key)}
                    });
        };
        doNextPromise(0);
    };

  
    const logSentDisplay = logSent.map(log => <ListGroupItem key={HelperMetiers360.generateUniqueId()}>
        Envoi de {log.name}&nbsp;
        {log.isOK && <span className="text-success icon-checkmark"> &nbsp;{log.msg}</span>}
        {log.isFailed && <span className="text-danger icon-cross"> &nbsp;{log.msg}</span>}
    </ListGroupItem>);
    const currentSendingDisplay = currentSending ? <ListGroupItem>Envoi en cours de {currentSending}&nbsp;<Spinner size="sm" animation="border" /></ListGroupItem> : null;

    return (
        <div>
            {showMultiClientComponent && <GetMultiClientComponent 
                clientsRef={clientsRef} 
                clientsParent={clientsParent}
                onValidate={onValidate} 
                dataStructureTarget={dataStructureTarget}
                clientsCategories={clientsCategories} />}
                
            {logSent.length > 0 && <ListGroup>{logSentDisplay}</ListGroup>}
            {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}

        </div>
    )

}

export default CreateMultiClientComponent;