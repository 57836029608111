import React ,  {useEffect} from 'react';
import { useStoreActions, useStoreState} from 'easy-peasy';
import Loader from "../../components/widgets/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from 'react-bootstrap';


const UpdateFormationSessionPage  = (props) => {
    const {userUniqueId} = useStoreState(state => state.auth);
    const {uniqueId, otherId} = useParams();

    const formationSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('formations'));

    const {participateUserToFormationSession} = useStoreActions(actions => actions.formationSessions);

    const navigate = useNavigate();

    
   
    useEffect(() => {
        if(uniqueId && otherId && (userUniqueId == otherId )){
            const formationSessionUniqueId  = uniqueId;
            const participatingUserUniqueId = otherId;

            const params =  {
                formationSessionUniqueId : formationSessionUniqueId,
                userUniqueId : participatingUserUniqueId,
            }

            participateUserToFormationSession(params)
                .then((result)=>{
                    if(!result || !result?.formationUniqueId){
                        navigate(formationSlugs.readAll);
                        return;
                    }
                    if(result?.link){
                        window.location.replace(result?.link);
                    }
                })
        }
        else{
            navigate(formationSlugs.readAll);
        }
    }, []);


  

    return (
		<Container>
            <Loader/>
		</Container>
	);
}
export default UpdateFormationSessionPage;