import React from "react";

import { useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";
import UploadFormationComponent from "../../components/formations/upload/UploadFormationComponent";
import useBackButton from "../../hooks/useBackButton";

const UploadFormationPage = () => {
    const formationsSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("formations"));

    const { backButtonComponent } = useBackButton()

    return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'un webinaire</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <UploadFormationComponent 
					action="create"
					{...formationsSlugs}
				/>
            }
		</div>
	);
}

export default UploadFormationPage;