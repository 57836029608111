import React, {useState, useEffect} from "react";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import useDebounceSearch from "../../../hooks/useDebounchSearch";
import HelperMetiers360 from "../../../services/HelpersMetiers360";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import Loader from "../../../components/widgets/Loader";
import ClientStatusTableComponent from "../../../components/clientStatus/ClientStatusTableComponent";
import { useStoreActions, useStoreState } from "easy-peasy";
import ClientStatusFormComponent, { submitClientStatusForm } from "../../../components/clientStatus/ClientStatusFormComponent";
import { useToast } from "../../../hooks/useToast";
import { useModal } from "../../../hooks/useModal";
import withURLParamsTableComponent from "../../../HOC/withURLParamsTableComponent";

const ClientStatusTableComponentWithParams = withURLParamsTableComponent(ClientStatusTableComponent);

const ClientStatusPage = () => {
    const { allClientStatus, isFetchingAllClientStatus } = useCustomGetStoreState("clientStatus");
    const clientStatusSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("clientStatus"));
    const {createClientStatus} = useStoreActions(actions => actions.clientStatus);

    const toast = useToast();
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const {searchInput, search} = useDebounceSearch({placeholder: 'Rechercher par nom', delay: 400});

    const [filteredClientStatus, setFilteredClientStatus] = useState(allClientStatus);

    useEffect(() => {
        setFilteredClientStatus(allClientStatus);
    }, [allClientStatus])

    useEffect(() => {
        if(allClientStatus) {
            setFilteredClientStatus([...allClientStatus.filter(
                status => HelperMetiers360.isSearchInText(search, status.label))
            ]);
        }
    }, [search, allClientStatus])

    const openCreateClientStatusModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            submitClientStatusForm({allClientStatus, action: createClientStatus})
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Création d'un statut client</>,
            content: <ClientStatusFormComponent />,
            cancelButton : 'Annuler',
            onValidate: handleSubmit,
            size: 'xl', 
        });
        setModalShow(true);
    }; 

    const createButton = clientStatusSlugs.create
        && <Button variant="success" onClick={() =>{ openCreateClientStatusModal()}} as="a" >
            <i className="fas fa-plus"></i> &nbsp;Nouveau
        </Button>

    const isNoClientStatus = !isFetchingAllClientStatus && !allClientStatus.length
    const isNoResult = !isFetchingAllClientStatus && allClientStatus.length && !filteredClientStatus.length;

    return <>
        <Container fluid>
            <Row>
                <Col><h2>Statuts client</h2></Col>
            </Row>
            <Row className="item-page">
                <Col>
                    {!isFetchingAllClientStatus && searchInput}
                </Col>
                 <Col className="d-flex justify-content-end align-items-center">
                     {createButton}
                 </Col>
            </Row>

            { (isFetchingAllClientStatus &&  allClientStatus.length < 1)  && <Loader /> }
            { filteredClientStatus.length > 0 && <ClientStatusTableComponentWithParams clientStatusToDisplay={filteredClientStatus}/> }
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucun statut client ne correspond à votre recherche</Alert>}
            {isNoClientStatus && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun statut client</Alert>}
        </Container>
        {modalComponent}
    </>;
}
export default ClientStatusPage;