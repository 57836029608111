import React, {useState, useEffect, useMemo} from "react";

import { Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useToast } from "../../../../../hooks/useToast.jsx";
import { useModal } from "../../../../../hooks/useModal.jsx";
import ClientContactsComponent from "../../../../clients/ClientsDetail/clientCRMDetailView/ClientContactsComponent.jsx";
import ClientAddressesComponent from "../../../../clients/ClientsDetail/clientCRMDetailView/ClientAddressesComponent.jsx";
import axios from '../../../../../services/axios';
import HelperMetiers360 from "../../../../../services/HelpersMetiers360.js";
import AddressFormComponent, { submitAddressForm } from "../../../../address/AddressFormComponent.jsx";
import withURLParamsTableComponent from "../../../../../HOC/withURLParamsTableComponent.jsx";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

const ClientAddressesComponentWithParams = withURLParamsTableComponent(ClientAddressesComponent);
const ClientContactsComponentWithParams = withURLParamsTableComponent(ClientContactsComponent);

registerLocale('fr', fr);

const DeliveryInformationComponent = ({
    client,
    orderToUpdate,
    deliveryInformationsRef,
    multiple,
    isToSetCommonInfos,
    commonDeliveryInfos,
    triggerUpdateCommonInfos
}) => {

    const { fetchClientCRMById, fetchAddressesByClientId} = useStoreActions(actions => actions.clients);
    const { clientCRMById, addressesByClientId} = useStoreState(state => state.clients);
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [addresses, setAddresses] = useState(null);
    const [deliveryInformations, setDeliveryInformations] = useState(deliveryInformationsRef.current);
    const { createClientAddress } = useStoreActions(actions => actions.clients);

    const [startDate, setStartDate] = useState(orderToUpdate?.deliveryDate ? new Date(orderToUpdate?.deliveryDate) : null);
        
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();
    

    
    const updateSelectedAddress = () => {
        const selectedAddressInResponse = addressesByClientId.find((address)=>{
            return selectedAddress.uniqueId === address.uniqueId 
        })
        if(selectedAddressInResponse) {
            setSelectedAddress(selectedAddressInResponse);
        }
    };
    


    const fetchAddresses = ()=> {
        let action = fetchAddressesByClientId(client?.uniqueId); 
        if(multiple){
            const getUrl = ACTIONS?.clientsCRM?.getProps?.getAddresses?.url?.replace('uniqueId', client?.uniqueId);
            action = axios.get(getUrl);
        }
         action.then(response => {
                if(response?.length > 0 ){
                    setAddresses(response);
                    if(!selectedAddress){
                        if(!orderToUpdate){
                            setSelectedAddress(response[0]);
                        }
                    }
                    else{
                        updateSelectedAddress();
                    }
                }
            });
    }



    const  updateAdresses = (fetchedAddressData)=> {
       if(fetchedAddressData.hasOwnProperty('addressToDeleteUniqueId')){
            const addressInAddressesIndex = addresses.findIndex((address)=>{
                return address.uniqueId === fetchedAddressData.addressToDeleteUniqueId
            });
            if(addressInAddressesIndex !== -1){
                addresses.splice(addressInAddressesIndex,1);
            }
       }
       if(fetchedAddressData.hasOwnProperty('addressToAdd')){
            addresses.push(fetchedAddressData.addressToAdd);
       }
       if(fetchedAddressData.hasOwnProperty('addressToUpdate')){
            const addressInAddressesIndex = addresses.findIndex((address)=>{
                return address.uniqueId === fetchedAddressData.addressToUpdate.uniqueId
            });
            if(addressInAddressesIndex !== -1){
                addresses[addressInAddressesIndex] = {
                    ...addresses[addressInAddressesIndex],
                    ...fetchedAddressData.addressToUpdate,
                };
            }
       };
       
       setAddresses([...addresses]);

       if(fetchedAddressData.hasOwnProperty('addressToAdd')){
            setSelectedAddress(fetchedAddressData.addressToAdd);
       }
    }

    const orderClient = useMemo(() => {
        if (client?.uniqueId === clientCRMById?.uniqueId) {
            return clientCRMById;
        }
    }, [clientCRMById, client]);



    useEffect(()=>{
    if(selectedAddress && addressesByClientId.length > 0){
        updateSelectedAddress();
    } 
    }, [addressesByClientId]);




    useEffect(()=>{
        if(client){
            fetchAddresses(client?.uniqueId); 
        }
    },[client]);



    useEffect(()=>{
        if(client){
            if (!isToSetCommonInfos) {
                fetchClientCRMById(client?.uniqueId);
            }

            if(client.contacts?.length === 1) {
                setSelectedContact(client.contacts[0]);

            }
            if(HelperMetiers360.isObjectEmpty(deliveryInformationsRef.current)){
                const newDeliveryInformations = {
                    recipientSociety: client?.name,
                    recipientAdress1:client?.addressStreet,
                    recipientAdress2: null,
                    postalCode:client?.addressZipCode,
                    city:client?.addressCity,
                    country:client?.addressCountry,
                    isRenting:null,
                    isKiosk:false,
                    isClientInventory:false};
                setDeliveryInformations(newDeliveryInformations);
                deliveryInformationsRef.current = newDeliveryInformations;
            }
        } else {
            setDeliveryInformations({  isRenting:null,
                isKiosk:false,
                isClientInventory:false});
        }
    },[]);

    const updateDeliveryInformation = ({item,value}) => {
        const newDeliveryInformations = {...deliveryInformations};
        newDeliveryInformations[item]=value;
        deliveryInformationsRef.current = newDeliveryInformations;
        setDeliveryInformations(newDeliveryInformations);
    };

    useEffect(() => {
        if(selectedContact) {
            let recipientName = ''
                + (selectedContact.lastName ? selectedContact.lastName:'')
                + (selectedContact.firstName ? ' '+selectedContact.firstName:'');
            const newRecipientInformations = {
                recipientName:recipientName,
                phoneRecipient:selectedContact.cellphoneNumber ? selectedContact.cellphoneNumber:'',
                emailRecipient:selectedContact.email ? selectedContact.email:''
            };
            deliveryInformationsRef.current = {...deliveryInformations,...newRecipientInformations};
            setDeliveryInformations({...deliveryInformations,...newRecipientInformations});
        }
    },[selectedContact]);


    useEffect(() => {
        if(selectedAddress) {
            const newRecipientInformations = { recipientSociety: selectedAddress.companyName,
                recipientAdress1:selectedAddress?.addressStreet,
                recipientAdress2: null,
                postalCode:selectedAddress?.addressZipCode,
                city:selectedAddress?.addressCity,
                country:selectedAddress?.addressCountry,
            };
            deliveryInformationsRef.current = {...deliveryInformations,...newRecipientInformations};
            setDeliveryInformations({...deliveryInformations,...newRecipientInformations});
        }
    },[selectedAddress]);


    useEffect(()=>{
        if(commonDeliveryInfos !== undefined){
            setDeliveryInformations({...deliveryInformations, ...commonDeliveryInfos});
            commonDeliveryInfos?.deliveryDate && setStartDate(new Date(commonDeliveryInfos?.deliveryDate));
        }
    },[triggerUpdateCommonInfos]);





    const addAddressModal = ()=>{
        const newAddress = {
            companyName : deliveryInformations?.recipientSociety,
            addressStreet : deliveryInformations?.recipientAdress1,
            addressCity  : deliveryInformations?.city,
            addressZipCode : deliveryInformations?.postalCode,
            addressCountry : deliveryInformations?.country,
        }
        const {formName} = ACTIONS['clientsCRM']['addresses']['create'];

        const handleSubmit = () => {
            setIsSending(true);
            const result = submitAddressForm({formName, action: createClientAddress, uniqueId: client?.uniqueId, 
                updateAddressesForOrders: multiple && updateAdresses});

            result.then(() => {
                toast.success();
                setModalShow(false);
            })
            .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Ajout d'adresse à {client.name}</>,
            content: <AddressFormComponent formName={formName} address={newAddress} />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'lg'
        });
        setModalShow(true);
    }






    return <>
        <Row className="mt-5">
            <h3>Livraison</h3>
        </Row>
        { !isToSetCommonInfos 
            && <>
            <Row  className="d-flex justify-content-around">
                <h4 className="mb-0">Contact</h4>
                {client?.contacts?.length >0 && <Col className="align-self-center" xs={8}>
                    <ClientContactsComponentWithParams client={orderClient ? orderClient : client} editable={true} updatable={false} onSelect={client.contacts.length !== 1 && setSelectedContact} downloadable={false}/>
                </Col>}
                <Col className="align-self-top mt-5">
                    <InputGroup>
                        <InputGroup.Text>Nom :</InputGroup.Text>
                        <Form.Control
                            name={`recipientName`}
                            value={deliveryInformations?.recipientName}
                            onInput={e => {updateDeliveryInformation({item:`recipientName`,value:e.target.value})}}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>Mobile :</InputGroup.Text>
                        <Form.Control
                            name={`phoneRecipient`}
                            value={deliveryInformations?.phoneRecipient}
                            onInput={e => {updateDeliveryInformation({item:`phoneRecipient`,value:e.target.value})}}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>Mail :</InputGroup.Text>
                        <Form.Control
                            name={`emailRecipient`}
                            value={deliveryInformations?.emailRecipient}
                            onInput={e => {updateDeliveryInformation({item:`emailRecipient`,value:e.target.value})}}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row  className="mt-4 d-flex justify-content-around">
                <h4>Adresse</h4>
                {addresses?.length >0 && <Col className="align-self-top" xs={8}>
                    <Row className="row-smallspace">
                        <ClientAddressesComponentWithParams client={client} onSelect={addresses.length !== 1 && setSelectedAddress} addresses={multiple ? addresses : addressesByClientId} updateAddressesForOrders={multiple ? updateAdresses :null}  downloadable={false} />
                    </Row>
                </Col>}
                <Col className="align-self-top mt-5">
                    <Row>
                        <InputGroup>
                            <InputGroup.Text>Structure :</InputGroup.Text>
                            <Form.Control
                                name={`recipientSociety`}
                                value={deliveryInformations?.recipientSociety}
                                onInput={e => {updateDeliveryInformation({item:`recipientSociety`,value:e.target.value})}}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Voie :</InputGroup.Text>
                            <Form.Control as="textarea"
                                rows={5}
                                name={`recipientAdress1`}
                                value={deliveryInformations?.recipientAdress1}
                                onInput={e => {updateDeliveryInformation({item:`recipientAdress1`,value:e.target.value})}}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Text>Complément d'adresse :</InputGroup.Text>
                            <Form.Control
                                name={`recipientAdress2`}
                                value={deliveryInformations?.recipientAdress2}
                                onInput={e => {updateDeliveryInformation({item:`recipientAdress2`,value:e.target.value})}}
                            />
                        </InputGroup>
                        <Col>
                            <InputGroup>
                                <InputGroup.Text>C.P :</InputGroup.Text>
                                <Form.Control
                                    name={`postalCode`}
                                    value={deliveryInformations?.postalCode}
                                    onInput={e => {updateDeliveryInformation({item:`postalCode`,value:e.target.value})}}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup>
                                <InputGroup.Text>Ville :</InputGroup.Text>
                                <Form.Control
                                    name={`city`}
                                    value={deliveryInformations?.city}
                                    onInput={e => {updateDeliveryInformation({item:`city`,value:e.target.value})}}
                                />
                            </InputGroup>
                        </Col>
                        <InputGroup>
                            <InputGroup.Text>Pays :</InputGroup.Text>
                            <Form.Control
                                name={`country`}
                                value={deliveryInformations?.country}
                                onInput={e => {updateDeliveryInformation({item:`country`,value:e.target.value})}}
                            />
                        </InputGroup>
                    </Row>
                    <Row>
                        <Col className="mt-3 d-flex align-items-center justify-content-center">
                                <Button variant="success" className="mt-3 d-flex align-items-center justify-content-center" onClick={(e) => addAddressModal ()}><i className="fas fa-plus"></i> &nbsp;Ajouter aux adresses</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>}
        <Row className="mt-4">{!isToSetCommonInfos &&<h4>Autres informations</h4>}
            <Col><InputGroup>
                <InputGroup.Text>Date de livraison souhaitée</InputGroup.Text>
                <DatePicker
                    showIcon
                    locale='fr'
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => {
                            setStartDate(date);
                            updateDeliveryInformation({item:`deliveryDate`,value: HelperMetiers360.formatDateForFormControl(date)});
                        }
                    } 
                    placeholderText="jj/mm/aaaa"
                />
            </InputGroup></Col>
            <Col className="d-flex justify-content-between align-items-center">
                <Form.Check
                    name={`isKiosk`}
                    id='isKiosk'
                    type='checkbox'
                    label='Mode kiosk'
                    defaultChecked={deliveryInformations?.isKiosk}
                    onClick={e => {updateDeliveryInformation({item:`isKiosk`,value:e.target.checked})}} />
                <Form.Check
                    name={`isClientInventory`}
                    id='isClientInventory'
                    type='checkbox'
                    label='Inventaire client'
                    defaultChecked={deliveryInformations?.isClientInventory}
                    onClick={e => {updateDeliveryInformation({item:`isClientInventory`,value:e.target.checked})}} />
            </Col>
        </Row>
        {modalComponent}
    </>;
}

export default DeliveryInformationComponent;