import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

/**
 * 
 * @param {Array} jobAppellations array of all jobAppellations to be rendered
 * @param {bool} withParams  bool to handle params in url
 * 
 * @example
 *  <JobAppellationsTableComponent jobAppellations={jobAppellations}/>
 * 
 */

const JobAppellationsTableComponent = ({ jobAppellations = [], withParams }) => {
    const [jobAppellationsToDisplay, setJobAppellationsToDisplay] = useState([]);
    const [sortedjobAppellation, setSortedjobAppellation] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const romesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('romes'));
    const videosSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    const largeProfessionalFieldsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('largeProfessionalFields'));

    const navigate = useNavigate();

    useEffect(() => {
        setShouldSort(true);
        setSortedjobAppellation([...jobAppellations]);
    }, [jobAppellations]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedjobAppellation, jobAppellationsToDisplay, setJobAppellationsToDisplay, 100);

    if (jobAppellations.length === 0) {
        return <></>;
    }

    const handleClick = (slugs, uniqueId, urlParamsToAdd = null) => {
        if (slugs?.readOne) {
            navigate(slugs.readOne.replace(':uniqueId', uniqueId) + (urlParamsToAdd ?? ""));
        }
    }

    const buttonRedirect = (key, label, slugs, urlParamsToAdd = null) => <Button variant='light' size='sm' className="m-1"
        onClick={(e) => { e.stopPropagation(); handleClick(slugs, key, urlParamsToAdd); }}>{label}</Button>;

    const sortType = [
        {
            value: 'label', label: 'Métier',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'label'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (jobAppellation) => jobAppellation.label
        },
        {
            value: 'rome', label: 'Code ROME',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'rome'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.rome, b.rome),
            display: (jobAppellation) => buttonRedirect(jobAppellation.rome, jobAppellation.rome, romesSlugs),
            flatDisplay: (jobAppellation) => jobAppellation.rome
        },
        {
            value: 'grandDomaine', label: 'Domaine professionnel',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'grandDomaine'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.grandDomaine?.label, b.grandDomaine?.label),
            display: (jobAppellation) => buttonRedirect(jobAppellation.grandDomaine?.code, jobAppellation.grandDomaine?.label, largeProfessionalFieldsSlugs),
            flatDisplay: (jobAppellation) => jobAppellation.grandDomaine?.label
        },
        {
            value: 'pros', label: 'Professionnel⋅les',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'pros'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.pros?.[0]?.name, b.pros?.[0]?.name),
            display: (jobAppellation) => <>{
                jobAppellation.pros?.map(pro =>
                    <div key={jobAppellation.code + '_' + pro.uniqueId}>{
                        buttonRedirect(pro?.videos[0], pro?.name, videosSlugs, "?tab=jobsVideo")
                    }</div>)
            }</>,
            flatDisplay: (jobAppellation) => jobAppellation.pros?.map(pro => pro.name)?.join(', ')
        },
        {
            value: 'videos', label: 'Expériences immersives',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'pros'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.videos[0]?.videoName, b.videos[0]?.videoName),
            display: (jobAppellation) => <>{
                jobAppellation.videos?.map(video =>
                    <div key={jobAppellation.code + '_' + video.uniqueId}>{
                        buttonRedirect(video?.uniqueId, video?.videoName, videosSlugs)
                    }</div>)
            }</>,
            flatDisplay: (jobAppellation) => jobAppellation.videos.map((video) => `${video.videoName}${video.subHeading ? " - " + video.subHeading : ""}`).join("/")
        }
    ];



    return (
        <DynamicTableInfinite
            contentTable={jobAppellationsToDisplay}
            contentSort={sortType}
            valueInitSort="label"
            index='code'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedjobAppellation}
            sortedContent={sortedjobAppellation}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            handleClick={null}
            filename="metiers"
        />
    );
}

JobAppellationsTableComponent.propTypes = {
    jobAppellations: PropTypes.array,
    withParams: PropTypes.bool.isRequired,
};

export default JobAppellationsTableComponent;