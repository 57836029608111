import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import labImage from '../../images/lab_none_tester.jpg';

const NoneTesterComponent = () => {
    return <Row>
        <Col>
            <h4>Rejoignez le Lab !</h4>
            <h5 className="mt-4">Nous constituons un groupe de bêta-testeurs et bêta-testeuses afin de solliciter votre avis sur notre solution de découverte des métiers.</h5>
            <div className="text-lab">
                <p className="mt-4 mb-1">Si cette initiative vous intéresse, vous ferez partie du Lab, une communauté d’utilisateur·ices qui ont envie de partager leurs avis et bonnes idées.*</p>
                <i className="text-little-lab">* Tout se fait en fonction de vos disponibilités, bien entendu !</i>
                <p className="fw-bold mb-1 mt-4">Dans le cadre de ce laboratoire, vous pourrez avoir accès à :</p>
                <ul>
                    <li>de nouvelles fonctionnalités ;</li>
                    <li>de nouveaux formats d'expériences immersives ;</li>
                    <li>de nouvelles modalités d'accompagnement ;</li>
                    <li>et bien d’autres choses encore !</li>
                </ul>
                <p className="mt-4">Prenons le temps d’échanger et réfléchissons ensemble aux outils et ressources les plus adaptés aux besoins de vos publics. Peut-être que certaines pistes que vous aurez proposées verront le jour !</p>
                <h4 className="mt-4">Prêt·e à rejoindre le Lab ?</h4>
                Inscrivez-vous en répondant à un rapide questionnaire
                <div>
                    <Button
                        className="mt-3"
                        variant="primary"
                        href="https://forms.gle/QecHdCcFFYDL7C6RA"
                        target="blank">
                        Je m'inscris
                    </Button>
                </div>
            </div>
        </Col>
        <Col xl={12} xxl={6} className="col-img-lab">
            <img src={labImage} className="lab-image" />
        </Col>
    </Row>
}
export default NoneTesterComponent;