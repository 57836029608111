import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStoreActions } from 'easy-peasy';
import { Container, Row, Col } from "react-bootstrap";

import Loader from "../../components/widgets/Loader.jsx";

import ClientDetailViewComponent from "../../components/clients/ClientsDetail/clientDetailView/ClientDetailViewComponent.jsx";

import "./ClientDetailPage.scss";
import useBackButton from "../../hooks/useBackButton.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";

const ClientDetailPage = (props) => {
    const {uniqueId} = useParams();


    const { allClients, clientById } = useCustomGetStoreState("clients");

    const { initClientData } = useStoreActions(actions => actions.clients);

    const [isLoading, setIsLoading] = useState(!clientById);

    useEffect(() => {
        setIsLoading(true);
        initClientData({uniqueId})
            .finally(() => {
                setIsLoading(false);
            });
	}, [uniqueId, initClientData]);

    const { backButtonComponent } = useBackButton();

    return <>
        {!clientById || allClients.length === 0 || isLoading 
            ? <Loader />
            : <Container fluid>
                <Row>
                    <Col className="d-flex justify-content-between align-items-center p-3">
                        <h2>Vue de l'établissement <i>{clientById.clientName}</i></h2>
                        { backButtonComponent }
                    </Col>
                </Row>
                <ClientDetailViewComponent clientById={clientById} allClients={allClients} />
            </Container>}
    </>
}

export default ClientDetailPage;