import React, { useState, useEffect, useRef }  from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";

import HeadsetTableComponent from '../../components/headset/HeadsetTableComponent.jsx';
import Loader from '../../components/widgets/Loader.jsx';
import AuthorizationChecker from '../../services/AuthorizationChecker.js';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import useSearchWithParams from '../../hooks/useSearchWithParams.jsx';
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import SelectParentClientComponent from "../../components/widgets/selectParentClient/SelectParentClientComponent.jsx";
import SelectTagOperationsComponent from '../../components/widgets/SelectTagOperationsComponent/SelectTagOperationsComponent.jsx';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';
import TooltipInfo from '../../components/widgets/tooltipInfo/TooltipInfo.jsx';

const HeadsetTableWithParams = withURLParamsTableComponent(HeadsetTableComponent);

const HeadsetsPage = () => {
    const {allHeadsets, isMergeAllHeadsetsPropsNecessary, isFetchingHeadsets, isMergeClientsPropsInAllHeadsetsNecessary, isUpdateNecessary, initialFetchDone:initialFetchDoneHeadset } = useCustomGetStoreState("headsets");

    const [filteredHeadsets, setFilteredHeadsets] = useState([]);
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfHeadsetOwnership } = BACKOFFICE_DEFAULT_VALUES;

    const {searchInput, search} = useSearchWithParams({placeholder: 
        'Rechercher par mots-clés (nom, N° série, établissement...)',
        delay: 400})
    const { fetchAllClientsAndMergeWithProps } = useStoreActions(actions => actions.clients);
    const { mergeClientsPropsInAllHeadsets, fetchAllPropsForAllHeadsets} = useStoreActions(actions => actions.headsets);
    const { allClients, isAllClientPropsAreMerged, initialFetchDone:initialFetchDoneClient } = useCustomGetStoreState("clients");

    const [searchParam, setSearchParam] = useSearchParams();
    const [outOfOrderFilter, setOutOfOrderFilter] = useState('working');

    const parentClientSelectorRef = useRef(null);
    const tagOperationsSelectorRef = useRef(null);

    const setParamsToUrl = (newParams) => {
        const paramsToSet = {}
        for (const [key, value] of searchParam.entries()) {
            paramsToSet[key] = value;
        }
        for (const [key, value] of newParams) {
            paramsToSet[key] = value;
        }
        setSearchParam(paramsToSet);
    }

    useEffect(() => {

        const newSearchParam = searchParam.get('parentSelected');
        const newSearchParam2 = searchParam.get('tagOpeSelected');

        if (newSearchParam && parentClientSelectorRef?.current) {
            parentClientSelectorRef.current.value = newSearchParam;
        }
        if (newSearchParam2 && tagOperationsSelectorRef?.current) {
            tagOperationsSelectorRef.current.value = newSearchParam2;
        }
    }, []);

    useEffect(() => {
        initialFetchDoneHeadset && AuthorizationChecker.hasGetPropsForAllRights('headsets') 
            && fetchAllPropsForAllHeadsets();
    }, [initialFetchDoneHeadset]);

    useEffect(() => {
        initialFetchDoneClient && fetchAllClientsAndMergeWithProps({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, [initialFetchDoneClient]);

    const shouldMergeClientsPropsInAllHeadsets = isMergeClientsPropsInAllHeadsetsNecessary && isAllClientPropsAreMerged && !isUpdateNecessary && !isMergeAllHeadsetsPropsNecessary;
    
    useEffect(() => {
        if(shouldMergeClientsPropsInAllHeadsets) mergeClientsPropsInAllHeadsets({hasAccessToClientsProps: AuthorizationChecker.hasReadAllRights('clientsCRM')});
    }, [shouldMergeClientsPropsInAllHeadsets]);

    const selectHeadsets = () => {
        if(allHeadsets?.length > 0) {
            const ref = parentClientSelectorRef.current?.value ?? null;
            const tagRef =  tagOperationsSelectorRef.current?.value ?? null;
            
            setParamsToUrl([
                ['parentSelected', ref ?? ""],
                ['tagOpeSelected', tagRef ?? ""],
                ['search', search ?? ""],
            ]);

            const headsetsToDisplay = allHeadsets.filter(h => {
                const outOfOrderCondition = (outOfOrderFilter === 'all') || (outOfOrderFilter === 'out-of-order' && h.isOutOfOrder) || (outOfOrderFilter === 'working' && !h.isOutOfOrder);
                const refCondition = !ref || (h.parentUniqueId === ref || h.clientUniqueId === ref) || (ref === "aucun" && !h.parentUniqueId);
                const tagCondition = !tagRef || h.tagOperations?.some(tag => tag.name == tagRef) || (tagRef === 'aucun' && !h.tagOperations?.length);
                const searchCondition = !search || ( HelperMetiers360.isSearchInText(search, h.headsetName)
                || HelperMetiers360.isSearchInText(search, h.typeOfHeadsetName)
                || HelperMetiers360.isSearchInText(search, h.deviceId)
                || HelperMetiers360.isSearchInText(search, h.macAdress)
                || HelperMetiers360.isSearchInText(search, h.inventoryNumber)
                || HelperMetiers360.isSearchInText(search, h.clientName)
                || HelperMetiers360.isSearchInText(search, h.appVersion)
                || HelperMetiers360.isSearchInText(search, h.deviceOSVersion)
                || HelperMetiers360.isSearchInText(search, h.typeOfHeadset)
                || HelperMetiers360.isSearchInText(search, h.tagOperations?.join())
                || HelperMetiers360.isSearchInText(search, h.categories?.join())
                || (h.ownership 
                    && HelperMetiers360.isSearchInText(search, typeOfHeadsetOwnership[h.ownership])));
                return (tagCondition && refCondition && searchCondition && outOfOrderCondition);
            });
            setFilteredHeadsets(headsetsToDisplay);
        }
    };

	useEffect(() => {
        selectHeadsets();
	}, [search, allHeadsets, outOfOrderFilter]);


    const isNoHeadset = !isFetchingHeadsets && !allHeadsets?.length;
    const isNoResult = !isFetchingHeadsets && allHeadsets?.length > 0 && !filteredHeadsets.length;
    const headsetsAreLoading = isFetchingHeadsets 
        || (isMergeAllHeadsetsPropsNecessary && AuthorizationChecker.hasGetPropsForAllRights('headsets')) 
        || (isMergeClientsPropsInAllHeadsetsNecessary && AuthorizationChecker.hasGetPropsForAllRights('clientsCRM'));

    const ParentClientSelector = <SelectParentClientComponent parentClientSelectorRef={parentClientSelectorRef} clients={allClients} onChange={selectHeadsets}/>;
    const TagOperationsSelector = <SelectTagOperationsComponent tagOperationsSelectorRef={tagOperationsSelectorRef} onChange={selectHeadsets}/>;

    const adminFilter = AuthorizationChecker.isAdmin() &&
        <>
            <Row className='mt-3'>
                <Col lg={8}>
                    {ParentClientSelector && 
                        <><strong className='me-2'>Le parent et ses enfants :</strong> {ParentClientSelector}</>}
                </Col>
                <Col className='d-flex justify-content-end'>    
                    {TagOperationsSelector && 
                        <><strong className='me-2'>Opé M360 :</strong> {TagOperationsSelector}</>}
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col >
                    <div className="d-flex align-items-center">
                        <label className="label-select">Etat&nbsp;: </label>
                        <div className='choices-selector-inline'>
                            <div className={outOfOrderFilter === 'out-of-order' ? 'selected' : null} 
                                onClick={() => setOutOfOrderFilter('out-of-order')} onKeyDown={() => setOutOfOrderFilter('out-of-order')}>
                                Hors&nbsp;service</div>
                            <div  className={outOfOrderFilter === 'all' ? 'selected' : null}
                                onClick={() => setOutOfOrderFilter('all')} onKeyDown={() => setOutOfOrderFilter('all')}>
                                Tous</div>
                            <div className={outOfOrderFilter === 'working' ? 'selected' : null}
                                onClick={() => setOutOfOrderFilter('working')} onKeyDown={() => setOutOfOrderFilter('working')}>
                                Actif</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>;

    return <Container fluid>
        { !AuthorizationChecker.isAdmin() && 
            <Row>
                <Col>
                    <h2 className='d-flex'>
                        Les casques qui vous sont rattachés
                        <TooltipInfo description='Gérer votre parc de casques VR' />
                    </h2>
                </Col>
            </Row>
        }
        <Row>
            <Col>
            {AuthorizationChecker.isAdmin() && 
                    <Alert variant='info'>Note : 
                        <ul>
                            <li><strong>Pour l'application des casques</strong>, s'il est indiqué "?" c'est que 
                                le casque a une version antérieure à 1.61. Recommandez au client de mettre à jour le casque.<br />
                                S'il est indiqué "N/A" c'est qu'il y a eu un souci avec le loader. 
                                Recommandez au client de mettre à jour son loader</li>
                            <li><strong>Pour la mémoire</strong>, s'il est indiqué "n.c." c'est à cause du loader, nous sommes en train de traiter le problème.</li>
                        </ul>
                    </Alert>
                    }

            </Col>
        </Row>
        <Row>
            <Col>
            {allHeadsets.length > 1 && searchInput}
            </Col>
        </Row>
        <Row>
            <Col>
                {!isFetchingHeadsets && adminFilter}
            </Col>
        </Row>
        <Row>  
        { (headsetsAreLoading) && <Loader /> }            
        {
            <>
                {isNoResult &&
                    <Alert className="mt-3" variant="warning">
                        Aucun casque ne correspond à votre recherche
                    </Alert>
                }
                {
                    isNoHeadset && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun casque</Alert>
                }
                    {filteredHeadsets.length > 0 && <HeadsetTableWithParams headsets={filteredHeadsets} />}
            </>
        }
        </Row>
    </Container>;
}

export default HeadsetsPage;