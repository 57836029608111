import React, { useState, useEffect, useRef } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Button, Alert } from 'react-bootstrap';
import { useToast } from "../../../hooks/useToast.jsx";
import { useModal } from "../../../hooks/useModal";
import PropTypes from 'prop-types';

import HelperMetiers360 from '../../../services/HelpersMetiers360';
import useDebounceSearch from '../../../hooks/useDebounchSearch';
import HeadsetCommentForm from '../headsetComment/HeadsetCommentForm';
import InfinitePaginationListComponent from '../../widgets/InfinitePaginationList/InfinitePaginationListComponent.jsx';

import './HeadsetComments.scss';

const HeadsetComments = (props) => {
    const { comments, headset } = props;

    const toast = useToast();
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending : setIsSendingModal } = useModal();

    const { deleteHeadsetComment, createHeadsetComment } = useStoreActions(actions => actions.headsets);

    const [isSending, setIsSending] = useState(false);
    const [filteredComments, setFilteredComments] = useState(comments);
    const [tabContext, setTabContext] = useState();

    const [contentIsEmpty, setContentIsEmpty] = useState(false);
    const newCommentContentRef = useRef();

    const { searchInput, search } = useDebounceSearch({ placeholder: "Rechercher un commentaire" });

    useEffect(() => {
        setFilteredComments(comments?.filter(c =>
            HelperMetiers360.isSearchInText(search, c.content) || HelperMetiers360.isSearchInText(search, c.author)
        ));
    }, [search, comments])

    const onValidate = () => {
        if (!newCommentContentRef?.current?.value) {
            setContentIsEmpty(true);
            return;
        };
        setIsSending(true);
        createHeadsetComment({headsetId: headset?.uniqueId, commentContent: newCommentContentRef?.current?.value})
            .then(() => toast.success())
            .finally(() => {
                setIsSending(false);
                setTabContext(null);
            });
    }

    const onDelete = (commentId) => {
        if (!commentId) {
            return;
        };
        setIsSendingModal(true);
        
        deleteHeadsetComment({commentId: commentId})
            .then(() => toast.success())
            .finally(() => {
                setModalShow(false);
                setIsSendingModal(false);
            });
    }

    const deleteCommentModal = (comment)=>{
        setModalData({
            ...modalData,
            header: <>Suppression d'un commentaire</>,
            content: <h5>
            Êtes-vous sûr⋅e de vouloir supprimer le commentaire du &nbsp;<b>{HelperMetiers360.getdisplayDateType(comment?.createdAt?.date, 'day')}?</b>
            </h5>,
            resetButton: 'Supprimer',
            cancelButton: 'Annuler',
            onReset: () => {onDelete(comment?.uniqueId)},
        });
        setModalShow(true);
    }

    if (tabContext === 'create') {
        return <div className='tab-comment-container' id="tab-container-comment">
            <h2>Ajouter un commentaire</h2>
            <Button className='return-comment-btn' size='sm' onClick={() => setTabContext(null)}>Retour</Button>
            <HeadsetCommentForm isSending={isSending} newCommentContent={newCommentContentRef} onValidate={onValidate} contentIsEmpty={contentIsEmpty} setContentIsEmpty={setContentIsEmpty} />
        </div>;
    }

    if (!comments) { 
        return (
        <div className='tab-comment-container' id="tab-container-comment">
            <Alert variant='danger'>Erreur lors de la récupération des commentaires.</Alert>
        </div>
        )
    };

    return (
        <>
            {modalComponent}
            <div className='tab-comment-container' id="tab-container-comment">
                <div className='search-add-block'>
                    {searchInput}
                    <Button variant="success" className="btn-create-comment"
                            onClick={() => setTabContext('create')}>
                            <i className="fas fa-plus"></i>&nbsp;Ajouter
                    </Button>
                </div>
                {comments?.length > 0 ?
                    <InfinitePaginationListComponent mediaList={filteredComments} mediaType='comments' deleteMedia={deleteCommentModal} maxVisibleItem={10}/>
                    : <p>Ce casque n'a pas encore de commentaires.</p>
                }
            </div>
        </>);

};

HeadsetComments.propTypes = {
    comments: PropTypes.array,
    headset: PropTypes.object,
};

export default HeadsetComments;